import React from 'react';
import '../styles/BuyBoxFrequency.css';
import { Bell } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { Dropdown, MobileRadio } from './';

function BuyBoxFrequency(props) {
    const selectedOption = props.selectedOption;
    const changeSelectedOption = props.changeSelectedOption;
    const buyBoxFrequencyDropdown = props.buyBoxFrequencyDropdown;
    const setBuyBoxFrequencyDropdown = props.setBuyBoxFrequencyDropdown;

    const options = [
        {
            label: "Daily",
            value: "daily",
            selected: selectedOption === "daily" ? true : false
        },
        {
            label: "Weekly",
            value: "weekly",
            selected: selectedOption === "weekly" ? true : false
        },
        {
            label: "Monthly",
            value: "monthly",
            selected: selectedOption === "monthly" ? true : false
        },
        {
            label: "Never",
            value: "never",
            selected: selectedOption === "never" ? true : false
        }
    ];

    const toggleDropdown = () => {
        setBuyBoxFrequencyDropdown(!buyBoxFrequencyDropdown);
    };

    return (
        <div className="buy-box-frequency-container">
            <div className="buy-box-frequency-title-container desktop-none">
                <h2 className="body-semibold colour-primary">
                    How often would you like to receive emails?
                </h2>
            </div>
            <div className="buy-box-frequency-row mobile-none">
                <Dropdown
                    open={buyBoxFrequencyDropdown}
                    icon={<Bell fill={colour.blueBlue03} className="" />}
                    label={options.find(item => item.selected === true).label}
                    disabled={false}
                    options={options}
                    multiselect={false}
                    toggleDropdown={toggleDropdown}
                    cta={changeSelectedOption}
                    sidealign={"right"}
                    topalign={"bottom"}
                    indexSelect={false}
                    selectedIndex={null}
                />

                {/* {
                    options.map((item, index) =>
                        <SelectionChip
                            key={index}
                            label={item.label}
                            selected={item.selected}
                            disabled={false}
                            func={() => changeSelectedOption(item.value)}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                        />
                    )
                } */}
            </div>
            <div className="buy-box-frequency-column desktop-none">
                {
                    options.map((item, index) =>
                        <MobileRadio
                            label={item.label}
                            selected={item.selected}
                            index={index}
                            clickFunc={() => changeSelectedOption(item.value)}
                            key={index}
                            disabled={false}
                        />
                    )
                }
            </div>
        </div>
    )
};

export default BuyBoxFrequency;