import React from 'react';
import '../styles/LastUpdated.css';
import { RedirectArrow } from '../assets/icons';
import moment from 'moment';
import { recordEvent } from '../functions';
import { colour } from '../styles/GlobalStyles';

function LastUpdated(props) {
    const property = props.property;
    const lastUpdated = property.lastDataUpdateTimestamp;
    const utcDate = new Date(lastUpdated);
    const utcTimestamp = utcDate.getTime();
    const timezoneOffsetMinutes = new Date().getTimezoneOffset();
    const timezoneOffsetMilliseconds = timezoneOffsetMinutes * 60000;
    const localTimestamp = new Date(utcTimestamp - timezoneOffsetMilliseconds);
    const currentTime = new Date();
    const lastUpdatedTSMoment = moment(localTimestamp);
    const todayTimestamp = moment(currentTime);
    const minuteDateDifference = todayTimestamp.diff(lastUpdatedTSMoment, 'minutes');
    const hourDateDifference = todayTimestamp.diff(lastUpdatedTSMoment, 'hours');
    const dayDateDifference = todayTimestamp.diff(lastUpdatedTSMoment, 'days');

    const openZillow = () => {
        recordEvent("View Zillow Listing", {
            zpid: property.zpid
        })
        window.open(property.zillowlistingUrl, "_blank");
    };

    return (
        <div className="last-updated-outer-container">
            <div className="last-updated-inner-container">
                <h2 className="body-regular colour-primary">
                    Updated{" "}
                    {
                        hourDateDifference < 1 ?
                        `${minuteDateDifference} minute${minuteDateDifference === 1 ? "" : "s"} ago`
                        :
                        hourDateDifference > 23 ?
                        `${dayDateDifference} day${dayDateDifference === 1 ? "" : "s"} ago`
                        :
                        `${hourDateDifference} hour${hourDateDifference === 1 ? "" : "s"} ago`
                    }
                </h2>
                <div className="last-updated-body-row">
                    <span className="body-regular colour-secondary">
                        Our data updates daily.{" "}
                        {
                            property.zillowlistingUrl !== undefined &&
                            `Consider verifying status on Zillow.`
                        }
                    </span>
                    <div className="last-updated-zillow-link-row">
                        <span 
                            className="body-regular-underline colour-link"
                            onClick={() => openZillow()}
                        >
                            Zillow listing
                        </span>
                        <RedirectArrow
                            fill={colour.blueBlue03}
                            className="cursor-pointer"
                            clickFunc={() => openZillow()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LastUpdated;