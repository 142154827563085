import React from 'react';

function Heating(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16"
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M9.08252 0.0287238C9.23533 -0.068276 9.4194 0.0976692 9.35734 0.268908C8.50929 2.60882 9.10633 4.35516 9.70034 6.09263C10.052 7.12132 10.4027 8.14691 10.4517 9.29074C10.5734 12.1296 9.65875 13.9263 6.43446 15.9709C6.28615 16.0649 6.10491 15.9153 6.16168 15.7479C7.60407 11.4949 6.91772 9.79478 6.17012 7.94297C5.95184 7.40228 5.72833 6.84865 5.55107 6.21477C4.79977 3.52813 6.8951 1.4173 9.08252 0.0287238ZM3.21522 2.0902C3.33305 2.01611 3.47499 2.14286 3.42713 2.27366C2.77321 4.06099 3.23358 5.39492 3.69162 6.72208C3.96281 7.50785 4.23317 8.29124 4.27097 9.16495C4.36479 11.3334 3.65955 12.7058 1.17333 14.2675C1.05897 14.3394 0.91922 14.225 0.962995 14.0972C2.07521 10.8486 1.54597 9.54996 0.969505 8.13546C0.801189 7.72245 0.628846 7.29957 0.492162 6.81538C-0.0871572 4.7632 1.52853 3.15085 3.21522 2.0902ZM14.776 2.27366C14.8238 2.14286 14.6819 2.01611 14.5641 2.0902C12.8774 3.15085 11.2617 4.7632 11.841 6.81538C11.9777 7.29957 12.15 7.72245 12.3183 8.13546C12.8948 9.54995 13.424 10.8486 12.3118 14.0972C12.2681 14.225 12.4078 14.3394 12.5222 14.2675C15.0084 12.7058 15.7136 11.3334 15.6198 9.16495C15.582 8.29124 15.3116 7.50785 15.0405 6.72208C14.5824 5.39492 14.122 4.06099 14.776 2.27366Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Heating;