import React from 'react';
import '../styles/Checkbox.css';

function Checkbox(props) {
    const label = props.label;
    const selected = props.selected;
    const type = props.type;
    const disabled = props.disabled;
    const cta = props.cta;

    return (
        <div className="checkbox-outer-container">
            <div className="checkbox-inner-container">
                <div 
                    className={"checkbox-container " + (disabled === true ? "disabled" : "")}
                    onClick={() => disabled === true ? null : cta()}
                >
                    {
                        selected === true ?
                        <div className="checkbox-icon">
                        </div>
                        :
                        null
                    }
                </div>
                <span className={(type === "default" ? "body-regular" : "body-semibold") + " colour-primary"}>
                    {label}
                </span>
            </div>
        </div>
    )
};

export default Checkbox;