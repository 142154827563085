import React from 'react';

function RedirectArrow(props) {
    const fill = props.fill;
    const className = props.className;
    const clickFunc = props.clickFunc;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="9" 
            height="8" 
            viewBox="0 0 9 8" 
            fill="none"
            className={className}
            onClick={clickFunc === null ? null : () => clickFunc()}
        >
            <path 
                d="M8.3335 0.72392V7.23918C8.3335 7.63899 8.00939 7.9631 7.60958 7.9631C7.20977 7.9631 6.88566 7.63899 6.88566 7.23919L6.88566 2.47161L1.5693 7.78797C1.28659 8.07068 0.828235 8.07068 0.545527 7.78797C0.262819 7.50526 0.262819 7.0469 0.545527 6.76419L5.86188 1.44784L1.09431 1.44784C0.694501 1.44784 0.370392 1.12373 0.370392 0.723919C0.370392 0.324109 0.694502 0 1.09431 0L7.60958 1.07131e-06C8.00939 1.07131e-06 8.3335 0.324111 8.3335 0.72392Z" 
                fill={fill}
            />
        </svg>
    )
};

export default RedirectArrow;