import React from 'react';

function Arrow(props) {
    const fill = props.fill;
    const className = props.className;
    
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="8" 
            height="8" 
            viewBox="0 0 8 8" 
            fill="none"
            className={className}
        >
            <path 
                d="M4.36274 7.84168L7.80272 4.4017C8.01381 4.19061 8.01381 3.84835 7.80272 3.63726C7.59162 3.42617 7.24937 3.42617 7.03828 3.63726L4.52106 6.15448L4.52106 0.540541C4.52106 0.242008 4.27905 0 3.98052 0C3.68199 0 3.43998 0.242008 3.43998 0.540541L3.43998 6.15448L0.922761 3.63726C0.711666 3.42617 0.369415 3.42617 0.158321 3.63726C-0.0527736 3.84836 -0.0527735 4.19061 0.158321 4.4017L3.5983 7.84168C3.80939 8.05277 4.15165 8.05277 4.36274 7.84168Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Arrow;