import React, { useEffect, useState, useRef } from 'react';
import '../styles/Settings.css';
import { Header, Footer, SideNavigation, Loading, ExitScreen } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, getDocument, getUserDocuments, setData, formatCityNames, checkNaN } from '../functions';
import { Button, HorizontalTab, Modal, SettingsSection, VerticalTab } from "../v4/components";
import { colour, defaultUserData } from '../styles/GlobalStyles';
import { Setup01Icon, Invoice03Icon } from 'hugeicons-react';
import { Arrow }  from '../assets/icons';
import { MarketSearch } from '../v4/modalbody';
import { counties } from '../counties';

function Settings() {

    const [userData, setUserData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userId, setUserId] = useState(null);
    const [preferences, setPreferences] = useState(defaultUserData.settings);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedPanel, setSelectedPanel] = useState(0);
    const [defaultInsurance, setDefaultInsurance] = useState(preferences.recurringCosts[0].value);
    const [insurance, setInsurance] = useState(preferences.recurringCosts[0].value);
    const [defaultPropertyTaxes, setDefaultPropertyTaxes] = useState(preferences.recurringCosts[1].value);
    const [propertyTaxes, setPropertyTaxes] = useState(preferences.recurringCosts[1].value);
    const [defaultVacancyRate, setDefaultVacancyRate] = useState(preferences.recurringCosts[2].value);
    const [vacancyRate, setVacancyRate] = useState(preferences.recurringCosts[2].value);
    const [defaultManagementFees, setDefaultManagementFees] = useState(preferences.recurringCosts[3].value);
    const [managementFees, setManagementFees] = useState(preferences.recurringCosts[3].value);
    const [defaultMaintenance, setDefaultMaintenance] = useState(preferences.recurringCosts[4].value);
    const [maintenance, setMaintenance] = useState(preferences.recurringCosts[4].value);
    const [defaultOtherCosts, setDefaultOtherCosts] = useState(preferences.recurringCosts[5].value);
    const [otherCosts, setOtherCosts] = useState(preferences.recurringCosts[5].value);
    const [defaultDownPayment, setDefaultDownPayment] = useState(preferences.initialCosts[0].value);
    const [downPayment, setDownPayment] = useState(preferences.initialCosts[0].value);
    const [defaultInterestRate, setDefaultInterestRate] = useState(preferences.initialCosts[1].value);
    const [interestRate, setInterestRate] = useState(preferences.initialCosts[1].value);
    const [defaultLoanType, setDefaultLoanType] = useState(preferences.loanType);
    const [loanType, setLoanType] = useState(preferences.loanType);
    const [defaultMortgage, setDefaultMortgage] = useState(preferences.mortgage);
    const [mortgage, setMortgage] = useState(preferences.mortgage);
    const [defaultRehabCost, setDefaultRehabCost] = useState(preferences.rehabCosts.value);
    const [rehabCost, setRehabCost] = useState(preferences.rehabCosts.value);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loanTypeDropdownOpen, setLoanTypeDropdownOpen] = useState(false);
    const [mobileOption, setMobileOption] = useState(null);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [customCities, setCustomCities] = useState([]);
    const [defaultCustomCities, setDefaultCustomCities] = useState([]);
    const [customCityDropdownOpen, setCustomCityDropdownOpen] = useState(null);
    const [marketModal, setMarketModal] = useState(false);
    const [city, setCity] = useState("");
    const [cityCharacters, setCityCharacters] = useState(null);
    const [cityNames, setCityNames] = useState([]);
    const [finalResults, setFinalResults] = useState([]);
    const [activeSearching, setActiveSearching] = useState(false);
    // const [scrollPosition, setScrollPosition] = useState(0);
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();
    const rightSectionRef = useRef(null);
    const sectionScrollPositions = [0, 1000, 1600, 2000];

    const saveLoanType = (val) => {
        setLoanType(val);
        setLoanTypeDropdownOpen(false);
    };

    const changeSetting = (text, setVal) => {
        if ( checkNaN(Number(text)) === true ) {
            return
        }
        setVal(text);
    };

    const tabs = [
        {
			label: "Financial",
			icon: <Setup01Icon size={16} color={selectedTab === 0 ? colour.grayScaleBlack : colour.blueBlue03} />,
			selected: selectedTab === 0 ? true : false,
			disabled: false,
            title: "Financial preferences",
            subtitle: "Manage how finances are calculated. Settings will automatically apply across all properties.",
            panels: [
                {
                    active: selectedPanel === 0 ? true : false,
                    title: "Monthly costs",
                    subtitle: null,
                    icon: null,
                    fields: [
                        {
                            title: "Insurance",
                            description: "The default is based on the average for each city according to Zillow.",
                            placeholder: "city based",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: insurance === defaultInsurance ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: insurance,
                            defaultVal: defaultUserData.settings.recurringCosts[0].value,
                            savedVal: defaultInsurance,
                            onChange: (text) => changeSetting(text, setInsurance),
                            cta: () => saveSettings("recurringCosts", 0, insurance, setDefaultInsurance)
                        },
                        {
                            title: "Property taxes",
                            description: "Based on Zillow's property tax estimates. This usually does NOT take into account any tax increase after you purchase the property. Please check county websites for this data (we're working on aggregating them!)",
                            placeholder: "city based",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: propertyTaxes === defaultPropertyTaxes ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: propertyTaxes,
                            defaultVal: defaultUserData.settings.recurringCosts[1].value,
                            savedVal: defaultPropertyTaxes,
                            onChange: (text) => changeSetting(text, setPropertyTaxes),
                            cta: () => saveSettings("recurringCosts", 1, propertyTaxes, setDefaultPropertyTaxes)
                        },
                        {
                            title: "Vacancy rate allocation",
                            description: "Based on the FRED (Federal Reserve Economic Data) average vacancy rate for the city.",
                            placeholder: "city based",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: vacancyRate === defaultVacancyRate ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: vacancyRate,
                            defaultVal: defaultUserData.settings.recurringCosts[2].value,
                            savedVal: defaultVacancyRate,
                            onChange: (text) => changeSetting(text, setVacancyRate),
                            cta: () => saveSettings("recurringCosts", 2, vacancyRate, setDefaultVacancyRate)
                        },
                        {
                            title: "Management fees",
                            description: "The default is 9% of your rental income.",
                            placeholder: "9",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: managementFees === defaultManagementFees ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: managementFees,
                            defaultVal: defaultUserData.settings.recurringCosts[3].value,
                            savedVal: defaultManagementFees,
                            onChange: (text) => changeSetting(text, setManagementFees),
                            cta: () => saveSettings("recurringCosts", 3, managementFees, setDefaultManagementFees)
                        },
                        {
                            title: "Maintenance",
                            description: "The default is 2% of your rental income.",
                            placeholder: "2",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: maintenance === defaultMaintenance ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: maintenance,
                            defaultVal: defaultUserData.settings.recurringCosts[4].value,
                            savedVal: defaultMaintenance,
                            onChange: (text) => changeSetting(text, setMaintenance),
                            cta: () => saveSettings("recurringCosts", 4, maintenance, setDefaultMaintenance)
                        },
                        {
                            title: "Other costs",
                            description: "Account for any additional monthly recurring costs.",
                            placeholder: "0",
                            defaultSwitch: false,
                            dropdown: false,
                            input: true,
                            disabled: otherCosts === defaultOtherCosts ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: otherCosts,
                            defaultVal: defaultUserData.settings.recurringCosts[5].value,
                            savedVal: defaultOtherCosts,
                            onChange: (text) => changeSetting(text, setOtherCosts),
                            cta: () => saveSettings("recurringCosts", 5, otherCosts, setDefaultOtherCosts)
                        }
                    ]
                },
                {
                    active: selectedPanel === 1 ? true : false,
                    title: "Initial costs",
                    subtitle: null,
                    icon: null,
                    fields: [
                        {
                            title: "Payment type",
                            description: null,
                            defaultSwitch: false,
                            chip: true,
                            dropdown: false,
                            input: false,
                            disabled: mortgage === defaultMortgage ? true : false,
                            size: "large",
                            defaultVal: defaultUserData.settings.mortgage,
                            savedVal: defaultMortgage,
                            onChange: (val) => changeSetting(val, setMortgage),
                            cta: () => saveSettings("mortgage", null, mortgage, setDefaultMortgage),
                            options: [
                                {
                                    label: "Mortgage",
                                    value: true,
                                    included: mortgage
                                },
                                {
                                    label: "Cash",
                                    value: false,
                                    included: !mortgage
                                },
                            ]
                        },
                        {
                            title: "Down payment",
                            description: "The default is 20% down.",
                            placeholder: "20",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: downPayment === defaultDownPayment ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: downPayment,
                            defaultVal: defaultUserData.settings.initialCosts[0].value,
                            savedVal: defaultDownPayment,
                            onChange: (text) => changeSetting(text === "20" ? "" : text, setDownPayment),
                            cta: () => saveSettings("initialCosts", 0, downPayment, setDefaultDownPayment)
                        },
                        {
                            title: "Interest rate",
                            description: "The default is based on FRED's average for 30 year fixed-rate mortgages & automatically updates weekly.",
                            placeholder: "FRED",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: interestRate === defaultInterestRate ? true : false,
                            size: "medium",
                            leftUnit: null,
                            rightUnit: "%",
                            value: interestRate,
                            defaultVal: defaultUserData.settings.initialCosts[1].value,
                            savedVal: defaultInterestRate,
                            onChange: (text) => changeSetting(text, setInterestRate),
                            cta: () => saveSettings("initialCosts", 1, interestRate, setDefaultMortgage)
                        },
                        {
                            title: "Loan type",
                            description: null,
                            defaultSwitch: false,
                            dropdown: true,
                            input: false,
                            disabled: loanType === defaultLoanType ? true : false,
                            size: "medium",
                            value: loanType,
                            defaultVal: defaultUserData.settings.loanType,
                            savedVal: defaultLoanType,
                            onChange: (val) => saveLoanType(val),
                            cta: () => saveSettings("loanType", null, loanType, setDefaultLoanType),
                            options: [
                                {
                                    label: "40 Year Fixed",
                                    value: "40",
                                    selected: loanType === "40" ? true : false
                                },
                                {
                                    label: "30 Year Fixed",
                                    value: "30",
                                    selected: loanType === "30" ? true : false
                                },
                                {
                                    label: "25 Year Fixed",
                                    value: "25",
                                    selected: loanType === "25" ? true : false
                                },
                                {
                                    label: "20 Year Fixed",
                                    value: "20",
                                    selected: loanType === "20" ? true : false
                                },
                                {
                                    label: "15 Year Fixed",
                                    value: "15",
                                    selected: loanType === "15" ? true : false
                                }
                            ]
                        }
                    ]
                },
                {
                    active: selectedPanel === 2 ? true : false,
                    title: "Rehab",
                    subtitle: null,
                    icon: null,
                    fields: [
                        {
                            title: "Rehab cost per sqaure foot",
                            description: `Set a default price per square foot rehab estimate. This will only apply to properties that are marked as "Needs Work" or "Gut Rehab".`,
                            placeholder: "0",
                            defaultSwitch: true,
                            dropdown: false,
                            input: true,
                            disabled: rehabCost === defaultRehabCost ? true : false,
                            size: "medium",
                            leftUnit: "$",
                            rightUnit: null,
                            value: rehabCost,
                            defaultVal: defaultUserData.settings.rehabCosts.value,
                            savedVal: defaultRehabCost,
                            onChange: (text) => changeSetting(text, setRehabCost),
                            cta: () => saveSettings("rehabCosts", null, rehabCost, setDefaultRehabCost)
                        }
                    ]
                },
                {
                    active: selectedPanel === 3 ? true : false,
                    title: "Custom",
                    subtitle: "Apply individual settings to a specific markets.",
                    icon: null,
                    fields: customCities
                }
            ]
		},
        {
			label: "Offer letters",
			icon: <Invoice03Icon size={16} color={selectedTab === 1 ? colour.grayScaleBlack : colour.blueBlue03} />,
			selected: selectedTab === 1 ? true : false,
			disabled: true,
            title: "",
            subtitle: "",
            panels: []
		}
    ];

    useEffect(() => {
        document.title = "Settings | Coffee Clozers";

        const fetchUserData = async() => {
            const collections = ["Users", "Subscriptions", "Financial Preferences"];
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                setUserId(docRef);
                const queryData = await getUserDocuments(collections, docRef);
                if ( queryData[0].status === 200 ) {
                    const data = queryData[0].data;
                    setUserData(data);
                }

                if ( queryData[1].status === 200 ) {
                    const data = queryData[1].data;
                    setSubscriptions(data);
                }

                if ( queryData[2].status === 200 ) {
                    const data = queryData[2].data;
                    setPreferences(data);

                    setDefaultInsurance(data.recurringCosts[0].value);
                    setInsurance(data.recurringCosts[0].value);

                    setDefaultPropertyTaxes(data.recurringCosts[1].value);
                    setPropertyTaxes(data.recurringCosts[1].value);

                    setDefaultVacancyRate(data.recurringCosts[2].value);
                    setVacancyRate(data.recurringCosts[2].value);

                    setDefaultManagementFees(data.recurringCosts[3].value);
                    setManagementFees(data.recurringCosts[3].value);

                    setDefaultMaintenance(data.recurringCosts[4].value);
                    setMaintenance(data.recurringCosts[4].value);

                    setDefaultOtherCosts(data.recurringCosts[5].value);
                    setOtherCosts(data.recurringCosts[5].value);

                    setDefaultDownPayment(data.initialCosts[0].value);
                    if ( data.initialCosts[0].value === 20 || data.initialCosts[0].value === "20" ) {
                        setDownPayment("");
                    }
                    else {
                        setDownPayment(data.initialCosts[0].value);
                    }

                    setDefaultInterestRate(data.initialCosts[1].value === "0" ? "" : data.initialCosts[1].value);
                    setInterestRate(data.initialCosts[1].value === "0" ? "" : data.initialCosts[1].value);

                    setDefaultLoanType(data.loanType);
                    setLoanType(data.loanType);

                    setDefaultMortgage(data.mortgage);
                    setMortgage(data.mortgage);

                    setDefaultRehabCost(data.rehabCosts.value);
                    setRehabCost(data.rehabCosts.value);

                    setCustomCities(data.customCities);

                    const cloneVal = structuredClone(data.customCities);
                    setDefaultCustomCities(cloneVal);
                }
                setLoading(false);
            }
            else {
                navigate("/sign-up");
                setLoading(false);
            }
        };

        fetchUserData();

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, [navigate, selectedPanel]);

    const backStep = () => {
        if ( mobileOption === null ) {
            navigate("/profile");
        }
        else {
            setMobileOption(null);
        }
    };

    const changeTab = (index) => {
        setSelectedTab(index);
        setSelectedPanel(0);
    };

    const selectPanel = (index) => {
        setSelectedPanel(index);
        if ( index === 0 ) {
            rightSectionRef.current.scrollTo({
                top: sectionScrollPositions[0],
                behavior: 'smooth'
            });
        }
        else if ( index === 1 ) {
            rightSectionRef.current.scrollTo({
                top: sectionScrollPositions[1],
                behavior: 'smooth'
            });
        }
        else if ( index === 2 ) {
            rightSectionRef.current.scrollTo({
                top: sectionScrollPositions[2],
                behavior: 'smooth'
            });
        }
        else if ( index === 3 ) {
            rightSectionRef.current.scrollTo({
                top: sectionScrollPositions[3],
                behavior: 'smooth'
            });
        }
        window.scrollTo(0,0);
    };

    const selectMobileOption = (panel, index, customSetting) => {
        setMobileOption([panel, index, customSetting]);
        window.scrollTo(0, 0);
    };

    const closeDropdowns = () => {
        setLoanTypeDropdownOpen(false);
        setCustomCityDropdownOpen(null);
    };

    const customSettingChangeVal = (val, cityTitle, fieldTitle) => {
        if ( checkNaN(Number(val)) === true ) {
            return
        }

        const newCustomCities = structuredClone(customCities);
        const findCityIndex = newCustomCities.findIndex(e => e.label === cityTitle);
        if ( findCityIndex !== -1 ) {
            const relevantCity = newCustomCities[findCityIndex].settings;
            const findFieldIndex = relevantCity.findIndex(e => e.title === fieldTitle);
            if ( findFieldIndex !== -1 ) {
                newCustomCities[findCityIndex].settings[findFieldIndex].value = val;
                setCustomCities(newCustomCities);
            }
        }
    };

    const openMarketModal = () => {
        setMarketModal(true);
    };

    const closeMarketModal = () => {
        setMarketModal(false);
    };

    const changeCity = (val) => {
        setCity(val);

        if ( val === "" ) {
            setFinalResults([]);
        }
        else {
            searchCities(val);
        }
    };

    const searchCities = async(val) => {
		if ( val.length >= 3 ) {
			const newVal = val.slice(0, 3).toUpperCase();
			if ( newVal === cityCharacters ) {
				matchUpCities(val, cityNames);
			}
			else {
				setActiveSearching(true);
				setCityCharacters(newVal);
				
				// Query city names
				const colRef = "City Names";
				const queryData = await getDocument(colRef, newVal);
				if ( queryData.status === 200 ) {
					const data = queryData.data.data;
                    const filteredRegions = data.filter(e => e.regionId !== null);
					setCityNames(filteredRegions);
					matchUpCities(val, filteredRegions);
				}
				setActiveSearching(false);
			}
		}
		else {
			setCityCharacters(null);
			setCityNames([]);
			searchCounties(val, []);
		}
	};

	const matchUpCities = (val, data) => {
		const citiesClone = [...data];
		const newArray = [];
		const lowerCaseVal = val.toLowerCase();

		for (let index = 0; index < citiesClone.length; index++) {
			const element = citiesClone[index];
			const cityName = element.regionName;

			const mainCityFullName = cityName.replace(".", "").replace(",", "").toLowerCase();
			const mainCityFullNameComma = cityName.replace(".", "").toLowerCase();
			const noFullStopVal = lowerCaseVal.replace(".", "");

			if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) ) {
				element.label = element.regionName;
				element.type = "city";
				newArray.push(element);
			}
		};
		const sortedData = newArray.sort((a, b) => a.regionName.localeCompare(b.regionName));
		searchCounties(val, sortedData);
	};

	const searchCounties = (val, cityData) => {
		const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
				counties[index].label = formatCityNames(element);
				counties[index].type = "county";
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
					counties[index].label = formatCityNames(element);
					counties[index].type = "county";
                    newArray.push(counties[index]);
                }
            }
        };

        const conjoinedArray = [
			{
				label: "CITY",
				results: cityData.slice(0, 2)
			},
			{
				label: "COUNTY",
				results: newArray
			}
		];

		if ( conjoinedArray[0].results.length === 0 && conjoinedArray[1].results.length === 0 ) {
			setFinalResults([]);
		}
		else {
			setFinalResults(conjoinedArray);
		}
    };

    const pickGeography = (item) => {
        changeCity("");
        const cityIndex = customCities.findIndex(e => e.label === item.label);
        if ( cityIndex === -1 ) {
            const newCustomCity = {
                label: item.label,
                msaCode: item.type === "county" ? `CTY${item.code}` : item.regionId,
                settings: [
                    {
                        title: "",
                        value: ""
                    }
                ]
            };
            const newCustomCities = [...customCities];
            newCustomCities.push(newCustomCity);
            setCustomCities(newCustomCities);
        }
        closeMarketModal();
    };

    // const updateScrollPosition = (val) => {
    //     if ( loanTypeDropdownOpen === true || customCityDropdownOpen !== null ) {
    //         console.log("BLOCKED");
    //         return;
    //     }
    //     console.log("Not blocked = ", customCityDropdownOpen);

    //     if ( scrollPosition < sectionScrollPositions[1] && val >= sectionScrollPositions[1] ) {
    //         setScrollPosition(val.target.scrollTop);
    //     }
    //     else {

    //     }
    // };

    const deleteCustomCity = async(index) => {
        setSaveLoading(true);
        const newCustomCities = [...customCities];
        const deleteCityLabel = newCustomCities[index].label;
        const colRef = "Financial Preferences";
        const queryData = await getDocument(colRef, userId);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            const savedCustomCities = data.customCities;

            const savedCityIndex = savedCustomCities.findIndex(e => e.label === deleteCityLabel);
            if ( savedCityIndex !== -1 ) {
                savedCustomCities.splice(savedCityIndex, 1);
            }

            setCustomCities(savedCustomCities);
            const cloneData = structuredClone(savedCustomCities);
            setDefaultCustomCities(cloneData);
            data.customCities = savedCustomCities;
            await setData(colRef, userId, data);
        }
        setSaveLoading(false);
    };

    const saveCustomSetting = async(cityTitle, fieldTitle) => {
        setSaveLoading(true);
        const newCustomCities = [...customCities];
        const findCityIndex = newCustomCities.findIndex(e => e.label === cityTitle)
        if ( findCityIndex !== -1 ) {
            const relevantCity = newCustomCities[findCityIndex].settings;
            const findFieldIndex = relevantCity.findIndex(e => e.title === fieldTitle);

            if ( findFieldIndex !== -1 || fieldTitle === "all" ) {

                const colRef = "Financial Preferences";
                const queryData = await getDocument(colRef, userId);
                if ( queryData.status === 200 ) {
                    const data = queryData.data.data;
                    const savedCustomCities = data.customCities;

                    const findSavedCityIndex = savedCustomCities.findIndex(e => e.label === cityTitle);
                    if ( findSavedCityIndex === -1 ) {
                        // The city doesn't already exist -> create the city and save ONLY the new object

                        const newObject = newCustomCities[findCityIndex];
                        savedCustomCities.push(newObject);
                        data.customCities = savedCustomCities;
                    }
                    else {
                        // The city does exist -> overwrite/create ONLY the new object

                        const newSetting = fieldTitle === "all" ? null : {
                            title: newCustomCities[findCityIndex].settings[findFieldIndex].title,
                            value: newCustomCities[findCityIndex].settings[findFieldIndex].value
                        }

                        const savedCustomSettings = savedCustomCities[findSavedCityIndex].settings;
                        const settingIndex = savedCustomSettings.findIndex(e => e.title === fieldTitle);
                        
                        if ( fieldTitle === "all" ) {
                            data.customCities[findSavedCityIndex] = newCustomCities[findCityIndex];
                        }
                        else if ( settingIndex === -1 ) {
                            data.customCities[findSavedCityIndex].settings.push(newSetting);
                        }
                        else if ( newSetting.value === "" ) {
                            if ( data.customCities[findSavedCityIndex].settings.length === 1 ) {
                                data.customCities.splice(findSavedCityIndex, 1);
                            }
                            else {
                                data.customCities[findSavedCityIndex].settings.splice(settingIndex, 1);
                            }
                        }
                        else {
                            data.customCities[findSavedCityIndex].settings[settingIndex] = newSetting;
                        }
                    }

                    await setData(colRef, userId, data);
                    setMobileOption(null);

                    const newVal = [...data.customCities];
                    setCustomCities(newVal);

                    const clone = structuredClone(newVal);
                    setDefaultCustomCities(clone);
                }
            }
        }

        setSaveLoading(false);
    };

    const saveSettings = async(field, subfield, val, setDefaultVal) => {
        setSaveLoading(true);
        const colRef = "Financial Preferences";
        const queryData = await getDocument(colRef, userId);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;

            if ( subfield === null && field !== "rehabCosts" ) {
                data[field] = val;
            }
            else if ( subfield === null && field === "rehabCosts" ) {
                data[field].value = val;
                if ( val === 0 || val === "" ) {
                    data[field].default = true;
                }
                else {
                    data[field].default = false;
                }
            }
            else {
                data[field][subfield].value = val;

                if ( field === "initialCosts" && subfield === 0 && val === "100" ) {
                    data.mortgage = false;
                    setMortgage(false);
                    setDefaultMortgage(false);
                }
                else if ( field === "initialCosts" && subfield === 0 && val !== "100" ) {
                    data.mortgage = true;
                    setMortgage(true);
                    setDefaultMortgage(true);
                }

                if ( defaultUserData.settings[field][subfield].value === val ) {
                    data[field][subfield].default = true;
                }
                else {
                    data[field][subfield].default = false;
                }
            }

            if ( field === "mortgage" && val === false ) {
                data.initialCosts[0].value = "100";
                setDownPayment(100);
                setDefaultDownPayment(100);
            }
            else if ( field === "mortgage" && val === true ) {
                data.initialCosts[0].value = "20";
                setDownPayment("");
                setDefaultDownPayment("");
            }

            setDefaultVal(val);
            await setData(colRef, userId, data);
            setMobileOption(null);
        }
        setSaveLoading(false);
    };

    return (
        <div className={"settings-outer-container bg-colour-white " + (mobileOption !== null && innerWidth < 1000 && mobileOption[2] === true ? "settings-custom-mobile-section-active " : "")}>
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={!marketModal}
                desktopControl={true}
            />
            <div className={"settings-inner-container relative-container " + (mobileOption !== null && innerWidth < 1000 ? "settings-mobile-section-active " : "") + (customCityDropdownOpen !== null ? "settings-custom-dropdown-open" : "")}>
                {
                    (loanTypeDropdownOpen === true || customCityDropdownOpen !== null) &&
                    <ExitScreen
                        clickScreen={() => closeDropdowns()}
                    />
                }
                {
                    marketModal === true && innerWidth < 1000 &&
                    <div className="dark-bg">
                    </div>
                }
                {
                    marketModal === true && innerWidth < 1000 &&
                    <Modal
                        heading="Custom settings"
                        subheading={"Apply individual settings to a specific markets."}
                        body={<MarketSearch city={city} changeCity={changeCity} finalResults={finalResults} activeSearching={activeSearching} pickGeography={pickGeography} extraPadding={false} />}
                        type={"basic"}
                        cancelText={"Cancel"}
                        cancelButtonCTA={closeMarketModal}
                        actionButtonText={"Continue"}
                        actionButtonDisabled={false}
                        actionButtonCTA={null}
                        secondaryButtonText=""
                        secondaryButtonCTA={null}
                        exitButtonCTA={closeMarketModal}
                        loading={false}
                        leftIcon={null}
                        rightIcon={null}
                        cancelLeftIcon={null}
                        cancelRightIcon={null}
                        customClass={"custom-setting-mobile-modal"}
                    />
                }
                <SideNavigation
					navigate={navigate}
					state={state}
					userData={userData}
					subscriptions={subscriptions}
				/>
                <div className="settings-body-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        <div className="settings-inner-body-container">
                            {
                                saveLoading === true &&
                                <Loading />
                            }
                            <div className="settings-title-container mobile-none">
                                <h1 className="heading-large-semibold colour-primary">
                                    Settings
                                </h1>
                                <div className="settings-title-tabs-row">
                                    {
                                        tabs.map((item, index) =>
                                            <HorizontalTab
                                                label={item.label}
                                                active={item.selected}
                                                icon={item.icon}
                                                disabled={item.disabled}
                                                func={() => changeTab(index)}
                                                standardIcon={false}
                                                key={index}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="settings-section-row">
                                <div className="settings-left-section-container">
                                    <div 
                                        className="text-button button-row gap-x-small desktop-none"
                                        onClick={() => backStep()}    
                                    >
                                        <Arrow
                                            fill={colour.blueBlue03}
                                            className="settings-back-arrow"
                                        />
                                        <span className="body-regular text-link">
                                            Back
                                        </span>
                                    </div>
                                    {
                                        innerWidth < 1000 && mobileOption !== null ?
                                        null
                                        :
                                        <div className="settings-left-section-title-container">
                                            <h2 className="heading-small-semibold colour-primary">
                                                {tabs[selectedTab].title}
                                            </h2>
                                            <span className="body-regular colour-secondary">
                                                {tabs[selectedTab].subtitle}
                                            </span>
                                        </div>
                                    }
                                    <div className="settings-left-section-panels-container mobile-none">
                                        {
                                            tabs[selectedTab].panels.map((item, index) =>
                                                <VerticalTab
                                                    selected={item.active}
                                                    label={item.title}
                                                    icon={item.icon}
                                                    cta={() => selectPanel(index)}
                                                    external={false}
                                                    account={false}
                                                    userData={null}
                                                    key={index}
                                                    standardIcon={false}
                                                    badge={false}
                                                    reset={false}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div 
                                    className="settings-right-section-container"
                                    ref={rightSectionRef}
                                    // onScroll={(val) => updateScrollPosition(val)}
                                >
                                    {
                                        mobileOption !== null && mobileOption[0] !== 0 && innerWidth < 1000 ?
                                        null
                                        :
                                        <SettingsSection
                                            title={tabs[selectedTab].panels[0].title}
                                            subtitle={tabs[selectedTab].panels[0].subtitle}
                                            data={tabs[selectedTab].panels[0].fields}
                                            sectionRef={null}
                                            dropdownOpen={false}
                                            setDropdownOpen={null}
                                            selectedPanel={0}
                                            mobileOption={mobileOption}
                                            selectMobileOption={selectMobileOption}
                                            innerWidth={innerWidth}
                                            customCities={customCities}
                                            setCustomCities={setCustomCities}
                                            customCityDropdownOpen={customCityDropdownOpen}
                                            setCustomCityDropdownOpen={setCustomCityDropdownOpen}
                                            customSettingChangeVal={null}
                                            saveCustomSetting={null}
                                            defaultCustomCities={[]}
                                            deleteCustomCity={null}
                                            openMarketModal={null}
                                            city={city}
                                            finalResults={finalResults}
                                            activeSearching={activeSearching}
                                            changeCity={changeCity}
                                            pickGeography={pickGeography}
                                        />
                                    }
                                    {
                                        mobileOption === null &&
                                        <div className="settings-spacing-container desktop-none"></div>
                                    }
                                    {
                                        mobileOption !== null && mobileOption[0] !== 1 && innerWidth < 1000 ?
                                        null
                                        :
                                        <SettingsSection
                                            title={tabs[selectedTab].panels[1].title}
                                            subtitle={tabs[selectedTab].panels[1].subtitle}
                                            data={tabs[selectedTab].panels[1].fields}
                                            sectionRef={null}
                                            dropdownOpen={loanTypeDropdownOpen}
                                            setDropdownOpen={setLoanTypeDropdownOpen}
                                            selectedPanel={1}
                                            mobileOption={mobileOption}
                                            selectMobileOption={selectMobileOption}
                                            innerWidth={innerWidth}
                                            customCities={customCities}
                                            setCustomCities={setCustomCities}
                                            customCityDropdownOpen={customCityDropdownOpen}
                                            setCustomCityDropdownOpen={setCustomCityDropdownOpen}
                                            customSettingChangeVal={null}
                                            saveCustomSetting={null}
                                            defaultCustomCities={[]}
                                            deleteCustomCity={null}
                                            openMarketModal={null}
                                            city={city}
                                            finalResults={finalResults}
                                            activeSearching={activeSearching}
                                            changeCity={changeCity}
                                            pickGeography={pickGeography}
                                        />
                                    }
                                    {
                                        mobileOption === null &&
                                        <div className="settings-spacing-container desktop-none"></div>
                                    }
                                    {
                                        mobileOption !== null && mobileOption[0] !== 2 && innerWidth < 1000 ?
                                        null
                                        :
                                        <SettingsSection
                                            title={tabs[selectedTab].panels[2].title}
                                            subtitle={tabs[selectedTab].panels[2].subtitle}
                                            data={tabs[selectedTab].panels[2].fields}
                                            sectionRef={null}
                                            dropdownOpen={false}
                                            setDropdownOpen={null}
                                            selectedPanel={2}
                                            mobileOption={mobileOption}
                                            selectMobileOption={selectMobileOption}
                                            innerWidth={innerWidth}
                                            customCities={customCities}
                                            setCustomCities={setCustomCities}
                                            customCityDropdownOpen={customCityDropdownOpen}
                                            setCustomCityDropdownOpen={setCustomCityDropdownOpen}
                                            customSettingChangeVal={null}
                                            saveCustomSetting={null}
                                            defaultCustomCities={[]}
                                            deleteCustomCity={null}
                                            openMarketModal={null}
                                            city={city}
                                            finalResults={finalResults}
                                            activeSearching={activeSearching}
                                            changeCity={changeCity}
                                            pickGeography={pickGeography}
                                        />
                                    }
                                    {
                                        mobileOption === null &&
                                        <div className="settings-spacing-container desktop-none"></div>
                                    }
                                    {
                                        mobileOption !== null && mobileOption[0] !== 3 && innerWidth < 1000 ?
                                        null
                                        :
                                        <SettingsSection
                                            title={tabs[selectedTab].panels[3].title}
                                            subtitle={tabs[selectedTab].panels[3].subtitle}
                                            data={tabs[selectedTab].panels[3].fields}
                                            sectionRef={null}
                                            dropdownOpen={false}
                                            setDropdownOpen={null}
                                            selectedPanel={3}
                                            mobileOption={mobileOption}
                                            selectMobileOption={selectMobileOption}
                                            innerWidth={innerWidth}
                                            customCities={customCities}
                                            setCustomCities={setCustomCities}
                                            customCityDropdownOpen={customCityDropdownOpen}
                                            setCustomCityDropdownOpen={setCustomCityDropdownOpen}
                                            customSettingChangeVal={customSettingChangeVal}
                                            saveCustomSetting={saveCustomSetting}
                                            defaultCustomCities={defaultCustomCities}
                                            deleteCustomCity={deleteCustomCity}
                                            openMarketModal={openMarketModal}
                                            city={city}
                                            finalResults={finalResults}
                                            activeSearching={activeSearching}
                                            changeCity={changeCity}
                                            pickGeography={pickGeography}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    mobileOption !== null && innerWidth < 1000 && customCityDropdownOpen === null &&
                    <div className="settings-section-mobile-sticky-button">
                        <Button
                            buttonType="primary-button"
                            text={"Save"}
                            cta={() => mobileOption[2] === true ? saveCustomSetting(customCities[mobileOption[1]].label, "all") : tabs[selectedTab].panels[mobileOption[0]].fields[mobileOption[1]].cta()}
                            disabled={mobileOption[2] === true ? false : tabs[selectedTab].panels[mobileOption[0]].fields[mobileOption[1]].disabled}
                            leftIcon={null}
                            rightIcon={null}
                            alt=""
                            size={"large"}
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
};

export default Settings;