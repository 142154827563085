import React from 'react';
import '../styles/MapSearchAddress.css';
import { PredictiveSearch, SearchInput } from '../v4/components';
import { MagnifyingGlass } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';

function MapSearchAddress(props) {
    const searchActive = props.searchActive;
    const closeMapSearch = props.closeMapSearch;
    const searchAddress = props.searchAddress;
    const onChangeSearchAddress = props.onChangeSearchAddress;
    const searchAddressResults = props.searchAddressResults;
    const selectAddress = props.selectAddress;
    const activateMapSearch = props.activateMapSearch;
    const googlePlacesLoading = props.googlePlacesLoading;

    return (
        <div 
            className="map-search-address-outer-container"
            onClick={() => searchActive === true ? null : activateMapSearch()}
        >
            <div className="map-search-address-inner-container">
                {
                    searchActive === false ?
                    <MagnifyingGlass
                        fill={colour.blueBlue03}
                        className="map-search-address-icon"
                        clickFunc={null}
                    />
                    :
                    null
                }
                <div className={"map-search-address-input-container " + (searchActive === true ? "map-search-address-active-input-container" : "map-search-address-inactive-input-container")}>
                    <SearchInput
                        value={searchAddress}
                        onChange={(text) => onChangeSearchAddress(text.target.value)}
                        placeholder="Find an address"
                        type="text"
                        clearInput={() => onChangeSearchAddress("")}
                        magnifyingGlassFunc={() => closeMapSearch()}
                    />
                    {
                        (searchAddressResults.length === 0 || searchAddress === "") && googlePlacesLoading === false ?
                        null
                        :
                        <PredictiveSearch
                            loading={googlePlacesLoading}
                            results={searchAddressResults}
                            selectItem={(item) => selectAddress(item)}
                            nested={false}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default MapSearchAddress;