import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function RedirectPage() {
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const params = useParams();
    
    useEffect(() => {
        if ( pathname === "/my-cities" ) {
            navigate("/home");
        }
        else if ( pathname === "/pick-city" ) {
            navigate("/home");
        }
        else if ( pathname.includes("/county-properties-list") ) {
            const cityId = params.id;
            navigate(`/properties-list/${cityId}`);
        }
        else if ( pathname.includes("/metro-properties-list") ) {
            const cityId = params.cityId;
            alert("I'm afraid we no longer support Metro Areas - you can instead view cities or counties. Please feel free to reach out if you have any questions 😊");
            navigate(`/properties-list/${cityId}`);
        }
        else if ( pathname.includes("/quick-report") ) {
            navigate("/home");
        }
        else if ( pathname.includes("/preferences") ) {
            navigate("/settings");
        }

    }, [pathname, navigate, params.id, params.cityId]);

    return (
        <div>
        </div>
    );
};

export default RedirectPage;