import React, { useState } from 'react';
import '../styles/PropertyDetails.css';
import { colour, numberFormatter } from '../styles/GlobalStyles';
import { Bathroom, Bedroom, SingleFamily, MultiFamily, SquareFootage, Units, HourGlass, Condition, PricePerSqFoot, LotSize, Heating, AirConditioning, Parking, ADUBorder, BedroomBorder, CashOnly, Floodzone, Agent, FSBO } from '../assets/icons';
import { Highlights, PropertyCondition } from '../v4/components';
import { checkCondition, checkHighlights } from '../functions';
import { ContactAgent } from '../components';

function PropertyDetails(props) {

	const property = props.property;
	const formatter = props.formatter;
	const [descriptionLength, setDescriptionLength] = useState(250);

	const icons = [
		{
			icon: property.propertyTypeDimension === "Single Family" ? <SingleFamily className="" fill={colour.grayScaleGray03} /> : property.propertyTypeDimension === "Multi Family" ? <MultiFamily className="icon-medium" fill={colour.grayScaleGray03} /> : "",
			text: property.propertyTypeDimension === "Single Family" ? "Single Family" : property.propertyTypeDimension === "Multi Family" ? "Multi-family" : property.propertyTypeDimension === "Townhouse" ? "Townhouse" : property.propertyTypeDimension === "Condo" ? "Condo" : property.propertyTypeDimension === "Manufactured" ? "Manufactured Home" : "",
		},
		{
			icon: property.propertyTypeDimension !== "Multi Family" ? null : <Units className="" fill={colour.grayScaleGray03} />,
			text: property.propertyTypeDimension !== "Multi Family" ? null : `${property.units === null || property.unitsGuess === true ? "N/A" : property.units} Unit`,
		},
		{
			icon: <Bedroom className="" fill={colour.grayScaleGray03} />,
			text: `${property.bedrooms} bed`,
		},
		{
			icon: <Bathroom className="" fill={colour.grayScaleGray03} />,
			text: `${property.bathrooms} bath`,
		},
		{
			icon: <SquareFootage className="" fill={colour.grayScaleGray03} />,
			text: `${property.livingArea !== null ? property.livingArea.toLocaleString() : "0"} sqft`,
		},
		{
			icon: property.onMarket !== false ? <HourGlass className="" fill={colour.grayScaleGray03} /> : null,
			text: property.onMarket !== false ? `${property.dateDifference} days on market` : null,
		}
	];

	const leftGrid = [
		{
			icon: <Condition className="" fill={colour.grayScaleGray03} />,
			text: property.yearBuilt === null ? "N/A" : `Built in ${property.yearBuilt}`
		},
		{
			icon: <PricePerSqFoot className="" fill={colour.grayScaleGray03} />,
			text: `${property.resoFacts.pricePerSqFoot !== null ? formatter.format(property.resoFacts.pricePerSqFoot).replace(".00", "") : "$0"}/sqft`,
		},
		{
			icon: <LotSize className="" fill={colour.grayScaleGray03} />,
			text: property.resoFacts.lotSize !== null ? typeof(property.resoFacts.lotSize) === "number" ? `${numberFormatter.format(property.resoFacts.lotSize)} sqft lot` : property.resoFacts.lotSize : "N/A"
		}
	];

	const rightGrid = [
		{
			icon: <Heating fill={colour.grayScaleGray03} className="" />,
			text: `${property.resoFacts.heating === null ? "None" : Object.keys(property.resoFacts.heating).length === 0 ? "None" : property.resoFacts.heating !== null ? property.resoFacts.heating.join(', ') : "None"}`,
		},
		{
			icon: <AirConditioning fill={colour.grayScaleGray03} className="" />,
			text: `${property.resoFacts.cooling === null ? "None" : Object.keys(property.resoFacts.cooling).length === 0 ? "None" : property.resoFacts.cooling === null ? "None" : property.resoFacts.cooling.join(', ')}`
		},
		{
			icon: <Parking fill={colour.grayScaleGray03} className="" />,
			text: `${property.resoFacts.garageSpaces === null ? "0" : property.resoFacts.garageSpaces} garage spaces`
		}
	];

	const leftLegalGrid = [
		{
			label: "MLS ID",
			value: property.mlsId
		},
		{
			label: "Legal description",
			value: property.legalDescription !== undefined && property.legalDescription !== null ? property.legalDescription : "N/A"
		}
	];

	const rightLegalGrid = [
		{
			label: "Parcel number",
			value: property.parcelId === undefined || property.parcelId === null ? "N/A" : property.parcelId
		},
		{
			label: "Zoning code",
			value: property.zoning
		}
	];

	const opportunities = [
		{
            icon: <ADUBorder className="" fill={colour.grayScaleBlack} />,
            title: "Accessory Dwelling Unit (ADU)",
            description: "This property either has an ADU or is zoned for ADU.",
			active: property.zonedForAdu
        },
        {
            icon: <BedroomBorder className="" fill={colour.grayScaleBlack} />,
            title: "Add a bedroom",
            description: "This property may have space to add a bedroom.",
			active: property.addBedOpportunity
        },
        {
            icon: <CashOnly className="" fill={colour.grayScaleBlack} />,
            title: "Cash only",
            description: "The seller has indicated they want cash only.",
			active: property.cashOnly
        }
	];

	const risks = [
		{
            icon: <Floodzone className="" fill={colour.grayScaleBlack} />,
            title: "Floodzone",
            description: "This property is located in a floodzone.",
			active: property.floodZone
        }
	];

	const listing = [
		{
            icon: <FSBO className="" fill={colour.grayScaleBlack} />,
            title: "For sale by owner (FSBO)",
            description: "The seller listed this property themself on Zillow.",
			active: property.fsbo
        },
		{
			icon: <Agent className="" fill={colour.grayScaleBlack} />,
            title: "Agent listed",
            description: "The property was listed by an agent.",
			active: !property.fsbo
		}
	];

	return (
		<div className="property-details-outer-container">
			<div className="property-details-inner-container">
				<div className="property-details-title-container">
					<h2 className="heading-small-regular colour-primary">
						{property.address.streetAddress}, {property.address.city}, {property.address.state} {property.address.zipcode}
					</h2>
					<div className="property-details-title-icons-row">
						{
							icons.map((item, index) =>
								item.icon !== null &&
								<div
									className="property-details-title-icon-element"
									key={index}
								>
									<div className="property-details-title-icon-container">
										{item.icon}
									</div>
									<div className="property-details-title-text-container">
										<span className="body-regular colour-primary">
											{item.text}
										</span>
									</div>
								</div>
							)
						}
					</div>
				</div>
				<div className="property-spacing-border">
                </div>
				{
					property.propertyCondition === undefined || property.propertyCondition === null || property.propertyCondition === "Unclear" ?
					null
					:
					<PropertyCondition
						property={property}
						condition={checkCondition(property.propertyCondition)}
					/>
				}
				{
					property.description !== undefined && property.description !== null && property.description !== "" &&
					<div className="property-details-description-container">
						<span className="body-regular colour-primary block-text">
							{property.description.slice(0, descriptionLength)}{
								property.description.length > descriptionLength ?
								"..."
								:
								null
							}
						</span>
						{
							property.description.length < 250 ?
							null
							:
							property.description.length > descriptionLength ?
							<div 
								className="property-details-show-more-container"
								onClick={() => setDescriptionLength(property.description.length + 2)}
							>
								<span className="body-regular-underline colour-link">
									Show more
								</span>
							</div>
							:
							<div 
								className="property-details-show-more-container"
								onClick={() => setDescriptionLength(250)}
							>
								<span className="body-regular-underline colour-link">
									Show less
								</span>
							</div>
						}
					</div>
				}
				{
					property.description !== undefined && property.description !== null && property.description !== "" &&
					<div className="property-spacing-border">
					</div>
				}
				<div className="property-details-icons-grid">
					<div className="property-details-left-grid">
						{
							leftGrid.map((item, index) =>
								<div 
									className="property-details-grid-element"
									key={index}
								>
									{item.icon}
									<span className="body-regular colour-primary">
										{item.text}
									</span>
								</div>
							)
						}
					</div>
					<div className="property-details-right-grid">
						{
							rightGrid.map((item, index) =>
								<div 
									className="property-details-grid-element"
									key={index}
								>
									{item.icon}
									<span className="body-regular colour-primary">
										{item.text}
									</span>
								</div>
							)
						}
					</div>
				</div>
				<div className="property-spacing-border">
                </div>
				<div className="property-details-icons-grid property-details-codes-container">
					<div className="property-details-left-grid">
						{
							leftLegalGrid.map((item, index) =>
								<div 
									className="property-details-legal-grid-element"
									key={index}
								>
									<h4 className="body-semibold colour-primary">
										{item.label}
									</h4>
									<span className="body-regular colour-primary">
										{item.value}
									</span>
								</div>
							)
						}
					</div>
					<div className="property-details-right-grid">
						{
							rightLegalGrid.map((item, index) =>
								<div 
									className="property-details-legal-grid-element"
									key={index}
								>
									<h4 className="body-semibold colour-primary">
										{item.label}
									</h4>
									<span className="body-regular colour-primary">
										{item.value}
									</span>
								</div>
							)
						}
					</div>
				</div>
				{
					checkHighlights(opportunities) === false ?
					null
					:
					<div className="property-spacing-border">
                	</div>
				}
				{
					checkHighlights(opportunities) === false ?
					null
					:
					<Highlights
						property={property}
						label="Opportunities"
						elements={opportunities}
						conditionView={false}
					/>
				}
				{
					checkHighlights(risks) === false ?
					null
					:
					<div className="property-spacing-border">
                	</div>
				}
				{
					checkHighlights(risks) === false ?
					null
					:
					<Highlights
						property={property}
						label="Risks"
						elements={risks}
						conditionView={false}
					/>
				}
				{
					checkHighlights(listing) === false ?
					null
					:
					<div className="property-spacing-border">
                	</div>
				}
				{
					checkHighlights(listing) === false ?
					null
					:
					<Highlights
						property={property}
						label="Listing"
						elements={listing}
						conditionView={false}
					/>
				}
				<div className="property-spacing-border">
                </div>
				{
					property.listedBy === null || property.attributionInfo === null || property.onMarket === false ?
					null
					:
					<ContactAgent
						property={property}
					/>
				}
				{
					property.listedBy === null || property.attributionInfo === null || property.onMarket === false ?
					null
					:
					<div className="property-spacing-border">
                	</div>
				}
			</div>
		</div>
	)
}

export default PropertyDetails;