import React from 'react';
import '../styles/ComboList.css';

function ComboList(props) {
    const options = props.options;
    const noIcons = props.noIcons;

    return (
        <div className="combo-list-outer-container">
            <div className="combo-list-inner-container">
                {
                    options.map((item, index) =>
                        <div 
                            className={"combo-list-element-container " + (noIcons === true ? "combo-list-element-container-no-icons " : item.leftIcon === null ? "combo-list-element-container-no-icon " : "combo-list-element-container-icon ") + (item.disabled ? " disabled" : " cursor-pointer")}
                            key={index}
                            onClick={() => item.disabled === true ? null : item.func()}
                        >
                            <div className="combo-list-element-left-container">
                                {
                                    item.leftIcon === null ? 
                                    null 
                                    :
                                    item.leftIcon
                                }
                                <span className={"body-regular " + (item.colour)}>
                                    {item.label}  
                                </span>
                            </div>
                            {
                                item.rightIcon === null ? 
                                null 
                                :
                                item.rightIcon
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default ComboList;