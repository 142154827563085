import React from 'react';
import '../styles/RentAccordion.css';
import { Accordion, AccordionDetails, AccordionSummary, formatterLong } from '../styles/GlobalStyles';
import { Block } from '../v4/components';

function RentAccordion(props) {
    const rentAccordion = props.rentAccordion;
    const handleRentAccordion = props.handleRentAccordion;
    const property = props.property;
    const traditionalRentEstimate = property.rental_income === undefined ? null : property.rental_income.traditional;
    const rentalEstimates = traditionalRentEstimate === null ? [] : [
		{
			label: "Rental Comps Median",
			value: traditionalRentEstimate.ccRentEstimate === null ? null : Math.round(traditionalRentEstimate.ccRentEstimate)
		},
		{
			label: "Median ZIP Code Rent",
			value: Math.round(traditionalRentEstimate.medianZipRent)
		},
		{
			label: "Zillow",
			value: traditionalRentEstimate.rentZestimate === undefined ? null : Math.round(traditionalRentEstimate.rentZestimate)
		},
		{
			label: "Rentcast",
			value: traditionalRentEstimate.rentCastEstimate === undefined ? null : property.propertyTypeDimension === "Single Family" && traditionalRentEstimate.ccRentEstimate !== null ? null : traditionalRentEstimate.rentCastEstimate === null ? null : Math.round(traditionalRentEstimate.rentCastEstimate)
		}
	];

    return (
        <Accordion 
            className="rent-accordion-outer-container"
            expanded={rentAccordion}
            onChange={handleRentAccordion}
            sx={{ 
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    gap: rentAccordion === true ? '16px !important' : '8px !important',
                    boxShadow: 'none',
                    '&:before': {
                    display: 'none',
                }}
            }
        >
            <AccordionSummary
                aria-controls={`rent-accordion-bh-content`}
                id={`rent-accordion-bh-header`}
            >
                <div className="button-row">
                    <span className="body-regular-underline colour-link">
                        {rentAccordion === true ? "Hide" : "Show"} other rent estimates
                    </span>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="rent-accordion-row">
                    {
                        rentalEstimates.map((item, index) =>
                            item.value === null || item.value === 0 ?
                            null
                            :
                            <Block
                                key={index}
                                label={item.label}
                                value={formatterLong.format(item.value).replace(".00", "")}
                                bg="bg-colour-grey"
                            />
                        )
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default RentAccordion;