import React from 'react';
import { CreditCard, SubscriptionTable, MobileSubscriptionGrid } from '../components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { recordEvent } from '../functions';
import { Button } from '../v4/components';

function PaymentSettings(props) {
    const userData = props.userData;
    const cardLoading = props.cardLoading;
    const setupIntent = props.setupIntent;
    const disabled = props.disabled;
    const subscriptions = props.subscriptions;
    const expiredSubscriptions = props.expiredSubscriptions;
    const showDeleteModal = props.showDeleteModal;
    const autoBilling = props.autoBilling;
    const freeTrialObject = props.freeTrialObject;
    const activateCancelSubscriptionModal = props.activateCancelSubscriptionModal;
    const navigate = useNavigate();

    const checkMonths = (item) => {
        const startDate = moment.unix(item.startDate.seconds);
        const endDate = moment.unix(item.endDate.seconds);
        const months = endDate.diff(startDate, 'months');
        return months;
    };

    const upgradeSubscription = (item) => {
        navigate("/pricing", {
            state: {
                chosenCity: {
                    city: item.city,
                    state: item.state
                }
            }
        });
        recordEvent("Upgrade Subscription", {
            oldPath: "/profile",
            newPath: "/pricing",
            item: item
        });
    };

    const getHelp = () => {
        window.location.href = "mailto:info@coffeeclozers.com";
    };

    return (
        <div className="profile-payments-subscriptions-container">
            <div className="profile-account-information-container margin-large">
                <div className="profile-inner-width-container">
                    <div className="profile-subheading-container margin-large">
                        <h2 className="body-semibold colour-quaternary">
                            Payment methods
                        </h2>
                    </div>
                    {
                        userData.creditCard !== undefined && cardLoading === false ?
                        <div className="profile-payment-methods-container">
                            <CreditCard
                                creditCard={userData.creditCard}
                                deleteCard={showDeleteModal}
                                checkout={false}
                                showDelete={true}
                            />
                        </div>
                        :
                        cardLoading === true ?
                        null
                        :
                        <div className="profile-payment-methods-container">
                            <div className="profile-payment-method-text-container">
                                <span className="body-regular colour-secondary">
                                    No saved payment methods. 
                                    Add a payment method for faster checkout.
                                </span>
                                {
                                    autoBilling === true ?
                                    <span className="body-regular colour-error block-text margin-top-small">
                                        Please add a credit card back in to 
                                        continue your subscription's auto-billing
                                    </span>
                                    :
                                    null
                                }
                            </div>
                            <div className="profile-add-payment-method-button-container">
                                <Button
                                    buttonType="primary-button"
                                    size="large"
                                    text={"Add a payment method"}
                                    cta={setupIntent}
                                    disabled={disabled}
                                    leftIcon={null}
                                    leftIconClass=""
                                    rightIcon={null}
                                    rightIconClass=""
                                    alt=""
                                    standardIcon={false}
                                    badge={false}
                                    badgeVal={null}
                                    badgeBG={null}
                                    buttonRef={null}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="profile-payment-settings-outer-container">
                <div className="">
                    <div className="profile-subheading-container margin-large">
                        <h2 className="body-semibold colour-quaternary">
                            Subscriptions
                        </h2>
                    </div>
                    <SubscriptionTable
                        subscriptions={subscriptions}
                        expiredSubscriptions={expiredSubscriptions}
                        freeTrialObject={freeTrialObject}
                        checkMonths={checkMonths}
                        cancelSubscription={activateCancelSubscriptionModal}
                        getHelp={getHelp}
                        upgradeSubscription={upgradeSubscription}
                    />
                    <MobileSubscriptionGrid
                        subscriptions={subscriptions}
                        expiredSubscriptions={expiredSubscriptions}
                        freeTrialObject={freeTrialObject}
                        checkMonths={checkMonths}
                        cancelSubscription={activateCancelSubscriptionModal}
                        getHelp={getHelp}
                        upgradeSubscription={upgradeSubscription}
                    />
                </div>
            </div>
        </div>
    )
}

export default PaymentSettings;