import React from 'react';

function Plus(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="17" 
            height="16" 
            viewBox="0 0 17 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M7.46468 15.881C7.26642 15.881 7.09789 15.8216 6.95911 15.7026C6.84015 15.5638 6.78067 15.3953 6.78067 15.197V9.01115H0.684015C0.48575 9.01115 0.317224 8.95167 0.178439 8.83271C0.0594795 8.69393 0 8.5254 0 8.32714V7.37546C0 7.1772 0.0594795 7.01859 0.178439 6.89963C0.317224 6.76084 0.48575 6.69145 0.684015 6.69145H6.78067V0.684015C6.78067 0.48575 6.84015 0.327138 6.95911 0.208179C7.09789 0.0693929 7.26642 0 7.46468 0H8.50558C8.70384 0 8.86245 0.0693929 8.98141 0.208179C9.1202 0.327138 9.18959 0.48575 9.18959 0.684015V6.69145H15.316C15.5143 6.69145 15.6729 6.76084 15.7918 6.89963C15.9306 7.01859 16 7.1772 16 7.37546V8.32714C16 8.5254 15.9306 8.69393 15.7918 8.83271C15.6729 8.95167 15.5143 9.01115 15.316 9.01115H9.18959V15.197C9.18959 15.3953 9.1202 15.5638 8.98141 15.7026C8.86245 15.8216 8.70384 15.881 8.50558 15.881H7.46468Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Plus;