import React, { useState } from 'react';
import '../styles/SummaryTab.css';
import { SummarySection } from './';
import { colour, formatterLong, percentage } from '../../styles/GlobalStyles';
import { Calculator01Icon } from 'hugeicons-react';

function SummaryTab(props) {
    const [calculatorHover, setCalculatorHover] = useState(null);
    const downPaymentCost = props.downPaymentCost;
    const closingCost = props.closingCost;
    const totalRehabCost = props.totalRehabCost;
    const highLevelProfits = props.highLevelProfits;
    const rent = props.rent;
    const acquisitionStrategy = props.acquisitionStrategy;
    const sellersMortgage = props.sellersMortgage;
    const exitStrategy = props.exitStrategy;
    const monthlyCosts = props.monthlyCosts;
    const cashInDeal = props.cashInDeal;
    const leaseOptionDollarDownPayment = props.leaseOptionDollarDownPayment;
    const mortgage = monthlyCosts.length === 0 ? 0 : monthlyCosts[0].inputs[0].value;
    const othercosts = highLevelProfits.monthlyCosts - mortgage;
    const initialCosts = exitStrategy === "lo" ? highLevelProfits.leaseOptionTotalCosts : highLevelProfits.totalCosts;
    const cashFlow = exitStrategy === "lo" ? highLevelProfits.leaseOptionProfit : exitStrategy === "str" ? highLevelProfits.strProfit : highLevelProfits.monthlyProfit;
    const roiColour = exitStrategy === "lo" && highLevelProfits.leaseOptionProfit > 0 ?  "colour-green" : exitStrategy === "lo" && highLevelProfits.leaseOptionProfit <= 0 ? "colour-error" : exitStrategy === "brrrr" && highLevelProfits.monthlyProfit > 0 ? "colour-green" : exitStrategy === "brrrr" && highLevelProfits.monthlyProfit <= 0 ? "colour-error" : ((highLevelProfits.cashOnCash >= 0.07 && exitStrategy === "ltr") || (highLevelProfits.strCashOnCash >= 0.07 && exitStrategy === "str")) ? "colour-green" : ((highLevelProfits.cashOnCash >= 0 && exitStrategy === "ltr") || (highLevelProfits.strCashOnCash >= 0 && exitStrategy === "str")) ? "colour-medium" : (highLevelProfits.expensesCovered >= 1 && exitStrategy === "hh") ? "colour-green" : (highLevelProfits.expensesCovered >= 0.7 && exitStrategy === "hh") ? "colour-medium" : "colour-error";
    const arvSpreadRoIColour = highLevelProfits.arvSpread > 0 ? "colour-green" : "colour-error";
    const cashOnCash = exitStrategy === "lo" ? highLevelProfits.leaseOptionCashOnCash : exitStrategy === "str" ? highLevelProfits.strCashOnCash : highLevelProfits.cashOnCash;
    const surplus = exitStrategy === "lo" && initialCosts < 0 ? true : false;

    const changeCalculatorHover = (index) => {
        setCalculatorHover(index);
    };

    const investmentSection = [
        {
            label: acquisitionStrategy === "c" ? "Offer price" : (acquisitionStrategy === "sf" || acquisitionStrategy === "sub") ? "Down payment to seller" : "Down payment",
            subLabel: null,
            value: formatterLong.format(Math.ceil(downPaymentCost)).replace(".00", ""),
            bold: false,
            green: false,
            animate: false
        },
        {
            label: "Closing costs",
            subLabel: null,
            value: formatterLong.format(Math.ceil(closingCost)).replace(".00", ""),
            bold: false,
            green: false,
            animate: false
        },
        {
            label: "Rehab",
            subLabel: null,
            value: formatterLong.format(Math.ceil(totalRehabCost)).replace(".00", ""),
            bold: false,
            green: false,
            animate: false
        },
        {
            label: "Tenant-buyer down payment to you",
            subLabel: null,
            value: exitStrategy === "lo" ? formatterLong.format(Math.ceil(leaseOptionDollarDownPayment * -1)).replace(".00", "") : null,
            bold: false,
            green: exitStrategy === "lo" ? true : false,
            animate: false
        },
        {
            label: "Total cash invested",
            subLabel: exitStrategy === "lo" && surplus === true ? "🎉 Yay, surplus!" : null,
            value: formatterLong.format(Math.ceil(initialCosts)).replace(".00", ""),
            bold: true,
            green: exitStrategy === "lo" && surplus === true ? true : false,
            animate: calculatorHover === 2 || (calculatorHover === 3 && exitStrategy !== "hh") ? true : false
        }
    ];

    const earningsSection = [
        {
            label: "Total monthly income",
            subLabel: exitStrategy === "hh" ? "(Rent by room/unit)" : exitStrategy === "lo" ? "(Tenant mortgage on lease option loan)" : "(Rent)",
            value: formatterLong.format(Math.ceil(rent)).replace(".00", ""),
            bold: true,
            green: false,
            animate: calculatorHover === 0 || (calculatorHover === 3 && exitStrategy === "hh") ? true : false
        }
    ];

    const expensesSection = [
        {
            label: (acquisitionStrategy === "sf" || acquisitionStrategy === "sub") ? "Mortgage owed to seller" : "Mortgage",
            subLabel: (acquisitionStrategy === "sf" || acquisitionStrategy === "sub") ? null : exitStrategy === "brrrr" ? "(Refinance bank loan)" : "(Bank loan)",
            value: formatterLong.format(Math.ceil(mortgage)).replace(".00", ""),
            bold: false,
            green: false,
            animate: false
        },
        {
            label: acquisitionStrategy === "sub" ? "Mortgage owed to seller's bank" : null,
            subLabel: null,
            value: acquisitionStrategy === "sub" ? formatterLong.format(Math.ceil(sellersMortgage)).replace(".00", "") : null,
            bold: false,
            green: false,
            animate: false
        },
        {
            label: "Additional expenses",
            subLabel: "(Insurance, taxes, vacancy, etc.)",
            value: formatterLong.format(Math.ceil(othercosts)).replace(".00", ""),
            bold: false,
            green: false,
            animate: false
        },
        {
            label: "Total monthly expenses",
            subLabel: null,
            value: formatterLong.format(Math.ceil(highLevelProfits.monthlyCosts)).replace(".00", ""),
            bold: true,
            green: false,
            animate: calculatorHover === 0 || (calculatorHover === 3 && exitStrategy === "hh") ? true : false
        }
    ];

    const returnSection = [
        {
            label: "Monthly cash flow",
            icon: <Calculator01Icon size={13} color={calculatorHover === 0 ? colour.grayScaleBlack : colour.grayScaleGray03} className="cursor-pointer" onMouseEnter={() => changeCalculatorHover(0)} onMouseLeave={() => changeCalculatorHover(null)} />,
            subLabel: "= Total income - total expenses",
            value: formatterLong.format(Math.ceil(cashFlow)).replace(".00", ""),
            colour: roiColour,
            animate: calculatorHover === 1 ? true : false
        },
        {
            label: "Annual cash flow",
            icon: <Calculator01Icon size={13} color={calculatorHover === 1 ? colour.grayScaleBlack : colour.grayScaleGray03} className="cursor-pointer" onMouseEnter={() => changeCalculatorHover(1)} onMouseLeave={() => changeCalculatorHover(null)} />,
            subLabel: "= (Mo. cash flow) * 12 months",
            value: formatterLong.format(Math.ceil(cashFlow * 12)).replace(".00", ""),
            colour: roiColour,
            animate: (calculatorHover === 2 && exitStrategy !== "brrrr") || (calculatorHover === 3 && exitStrategy !== "hh") ? true : false
        },
        {
            label: exitStrategy === "brrrr" ? "ARV Spread" : "Breakeven year",
            icon: <Calculator01Icon size={13} color={calculatorHover === 2 ? colour.grayScaleBlack : colour.grayScaleGray03} className="cursor-pointer" onMouseEnter={() => changeCalculatorHover(2)} onMouseLeave={() => changeCalculatorHover(null)} />,
            subLabel: exitStrategy === "brrrr" ? "= (ARV - total cash invested) ÷ total cash invested" : "= total cash invested ÷ annual cashflow",
            value: exitStrategy === "brrrr" ? percentage.format(highLevelProfits.arvSpread) : cashFlow < 0 ? "N/A" : exitStrategy === "brrrr" && cashInDeal <= 0 ? "Year 0" : surplus === true ? "Year 0" : `Year ${Math.ceil(initialCosts / (cashFlow * 12))}`,
            colour: exitStrategy === "brrrr" ? arvSpreadRoIColour : cashFlow < 0 ? "colour-tertiary" : roiColour,
            animate: false
        },
        {
            label: exitStrategy === "hh" ? "% expenses covered" : exitStrategy === "brrrr" ? "Cash on cash (post re-fi)" : "Cash on cash return",
            icon: <Calculator01Icon size={13} color={calculatorHover === 3 ? colour.grayScaleBlack : colour.grayScaleGray03} className="cursor-pointer" onMouseEnter={() => changeCalculatorHover(3)} onMouseLeave={() => changeCalculatorHover(null)} />,
            subLabel: exitStrategy === "hh" ? "= Mo. income ÷ mo. expenses" : "= Annual cash flow ÷ total cash invested",
            value: exitStrategy === "hh" ? percentage.format(highLevelProfits.expensesCovered) : exitStrategy === "brrrr" && cashInDeal <= 0 ? "∞" : surplus === true ? "∞" : percentage.format(cashOnCash),
            colour: roiColour,
            animate: false
        }
    ];

    return (
        <div className="summary-tab-container">
            <SummarySection
                label={"Investment"}
                data={investmentSection}
                roiVals={false}
            />
            <div className="summary-tab-spacing-container">
            </div>
            <SummarySection
                label={"Earnings"}
                data={earningsSection}
                roiVals={false}
            />
            <div className="summary-tab-spacing-container">
            </div>
            <SummarySection
                label={"Expenses"}
                data={expensesSection}
                roiVals={false}
            />
            <div className="summary-tab-spacing-container">
            </div>
            <SummarySection
                label={"Return"}
                data={returnSection}
                roiVals={true}
            />
        </div>
    )
};

export default SummaryTab;