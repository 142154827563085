import React from 'react';

function MagnifyingGlass(props) {
    const fill = props.fill;
    const className = props.className;
    const clickFunc = props.clickFunc;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16"
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
            onClick={() => clickFunc === null ? null : clickFunc()}
        >
            <path 
                d="M6.60304 13.0826C7.93707 13.0826 9.18721 12.6836 10.2276 12.0021L13.9025 15.6509C14.1458 15.8836 14.4562 16 14.7918 16C15.4882 16 16 15.4597 16 14.7782C16 14.4623 15.8909 14.1548 15.656 13.9221L12.0063 10.2899C12.7614 9.22597 13.2061 7.93766 13.2061 6.5413C13.2061 2.94234 10.236 0 6.60304 0C2.9785 0 0 2.94234 0 6.5413C0 10.1403 2.97011 13.0826 6.60304 13.0826ZM6.60304 11.3371C3.94337 11.3371 1.76193 9.1761 1.76193 6.5413C1.76193 3.90649 3.94337 1.74545 6.60304 1.74545C9.26272 1.74545 11.4442 3.90649 11.4442 6.5413C11.4442 9.1761 9.26272 11.3371 6.60304 11.3371Z" 
                fill={fill}
            />
        </svg>
    )
}

export default MagnifyingGlass;