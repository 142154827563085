import React, { useState, useEffect } from 'react';
import '../styles/Pricing.css';
import { Cappuccino, EspressoMartini, EspressoShots, YearlySavings } from '../assets/images';
import { GreyTickSmall } from '../assets';
import { Footer, Header, PricingCard, PricingCardMobile, Loading } from '../components';
import { getDocument, checkOnAuthStateChanged, combinedPricing, recordEvent } from '../functions';
import { useNavigate } from 'react-router-dom';
import { Button, OptionSwitch } from '../v4/components';
import { formatterLong } from '../styles/GlobalStyles';

function Pricing() {

    const origin = window.location.origin;
    const devMode = origin.includes("localhost") || window.location.hostname.includes("192.") || origin.includes("refi") ? true : false;
    const [selectedCard, setSelectedCard] = useState(null);
    const [userData, setUserData] = useState(null);
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const [freeTrial, setFreeTrial] = useState(false);
    const [loading, setLoading] = useState(true);
    const [firstPricingOption, setFirstPricingOption] = useState(false);
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    const numberOfCounties = "150+";

    useEffect(() => {
        document.title = "Pricing | Coffee Clozers";
        const fetchData = async() => {
            const colRef = "Users";

            const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
                const docRef = getUserId.userId;
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    const userDetails = queryData.data.data;
                    setUserData(userDetails);
                    if ( userDetails.freeTrial.length === 0 ) {
                        setFreeTrial(false);
                    }
                    else {
                        setFreeTrial(true);
                    }
                }
                else {
                    setFreeTrial(false);
                }
                setLoading(false);

                recordEvent("Pricing View - Authenticated", {
                    userId: docRef
                });
            }
            else {
                recordEvent("Pricing View - Non-Authenticated", {});
                setLoading(false);
            }
        };

        fetchData();
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    const cards = [
        {
            image: EspressoShots,
            ctaText: freeTrial === false ? "Get started free" : "Choose plan",
            ctaExtraText: "No credit card required",
            title: "Always Free",
            label: "",
            bestFor: "For running occasional reports",
            oldPrice: "",
            price: "Free",
            duration: "",
            utility: "Unlimited access to a city of your choice.",
            link: "",
            counties: 0,
            creative: false,
            extraSavings: "",
            features: [
                {
                    icon: GreyTickSmall,
                    text: "Free market analysis tool",
                    singleLine: true,
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: "5 Quick report credits per month",
                    singleLine: true,
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `Access our pool of ${numberOfCounties} counties for one week`,
                    singleLine: false,
                    firstText: `Start a free trial to access our pool of ${numberOfCounties} counties for`,
                    boldText: "one week",
                    secondText: "on Traditional + Creative",
                    link: "",
                    endsSoon: false
                }
            ],
            amount: 0,
            subscriptionId: "",
            testSubscriptionId: "",
            total: 0,
            route: freeTrial === false ? "/sign-up" : "/pay"
        },
        {
            image: Cappuccino,
            ctaText: freeTrial === false ? "Get started free" : "Choose plan",
            ctaExtraText: freeTrial === false ? "No credit card required" : firstPricingOption === false ? `Pay ${formatterLong.format(Math.floor(combinedPricing[0].annualPrice / 100)).replace(".00", "")} today for 1 year of access` : "Billed monthly • Cancel anytime",
            title: combinedPricing[0].title,
            label: combinedPricing[0].label,
            bestFor: "You're looking for properties with good cash-flow on traditional financing.",
            oldPrice: firstPricingOption === false ? `${formatterLong.format(combinedPricing[0].price / 100).replace(".00", "")}` : "",
            price: firstPricingOption === false ? `${formatterLong.format(Math.floor(combinedPricing[0].annualPrice / 1200)).replace(".00", "")}` : `${formatterLong.format(combinedPricing[0].price / 100).replace(".00", "")}`,
            duration: "/month",
            utility: "Unlimited access to an entire metropolitan area.",
            link: "",
            counties: 1,
            creative: false,
            extraSavings: "",
            features: [
                {
                    icon: GreyTickSmall,
                    text: "Access to Traditional Coffee Clozers",
                    singleLine: true,
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `Access our pool of ${numberOfCounties} counties on Coffee Clozers, plus unlock 1 extra county of your choice`,
                    singleLine: false,
                    firstText: "Access our pool of",
                    boldText: `${numberOfCounties} counties`,
                    secondText: "on Coffee Clozers, plus unlock 1 extra county of your choice",
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `Unlimited nationwide quick report credits`,
                    singleLine: false,
                    firstText: "Unlimited",
                    boldText: "nationwide",
                    secondText: "quick report credits",
                    link: "",
                    endsSoon: false
                }
            ],
            amount: combinedPricing[0].price,
            subscriptionId: combinedPricing[0].subscriptionId,
            testSubscriptionId: combinedPricing[0].testSubscriptionId,
            total: firstPricingOption === true ? combinedPricing[0].price : combinedPricing[0].annualPrice,
            route: freeTrial === false ? "/sign-up" : "/pay",
            discountRate: combinedPricing[0].discountRate
        },
        {
            image: EspressoMartini,
            ctaText: freeTrial === false ? "Get started free" : "Choose plan",
            ctaExtraText: freeTrial === false ? "No credit card required" : firstPricingOption === false ? `Pay ${formatterLong.format(Math.floor(combinedPricing[1].annualPrice / 100)).replace(".00", "")} today for 1 year of access` : "Billed monthly • Cancel anytime",
            title: combinedPricing[1].title,
            label: combinedPricing[1].label,
            bestFor: "You want to invest with less money by using creative financing",
            oldPrice: firstPricingOption === false ? `${formatterLong.format(combinedPricing[1].price / 100).replace(".00", "")}` : "",
            price: firstPricingOption === false ? `${formatterLong.format(Math.floor(combinedPricing[1].annualPrice / 1200)).replace(".00", "")}` : `${formatterLong.format(combinedPricing[1].price / 100).replace(".00", "")}`,
            duration: "/month",
            utility: "Unlimited access to the core 50 cities on Coffee Clozers",
            link: "",
            counties: 1,
            creative: true,
            extraSavings: "",
            features: [
                {
                    icon: GreyTickSmall,
                    text: "Access to Traditional + Creative Coffee Clozers",
                    singleLine: false,
                    firstText: "Access to Traditional",
                    boldText: "+ Creative",
                    secondText: "Coffee Clozers",
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `Access our pool of ${numberOfCounties} counties on Coffee Clozers, plus unlock 1 extra county of your choice`,
                    singleLine: false,
                    firstText: "Access our pool of",
                    boldText: `${numberOfCounties} counties`,
                    secondText: "on Coffee Clozers, plus unlock 1 extra county of your choice",
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `Unlimited nationwide quick report credits`,
                    singleLine: false,
                    firstText: "Unlimited",
                    boldText: "nationwide",
                    secondText: "quick report credits",
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: "Nationwide data for all FSBO properties",
                    singleLine: false,
                    firstText: "",
                    boldText: "Nationwide",
                    secondText: "data for all FSBO properties",
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `Generate unlimited creative finance offer letters`,
                    singleLine: true,
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `CoffeeClozersGPT to practice pitching sellers`,
                    singleLine: false,
                    firstText: "",
                    boldText: "CoffeeClozersGPT",
                    secondText: "to practice pitching sellers",
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `Membership to our exclusive creative finance community`,
                    singleLine: false,
                    firstText: "Membership to our exclusive creative finance",
                    boldText: "community",
                    secondText: "",
                    link: "",
                    endsSoon: false
                },
                {
                    icon: GreyTickSmall,
                    text: `AI summaries on eligible properties`,
                    singleLine: true,
                    link: "",
                    endsSoon: false
                }
            ],
            amount: combinedPricing[1].price,
            subscriptionId: combinedPricing[1].subscriptionId,
            testSubscriptionId: combinedPricing[1].testSubscriptionId,
            total: firstPricingOption === true ? combinedPricing[1].price : combinedPricing[1].annualPrice,
            route: freeTrial === false ? "/sign-up" : "/pay",
            discountRate: combinedPricing[1].discountRate
        }
    ];

    const changeRadio = (index) => {
        setSelectedCard(Number(index));
    }

    const changeAccordion = (index) => {
        if ( expandedAccordion === index ) {
            setExpandedAccordion(null);
            return;
        }
        else {
            setExpandedAccordion(index);
            return;
        }
    };

    const changePricingOption = () => {
        setFirstPricingOption(!firstPricingOption);
    };

    // IF YOU'RE ON FREE TRIAL DON'T NAV TO PICK SINGLE CITY

    const selectMobilePlan = () => {
        const item = cards[selectedCard];

        recordEvent("Plan Selected", {
            months: firstPricingOption === true ? 1 : 12,
            price: item.total / 100,
            counties: item.counties
        });

        const newState = {
            route: "/pay",
            allCities: false,
            price: item.amount / 100,
            subscriptionId: devMode === true ? item.testSubscriptionId : item.subscriptionId,
            autoBilling: firstPricingOption === true ? true : false,
            plan: {
                total: item.total / 100,
                originalPrice: item.amount / 100,
                months: firstPricingOption === true ? 1 : 12,
                price: firstPricingOption === true ? item.total / 100 : item.total / 1200,
                title: item.title,
                label: item.label,
                counties: item.counties,
                creative: item.creative,
                discount: firstPricingOption === true ? 0 : item.discountRate
            }
        };
        navigate(item.route, {
            state: newState
        });
    };

    const customPlan = () => {
        navigate("/custom-plan");
    };

    return (
        <div className="pricing-outer-container">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="pricing-inner-container">
                <div className="pricing-top-container">
                    {
                        loading === false ?
                        <div className="pricing-title-container">
                            <h1 className="heading-large-semibold colour-primary text-align-center">
                                Pricing
                            </h1>
                            <h2 className="body-regular colour-secondary text-align-center">
                                {
                                    userData === null ?
                                    "No credit card required to start your free trial."
                                    :
                                    firstPricingOption === false ?
                                    "Cancel anytime. 14 day money-back guarantee"
                                    :
                                    "Cancel anytime."
                                }
                            </h2>
                        </div>
                        :
                        null
                    }
                    <div className="pricing-switch-container relative-container">
                        <OptionSwitch
                            labels={["Monthly", "Yearly"]}
                            firstOption={firstPricingOption}
                            disabled={false}
                            optionSwitch={changePricingOption}
                            switchWidth={190}
                            leftWidth={0.5}
                            rightWidth={0.5}
                        />
                        <img
                            src={YearlySavings}
                            className="pricing-yearly-savings-image"
                            alt="Yearly savings"
                        />
                    </div>
                </div>
                {
                    loading === true ?
                    <div className="pricing-loading-container">
                        <Loading />
                    </div>
                    :
                    <div className="pricing-cards-row">
                        {
                            cards.map((item, index) =>
                                <PricingCard
                                    item={item}
                                    key={index}
                                    index={index}
                                    pickCity={false}
                                    openCheckout={null}
                                    disabled={false}
                                    devMode={devMode}
                                    firstPricingOption={firstPricingOption}
                                    changePricingOption={changePricingOption}
                                    freeTrial={freeTrial}
                                    blackFriday={false}
                                />
                            )
                        }
                    </div>
                }
                {
                    loading === true ?
                    null
                    :
                    <div className="pricing-cards-mobile-container">
                        {
                            cards.map((item, index) =>
                                index !== 0 &&
                                <PricingCardMobile
                                    item={item}
                                    selectedCard={selectedCard}
                                    index={index}
                                    key={index}
                                    changeRadio={changeRadio}
                                    changeAccordion={changeAccordion}
                                    expandedAccordion={expandedAccordion}
                                    pickCity={false}
                                    checkout={false}
                                    firstPricingOption={firstPricingOption}
                                    changePricingOption={changePricingOption}
                                    freeTrial={freeTrial}
                                />
                            )
                        }
                    </div>
                }
                {
                    (selectedCard === null) || loading === true ?
                    null
                    :
                    selectedCard !== null ?
                    <div className={"pricing-mobile-button-outer-container " + (scrollPosition < 700 ? "pricing-mobile-button-sticky" : "")}>
                        <div className="pricing-mobile-button-container">
                            <Button
                                buttonType="primary-button"
                                text={freeTrial === true ? `Choose plan` : "Start my free trial"}
                                cta={selectMobilePlan}
                                disabled={false}
                                leftIcon={null}
                                rightIcon={null}
                                alt=""
                                size="large"
                                standardIcon={true}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                    </div>
                    :
                    null
                }
                {
                    loading === true ?
                    null
                    :
                    <div className="pricing-subtext-container">
                        <div className="pricing-subtext-inner-container">
                            <span className="body-regular colour-secondary block-text text-align-center">
                                Need something different?
                            </span>
                            <span 
                                className="body-regular-underline colour-link block-text text-align-center"
                                onClick={() => customPlan()}
                            >
                                Request a custom plan
                            </span>
                        </div>
                    </div>
                }
            </div>
            {
                loading === false ?
                <Footer />
                :
                null
            }
        </div>
    )
}

export default Pricing;