import React from 'react';
import '../styles/RentTab.css';
import { MFHRentOneUnitType, SFHRent, MFHHouseHackOneUnitType, SFHRentHouseHack, MFHHouseHackMultiUnitType, MFHRentMultiUnitType } from '../components';

function RentTab(props) {
    const rent = props.rent;
    const blurred = props.blurred;
    const onChangeRent = props.onChangeRent;
    const defaultRent = props.defaultRent;
    const exitStrategy = props.exitStrategy;
    const property = props.property;
    const unitsRent = props.unitsRent;
    const onChangeUnitRent = props.onChangeUnitRent;
    const houseHackUnitsRent = props.houseHackUnitsRent;
    const houseHackRent = props.houseHackRent;
    const unitLiving = props.unitLiving;
    const setUnitLiving = props.setUnitLiving;
    const defaultHouseHackRent = props.defaultHouseHackRent;
    const onChangeUnitLiving = props.onChangeUnitLiving;
    const houseHackBedroomsRent = props.houseHackBedroomsRent;
    const onChangeBedroomRent = props.onChangeBedroomRent;
    const onChangeBedroomLiving = props.onChangeBedroomLiving;
    const resetRentEstimates = props.resetRentEstimates;
    const multipleUniqueUnits = props.multipleUniqueUnits;
    const multiUnitRent = props.multiUnitRent;
    const multiUnitRentTotal = props.multiUnitRentTotal;
    const defaultMultiUnitRent = props.defaultMultiUnitRent;
    const houseHackMultiUnitsRent = props.houseHackMultiUnitsRent;
    const multiUnitTypeUnitLiving = props.multiUnitTypeUnitLiving;
    const multiUnitHouseHackRentTotal = props.multiUnitHouseHackRentTotal;
    const defaultMultiUnitHouseHackRentTotal = props.defaultMultiUnitHouseHackRentTotal;
    const multiUnitRentRatio = props.multiUnitRentRatio;
    const onChangeMultiUnitTotalRent = props.onChangeMultiUnitTotalRent;
    const onChangeMultiUnitLiving = props.onChangeMultiUnitLiving;
    const resetMFHMultiUnitRentEstimates = props.resetMFHMultiUnitRentEstimates;
    const onChangeMultiUnitRent = props.onChangeMultiUnitRent;
    const onChangeSFHHouseHackTotal = props.onChangeSFHHouseHackTotal;

    return (
        <div className="rent-tab-outer-container">
            <div className="rent-tab-inner-container">
                {
                    ((property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" || property.propertyTypeDimension === "Condo" || property.propertyTypeDimension === "Manufactured") && exitStrategy !== "hh") ?
                    // SFH rent for NON house hack
                    <SFHRent
                        rent={rent}
                        blurred={blurred}
                        onChangeRent={onChangeRent}
                        defaultRent={defaultRent}
                        resetRentEstimates={resetRentEstimates}
                        property={property}
                    />
                    :
                    ((property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" || property.propertyTypeDimension === "Condo" || property.propertyTypeDimension === "Manufactured") && exitStrategy === "hh") ?
                    // SFH rent for House Hack
                    <SFHRentHouseHack
                        rent={houseHackRent}
                        blurred={blurred}
                        onChangeRent={onChangeSFHHouseHackTotal}
                        defaultRent={defaultHouseHackRent}
                        houseHackBedroomsRent={houseHackBedroomsRent}
                        bedroomLiving={unitLiving}
                        onChangeBedroomLiving={onChangeBedroomLiving}
                        onChangeBedroomRent={onChangeBedroomRent}
                        resetRentEstimates={resetRentEstimates}
                        property={property}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === false && property.units !== undefined && property.units !== null && property.units > 1) 
                    && 
                    (exitStrategy === "ltr" || exitStrategy === "brrrr") ?
                    // MFH rent for one unit type
                    <MFHRentOneUnitType
                        property={property}
                        blurred={blurred}
                        rent={rent}
                        defaultRent={defaultRent}
                        onChangeRent={onChangeRent}
                        units={unitsRent}
                        onChangeUnitRent={onChangeUnitRent}
                        resetRentEstimates={resetRentEstimates}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === false && property.units !== undefined && property.units !== null && property.units > 1 && exitStrategy === "hh") ?
                    // MFH rent for one unit type on a House Hack
                    <MFHHouseHackOneUnitType
                        property={property}
                        blurred={blurred}
                        rent={houseHackRent}
                        defaultRent={defaultHouseHackRent}
                        onChangeRent={onChangeRent}
                        units={houseHackUnitsRent}
                        onChangeUnitRent={onChangeUnitRent}
                        unitLiving={unitLiving}
                        setUnitLiving={setUnitLiving}
                        onChangeUnitLiving={onChangeUnitLiving}
                        resetRentEstimates={resetRentEstimates}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === true && property.units !== undefined && property.units !== null && property.units > 1 && exitStrategy !== "hh") ?
                    <MFHRentMultiUnitType
                        property={property}
                        blurred={blurred}
                        rent={multiUnitRentTotal}
                        defaultRent={defaultMultiUnitRent}
                        onChangeRent={onChangeMultiUnitTotalRent}
                        units={multiUnitRent}
                        onChangeUnitRent={onChangeMultiUnitRent}
                        unitLiving={unitLiving}
                        setUnitLiving={setUnitLiving}
                        onChangeUnitLiving={onChangeUnitLiving}
                        resetRentEstimates={resetMFHMultiUnitRentEstimates}
                        uniqueUnits={property.uniqueUnitPropertyDetails}
                        multiUnitRentRatio={multiUnitRentRatio}
                    />
                    :
                    (property.propertyTypeDimension === "Multi Family" && multipleUniqueUnits === true && property.units !== undefined && property.units !== null && property.units > 1 && exitStrategy === "hh") ?
                    <MFHHouseHackMultiUnitType
                        property={property}
                        blurred={blurred}
                        rent={multiUnitHouseHackRentTotal}
                        defaultRent={defaultMultiUnitHouseHackRentTotal}
                        onChangeRent={onChangeMultiUnitTotalRent}
                        units={houseHackMultiUnitsRent}
                        onChangeUnitRent={onChangeMultiUnitRent}
                        unitLiving={multiUnitTypeUnitLiving}
                        setUnitLiving={setUnitLiving}
                        onChangeUnitLiving={onChangeMultiUnitLiving}
                        resetRentEstimates={resetMFHMultiUnitRentEstimates}
                        uniqueUnits={property.uniqueUnitPropertyDetails}
                        multiUnitRentRatio={multiUnitRentRatio}
                    />
                    :
                    null
                }
            </div>
        </div>
    )
};

export default RentTab;