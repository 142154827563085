import React, { useRef, useState } from 'react';
import { percentage, formatterCompact, formatterLong, numberFormatter, colour, RedActionButton, GreenActionButton, defaultCompData } from '../styles/GlobalStyles';
import ProgressBar from './ProgressBar';
import moment from 'moment';
import { calculateMortgagePayment, checkUserId, saveCompData } from '../functions';
import { ApplyEstimate } from './';

function ARVComps(props) {
    const comps = props.comps;
    const blurred = props.blurred;
    const partnerEdit = props.partnerEdit;
    const deleteComp = props.deleteComp;
    const property = props.property;
    const savedData = props.savedData === null ? defaultCompData : props.savedData;
    const setSavedData = props.setSavedData;
    const editedProperty = props.editedProperty;
    const setEditedProperty = props.setEditedProperty;
    const loanYears = props.loanYears;
    const applyEstimateModal = props.applyEstimateModal;
    const setApplyEstimateModal = props.setApplyEstimateModal;
    const propertySearch = props.propertySearch;
    const excludedComps = savedData === null ? [] : savedData.excludedComps;
    const [showExcludedComps, setShowExcludedComps] = useState(false);
    const [sorting, setSorting] = useState("similarity_score");
    const [direction, setDirection] = useState("descending");
    const [hoverOption, setHoverOption] = useState(null);
    const [sortedComps, setSortedComps] = useState(comps);
    const [selectedComps, setSelectedComps] = useState([]);
    const [amendedValue, setAmendedValue] = useState(null);
    const valueField = property.fixerUpper === true && property.arvComps === true ? property.arvCompsStats.arvPrice : property.propertyEstimateDefault;
    const arvDefault = property.arvComps === true ? property.arvCompsStats.arvPrice : property.price;
    const scrollContainer = useRef(null);
    const labels = [
        {
            label: "Address",
            val: null
        },
        {
            label: "Distance",
            val: "distance"
        },
        {
            label: "Similarity",
            val: "similarity_score"
        },
        {
            label: "Status",
            val: "listingStatus"
        },
        {
            label: "Price",
            val: "price"
        },
        {
            label: "Price/Sqft",
            val: "pricePerSquareFoot"
        },
        {
            label: "Sqft",
            val: "livingArea"
        },
        {
            label: "Beds",
            val: "bedrooms"
        },
        {
            label: "Baths",
            val: "bathrooms"
        },
        {
            label: "Built in",
            val: "yearBuilt"
        },
        {
            label: "Lot",
            val: "lotAreaValue"
        },
        {
            label: "Date",
            val: "activityDate"
        },
        {
            label: "Property type",
            val: null
        }
    ];

    const subjectProperty = [
        {
            label: `${property.address.streetAddress}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: "",
            class: "bg-colour-white",
            innerClass: "",
            was: null
        },
        {
            label: "",
            class: "bg-colour-white",
            innerClass: "",
            was: null
        },
        {
            label: "For sale",
            class: "bg-colour-white",
            innerClass: "body-regular colour-secondary block-text text-align-left",
            was: null
        },
        {
            label: formatterCompact.format(property.price).replace(".00", ""),
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.resoFacts !== undefined && property.resoFacts.pricePerSqFoot !== null ? `${formatterLong.format(property.resoFacts.pricePerSqFoot).replace(".00", "")}` : "N/A"}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.livingArea !== null ? `${numberFormatter.format(property.livingArea)}` : "N/A"}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.bedrooms}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.bathrooms}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.yearBuilt}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.resoFacts === undefined || property.resoFacts.lotSize === null ? "N/A" : numberFormatter.format(property.resoFacts.lotSize)}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: "",
            class: "bg-colour-white",
            innerClass: "",
            was: null
        },
        {
            label: `${(property.resoFacts !== undefined && property.resoFacts.propertyType === "SINGLE_FAMILY") || (property.propertyTypeDimension === "Single Family") ? "Single Family" : property.propertyTypeDimension === "Condo" ? "Condo" : property.propertyTypeDimension === "Townhouse" ? "Townhouse" : "Multi Family"}`,
            class: "bg-colour-white",
            innerClass: "body-regular colour-secondary block-text text-align-left",
            was: null
        }
    ];

    const sortVals = (newComps, val, type, number) => {
        if ( type === "descending" ) {
            if ( val === "listingStatus" ) {
                newComps.sort((a, b) => a.listingStatus.localeCompare(b.listingStatus));
            }
            else if ( number === true ) {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (Number(a[val]) < Number(b[val])) ? 1 : (Number(b[val]) < Number(a[val])) ? -1 : 0);
            }
            else {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (a[val] < b[val]) ? 1 : (b[val] < a[val]) ? -1 : 0);
            }
        }
        else {
            if ( val === "listingStatus" ) {
                newComps.sort((a, b) => b.listingStatus.localeCompare(a.listingStatus));
            }
            else if ( number === true ) {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (Number(a[val]) > Number(b[val])) ? 1 : (Number(b[val]) > Number(a[val])) ? -1 : 0);
            }
            else {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (a[val] > b[val]) ? 1 : (b[val] > a[val]) ? -1 : 0);
            }
        }
        return newComps;
    }

    const reorderComps = async(val) => {
        if ( sorting === val ) {
            if ( direction === "descending" ) {
                setSorting(val);
                const newComps = [...comps];
                const checkNumber = val === "activityDate" ? false : true;
                const newSortedComps = await sortVals(newComps, val, "ascending", checkNumber);
                setSortedComps(newSortedComps);
                setDirection("ascending");
            }
            else {
                setSorting(val);
                const newComps = [...comps];
                const checkNumber = val === "activityDate" ? false : true;
                const newSortedComps = await sortVals(newComps, val, "descending", checkNumber);
                setSortedComps(newSortedComps);
                setDirection("descending");
            }
        }
        else {
            setSorting(val);
            const newComps = [...comps];
            const checkNumber = val === "activityDate" ? false : true;
            const newSortedComps = await sortVals(newComps, val, "descending", checkNumber);
            setSortedComps(newSortedComps);
            setDirection("descending");
        }

        if ( scrollContainer.current !== null ) {
            scrollContainer.current.scrollTo(0, 0);
        }
    };

    const toggleCheckbox = (item) => {
        const excludedSelectedComps = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex !== -1 ) {
                excludedSelectedComps.push(element);
            }
        }

        const selectedCompsClone = excludedSelectedComps.length === 0 ? [...selectedComps] : [];
        const compIndex = selectedCompsClone.indexOf(item.zpid);
        if ( compIndex === -1 ) {
            selectedCompsClone.push(item.zpid);
        }
        else {
            selectedCompsClone.splice(compIndex, 1);
        }
        setSelectedComps(selectedCompsClone);
    };

    const toggleExcludedCheckbox = (item) => {
        const nonExcludedComps = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex === -1 ) {
                nonExcludedComps.push(element);
            }
        }

        const selectedCompsClone = nonExcludedComps.length === 0 ? [...selectedComps] : [];
        const compIndex = selectedCompsClone.indexOf(item.zpid);
        if ( compIndex === -1 ) {
            selectedCompsClone.push(item.zpid);
        }
        else {
            selectedCompsClone.splice(compIndex, 1);
        }
        setSelectedComps(selectedCompsClone);
    };
    
    const toggleAllCheckboxes = () => {
        if ( selectedComps.length === (comps.length - excludedComps.length) ) {
            setSelectedComps([]);
        }
        else {
            const zpidArray = comps.flat(2).map(item => item.zpid);
            const finalComps = [];
            for (let index = 0; index < zpidArray.length; index++) {
                const element = zpidArray[index];
                const zpid = zpidArray[index];
                const excludedIndex = excludedComps.indexOf(zpid);
                if ( excludedIndex === -1 ) {
                    finalComps.push(element);
                }
            }
            setSelectedComps(finalComps);
        }
    };

    const checkSelectedComp = (item) => {
        const compIndex = selectedComps.findIndex(e => e === item.zpid);
        if ( compIndex === -1 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const checkCompActive = (item) => {
        const compIndex = comps.findIndex(e => e.zpid === item.zpid);
        if ( partnerEdit === false ) {
            return true;
        }
        else if ( compIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const excludeComps = async() => {
        const savedDataClone = structuredClone(savedData);
        const newArray = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex === -1 ) {
                newArray.push(element);
            }
        };
        const newExcludedComps = [...excludedComps, ...newArray];
        savedDataClone.excludedComps = newExcludedComps;
        setSavedData(savedDataClone);
        setSelectedComps([]);

        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const finalCompData = {
                salesCompData: savedDataClone,
            };
            await saveCompData(finalCompData, userId, property.zpid);
        }
        recalculateARV(newExcludedComps);
    };

    const includeComps = async() => {
        const savedDataClone = structuredClone(savedData);
        const newArray = []
        for (let index = 0; index < excludedComps.length; index++) {
            const element = excludedComps[index];
            const excludedIndex = selectedComps.indexOf(element);
            if ( excludedIndex === -1 ) {
                newArray.push(element);
            }
        };
        savedDataClone.excludedComps = newArray;
        setSavedData(savedDataClone);
        setSelectedComps([]);
        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const finalCompData = {
                salesCompData: savedDataClone,
            };
            await saveCompData(finalCompData, userId, property.zpid);
        }
        recalculateARV(newArray);
    };

    const recalculateARV = async(newData) => {
        const newComps = [];
        const maxDistance = savedData.maxDistance;
        const maxSoldDate = savedData.maxSoldDate;
        const bedrooms = savedData.bedrooms;
        const bathrooms = savedData.bathrooms;
        const priceRange = savedData.priceRange;
        const squareFootage = savedData.squareFootage;
        const listingStatus = savedData.listingStatus;

        for (let index = 0; index < sortedComps.length; index++) {
            const element = sortedComps[index];
            const today = new Date();
            const todaySeconds = moment.unix(today.getTime() / 1000);
            const activityDateTS = moment(element.activityDate).toDate();
            const activityDate = moment(activityDateTS)
            const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');

            if ( element.listingStatus === "FOR_SALE" && Number(listingStatus) === 1 ) {
                continue;
            }
            else if ( (element.listingStatus === "SOLD" || element.listingStatus === "RECENTLY_SOLD") && Number(listingStatus) === 0 ) {
                continue;
            }

            const checkedMinPrice = priceRange[0] === "" ? 0 : Number(priceRange[0]);
            const checkedMaxPrice = priceRange[1] === "" || priceRange[1] === 0 ? 999999999 : Number(priceRange[1]);

            if ( 
                    element.distance <= maxDistance && 
                    updateDateMonthDifference <= maxSoldDate &&
                    element.bedrooms >= bedrooms &&
                    element.bathrooms >= bathrooms &&
                    element.livingArea >= squareFootage[0] && element.livingArea <= squareFootage[1] &&
                    (element.price >= checkedMinPrice && element.price <= checkedMaxPrice)
                ) {
                newComps.push(element);
            }
        }

        if ( newData.length === 0 && arvDefault === editedProperty.arv ) {

        }
        else if ( newData.length === 0 ) {
            setAmendedValue(valueField);
            setApplyEstimateModal(true);
        }
        else {
            const weightedMedian = await recalculateAmendedValue(newComps, newData);
            setAmendedValue(weightedMedian);
            setApplyEstimateModal(true);
        }
    };

    const recalculateAmendedValue = (finalComps, newExcluded) => {
        const values = [];
        const weights = [];
        for (let index = 0; index < finalComps.length; index++) {
            const element = finalComps[index];
            const excludedcompsIndex = newExcluded.indexOf(element.zpid);
            if ( excludedcompsIndex !== -1 ) {
                continue;
            }

            const price = element.price;
            const weight = element.similarity_score;
            values.push(price);
            weights.push(weight);
        }
        if ( values.length === 0 ) {
            return 0;
        }
    
        const weightedSum = values.reduce((acc, value, index) => acc + value * weights[index], 0);
        const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
        const weightedAverage = weightedSum / totalWeight;
    
        return weightedAverage;
    };

    const checkCompExcluded = (item) => {
        const compIndex = excludedComps.indexOf(item.zpid);
        if ( compIndex === -1 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const toggleExcludedComps = () => {
        setShowExcludedComps(!showExcludedComps);

        setTimeout(() => {
            if ( !showExcludedComps === true && scrollContainer.current !== null ) {
                scrollContainer.current.scrollTo(0, scrollContainer.current.scrollHeight);
            }
        }, 100);
    };

    const checkSelectedCompExclusion = () => {
        const newArray = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex !== -1 ) {
                newArray.push(element);
            }
        }

        if ( newArray.length === 0 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const findListingDateDifference = (item) => {
        const date = item;
        const monthsDifference = moment().diff(moment(date), 'months');
        const weeksDifference = moment().diff(moment(date), 'weeks');
        const daysDifference = moment().diff(moment(date), 'days');
        const relevantDifference = monthsDifference > 0 ? monthsDifference : weeksDifference > 0 ? weeksDifference : daysDifference;
        const relevantUnit = monthsDifference > 0 ? "month" : weeksDifference > 0 ? "week" : "day";
        const finalString = relevantDifference === 0 ? "today" : `${relevantDifference} ${relevantUnit}${relevantDifference === 1 ? "" : "s"} ago`;

        return finalString;
    };

    const closeApplyEstimateModal = () => {
        setApplyEstimateModal(false);
    };

    const openZillow = (item) => {
        if ( item.userInputted === true ) {
            window.open(item.zillowURL, "_blank");
        }
        else {
            window.open(`https://www.zillow.com/${item.detailUrl}`, "_blank");
        }
    };

    const saveNewValue = async(val, type) => {
        const removedCommas = val.replace(",", "");
        const newVal = Number(removedCommas);

        if ( type === "offer-price" ) {
            const newMortgage = await calculateMortgagePayment(newVal, editedProperty.downPayment, editedProperty.interestRate, loanYears);
            const newMonthlyCosts = structuredClone(editedProperty.monthlyCosts);

            if ( newMonthlyCosts.length > 0 ) {
                newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
                setEditedProperty(prevState => ({
                    ...prevState,
                    price: newVal,
                    monthlyCosts: newMonthlyCosts
                }));
            }
        }
        else {
            setEditedProperty(prevState => ({
                ...prevState,
                arv: newVal
            }));
        }
        closeApplyEstimateModal();
    };

    return (
        <div className={"comparables-outer-container " + (applyEstimateModal === true ? "relative-container" : "")}>
            {
                applyEstimateModal === true ?
                <ApplyEstimate
                    amendedValue={amendedValue}
                    setAmendedValue={setAmendedValue}
                    valueField={valueField}
                    closeModal={closeApplyEstimateModal}
                    saveCompChanges={closeApplyEstimateModal}
                    saveNewValue={saveNewValue}
                    rentMode={false}
                />
                :
                null
            }
            {
                selectedComps.length === 0 || comps === null ?
                null
                :
                <div className="comps-table-bulk-action-container margin-medium mobile-none">
                    <div className="comps-table-bulk-action-inner-container">
                        <div className="comps-table-bulk-action-left-container">
                            <span className="body-regular colour-primary">
                                {selectedComps.length} comp{selectedComps.length === 1 ? "" : "s"} selected
                            </span>
                        </div>
                        <div className="comps-table-bulk-action-right-container">
                            {
                                checkSelectedCompExclusion() === false ?
                                <RedActionButton
                                    onClick={() => excludeComps()}
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="17" 
                                        height="16" 
                                        viewBox="0 0 17 16" 
                                        fill="none"
                                        className="subtext-margin-right"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M4.00479 0.648211C3.28082 -0.155843 2.04211 -0.220761 1.23805 0.503212C0.434001 1.22718 0.369083 2.46589 1.09306 3.26995L5.36282 8.012L1.11468 12.73C0.390706 13.5341 0.455625 14.7728 1.25968 15.4968C2.06373 16.2208 3.30244 16.1558 4.02641 15.3518L7.999 10.9398L11.9716 15.3518C12.6956 16.1558 13.9343 16.2207 14.7383 15.4968C15.5424 14.7728 15.6073 13.5341 14.8833 12.73L10.6352 8.012L14.9049 3.26996C15.6289 2.4659 15.564 1.22719 14.7599 0.503221C13.9559 -0.220752 12.7172 -0.155834 11.9932 0.648219L7.999 5.08423L4.00479 0.648211Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                    Exclude selection
                                </RedActionButton>
                                :
                                <GreenActionButton
                                    onClick={() => includeComps()}
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="17" 
                                        height="16" 
                                        viewBox="0 0 17 16" 
                                        fill="none"
                                        className="subtext-margin-right"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M15.8237 1.43765C16.4657 2.0449 16.4938 3.05758 15.8866 3.69952L6.55323 13.5662C6.25633 13.8801 5.84511 14.0605 5.4131 14.0665C4.98109 14.0725 4.56502 13.9035 4.25951 13.598L0.792848 10.1314C0.168009 9.50653 0.168009 8.49347 0.792848 7.86863C1.41769 7.24379 2.43075 7.24379 3.05559 7.86863L5.35902 10.1721L13.5619 1.50048C14.1691 0.858536 15.1818 0.830406 15.8237 1.43765Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                    Include Selection
                                </GreenActionButton>
                            }
                        </div>
                    </div>
                </div>
            }
            <div
                ref={scrollContainer}
                className="rental-comps-body-container margin-x-large"
            >
                <table className="rental-comps-table-container">
                    <thead className="">
                        <tr className="rental-comps-label-row">
                            <th className="rental-comps-table-checkbox-container mobile-none">
                                <div 
                                    className="checkbox-container"
                                    onClick={() => toggleAllCheckboxes()}
                                >
                                {
                                        comps !== null && selectedComps.length === (comps.length - excludedComps.length) && checkSelectedCompExclusion() === false ?
                                        <div className="checkbox-icon">
                                        </div>
                                        :
                                        null
                                }
                                </div>
                            </th>
                            {
                                labels.map((item, index) => 
                                    <th
                                        className={"rental-comps-table-label relative-container " + (item.val === null ? "" : "cursor-pointer")}
                                        key={index}
                                        onMouseEnter={() => setHoverOption(item.val)}
                                        onMouseLeave={() => setHoverOption(null)}
                                        onClick={() => item.val === null ? null : reorderComps(item.val)}
                                    >
                                        <span className={"body-semibold colour-primary float-left " + (index === 0 ? "block-text" : "")}>
                                            {item.label}
                                        </span>
                                        {
                                            (item.val === null) || (item.val !== sorting && item.val !== hoverOption) ?
                                            null
                                            :
                                            <div className="rental-comps-icon-container">
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    viewBox="0 0 16 16" 
                                                    fill="none"
                                                >
                                                    <path 
                                                        fillRule="evenodd" 
                                                        clipRule="evenodd"
                                                        d="M8.35401 5.87499L4.67098 2.19197C4.44497 1.96596 4.07854 1.96596 3.85253 2.19197L0.169507 5.87499C-0.0565021 6.101 -0.0565023 6.46743 0.169507 6.69344C0.395515 6.91945 0.761948 6.91945 0.987957 6.69344L3.68303 3.99837L3.68302 12.3094C3.68302 12.629 3.94213 12.8881 4.26176 12.8881C4.58138 12.8881 4.84049 12.629 4.84049 12.3094L4.84049 3.99837L7.53556 6.69344C7.76156 6.91945 8.128 6.91945 8.35401 6.69344C8.58002 6.46743 8.58002 6.101 8.35401 5.87499ZM15.8305 10.1243L12.1475 13.8074C11.9215 14.0334 11.555 14.0334 11.329 13.8074L7.64599 10.1243C7.41999 9.89834 7.41999 9.53191 7.64599 9.3059C7.872 9.07989 8.23844 9.07989 8.46444 9.3059L11.1595 12.001L11.1595 3.68993C11.1595 3.37031 11.4186 3.1112 11.7382 3.1112C12.0579 3.1112 12.317 3.37031 12.317 3.68993L12.317 12.001L15.012 9.3059C15.2381 9.07989 15.6045 9.07989 15.8305 9.3059C16.0565 9.53191 16.0565 9.89834 15.8305 10.1243Z" 
                                                        fill={item.val === sorting ? colour.grayScaleBlack : colour.grayScaleGray03}
                                                    />
                                                </svg>
                                            </div>
                                        }
                                    </th>
                                )
                            }
                        </tr>
                        <tr className="rental-comps-subject-property-row">
                            <th className="rental-comps-table-checkbox-container bg-colour-white mobile-none">
                                <span className="label-regular-caps colour-secondary block-text text-align-left margin-left-medium">
                                    SUB.<br/>PROP.
                                </span>
                            </th>
                            {
                                subjectProperty.map((item, index) => 
                                    <th
                                        className={item.class}
                                        key={index}
                                    >
                                        <span className={item.innerClass}>
                                            {item.label}
                                        </span>
                                    </th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            comps === null ?
                            null
                            :
                            sortedComps.map((item, index) =>
                                checkCompActive(item) === false || checkCompExcluded(item) === true ?
                                null
                                :
                                <tr
                                    key={index}
                                    className={"rental-comps-table-row cursor-pointer " + (checkSelectedComp(item) ? "rental-comps-table-row-selected" : "")}
                                    onClick={() => toggleCheckbox(item)}
                                >
                                    <td className="rental-comps-table-checkbox-container mobile-none">
                                        <div 
                                            className="checkbox-container"
                                            onClick={() => toggleCheckbox(item)}
                                        >
                                            {
                                                checkSelectedComp(item) ?
                                                <div className="checkbox-icon">
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </td>
                                    <td className={"rental-comps-table-value rental-comps-table-value-address rental-comps-blur-subject " + (item.detailUrl !== undefined && item.detailUrl !== null && item.detailUrl !== "" ? "rental-comps-link-container" : "")}> 
                                        <div className="rental-comps-address-row">
                                            <span 
                                                className={"body-regular block-text subtext-margin-right " + (partnerEdit === true ? "" : item.userInputted === true && item.zillowURL === "" ? "" : item.detailUrl !== undefined && item.detailUrl !== null ? "colour-link" : "")}
                                                onClick={() => partnerEdit === true ? null : item.userInputted === true && item.zillowURL === "" ? null : item.detailUrl !== undefined && item.detailUrl !== null ? openZillow(item) : null}
                                            >
                                                {
                                                    blurred === true ?
                                                    "1234 Main St"
                                                    :
                                                    item.address.streetAddress
                                                }
                                            </span>
                                            {
                                                partnerEdit === true ? null : item.userInputted === true && item.zillowURL === "" ? null : item.detailUrl !== undefined && item.detailUrl !== null ?
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="8" 
                                                    height="8" 
                                                    viewBox="0 0 8 8" 
                                                    fill="none"
                                                >
                                                    <path 
                                                        d="M8 0.72392V7.23918C8 7.63899 7.67589 7.9631 7.27608 7.9631C6.87627 7.9631 6.55216 7.63899 6.55216 7.23919L6.55216 2.47161L1.23581 7.78797C0.953098 8.07068 0.494739 8.07068 0.212031 7.78797C-0.0706771 7.50526 -0.0706768 7.0469 0.212031 6.76419L5.52839 1.44784L0.760814 1.44784C0.361005 1.44784 0.0368958 1.12373 0.0368958 0.723919C0.0368957 0.324109 0.361005 0 0.760815 0L7.27608 1.07131e-06C7.67589 1.07131e-06 8 0.324111 8 0.72392Z" 
                                                        fill="#2F4858"
                                                    />
                                                </svg>
                                                :
                                                null
                                            }
                                        </div>
                                        <span className="label-regular colour-secondary block-text">
                                            {item.address.city}, {item.address.state} {item.address.zipcode}
                                        </span>

                                        {
                                            partnerEdit === true ?
                                            <span 
                                                className="body-regular underline colour-error cursor-pointer"
                                                onClick={() => deleteComp(item)}
                                            >
                                                Delete
                                            </span>
                                            :
                                            null
                                        }
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-distance-container rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {item.distance === null ? "null" : `${item.distance.toFixed(2)} mi`}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-similarity-container rental-comps-blur-subject"> 
                                        <span className={"colour-primary block-text " + (item.userInputted === true ? "body-regular" : "body-semibold")}>
                                            {
                                                blurred === true ?
                                                "99%"
                                                :
                                                item.userInputted === true ?
                                                "Partner inputted"
                                                :
                                                percentage.format(item.similarity_score)
                                            }
                                        </span>
                                        {
                                            item.userInputted === true ?
                                            null
                                            :
                                            <div className="rental-comps-progress-bar-outer-container">
                                                <ProgressBar
                                                    item={item}
                                                />
                                            </div>
                                        }
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text">
                                            {
                                                propertySearch === true ?
                                                "Unknown for Quick Report"
                                                :
                                                item.listingStatus === "RECENTLY_SOLD" ?
                                                "Sold"
                                                :
                                                item.listingStatus === "FOR_SALE" ?
                                                "For sale"
                                                :
                                                item.listingStatus
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {
                                                blurred === true ?
                                                "$999,999"
                                                :
                                                formatterCompact.format(item.price).replace(".00", "")
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {
                                                item.pricePerSquareFoot === null ?
                                                "N/A"
                                                :
                                                formatterLong.format(item.pricePerSquareFoot).replace(".00", "")
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {
                                                item.livingArea === null ?
                                                "N/A"
                                                :
                                                numberFormatter.format(item.livingArea)
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {item.bedrooms}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {item.bathrooms}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {item.yearBuilt === null ? "N/A" : item.yearBuilt}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-primary block-text">
                                            {item.lotAreaValue === null ? "N/A" : `${numberFormatter.format(item.lotAreaValue)}`}
                                        </span>
                                    </td>
                                    {
                                        propertySearch === true ?
                                        <td className="rental-comps-table-value rental-comps-blur-subject">
                                            <span className="body-regular colour-secondary block-text">
                                                N/A
                                            </span>
                                        </td>
                                        :
                                        blurred === true ?
                                        <td className="rental-comps-table-value rental-comps-blur-subject">
                                            <span className="body-regular colour-secondary block-text">
                                                1 Jan. 1970
                                            </span>
                                        </td>
                                        :
                                        <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            <span className="body-regular colour-primary block-text text-align-left">
                                                {findListingDateDifference(item.activityDate)}
                                            </span>
                                            <span className="label-regular colour-secondary block-text text-align-left">
                                                {moment(item.activityDate).format("MMM D, YYYY")}
                                            </span>
                                        </td>
                                    }
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text">
                                            {
                                                item.propertyType === "SINGLE_FAMILY" ? 
                                                "Single Family" 
                                                :
                                                item.propertyType === "MULTI_FAMILY" ? 
                                                "Multi Family" 
                                                :
                                                item.propertyType
                                            }
                                        </span>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            excludedComps.length > 0 ?
                                <tr
                                    className=""
                                >
                                    <td className="rental-comps-table-checkbox-container mobile-none">
                                        
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-table-value-address rental-comps-blur-subject"> 
                                        <div
                                            className="show-hide-excluded-comps mobile-none text-button button-row"
                                            onClick={() => toggleExcludedComps()}
                                        >
                                            <span className="body-regular text-link block-text subtext-margin-right">
                                                {
                                                    showExcludedComps === false ?
                                                    "Show excluded comps"
                                                    :
                                                    "Hide excluded comps"
                                                }
                                            </span>
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                viewBox="0 0 16 16" 
                                                fill="none"
                                                className={showExcludedComps === false ? "reverse" : ""}
                                            >
                                                <path 
                                                    fillRule="evenodd" 
                                                    clipRule="evenodd" 
                                                    d="M0.242395 12.5943C0.565587 12.9054 1.08959 12.9054 1.41278 12.5943L8 6.25327L14.5872 12.5943C14.9104 12.9054 15.4344 12.9054 15.7576 12.5943C16.0808 12.2831 16.0808 11.7787 15.7576 11.4676L8 4L0.242395 11.4676C-0.0807978 11.7787 -0.0807978 12.2831 0.242395 12.5943Z" 
                                                    fill="#2F4858"
                                                />
                                            </svg>
                                        </div>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-distance-container rental-comps-blur-subject"> 
                                        
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-similarity-container rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                    </td>
                                </tr>
                            :
                            null
                        }
                        {
                            showExcludedComps === false ?
                            null
                            :
                            sortedComps.map((item, index) =>
                                checkCompExcluded(item) === false ?
                                null
                                :
                                <tr
                                    key={index}
                                    className={"rental-comps-table-row excluded-comps-table-row " + (checkSelectedComp(item) ? "rental-comps-table-row-selected" : "")}
                                    onClick={() => toggleExcludedCheckbox(item)}
                                >
                                    <td className="rental-comps-table-checkbox-container mobile-none">
                                        <div 
                                            className="checkbox-container"
                                            onClick={() => toggleExcludedCheckbox(item)}
                                        >
                                            {
                                                checkSelectedComp(item) ?
                                                <div className="checkbox-icon">
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </td>
                                    <td className={"rental-comps-table-value rental-comps-table-value-address rental-comps-blur-subject " + (item.detailUrl !== undefined && item.detailUrl !== null && item.detailUrl !== "" ? "rental-comps-link-container" : "")}> 
                                        <div className="rental-comps-address-row">
                                            <span 
                                                className={"body-regular block-text subtext-margin-right text-italic " + (partnerEdit === true ? "" : item.userInputted === true && item.zillowURL === "" ? "" : item.detailUrl !== undefined && item.detailUrl !== null ? "colour-link" : "")}
                                                onClick={() => partnerEdit === true ? null : item.userInputted === true && item.zillowURL === "" ? null : item.detailUrl !== undefined && item.detailUrl !== null ? openZillow(item) : null}
                                            >
                                                {
                                                    blurred === true ?
                                                    "1234 Main St"
                                                    :
                                                    item.address.streetAddress
                                                }
                                            </span>
                                            {
                                                partnerEdit === true ? null : item.userInputted === true && item.zillowURL === "" ? null : item.detailUrl !== undefined && item.detailUrl !== null ?
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="8" 
                                                    height="8" 
                                                    viewBox="0 0 8 8" 
                                                    fill="none"
                                                >
                                                    <path 
                                                        d="M8 0.72392V7.23918C8 7.63899 7.67589 7.9631 7.27608 7.9631C6.87627 7.9631 6.55216 7.63899 6.55216 7.23919L6.55216 2.47161L1.23581 7.78797C0.953098 8.07068 0.494739 8.07068 0.212031 7.78797C-0.0706771 7.50526 -0.0706768 7.0469 0.212031 6.76419L5.52839 1.44784L0.760814 1.44784C0.361005 1.44784 0.0368958 1.12373 0.0368958 0.723919C0.0368957 0.324109 0.361005 0 0.760815 0L7.27608 1.07131e-06C7.67589 1.07131e-06 8 0.324111 8 0.72392Z" 
                                                        fill="#2F4858"
                                                    />
                                                </svg>
                                                :
                                                null
                                            }
                                        </div>
                                        <span className="label-regular text-italic colour-secondary block-text">
                                            {item.address.city}, {item.address.state} {item.address.zipcode}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-distance-container rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {item.distance === null ? "null" : `${item.distance.toFixed(2)} mi`}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-excluded-element rental-comps-similarity-container rental-comps-blur-subject"> 
                                        <span className={"colour-primary block-text text-italic " + (item.userInputted === true ? "body-regular" : "body-semibold")}>
                                            {
                                                blurred === true ?
                                                "99%"
                                                :
                                                item.userInputted === true ?
                                                "Partner inputted"
                                                :
                                                percentage.format(item.similarity_score)
                                            }
                                        </span>
                                        {
                                            item.userInputted === true ?
                                            null
                                            :
                                            <div className="rental-comps-progress-bar-outer-container">
                                                <ProgressBar
                                                    item={item}
                                                />
                                            </div>
                                        }
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {
                                                item.listingStatus === "RECENTLY_SOLD" ?
                                                "Sold"
                                                :
                                                item.listingStatus === "FOR_SALE" ?
                                                "For sale"
                                                :
                                                item.listingStatus
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {
                                                blurred === true ?
                                                "$999,999"
                                                :
                                                formatterCompact.format(item.price).replace(".00", "")
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {
                                                item.pricePerSquareFoot === null ?
                                                "N/A"
                                                :
                                                formatterLong.format(item.pricePerSquareFoot).replace(".00", "")
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {
                                                item.livingArea === null ?
                                                "N/A"
                                                :
                                                numberFormatter.format(item.livingArea)
                                            }
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {item.bedrooms}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {item.bathrooms}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {item.yearBuilt === null ? "N/A" : item.yearBuilt}
                                        </span>
                                    </td>
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {item.lotAreaValue === null ? "N/A" : `${numberFormatter.format(item.lotAreaValue)}`}
                                        </span>
                                    </td>
                                    {
                                        blurred === true ?
                                        <td className="rental-comps-table-value rental-comps-blur-subject">
                                            <span className="body-regular colour-secondary block-text text-italic">
                                                1 Jan. 1970
                                            </span>
                                        </td>
                                        :
                                        <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            <span className="body-regular colour-primary block-text text-align-left text-italic">
                                                {findListingDateDifference(item.activityDate)}
                                            </span>
                                            <span className="label-regular colour-secondary block-text text-align-left text-italic">
                                                {moment(item.activityDate).format("MMM D, YYYY")}
                                            </span>
                                        </td>
                                    }
                                    <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                        <span className="body-regular colour-secondary block-text text-italic">
                                            {
                                                item.propertyType === "SINGLE_FAMILY" ? 
                                                "Single Family" 
                                                :
                                                item.propertyType === "MULTI_FAMILY" ? 
                                                "Multi Family" 
                                                :
                                                item.propertyType
                                            }
                                        </span>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default ARVComps;