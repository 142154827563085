import React from 'react';

function TickThick(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M15.4995 1.43765C16.1415 2.0449 16.1696 3.05758 15.5623 3.69952L6.22901 13.5662C5.93211 13.8801 5.52089 14.0605 5.08888 14.0665C4.65687 14.0725 4.2408 13.9035 3.93529 13.598L0.468629 10.1314C-0.15621 9.50653 -0.15621 8.49347 0.468629 7.86863C1.09347 7.24379 2.10653 7.24379 2.73137 7.86863L5.0348 10.1721L13.2376 1.50048C13.8449 0.858536 14.8576 0.830406 15.4995 1.43765Z" 
                fill={fill}
            />
        </svg>
    )
};

export default TickThick;