import React from 'react';
import '../styles/MobileTab.css';

function MobileTab(props) {
    const item = props.item;
    const title = props.title;
    const active = props.active;
    const cta = props.cta;
    const icon = props.icon;
    const disabled = props.disabled;
    const line = props.line;

    return (
        <div 
            className={"mobile-tab-outer-container " + (disabled === true ? "disabled" : "cursor-pointer")}
            onClick={() => disabled === true ? null : cta(item)}
        >
            {
                icon !== null &&
                icon
            }
            <span className={"block-text text-align-center " + (active === true ? "xs-label-semibold colour-primary" : "xs-label colour-secondary")}>
                {title}
            </span>
            {
                line === true && active === true &&
                <div className="mobile-tab-line-element">
                </div>
            }
        </div>
    )
};

export default MobileTab;