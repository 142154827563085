import React from 'react';
import '../styles/Modal.css';
import { Button, IconButton } from './';
import { ModalExit } from '../../assets';
import { Loading } from '../../components';

function Modal(props) {
    const heading = props.heading;
    const subheading = props.subheading;
    const body = props.body;
    const type = props.type;
    const cancelText = props.cancelText;
    const cancelButtonCTA = props.cancelButtonCTA;
    const actionButtonText = props.actionButtonText;
    const actionButtonCTA = props.actionButtonCTA;
    const secondaryButtonText = props.secondaryButtonText;
    const secondaryButtonCTA = props.secondaryButtonCTA;
    const exitButtonCTA = props.exitButtonCTA;
    const loading = props.loading;
    const actionButtonDisabled = props.actionButtonDisabled;
    const leftIcon = props.leftIcon;
    const rightIcon = props.rightIcon;
    const cancelLeftIcon = props.cancelLeftIcon;
    const cancelRightIcon = props.cancelRightIcon;
    const customClass = props.customClass;

    return (
        <div className={"modal-outer-container " + (customClass === null ? "" : customClass)}>
            <div className="relative-container modal-exit-outer-container">
                {
                    loading &&
                    <Loading />
                }
                {
                    exitButtonCTA === null ?
                    null
                    :
                    <div className="modal-exit-container cursor-pointer">
                        <IconButton
                            size="medium"
                            icon={ModalExit}
                            disabled={false}
                            clickFunc={exitButtonCTA}
                            standardIcon={true}
                            contextTooltip={false}
                            contextTooltipBG={null}
                            contextTooltipText={null}
                            customClass={null}
                        />
                    </div>
                }
            </div>
            <div className="modal-heading-container margin-medium">
                <h2 className="heading-large-semibold colour-primary">
                    {heading}
                </h2>
                {
                    subheading !== null ?
                    <span className="body-regular colour-secondary block-text">
                        {subheading}
                    </span>
                    :
                    null
                }
            </div>
            <div className="grey-divider">
            </div>
            <div className="modal-body-container">
                {body}
            </div>
            <div className="modal-buttons-container">
                {
                    type === "single" || type === "none" || cancelText === null ?
                    null
                    :
                    <div 
                        className="modal-cancel-button text-button"
                        onClick={() => cancelButtonCTA()}
                    >
                        {
                            cancelLeftIcon !== null &&
                            cancelLeftIcon
                        }
                        <span className="body-regular text-link">
                            {cancelText}
                        </span>
                        {
                            cancelRightIcon !== null &&
                            cancelRightIcon
                        }
                    </div>
                }
                {
                    type === "none" ?
                    null
                    :
                    type === "single" ?
                    <div className="modal-buttons-single-cta">
                        <Button
                            buttonType="primary-button"
                            size="large"
                            text={actionButtonText}
                            cta={actionButtonCTA}
                            disabled={actionButtonDisabled}
                            leftIcon={leftIcon}
                            leftIconClass=""
                            rightIcon={rightIcon}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                    :
                    type === "accent" ?
                    <div className="modal-buttons-basic-cta">
                        <Button
                            buttonType="accent-button"
                            size="large"
                            text={actionButtonText}
                            cta={actionButtonCTA}
                            disabled={actionButtonDisabled}
                            leftIcon={leftIcon}
                            leftIconClass=""
                            rightIcon={rightIcon}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                    :
                    type === "basic" ?
                    <div className="modal-buttons-basic-cta">
                        <Button
                            buttonType="primary-button"
                            size="large"
                            text={actionButtonText}
                            cta={actionButtonCTA}
                            disabled={actionButtonDisabled}
                            leftIcon={leftIcon}
                            leftIconClass=""
                            rightIcon={rightIcon}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                    :
                    type === "destructive" ?
                    <div className="modal-buttons-basic-cta">
                        <Button
                            buttonType="destructive-button"
                            size="large"
                            text={actionButtonText}
                            cta={actionButtonCTA}
                            disabled={actionButtonDisabled}
                            leftIcon={leftIcon}
                            leftIconClass=""
                            rightIcon={rightIcon}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                    :
                    type === "secondary" ?
                    <div className="modal-buttons-secondary-cta">
                        <div className="modal-secondary-button-element">
                            <Button
                                buttonType="secondary-button"
                                size="large"
                                text={secondaryButtonText}
                                cta={secondaryButtonCTA}
                                disabled={false}
                                leftIcon={leftIcon}
                                leftIconClass=""
                                rightIcon={rightIcon}
                                rightIconClass=""
                                alt=""
                                standardIcon={false}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                        <div className="modal-primary-button-element">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={actionButtonText}
                                cta={actionButtonCTA}
                                disabled={actionButtonDisabled}
                                leftIcon={leftIcon}
                                leftIconClass=""
                                rightIcon={rightIcon}
                                rightIconClass=""
                                alt=""
                                standardIcon={false}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
};

export default Modal;