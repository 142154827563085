import React from 'react';
import '../styles/StrategyLoading.css';
import { StrategiesLoader } from '../assets/animations';
import { useLottie } from 'lottie-react';
import { SingleEspressoShot } from '../assets/images';

function StrategyLoading(props) {
    const index = props.index;
    const strategyText = [
        "Retrieving turnkey properties in appreciating neighborhoods",
        "Calculating rent-by-unit cash flow",
        "Locating fixer-uppers with best ROI",
        "Scanning for seller finance, ADUs, back on market, and more…"
    ];

    const options = {
        animationData: StrategiesLoader,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div className="strategy-loading-container relative-container">
            {View}
            <div className="strategy-loading-text-container">
                <img
                    src={SingleEspressoShot}
                    className="strategy-loading-image"
                    alt="Espresso Shots"
                />
                <span className="body-semibold colour-primary block-text text-align-center">
                    {strategyText[index]}
                </span>
            </div>
        </div>
    )
};

export default StrategyLoading;