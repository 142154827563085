import React from 'react';
import '../styles/PricingCard.css';
import { useNavigate } from 'react-router-dom';
import { recordEvent } from "../functions";
import { BestValue } from './';
import { Button } from '../v4/components';

function PricingCard(props) {
    const item = props.item;
    const index = props.index;
    const devMode = props.devMode;
    const firstPricingOption = props.firstPricingOption;
    const freeTrial = props.freeTrial;
    const blackFriday = props.blackFriday;
    const navigate = useNavigate();

    const startFreeTrial = () => {
        navigate("/sign-up");
    };

    const complete = () => {
        recordEvent("Plan Selected", {
            months: firstPricingOption === true ? 1 : 12,
            price: item.total / 100,
            counties: item.counties
        });

        const newState = {
            route: "/pay",
            allCities: false,
            price: item.amount,
            subscriptionId: devMode === true ? item.testSubscriptionId : item.subscriptionId,
            autoBilling: firstPricingOption === true ? true : false,
            plan: {
                total: item.total / 100,
                originalPrice: blackFriday === true ? item.undiscountedPrice / 100 : item.amount / 100,
                months: firstPricingOption === true ? 1 : 12,
                price: firstPricingOption === true ? item.total / 100 : item.total / 1200,
                title: item.title,
                label: item.label,
                counties: item.counties,
                creative: item.creative,
                discount: firstPricingOption === true ? 0 : item.discountRate
            }
        };
        navigate(item.route, {
            state: newState
        });
    };

    return (
        <div className="pricing-card-outer-container">
            {
                index === 2 ?
                <BestValue
                    text="✨Recommended✨"
                />
                :
                null
            }
            <div className="pricing-card-top-inner-container">
                <span className="heading-large-semibold text-link text-align-center block-text">
                    {item.title}
                </span>
                <div className="pricing-card-subscription-type-container">
                    <span className="heading-small-regular text-link text-align-center block-text">
                        {item.label}
                    </span>
                </div>
                <div className="pricing-card-image-container">
                    <img
                        src={item.image}
                        className="pricing-card-image"
                        alt={item.label}
                    />
                </div>
                {
                    item.price !== undefined ?
                    <div className="pricing-card-row-container">
                        <span className="heading-large-light colour-secondary strike-through block-text">
                            {item.oldPrice}
                        </span>
                        <h2 className="heading-large-semibold text-link">
                            {item.price}
                        </h2>
                        <span className="body-regular colour-secondary">
                            {item.duration}
                        </span>
                        {
                            item.extraSavings !== "" &&
                            <span className="heading-small-semibold colour-light-blue">
                                {item.extraSavings}
                            </span>
                        }
                    </div>
                    :
                    null
                }
                <div className="pricing-card-best-for-container">
                    <span className="body-regular colour-secondary block-text text-align-center text-italic">
                        {item.bestFor}
                    </span>
                </div>
                {
                    index === 0 && freeTrial === true ?
                    null
                    :
                    <div className="pricing-card-button-container">
                        <Button
                            buttonType="primary-button"
                            text={item.ctaText}
                            cta={() => freeTrial === false && index === 0 ? startFreeTrial() : complete()}
                            disabled={false}
                            leftIcon={null}
                            rightIcon={null}
                            alt=""
                            size="large"
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                        {
                            item.ctaExtraText !== null ?
                            <span className="body-regular colour-primary text-italic text-align-center block-text">
                                {item.ctaExtraText}
                            </span>
                            :
                            null
                        }
                    </div>
                }
            </div>
            <div className="pricing-card-features-list">
                {
                    item.features.length === 0 ?
                    null
                    :
                    <ul className="pricing-card-features-list-ul">
                        {
                            item.features.map((item, index) => (
                                item.text === null ?
                                null
                                :
                                <li
                                    key={index}
                                    className="pricing-card-features-list-element"
                                >
                                    <div className="">
                                        <img
                                            src={item.icon}
                                            className="pricing-card-features-list-element-icon"
                                            alt="Tick"
                                        />
                                    </div>
                                    <div className="pricing-card-features-list-element-row">
                                        <div className="pricing-card-features-list-element-text-container">
                                            {
                                                item.text.includes("No 1-on-1") ?
                                                <span className="body-regular colour-error">
                                                    {item.text}
                                                </span>
                                                :
                                                item.singleLine === true ?
                                                <span className="body-regular colour-secondary">
                                                    {item.text}
                                                </span>
                                                :
                                                item.singleLine === false && item.boldText === "BONUS:" ?
                                                <span className="body-regular colour-regular">
                                                    {item.firstText} <span className={"text-bold colour-green " + (item.link !== "" ? "underline cursor-pointer" : "")}>{item.boldText}</span> {item.secondText}
                                                </span>
                                                :
                                                <span className="body-regular colour-secondary">
                                                    {item.firstText} <span className={"text-bold colour-primary " + (item.link !== "" ? "underline cursor-pointer" : "")}>{item.boldText}</span> {item.secondText}
                                                </span>
                                            }
                                        </div>
                                        {
                                            item.endsSoon === true ?
                                            <div className="pricing-card-ends-soon-container bg-colour-green-2">
                                                <span className="label-semibold-caps colour-white block-text text-align-center">
                                                    ENDS SOON
                                                </span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
    )
}

export default PricingCard;