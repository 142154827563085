import React, { useEffect, useState } from 'react';
import '../styles/ResetPassword.css';
import { Header, Footer, Loading } from '../components';
import { cloudFunctionV2 } from '../functions';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, FinancialsInput } from '../v4/components';

function ResetPassword() {
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [expired, setExpired] = useState(false);
    const [invalidParams, setInvalidParams] = useState(false);
    const [searchParams] = useSearchParams({});
    const oobCode = searchParams.get('oobCode');
    const apiKey = searchParams.get('apiKey');
    const mode = searchParams.get('mode');
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [changeLoading, setChangeLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Reset Password | Coffee Clozers";

        const checkResetPassword = async() => {
            if ( oobCode === null || apiKey === null || mode === null ) {
                navigate("/home");
            }
            else {
                const gatewayURL = process.env.REACT_APP_VALIDATE_PW_RESET;
                const params = {
                    oobCode: oobCode,
                    apiKey: apiKey,
                    mode: mode,
                    readOnly: true,
                    password: null
                };
                const queryData = await cloudFunctionV2(gatewayURL, params);
                if ( queryData.status === 200 ) {
                    setEmail(queryData.email);
                }
                else if ( queryData.error === "Link has expired" ) {
                    setExpired(true);
                }
                else {
                    setInvalidParams(true);
                }
                setLoading(false);
            }
        };

        checkResetPassword();
    }, [apiKey, mode, oobCode, navigate]);

    const forgotPassword = async() => {
        navigate("/password-forgotten");
    };

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const onChangeRepeatPassword = (e) => {
        setRepeatPassword(e.target.value);
    };

    const login = async() => {
        navigate("/login");
    };

    const complete = async() => {
        setChangeLoading(true);
        if ( password === repeatPassword ) {
            setPasswordMatch(true);

            const gatewayURL = process.env.REACT_APP_VALIDATE_PW_RESET;
            const params = {
                oobCode: oobCode,
                apiKey: apiKey,
                mode: mode,
                readOnly: false,
                password: password
            };
            const queryData = await cloudFunctionV2(gatewayURL, params);
            if ( queryData.status === 200 ) {
                setErrorMessage("");
                setSuccess(true);
            }
            else {
                if ( queryData.error === "Link has expired" ) {
                    setExpired(true);
                }
                else if ( queryData.message === "Couldn't get user ID from email" ) {
                    setErrorMessage("We couldn't find your email in our system, please contact us for more details");
                }
                else if ( queryData.error.code === "auth/invalid-password" ) {
                    setErrorMessage("Your new password must have at least 6 characters");
                }
                else if ( queryData.error.code === "auth/network-request-failed" ) {
                    setErrorMessage("Connection failed, please try again");
                }
                else {
                    console.log("queryData.error = ", queryData.error);
                    setErrorMessage("Something went wrong, please try again or contact us");
                }
            }
        }
        else {
            setPasswordMatch(false);
            return;
        }

        setChangeLoading(false);
    };

    return (
        <div className="reset-password-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
                desktopControl={true}
            />
            {
                loading === true ?
                <div className="reset-password-inner-container relative-container">
                    <Loading />
                </div>
                :
                success === true ?
                <div className="reset-password-inner-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Password reset successful
                    </h1>
                    <div className="reset-password-button-container">
                        <Button
                            buttonType="primary-button"
                            size="large"
                            text={"Login"}
                            cta={login}
                            disabled={false}
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                </div>
                :
                invalidParams === true || expired === true ?
                <div className="reset-password-inner-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        {
                            invalidParams === true ?
                            "Invalid parameters"
                            :
                            expired === true ?
                            "Link has expired"
                            :
                            "Something went wrong"
                        }
                    </h1>
                    <div className="reset-password-button-container">
                        <Button
                            buttonType="primary-button"
                            size="large"
                            text={"Start again"}
                            cta={forgotPassword}
                            disabled={false}
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                </div>
                :
                <div className="reset-password-inner-container relative-container">
                    {
                        changeLoading === true &&
                        <Loading />
                    }
                    <div className="reset-password-title-container">
                        <h1 className="heading-large-semibold colour-primary text-align-center">
                            Reset your password
                        </h1>
                    </div>
                    <div className="reset-password-body-container">
                        <span className="body-regular colour-primary underline">
                            {email}
                        </span>
                        <div className="reset-password-input-container">
                            <FinancialsInput
                                label={"Password"}
                                labelType={"inside"}
                                info={false}
                                value={password}
                                onChange={onChangePassword}
                                placeholder={"we gonna remember it this time?"}
                                type="password"
                                leftUnit={null}
                                rightUnit={null}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={false}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={false}
                                resetFunc={null}
                                resetPosition={null}
                            />
                        </div>
                        <div className="reset-password-input-container">
                            <FinancialsInput
                                label={"Repeat password"}
                                labelType={"inside"}
                                info={false}
                                value={repeatPassword}
                                onChange={onChangeRepeatPassword}
                                placeholder={"nope, but that's okay"}
                                type="password"
                                leftUnit={null}
                                rightUnit={null}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={false}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={false}
                                resetFunc={null}
                                resetPosition={null}
                            />
                        </div>
                        {
                            passwordMatch === false &&
                            <span className="body-regular colour-error">
                                Ideally these would match..
                            </span>
                        }
                        {
                            errorMessage !== "" &&
                            <span className="body-regular colour-error">
                                {errorMessage}
                            </span>
                        }
                    </div>
                    <div className="repeat-password-button-container">
                        <Button
                            buttonType="primary-button"
                            size="large"
                            text={"Save new password"}
                            cta={complete}
                            disabled={password === "" || repeatPassword === "" ? true : changeLoading}
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default ResetPassword;