import React, { useEffect, useState } from 'react';
import '../styles/Comparables.css';
import { ARVComps, RentalComps } from './';
import { Button, FilterChip } from '../v4/components';
import { RedirectArrow } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';

function Comparables(props) {
    const property = props.property;
    const blurred = props.blurred;
    const propertySearch = props.propertySearch;
    const compsRef = props.compsRef;
    const selectedPanel = props.selectedPanel;
    const setSelectedPanel = props.setSelectedPanel;
    const runComps = props.runComps;
    const savedData = props.savedData;
    const setSavedData = props.setSavedData;
    const savedRentData = props.savedRentData;
	const setSavedRentData = props.setSavedRentData;
    const editedProperty = props.editedProperty;
    const setEditedProperty = props.setEditedProperty;
    const loanYears = props.loanYears;
    const applyEstimateModal = props.applyEstimateModal;
	const setApplyEstimateModal = props.setApplyEstimateModal;
    const [firstRender, setFirstRender] = useState(true);
    const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
    const rentalComps = multipleUniqueUnits === true && property.propertyTypeDimension === "Multi Family" ?
        property.unitPropertyComps
        :
        property.propertyTypeDimension === "Multi Family" && property.uniqueUnitPropertyDetails !== undefined ?
        property.unitPropertyComps
        :
        property.rentComps !== undefined && property.rentComps !== null ?
        property.rentComps
        :
        [];

    useEffect(() => {

        const newRentalComps = multipleUniqueUnits === true && property.propertyTypeDimension === "Multi Family" ?
            property.unitPropertyComps
            :
            property.propertyTypeDimension === "Multi Family" && property.uniqueUnitPropertyDetails !== undefined ?
            property.unitPropertyComps
            :
            property.rentComps !== undefined && property.rentComps !== null ?
            property.rentComps
            :
            [];

        const checkRentalComps = async() => {
            setFirstRender(false);

            if ( property.unitPropertyComps !== undefined && property.unitPropertyComps !== null && property.unitPropertyComps.length === 0 ) {
                setSelectedPanel(1);
            }
            else if ( property.rentComps !== undefined && property.rentComps !== null && property.rentComps.length === 0 ) {
                setSelectedPanel(1);
            }
            else if ( property.rentComps === undefined && property.unitPropertyComps === undefined ) {
                setSelectedPanel(1);
            }
            else if ( (newRentalComps === undefined ||  newRentalComps === null || newRentalComps.length === 0) && property.arvComps === true ) {
                setSelectedPanel(1);
            }
        };

        if ( firstRender === true ) {
            checkRentalComps();
        }
    }, [firstRender, property.rentComps, property.unitPropertyComps, setSelectedPanel, multipleUniqueUnits, property.arvComps, property.propertyTypeDimension, property.uniqueUnitPropertyDetails]);

    const compOptions = [
        {
            label: "Rental comps",
            disabled: rentalComps === undefined || rentalComps === null || rentalComps.length === 0 ? true : false
        },
        {
            label: property.propertyCondition === "Moderate Rehab" || property.propertyCondition === "Gut Rehab" ? "ARV comps" : "Sales comps",
            disabled: !property.arvComps
        }
    ];

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    };

    const openRentcast = () => {
        window.open("https://www.rentcast.io", "_blank");
    };

    return (
        <div 
            className="rental-comps-outer-container"
            ref={compsRef}
        >
            <div className="rental-comps-inner-container">
                <div className="rental-comps-title-container">
                    <h1 className="heading-small-regular colour-primary">
                        Comparables
                    </h1>
                </div>
                <div className="comparables-chips-outer-row-container">
                    <div className="rental-comps-chips-row">
                        {
                            property.arvComps === true ?
                            compOptions.map((item, index) =>
                                <FilterChip
                                    label={item.label}
                                    selected={selectedPanel}
                                    index={index}
                                    disabled={item.disabled}
                                    leftIcon={null}
                                    rightIcon={null}
                                    func={changeSelectedPanel}
                                    key={index}
                                />
                            )
                            :
                            null
                        }
                    </div>
                    {
                        ( (selectedPanel === 0 && rentalComps !== null && multipleUniqueUnits === false) || (selectedPanel === 1 && property.arvCompsSimilarProp !== null) ) && runComps !== null ?
                        <div className="comparables-run-comps-button-container relative-container mobile-none">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={"Modify comps"}
                                cta={() => runComps()}
                                disabled={false}
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={<RedirectArrow className="comparables-arrow-icon" clickFunc={null} fill={colour.grayScaleWhite} />}
                                rightIconClass=""
                                alt=""
                                standardIcon={false}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                        :
                        null
                    }
                </div>
                {
                    selectedPanel === 0 ?
                    <RentalComps
                        property={property}
                        comps={rentalComps === null ? [] : rentalComps}
                        blurred={blurred}
                        propertySearch={propertySearch}
                        partnerEdit={false}
                        deleteComp={null}
                        rent={null}
                        multipleUniqueUnits={multipleUniqueUnits}
                        savedData={savedRentData}
                        setSavedData={setSavedRentData}
                        editedProperty={editedProperty}
                        setEditedProperty={setEditedProperty}
                        applyEstimateModal={applyEstimateModal}
                        setApplyEstimateModal={setApplyEstimateModal}
                    />
                    :
                    <ARVComps
                        comps={property.arvCompsSimilarProp}
                        blurred={blurred}
                        propertySearch={propertySearch}
                        partnerEdit={false}
                        deleteComp={null}
                        property={property}
                        savedData={savedData}
                        setSavedData={setSavedData}
                        editedProperty={editedProperty}
                        setEditedProperty={setEditedProperty}
                        loanYears={loanYears}
                        applyEstimateModal={applyEstimateModal}
                        setApplyEstimateModal={setApplyEstimateModal}
                    />
                }
                <div className="property-spacing-border">
                </div>
                <div className="comparables-sources-container">
                    <h3 className="body-semibold colour-primary">
                        Sources
                    </h3>
                    <span className="body-regular colour-secondary">
                        Recently sold and rental listings are sourced from <span className="underline cursor-pointer" onClick={() => openRentcast()}>RentCast.</span>
                        {" "}Similarity score determined by Coffee Clozers proprietary algorithm.
                    </span>
                </div>
            </div>
        </div>
    )
};

export default Comparables;