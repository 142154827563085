import React from 'react';
import '../styles/DaysOnMarketFilter.css';
import { HourGlass } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { FilterReset, SelectionChip, Switch } from './';

function DaysOnMarketFilter(props) {
    const daysOnMarket = props.daysOnMarket;
    const setDaysOnMarket = props.setDaysOnMarket;
    const backOnMarket = props.backOnMarket;
    const setBackOnMarket = props.setBackOnMarket;
    const recentPriceReduction = props.recentPriceReduction;
    const setRecentPriceReduction = props.setRecentPriceReduction;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;

    const daysOnMarketOptions = [
        {
            label: "Any",
            value: "",
            selected: daysOnMarket === "" ? true : false
        },
        {
            label: "<30",
            value: 0,
            selected: daysOnMarket === 0 ? true : false
        },
        {
            label: "30+",
            value: 30,
            selected: daysOnMarket === 30 ? true : false
        },
        {
            label: "60+",
            value: 60,
            selected: daysOnMarket === 60 ? true : false
        },
        {
            label: "90+",
            value: 90,
            selected: daysOnMarket === 90 ? true : false
        }
    ];

    const changeDaysOnMarket = (index) => {
        setDaysOnMarket(daysOnMarketOptions[index].value);
    };

    const toggleBackOnMarket = () => {
        setBackOnMarket(!backOnMarket);
    };

    const togglePriceReduction = () => {
        setRecentPriceReduction(!recentPriceReduction);
    };

    return (
        <div className="days-on-market-container">
            <div className="days-on-market-selector-container">
                <div className="days-on-market-title-container">
                    <HourGlass
                        className=""
                        fill={colour.grayScaleBlack}
                    />
                    <h3 className="body-semibold colour-primary">
                        Days on market
                    </h3>
                </div>
                <div className="days-on-market-options-container">
                    {
                        daysOnMarketOptions.map((item, index) =>
                            <SelectionChip
                                key={index}
                                label={item.label}
                                selected={item.selected}
                                disabled={false}
                                func={changeDaysOnMarket}
                                index={index}
                                leftIcon={null}
                                rightIcon={null}
                            />
                        )
                    }
                </div>
                {
                    reset &&
                    <FilterReset
                        clickFunc={() => resetFilterType("daysOnMarket")}
                    />
                }
            </div>
            <div className="days-on-market-switch-container">
                <h3 className="body-semibold colour-primary">
                    Back on market
                </h3>
                <Switch
                    val={backOnMarket}
                    setVal={() => toggleBackOnMarket()}
                    label="Filter by properties back on market"
                />
            </div>
            <div className="days-on-market-switch-container">
                <h3 className="body-semibold colour-primary">
                    Price reduction (last 30 days)
                </h3>
                <Switch
                    val={recentPriceReduction}
                    setVal={() => togglePriceReduction()}
                    label="Filter by properties with recent price reductions"
                />
            </div>
        </div>
    )
};

export default DaysOnMarketFilter;