import React from 'react';
import '../styles/MoreLocationFilter.css';
import { Switch } from './';

function MoreLocationFilter(props) {
    const hideFloodzone = props.hideFloodzone;
    const setHideFloodzone = props.setHideFloodzone;
    const hideHOA = props.hideHOA;
    const setHideHOA = props.setHideHOA;

    const toggleFloodzone = () => {
        setHideFloodzone(!hideFloodzone);
    };

    const toggleHOA = () => {
        setHideHOA(!hideHOA);
    };

    return (
        <div className="more-location-filter-container">
            <div className="more-location-filter-floodzone-container">
                <div className="more-location-fitler-title-container">
                    <h3 className="body-semibold colour-primary">
                        Floodzone properties
                    </h3>
                </div>
                <div className="more-location-filter-body-container">
                    <Switch
                        val={hideFloodzone}
                        setVal={() => toggleFloodzone()}
                        label="Hide properties in floodzones"
                    />
                </div>
            </div>
            <div className="more-location-filter-hoa-container">
                <div className="more-location-fitler-title-container">
                    <h3 className="body-semibold colour-primary">
                        HOA
                    </h3>
                </div>
                <div className="more-location-filter-body-container">
                    <Switch
                        val={hideHOA}
                        setVal={() => toggleHOA()}
                        label="Hide properties with HOA"
                    />
                </div>
            </div>
        </div>
    )
};

export default MoreLocationFilter;