import React from 'react';
import '../styles/Switch.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { IOSSwitch } from '../../styles/GlobalStyles';

function Switch(props) {
    const val = props.val;
    const setVal = props.setVal;
    const label = props.label;

    return (
        <div className="switch-container">
            <FormGroup>
                <FormControlLabel
                    sx={{ margin: '0px !important' }}
                    control={<IOSSwitch checked={val} onChange={(e) => setVal(e.target.checked)} />}
                />
            </FormGroup>
            {
                label !== null &&
                <span className="body-regular colour-primary">
                    {label}
                </span>
            }
        </div>
    )
};

export default Switch;