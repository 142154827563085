import React from 'react';
import '../styles/MobileSorting.css';
import { MobileRadio, SelectionChip } from '../components';

function MobileSorting(props) {
    const strategy = props.strategy;
    const setStrategy = props.setStrategy;
    const strategies = props.strategies;
    const sorting = props.sorting;
    const setSorting = props.setSorting;
    const sortOptions = props.sortOptions;

    const changeStrategy = (index) => {
        setStrategy(index);
    };

    const changeSorting = (index) => {
        setSorting(index);
    };

    return (
        <div className="mobile-sorting-container">
            {
                sorting < 2 &&
                <div className="mobile-sorting-exit-strategy-container">
                    <h3 className="body-semibold colour-primary">
                        Exit strategy
                    </h3>
                    <div className="mobile-sorting-exit-strategy-row">
                        {
                            strategies.map((item, index) =>
                                item.visible === true &&
                                <SelectionChip
                                    label={item.label}
                                    selected={strategy === index ? true : false}
                                    disabled={item.disabled}
                                    func={changeStrategy}
                                    index={index}
                                    leftIcon={null}
                                    rightIcon={null}
                                />
                            )
                        }
                    </div>
                </div>
            }
            <div className="mobile-sorting-method-container">
                <h3 className="body-semibold colour-primary">
                    Choose sorting method
                </h3>
                <div className="mobile-sorting-options-container">
                    {
                        sortOptions.map((item, index) =>
                            index !== 0 &&
                            <div 
                                className="mobile-sorting-option-element"
                                key={index}
                            >
                                <MobileRadio
                                    label={item.label}
                                    selected={((sorting === 0 || sorting === 1) && index < 2 ) ? true : index === sorting ? true : false}
                                    index={index}
                                    clickFunc={() => changeSorting(index)}
                                    key={index}
                                    disabled={false}
                                />
                                {
                                    index === 4 && sorting === 4 &&
                                    <span className="body-regular colour-secondary">
                                        <span className="body-semibold">
                                            Not recommended:
                                        </span>
                                        {" "}Sorting by CoCR can rank properties in 
                                        low-growth locations higher. Use Strategies option to 
                                        find well-rounded picks for each strategy.
                                    </span>
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default MobileSorting;