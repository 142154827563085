import React, { useEffect } from 'react';
import { Header, Footer } from '../components';
import '../styles/FAQ.css';

function FAQ() {

	useEffect(() => {
		document.title = "Coffee Clozers | FAQs";
	});

	const faqs = [
		{
			question: "Who is Coffee Clozers most suited to?",
			answer: "Newbie real estate investors looking to find cash flowing long-term rental properties."
		},
		{
			question: "Why are you called Coffee Clozers?",
			answer: "It's from the film Glengarry Glen Ross - you should watch it, it's a good film, very dialogue heavy. \n\n To everyone who thinks your company’s name should make the service obvious - Slack is a procrastination app, Amazon sells tree bark, and Über helps you learn German."
		},
		// {
		// 	question: "Do you have a free version?",
		// 	answer: "Yes, you can look at up to 5 individual properties for free. \n\n To have properties ranked by cash flow, you'll need to subscribe to our Premium Plan (although we do have a 48h free trial 👀)"
		// },
		{
			question: "Do you also have access to off-market deals?",
			answer: "No."
		},
		{
			question: "Can you help me find Short Term Rentals/Airbnbs?",
			answer: "Ever heard of AirDNA?"
		}
	];

	return (
		<div className="faq-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
				desktopControl={true}
			/>
			<div className="faq-inner-container">
				<h1>
					FAQ
				</h1>
				<ul className="faq-list-container">
					{
						faqs.map((item, index) =>
							<li 
								className="faq-element" 
								key={index}
							>
								<h2>
									{item.question}
								</h2>
								<p>
									{item.answer}
								</p>
							</li>
						)
					}
				</ul>
			</div>
			<Footer />
		</div>
	)
}

export default FAQ;