import React from 'react';

function Heart(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="17" 
            viewBox="0 0 16 17" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M13.4567 11.0308C13.8893 10.5232 14.3737 9.90138 14.7921 9.21897C15.2018 8.55088 16 7.12258 16 5.33824C16 2.6819 13.8499 0.5 11.1623 0.5C9.94977 0.5 8.8457 0.945689 8 1.67708C7.1543 0.945689 6.05023 0.5 4.83766 0.5C2.15012 0.5 0 2.6819 0 5.33824C0 7.12258 0.798203 8.55088 1.20785 9.21897C1.71066 10.039 2.30875 10.7715 2.79762 11.3234C3.30363 11.8947 3.79523 12.387 4.15266 12.7308C4.98298 13.5294 5.86032 14.2672 6.72746 15.0243C7.45406 15.6586 8.54594 15.6586 9.27254 15.0243C10.141 14.2661 11.0158 13.5306 11.8473 12.7308C12.411 12.1886 12.9321 11.6102 13.4567 11.0308ZM13.6981 8.56079C14.0768 7.94335 14.72 6.76341 14.72 5.33824C14.72 3.36635 13.1272 1.76785 11.1623 1.76785C10.2775 1.76785 9.46813 2.09205 8.8457 2.62851C8.49961 2.92678 8.21133 3.29067 8 3.70096C7.78867 3.29067 7.50039 2.92678 7.1543 2.62851C6.53188 2.09205 5.72246 1.76785 4.83766 1.76785C2.87281 1.76785 1.28 3.36635 1.28 5.33824C1.28 6.76341 1.92324 7.94335 2.30187 8.56079C2.7507 9.2928 3.2957 9.96332 3.7598 10.4873C4.23672 11.0258 4.70301 11.4929 5.04434 11.8212C6.27823 13.0079 5.79964 12.5243 7.57379 14.0731C7.81672 14.2852 8.18328 14.2852 8.42621 14.0731C9.27931 13.3284 10.1389 12.6067 10.9557 11.8212C11.8054 11.0039 12.8595 9.92855 13.6981 8.56079Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Heart;