import React, { useEffect, useState } from 'react';
import '../styles/NewPropertyMap.css';
import { GoogleMap, OverlayView, useLoadScript, OverlayViewF, InfoWindow } from '@react-google-maps/api';
import { NewPropertyCard, MapLegend, FullScreenButton, ResetButton, SidePanel, MapSearchAddress, NeighbourhoodGradeMap, DoubleTapMessage, EmergingAreaTooltip, MapMarket } from './';
import { placesAutocompleteSearch, geocode, checkCardPosition, recordEvent } from '../functions';
import mapStyles from '../styles/mapStyles.json';
import mapStylesFull from '../styles/mapStylesFull.json';
import { percentage, percentageCompact } from "../styles/GlobalStyles";
import { PropertyPosition } from '../v4/components';

function NewPropertyMap(props) {

	const properties = props.properties;
	const userDetails = props.userDetails;
	const formatterLong = props.formatterLong;
	const centreCoordinates = props.centreCoordinates;
	const viewedProperties = props.viewedProperties;
	const setViewedProperties = props.setViewedProperties;
	const allProperties = props.allProperties;
	const setMobileAbstract = props.setMobileAbstract;
	const cityId = props.cityId;
	const hoverCard = props.hoverCard;
	const propertyRefs = props.propertyRefs;
	const noProperties = props.noProperties;
	const setPage = props.setPage;
	const cardIndex = props.cardIndex;
	const setCardIndex = props.setCardIndex;
	const cardClick = props.cardClick;
	const setCardClick = props.setCardClick;
	const onboarding = props.onboarding;
	const showResetButton = props.showResetButton;
	const setShowResetButton = props.setShowResetButton;
	const mapSidePanelOpen = props.mapSidePanelOpen;
	const setMapSidePanelOpen = props.setMapSidePanelOpen;
	const temporaryId = props.temporaryId;
	const setSignUpModal = props.setSignUpModal;
	const overlay = props.overlay;
	const setOverlay = props.setOverlay;
	const loading = props.loading;
	const setChangeMade = props.setChangeMade;
	const addressSearchedProperty = props.addressSearchedProperty;
	const setAddressSearchedProperty = props.setAddressSearchedProperty;
	const googleMapProperty = props.googleMapProperty;
	const setGoogleMapProperty = props.setGoogleMapProperty;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites;
	const strategy = props.strategy;
	const arrowClicked = props.arrowClicked;

	const neighbourhoodData = props.neighbourhoodData;
	const neighbourhoodBounds = props.neighbourhoodBounds;
	const tracts = props.tracts;
	const neighbourhoodOption = props.neighbourhoodOption;
	const clickTract = props.clickTract;
	const clickedTract = props.clickedTract;
	const chosenTract = props.chosenTract;
	const hoverMapLegend = props.hoverMapLegend;
	const setHoverMapLegend = props.setHoverMapLegend;
	const setUserDetails = props.setUserDetails;
	const bubbles = props.bubbles;
	const emergingMarkets = props.emergingMarkets;
	const setEmergingMarkets = props.setEmergingMarkets;
	const setEmergingAreaModal = props.setEmergingAreaModal;
	const setEmergingAreaTract = props.setEmergingAreaTract;
	const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
	const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
	const hideHeatmap = props.hideHeatmap;
	const setNewCentre = props.setNewCentre;
	const creativeStrategy = props.creativeStrategy;
	const activeMarket = props.activeMarket;
	const cityStateName = props.cityStateName;

	const [renderOverlay, setRenderOverlay] = useState(false);
	const [mapRef, setMapRef] = useState(null);
	const [clickedDots, setClickedDots] = useState([]);
	const [searchActive, setSearchActive] = useState(false);
	const [searchAddress, setSearchAddress] = useState("");
	const [searchAddressResults, setSearchAddressResults] = useState([]);
	const [mapSearchProperties] = useState([]);
	const [googlePlacesLoading, setGooglePlacesLoading] = useState(false);
	const [hideMobileMessage, setHideMobileMessage] = useState(false);
	
	// Leave setCentre unused...otherwise map-snap happens for 
	// some reason
	const [centre, setCentre] = useState({ lat: centreCoordinates.lat, lng: centreCoordinates.lng });
	const api_key = JSON.parse(process.env.REACT_APP_GOOGLE).key

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}

	}, [renderOverlay]);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: api_key
	});

	const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: true,
		disableDoubleClickZoom: true,
		styles: hideHeatmap === true ? mapStylesFull : mapStyles,
		clickableIcons: false
	};

	const clickIcon = (zpid) => {
		setCardClick(true);
		setCardIndex(cardIndex === zpid ? null : zpid);

		if ( cardIndex === zpid ) {
			recordEvent("Deactivate Map Bubble", {
				zpid: zpid
			});
		}
		else {
			recordEvent("Activate Map Bubble", {
				zpid: zpid
			});
		}

		const allPropertiesIndex = allProperties.findIndex(e => e.zpid === zpid);
		if ( allPropertiesIndex !== -1 ) {
			const item = allProperties[allPropertiesIndex];
			setMobileAbstract("");

			setTimeout(() => {
				setMobileAbstract(cardIndex === zpid ? "" : item);
			}, 1);
		}

		const newArray = clickedDots.slice(0);
		newArray.push(zpid);
		setClickedDots(newArray);

		setTimeout(() => {
			setCardClick(false);
		}, 500);
	};

	const clickOnMap = (e) => {
		if ( cardClick === false ) {
			if ( cardIndex !== null ) {
				recordEvent("Deactivate Map Bubble", {
					zpid: cardIndex
				});
			}
			setCardIndex(null);
			setAddressSearchedProperty(null);
			setGoogleMapProperty(null);
			setMobileAbstract("");
		}
	}

	const handleLoad = (map) => {
		setMapRef(map);
	};

	const checkViewedProperties = (zpid) => {
		const clickedDotsIndex = clickedDots.indexOf(zpid);
		if ( clickedDotsIndex === -1 ) {
			return "";
		}
		else {
			return "new-property-map-overlay-container-viewed ";
		}
	};

	const returnLatLng = (item, index) => {
		const object = {
			lat: item.latitude, 
			lng: item.longitude
		};

		return object;
	};

	const onChangeSearchAddress = async(val) => {
		setSearchAddress(val);
		const amendedVal = val.toLowerCase();
		const checkedProperties = mapSearchProperties.length === 0 ? allProperties : mapSearchProperties;

		const predictedSearch = [];
		for (let index = 0; index < checkedProperties.length; index++) {
			const element = checkedProperties[index];
			const streetAddress = element.address.streetAddress.toLowerCase();
			if ( streetAddress.includes(amendedVal) ) {
				const newObject = {
					label: `${element.address.streetAddress}, ${element.address.city}, ${element.address.state} ${element.address.zipcode}`
				}
				predictedSearch.push(newObject);
			}
		}

		if ( predictedSearch.length !== 0 ) {
			setSearchAddressResults(predictedSearch);
		}

		const firstProperty = properties.length > 0 ? properties[0] : null;
		const firstPropertyState = firstProperty !== null ? firstProperty.address.state : null;
		if ( val.length > 4 ) {
			setGooglePlacesLoading(true);
			const autocompleteParams = {
				input: val,
				language: "en",
				types: "address",
				components: "country:us"
			};
			const getAutocomplete = await placesAutocompleteSearch(autocompleteParams);
			if ( getAutocomplete.status === 200 ) {
				const responses = getAutocomplete.data;
				let firstResponse = null;
				for (let index = 0; index < responses.length; index++) {
					const element = responses[index];
					const terms = element.terms;
					const checkStateIndex = terms.findIndex(e => e.value === firstPropertyState);
					if ( checkStateIndex !== -1 ) {
						firstResponse = element;
						break;
					};
				}
				
				if ( firstResponse !== null ) {
					firstResponse.label = firstResponse.description;
					const addressIndex = predictedSearch.findIndex(e => e.label === firstResponse.label);
					if ( addressIndex === -1 ) {
						predictedSearch.push(firstResponse);
					}
					setSearchAddressResults(predictedSearch);
				}
				setGooglePlacesLoading(false);
			}
		}
	};

	const activateMapSearch = async() => {
		recordEvent("Activate Map Search", {});
		setSearchActive(true);
	};

	const closeMapSearch = () => {
		recordEvent("Deactivate Map Search", {});
		setSearchActive(false);
		setSearchAddressResults([]);
	};

	const selectAddress = async(item, googlePlace) => {
		setSearchAddress("");
		
		if ( googlePlace === false ) {
			setCardIndex(item.zpid);
			setAddressSearchedProperty(item);
			setGoogleMapProperty(null);
			closeMapSearch();
			setCentre({ lat: item.latitude, lng: item.longitude });
		}
		else {
			const getGeocode = await geocode(item.description);
			if ( getGeocode.status === 200 ) {
				item.latitude = getGeocode.data[0];
				item.longitude = getGeocode.data[1];
				setCentre({ lat: item.latitude, lng: item.longitude });

				setCardIndex(null);
				setGoogleMapProperty(item);
				setShowResetButton(true);
				setAddressSearchedProperty(null);
				closeMapSearch();
			}
			else {
				console.log("Error in geocoding = ", getGeocode.error);
			}
		}
	}

	const mapChange = async() => {
		// Max Zoom is 22
		// Default zoom is 11

		if ( mapRef !== null ) {
			const zoomRate = mapRef.zoom;
			if ( zoomRate > 12 ) {
				const centrePoint = {
					latitude: mapRef.getCenter().lat(),
					longitude: mapRef.getCenter().lng()
				}
				setNewCentre(centrePoint);
				setChangeMade("zoom");
				setPage(1);
				setShowResetButton(true);
			}
		}
	};

	const resetMap = async() => {
		setNewCentre(null);
		setShowResetButton(false);
		setPage(1);
		setGoogleMapProperty(null);
		setChangeMade("zoom");
	};

	const getEmergingAreaCentre = (item) => {
		const polygon = item.tractPolygon;
		let totalLat = 0;
		let totalLng = 0;
		const amendedPolygonPoints = polygon.flat(100);
		amendedPolygonPoints.forEach(point => {
			totalLat += point.lat;
			totalLng += point.lng;
		});
		
		const centerLat = totalLat / amendedPolygonPoints.length;
		const centerLng = totalLng / amendedPolygonPoints.length;
		
		return { lat: centerLat, lng: centerLng };
	};

	const checkRoI = (item) => {
		if ( strategy === 4 && item.arvComps === true && item.fixerUpper === true && item.propertyTypeDimension === "Single Family" ) {
			const arvPrice = item.arvPrice;
    		const purchasePrice = item.price;
    		const arvSpread = (arvPrice - purchasePrice) / purchasePrice;
			return percentageCompact.format(arvSpread);
		}
		else {
			return percentage.format(item.financials.cashOnCash / 100);
		}
	};

	return (
		<div className="new-property-map-outer-container">
			<div className="new-property-map-inner-container">
				{
					userDetails === null ?
					null
					:
					userDetails.mobileMessage > 2 || hideMobileMessage === true ?
					null
					:
					<DoubleTapMessage
						userDetails={userDetails}
						setUserDetails={setUserDetails}
						setHideMobileMessage={setHideMobileMessage}
					/>
				}
				{
					isLoaded === true ?
					<GoogleMap
						zoom={11}
						center={centre}
						mapContainerClassName="new-property-map-element"
						options={options}
						onLoad={handleLoad}
						onClick={(e) => clickOnMap(e)}
						onZoomChanged={() => mapRef !== null ? mapChange() : null}
						onDragEnd={() => mapRef !== null ? mapChange() : null}
					>
						{
							renderOverlay === true ?
							<FullScreenButton />
							:
							null
						}
						{
							renderOverlay === true && showResetButton === true ?
							<ResetButton
								resetMap={resetMap}
							/>
							:
							null
						}
						{
							renderOverlay === true && hideHeatmap === false ?
							<MapLegend
								hoverMapLegend={hoverMapLegend}
								setHoverMapLegend={setHoverMapLegend}
								neighbourhoodOption={neighbourhoodOption}
							/>
							:
							null
						}
						{
							renderOverlay === true && searchActive === false &&
							<MapMarket
								market={cityStateName}
								activeMarket={activeMarket}
							/>
						}
						{
							renderOverlay === true ?
							<MapSearchAddress
								searchActive={searchActive}
								setSearchActive={setSearchActive}
								searchAddress={searchAddress}
								onChangeSearchAddress={onChangeSearchAddress}
								searchAddressResults={searchAddressResults}
								selectAddress={selectAddress}
								activateMapSearch={activateMapSearch}
								closeMapSearch={closeMapSearch}
								googlePlacesLoading={googlePlacesLoading}
							/>
							:
							null
						}
						{
							renderOverlay === true && googleMapProperty !== null ?
							<PropertyPosition
								latitude={googleMapProperty.latitude}
								longitude={googleMapProperty.longitude}
							/>
							:
							null
						}
						{
							renderOverlay === true && hideHeatmap === false ?
							<NeighbourhoodGradeMap
								bounds={neighbourhoodBounds}
								tracts={tracts}
								neighbourhoodOption={neighbourhoodOption}
								neighbourhoodData={neighbourhoodData}
								clickTract={clickTract}
								clickedTract={clickedTract}
								hoverMapLegend={hoverMapLegend}
								cardClick={cardClick}
								cardIndex={cardIndex}
								neighbourhoodGradeMin={neighbourhoodGradeMin}
								neighbourhoodGradeMax={neighbourhoodGradeMax}
							/>
							:
							null
						}
						{
							bubbles === false ?
							null
							:
							renderOverlay === true && noProperties === false ?
							addressSearchedProperty !== null ?
								<OverlayViewF
									position={returnLatLng(addressSearchedProperty, 0)}
									mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
								>
									<div 
										className={"new-property-map-overlay-container " + (checkViewedProperties(addressSearchedProperty.zpid)) + (addressSearchedProperty.zpid === cardIndex || hoverCard === addressSearchedProperty.zpid ? "new-property-map-overlay-container-selected" : "")}
										onClick={() => loading === true ? null : clickIcon(addressSearchedProperty.zpid)}
									>
										<span className={"body-regular " + (addressSearchedProperty.zpid === cardIndex || hoverCard === addressSearchedProperty.zpid ? "colour-white" : "colour-brown")}>
											{percentage.format(addressSearchedProperty.financials.cashOnCash / 100)}
										</span>
									</div>
								</OverlayViewF>
							:
							properties.map((item, index) =>
								<OverlayViewF
									key={index}
									position={returnLatLng(item, 0)}
									mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
								>
									<div 
										className={"new-property-map-overlay-container " + (checkViewedProperties(item.zpid)) + (item.zpid === cardIndex || hoverCard === item.zpid ? "new-property-map-overlay-container-selected" : "")}
										onClick={() => loading === true ? null : clickIcon(item.zpid)}
										ref={propertyRefs[index]}
									>
										<span className={"body-regular " + (item.zpid === cardIndex || hoverCard === item.zpid ? "colour-white" : "colour-brown")}>
											{checkRoI(item)}
										</span>
									</div>
								</OverlayViewF>
							)
							:
							null
						}
						{
							renderOverlay === true && emergingMarkets.length > 0 ?
								emergingMarkets.map((item, index) =>
									<InfoWindow 
										options={{ pixelOffset: new window.google.maps.Size(0, 0) }}
										position={getEmergingAreaCentre(item)}
										className="emerging-area-tooltip-container bg-colour-white"
										key={index}
									>
										<EmergingAreaTooltip
											setEmergingAreaModal={setEmergingAreaModal}
											setEmergingAreaTract={setEmergingAreaTract}
											tract={item}
											title="Emerging area detected"
											emergingMarkets={emergingMarkets}
											setEmergingMarkets={setEmergingMarkets}
										/>
									</InfoWindow>
								)
							:
							null
						}
						{
							renderOverlay === true && noProperties === false ?
								allProperties.map((item, index) =>
									item.zpid === cardIndex && cardIndex !== null ?
									<OverlayViewF
										key={index}
										position={checkCardPosition(item, mapRef)}
										mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
									>
										<div className="new-map-overlay-property-card-container mobile-none new-map-overlay-property-card-container-desktop">
											<NewPropertyCard
												item={item}
												index={index}
												key={index}
												userDetails={userDetails}
												formatter={formatterLong}
												abstract={true}
												viewedProperties={viewedProperties}
												setViewedProperties={setViewedProperties}
												cityId={cityId}
												onboarding={onboarding}
												setCardClick={setCardClick}
												temporaryId={temporaryId}
												setSignUpModal={setSignUpModal}
												overlay={overlay}
												setOverlay={setOverlay}
												propertySearch={false}
												partnerView={false}
												partnerEditProperty={null}
												partnerSoldProperty={null}
												partnerData={null}
												changeStatus={null}
												setShowExitScreen={null}
												selectOption={null}
												openStatus={null}
												chooseStatus={null}
												state={null}
												favourites={favourites}
												setFavourites={setFavourites}
												strategy={strategy}
												arrowClicked={arrowClicked}
												imageLimit={10}
												bulkActions={false}
												note={null}
												openNoteModal={null}
												openUnfavouritePropertiesModal={null}
												creativeStrategy={creativeStrategy}
												activeMarket={activeMarket}
											/>
										</div>
									</OverlayViewF>
									:
									null
								)
							:
							null
						}
					</GoogleMap>
					:
					null
				}
				{
					neighbourhoodData.length !== 0 ?
					<SidePanel
						chosenTract={chosenTract}
						neighbourhoodData={neighbourhoodData}
						neighbourhoodOption={neighbourhoodOption}
						clickedTract={clickedTract}
						mapSidePanelOpen={mapSidePanelOpen}
						setMapSidePanelOpen={setMapSidePanelOpen}
					/>
					:
					null
				}
			</div>
		</div>
	)
}

export default NewPropertyMap;