import React, { useState } from 'react';
import '../styles/PropertyListTopBar.css';
import { Sort, Filters, Download } from '../assets/icons';
import { ExitScreen } from './';
import { recordEvent, updateHideHeatmapSetting } from '../functions';
import { colour, neighbourhoodDataOptions } from '../styles/GlobalStyles';
import { Button, Dropdown, IconButton, MobileTab, OptionSwitch, Switch } from '../v4/components';
import { BankIcon, PuzzleIcon, Settings01Icon } from 'hugeicons-react';

function PropertyListTopBar(props) {
    const changeSorting = props.changeSorting;
    const clearFilters = props.clearFilters;
    const creativeStrategy = props.creativeStrategy;
    const downloadCSV = props.downloadCSV;
    const downloadCSVFreeTrial = props.downloadCSVFreeTrial;
    const freeTrial = props.freeTrial;
    const hideHeatmap = props.hideHeatmap;
    const mapTypeDropdownOpen = props.mapTypeDropdownOpen;
    const neighbourhoodOption = props.neighbourhoodOption;
    const onChangeNeighbourhoodOption = props.onChangeNeighbourhoodOption;
    const searchParams = props.searchParams;
    const setAddressSearchedProperty = props.setAddressSearchedProperty;
    const setCardIndex = props.setCardIndex;
    const setFiltersModalOpen = props.setFiltersModalOpen;
    const setGoogleMapProperty = props.setGoogleMapProperty;
    const setHideHeatmap = props.setHideHeatmap;
    const setMapTypeDropdownOpen = props.setMapTypeDropdownOpen;
    const sortDropdownOpen = props.sortDropdownOpen;
    const sortOptions = props.sortOptions;
    const setSortDropdownOpen = props.setSortDropdownOpen;
    const sorting = props.sorting;
    const toggleCreativeStrategy = props.toggleCreativeStrategy;
    const setMapSettingsModal = props.setMapSettingsModal;
    const setSettingsHideHeatmap = props.setSettingsHideHeatmap;
    const alreadyShownNeighbourhoodHeatmap = props.alreadyShownNeighbourhoodHeatmap;
    const setNeighbourhoodHeatmapModal = props.setNeighbourhoodHeatmapModal;
    const csvDownloadLoading = props.csvDownloadLoading;

    const [openFilters, setOpenFilters] = useState(null);
    const getFilters = searchParams.get('filters');
	const parsedFilters = getFilters !== null ? JSON.parse(getFilters) : null;
    const filterCount = parsedFilters === null ? 0 : parsedFilters.length;

    const mobileTabs = [
        {
            label: "Traditional",
            icon: <BankIcon size={16} color={creativeStrategy === true ? colour.grayScaleBlack : colour.grayScaleGray03} />,
            disabled: false,
            value: false,
            active: !creativeStrategy
        },
        {
            label: "Creative",
            icon: <PuzzleIcon size={16} color={creativeStrategy === true ? colour.grayScaleBlack : colour.grayScaleGray03} />,
            disabled: false,
            value: true,
            active: creativeStrategy
        }
    ];

    const openFiltersModal = () => {
		setFiltersModalOpen(true)
        recordEvent("Open Filters", {});
	};

    const clickOutside = (e) => {
        setCardIndex(null)
        setAddressSearchedProperty(null);
        setGoogleMapProperty(null);
    }
    
    const closeEverything = () => {
        setOpenFilters(null);
        setMapTypeDropdownOpen(false);
        setSortDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setMapTypeDropdownOpen(!mapTypeDropdownOpen);
    };

    const selectOption = (option, index) => {
        onChangeNeighbourhoodOption(index);
        setMapTypeDropdownOpen(false);
    };

    const toggleSortDropdown = () => {
        setSortDropdownOpen(!sortDropdownOpen);
    };

    const selectSorting = (option) => {
        const getObject = sortOptions.findIndex((x) => x.label === option.label);
        changeSorting(getObject);
    };

    const toggleHideHeatmap = async() => {
        if ( alreadyShownNeighbourhoodHeatmap === false && !hideHeatmap === false && neighbourhoodOption === 0 ) {
            setNeighbourhoodHeatmapModal(true);
        }
        setHideHeatmap(!hideHeatmap);
        setSettingsHideHeatmap(!hideHeatmap);
        await updateHideHeatmapSetting(!hideHeatmap);
    };

    const openMapSettings = () => {
        setMapSettingsModal(true);
    };

    return (
        <div className="property-list-top-bar-outer-container">
            {
                openFilters !== null || sortDropdownOpen === true || mapTypeDropdownOpen === true ?
                <ExitScreen
                    clickScreen={() => closeEverything()}
                />
                :
                null
            }
            <div 
                className="property-list-top-bar-inner-container"
                onClick={(e) => clickOutside(e)}
            >
                <div className="property-list-top-bar-left-container mobile-none relative-container">
                    <IconButton
                        size="medium"
                        icon={<Download fill={colour.blueBlue03} className="" />}
                        disabled={csvDownloadLoading}
                        clickFunc={() => freeTrial === true ? downloadCSVFreeTrial() : downloadCSV()}
                        standardIcon={false}
                        contextTooltip={true}
                        contextTooltipBG={"dark"}
                        contextTooltipText={"Download CSV"}
                        customClass={null}
                    />
                    <OptionSwitch
                        labels={["Traditional", "Creative"]}
                        firstOption={creativeStrategy === true ? false : true}
                        disabled={false}
                        optionSwitch={() => toggleCreativeStrategy(!creativeStrategy)}
                        switchWidth={190}
                        leftWidth={0.5}
                        rightWidth={0.5}
                    />
                    <div className="property-list-top-bar-sort-container">
                        <Dropdown
                            open={sortDropdownOpen}
                            icon={<Sort className="" fill={colour.grayScaleBlack} />}
                            label={sorting === 0 ? "Sort" : sortOptions[sorting].label}
                            disabled={false}
                            options={sortOptions}
                            multiselect={false}
                            toggleDropdown={toggleSortDropdown}
                            cta={selectSorting}
                            sidealign={"left"}
                            topalign={"bottom"}
                            indexSelect={false}
                            selectedIndex={null}
                        />
                    </div>
                    <div className={"property-list-top-bar-filters-container " + (filterCount !== 0 ? "relative-container property-list-top-bar-active-filters-container" : "")}>
                        <Button
                            buttonType="secondary-button"
                            size="medium"
                            text={"Filters"}
                            cta={openFiltersModal}
                            disabled={false}
                            leftIcon={<Filters className="property-list-top-bar-filters-icon" fill={colour.grayScaleBlack} />}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={filterCount === 0 ? false : true}
                            badgeVal={filterCount}
                            badgeBG={"badge-standard-bg-border"}
                            buttonRef={null}
                        />
                    </div>
                    {
                        filterCount !== 0 &&
                        <div className="subtext-margin-left-small">
                            <span 
                                className="body-regular-underline colour-primary cursor-pointer"
                                onClick={() => clearFilters()}
                            >
                                Clear filters
                            </span>
                        </div>
                    }
                </div>
                <div className="property-list-top-bar-right-container mobile-none">
                    <Switch
                        val={!hideHeatmap}
                        setVal={() => toggleHideHeatmap()}
                        label="Show heatmap"
                    />
                    <div className="property-list-top-bar-dropdown-container">
                        <Dropdown
                            open={mapTypeDropdownOpen}
                            icon={null}
                            label={hideHeatmap === true ? "Map view" : neighbourhoodDataOptions[neighbourhoodOption].label}
                            disabled={false}
                            options={neighbourhoodDataOptions}
                            multiselect={false}
                            toggleDropdown={toggleDropdown}
                            cta={selectOption}
                            sidealign={"right"}
                            topalign={"bottom"}
                            indexSelect={hideHeatmap === true ? false : true}
                            selectedIndex={neighbourhoodOption}
                        />
                    </div>
                </div>
                <div className="property-list-top-bar-mobile-tabs-row desktop-none">
                    {
                        mobileTabs.map((item, index) =>
                            <MobileTab
                                item={item}
                                index={index}
                                title={item.label}
                                active={item.active}
                                cta={() => toggleCreativeStrategy(item.value)}
                                icon={item.icon}
                                disabled={item.disabled}
                                line={true}
                                key={index}
                            />
                        )
                    }
                </div>
                <div className="property-list-top-bar-mobile-right-buttons-row desktop-none">
                    <div className="property-list-top-bar-mobile-map-button">
                        <Button
                            buttonType="secondary-button"
                            size="large"
                            text={"Map"}
                            cta={openMapSettings}
                            disabled={false}
                            leftIcon={<Settings01Icon color={colour.grayScaleBlack} size={16} />}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                    <div className={(filterCount !== 0 ? "property-list-top-bar-active-filters-container" : "")}>
                        <Button
                            buttonType="secondary-button"
                            size="large"
                            text={"Filters"}
                            cta={openFiltersModal}
                            disabled={false}
                            leftIcon={<Filters className="property-list-top-bar-filters-icon" fill={colour.grayScaleBlack} />}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={filterCount === 0 ? false : true}
                            badgeVal={filterCount}
                            badgeBG={"badge-standard-bg-border"}
                            buttonRef={null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyListTopBar;