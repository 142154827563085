import React from 'react';
import '../styles/PropertyListButton.css';
import { colour } from '../styles/GlobalStyles';
import { Button } from '../v4/components';
import { Location, SingleFamily } from '../assets/icons';

function PropertyListButton(props) {
    const clickFunc = props.clickFunc;
    const overlay = props.overlay;
    const label = overlay === false ? "Properties list" : "Map";
    const style = overlay === false ? "light-button" : "primary-button";
    const icon = overlay === false ? <SingleFamily fill={colour.blueBlue03} className="property-list-button-icon" /> : <Location fill={colour.grayScaleWhite} className="property-list-button-icon" />;
    return (
        <div 
            className={"property-list-button-container " + (overlay === true ? "property-list-small-button-container" : "")}
        >
            <Button
                buttonType={style}
                size="large"
                text={label}
                cta={clickFunc}
                disabled={false}
                leftIcon={icon}
                leftIconClass=""
                rightIcon={null}
                rightIconClass=""
                alt=""
                standardIcon={false}
                badge={false}
                badgeVal={null}
                badgeBG={null}
                buttonRef={null}
            />
        </div>
    );
};

export default PropertyListButton;