import React from 'react';

function Location(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M5.33333 16C5.33333 16 10.6667 8.27885 10.6667 5.33333C10.6667 2.38781 8.27885 0 5.33333 0C2.38781 0 0 2.38781 0 5.33333C0 8.27885 5.33333 16 5.33333 16ZM5.33346 8.53334C7.10077 8.53334 8.53346 7.10065 8.53346 5.33334C8.53346 3.56603 7.10077 2.13334 5.33346 2.13334C3.56615 2.13334 2.13346 3.56603 2.13346 5.33334C2.13346 7.10065 3.56615 8.53334 5.33346 8.53334Z" 
                fill={fill}
            />
        </svg>
    );
};

export default Location;