import React from 'react';
import '../styles/OwnerType.css';
import { User } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { FilterReset, MobileRadio, SelectionChip } from './';

function OwnerTypeFilter(props) {
    const ownerType = props.ownerType;
    const setOwnerType = props.setOwnerType;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;

    const ownerTypes = [
        {
            label: "Any",
            value: "",
            selected: ownerType === "" ? true : false
        },
        {
            label: "Individual",
            value: "i",
            selected: ownerType === "i" ? true : false
        },
        {
            label: "Corporate",
            value: "c",
            selected: ownerType === "c" ? true : false
        }
    ];

    const changeOwnerType = (item) => {
        setOwnerType(item.value);
    };

    return (
        <div className="owner-type-filter-container">
            <div className="owner-type-filter-title-container">
                <User
                    fill={colour.grayScaleBlack}
                />
                <h3 className="body-semibold colour-primary">
                    Owner type
                </h3>
            </div>
            <div className="owner-type-filter-body-container mobile-none">
                {
                    ownerTypes.map((item, index) =>
                        <SelectionChip
                            key={index}
                            label={item.label}
                            selected={item.selected}
                            disabled={false}
                            func={() => changeOwnerType(item)}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                        />
                    )
                }
            </div>
            <div className="owner-type-filter-body-container-mobile desktop-none">
                {
                    ownerTypes.map((item, index) =>
                        <MobileRadio
                            label={item.label}
                            selected={item.selected}
                            index={index}
                            clickFunc={() => changeOwnerType(item)}
                            key={index}
                            disabled={false}
                        />
                    )
                }
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("ownerType")}
                />
            }
        </div>
    )
};

export default OwnerTypeFilter;