import React from 'react';

function Info(props) {
    const fill = props.fill;
    const className = props.className;
    const onMouseEnter = props.onMouseEnter;
    const onMouseLeave = props.onMouseLeave;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
            onMouseEnter={onMouseEnter === null ? null : () => onMouseEnter()}
            onMouseLeave={onMouseLeave === null ? null : () => onMouseLeave()}
        >
            <path 
                d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                fill={fill}
            />
            <path 
                d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Info;