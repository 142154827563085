import React from 'react';

function Download(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M8.53367 11.9488L13.3367 7.14577C13.6314 6.85104 13.6314 6.37317 13.3367 6.07844C13.0419 5.7837 12.5641 5.7837 12.2693 6.07844L8.75472 9.59305L8.75472 1.75472C8.75472 1.3379 8.41682 1 8 1C7.58318 1 7.24528 1.3379 7.24528 1.75472L7.24528 9.59305L3.73068 6.07844C3.43594 5.7837 2.95808 5.7837 2.66335 6.07844C2.36861 6.37317 2.36861 6.85104 2.66335 7.14577L7.46634 11.9488C7.76107 12.2435 8.23893 12.2435 8.53367 11.9488Z" 
                fill={fill}
            />
            <path 
                d="M0.754717 14.283C0.337898 14.283 0 14.6209 0 15.0377C0 15.4546 0.337898 15.7925 0.754717 15.7925H15.2453C15.6621 15.7925 16 15.4546 16 15.0377C16 14.6209 15.6621 14.283 15.2453 14.283H0.754717Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Download;