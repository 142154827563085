import React, { useState } from 'react';
import '../styles/EditNote.css';
import { FinancialsInput } from '../components';

function EditNote(props) {
    const val = props.val;
    const setVal = props.setVal;
    const placeholder = props.placeholder
    const [editMode, setEditMode] = useState(val === "" ? true : false);

    const onChange = (e) => {
        setVal(e.target.value);
    };

    const activateEditMode = () => {
        setEditMode(true);
    };

    return (
        <div className="edit-note-container">
            {
                editMode === true ?
                <FinancialsInput
                    label={null}
                    labelType={null}
                    info={false}
                    value={val}
                    onChange={onChange}
                    placeholder={placeholder}
                    type="text"
                    leftUnit={null}
                    rightUnit={null}
                    unitSwitch={false}
                    leftUnitSelected={true}
                    unitSwitchFunc={null}
                    disabled={false}
                    multiline={true}
                    error={false}
                    onMouseEnter={null}
                    onMouseLeave={null}
                    reset={false}
                    resetFunc={null}
                    resetPosition={null}
                />
                :
                <div className="edit-note-inner-container">
                    <span className="body-regular colour-primary">
                        {val}
                    </span>
                    <span 
                        className="body-regular-underline colour-link"
                        onClick={() => activateEditMode()}
                    >
                        Edit note
                    </span>
                </div>
            }
        </div>
    );
};

export default EditNote;