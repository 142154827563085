import React from 'react';
import '../styles/ScoreBreakdown.css';

function ScoreBreakdown(props) {
    const property = props.property;

    const elements = typeof(property.dripScoreSummaryDescription.roi) === "object" ? [] : [
        {
            title: "ROI",
            description: property.dripScoreSummaryDescription.roi,
            score: property.dripScoreSummarySubScores.roi,
            bg: property.dripScoreSummarySubScores.roi >= 75 ? "bg-colour-high-score" : property.dripScoreSummarySubScores.roi >= 50 ? "bg-colour-medium-score" : "bg-colour-low-score"
        },
        {
            title: "Property Risk",
            description: property.dripScoreSummaryDescription.propertyRisk,
            score: property.dripScoreSummarySubScores.propertyRisk,
            bg: property.dripScoreSummarySubScores.propertyRisk >= 75 ? "bg-colour-high-score" : property.dripScoreSummarySubScores.propertyRisk >= 50 ? "bg-colour-medium-score" : "bg-colour-low-score"
        },
        {
            title: "Market Risk",
            description: property.dripScoreSummaryDescription.marketRisk,
            score: property.dripScoreSummarySubScores.marketRisk,
            bg: property.dripScoreSummarySubScores.marketRisk >= 75 ? "bg-colour-high-score" : property.dripScoreSummarySubScores.marketRisk >= 50 ? "bg-colour-medium-score" : "bg-colour-low-score"
        },
        {
            title: "Location",
            description: property.dripScoreSummaryDescription.location,
            score: property.dripScoreSummarySubScores.location,
            bg: property.dripScoreSummarySubScores.location >= 75 ? "bg-colour-high-score" : property.dripScoreSummarySubScores.location >= 50 ? "bg-colour-medium-score" : "bg-colour-low-score"
        },
        {
            title: "Value-Add",
            description: property.dripScoreSummaryDescription.valueAdd,
            score: property.dripScoreSummarySubScores.valueAdd,
            bg: property.dripScoreSummarySubScores.valueAdd >= 75 ? "bg-colour-high-score" : property.dripScoreSummarySubScores.valueAdd >= 50 ? "bg-colour-medium-score" : "bg-colour-low-score"
        },
        {
            title: "Negotiation",
            description: property.dripScoreSummaryDescription.negotiation,
            score: property.dripScoreSummarySubScores.negotiation,
            bg: property.dripScoreSummarySubScores.negotiation >= 75 ? "bg-colour-high-score" : property.dripScoreSummarySubScores.negotiation >= 50 ? "bg-colour-medium-score" : "bg-colour-low-score"
        }
    ];

    return (
        <div className="score-breakdown-outer-container">
            <h2 className="heading-small-regular colour-primary">
                Score breakdown
            </h2>
            {
                elements.map((item, index) =>
                    <div 
                        className="score-breakdown-element-container"
                        key={index}
                    >
                        <div className="score-breakdown-icon-outer-container">
                            <div className={"score-breakdown-icon-container " + (item.bg)}>
                                <span className="body-semibold colour-primary block-text text-align-center">
                                    {item.score}
                                </span>
                            </div>
                        </div>
                        <div className="score-breakdown-text-container">
                            <h3 className="body-semibold colour-primary">
                                {item.title}
                            </h3>
                            <span className="body-regular colour-secondary">
                                {item.description}
                            </span>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default ScoreBreakdown;