import React from 'react';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { colour } from '../../styles/GlobalStyles';

function CustomSlider(props) {
    const value = props.value;
    const disabled = props.disabled;
    const label = props.label;
    const onChange = props.onChange;
    const min = props.min;
    const max = props.max;
    return (
        <Stack
            sx={{
                width: '100%'
            }}
        >
            <Slider
                aria-label={label}
                value={value}
                onChange={onChange}
                min={min}
                max={max}
                disabled={disabled}
                sx={{
                    color: colour.blueBlue02,
                    width: '100%',
                    height: '5px',
                    padding: 0,
                    '& .MuiSlider-track': {
                        border: 'none'
                    },
                    '& .MuiSlider-thumb': {
                        width: 24,
                        height: 24,
                        backgroundColor: colour.grayScaleWhite,
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                            boxShadow: 'none',
                        },
                    }
                }}
            />
        </Stack>
    )
};

export default CustomSlider;