import React from 'react';

function Sparkle(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M2.73077 0L2.82356 0.470058C3.09683 1.85436 4.11124 2.97513 5.46154 3.38462C4.11124 3.7941 3.09683 4.91487 2.82356 6.29917L2.73077 6.76923L2.63798 6.29917C2.36471 4.91487 1.3503 3.7941 0 3.38462C1.3503 2.97513 2.36471 1.85436 2.63798 0.470058L2.73077 0ZM10 0.615385L10.7199 4.2365C11.0027 5.65922 12.0475 6.80976 13.4364 7.22802L16 8L13.4364 8.77198C12.0475 9.19024 11.0027 10.3408 10.7199 11.7635L10 15.3846L9.28014 11.7635C8.99731 10.3408 7.9525 9.19024 6.56355 8.77198L4 8L6.56356 7.22802C7.9525 6.80976 8.9973 5.65922 9.28014 4.2365L10 0.615385ZM4.04398 10.2608L3.96154 9.84615L3.8791 10.2608C3.6274 11.527 2.6976 12.5509 1.46154 12.9231C2.6976 13.2953 3.6274 14.3192 3.8791 15.5853L3.96154 16L4.04398 15.5853C4.29567 14.3192 5.22547 13.2953 6.46154 12.9231C5.22547 12.5509 4.29567 11.527 4.04398 10.2608Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Sparkle;