import React, { useState } from 'react';
import '../styles/MFHRentMultiUnitType.css';
import { colour } from '../styles/GlobalStyles';
import { RentAccordion } from '../components';
import { FilterReset, FinancialsInput } from '../v4/components';
import { formatPrice, checkNaN } from '../functions';
import { Dot, Units } from '../assets/icons';

function MFHRentMultiUnitType(props) {
    const units = props.units;
    const blurred = props.blurred;
    const rent = props.rent;
    const defaultRent = props.defaultRent;
    const onChangeRent = props.onChangeRent;
    const resetRentEstimates = props.resetRentEstimates;
    const onChangeUnitRent = props.onChangeUnitRent;
    const property = props.property;
    const [rentAccordion, setRentAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };

    return (
        <div className="mfh-rent-multi-unit-type-outer-container">
            <div className="mfh-rent-multi-unit-type-inner-container">
                <RentAccordion
                    rentAccordion={rentAccordion}
                    handleRentAccordion={handleRentAccordion}
                    property={property}
                />
                <div className="property-spacing-border">
				</div>
                <div className="mfh-rent-multi-type-title-container relative-container">
                    <h3 className="body-semibold colour-primary">
                        Rent by unit
                    </h3>
                    {
                        Number(rent) !== Number(defaultRent) &&
                        <Dot
                            className="mfh-rent-multi-unit-type-dot"
                            fill={colour.purplePurple02}
                            stroke={colour.indigoIndigo00}
                        />
                    }
                </div>
                <div className="mfh-rent-multi-unit-type-inputs-container">
                    {
                        units.map((item, index) =>
                            <div 
                                className="mfh-rent-multi-unit-type-element-container"
                                key={index}
                            >
                                <div className="mfh-rent-multi-unit-type-icon-container">
                                    <Units
                                        fill={colour.grayScaleBlack}
                                        className="mfh-rent-multi-unit-type-icon"
                                    />
                                </div>
                                <div className="mfh-rent-multi-unit-type-text-container">
                                    <span className="body-regular colour-primary block-text">
                                        {item.label}
                                    </span>
                                    <div className="mfh-rent-multi-unit-type-grid">
                                        {
                                            item.units.map((unit, ii) =>
                                                <div
                                                    key={ii}
                                                    className="mfh-rent-multi-unit-type-input-container"
                                                >
                                                    <FinancialsInput
                                                        label={unit.label}
                                                        labelType={"inside"}
                                                        info={false}
                                                        value={blurred === true ? "999" : formatPrice(unit.value)}
                                                        onChange={(text) => onChangeUnitRent(text.target.value.replaceAll(",", ""), index, ii)}
                                                        placeholder={formatPrice(rent)}
                                                        type="text"
                                                        leftUnit={"$"}
                                                        rightUnit={null}
                                                        unitSwitch={false}
                                                        leftUnitSelected={true}
                                                        unitSwitchFunc={null}
                                                        disabled={blurred}
                                                        multiline={false}
                                                        error={checkNaN(unit.value)}
                                                        onMouseEnter={null}
                                                        onMouseLeave={null}
                                                        reset={false}
                                                        resetFunc={null}
                                                        resetPosition={null}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    Number(rent) !== Number(defaultRent) &&
                    <div className="mfh-rent-reset-container">
                        <FilterReset
                            clickFunc={() => resetRentEstimates()}
                        />
                    </div>
                }
                <div className="property-spacing-border">
				</div>
                <div className="mfh-rent-multi-unit-type-total-container">
                    <FinancialsInput
                        label={"Total rent"}
                        labelType={"default"}
                        info={false}
                        value={blurred === true ? "999" : formatPrice(rent)}
                        onChange={(text) => onChangeRent(text.target.value.replaceAll(",", ""))}
                        placeholder={formatPrice(defaultRent)}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={blurred}
                        multiline={false}
                        error={checkNaN(rent)}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
            </div>
        </div>
    )
};

export default MFHRentMultiUnitType;