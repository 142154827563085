import React from 'react';

function MobileRadioOutline(props) {
    const fill = props.fill;
    const className = props.className;
    const stroke = props.stroke;
    const selected = props.selected;
    const selectedFill = props.selectedFill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="20" 
            height="20" 
            viewBox="0 0 20 20" 
            fill="none"
            className={className}
        >
            <circle 
                cx="10" 
                cy="10" 
                r="9.5" 
                fill={fill}
                stroke={stroke}
            />
            {
                selected === true &&
                <circle 
                    cx="10" 
                    cy="10" 
                    r="7" 
                    fill={selectedFill}
                />
            }
        </svg>
    )
};

export default MobileRadioOutline;