import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendEmailVerification, signInWithPopup, GoogleAuthProvider, deleteUser, updateEmail } from 'firebase/auth';
import { doc, getFirestore, runTransaction, setDoc, getDoc, getDocs, collection, deleteDoc, query, where } from 'firebase/firestore';
import { setDefaults, fromAddress } from 'react-geocode';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import * as XLSX from 'xlsx';
import mixpanel from 'mixpanel-browser';
import { allStates, citiesForPurchase, defaultUserData, formatterLong } from '../styles/GlobalStyles';
import moment from 'moment';
import { FullStory } from '@fullstory/browser';
import posthog from 'posthog-js';
import { CUMIPMT } from '@formulajs/formulajs'
import { defaultVals } from '../defaults';
import TagManager from 'react-gtm-module';

setDefaults({
	key: JSON.parse(process.env.REACT_APP_GOOGLE).key,
	language: "en", // Default language for responses.
	region: "us", // Default region for responses.
});

export const cloudFunctionV2 = async(url, data) => {
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			if (error instanceof TypeError) {
				const res = {
					status: 406,
					error: "Load failed - network error"
				};
				resolve(res);
			}
		});
	});
};

export const createUser = (email, password) => {
	const auth = getAuth();
	return new Promise(resolve => {
		createUserWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			const data = {
				user: userCredential.user,
				status: 200,
				message: "Success",
				errorCode: ""
			}
			resolve(data);
		})
		.catch((error) => {
			const data = {
				user: "",
				status: 400,
				message: error.message,
				errorCode: error.code
			}
			resolve(data);
		});
	})
}

export const getUser = (email, password) => {
	const auth = getAuth();
	return new Promise(resolve => {
		signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			const data = {
				code : "",
				message: "",
				status: 200,
				userId: userCredential.user.uid,
				user: userCredential.user
			};
			resolve(data);
		})
		.catch((error) => {
			const data = {
				code : error.code,
				message: error.message,
				status: 400,
				userId: "",
				user: ""
			};
			resolve(data);
		});
	});
}

export const signInWithGoogle = async() => {
	const auth = getAuth();
	const provider = new GoogleAuthProvider();
	return new Promise(resolve => {
		signInWithPopup(auth, provider)
		.then((result) => {
			const user = result.user;
			const data = {
				code : "",
				message: "",
				status: 200,
				userId: user.uid,
				user: user
			};
			resolve(data);
		}).catch((error) => {
			const data = {
				code : error.code,
				message: error.message,
				status: 400,
				userId: "",
				user: ""
			};
			resolve(data);
		});
	});
}

export const resetPassword = (email) => {
	const auth = getAuth();
	return new Promise(resolve => {
		sendPasswordResetEmail(auth, email)
		.then(() => {
			const success = {
				status: 200
			};
			resolve(success);
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			const failure = {
				status: 400,
				code: errorCode,
				message: errorMessage
			}
			resolve(failure);
		});
	});
}

export const emailVerification = async() => {
	const auth = getAuth();

	return new Promise(resolve => {
		sendEmailVerification(auth.currentUser)
		.then(() => {
			resolve(true);
		})
		.catch((err) => {
			console.log("Email verification error = ", err);
			resolve(false);
		});
	});
}

export const updateUserEmail = async(email) => {
	const auth = getAuth();
	return new Promise(resolve => {
		updateEmail(auth.currentUser, email)
		.then(() => {
			const success = {
				status: 200
			}
			resolve(success);
		})
		.catch((error) => {
			const failure = {
				status: 400,
				error: error
			}
			resolve(failure);
		});
	});
};

export const logout = () => {
	const auth = getAuth();
	return new Promise(resolve => {
		signOut(auth)
		.then(() => {
			const data = {
				status: 200,
				message: ""
			};
			resolve(data);
		})
		.catch((error) => {
			const data = {
				status: 400,
				message: error
			};
			resolve(data);
		});
	});
}

export const userDeletion = (user) => {
	return new Promise(resolve => {
		deleteUser(user).then(() => {
			const data = {
				status: 200
			};
			resolve(data);
		}).catch((error) => {
			const data = {
				status: 400,
				message: error
			};
			resolve(data);
		});
	});
}

export const checkUserId = async() => {
	const auth = getAuth();
	const user = auth.currentUser;
	if (user !== null) {
		const providerData = user.providerData;
		let emailChangeable = true;
		for (let index = 0; index < providerData.length; index++) {
			const providerId = providerData[index].providerId;
			if ( providerId !== "password" ) {
				emailChangeable = false;
			}
		}

		const data = {
			status: 200,
			userId: user.uid,
			email: user.email,
			emailVerified: user.emailVerified,
			emailChangeable: emailChangeable
		};

		return data;
	}
	else {
		const data = {
			status: 400,
			userId: "",
			email: "",
			emailVerified: "",
			emailChangeable: false
		};
		return data;
	}
}

export const checkOnAuthStateChanged = async() => {
	const auth = getAuth();
	return new Promise(resolve => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				const providerData = user.providerData;
				let emailChangeable = true;
				for (let index = 0; index < providerData.length; index++) {
					const providerId = providerData[index].providerId;
					if ( providerId !== "password" ) {
						emailChangeable = false;
					}
				}
				const data = {
					status: 200,
					userId: user.uid,
					email: user.email,
					emailVerified: user.emailVerified,
					emailChangeable: emailChangeable,
					user: user
				};
	
				resolve(data);
			}
			else {
				const data = {
					status: 400,
					userId: "",
					email: "",
					emailVerified: "",
					emailChangeable: false
				};
				resolve(data);
			}
		});
	});
}

export const resetFilters = async(setDisabled, setLoading, setSearchParams) => {
	setLoading(true);
	setDisabled(true);

	setSearchParams({});

	setLoading(false);
	setDisabled(false);
};

export const makeId = (length) => {
    var id = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       id += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return id;
}

export const updateHideHeatmapSetting = async(val) => {
	const colRef = "Users";
	const user = await checkOnAuthStateChanged();
	if ( user.status === 200 ) {
		const docRef = user.userId;
		const queryData = await getDocument(colRef, docRef);
		if ( queryData.status === 200 ) {
			const data = queryData.data.data;
			data.hideHeatmap = val;
			await setData(colRef, docRef, data);
		}
	}
};

export const updateShownHeatmapModal = async(val) => {
	const colRef = "Users";
	const user = await checkOnAuthStateChanged();
	if ( user.status === 200 ) {
		const docRef = user.userId;
		const queryData = await getDocument(colRef, docRef);
		if ( queryData.status === 200 ) {
			const data = queryData.data.data;
			data.shownHeatmapModal = val;
			await setData(colRef, docRef, data);
		}
	}
};

export const addStripeCreditCard = async(
	event,
	stripe,
	setDisabled,
	elements,
	setErrorMessage,
	userData,
	setUserData,
	setPaymentMethodModal,
	domain,
	savedClientSecret,
	pageState
) => {
	return new Promise(async(resolve) => {
		setDisabled(true);
		event.preventDefault();

		if (!stripe || !elements) {
			const failure = {
				status: 400,
				error: "Stripe not initialized"
			};
			resolve(failure);
		}

		const rootURL = window.location.host;
		const returnPath = domain === "buy-credits" ? "buy-credits?" : domain === "profile" ? "profile?tab=1&" : domain === "pay" ? "pay?" : domain === "pull-records-pay" ? "pull-records-pay?" : "";

		let stateId = null;
		const recordsStateObject = domain === "pull-records-pay" ? {
			"compCount": pageState.compCount,
			"comps": pageState.comps,
			"county": pageState.county,
			"plan": pageState.plan,
			"reAPIEndpoint": pageState.reAPIEndpoint,
			"reAPIParams": pageState.reAPIParams,
			"skipTrace": pageState.skipTrace,
			"total": pageState.total,
			"totalRecords": pageState.totalRecords
		} : null;

		const stateObject = pageState === null ? null : recordsStateObject !== null ? recordsStateObject : {
			"allCities": pageState.allCities,
			"autoBilling": pageState.autoBilling,
			"plan": pageState.plan,
			"price": pageState.price,
			"subscriptionId": pageState.subscriptionId
		};

		if ( pageState !== null ) {
			const newId = await makeId(10);
			stateId = newId;
			stateObject.date = new Date();
			stateObject.email = userData.email;
			await setData("Page State", newId, stateObject);
		}
	
		const productParams = domain === "profile" || domain === "buy-credits" ? "" : `&stateId=${stateId}`;
		const fullReturnURL = `http://${rootURL}/${returnPath}setup_intent_client_secret=${savedClientSecret}${productParams}`

		await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: fullReturnURL
			},
			redirect: "if_required" //always
		})
		.then(async(result) => {
			if (result.error) {
				setErrorMessage(result.error.message);
				// setLoading(false);
				setDisabled(false);
				const failure = {
					status: 400,
					error: result.error.message
				};
				resolve(failure);
			}
			else {
				setErrorMessage(null);
				const id = result.setupIntent.id;
				const mode = result.setupIntent.livemode === true ? "live" : "test";
				const request = {
					setupIntent: id,
					mode: mode
				}
				const setupIntent = await retrieveStripeSetupIntent(request);
				const paymentMethod = setupIntent.setupIntent.payment_method;
				const paymentMethodReq = {
					paymentMethod: paymentMethod,
					mode: mode
				};
				const retreiveCard = await retrieveStripePaymentMethod(paymentMethodReq);
				if ( retreiveCard.paymentMethod.customer !== null ) {
					const paymentMethodId = retreiveCard.paymentMethod.id;
					const customerId = retreiveCard.paymentMethod.customer;
					const newCustomerData = {
						paymentMethod: paymentMethodId,
						customerId: customerId,
						mode: mode
					}
					const setDefaultPaymentMethod = await updateStripeCustomer(newCustomerData);
					if ( setDefaultPaymentMethod.status === 200 ) {
						const card = retreiveCard.paymentMethod.card;
						card.paymentMethod = paymentMethod;
						card.mode = mode;

						const amendedCard = {
							brand: card.brand,
							last4: card.last4,
							exp_month: card.exp_month,
							exp_year: card.exp_year,
							mode: card.mode,
							paymentMethod: card.paymentMethod
						};

						const user = await checkUserId();
						if ( user.status === 200 ) {
							const colRef = "Users";
							const userId = user.userId;
							const newUserDetails = structuredClone(userData);
							newUserDetails.creditCard = amendedCard;
							await setData(colRef, userId, newUserDetails);
							setUserData(newUserDetails);
							
							if ( setPaymentMethodModal !== null ) {
								setPaymentMethodModal(false);
							}
							setDisabled(false);
							const success = {
								status: 200,
								userDetails: newUserDetails
							}
							resolve(success);
						}
						else {
							setDisabled(false);
							const failure = {
								status: 400,
								error: "User logged out"
							}
							resolve(failure);
						}
					}
					else {
						setDisabled(false);
						const failure = {
							status: 400,
							error: "Customer not updated"
						}
						resolve(failure);
					}
				}
				else {
					setDisabled(false);
					const failure = {
						status: 400,
						error: "Card not retrieved"
					}
					resolve(failure);
				}
			}
		})
		.catch((error) => {
			setDisabled(false);
			const failure = {
				status: 400,
				error: error
			}
			resolve(failure);
		});
		setDisabled(false);
	});
};

export const queryOfferREI = async(data) => {
	const url = "https://queryofferrei-oyonrz73aa-uc.a.run.app";

	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const getStripeCustomer = async(data) => {
	const url = "https://createstripecustomerv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
}

export const retrieveStripeCustomer = async(data) => {
	const url = "https://retrievestripecustomerv2-oyonrz73aa-uc.a.run.app";

	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const createStripeSetupIntent = async(data) => {
	const url = "https://createstripesetupintentv2-oyonrz73aa-uc.a.run.app";

	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const getSetupIntent = async(code, mode) => {
	const request = {
		setupIntent: code,
		mode: mode
	}
	const setupIntent = await retrieveStripeSetupIntent(request);
	const paymentMethod = setupIntent.setupIntent.payment_method;
	const paymentMethodReq = {
		paymentMethod: paymentMethod,
		mode: mode
	};
	const retreiveCard = await retrieveStripePaymentMethod(paymentMethodReq);
	if ( retreiveCard.paymentMethod.customer !== null ) {
		const card = retreiveCard.paymentMethod.card;
		card.paymentMethod = paymentMethod;
		card.mode = mode;
		return card;
	}
	else {
		return "";
	}
};

export const retrieveStripeSetupIntent = async(data) => {
	const url = "https://retrievestripesetupintentv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const retrieveStripePaymentMethod = async(data) => {
	const url = "https://retrievestripepaymentmethodv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const updateStripeCustomer = async(data) => {
	const url = "https://updatestripecustomerv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const deleteStripePaymentMethod = async(data) => {
	const url = "https://deletestripepaymentmethodv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const submitPayment = async(newUserDetails, metadata) => {
	const discountApplied = metadata.discountApplied;
	const amount = discountApplied === null ? metadata.price * 100 : metadata.discountedTotal * 100;
	const customer = newUserDetails.customerId;
	const currency = "usd";
	const paymentMethod = newUserDetails.creditCard.paymentMethod;
	const receipt_email = newUserDetails.email;
	const confirm = true;
	const mode = window.location.hostname === "localhost" || window.location.hostname.includes("refi-787d3") ? "test" : "live";
	const returnUrl = mode === "test" ? "http://localhost:3000/profile" : "https://app.coffeeclozers.com/profile";

	const params = {
		amount: amount,
		customer: customer,
		currency: currency,
		paymentMethod: paymentMethod,
		receipt_email: receipt_email,
		confirm: confirm,
		returnUrl: returnUrl,
		mode: mode,
		metadata: {}
	};
	const payment = await createStripePaymentIntent(params);
	return payment;
};

export const payAutoBilling = async(userData, mode, metadata) => {
	const newItems = [];
	const price = metadata.subscriptionId;
	const priceIndex = newItems.findIndex(e => e.price === price);
	if ( priceIndex === -1 ) {
		const newElement = {
			price: price,
			quantity: 1
		};
		newItems.push(newElement);
	}
	else {
		newItems[priceIndex].quantity = newItems[priceIndex].quantity + 1;
	}

	const subscriptionData = {
		customerId: userData.customerId,
		items: newItems,
		mode: mode,
		metadata: metadata
	}
	const createSubscription = await createStripeCitySubscription(subscriptionData);
	return createSubscription
}

export const createStripePaymentIntent = async(data) => {
	const url = "https://createstripepaymentintentv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const createStripeCitySubscription = async(data) => {
	const url = "https://createstripecitysubscriptionv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const writeSubscriptionData = async(payment, autoBilling, metadata, userDetails, discountApplied) => {
	const colRef = "Subscriptions";
	const user = await checkUserId();
	if ( user.status === 200 ) {
		const userId = user.userId;
		const getUserDetails = await getDocument(colRef, userId);
		let subscriptions = [];
		if ( getUserDetails.status === 200 ) {
			subscriptions = getUserDetails.data.data;
		}
		const monthDuration = Number(metadata.months);
		const startDate = new Date();
		const endDate = moment(startDate).add(monthDuration, "M");
		const allCities = metadata.allCities;
		const counties = metadata.counties;
		const creative = metadata.creative;

		const totalPrice = discountApplied === null ? metadata.price : metadata.discountedTotal;
		const subscriptionArray = [];
		for (let index = 0; index < counties; index++) {
			if ( autoBilling === false ) {
				const price = Number((totalPrice / counties).toFixed(2));
				const newSubscription = {
					startDate: startDate,
					endDate: endDate['_d'],
					id: payment.paymentMethod.id,
					autoBilling: false,
					price: price,
					allCities: allCities,
					discountApplied: discountApplied,
					credits: false,
					creative: creative
				};
	
				newSubscription.msaCode = "";
				newSubscription.metroCities = [];
				newSubscription.msaTitle = "";
				newSubscription.metroArea = true;
				newSubscription.msaCityIds = [];
				subscriptionArray.push(newSubscription);
			}
			else if ( autoBilling === true ) {
				const price = Number((totalPrice / counties).toFixed(2));
				const newSubscription = {
					startDate: startDate,
					endDate: endDate['_d'],
					id: payment.subscription.id,
					autoBilling: autoBilling,
					cancelled: false,
					price: price,
					allCities: allCities,
					discountApplied: discountApplied,
					credits: false,
					creative: creative
				};
	
				newSubscription.msaCode = "";
				newSubscription.metroCities = [];
				newSubscription.msaTitle = "";
				newSubscription.metroArea = true;
				newSubscription.msaCityIds = [];
				subscriptionArray.push(newSubscription);
			}	
		}

		const combinedSubscriptions = subscriptions.concat(subscriptionArray);
		const writeData = await setData(colRef, userId, combinedSubscriptions);

		const credits = 99999;
		await writeCredits(userId, credits);

		// Write county assigning
		await unassignedCountyWrite(userId, [true, true]);

		if ( writeData.status === 200 ) {
			const senderEmail = "info@coffeeclozers.com";
			const recipientEmail = userDetails.email;
			const dynamicTemplate = "d-f6dba87ee0384ee98b6035c4cc5b7ef4";
			const emailPrice = discountApplied === null ? metadata.price : metadata.discountedTotal;

			const emailData = {
				firstName: userDetails.firstName,
				payment: `${formatterLong.format(emailPrice).replace(".00", "")}`,
				duration: monthDuration === 1 ? "1 month" : `${monthDuration} months`
			};

			const msg = {
				to: recipientEmail,
				from: senderEmail,
				templateId: dynamicTemplate,
				dynamic_template_data: {
					data: emailData
				}
			};
			await sendEmail(msg);

			const tags = [
				{
					name: "hasConverted",
					status: "active"
				}    
			]
			const mailchimpData = {
				email: recipientEmail,
				list_id: "d7f5358698",
				tags: tags
			}
			await addMailchimpAPITag(mailchimpData);

			const res = {
				status : 200,
				email: recipientEmail
			};
			return res;
		}
		else {
			const res = {
				status : 400
			};
			return res;
		}
	}
};

export const writeCreditsSubscriptionData = async(payment, autoBilling, metadata, userDetails, discountApplied) => {
	const colRef = "Subscriptions";
	const user = await checkUserId();
	if ( user.status === 200 ) {
		const userId = user.userId;
		const getUserDetails = await getDocument(colRef, userId);
		let subscriptions = [];
		if ( getUserDetails.status === 200 ) {
			subscriptions = getUserDetails.data.data;
		}
		const monthDuration = Number(metadata.months);
		const startDate = new Date();
		const endDate = moment(startDate).add(monthDuration, "M");

		const subscriptionArray = [];
		if ( autoBilling === true ) {
			const price = metadata.price;
			const newSubscription = {
				startDate: startDate,
				endDate: endDate['_d'],
				id: payment.subscription.id,
				autoBilling: autoBilling,
				cancelled: false,
				price: price,
				allCities: false,
				discountApplied: null,
				credits: true,
				creditCount: metadata.creditCount
			};
			subscriptionArray.push(newSubscription);
		}

		const combinedSubscriptions = subscriptions.concat(subscriptionArray);
		const writeData = await setData(colRef, userId, combinedSubscriptions);

		const propertySearchColRef = "Property Searches";
		const queryPropertySearchData = await getDocument(propertySearchColRef, userId);
		let searchesObject;
		if ( queryPropertySearchData.status === 200 ) {
			searchesObject = queryPropertySearchData.data.data;
		}
		else {
			searchesObject = {
				searches: [],
				credits: 0
			};
		}

		if ( searchesObject.credits === undefined ) {
			searchesObject = {
				searches: [],
				credits: metadata.creditCount
			}
		}
		else if ( searchesObject.credits > metadata.creditCount ) {
			searchesObject.credits = searchesObject.credits + metadata.creditCount;
		}
		else {
			searchesObject.credits = metadata.creditCount;
		}

		const writePropertySearches = await setData(propertySearchColRef, userId, searchesObject);

		if ( writeData.status === 200 && writePropertySearches.status === 200 ) {
			const senderEmail = "info@coffeeclozers.com";
			const recipientEmail = userDetails.email;
			const dynamicTemplate = "d-f6dba87ee0384ee98b6035c4cc5b7ef4";
			const emailPrice = metadata.price;

			const emailData = {
				firstName: userDetails.firstName,
				payment: `${formatterLong.format(emailPrice).replace(".00", "")}`,
				duration: monthDuration === 1 ? "1 month" : `${monthDuration} months`
			};

			const msg = {
				to: recipientEmail,
				from: senderEmail,
				templateId: dynamicTemplate,
				dynamic_template_data: {
					data: emailData
				}
			};
			await sendEmail(msg);

			const tags = [
				{
					name: "hasConverted",
					status: "active"
				}    
			]
			const mailchimpData = {
				email: recipientEmail,
				list_id: "d7f5358698",
				tags: tags
			}
			await addMailchimpAPITag(mailchimpData);

			const res = {
				status : 200,
				email: recipientEmail
			};
			return res;
		}
		else {
			const res = {
				status : 400
			};
			return res;
		}
	}
};

export const writeCredits = async(userId, credits) => {
	const colRef = "Property Searches";
	const docRef = userId;
	const queryData = await getDocument(colRef, docRef);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		data.credits = credits;
		await setData(colRef, docRef, data);
	}
	else {
		const data = {
			searches: [],
			credits: credits
		};
		await setData(colRef, docRef, data);
	}
};

export const unassignedCountyWrite = async(userId, vals) => {
	const colRef = "Users";
	const docRef = userId;
	const queryData = await getDocument(colRef, docRef);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;

		if ( vals === null )  {
			delete data.unassignedCounties;
			await setData(colRef, docRef, data);
		}
		else {
			data.unassignedCounties = vals;
			await setData(colRef, docRef, data);
		}
	}
};

export const writeBuyBoxes = async(userId, cities) => {
	const colRef = "Buy Boxes";
	const docRef = userId;
	const queryData = await getDocument(colRef, docRef);
	let buyBoxes = [];
	if ( queryData.status === 200 ) {
		buyBoxes = queryData.data.data;
	}

	for (let index = 0; index < cities.length; index++) {
		const chosenCity = cities[index];
		const metroArea = chosenCity.metroArea;
		const findCity = buyBoxes.findIndex(e => e.msaCode === chosenCity.msaCode);
		if ( findCity === -1 ) {
			const newBuyBox = {
				metroArea: metroArea,
				buyBoxes: [],
				loaded: false
			};

			const metroCities = chosenCity.metroCities;
			newBuyBox.msaCode = chosenCity.msaCode;
			newBuyBox.metroCities = metroCities;
			newBuyBox.msaTitle = chosenCity.msaTitle;

			const msaCityIds = [];
			for (let index = 0; index < metroCities.length; index++) {
				const element = metroCities[index];
				msaCityIds.push(element.cityId);
			};
			newBuyBox.msaCityIds = msaCityIds;
			buyBoxes.unshift(newBuyBox);
		}
		else {
			buyBoxes[findCity].loaded = false;
		}	
	}

	await setData(colRef, docRef, buyBoxes);
};

export const updateWaitingRoom = async(params) => {
	const colRef = "Waiting Room";
	const docRef = "Users";
	
	const selectedCounties = params.selectedCounties;
	const userId = params.userId;
	const email = params.email;
	const paid = params.paid;
	
	const db = getFirestore();
	const queryParams = doc(db, colRef, docRef);
	return new Promise(async(resolve) => {
		await runTransaction(db, async (transaction) => {
			const query = await transaction.get(queryParams);
			if (!query.exists()) {
				const res = {
					status: 400,
					message: `Transaction failed: document doesn't exist`
				};
				resolve(res);
			}
			else {
				let data = query.data().data;

				for (let index = 0; index < selectedCounties.length; index++) {
					const chosenCity = selectedCounties[index];
					const cityId = chosenCity.msaCode;
					const city = chosenCity.msaTitle;

					const findCity = data.findIndex(e => e.cityId === cityId);
					const newUser = {
						userId: userId,
						email: email,
						date: new Date(),
						paid: paid
					}

					if ( findCity === -1 ) {
						const newCity = {
							city: city,
							state: "",
							cityId: cityId,
							metroArea: true,
							users: [newUser]
						};
						data.push(newCity);
					}
					else {
						data[findCity].users.push(newUser);
					}	
				}

				transaction.update(queryParams, {
					data
				})
			}
		})
		.then(() => {
			const res = {
				status: 200
			};
			resolve(res);
		})
		.catch((error) => {
			const res = {
				status: 400,
				message: `Transaction error: ${error}`
			};
			resolve(res);
		});
	});
};

export const getFreeTrialEnd = async(userId) => {
	return new Promise(async(resolve) => {
		const colRef = "Users";
		const docRef = userId;
		const queryData = await getDocument(colRef, docRef);
		if ( queryData.status === 200 ) {
			const data = queryData.data.data;
			const freeTrial = data.freeTrial;

			if ( freeTrial.length > 0 ) {
				const endDate = freeTrial[0].endDate;
				let date = new Date(0); 
				date.setUTCSeconds(endDate.seconds);
				
				const startDate = freeTrial[0].startDate;
				let formattedStartDate = new Date(0);
				formattedStartDate.setUTCSeconds(startDate.seconds);

				const res = {
					status: 200,
					endDate: date,
					startDate: formattedStartDate
				};
				resolve(res);
			}
			else {
				const res = {
					status: 400
				}
				resolve(res);
			}
		}
		else {
			const res = {
				status: 400
			};
			resolve(res);
		}
	});
};

export const checkStripeError = (error) => {
	if ( error.decline_code === "authentication_required" ) {
		return "The card was declined because the transaction requires authentication such as 3D Secure.";
	}
	else if ( error.decline_code === "approve_with_id" ) {
		return "The payment can’t be authorised.";
	}
	else if ( error.decline_code === "call_issuer" ) {
		return "The card was declined for an unknown reason. Please contact us for more details.";
	}
	else if ( error.decline_code === "card_not_supported" ) {
		return "The card does not support this type of purchase.";
	}
	else if ( error.decline_code === "card_velocity_exceeded" ) {
		return "The customer has exceeded the balance, credit limit, or transaction amount limit available on their card.";
	}
	else if ( error.decline_code === "currency_not_supported" ) {
		return "The card does not support the specified currency.";
	}
	else if ( error.decline_code === "do_not_honor" ) {
		return "The card was declined for an unknown reason. Please contact us for more details.";
	}
	else if ( error.decline_code === "do_not_try_again" ) {
		return "The card was declined for an unknown reason. Please contact us for more details.";
	}
	else if ( error.decline_code === "duplicate_transaction" ) {
		return "A transaction with identical amount and credit card information was submitted very recently.";
	}
	else if ( error.decline_code === "expired_card" ) {
		return "The card has expired.";
	}
	else if ( error.decline_code === "fraudulent" ) {
		return "The card was declined for an unknown reason or Stripe Radar blocked the payment."
	}
	else if ( error.decline_code === "generic_decline" ) {
		return "The card was declined for an unknown reason or Stripe Radar blocked the payment."
	}
	else if ( error.decline_code === "incorrect_number" ) {
		return "The card number is incorrect."
	}
	else if ( error.decline_code === "incorrect_cvc" ) {
		return "The CVC number is incorrect."
	}
	else if ( error.decline_code === "incorrect_pin" ) {
		return "The PIN entered is incorrect. This decline code only applies to payments made with a card reader."
	}
	else if ( error.decline_code === "incorrect_zip" ) {
		return "The ZIP code is incorrect."
	}
	else if ( error.decline_code === "insufficient_funds" ) {
		return "The card has insufficient funds to complete the purchase."
	}
	else if ( error.decline_code === "invalid_account" ) {
		return "The card, or account the card is connected to, is invalid."
	}
	else if ( error.decline_code === "invalid_amount" ) {
		return "The payment amount is invalid, or exceeds the amount that’s allowed."
	}
	else if ( error.decline_code === "invalid_cvc" ) {
		return "The CVC number is incorrect."
	}
	else if ( error.decline_code === "invalid_expiry_month" ) {
		return "The expiry month is invalid."
	}
	else if ( error.decline_code === "invalid_expiry_year" ) {
		return "The expiry year is invalid."
	}
	else if ( error.decline_code === "invalid_number" ) {
		return "The card number is incorrect."
	}
	else if ( error.decline_code === "invalid_pin" ) {
		return "The PIN entered is incorrect."
	}
	else if ( error.decline_code === "issuer_not_available" ) {
		return "The card issuer couldn’t be reached, so the payment couldn’t be authorised."
	}
	else if ( error.decline_code === "lost_card" ) {
		return "The payment was declined because the card has been reported as lost."
	}
	else if ( error.decline_code === "merchant_blacklist" ) {
		return "The card was declined for an unknown reason or Stripe Radar blocked the payment."
	}
	else if ( error.decline_code === "new_account_information_available" ) {
		return "The card, or account the card is connected to, is invalid."
	}
	else if ( error.decline_code === "no_action_taken" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "not_permitted" ) {
		return "The payment isn’t permitted."
	}
	else if ( error.decline_code === "offline_pin_required" ) {
		return "The card was declined because it requires a PIN."
	}
	else if ( error.decline_code === "online_or_offline_pin_required" ) {
		return "The card was declined because it requires a PIN."
	}
	else if ( error.decline_code === "pickup_card" ) {
		return "The customer can’t use this card to make this payment (it’s possible it was reported lost or stolen)."
	}
	else if ( error.decline_code === "pin_try_exceeded" ) {
		return "The allowable number of PIN tries was exceeded."
	}
	else if ( error.decline_code === "processing_error" ) {
		return "An error occurred while processing the card. Please try again."
	}
	else if ( error.decline_code === "reenter_transaction" ) {
		return "The payment couldn’t be processed by the issuer for an unknown reason."
	}
	else if ( error.decline_code === "restricted_card" ) {
		return "The customer can’t use this card to make this payment (it’s possible it was reported lost or stolen)."
	}
	else if ( error.decline_code === "revocation_of_all_authorizations" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "revocation_of_authorization" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "security_violation" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "service_not_allowed" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "stolen_card" ) {
		return "The card was declined for an unknown reason or Stripe Radar blocked the payment."
	}
	else if ( error.decline_code === "stop_payment_order" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "testmode_decline" ) {
		return "A Stripe test card number was used."
	}
	else if ( error.decline_code === "try_again_later" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "transaction_not_allowed" ) {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
	else if ( error.decline_code === "withdrawal_count_limit_exceeded" ) {
		return "The customer has exceeded the balance or credit limit available on their card."
	}
	else {
		return "The card was declined for an unknown reason. Please contact us for more details."
	}
}

export const submitError = async(colRef, docRef, data) => {
	return new Promise(resolve => {
		const db = getFirestore();
		setDoc(doc(db, colRef, docRef), {
			data
		})
		.then(() => {
			const res = {
				status: 200,
				message: "Document created successfully"
			};
			resolve(res);
		})
		.catch((e) => {
			const res = {
				status: 400,
				message: `Error in creating document ${e}`
			};
			console.log("Set data error = ", e);
			console.log("Set data error data = ", data);
			resolve(res);
		});
	});
};

export const setData = (colRef, docRef, data) => {
	return new Promise(resolve => {
		const db = getFirestore();
		setDoc(doc(db, colRef, docRef), {
			data
		})
		.then(() => {
			const res = {
				status: 200,
				message: "Document created successfully"
			};
			resolve(res);
		})
		.catch((e) => {
			const res = {
				status: 400,
				message: `Error in creating document ${e}`
			};
			console.log("Set data error = ", e);
			console.log("Set data error data = ", data);
			resolve(res);
		});
	});
}

export const setSubData = (colRef, docRef, subColRef, subDocRef, data) => {
	return new Promise(resolve => {
		const db = getFirestore();
		setDoc(doc(db, colRef, docRef, subColRef, subDocRef), {
			data
		})
		.then(() => {
			const res = {
				status: 200,
				message: "Document created successfully"
			};
			resolve(res);
		})
		.catch((e) => {
			console.log("Error = ", e);
			const res = {
				status: 400,
				message: `Error in creating document ${e}`
			};
			resolve(res);
		});
	});
}

export const setMergeData = (colRef, docRef, data) => {
	return new Promise(resolve => {
		const db = getFirestore();
		setDoc(doc(db, colRef, docRef), {
			data
		}, {merge: true})
		.then(() => {
			const res = {
				status: 200,
				message: "Document created successfully"
			};
			resolve(res);
		})
		.catch((e) => {
			console.log("Error = ", e);
			const res = {
				status: 400,
				message: `Error in creating document ${e}`
			};
			resolve(res);
		});
	});
}

export const setMergeSubData = (colRef, docRef, subColRef, subDocRef, data) => {
	return new Promise(resolve => {
		const db = getFirestore();
		setDoc(doc(db, colRef, docRef, subColRef, subDocRef), {
			data
		}, {merge: true})
		.then(() => {
			const res = {
				status: 200,
				message: "Document created successfully"
			};
			resolve(res);
		})
		.catch((e) => {
			console.log("Error = ", e);
			const res = {
				status: 400,
				message: `Error in creating document ${e}`
			};
			resolve(res);
		});
	});
}

export const setTransaction = async (colRef, docRef, root, val, subval) => {
	const db = getFirestore();
	const queryParams = doc(db, colRef, docRef);
	let res;
	try {
		await runTransaction(db, async (transaction) => {
			const query = await transaction.get(queryParams);
			if (!query.exists()) {
				res = {
					status: 400,
					message: `Transaction failed: document doesn't exist`
				};
			}
			else {
				let data = query.data().data;
				root === null ?
					data = val
				:
				subval === null ?
					data[root] = val
				:
					data[root][subval] = val
				transaction.update(queryParams, {
					data
				});
				res = {
					status: 200,
					message: "Transaction successfully committed"
				};
			}
		});

		return res;
	} 
	catch (e) {
		const res = {
			status: 400,
			message: `Transaction error: ${e}`
		};
		return res;
	}
}

export const setTransactionPushArray = async (colRef, docRef, val) => {
	const db = getFirestore();
	const queryParams = doc(db, colRef, docRef);
	let res;
	try {
		await runTransaction(db, async (transaction) => {
			const query = await transaction.get(queryParams);
			if (!query.exists()) {
				res = {
					status: 400,
					message: `Transaction failed: document doesn't exist`
				};
			}
			else {
				const data = query.data().data;
				const dataIndex = data.indexOf(val);

				if ( dataIndex === -1 ) {
					data.push(val);
					transaction.update(queryParams, {
						data
					});
					res = {
						status: 200,
						message: "Transaction successfully committed"
					};
				}
				else {
					transaction.update(queryParams, {
						data
					});
					res = {
						status: 400,
						message: "Transaction successfully committed"
					};
				}
			}
		});

		return res;
	} 
	catch (e) {
		const res = {
			status: 400,
			message: `Transaction error: ${e}`
		};
		return res;
	}
}

export const setSubTransaction = async (colRef, docRef, subColRef, subDocRef, root, val, subval) => {
	const db = getFirestore();
	const queryParams = doc(db, colRef, docRef, subColRef, subDocRef);
	let res;
	try {
		await runTransaction(db, async (transaction) => {
			const query = await transaction.get(queryParams);
			if (!query.exists()) {
				res = {
					status: 400,
					message: `Transaction failed: document doesn't exist`
				};
			}
			else {
				const data = query.data().data;
				subval === null ?
					data[root] = val
				:
					data[root][subval] = val
				transaction.update(queryParams, {
					data
				});
				res = {
					status: 200,
					message: "Transaction successfully committed"
				};
			}
		});

		return res;
	} 
	catch (e) {
		const res = {
			status: 400,
			message: `Transaction error: ${e}`
		};
		return res;
	}
}

export const setViewedPropTransaction = async (colRef, docRef, val) => {
	const db = getFirestore();
	const queryParams = doc(db, colRef, docRef);
	let res;
	try {
		await runTransaction(db, async (transaction) => {
			const query = await transaction.get(queryParams);
			if (!query.exists()) {
				res = {
					status: 400,
					message: `Transaction failed: document doesn't exist`
				};
			}
			else {
				const data = query.data().data;
				const viewedProperties = data.viewedProperties;
				if ( viewedProperties === undefined ) {
					const newArray = [
						{
							zpid: val,
							date: new Date()
						}
					];
					data.viewedProperties = newArray;
				}
				else {
					const zpidIndex = viewedProperties.findIndex(e => e.zpid === val);
					if ( zpidIndex === -1 ) {
						const newObject = {
							zpid: val,
							date: new Date()
						}
						viewedProperties.push(newObject);
						data.viewedProperties = viewedProperties;
					}
				}

				transaction.update(queryParams, {
					data
				});
				res = {
					status: 200,
					message: "Transaction successfully committed"
				};
			}
		});

		return res;
	} 
	catch (e) {
		const res = {
			status: 400,
			message: `Transaction error: ${e}`
		};
		return res;
	}
}

export const getDocument = async (colRef, docRef) => {
	const db = getFirestore();
	const query = doc(db, colRef, docRef);

	return new Promise(async resolve => {
		await getDoc(query)
		.then((doc) => {
			if ( doc.exists() ) {
				const res = {
					status: 200,
					data: doc.data(),
					message: "Document found!"
				}
				resolve(res);
			} 
			else {
				const res = {
					status: 400,
					data: [],
					message: "No such document!"
				}
				resolve(res);
			}
		})
		.catch((error) => {
			const res = {
				status: 400,
				data: [],
				message: `Error getting document: ${error}`,
				code: error.code
			}
			resolve(res);
		});




		// REPLACE CACHE ABOVE WITH THIS
		// FOR OFFLINE MODE

		// .catch(async (error) => {
		// 	// If there is an error (e.g., network issue), fallback to cache
		// 	console.warn(`Error getting document online: ${error}. Attempting to fetch from cache...`);
		// 	await getDoc(query, { source: "cache" })
		// 		.then((doc) => {
		// 			if (doc.exists()) {
		// 				const res = {
		// 					status: 200,
		// 					data: doc.data(),
		// 					message: "Document found in cache!"
		// 				};
		// 				resolve(res);
		// 			} else {
		// 				const res = {
		// 					status: 400,
		// 					data: [],
		// 					message: "No such document in cache!"
		// 				};
		// 				resolve(res);
		// 			}
		// 		})
		// 		.catch((cacheError) => {
		// 			const res = {
		// 				status: 400,
		// 				data: [],
		// 				message: `Error getting document from cache: ${cacheError}`,
		// 				code: cacheError.code
		// 			};
		// 			resolve(res);
		// 		});
		// });
	});
};

export const checkUserDocumentExists = async (user, navigate) => {
	const db = getFirestore();
	const colRef = "Users";
	const docRef = user.userId;
	const query = doc(db, colRef, docRef);

	if ( docRef === "" ) {
		return;
	}

	return new Promise(async resolve => {
		await getDoc(query)
		.then(async (doc) => {
			if ( doc.exists() ) {
				const res = {
					status: 200,
					data: doc.data(),
					message: "Document found!"
				}
				resolve(res);
			} 
			else {
				const res = {
					status: 400,
					data: [],
					message: "No such document!"
				}
				await userDeletion(user);
				navigate("/sign-up");
				resolve(res);
			}
		})
		.catch((error) => {
			const res = {
				status: 400,
				data: [],
				message: `Error getting document: ${error}`,
				code: error.code
			}
			resolve(res);
		});
	});
};

export const getSubDocument = async (colRef, docRef, subColRef, subDocRef) => {
	const db = getFirestore();
	const query = doc(db, colRef, docRef, subColRef, subDocRef);

	return new Promise(async resolve => {
		await getDoc(query)
		.then((doc) => {
			if ( doc.exists() ) {
				const res = {
					status: 200,
					data: doc.data(),
					message: "Document found!"
				}
				resolve(res);
			}
			else {
				const res = {
					status: 400,
					data: [],
					message: "No such document!"
				}
				resolve(res);
			}
		})
		.catch((error) => {
			const res = {
				status: 400,
				data: [],
				error: error,
				code: error.code
			}
			resolve(res);
		});
	});
}

export const getAllDocuments = async(colRef) => {
	const db = getFirestore();
	const array = [];
	const ids = [];

	return new Promise(async resolve => {
		await getDocs(collection(db, colRef))
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				array.push(data.data);
				ids.push(doc.id);
			});
			const res = {
				status: 200,
				data: array,
				ids: ids,
				message: "Document found!"
			}
			resolve(res);
		})
		.catch((error) => {
			const res = {
				status: 400,
				data: [],
				ids: [],
				message: error,
				code: error.code
			}
			resolve(res);
		});
	});
}

export const getAllSubDocuments = async(colRef, docRef, subColRef) => {
	const array = [];
	const docNames = [];
	const db = getFirestore();

	return new Promise(async resolve => {
		await getDocs(collection(db, colRef, docRef, subColRef))
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				const subData = data.data;
				array.push(subData);
				docNames.push(doc.id);
			});
			const res = {
				status: 200,
				data: array,
				docNames: docNames,
				message: "Document found!"
			}
			resolve(res);
		})
		.catch((error) => {
			const res = {
				status: 400,
				data: [],
				docNames: [],
				message: `Error getting documents: ${error}`,
				code: error.code
			}
			resolve(res);
		});
	});
}

export const getAllDocumentNames = async (colRef) => {
	const db = getFirestore();
	const query = await getDocs(collection(db, colRef));
	const array = [];
	query.forEach((doc) => {
		const newObject = doc.id;
		array.push(newObject);
	});
	const res = {
		status: 200,
		data: array,
		message: "Document found!"
	}
	return res;
}

export const getWhereDoc = async(col, field, operator, val) => {
	const db = getFirestore();
	const userRef = collection(db, col);
	const whereQuery = await query(userRef, where(field, operator, val));
	const queryDoc = await getDocs(whereQuery);
	
	const response = [];
	queryDoc.forEach((doc) => {
		const data = doc.data();
		response.push(data.data);
	});

	const res = {
		status: response.length === 0 ? 400 : 200,
		data: response
	};
	
	return res;
};

export const getDoubleWhereDoc = async(col, field, operator, val) => {
	const db = getFirestore();
	const userRef = collection(db, col);
	const whereQuery = await query(userRef, where(field[0], operator[0], val[0]), where(field[1], operator[1], val[1]));
	const queryDoc = await getDocs(whereQuery);
	
	const response = [];
	queryDoc.forEach((doc) => {
		const data = doc.data();
		response.push(data.data);
	});
	
	return response;
};

export const getTripleWhereDoc = async(col, field, operator, val) => {
	const db = getFirestore();
	const userRef = collection(db, col);
	const whereQuery = await query(userRef, where(field[0], operator[0], val[0]), where(field[1], operator[1], val[1]), where(field[2], operator[2], val[2]));
	const queryDoc = await getDocs(whereQuery);
	
	const response = [];
	queryDoc.forEach((doc) => {
		const data = doc.data();
		response.push(data.data);
	});
	
	return response;
};

export const deleteDocument = async(colRef, docRef) => {
	const db = getFirestore();
	await deleteDoc(doc(db, colRef, docRef));
}

export const queryAllSubscriptions = async() => {
	const url = "https://getallsubscriptions-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: {
					"email": "all"
				}
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const geocode = async(data) => {

	return new Promise(resolve => {
		fromAddress(data)
		.then((response) => {
			const values = response.results[0].geometry.location;
			const array = [values.lat, values.lng];
			const success = {
				status: 200,
				data: array
			}
			resolve(success);
		},
		(error) => {
			const failure = {
				status: 400,
				error: error
			}
			resolve(failure);
		});
	})
}

export const openPropertySearch = (item, setCardClick) => {
	if ( setCardClick !== null ) {
		setCardClick(true);
	}
	const address = item.description;
	const encodedAddress = encodeURIComponent(address);
	window.open(`/quick-report/${encodedAddress}`, "_blank");

	if ( setCardClick !== null ) {
		setTimeout(() => {
			setCardClick(false);
		}, 500);
	}
};

// This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export const calcCrow = (originalProperty, property) => {
	const R = 6371; // km
	const dLat = toRad(property.latitude - originalProperty.latitude);
	const dLon = toRad(property.longitude - originalProperty.longitude);
	const lat1 = toRad(originalProperty.latitude);
	const lat2 = toRad(property.latitude);

	const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
	const d = R * c;
	return d;
}

 // Converts numeric degrees to radians
const toRad = (Value) => {
	return Value * Math.PI / 180;
}

export const propertiesReadyEmail = async(data) => {
	const senderEmail = "info@coffeeclozers.com"
	const dynamicTemplate = "d-3b638321bcc0486baa02bd232d76c0e5";

	const msg = {
		to: data.contactEmail,
		from: senderEmail,
		templateId: dynamicTemplate,
		dynamic_template_data: {
			data: {
				firstName: data.firstName,
				market: data.market
			}
		}
	};

	const send = await sendEmail(msg);
	return send;
}

export const sendEmail = async(data) => {
	const url = "https://sendemailv2-oyonrz73aa-uc.a.run.app";

	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				message: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
}

export const calculateMortgagePayment = async(housePrice, downPaymentCalc, interestRateCalc, loanType) => {
	const downPayment = downPaymentCalc === 0 ? 0 : downPaymentCalc / 100;
	const interestRate = interestRateCalc === 0 ? 0 : interestRateCalc / 100;

	if ( downPayment === 1 ) {
		return 0;
	}
	else if ( interestRate === 0 ) {
		const loanAmount = housePrice - (housePrice * downPayment);
		const monthlyMortgagePayment = loanAmount / (loanType * 12);
		return monthlyMortgagePayment;
	}

	const monthlyInterestRate = interestRate === 0 ? 0 : (interestRate / 12);
	const principal = housePrice - (housePrice * downPayment);
	const monthlyPayments = loanType * 12;
	const powerEquation = Math.pow((1 + monthlyInterestRate), monthlyPayments);
	const numerator = monthlyInterestRate * powerEquation;
	const denominator = powerEquation - 1;
	const division = numerator / denominator;
	const monthlyMortgagePayment = principal * division;
	return monthlyMortgagePayment;
}

export const calculateSubToMortgagePayment = async(housePrice, downPaymentCalc, interestRateCalc, ownerEquity, loanType) => {
	const downPayment = downPaymentCalc === 0 ? 0 : downPaymentCalc / 100;
	const interestRate = interestRateCalc === 0 ? 0 : interestRateCalc / 100;
	const ownerEquityDollar = housePrice * ownerEquity;

	if ( downPayment === 1 ) {
		return 0;
	}
	else if ( interestRate === 0 ) {
		const loanAmount = ownerEquityDollar - (housePrice * downPayment);
		const monthlyMortgagePayment = loanAmount / (loanType * 12);
		return monthlyMortgagePayment;
	}
	
	const monthlyInterestRate = interestRate === 0 ? 0 : (interestRate / 12);
	const principal = ownerEquityDollar - (housePrice * downPayment);
	const monthlyPayments = loanType * 12;
	const powerEquation = Math.pow((1 + monthlyInterestRate), monthlyPayments);
	const numerator = monthlyInterestRate * powerEquation;
	const denominator = powerEquation - 1;
	const division = numerator / denominator;
	const monthlyMortgagePayment = principal * division;

	if ( monthlyMortgagePayment < 0 ) {
		return 0;
	}
	else {
		return monthlyMortgagePayment;
	}
}

export const calculateInterestPaid = async(housePrice, downPaymentCalc, interestRateCalc, loanType, loanDuration) => {
	const downPayment = downPaymentCalc === 0 ? 0 : downPaymentCalc / 100;
	const interestRate = interestRateCalc === 0 ? 0 : interestRateCalc / 100;

	if ( downPayment === 1 || interestRate === 0 ) {
		return 0;
	}

	const monthlyInterestRate = interestRate === 0 ? 0 : (interestRate / 12);
	const principal = housePrice - (housePrice * downPayment);
	const monthlyPayments = loanType * 12;
	const loanDurationMonths = loanDuration * 12;

	if ( loanDurationMonths === 0 || loanType === 0 ) {
		return 0;
	}
	
	const getInterest = CUMIPMT(monthlyInterestRate, monthlyPayments, principal, 1, loanDurationMonths, 0);
	return getInterest * -1;
};

export const calculateCAPRate = async(monthlyProfit, price) => {
	const yearlyProfit = monthlyProfit * 12;
	const capRate = (yearlyProfit / price) * 100;
	return capRate;
}

export const calculateCashOnCash = async(monthlyProfit, initialCosts) => {
	const yearlyProfit = monthlyProfit * 12;
	const cashOnCash = (yearlyProfit / initialCosts) * 100;
	return cashOnCash;
}

export const calculateARVSpread = (price, rehab, arv, holdingCosts) => {
	const totalCosts = price + rehab + holdingCosts;
	const arvSpread = (arv - totalCosts) / totalCosts;
	return arvSpread;
};

export const formatHighLevelProfits = async(vals, exitStrategy, highLevelProfits, setHighLevelProfits) => {

	if ( exitStrategy === "ltr" || exitStrategy === "hh" || exitStrategy === "str" ) {
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab;
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const yearlyProfit = monthlyProfit * 12;
		const newCashOnCash = (yearlyProfit / newInitialCosts) * 100;
		const totalCosts = vals.price + vals.rehab + 0;
		const newArvSpread = (vals.arv - totalCosts) / totalCosts;
		const expensesCovered = (vals.rent / vals.monthlyCosts);
		const arvProfit = vals.arv - vals.price - vals.rehab;

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: expensesCovered,
			cashInDeal: null,
			monthlyCosts: vals.monthlyCosts,
			leaseOptionProfit: 0,
            leaseOptionCashOnCash: 0,
            leaseOptionTotalCosts: 0,
            strProfit: 0,
            strCashOnCash: 0
		};

		if (JSON.stringify(highLevelProfits) !== JSON.stringify(profitObject)) {
			setHighLevelProfits(profitObject);
		}
	}
	else if ( exitStrategy === "lo" ) {
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab;
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const yearlyProfit = monthlyProfit * 12;
		const leaseOptionInitialCosts = newInitialCosts - vals.tenantBuyerDownPayment;
		const newCashOnCash = (yearlyProfit / leaseOptionInitialCosts) * 100;
		
		const totalCosts = vals.price + vals.rehab + 0;
		const newArvSpread = (vals.arv - totalCosts) / totalCosts;
		const expensesCovered = (vals.rent / vals.monthlyCosts);
		const arvProfit = vals.arv - vals.price - vals.rehab;

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: expensesCovered,
			cashInDeal: null,
			monthlyCosts: vals.monthlyCosts,
			leaseOptionProfit: monthlyProfit,
            leaseOptionCashOnCash: newCashOnCash / 100,
            leaseOptionTotalCosts: leaseOptionInitialCosts,
            strProfit: 0,
            strCashOnCash: 0
		};

		if (JSON.stringify(highLevelProfits) !== JSON.stringify(profitObject)) {
			setHighLevelProfits(profitObject);
		}
	}
	else if ( exitStrategy === "brrrr" ) {
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab + vals.holdingCosts;
		const newCashInDeal = newInitialCosts - vals.arvFinancing;
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const yearlyProfit = monthlyProfit * 12;
		const newCashOnCash = (yearlyProfit / newCashInDeal) * 100;
		const totalCosts = vals.price + vals.rehab + vals.holdingCosts;
		const newArvSpread = (vals.arv - totalCosts) / totalCosts;
		const expensesCovered = (vals.rent / vals.monthlyCosts);
		const arvProfit = vals.arv - vals.price - vals.rehab;

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: expensesCovered,
			cashInDeal: newCashInDeal,
			monthlyCosts: vals.monthlyCosts,
			leaseOptionProfit: 0,
            leaseOptionCashOnCash: 0,
            leaseOptionTotalCosts: 0,
            strProfit: 0,
            strCashOnCash: 0
		};
		
		if (JSON.stringify(highLevelProfits) !== JSON.stringify(profitObject)) {
			setHighLevelProfits(profitObject);
		}
	}
};

export const toggleFavourite = async(favourites, zpid, setFavourites, setFavouriteDisable, navigate, cityObject, setCardClick) => {
	setFavouriteDisable(true);
	if ( setCardClick !== null ) {
		setCardClick(true);
	}
	const userCheck = await checkUserId();
	if ( userCheck.status === 200 ) {
		const cityId = cityObject.cityId;
		const city = cityObject.city;
		const state = cityObject.state;

		if ( setCardClick !== null ) {
			setCardClick(true);
		}

		let favouritesDoc = false;
		const getFavouritesDoc = await getDocument("Favourites", userCheck.userId);
		if ( getFavouritesDoc.status === 200 ) {
			favourites = getFavouritesDoc.data.data;
			favouritesDoc = true;
		}

		const colRef = "Favourites"; 
		const docRef = userCheck.userId;
		const zpidIndex = favourites.findIndex(e => e.zpid === zpid);
		if ( zpidIndex === -1 ) {
			const newObject = {
				zpid: zpid,
				cityId: cityId,
				city: city,
				state: state,
				date: new Date(),
				note: null,
				offerSent: false
			};
			favourites.push(newObject);

			recordEvent("Add Favourites", {
				zpid: zpid
			});
		}
		else {
			favourites.splice(zpidIndex, 1);
			recordEvent("Remove Favourites", {
				zpid: zpid
			});
		}

		const transaction = favouritesDoc === true ? await setTransaction(colRef, docRef, null, favourites, null) : await setData(colRef, docRef, favourites);
		if ( transaction.status === 200 ) {
			setFavourites(favourites);
			setFavouriteDisable(false);
		}
		else {
			setFavouriteDisable(false);
		}

		setTimeout(() => {
			if ( setCardClick !== null ) {
				setCardClick(false);
			}
		}, 500)
	}
	else {
		if ( setCardClick !== null ) {
			setCardClick(false);
		}
		navigate('/sign-up', {
			state: {
				path: window.location.href
			}
		});
	}
}

export const removeMultiFavourites = async(favourites, setFavourites, setFavouriteDisable, navigate, amendedProperties) => {
	const userCheck = await checkUserId();
	if ( userCheck.status === 200 ) {
		const colRef = "Favourites"; 
		const docRef = userCheck.userId;
		const root = null;

		const transaction = await setTransaction(colRef, docRef, root, favourites, null);
		if ( transaction.status === 200 ) {
			setFavourites(amendedProperties);
			setFavouriteDisable(false);
		}
		else {
			setFavouriteDisable(false);
		}
	}
	else {
		navigate('/sign-up', {
			state: {
				path: window.location.href
			}
		});
	}
}

export const timer = ms => new Promise(res => setTimeout(res, ms));

export const checkStripeSubscription = async(data) => {
	const url = "https://retrievestripesubscriptionv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const cancelStripeSubscription = async(data) => {
	const url = "https://cancelstripesubscriptionv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const placesAutocompleteSearch = async(data) => {
	const url = "https://placesautocompletesearchv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const checkFinancialsInteger = (val) => {
	if ( Number.isInteger(val) === true ) {
		return val.toFixed(0);
	}
	else {
		return val.toFixed(2);
	}
}

export const uploadImage = async(file, code, setLoading) => {
	const storage = getStorage();
	const storageRef = ref(storage, code);
	const response = await fetch(file);
	const blob = await response.blob();

	const uploadTask = uploadBytesResumable(storageRef, blob);

	return new Promise(resolve => {
		uploadTask.on('state_changed', 
			(snapshot) => {
				// Observe state change events such as progress, pause, and resume
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setLoading(`${progress.toFixed(0)}%`)
				console.log('Upload is ' + progress + '% done');
				switch (snapshot.state) {
				case 'paused':
					console.log('Upload is paused');
					break;
				case 'running':
					console.log('Upload is running');
					break;
				default:
					console.log("Upload default case");
				}
			}, 
			(error) => {
				console.log("Error = ", error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref)
				.then(async(downloadURL) => {
					setLoading("");
					resolve(downloadURL);
				});
			}
		);
	})
}

export const deleteImage = async(code) => {
	const storage = getStorage();
	const storageRef = ref(storage, code);
	
	return new Promise(resolve => {
		deleteObject(storageRef)
		.then(() => {
			const success = {
				status: 200
			};
			resolve(success);
		})
		.catch((error) => {
			const failure = {
				status: 400,
				error: error
			};
			resolve(failure);
		});
	})
}

export const getIp = async() => {
    // Connect ipapi.co with fetch()
	const origin = window.location.origin;

	if ( origin.includes("localhost") ) {
		const errorRes = {
			error: true
		}
		return errorRes;
	}
	else {
		const response = await fetch('https://ipapi.co/json/')
		const data = await response.json()
		// Set the IP address to the constant `ip`
		return data;
	}
}

export const getUserDocuments = async(colRefs, userId) => {
	const array = [];
	for (let index = 0; index < colRefs.length; index++) {
		const colRef = colRefs[index];
		const queryData = await getDocument(colRef, userId);
		if ( queryData.status === 200 ) {
			const newObject = {
				colRef: colRef,
				data: queryData.data.data,
				status: 200
			}
			array.push(newObject);
		}
		else {
			const newObject = {
				colRef: colRef,
				data: null,
				status: 400,
				message: queryData.message,
				code: queryData.code
			};
			array.push(newObject);
		}
	}
	return array;
};

export const serialize = (obj) => {
	let str = [];
	for (let p in obj)
	  if (obj.hasOwnProperty(p)) {
		str.push("'" + encodeURIComponent(p) + "':+" + encodeURIComponent(obj[p]));
	  }
	return str.join(",");
}

export const formatFilters = (label, filters) => {
	const filterString = JSON.stringify(filters)
	//   .replace(/"([^"]+)":/g, '$1:')
	//   .replace(/"/g, "'");
  
	return `${label}=${filterString}`;
};

export const filtersEncoder = (filters) => {
	const filterObject = {

	};
	for (let index = 0; index < filters.length; index++) {
		const element = filters[index];
		if ( element.label === "propertyType" ) {
			const propertyTypes = element.value.propertyTypes;
			const multiFam = propertyTypes.includes("MFH");
			const singleFam = propertyTypes.includes("SFH");
			const finalPropertyTypes = multiFam === true && singleFam === true ? ["SingleFamily", "MultiFamily"] : multiFam === true && singleFam === false ? ["MultiFamily"] : multiFam === false && singleFam === true ? ["SingleFamily"] : ["SingleFamily", "MultiFamily"];
			filterObject.propertyTypes = finalPropertyTypes;
			if ( element.value.units !== 0 && multiFam === true ) {
				filterObject.unitsMin = element.value.units;
			}
			if ( element.value.match === true ) {
				filterObject.unitsMax = element.value.units;
			}
		}
		else if ( element.label === "price" ) {
			filterObject.priceMin = Number(element.value[0]);
			filterObject.priceMax = Number(element.value[1]);
		}
		else if ( element.label === "beds" ) {
			filterObject.bedroomsMin = element.value;
			if ( element.match === true ) {
				filterObject.bedroomsMax = element.value;
			}
		}
		else if ( element.label === "baths" ) {
			filterObject.bathroomsMin = element.value;
			if ( element.match === true ) {
				filterObject.bathroomsMax = element.value;
			}
		}
		else if ( element.label === "sqft" ) {
			filterObject.sqftMin = element.value[0];
			filterObject.sqftMax = element.value[1];
		}
		else if ( element.label === "construction" ) {
			filterObject.constructionYearsMin = Number(element.value[0]);
			filterObject.constructionYearsMax = Number(element.value[1]);
		}
		else if ( element.label === "condition" ) {
			const conditionKeys = [
				{
					code: "recentRemodel",
					abbreviation: "rr"
				},
				{
					code: "wellMaintain",
					abbreviation: "wm"
				},
				{
					code: "modRehab",
					abbreviation: "mr"
				},
				{
					code: "gutRehab",
					abbreviation: "gr"
				}
			];
			const newArray = [];
			for (let index = 0; index < element.value.length; index++) {
				const value = element.value[index];
				const conditionIndex = conditionKeys.findIndex(e => e.abbreviation === value);
				if ( conditionIndex !== -1 ) {
					newArray.push(conditionKeys[conditionIndex].code);
				}
			};
			filterObject.propertyConditionTypes = newArray;
		}
		else if ( element.label === "creative" ) {
			if ( element.value === "sf" ) {
				filterObject.sellerFinancingFlag = 1;
			}
			else if ( element.value === "sub" ) {
				filterObject.subtoFlag = 1;
			}
		}
		else if ( element.label === "listingType" ) {
			if ( element.value === "agent" ) {
				filterObject.fsboFlag = 0;
			}
			else if ( element.value === "fsbo" ) {
				filterObject.fsboFlag = 1;
			}
		}
		else if ( element.label === "daysOnMarket" ) {
			if ( element.value === 0 ) {
				filterObject.daysOnMarketMin = 0;
				filterObject.daysOnMarketMax = 30;
			}
			else {
				filterObject.daysOnMarketMin = element.value;
			}
		}
		else if ( element.label === "backOnMarket" ) {
			filterObject.backOnMarketFlag = element.value === true ? 1 : 0;
		}
		else if ( element.label === "priceReduction" ) {
			if ( element.value === true ) {
				filterObject.priceReductionRecentFlag = 1;
			}
		}
		else if ( element.label === "neighbourhoodGrades" ) {
			filterObject.neighborhoodGradeMin = element.value[0];
			filterObject.neighborhoodGradeMax = element.value[1];
		}
		else if ( element.label === "zipcodes" ) {
			filterObject.zipcodes = element.value;
		}
		else if ( element.label === "floodzone" && element.value === true ) {
			filterObject.hideFloodZoneFlag = 1;
		}
		else if ( element.label === "hoa" && element.value === true ) {
			filterObject.hideHoaFlag = 1;
		}
		else if ( element.label === "adu" && element.value === true )  {
			filterObject.aduFlag = 1;
		}
		else if ( element.label === "extraBedroom" && element.value === true ) {
			filterObject.addBedroomFlag = 1;
		}
		else if ( element.label === "oversizedLot" && element.value === true ) {
			filterObject.largeLotFlag = 1;
		}
		else if ( element.label === "ownerType" ) {
			const ownerTypeVal = element.value === "i" ? "Individual" : element.value === "c" ? "Corporate" : null;
			if ( ownerTypeVal !== null ) {
				filterObject.ownerTypes = ownerTypeVal;
			}
		}
		else if ( element.label === "ownershipLength" ) {
			if ( element.value === 0 ) {
			}
			else if ( element.value === 5 ) {
				filterObject.ownerLengthYearMin = 0;
				filterObject.ownerLengthYearMax = 5;
			}
			else {
				filterObject.ownerLengthYearMin = element.value;
			}
		}
		else if ( element.label === "distress" ) {
			const distressKeys = [
				{
					label: "Absentee",
					value: "ab",
					flag: "absenteeOwnerFlag"
				},
				{
					label: "In-state absentee",
					value: "ia",
					flag: "inStateAbsenteeOwnerFlag"
				},
				{
					label: "Death",
					value: "dt",
					flag: "deathFlag"
				},
				{
					label: "Inherited",
					value: "in",
					flag: "inheritedFlag"
				},
				{
					label: "Pre-foreclosure",
					value: "pf",
					flag: "preForeclosureFlag"
				},
				{
					label: "Spousal death",
					value: "sd",
					flag: "spousalDeathFlag"
				},
				{
					label: "Vacant",
					value: "va",
					flag: "vacantFlag"
				}
			];
			const distressIndex = distressKeys.findIndex(e => e.value === element.value);
			if ( distressIndex !== -1 ) {
				filterObject[distressKeys[distressIndex].flag] = 1;
			}
		}
		else if ( element.label === "interest" ) {
			filterObject.estimatedMortgageInterestRateMax = element.value;
		}
		else if ( element.label === "equity" ) {
			if ( element.value === 20 ) {
				filterObject.estimatedMortgageEquityLevelMin = 0;
				filterObject.estimatedMortgageEquityLevelMax = 20;
			}
			else if ( element.value === 80 ) {
				filterObject.estimatedMortgageEquityLevelMin = 80;
			}
		}
		else if ( element.label === "mortgageType" ) {
			filterObject.loanCodeTypes = element.value;
		}
		else if ( element.label === "cocr" ) {
			filterObject.cashOnCashReturnMin = Number(element.value);
		}
	}

	return filterObject;
};

export const financialsEncoder = (financials) => {
	const financialsObject = {

	};

	if ( financials.insuranceRate !== undefined && financials.insuranceRate !== "None" ) {
		financialsObject.insuranceRate = financials.insuranceRate;
	}
	if ( financials.propertyTaxesRate !== undefined && financials.propertyTaxesRate !== "None" ) {
		financialsObject.propertyTaxesRate = financials.propertyTaxesRate;
	}
	if ( financials.vacancyRate !== undefined && financials.vacancyRate !== "None" ) {
		financialsObject.vacancyRate = financials.vacancyRate;
	}
	if ( financials.managementFeeRate !== undefined && financials.managementFeeRate !== "None" ) {
		financialsObject.managementFeeRate = financials.managementFeeRate;
	}
	if ( financials.maintenanceRate !== undefined && financials.maintenanceRate !== "None" ) {
		financialsObject.maintenanceRate = financials.maintenanceRate;
	}
	if ( financials.otherCostsRate !== undefined && financials.otherCostsRate !== "None" ) {
		financialsObject.otherCostsRate = financials.otherCostsRate;
	}
	if ( financials.mortgageFlag !== undefined && financials.mortgageFlag === 1 ) {
		financialsObject.mortgageFlag = financials.mortgageFlag;
	}
	if ( financials.downPaymentRate !== undefined && financials.downPaymentRate !== 0 ) {
		financialsObject.downPaymentRate = financials.downPaymentRate;
	}
	if ( financials.interestRate !== undefined && financials.interestRate !== "None" ) {
		financialsObject.interestRate = financials.interestRate;
	}
	if ( financials.loanType !== undefined && financials.loanType !== 0 ) {
		financialsObject.loanType = financials.loanType;
	}
	return financialsObject;
};

export const getBuyBoxFilterParams = (filters, item, onlyFilters) => {
	const newFilterArray = [];
	
	// Basics
	if ( filters.budgetRange !== undefined ) {
		const newObject = {
			label: "price",
			value: filters.budgetRange,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.propertyTypes !== undefined ) {
		const commaSeparatedString = filters.propertyTypes;
		const newPropertyTypeObject = {
			propertyTypes: commaSeparatedString
		}

		if ( commaSeparatedString.includes("MFH") ) {
			newPropertyTypeObject.units = filters.units !== undefined ? filters.units : 0;
			newPropertyTypeObject.match = filters.unitExactMatch !== undefined ? filters.unitExactMatch : false;
		}
		const newObject = {
			label: "propertyType",
			value: newPropertyTypeObject,
			type: "basics"
		}
		newFilterArray.push(newObject);
	}
	if ( filters.bedrooms !== undefined ) {
		const newObject = {
			label: "beds",
			value: filters.bedrooms,
			match: filters.bedroomExactMatch !== undefined ? filters.bedroomExactMatch : false,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.bathrooms !== undefined ) {
		const newObject = {
			label: "baths",
			value: filters.bathrooms,
			match: filters.bathroomExactMatch !== undefined ? filters.bathroomExactMatch : false,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.squareFootage !== undefined ) {
		const newObject = {
			label: "sqft",
			value: filters.squareFootage,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.constructionYears !== undefined ) {
		const newObject = {
			label: "construction",
			value: filters.constructionYears,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}

	// Condition
	if ( filters.condition !== undefined ) {
		const newObject = {
			label: "condition",
			value: filters.condition,
			type: "condition"
		};
		newFilterArray.push(newObject);
	}

	// Creative finance
	if ( item.acquisitionStrategy === "sf" || item.acquisitionStrategy === "sub" ) {
		const newObject = {
			label: "creative",
			value: item.acquisitionStrategy,
			type: "creative"
		};
		newFilterArray.push(newObject);
	}

	// Listing
	if ( filters.listingType !== undefined ) {
		const newObject = {
			label: "listingType",
			value: filters.listingType,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.daysOnMarket !== undefined ) {
		const newObject = {
			label: "listingType",
			value: filters.daysOnMarket,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.backOnMarket !== undefined ) {
		const newObject = {
			label: "backOnMarket",
			value: filters.backOnMarket,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.recentPriceReduction !== undefined ) {
		const newObject = {
			label: "priceReduction",
			value: filters.recentPriceReduction,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}


	// Location
	if ( filters.neighbourhoodGrades !== undefined ) {
		const newObject = {
			label: "neighbourhoodGrades",
			value: filters.neighbourhoodGrades,
			type: "location"
		};
		newFilterArray.push(newObject);
	}
	if ( item.zipcodes.length > 0 ) {
		const newObject = {
			label: "zipcodes",
			value: item.zipcodes,
			type: "location"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.hideFloodzone !== undefined ) {
		const newObject = {
			label: "floodzone",
			value: filters.hideFloodzone,
			type: "location"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.hideHOA !== undefined ) {
		const newObject = {
			label: "hoa",
			value: filters.hideHOA,
			type: "location"
		};
		newFilterArray.push(newObject);
	}

	// Opportunities
	if ( filters.onlyADU !== undefined ) {
		const newObject = {
			label: "adu",
			value: filters.onlyADU,
			type: "opportunities"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.onlyExtraBedroom !== undefined ) {
		const newObject = {
			label: "extraBedroom",
			value: filters.onlyExtraBedroom,
			type: "opportunities"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.oversizedLot !== undefined ) {
		const newObject = {
			label: "oversizedLot",
			value: filters.oversizedLot,
			type: "opportunities"
		};
		newFilterArray.push(newObject);
	}

	// Ownership
	if ( filters.ownerType !== undefined ) {
		const newObject = {
			label: "ownerType",
			value: filters.ownerType,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.ownershipLength !== undefined ) {
		const newObject = {
			label: "ownershipLength",
			value: filters.ownershipLength,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.distressFlags !== undefined ) {
		const newObject = {
			label: "distress",
			value: filters.distressFlags,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.estimatedInterest !== undefined ) {
		const newObject = {
			label: "interest",
			value: filters.estimatedInterest,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}
	if ( filters.equityLevel !== undefined ) {
		const newObject = {
			label: "equity",
			value: filters.equityLevel,
			type: "ownership"
		}
		newFilterArray.push(newObject);
	}
	if ( filters.mortgageType !== undefined ) {
		const newObject = {
			label: "mortgageType",
			value: filters.mortgageType,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}

	// ROI
	if ( filters.minCoCR !== undefined ) {
		const newObject = {
			label: "cocr",
			value: filters.minCoCR,
			type: "roi"
		};
		newFilterArray.push(newObject);
	}
	
	if ( onlyFilters === true ) {
		return newFilterArray;
	}

	const params = JSON.stringify(newFilterArray);
	const creativeParam = item.acquisitionStrategy === "sf" || item.acquisitionStrategy === "sub" ? `&creative=true` : "";
	const strategyVals = [
		"ltr",
		"hh",
		"brrrr",
		"lo"
	];
	const exitStrategyIndex = strategyVals.indexOf(item.exitStrategy);
	const exitStrategyParam = `&strategy=${exitStrategyIndex}`;
	const finalParams = `?filters=${params}${creativeParam}${exitStrategyParam}`;
	return finalParams;
};

export const getAPIGatewayListings = async(userSettings, creativeStrategy, cityId, filters) => {
	const initialCosts = userSettings.initialCosts;
	const recurringCosts = userSettings.recurringCosts;
	let insuranceRate = recurringCosts[0].default === true ? "None" : (Number(recurringCosts[0].value) / 100);
	let propertyTaxes = recurringCosts[1].default === true ? "None" : (Number(recurringCosts[1].value) / 100);
	let vacancyRate = recurringCosts[2].default === true ? "None" : (Number(recurringCosts[2].value) / 100);
	let managementFee = recurringCosts[3].default === true ? "None" : (Number(recurringCosts[3].value) / 100);
	let maintenanceRate = recurringCosts[4].default === true ? "None" : (Number(recurringCosts[4].value) / 100);
	let otherCostsRate = recurringCosts[5].default === true ? "None" : (Number(recurringCosts[5].value) / 100);
	const downPayment = initialCosts[0].value === "" ? 0.2 : Number(initialCosts[0].value) / 100;
	const interestRate = initialCosts[1].default === true ? "None" : (Number(initialCosts[1].value) / 100);
	const mortgage = userSettings.mortgage === true ? 1 : 0;
	const loanType = userSettings.loanType;

	const customCities = userSettings.customCities;
	const customCityCheck = -1;
	if ( customCityCheck !== -1 ) {
		const costOptions = [
			{
				value: "insurance"
			},
			{
				value: "property-taxes"
			},
			{
				value: "vacancy-rate"
			},
			{
				value: "management-fee"
			},
			{
				value: "maintenance-fee"
			},
			{
				value: "other"
			}
		];
		const citysettings = customCities[customCityCheck].settings;
		for (let index = 0; index < citysettings.length; index++) {
			const element = citysettings[index];
			const costIndex = costOptions.findIndex(e => e.value === element.type);
			if ( costIndex !== -1 ) {
				const costVal = element.value;
				if ( costIndex === 0 ) {
					insuranceRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 1 ) {
					propertyTaxes = (Number(costVal) / 100);
				}
				else if ( costIndex === 2 ) {
					vacancyRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 3 ) {
					managementFee = (Number(costVal) / 100);
				}
				else if ( costIndex === 4 ) {
					maintenanceRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 5 ) {
					otherCostsRate = (Number(costVal) / 100);
				}
			}
		}
	}

	const rawFinancials = {
		insuranceRate: insuranceRate,
		propertyTaxesRate: propertyTaxes,
		vacancyRate: vacancyRate,
		managementFeeRate: managementFee,
		maintenanceRate: maintenanceRate,
		otherCostsRate: otherCostsRate,
		mortgageFlag: mortgage,
		downPaymentRate: downPayment,
		interestRate: interestRate,
		loanType: loanType
	};
	const newFinancials = await financialsEncoder(rawFinancials);

	const body = {
		cityId: cityId,
		financials: newFinancials
	};
	const financialsString = body.financials !== undefined && body.financials !== null ? `&${formatFilters("financials", body.financials)}` : null;
	const filtersString = filters === null ? null : `&${formatFilters("filters", filters)}`;

	const pathWithQueryString = `listings?cityId=${body.cityId}${financialsString === null ? "" : financialsString}${filtersString === null ? "" : filtersString}`;
	return pathWithQueryString;
};

export const getAPIGatewayListingsV4 = async(userSettings, creativeStrategy, cityId, filters, page, strategy, sorting, centre, allProps) => {
	const initialCosts = userSettings.initialCosts;
	const recurringCosts = userSettings.recurringCosts;
	let insuranceRate = recurringCosts[0].default === true ? "None" : (Number(recurringCosts[0].value) / 100);
	let propertyTaxes = recurringCosts[1].default === true ? "None" : (Number(recurringCosts[1].value) / 100);
	let vacancyRate = recurringCosts[2].default === true ? "None" : (Number(recurringCosts[2].value) / 100);
	let managementFee = recurringCosts[3].default === true ? "None" : (Number(recurringCosts[3].value) / 100);
	let maintenanceRate = recurringCosts[4].default === true ? "None" : (Number(recurringCosts[4].value) / 100);
	let otherCostsRate = recurringCosts[5].default === true ? "None" : (Number(recurringCosts[5].value) / 100);
	const downPayment = initialCosts[0].value === "" ? 0.2 : Number(initialCosts[0].value) / 100;
	const interestRate = initialCosts[1].default === true ? "None" : (Number(initialCosts[1].value) / 100);
	const mortgage = userSettings.mortgage === true ? 1 : 0;
	const loanType = userSettings.loanType;

	const customCities = userSettings.customCities;
	const customCityCheck = -1;
	if ( customCityCheck !== -1 ) {
		const costOptions = [
			{
				value: "insurance"
			},
			{
				value: "property-taxes"
			},
			{
				value: "vacancy-rate"
			},
			{
				value: "management-fee"
			},
			{
				value: "maintenance-fee"
			},
			{
				value: "other"
			}
		];
		const citysettings = customCities[customCityCheck].settings;
		for (let index = 0; index < citysettings.length; index++) {
			const element = citysettings[index];
			const costIndex = costOptions.findIndex(e => e.value === element.type);
			if ( costIndex !== -1 ) {
				const costVal = element.value;
				if ( costIndex === 0 ) {
					insuranceRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 1 ) {
					propertyTaxes = (Number(costVal) / 100);
				}
				else if ( costIndex === 2 ) {
					vacancyRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 3 ) {
					managementFee = (Number(costVal) / 100);
				}
				else if ( costIndex === 4 ) {
					maintenanceRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 5 ) {
					otherCostsRate = (Number(costVal) / 100);
				}
			}
		}
	}

	const rawFinancials = {
		insuranceRate: insuranceRate,
		propertyTaxesRate: propertyTaxes,
		vacancyRate: vacancyRate,
		managementFeeRate: managementFee,
		maintenanceRate: maintenanceRate,
		otherCostsRate: otherCostsRate,
		mortgageFlag: mortgage,
		downPaymentRate: downPayment,
		interestRate: interestRate,
		loanType: loanType
	};
	const newFinancials = await financialsEncoder(rawFinancials);

	const body = {
		cityId: cityId,
		financials: newFinancials
	};

	const zipcodeQueryArray = [];
	const zipcodes = filters === null || filters.zipcodes === undefined ? [] : filters.zipcodes;
	for (let index = 0; index < zipcodes.length; index++) {
		const element = zipcodes[index];
		const newObject = {
			regionId: element,
			regionType: "zipcode", 
			strategy: strategy,
			acquisition: creativeStrategy === true ? "creative" : "traditional"
		};
		zipcodeQueryArray.push(newObject);
	}

	const financialsString = body.financials !== undefined && body.financials !== null ? `&${formatFilters("financials", body.financials)}` : "";
	const filtersString = filters === null ? "" : `&${formatFilters("filters", filters)}`;
	const countyType = cityId.includes("CTY") ? true : false;
	const regionOnly = `${countyType === true && zipcodeQueryArray.length === 0 ? "" : "&regionOnly=1"}`;
	const pageParam = page === 1 ? "" : `&page=${page}`;
	const sortingParam = sorting === "" ? "" : `&sortOption=${sorting}`;
	const centreParam = centre === null ? "" : `&latitude=${centre.latitude}&longitude=${centre.longitude}`;
	const allPropertiesParam = allProps === true ? "&allPropertiesFlag=1" : "";

	const newObject = zipcodeQueryArray.length !== 0 ? zipcodeQueryArray : [{
		regionId: cityId.replace("CTY", ""),
		regionType: countyType === true ? "county" : "city", 
		strategy: strategy,
		acquisition: creativeStrategy === true ? "creative" : "traditional"
	}];

	const queryString = `${await formatFilters("regionIds", newObject)}${financialsString}${filtersString}${regionOnly}${pageParam}${sortingParam}${centreParam}${allPropertiesParam}`;
	const pathWithQueryString = `abstractListings?${queryString}`;
	return pathWithQueryString;
};

export const getAllProperties = async(params) => {
	return new Promise(async resolve => {
		const settings = params.settings;
		const creativeStrategy = params.creativeStrategy;
		const cityId = params.cityId;
		const filters = params.filters;
		const page = params.page;
		const strategy = params.strategy;
		const sorting = params.sorting;
		const centre = params.centre;

		const getGatewayQueryString = await getAPIGatewayListingsV4(settings, creativeStrategy, cityId, filters, page, strategy, sorting, centre, true);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "listingsV4",
			queryString: getGatewayQueryString
		};
		const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getGateway.status === 404 ) {
			const body = getGateway.body;
			const customS3Location = body.s3_location;
			const res = {
				status: 200,
				data: customS3Location
			};
			resolve(res);
			// const params = {
			// 	type: "s3",
			// 	s3Data: {
			// 		key: customS3Location,
			// 		bucketName: "residentialpropertydata"
			// 	}
			// };
			// const getS3Query = await cloudFunctionV2(gatewayURL, params);
			// if ( getS3Query.status === 200 ) {
			// 	const getS3Body = getS3Query.body;
			// 	if ( getS3Body.length !== 0 ) {
			// 		const data = getS3Body[0].data.props;
			// 		const res = {
			// 			status: 200,
			// 			data: data
			// 		};
			// 		resolve(res);
			// 	}
			// 	else {
			// 		const res = {
			// 			status: 400,
			// 			data: [],
			// 			error: "Empty body field"
			// 		};
			// 		resolve(res);
			// 	}
			// }
			// else {
			// 	const res = {
			// 		status: 400,
			// 		data: [],
			// 		error: "Error querying S3"
			// 	};
			// 	resolve(res);
			// }
		}
		else {
			const res = {
				status: 400,
				data: [],
				error: "Error querying gateway"
			};
			resolve(res);
		}
	});
}

export const getAPIGatewayListingsSimple = async(cityId) => {
	const body = {
		cityId: cityId
	};
	const queryString = `listings?cityId=${body.cityId}`;
	return queryString;
};

export const getCompsImages = (comps, type) => {
	const rentArray = [];
	const salesArray = [];
	for (let index = 0; index < comps.length; index++) {
		if ( type === "sales" ) {
			const element = comps[index];
			const fullAddress = `'${element.fullAddress.replaceAll(" ", "-")}'`;
			salesArray.push(fullAddress);
		}
		else if ( type === "rent" ) {
			const element = comps[index];
			const fullAddress = `${element.address.streetAddress}, ${element.address.city}, ${element.address.state}, ${element.address.zipcode}`;
			const formattedAddress = `'${fullAddress.replaceAll(" ", "-")}'`;
			rentArray.push(formattedAddress);
		}
	};
	const reducedSalesArray = salesArray.join(",");
	const reducedRentArray = rentArray.join(",");
	const queryString = `compPhotos?compIds={'rent':[${reducedRentArray}],'sales':[${reducedSalesArray}]}`;
	return queryString;
};

export const addComps = (zpid, address, type) => {
	const queryString = `compAdd?subjectPropertyId=${zpid}&compAddress=${address.description}&compType=${type}`;
	return queryString;
};

export const getAPIGatewayDetail = async(cityId, zpid, userSettings) => {
	const initialCosts = userSettings.initialCosts;
	const recurringCosts = userSettings.recurringCosts;
	let insuranceRate = recurringCosts[0].default === true ? "None" : (Number(recurringCosts[0].value) / 100);
	let propertyTaxes = recurringCosts[1].default === true ? "None" : (Number(recurringCosts[1].value) / 100);
	let vacancyRate = recurringCosts[2].default === true ? "None" : (Number(recurringCosts[2].value) / 100);
	let managementFee = recurringCosts[3].default === true ? "None" : (Number(recurringCosts[3].value) / 100);
	let maintenanceRate = recurringCosts[4].default === true ? "None" : (Number(recurringCosts[4].value) / 100);
	let otherCostsRate = recurringCosts[5].default === true ? "None" : (Number(recurringCosts[5].value) / 100);
	const downPayment = initialCosts[0].value === "" ? 0.2 : Number(initialCosts[0].value) / 100;
	const interestRate = initialCosts[1].default === true ? "None" : (Number(initialCosts[1].value) / 100);
	const mortgage = userSettings.mortgage === true ? 1 : 0;
	const loanType = userSettings.loanType;

	const customCities = userSettings.customCities;
	const customCityCheck = customCities.findIndex(e => e.msaCode === cityId);
	if ( customCityCheck !== -1 ) {
		const costOptions = [
			{
				value: "insurance"
			},
			{
				value: "property-taxes"
			},
			{
				value: "vacancy-rate"
			},
			{
				value: "management-fee"
			},
			{
				value: "maintenance-fee"
			},
			{
				value: "other"
			}
		];
		const citysettings = customCities[customCityCheck].settings;
		for (let index = 0; index < citysettings.length; index++) {
			const element = citysettings[index];
			const costIndex = costOptions.findIndex(e => e.value === element.type);
			if ( costIndex !== -1 ) {
				const costVal = element.value;
				if ( costIndex === 0 ) {
					insuranceRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 1 ) {
					propertyTaxes = (Number(costVal) / 100);
				}
				else if ( costIndex === 2 ) {
					vacancyRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 3 ) {
					managementFee = (Number(costVal) / 100);
				}
				else if ( costIndex === 4 ) {
					maintenanceRate = (Number(costVal) / 100);
				}
				else if ( costIndex === 5 ) {
					otherCostsRate = (Number(costVal) / 100);
				}
			}
		}
	}

	const body = {
		propertyId: zpid,
		financials: {
			insuranceRate: insuranceRate,
			propertyTaxesRate: propertyTaxes,
			vacancyRate: vacancyRate,
			managementFeeRate: managementFee,
			maintenanceRate: maintenanceRate,
			otherCostsRate: otherCostsRate,
			mortgageFlag: mortgage,
			downPaymentRate: downPayment,
			interestRate: interestRate,
			loanType: loanType
		}
	};
	const queryString = `propertydetail?propertyId=${body.propertyId}&financials={'mortgageFlag':${body.financials.mortgageFlag}, 'downPaymentRate':${body.financials.downPaymentRate}, 'interestRate':${body.financials.interestRate}, 'loanType':${body.financials.loanType}, 'insuranceRate':${body.financials.insuranceRate}, 'propertyTaxesRate':${body.financials.propertyTaxesRate}, 'vacancyRate':${body.financials.vacancyRate}, 'managementFeeRate':${body.financials.managementFeeRate}, 'maintenanceRate':${body.financials.maintenanceRate}, 'otherCostsRate':${body.financials.otherCostsRate}}`;
	return queryString;
};

export const getAPIGatewayDetailAddress = async(userSettings, address) => {
	const initialCosts = userSettings.initialCosts;
	const recurringCosts = userSettings.recurringCosts;
	let insuranceRate = recurringCosts[0].default === true ? "None" : (Number(recurringCosts[0].value) / 100);
	let propertyTaxes = recurringCosts[1].default === true ? "None" : (Number(recurringCosts[1].value) / 100);
	let vacancyRate = recurringCosts[2].default === true ? "None" : (Number(recurringCosts[2].value) / 100);
	let managementFee = recurringCosts[3].default === true ? "None" : (Number(recurringCosts[3].value) / 100);
	let maintenanceRate = recurringCosts[4].default === true ? "None" : (Number(recurringCosts[4].value) / 100);
	let otherCostsRate = recurringCosts[5].default === true ? "None" : (Number(recurringCosts[5].value) / 100);
	const downPayment = initialCosts[0].value === "" ? 0.2 : Number(initialCosts[0].value) / 100;
	const interestRate = initialCosts[1].default === true ? "None" : (Number(initialCosts[1].value) / 100);
	const mortgage = userSettings.mortgage === true ? 1 : 0;
	const loanType = userSettings.loanType;

	const body = {
		address: address,
		financials: {
			insuranceRate: insuranceRate,
			propertyTaxesRate: propertyTaxes,
			vacancyRate: vacancyRate,
			managementFeeRate: managementFee,
			maintenanceRate: maintenanceRate,
			otherCostsRate: otherCostsRate,
			mortgageFlag: mortgage,
			downPaymentRate: downPayment,
			interestRate: interestRate,
			loanType: loanType
		}
	};
	const queryString = `propertydetail?address=${body.address}&financials={'mortgageFlag':${body.financials.mortgageFlag}, 'downPaymentRate':${body.financials.downPaymentRate}, 'interestRate':${body.financials.interestRate}, 'loanType':${body.financials.loanType}, 'insuranceRate':${body.financials.insuranceRate}, 'propertyTaxesRate':${body.financials.propertyTaxesRate}, 'vacancyRate':${body.financials.vacancyRate}, 'managementFeeRate':${body.financials.managementFeeRate}, 'maintenanceRate':${body.financials.maintenanceRate}, 'otherCostsRate':${body.financials.otherCostsRate}}`;
	return queryString;
};

export const checkCondition = (condition) => {
	if ( condition === "Recently Remodeled" ) {
		return 3;
	}
	else if ( condition === "Well-Maintained" ) {
		return 2;
	}
	else if ( condition === "Moderate Rehab" ) {
		return 1;
	}
	else if ( condition === "Gut Rehab" ) {
		return 0;
	}
};

export const getARVGateway = async(cityId, zpid, address) => {
	const queryString = address === "" ? `arv?cityId=${cityId}&zpid=${zpid}` : `arv?address=${address}`;
	return queryString;
};

export const queryARVErrorEmail = async(body, address) => {
	if ( body.message === "Internal server error" ) {
		const getUser = await checkUserId();
		const contactEmail = "info@coffeeclozers.com";
		const dynamicTemplate = "d-83fa99bb35434917aa569c96d5c61eb0";

		let userData = {
			firstName: "",
			lastName: "",
			email: ""
		};
		if ( getUser.status === 200 ) {
			const colRef = "Users";
			const docRef = getUser.userId;
			const queryData = await getDocument(colRef, docRef);
			if ( queryData.status === 200 ) {
				userData = queryData.data.data;
			}
		}

		const payingUser = await checkPayingCustomer();
		const emailData = {
			to: ["ariel.herrera@coffeeclozers.com", "liam.maher@coffeeclozers.com"],
			from: contactEmail,
			templateId: dynamicTemplate,
			dynamic_template_data: {
				name: getUser.status === 200 ? `${userData.firstName} ${userData.lastName}` : "",
				email: getUser.status === 200 ? userData.email : "",
				userId: getUser.status === 200 ? getUser.userId : "",
				address: address,
				payingUser: payingUser === true ? "TRUE" : "FALSE"
			}
		};
		await sendEmail(emailData);
		console.log("internal server error email sent");
	}
}

export const getAPIGatewaySavedProperties = async(zpids, userSettings) => {
	const initialCosts = userSettings.initialCosts;
	const recurringCosts = userSettings.recurringCosts;
	let insuranceRate = recurringCosts[0].default === true ? "None" : (Number(recurringCosts[0].value) / 100);
	let propertyTaxes = recurringCosts[1].default === true ? "None" : (Number(recurringCosts[1].value) / 100);
	let vacancyRate = recurringCosts[2].default === true ? "None" : (Number(recurringCosts[2].value) / 100);
	let managementFee = recurringCosts[3].default === true ? "None" : (Number(recurringCosts[3].value) / 100);
	let maintenanceRate = recurringCosts[4].default === true ? "None" : (Number(recurringCosts[4].value) / 100);
	let otherCostsRate = recurringCosts[5].default === true ? "None" : (Number(recurringCosts[5].value) / 100);
	const downPayment = initialCosts[0].value === "" ? 0.2 : Number(initialCosts[0].value) / 100;
	const interestRate = initialCosts[1].default === true ? "None" : (Number(initialCosts[1].value) / 100);
	const mortgage = userSettings.mortgage === true ? 1 : 0;
	const loanType = userSettings.loanType;

	const body = {
		zpids: zpids,
		financials: {
			insuranceRate: insuranceRate,
			propertyTaxesRate: propertyTaxes,
			vacancyRate: vacancyRate,
			managementFeeRate: managementFee,
			maintenanceRate: maintenanceRate,
			otherCostsRate: otherCostsRate,
			mortgageFlag: mortgage,
			downPaymentRate: downPayment,
			interestRate: interestRate,
			loanType: loanType
		}
	};
	const queryString = `savedPropertySearches?propertyIds=${body.zpids}`;
	// &financials={'mortgageFlag':${body.financials.mortgageFlag}, 'downPaymentRate':${body.financials.downPaymentRate}, 'interestRate':${body.financials.interestRate}, 'loanType':${body.financials.loanType}, 'insuranceRate':${body.financials.insuranceRate}, 'propertyTaxesRate':${body.financials.propertyTaxesRate}, 'vacancyRate':${body.financials.vacancyRate}, 'managementFeeRate':${body.financials.managementFeeRate}, 'maintenanceRate':${body.financials.maintenanceRate}, 'otherCostsRate':${body.financials.otherCostsRate}}
	return queryString;
};

export const getCitySearch = async(params, searchParams) => {
	const queryString = searchParams === false ? `citysearch?city=${params.city}&state=${params.state}&returnCityId=${params.returnCityId}` : `citysearch?city=${params.city}&state=${params.state}&returnCityId=${params.returnCityId}&searchParams={'homeType':${params.homeType}, 'minPrice':${params.minPrice}, 'maxPrice':${params.maxPrice}, 'minBeds':${params.minBeds}, 'maxBeds':${params.maxBeds}, 'minBaths':${params.minBaths}, 'maxBaths':${params.maxBaths}, 'minLivingArea':${params.minSqft}, 'maxLivingArea':${params.maxSqft}, 'minYear':${params.minYearBuilt}, 'maxYear':${params.maxYearBuilt}, 'daysOnMarket':${params.daysOnMarket}, 'isForSaleForeclosure':${params.isForSaleForeclosure}, 'isNewConstruction':${params.isNewConstruction}, 'isAuction':${params.isAuction}}`;
	return queryString;
};

export const getMetroSearch = async(params) => {
	const queryString = `queryStringParameters?msaId=${params.msaTitle}`;
	return queryString;
};

export const getMetroIdSearch = async(params) => {
	const queryString = `queryStringParameters?msaId=${params.msaCode}&update=True&activeState=${params.activeState}`;
	return queryString;
};

export const getCountySearch = async(params) => {
	const queryString = `queryStringParameters?countyId=${params.countyId}`;
	return queryString;
};

export const runCountySearch = async(params) => {
	const queryString = `queryStringParameters?countyId=${params.countyId}&update=True&activeState=${params.activeState}`;
	return queryString;
};

export const getCitySearchDB = async(params) => {
	const queryString = `citySearchDb?city=${params.city}&state=${params.state}&cityId=${params.cityId}&update=${params.update}&activeState=${params.activeState}`;
	return queryString;
}

export const getCityMetroDetails = async(params, update) => {
	const extraParams = update === true ? `&update=True&activeState=${params.activeState}` : "";
	const queryParams = `?msaId=${params.id}${extraParams}`;
	return queryParams
};

export const getCompImages = async(comps, type) => {
	const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
	const getGatewayQueryString = await getCompsImages(comps, type);
	const resourceId = "compPhotos";
	const getGatewayParams = {
		type: "gateway",
		resourceId: resourceId,
		queryString: getGatewayQueryString
	};

	return new Promise(async resolve => {
		const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
		if ( getGateway.status === 200 ) {
			const body = getGateway.body;
			if ( body.message === "Endpoint request timed out" ) {
				const res = {
					status: 400,
					message: "Request timed out"
				};
				resolve(res);
			}
			const salesCompImages = body[type];
			const newArray = [];
			for (let index = 0; index < comps.length; index++) {
				const element = comps[index];
				const temporaryId = element.fullAddress.replaceAll(" ", "-");
				const imageIndex = salesCompImages.findIndex(e => e.id === temporaryId);
				if ( imageIndex !== -1 ) {
					element.images = salesCompImages[imageIndex].photos;
					element.imgSrc = salesCompImages[imageIndex].photoSourceUrl;
					newArray.push(element);
				}
				else {
					element.images = [];
					element.imgSrc = "";
					newArray.push(element);
				}
			};

			const res = {
				status: 200,
				data: newArray
			};
			resolve(res);
		}
		else {
			const res = {
				status: 400,
				message: "Error fetching images"
			};
			resolve(res);
		}
	});
};

export const addCreditRemoveSearch = async(userId, property) => {
	const colRef = "Property Searches";
	const queryData = await getDocument(colRef, userId);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		const searches = data.searches;
		const newArray = [];
		let propertyFound = false;
		for (let index = 0; index < searches.length; index++) {
			const element = searches[index];
			const brokenDownAddress = element.brokenDownAddress;
			const brokenDownStreetAddress = brokenDownAddress.streetAddress.replaceAll(" ", "");
			const propertyStreetAddress = property.address.streetAddress.replaceAll(" ", "");
			if ( brokenDownStreetAddress === propertyStreetAddress ) {
				propertyFound = true;
			}
			else {
				newArray.push(element);
			}
		};
		if ( propertyFound === true ) {
			data.searches = newArray;
			data.credits = data.credits + 1;
			await setData(colRef, userId, data);
		}
	}
};

export const checkActiveCities = async(params, userId) => {
	// Check central database of cities to check
	// which need to be updated at which frequency

	const colRef = "Active Cities";
	const docRef = "List";
	const cityId = params.cityId;
	
	const db = getFirestore();
	const queryParams = doc(db, colRef, docRef);
	let res;
	try {
		await runTransaction(db, async (transaction) => {
			const query = await transaction.get(queryParams);
			if (!query.exists()) {
				res = {
					status: 400,
					message: `Transaction failed: document doesn't exist`
				};
			}
			else {
				let data = query.data().data;
				const findCity = data.findIndex(e => e.cityId === cityId);
				if ( findCity === -1 ) {
					data = await addActiveCity(data, params, userId);
				}
				else {
					data = await checkActiveCity(data, params, findCity, userId);
				}

				transaction.update(queryParams, {
					data
				});

				res = {
					status: 200,
					message: "Transaction successfully committed",
					activeState: 2
				};
			}
		});

		return res;
	} 
	catch (e) {
		const res = {
			status: 400,
			message: `Transaction error: ${e}`,
			activeState: 0
		};
		return res;
	}
};

export const addActiveCity = async(data, params, userId) => {
	return new Promise(resolve => {
		const newCity = {
			cityId: params.cityId,
			city: params.city,
			state: params.state,
			options: [
				{
					userId: userId,
					endDate: params.endDate,
					paid: params.paid
				}
			]
		}
		data.push(newCity);
		resolve(data);
	});
};

export const checkActiveCity = async(data, params, index, userId) => {
	const cityObject = data[index];
	const options = cityObject.options;
	const paid = params.paid;

	return new Promise(resolve => {
		if ( options.length === 0 ) {
			// There should never be an array length of zero, but just in case
			// there is, we will remove that city from the global database
	
			data.splice(index, 1);
			resolve(data);
		}
		else if ( options.length === 1 ) {
	
			// 3. The NEW user is a free trial user and the old user was a paid user
			// Check if NEW user's end date is later than the old user's end date
			// ACTION -> If NOT, then do nothing.
	
			// ACTION -> If it IS, then add an extra object to the options array with the free trial user's end date
	
			const newEndDateTime = params.endDate.getTime();
			const newEndDate = Math.floor(newEndDateTime / 1000);
			const oldEndDate = options[0].endDate.seconds;

			if ( newEndDate > oldEndDate ) {
				// If new User is a paid user, we can just replace the old user with the new user.
				// If the new user is a free trial user, we need to add a second object

				cityObject.options = [
					{
						userId: userId,
						endDate: params.endDate,
						paid: paid
					}
				];
			}
			resolve(data);
		}
	});
};

export const saveUserInformation = async(userId, cities, paidUser) => {
	// Check if this city is already in our global database
	// and if not, send the city to Ariel so she can upload it

	for (let index = 0; index < cities.length; index++) {
		const element = cities[index];
		if ( element.allCities === true ) {
			continue;
		}
		const endDate = new Date(element.endDate.seconds * 1000);
		const params = {
			city: element.metroArea === false ? element.city : element.msaTitle,
			state: element.metroArea === false ? element.state : "",
			cityId: element.metroArea === false ? element.cityId : element.msaCode,
			endDate: endDate,
			paid: paidUser
		};

		const firstLetterId = params.cityId.substring(0, 1);
		const firstThreeLettersId = params.cityId.substring(0, 3);
		const areaType = firstLetterId === "M" ? "metro" : firstThreeLettersId === "CTY" ? "county" : "city";

		const activeCities = await checkActiveCities(params, userId);
		if ( activeCities.status === 200 ) {
			params.update = activeCities.activeState === 0 ? "False" : "True";
			params.activeState = activeCities.activeState;


			if ( areaType === "metro" ) {
				// If the user bought a metro area
				params.msaCode = params.cityId;
				const getCitySearchDBQueryString = await getMetroIdSearch(params);
				const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
				const gatewayData = {
					type: "gateway",
					resourceId: "msa",
					queryString: getCitySearchDBQueryString
				};
				await cloudFunctionV2(gatewayURL, gatewayData);
			}
			else if ( areaType === "county" ) {
				// If the user bought a county
				params.countyId = params.cityId;
				const getCitySearchDBQueryString = await runCountySearch(params);
				const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
				const gatewayData = {
					type: "gateway",
					resourceId: "county",
					queryString: getCitySearchDBQueryString
				};
				await cloudFunctionV2(gatewayURL, gatewayData);
			}
			else {
				// If the user bought a city
				const getCitySearchDBQueryString = await getCitySearchDB(params);
				const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
				const gatewayData = {
					type: "gateway",
					resourceId: "citySearchDB",
					queryString: getCitySearchDBQueryString
				};
				await cloudFunctionV2(gatewayURL, gatewayData);
			}
		};	
	}

	const credits = paidUser === false ? 5 : 50;
	await writeCredits(userId, credits);
	
	return true;
};

export const csvToJSON = async(str, delimiter = ",") => {

	// slice from start of text to the first \n index
	// use split to create an array from string by delimiter
	const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

	// slice from \n index + 1 to the end of the text
	// use split to create an array of each csv value row
	const rows = str.slice(str.indexOf("\n") + 1).split("\n");

	// Map the rows
	// split values from each row into an array
	// use headers.reduce to create an object
	// object properties derived from headers:values
	// the object passed as an element of the array
	const arr = rows.map(function (row) {
	  const values = row.split(delimiter);
	  const el = headers.reduce(function (object, header, index) {
		object[header] = values[index];
		return object;
	  }, {});
	  return el;
	});

	// return the array
	return arr;
}

export const getMiddle = (prop, markers) => {
	let values = markers.map(m => m[prop]);
	for (let index = 0; index < values.length; index++) {
		const element = values[index];
		if ( element === null ) {
			values.splice(index, 1);
		}
	}

	let min = Math.min(...values);
	let max = Math.max(...values);

	if ( min === Infinity || max === Infinity ) {
		return [0, 0];
	}

	if (prop === 'longitude' && (max - min > 180)) {
	  values = values.map(val => val < max - 180 ? val + 360 : val);
	  min = Math.min(...values);
	  max = Math.max(...values);
	}
	let result = (min + max) / 2;
	if (prop === 'longitude' && result > 180) {
	  result -= 360
	}
	return result;
};

export const markPropertyViewed = async(zpid) => {
	const user = await checkUserId();
	if ( user.status === 200 ) {
		const userId = user.userId;
		const colRef = "Users";
		const docRef = userId;
		const val = zpid;
		const saveData = await setViewedPropTransaction(colRef, docRef, val);
		return saveData;
	}
};

export const saveURLZipCodes = async(getSearchQueryState, setLoading, setMapProperties, page, sorting, setShowResetButton, allProperties, setProperties, strategy) => {
	// This functions checks the ZIP Codes in the URL
	// and adjusts the map accordingly

	const searchQueryState = JSON.parse(getSearchQueryState);
	const zipCodeSelections = searchQueryState.selections;

	const filteredProperties = [];
	for (let index = 0; index < allProperties.length; index++) {
		const element = allProperties[index];
		const propZipCode = element.address.zipcode;
		if ( zipCodeSelections.indexOf(propZipCode) !== -1 ) {
			filteredProperties.push(element);
		}
	}
	let newPropertyArray = await sortValues(filteredProperties, sorting, strategy);
	newPropertyArray = await sortByPage(newPropertyArray, page);
	setProperties(newPropertyArray.page);
	setMapProperties(filteredProperties);
	setLoading(false);
	setShowResetButton(true);
}

export const saveURLTracts = async(savedTracts, setSelectedTracts, properties, setPage, page, setLoading, setMapProperties, sorting, setProperties, setPropertiesOnPageChange, strategy) => {
	// This function checks the tracts in the URL
	// and adjusts the property cards & map accordingly

	let propertyCards = []
	for (let index = 0; index < savedTracts.length; index++) {
		const tract = savedTracts[index];
		for (let i = 0; i < properties.length; i++) {
			const property = properties[i];
			const propertyTract = property.censusTract;
			if ( tract === propertyTract ) {
				propertyCards.push(property);
			}
		}
	}

	propertyCards = await sortValues(propertyCards, sorting, strategy);
	setSelectedTracts(savedTracts);

	const maxPages = Math.ceil(propertyCards.length / 20);
	if ( page > maxPages && propertyCards.length !== 0 ) {
		const lowerBound = (maxPages - 1) * 20;
		const upperBound = lowerBound + 20;
		const propsSegmentedByPage = propertyCards.slice(lowerBound, upperBound);

		setProperties(propsSegmentedByPage);
		setPropertiesOnPageChange(true);
		setPage(maxPages);
	}
	else {
		const lowerBound = (page - 1) * 20;
		const upperBound = lowerBound + 20;
		const propsSegmentedByPage = propertyCards.slice(lowerBound, upperBound);

		setProperties(propsSegmentedByPage);
		setPropertiesOnPageChange(true);
	}

	setTimeout(() => {
		setLoading(false);
	}, 2000);
	setMapProperties(propertyCards);
};

export const resetMap = async(allProperties, setProperties, setMapProperties, sorting, setPage, setSearchParams, setShowResetButton, strategy) => {
	let newProperties = await sortValues(allProperties, sorting, strategy);
	newProperties = await sortByPage(newProperties, 1);
	setProperties(newProperties.page);
	setMapProperties(newProperties.map);
	setSearchParams({});
	setShowResetButton(false);
	setPage(1);
};

export const chooseCity = async(freeTrial, subscriptions, item) => {
	let checkFreeTrial = false;
	const today = new Date();
	const todaySeconds = today.getTime() / 1000;
	if ( freeTrial !== undefined && freeTrial !== null && freeTrial.length > 0 && freeTrial[0].endDate.seconds > todaySeconds ) {
		checkFreeTrial = true;
	}

	const citiesForPurchaseIndex = citiesForPurchase.findIndex(e => e.cityUnchanged === item.city && e.state === item.state)
	if ( subscriptions.length > 0 ) {
		const subscriptionIndex = subscriptions.findIndex(e => e.city === item.city && e.state === item.state && e.endDate.seconds > new Date().getTime() / 1000);
		const allCitiesSubscriptionIndex = subscriptions.findIndex(e => e.endDate.seconds > new Date().getTime() / 1000 && e.allCities === true);
		let metroSubscriptionIndex = -1;
		let metroCityIndex = -1;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea !== true ) {
				continue;
			}
			else {
				const metroCities = element.metroCities;
				const metroCityIndexCheck = metroCities.findIndex(e => e.city === item.city && e.state === item.state);
				if ( metroCityIndexCheck !== -1 ) {
					metroSubscriptionIndex = index;
					metroCityIndex = metroCityIndexCheck;
					break;
				}
			}
		}

		if ( subscriptionIndex !== -1 ) {
			const res = {
				status: 200,
				cityId: subscriptions[subscriptionIndex].cityId,
				metroArea: false
			};
			return res;
		}
		else if ( metroSubscriptionIndex !== -1 && metroCityIndex !== -1 ) {
			const res = {
				status: 200,
				cityId: subscriptions[metroSubscriptionIndex].metroCities[metroCityIndex].cityId,
				metroArea: true,
				msaCode: subscriptions[metroSubscriptionIndex].msaCode
			};
			return res;
		}
		else if ( allCitiesSubscriptionIndex !== -1 && citiesForPurchaseIndex !== -1 ) {
			const cityId = citiesForPurchase[citiesForPurchaseIndex].city_id;
			const res = {
				status: 200,
				cityId: cityId,
				metroArea: false
			};
			return res;
		}
		else if ( checkFreeTrial === true && citiesForPurchaseIndex !== -1 ) {
			const cityId = citiesForPurchase[citiesForPurchaseIndex].city_id;
			const res = {
				status: 200,
				cityId: cityId,
				metroArea: false
			};
			return res;
		}
		else {
			const res = {
				status: 400,
				cityId: "",
				metroArea: false
			};
			return res;
		}
	}
	else if ( freeTrial[0].endDate.seconds > new Date().getTime() / 1000 && citiesForPurchaseIndex !== -1 ) {
		const cityId = citiesForPurchase[citiesForPurchaseIndex].city_id;
		const res = {
			status: 200,
			cityId: cityId,
			metroArea: false
		};
		return res;
	}
	else {
		const res = {
			status: 400,
			cityId: "",
			metroArea: false
		};
		return res;
	}
};

export const submitListChanges = async(
	setListLoading,
	setLists,
	setListModal,
	setSelectedList,
	setSelectedProperties,
	userId,
	selectedList,
	selectedProperties,
	favourites
) => {

	return new Promise(async resolve => {
		setListLoading(true);
		let oldData = [];
		const queryData = await getDocument("Lists", userId);
		if ( queryData.status === 200 ) {
			oldData = queryData.data.data;
		}
		
		// Add properties to selected lists which that property is NOT currently in
		for (let index = 0; index < selectedList.length; index++) {
			const listId = selectedList[index];
			const listIndex = oldData.findIndex(e => e.id === listId);
			if ( listIndex !== -1 ) {
				const currentProperties = oldData[listIndex].properties;
				for (let ii = 0; ii < selectedProperties.length; ii++) {
					const zpid = selectedProperties[ii];
					const currentPropertyIndex = currentProperties.findIndex(e => e.zpid === zpid);
					if ( currentPropertyIndex === -1 ) {
						const newObject = {
							date: new Date(),
							zpid: zpid,
							featuredImage: null
						}

						const favouriteIndex = favourites.findIndex(e => e.zpid === zpid);
						if ( favouriteIndex !== -1 ) {
							const item = favourites[favouriteIndex];
							if ( item.imgSrc !== undefined && item.imgSrc !== null ) {
								if ( item.imgSrc.includes("maps.googleapis.com") ) {

								}
								else {
									oldData[listIndex].featuredImage = item.imgSrc;
									newObject.featuredImage = item.imgSrc;
								}
							}
						}

						oldData[listIndex].properties.push(newObject);
					}
				}
			}
		}

		// Check if we need to REMOVE any property from a list
		for (let index = 0; index < selectedProperties.length; index++) {
			const element = selectedProperties[index];
			for (let ii = 0; ii < oldData.length; ii++) {
				const list = oldData[ii];
				const listIndex = selectedList.indexOf(list.id);
				if ( listIndex === -1 ) {
					const propertyIndex = list.properties.findIndex(e => e.zpid === element);
					if ( propertyIndex !== -1 ) {
						oldData[ii].properties.splice(propertyIndex, 1);

						// Setting the list's featured image to null if there are no
						// more properties left
						if ( oldData[ii].properties.length === 0 ) {
							oldData[ii].featuredImage = null;
						}
						else {
							// If there are properties left and the list's featured image belongs
							// to a property still in the list, do NOTHING

							// If the featured image is null or belongs to a property no longer in the list,
							// choose a new property in the list
							const listFeaturedImage = oldData[ii].featuredImage;
							if ( listFeaturedImage !== null ) {
								const featuredImageIndex = oldData[ii].properties.findIndex(e => e.featuredImage === listFeaturedImage);
								if ( featuredImageIndex === -1 ) {
									const newFeaturedImage = oldData[ii].properties.findIndex(e => e.featuredImage !== null);
									if ( newFeaturedImage !== -1 ) {
										oldData[ii].featuredImage = oldData[ii].properties[newFeaturedImage].featuredImage;
									}
									else {
										oldData[ii].featuredImage = null;
									}
								}
							}
						}
					}
				}
			}
		}

		await setData("Lists", userId, oldData);

		setLists(oldData);
		setListModal(false);
		setSelectedList([]);
		setListLoading(false);
		setSelectedProperties([]);

		const res = {
			status: 200,
			data: oldData
		};
		resolve(res);
	});
};

export const submitListDeletion = async(
	setDeleteListLoading,
	setDeleteListModal,
	setDeleteListAndProps,
	userId,
	listId,
	deleteListAndProps
) => {
	setDeleteListLoading(true);
	const queryData = await getDocument("Lists", userId);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		const listIndex = data.findIndex(e => e.id === listId);

		if ( deleteListAndProps === true && listIndex !== -1 ) {
			const queryFavourites = await getDocument("Favourites", userId);
			if ( queryFavourites.status === 200 ) {
				const favouriteData = queryFavourites.data.data;
				
				const newData = [];
				for (let index = 0; index < favouriteData.length; index++) {
					const element = favouriteData[index];
					const zpid = element.zpid;
					const listZpidIndex = data[listIndex].properties.findIndex(e => e.zpid === zpid);
					if ( listZpidIndex === -1 ) {
						newData.push(element);
					}
				};
				await setData("Favourites", userId, newData);
			}
		}

		if ( listIndex !== -1 ) {
			data.splice(listIndex, 1);
			await setData("Lists", userId, data);
		}
	}

	setDeleteListModal(false);
	setDeleteListAndProps(false);
	setDeleteListLoading(false);
};

export const submitListRename = async(
	setRenameLoading,
	setRenameModal,
	setList,
	userId,
	listId,
	listName
) => {
	setRenameLoading(true);
	const queryData = await getDocument("Lists", userId);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		const listIndex = data.findIndex(e => e.id === listId);
		if ( listIndex !== -1 ) {
			data[listIndex].title = listName;
			await setData("Lists", userId, data);
			setList(data[listIndex]);
		}
	}

	setRenameModal(false);
	setRenameLoading(false);
}

export const submitCreateList = async(
	setListLoading,
	setLists,
	setCreateListModal,
	setListModal,
	userId,
	newListName
) => {
	setListLoading(true);
	let oldData = [];
	const queryData = await getDocument("Lists", userId);
	if ( queryData.status === 200 ) {
		oldData = queryData.data.data;
	}

	const generateId = await makeId(15);
	const newList = {
		properties: [],
		date: new Date(),
		id: generateId,
		title: newListName
	};
	oldData.push(newList);
	await setData("Lists", userId, oldData);
	setLists(oldData);
	setCreateListModal(false);
	setListModal(true);
	setListLoading(false);
};

export const submitDeleteProperties = async(
	userId,
	selectedProperties,
	setDeletePropertiesModal,
	setDeleteLoading,
	setSelectedProperties,
	setFavourites,
	favourites,
	setLists,
	setSelectedList,
	listId,
	setList
) => {
	setDeleteLoading(true);
	const collections = [
		"Favourites",
		"Lists"
	];
	const queryData = await getUserDocuments(collections, userId);
	if ( queryData[0].status === 200 ) {
		const oldData = queryData[0].data;
		const newFavourites = [];
		for (let index = 0; index < oldData.length; index++) {
			const element = oldData[index];
			const zpid = element.zpid;
			const propertyIndex = selectedProperties.indexOf(zpid);
			if ( propertyIndex === -1 ) {
				newFavourites.push(element);
			}
		};

		await setData("Favourites", userId, newFavourites);
		
		const newProps = [];
		for (let index = 0; index < favourites.length; index++) {
			const element = favourites[index];
			if ( selectedProperties.indexOf(element.zpid) === -1 ) {
				newProps.push(element);
			}
		}
		setFavourites(newProps);
	}

	if ( queryData[1].status === 200 ) {
		const oldData = queryData[1].data;
		const newLists = [];
		for (let index = 0; index < oldData.length; index++) {
			const element = oldData[index];
			const properties = element.properties;
			const newProperties = [];
			for (let ii = 0; ii < properties.length; ii++) {
				const property = properties[ii];
				const propertyIndex = selectedProperties.indexOf(property.zpid);
				if ( propertyIndex === -1 ) {
					newProperties.push(property);
				}
			};
			
			element.properties = newProperties;
			if ( element.properties.length === 0 ) {
				element.featuredImage = null;
			}
			else {
				const currentFeaturedImage = element.featuredImage;
				const featuredImageIndex = element.properties.findIndex(e => e.featuredImage === currentFeaturedImage);
				if ( featuredImageIndex === -1 ) {
					const newImageIndex = element.properties.findIndex(e => e.featuredImage !== null);
					if ( newImageIndex !== -1 ) {
						element.featuredImage = element.properties[newImageIndex].featuredImage;
					}
					else {
						element.featuredImage = null;
					}
				}
			}
			newLists.push(element);
		};

		if ( setList !== null ) {
			const listIndex = newLists.findIndex(e => e.id === listId);
			if ( listIndex !== -1 ) {
				setList(newLists[listIndex]);
			}
		}

		await setData("Lists", userId, newLists);
		setLists(newLists);
	}

	setDeletePropertiesModal(false);
	setDeleteLoading(false);
	setSelectedProperties([]);
	setSelectedList([]);
};

export const submitPropertyNote = async(
	setNoteLoading,
	setNewNote,
	setNoteModal,
	setNoteZpid,
	userId,
	noteZpid,
	newNote,
	properties,
	setProperties
) => {
	setNoteLoading(true);
	const queryData = await getDocument("Favourites", userId);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		const favouriteIndex = data.findIndex(e => e.zpid === noteZpid);
		if ( favouriteIndex !== -1 ) {
			data[favouriteIndex].note = newNote;
			await setData("Favourites", userId, data);

			const propertiesClone = [...properties];
			const propertiesCloneIndex = propertiesClone.findIndex(e => e.zpid === noteZpid);
			if ( propertiesCloneIndex !== -1 ) {
				propertiesClone[propertiesCloneIndex].note = newNote;
				setProperties(propertiesClone);
			}
		}
	}
	setNewNote("");
	setNoteModal(false);
	setNoteZpid("");
	setNoteLoading(false);
};

export const markOfferSent = async(userId, zpid, val) => {
	const colRef = "Favourites";
	const queryData = await getDocument(colRef, userId);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		const index = data.findIndex(e => e.zpid === zpid);
		if ( index !== -1 ) {
			data[index].offerSent = val;
			await setData(colRef, userId, data);
		}
	}
};

export const getCellKey = (label, excelIndex) => {
	const cellKey = [
		{
			label: "Sq. Footage",
			cell: `G${excelIndex}`
		},
		{
			label: "Cash-flow",
			cell: `K${excelIndex}`
		},
		{
			label: "Up-Front Costs",
			cell: `L${excelIndex}`
		},
		{
			label: "Price",
			cell: `M${excelIndex}`
		},
		{
			label: "Price per sq. foot",
			cell: `N${excelIndex}`
		},
		{
			label: "Down Payment",
			cell: `O${excelIndex}`
		},
		{
			label: "Interest Rate",
			cell: `P${excelIndex}`
		},
		{
			label: "Closing Costs",
			cell: `Q${excelIndex}`
		},
		{
			label: "Renovation",
			cell: `R${excelIndex}`
		},
		{
			label: "Cash In Deal",
			cell: `S${excelIndex}`
		},
		{
			label: "ARV",
			cell: `T${excelIndex}`
		},
		{
			label: "ARV Spread",
			cell: `U${excelIndex}`
		},
		{
			label: "ARV Refinance",
			cell: `V${excelIndex}`
		},
		{
			label: "Rent",
			cell: `W${excelIndex}`
		},
		{
			label: "Other Income",
			cell: `X${excelIndex}`
		},
		{
			label: "Mortgage",
			cell: `Y${excelIndex}`
		},
		{
			label: "HOA",
			cell: `Z${excelIndex}`
		},
		{
			label: "Insurance",
			cell: `AA${excelIndex}`
		},
		{
			label: "Property taxes",
			cell: `AB${excelIndex}`
		},
		{
			label: "Other costs",
			cell: `AF${excelIndex}`
		},
		{
			label: "Total Holding Costs",
			cell: `AG${excelIndex}`
		},
		{
			label: "Monthly Holding Costs",
			cell: `AH${excelIndex}`
		},
		{
			label: "Holding Period",
			cell: `AI${excelIndex}`
		},
		{
			label: "Loan years",
			cell: `AJ${excelIndex}`
		},
		{
			label: "Loan amount",
			cell: `AK${excelIndex}`
		},
		{
			label: "Payment Months",
			cell: `AL${excelIndex}`
		}
	];
	const index = cellKey.findIndex(e => e.label === label);
	return cellKey[index].cell;
};

export const downloadExcelFreeTrial = (rawData, city, cityId, downPayment, propertySearch, strategy) => {
	const data = [];
	for (let index = 0; index < rawData.length; index++) {
		const element = rawData[index];
		const excelIndex = index + 2;
		const fullAddress = `${element.address.streetAddress}, ${element.address.city}, ${element.address.state} ${element.address.zipcode}`
		const encodedAddress = element.propertySearch === false ? "" : encodeURIComponent(fullAddress);
		const purchaseCosts = element.purchaseCosts;
		if ( purchaseCosts === undefined ) {
			element.purchaseCosts = {
				closingCostRate: 0.03
			}
		}

		const initialCostsCell = getCellKey("Up-Front Costs", excelIndex);
		const monthlyProfitCell = getCellKey("Cash-flow", excelIndex);
		const priceCell = getCellKey("Price", excelIndex);
		const downPaymentCell = getCellKey("Down Payment", excelIndex);
		const closingCostCell = getCellKey("Closing Costs", excelIndex);
		const interestRateCell = getCellKey("Interest Rate", excelIndex);
		const renovationCell = getCellKey("Renovation", excelIndex);

		const rentCell = getCellKey("Rent", excelIndex);
		const otherIncomeCell = getCellKey("Other Income", excelIndex);
		const mortgageCell = getCellKey("Mortgage", excelIndex);
		const hoaCell = getCellKey("HOA", excelIndex);
		const insuranceCell = getCellKey("Insurance", excelIndex);
		const propertyTaxesCell = getCellKey("Property taxes", excelIndex);
		const otherCostsCell = getCellKey("Other costs", excelIndex);
		const loanYearsCell = getCellKey("Loan years", excelIndex);
		const paymentMonthsCell = getCellKey("Payment Months", excelIndex);
		const loanAmountCell = getCellKey("Loan amount", excelIndex);

		const arvCell = getCellKey("ARV", excelIndex);
		// const arvSpreadCell = getCellKey("ARV Spread", excelIndex);
		const arvRefinanceCell = getCellKey("ARV Refinance", excelIndex);

		const holdingPeriodCell = getCellKey("Holding Period", excelIndex);
		const monthlyHoldingCostsCell = getCellKey("Monthly Holding Costs", excelIndex);
		const totalHoldingCostsCell = getCellKey("Total Holding Costs", excelIndex);
		const cashInDealCell = getCellKey("Cash In Deal", excelIndex);

		const arvPrice = element.arvComps === true && element.arvCompsStats !== undefined ? element.arvCompsStats.arvPrice : element.arvComps === true ? element.arvPrice : element.price;
		const purchasePrice = element.price;
		const arvSpread = (arvPrice - purchasePrice) / purchasePrice;

		const sqFootageCell = getCellKey("Sq. Footage", excelIndex);
		const loanYears = 30;
		const brrrrTaxRate = element.apiFinancialParams !== undefined && element.apiFinancialParams.propertyTaxRate !== undefined ? element.apiFinancialParams.propertyTaxRate : 0;
		const brrrrPropertyTax = (((brrrrTaxRate / 100) * arvPrice) / 12);

		const newObject = {
			"Street": element.address.streetAddress,
			"City": element.address.city,
			"State": element.address.state,
			"ZIP Code": element.address.zipcode,
			"House Type": element.propertyTypeDimension,
			"Year Built": element.yearBuilt,
			"Sq. Footage": element.livingArea !== null ? element.livingArea : 1,
			"Bedrooms": element.bedrooms !== null ? element.bedrooms : 0,
			"Bathrooms": element.bathrooms !== null ? element.bathrooms : 0,
			"Cash on Cash": {
				t: "n",
				z: "0.00%",
				f: `((${monthlyProfitCell}*12)/${strategy === 2 ? cashInDealCell : initialCostsCell})`
			},
			"Cash-flow": {
				t: "n", 
				v: element.financials.monthlyProfit,
				z: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',
				f: `(${rentCell}+${otherIncomeCell})-SUM(${mortgageCell}:${otherCostsCell})`,
			},
			"Up-Front Costs": {
				t: "n",
				v: (((downPayment / 100) * element.price) + (element.price * element.purchaseCosts.closingCostRate) + (element.financials.renovation !== undefined ? element.financials.renovation : 0)),
				z: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',
				f: `(${downPaymentCell}+${closingCostCell}+${renovationCell})`
			},
			"Price": {
				t: "n",
				v: element.price,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)'
			},
			"Price Per Sq. Foot": {
				t: "n",
				v: element.price / (element.livingArea !== null ? element.livingArea : 1),
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${priceCell}/${sqFootageCell})`
			},
			"Down Payment": {
				t: "n",
				v: ((downPayment / 100) * element.price),
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${downPayment}/100)*${priceCell}`
			},
			"Interest Rate": {
				t: "n",
				v: element.mortgage30us !== undefined ? element.mortgage30us / 100 : 0.0718,
				z: "0.00%"
			},
			"Closing Costs": {
				t: "n",
				v: element.price * element.purchaseCosts.closingCostRate,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${priceCell}*${element.purchaseCosts.closingCostRate})`
			},
			"Total Renovation Cost": {
				t: "n",
				v: element.financials.totalRehabCost !== undefined ? element.financials.totalRehabCost : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)'
			},
			"Cash In Deal": {
				t: "n",
				v: 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `${arvRefinanceCell}-(${downPaymentCell}+${renovationCell}+${closingCostCell}+${totalHoldingCostsCell})`
			},
			"ARV": {
				t: "n",
				v: arvPrice,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"ARV Spread": {
				t: "n",
				v: arvSpread,
				z: "0.00%",
				f: `(${arvCell}-${priceCell})/${priceCell}`
			},
			"ARV Refinance": {
				t: "n",
				v: 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `${arvCell}*${strategy === 2 ? 0.75 : 0}`
			},
			"Rent": {
				t: "n",
				v: element.financials.rent !== null ? element.financials.rent : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Other Income": {
				t: "n",
				v: element.financials.otherIncome !== undefined ? element.financials.otherIncome : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Mortgage payment": {
				t: "n",
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(-PMT(${interestRateCell}/(${paymentMonthsCell}/${loanYearsCell}),${paymentMonthsCell},${strategy === 2 ? arvRefinanceCell : loanAmountCell},0))`
			},
			"HOA fees": {
				t: "n",
				v: element.financials.hoaFees,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Insurance": {
				t: "n",
				v: element.financials.insurance,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Property taxes": {
				t: "n",
				v: strategy === 2 ? brrrrPropertyTax : element.financials.propertyTaxes,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Vacancy": {
				t: "n",
				v: element.financials.vacancyRateAllocation !== null ? element.financials.vacancyRateAllocation : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Management fee": {
				t: "n",
				v: element.financials.managementFee !== null ? element.financials.managementFee : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Maintenance": {
				t: "n",
				v: element.financials.maintenanceFee !== null ? element.financials.maintenanceFee : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Other costs": {
				t:"n",
				v: element.financials.otherCosts !== undefined ? element.financials.otherCosts : 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
			},
			"Total Holding Costs": {
				t: "n",
				v: 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${monthlyHoldingCostsCell}*${holdingPeriodCell})`
			},
			"Monthly Holding Costs": {
				t: "n",
				v: 0,
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${hoaCell}+${insuranceCell}+${propertyTaxesCell})`
			},
			"Holding Period": {
				t: "n",
				v: 4,
			},
			"Loan Years": {
				t: "n",
				v: loanYears
			},
			"Loan Amount": {
				t: "n",
				z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				f: `(${priceCell}-${downPaymentCell})`
			},
			"Payment Months": {
				t: "n",
				v: (loanYears * 12),
				f: `(${loanYearsCell}*12)`
			},
			"Zoned for ADU": {
				t: "b",
				v: element.zonedForAdu
			},
			"Add Bedroom Opportunity": {
				t: "b",
				v: element.addBedOpportunity
			},
			"Fixer Upper": {
				t: "b",
				v: element.fixerUpper
			},
			"Drip Score": {
				t: "n",
				v: element.dripScore !== null ? element.dripScore : 0,
				z: "0.00%"
			},
			"Off Market": {
				t: "b",
				v: element.offMarket
			},
			"Contact Name": element.listedBy !== undefined && element.listedBy !== null && element.listedBy.displayName !== undefined && element.listedBy.displayName !== null ? element.listedBy.displayName : element.listedBy !== undefined && element.listedBy !== null && element.attributionInfo.agentName !== null ? element.attributionInfo.agentName : "N/A",
			"Contact Type": element.fsbo === true ? "Owner" : "Agent",
			"Contact Phone Number": element.attributionInfo !== undefined && element.attributionInfo !== null && element.attributionInfo.agentPhoneNumber !== null ? element.attributionInfo.agentPhoneNumber : "N/A",
			"Contact Email": element.attributionInfo !== undefined && element.attributionInfo !== null && element.attributionInfo.agentEmail !== undefined && element.attributionInfo.agentEmail !== null ? element.attributionInfo.agentEmail : "N/A",
			"Parcel ID": element.parcelId !== undefined ? element.parcelId : "N/A",
			"Coffee Clozers Report": {
				v: propertySearch === true ? `http://app.coffeeclozers.com/quick-report/${encodedAddress}` : `http://app.coffeeclozers.com/properties/${cityId !== "" ? cityId : element.cityId}/${element.zpid}`,
				l: {
					Target: propertySearch === true ? `http://app.coffeeclozers.com/quick-report/${encodedAddress}` : `http://app.coffeeclozers.com/properties/${cityId !== "" ? cityId : element.cityId}/${element.zpid}`
				}
			}
		};

		if ( element.zonedForAdu === undefined ) {
			delete newObject["Zoned for ADU"];
		}
		if ( element.addBedOpportunity === undefined ) {
			delete newObject["Add Bedroom Opportunity"];
		}
		if ( element.dripScore === undefined ) {
			delete newObject["Drip Score"];
		}

		data.push(newObject);
	}

	const freeTrialCell = {
		"Cash on Cash": "For the full data set, contact liam.maher@coffeeclozers.com"
	};
	data.push(freeTrialCell);

	const formattedTitle = `Coffee Clozers Free Report.xlsx`;
	const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, formattedTitle);
    XLSX.writeFile(workbook, formattedTitle);
};

export const downloadExcel = async(rawData, city, cityId, downPayment, propertySearch, strategy, params) => {
	return new Promise(async resolve => {
		if ( rawData.length === 0 ) {
			const queryData = await getAllProperties(params);
			if ( queryData.status === 200 ) {
				rawData = queryData.data;
			}
			else {
				resolve(queryData);
				return;
			}
		}
	
		const data = [];
		for (let index = 0; index < rawData.length; index++) {
			const element = rawData[index];
			const excelIndex = index + 2;
			const fullAddress = `${element.address.streetAddress}, ${element.address.city}, ${element.address.state} ${element.address.zipcode}`
			const encodedAddress = element.propertySearch === false ? "" : encodeURIComponent(fullAddress);
			const purchaseCosts = element.purchaseCosts;
			if ( purchaseCosts === undefined ) {
				element.purchaseCosts = {
					closingCostRate: 0.03
				}
			}
	
			const initialCostsCell = getCellKey("Up-Front Costs", excelIndex);
			const monthlyProfitCell = getCellKey("Cash-flow", excelIndex);
			const priceCell = getCellKey("Price", excelIndex);
			const downPaymentCell = getCellKey("Down Payment", excelIndex);
			const closingCostCell = getCellKey("Closing Costs", excelIndex);
			const interestRateCell = getCellKey("Interest Rate", excelIndex);
			const renovationCell = getCellKey("Renovation", excelIndex);
	
			const rentCell = getCellKey("Rent", excelIndex);
			const otherIncomeCell = getCellKey("Other Income", excelIndex);
			const mortgageCell = getCellKey("Mortgage", excelIndex);
			const hoaCell = getCellKey("HOA", excelIndex);
			const insuranceCell = getCellKey("Insurance", excelIndex);
			const propertyTaxesCell = getCellKey("Property taxes", excelIndex);
			const otherCostsCell = getCellKey("Other costs", excelIndex);
			const loanYearsCell = getCellKey("Loan years", excelIndex);
			const paymentMonthsCell = getCellKey("Payment Months", excelIndex);
			const loanAmountCell = getCellKey("Loan amount", excelIndex);
	
			const arvCell = getCellKey("ARV", excelIndex);
			// const arvSpreadCell = getCellKey("ARV Spread", excelIndex);
			const arvRefinanceCell = getCellKey("ARV Refinance", excelIndex);
	
			const holdingPeriodCell = getCellKey("Holding Period", excelIndex);
			const monthlyHoldingCostsCell = getCellKey("Monthly Holding Costs", excelIndex);
			const totalHoldingCostsCell = getCellKey("Total Holding Costs", excelIndex);
			const cashInDealCell = getCellKey("Cash In Deal", excelIndex);
	
			const arvPrice = element.arvComps === true && element.arvCompsStats !== undefined ? element.arvCompsStats.arvPrice : element.arvComps === true ? element.arvPrice : element.price;
			const purchasePrice = element.price;
			const arvSpread = (arvPrice - purchasePrice) / purchasePrice;
	
			const sqFootageCell = getCellKey("Sq. Footage", excelIndex);
			const loanYears = 30;
			const brrrrTaxRate = element.apiFinancialParams !== undefined && element.apiFinancialParams.propertyTaxRate !== undefined ? element.apiFinancialParams.propertyTaxRate : 0;
			const brrrrPropertyTax = (((brrrrTaxRate / 100) * arvPrice) / 12);
	
			const newObject = {
				"Street": element.address.streetAddress,
				"City": element.address.city,
				"State": element.address.state,
				"ZIP Code": element.address.zipcode,
				"House Type": element.propertyTypeDimension,
				"Year Built": element.yearBuilt,
				"Sq. Footage": element.livingArea !== null ? element.livingArea : 1,
				"Bedrooms": element.bedrooms !== null ? element.bedrooms : 0,
				"Bathrooms": element.bathrooms !== null ? element.bathrooms : 0,
				"Cash on Cash": {
					t: "n",
					z: "0.00%",
					f: `((${monthlyProfitCell}*12)/${strategy === 2 ? cashInDealCell : initialCostsCell})`
				},
				"Cash-flow": {
					t: "n", 
					v: element.financials.monthlyProfit,
					z: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',
					f: `(${rentCell}+${otherIncomeCell})-SUM(${mortgageCell}:${otherCostsCell})`,
				},
				"Up-Front Costs": {
					t: "n",
					v: (((downPayment / 100) * element.price) + (element.price * element.purchaseCosts.closingCostRate) + (element.financials.renovation !== undefined ? element.financials.renovation : 0)),
					z: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',
					f: `(${downPaymentCell}+${closingCostCell}+${renovationCell})`
				},
				"Price": {
					t: "n",
					v: element.price,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)'
				},
				"Price Per Sq. Foot": {
					t: "n",
					v: element.price / (element.livingArea !== null ? element.livingArea : 1),
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `(${priceCell}/${sqFootageCell})`
				},
				"Down Payment": {
					t: "n",
					v: ((downPayment / 100) * element.price),
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `(${downPayment}/100)*${priceCell}`
				},
				"Interest Rate": {
					t: "n",
					v: element.mortgage30us !== undefined ? element.mortgage30us / 100 : 0.0718,
					z: "0.00%"
				},
				"Closing Costs": {
					t: "n",
					v: element.price * element.purchaseCosts.closingCostRate,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `(${priceCell}*${element.purchaseCosts.closingCostRate})`
				},
				"Total Renovation Cost": {
					t: "n",
					v: element.financials.totalRehabCost !== undefined ? element.financials.totalRehabCost : 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)'
				},
				"Cash In Deal": {
					t: "n",
					v: 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `${arvRefinanceCell}-(${downPaymentCell}+${renovationCell}+${closingCostCell}+${totalHoldingCostsCell})`
				},
				"ARV": {
					t: "n",
					v: arvPrice,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"ARV Spread": {
					t: "n",
					v: arvSpread,
					z: "0.00%",
					f: `(${arvCell}-${priceCell})/${priceCell}`
				},
				"ARV Refinance": {
					t: "n",
					v: 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `${arvCell}*${strategy === 2 ? 0.75 : 0}`
				},
				"Rent": {
					t: "n",
					v: element.financials.rent !== null ? element.financials.rent : 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Other Income": {
					t: "n",
					v: element.financials.otherIncome !== undefined ? element.financials.otherIncome : 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Mortgage payment": {
					t: "n",
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `(-PMT(${interestRateCell}/(${paymentMonthsCell}/${loanYearsCell}),${paymentMonthsCell},${strategy === 2 ? arvRefinanceCell : loanAmountCell},0))`
				},
				"HOA fees": {
					t: "n",
					v: element.financials.hoaFees,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Insurance": {
					t: "n",
					v: element.financials.insurance,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Property taxes": {
					t: "n",
					v: strategy === 2 ? brrrrPropertyTax : element.financials.propertyTaxes,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Vacancy": {
					t: "n",
					v: element.financials.vacancyRateAllocation !== null ? element.financials.vacancyRateAllocation : 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Management fee": {
					t: "n",
					v: element.financials.managementFee !== null ? element.financials.managementFee : 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Maintenance": {
					t: "n",
					v: element.financials.maintenanceFee !== null ? element.financials.maintenanceFee : 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Other costs": {
					t:"n",
					v: element.financials.otherCosts !== undefined ? element.financials.otherCosts : 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
				},
				"Total Holding Costs": {
					t: "n",
					v: 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `(${monthlyHoldingCostsCell}*${holdingPeriodCell})`
				},
				"Monthly Holding Costs": {
					t: "n",
					v: 0,
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `(${hoaCell}+${insuranceCell}+${propertyTaxesCell})`
				},
				"Holding Period": {
					t: "n",
					v: 4,
				},
				"Loan Years": {
					t: "n",
					v: loanYears
				},
				"Loan Amount": {
					t: "n",
					z: '"$"#,##0.00_);\\("$"#,##0.00\\)',
					f: `(${priceCell}-${downPaymentCell})`
				},
				"Payment Months": {
					t: "n",
					v: (loanYears * 12),
					f: `(${loanYearsCell}*12)`
				},
				"Zoned for ADU": {
					t: "b",
					v: element.zonedForAdu
				},
				"Add Bedroom Opportunity": {
					t: "b",
					v: element.addBedOpportunity
				},
				"Fixer Upper": {
					t: "b",
					v: element.fixerUpper
				},
				"Drip Score": {
					t: "n",
					v: element.dripScore !== null ? element.dripScore : 0,
					z: "0.00%"
				},
				"Off Market": {
					t: "b",
					v: element.offMarket
				},
				"Contact Name": element.listedBy !== undefined && element.listedBy !== null && element.listedBy.displayName !== undefined && element.listedBy.displayName !== null ? element.listedBy.displayName : element.listedBy !== undefined && element.listedBy !== null && element.attributionInfo.agentName !== null ? element.attributionInfo.agentName : "N/A",
				"Contact Type": element.fsbo === true ? "Owner" : "Agent",
				"Contact Phone Number": element.attributionInfo !== undefined && element.attributionInfo !== null && element.attributionInfo.agentPhoneNumber !== null ? element.attributionInfo.agentPhoneNumber : "N/A",
				"Contact Email": element.attributionInfo !== undefined && element.attributionInfo !== null && element.attributionInfo.agentEmail !== undefined && element.attributionInfo.agentEmail !== null ? element.attributionInfo.agentEmail : "N/A",
				"Parcel ID": element.parcelId !== undefined ? element.parcelId : "N/A",
				"Coffee Clozers Report": {
					v: propertySearch === true ? `http://app.coffeeclozers.com/quick-report/${encodedAddress}` : `http://app.coffeeclozers.com/properties/${cityId !== "" ? cityId : element.cityId}/${element.zpid}`,
					l: {
						Target: propertySearch === true ? `http://app.coffeeclozers.com/quick-report/${encodedAddress}` : `http://app.coffeeclozers.com/properties/${cityId !== "" ? cityId : element.cityId}/${element.zpid}`
					}
				}
			};
			if ( element.zonedForAdu === undefined ) {
				delete newObject["Zoned for ADU"];
			}
			if ( element.addBedOpportunity === undefined ) {
				delete newObject["Add Bedroom Opportunity"];
			}
			if ( element.dripScore === undefined ) {
				delete newObject["Drip Score"];
			}
	
			data.push(newObject);
		}
	
		const formattedTitle = `${city !== "" ? city : "Favourites"} - Coffee Clozers Report.xlsx`;
		const sheetTitle = formattedTitle.length > 30 ? `${city !== "" ? city : "Favourites"}.xlsx` : formattedTitle;
		const doubleCheckedTitle = sheetTitle.length > 30 ? `Coffee Clozers Report.xlsx` : sheetTitle;
		const tripleCheckedTitle = doubleCheckedTitle.length > 30 ? `${doubleCheckedTitle.slice(0, 25)}.xlsx` : doubleCheckedTitle;
	
		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, tripleCheckedTitle);
		XLSX.writeFile(workbook, tripleCheckedTitle);
		resolve({
			status: 200
		});
	});
};

export const sendPropertiesForExcel = async(properties, sheetName, cityId, downPayment, sheetStrategy) => {
	return new Promise(async resolve => {
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			const gatewayURL = process.env.REACT_APP_SPREADSHEET_URL;
			const gatewayData = {
				s3Data: null,
				city: sheetName,
				cityId: cityId,
				downPayment: downPayment,
				exitStrategy: sheetStrategy,
				userId: userId,
				downloadData: false,
				properties: properties
			};
			const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
			resolve(getGateway);
		}
		else {
			const res = {
				status: 400,
				error: "User not logged in"
			};
			resolve(res);
		}
	});
};

export const downloadAllPropertiesExcel = async(cityStateName, cityId, downPayment, sheetStrategy, sheetDownloadParams) => {
	return new Promise(async resolve => {
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			const queryData = await getAllProperties(sheetDownloadParams);
			if ( queryData.status === 200 ) {
				const key = queryData.data;
				const gatewayURL = process.env.REACT_APP_SPREADSHEET_URL;
				const gatewayData = {
					s3Data: {
						bucketName: "residentialpropertydata",
						key: key
					},
					city: cityStateName,
					cityId: cityId,
					downPayment: downPayment,
					exitStrategy: sheetStrategy,
					userId: userId,
					downloadData: true,
					properties: []
				};
				const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
				resolve(getGateway);
			}
			else {
				resolve(queryData);
			}
		}
		else {
			const res = {
				status: 400,
				error: "User not logged in"
			};
			resolve(res);
		}
	});
};

export const sendSMS = async(data) => {
	const url = "https://twilioapiv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
}

export const mailchimpAPI = async(data) => {
	const url = "https://mailchimpapiv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
}

export const addMailchimpAPITag = async(data) => {
	const url = "https://addmailchimpapitagv2-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
}

export const queryOffMarketData = async(data) => {
	const url = "https://getoffmarketdata-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'access-control-request-headers': 'content-type'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
}

export const sortValues = (data, val, strategy) => {
	if ( val === 0 || val === 1 || val > 7 ) {
		if ( strategy === 0 ) {
			data.sort((a,b) => (a.dripScore < b.dripScore) ? 1 : ((b.dripScore < a.dripScore) ? -1 : 0));
		}
		else if ( strategy === 1 ) {
			data.sort((a,b) => (a.dripScoreOptions.traditional < b.dripScoreOptions.traditional) ? 1 : ((b.dripScoreOptions.traditional < a.dripScoreOptions.traditional) ? -1 : 0));
		}
		else if ( strategy === 2 ) {
			data.sort((a,b) => (a.dripScoreOptions.houseHacking < b.dripScoreOptions.houseHacking) ? 1 : ((b.dripScoreOptions.houseHacking < a.dripScoreOptions.houseHacking) ? -1 : 0));
		}
		else if ( strategy === 3 ) {
			data.sort((a,b) => (a.dripScoreOptions.brrrr < b.dripScoreOptions.brrrr) ? 1 : ((b.dripScoreOptions.brrrr < a.dripScoreOptions.brrrr) ? -1 : 0));
		}
		else if ( strategy === 4 ) {
			data.sort((a,b) => (a.dripScoreOptions.fixAndFlip < b.dripScoreOptions.fixAndFlip) ? 1 : ((b.dripScoreOptions.fixAndFlip < a.dripScoreOptions.fixAndFlip) ? -1 : 0));
		}
		else if ( strategy === 5 ) {
			data.sort((a,b) => (a.dripScoreOptions.hiddenGem < b.dripScoreOptions.hiddenGem) ? 1 : ((b.dripScoreOptions.hiddenGem < a.dripScoreOptions.hiddenGem) ? -1 : 0));
		}
		return data;
	}
	else if ( val === 2 ) {
		data.sort((a,b) => (a.daysOnMarket > b.daysOnMarket) ? 1 : ((b.daysOnMarket > a.daysOnMarket) ? -1 : 0));
		const newData = [];
		const nullArray = [];
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			if ( element.daysOnMarket === null ) {
				nullArray.push(element);
			}
			else {
				newData.push(element);
			}
		}
		const finalArray = newData.concat(nullArray)
		return finalArray;
	}
	else if ( val === 3 ) {
		data.sort((a,b) => (a.daysOnMarket < b.daysOnMarket) ? 1 : ((b.daysOnMarket < a.daysOnMarket) ? -1 : 0));
		const newData = [];
		const nullArray = [];
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			if ( element.daysOnMarket === null ) {
				nullArray.push(element);
			}
			else {
				newData.push(element);
			}
		}
		const finalArray = newData.concat(nullArray)
		return finalArray;
	}
	else if ( val === 4 ) {
		data.sort((a,b) => (a.financials.cashOnCash < b.financials.cashOnCash) ? 1 : ((b.financials.cashOnCash < a.financials.cashOnCash) ? -1 : 0));
		return data;
	}
	else if ( val === 5 ) {
		data.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
		return data;
	}
	else if ( val === 6 ) {
		data.sort((a,b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0));
		return data;
	}
	else if ( val === 7 ) {
		data.sort((a,b) => (a.arvSpread < b.arvSpread) ? 1 : ((b.arvSpread < a.arvSpread) ? -1 : 0));
		return data;
	}
};

export const checkHighlights = (array) => {
	const activeIndex = array.findIndex(e => e.active === true);
	if ( activeIndex !== -1 ) {
		return true;
	}
	else {
		return false;
	}
};

export const checkNeighbourhoodInfoAvailable = (neighbourhoodData, property) => {
	if ( neighbourhoodData.length === 0 || property.length === 0 ) {
		return false;
	}
	const neighbourhoodIndex = neighbourhoodData.findIndex(e => e.region.tractId === property.censusTract);
    const chosenTract = neighbourhoodIndex !== -1 ? true : false;
	return chosenTract;
};

export const isOdd = (num) => { 
	return num % 2;
};

export const sortByPage = async(props, page) => {
	const lowerBound = (page - 1) * 20;
	const upperBound = 20 * page;
	const propsSegmentedByPage = props.slice(lowerBound, upperBound);
	let mapLowerBound;

	if ( isOdd(page) === 1 ) {
		mapLowerBound = (page - 1) * 20;
	}
	else {
		mapLowerBound = (page - 2) * 20;
	}
	const mapUpperBound = mapLowerBound + 40;
	const propsSegmentedByMapPage = props.slice(mapLowerBound, mapUpperBound);
	const res = {
		page: propsSegmentedByPage,
		map: propsSegmentedByMapPage
	};
	return res;
};

export const saveCompData = async(compData, userId, zpid) => {
	return new Promise(async resolve => {
		const colRef = "Users";
		const subColRef = "V4 Properties";
		const subDocRef = `${zpid}`;
		const saveData = await setMergeSubData(colRef, userId, subColRef, subDocRef, compData);
		resolve(saveData);
	});
};

export const checkSavedRehabNumber = async(userSettings, propertyObject, cityId, propertySearch) => {
	if ( propertySearch === false && propertyObject.livingArea !== null && propertyObject.fixerUpper === true ) {
		if ( userSettings.customCities.length !== 0 ) {
			const checkCustomCity = userSettings.customCities.findIndex(e => e.msaCode === cityId);
			if ( checkCustomCity !== -1 ) {
				const citySettings = userSettings.customCities[checkCustomCity].settings;
				const rehabIndex = citySettings.findIndex(e => e.type === "Rehab");
				if ( rehabIndex !== -1 ) {
					const rehabCost = Number(citySettings[rehabIndex].value);
					const newTotalCost = rehabCost * propertyObject.livingArea;
					const values = {
						totalCost: newTotalCost,
						costPerSqFoot: rehabCost,
						default: rehabCost
					};
					return values;
				}
				else {
					return await writeDefaultRehabValues(userSettings, propertyObject);
				}
			}
			else {
				return await writeDefaultRehabValues(userSettings, propertyObject);
			}
		}
		else {
			return await writeDefaultRehabValues(userSettings, propertyObject);
		}
	}
	else {
		const values = {
			totalCost: 0,
			costPerSqFoot: 0,
			default: 0
		};
		return values;
	}
};

async function writeDefaultRehabValues(userSettings, propertyObject) {
	if ( userSettings.rehabCosts !== undefined ) {
		if ( userSettings.rehabCosts.default === false ) {
			const costPerSqFoot = Number(userSettings.rehabCosts.value);
			const newTotalCost = costPerSqFoot * propertyObject.livingArea;
			const values = {
				totalCost: newTotalCost,
				costPerSqFoot: costPerSqFoot,
				default: costPerSqFoot
			};
			return values;
		}
		else {
			const values = {
				totalCost: 0,
				costPerSqFoot: 0,
				default: 0
			};
			return values;
		}
	}
	else {
		const values = {
			totalCost: 0,
			costPerSqFoot: 0,
			default: 0
		};
		return values;
	}
}

export const recalculateProfits = async(
	editedFinancials, 
	editedProperty,
	newPrice,
	totalRehabCost,
	setHighLevelProfits,
	downPayment,
	property,
	setRecurringCosts,
	firstCheck,
	setSpreadsheetProperty
) => {
	const closingCostsPercentage = editedFinancials === "" ? 0.03 : editedProperty.closingCosts / 100;
	const downPaymentPercentage = downPayment / 100;
	const downPaymentCost = (newPrice * downPaymentPercentage);
	const closingCosts = closingCostsPercentage * newPrice;
	const newInitialCosts = downPaymentCost + closingCosts;

	const newCosts = [];
	const newProfits = [];
	let monthlyProfit = 0;
	for (let i = 0; i < editedFinancials.length; i++) {

		if ( editedFinancials[i].loss === false ) {
			monthlyProfit = monthlyProfit + Number(editedFinancials[i].value);
			newProfits.push(editedFinancials[i]);
		}
		else if ( editedFinancials[i].label === "Mortgage payment" && firstCheck === false ) {
			const newMortgage = await calculateMortgagePayment(newPrice, downPayment, editedProperty.interestRate, 30);
			monthlyProfit = monthlyProfit - newMortgage;
			editedFinancials[i].value = newMortgage.toFixed(2);
			newCosts.push(editedFinancials[i]);
		}
		else {
			monthlyProfit = monthlyProfit - Number(editedFinancials[i].value);
			newCosts.push(editedFinancials[i]);
		}
	}

	const newCostsWithRenovation = newInitialCosts + totalRehabCost;
	const newCashOnCash = await calculateCashOnCash(monthlyProfit, newCostsWithRenovation);

	setRecurringCosts(newCosts);
	const profitObject = {
		monthlyProfit: monthlyProfit,
		cashOnCash: newCashOnCash,
		totalCosts: newCostsWithRenovation,
		cocrRange: typeof(newCashOnCash) === "string" ? true : false
	};
	setHighLevelProfits(profitObject);

	const cloneProperty = structuredClone(property);
	cloneProperty.price = newPrice;
	cloneProperty.financials.monthlyProfit = monthlyProfit;
	cloneProperty.financials.cashOnCash = newCashOnCash;
	cloneProperty.financials.rent = Number(newProfits[0].value);
	cloneProperty.financials.otherIncome = Number(newProfits[1].value);
	cloneProperty.financials.mortgagePayment = Number(newCosts[0].value);
	cloneProperty.financials.hoaFees = Number(newCosts[1].value);
	cloneProperty.financials.insurance = Number(newCosts[2].value);
	cloneProperty.financials.propertyTaxes = Number(newCosts[3].value);
	cloneProperty.financials.vacancyRateAllocation = Number(newCosts[4].value);
	cloneProperty.financials.managementFee = Number(newCosts[5].value);
	cloneProperty.financials.maintenanceFee = Number(newCosts[6].value);
	cloneProperty.financials.otherCosts = Number(newCosts[7].value);
	cloneProperty.financials.totalRehabCost = totalRehabCost;

	cloneProperty.mortgage30us = editedProperty.interestRate;
	cloneProperty.purchaseCosts.closingCostRate = editedProperty.closingCosts / 100;

	setSpreadsheetProperty(cloneProperty);
};

export const findNearbyProperties = async(centrePoint, kmDistance, allProperties) => {
	const nearbyProperties = [];
	for (let index = 0; index < allProperties.length; index++) {
		const element = allProperties[index];
		const latitude = element.latitude;
		const longitude = element.longitude;
		const propLocation = {
			latitude: latitude,
			longitude: longitude
		}

		const distance = await calcCrow(centrePoint, propLocation);
		if ( distance < kmDistance ) {
			nearbyProperties.push(element);
		}
	};
	return nearbyProperties;
};

export const containsNumbers = (str) => {
	return /[0-9]/.test(str);
};

export const queryFinancialPreferences = async(userId) => {
	return new Promise(async resolve => {
		const colRef = "Financial Preferences";
		if ( userId === "" || userId === null ) {
			return;
		}
		const queryData = await getDocument(colRef, userId);
		if ( queryData.status === 200 ) {
			const data = queryData.data.data;
			const res = {
				status: 200,
				data: data
			};
			resolve(res);
		}
		else {
			const res = {
				status: 200,
				data: defaultUserData.settings
			};
			resolve(res);
		}
	});
};

export const checkCardPosition = (item, mapRef) => {
	if ( mapRef !== null ) {
		const lat = mapRef.getCenter().lat();

		const zoomRate = mapRef.zoom;
		const zoomAdditions = {
			0: 0.16,
			1: 0.16,
			2: 0.16,
			3: 0.16,
			4: 0.16,
			5: 0.16,
			6: 7.68,
			7: 3.84,
			8: 1.92,
			9: 0.96,
			10: 0.48,
			11: 0.24,
			12: 0.12,
			13: 0.06,
			14: 0.03,
			15: 0.015,
			16: 0.0075,
			17: 0.00375,
			18: 0.001875
		};
		const latAddition = zoomAdditions[zoomRate] === undefined ? 0.16 : zoomAdditions[zoomRate];

		const extraTopMargin = latAddition;
		const extraBottomMargin = latAddition / 8;

		const cardObject = { 
			lat: item.latitude < lat ? item.latitude + extraTopMargin : item.latitude - extraBottomMargin,
			lng: item.longitude 
		};
		return cardObject;
	}
	else {
		const cardObject = { 
			lat: item.latitude,
			lng: item.longitude 
		};
		return cardObject;
	}
};

export const checkSmallCardPosition = (item, mapRef) => {
	if ( mapRef !== null ) {
		const lat = mapRef.getCenter().lat();

		const zoomRate = mapRef.zoom;
		const zoomAdditions = {
			0: 0.16,
			1: 0.16,
			2: 0.16,
			3: 0.16,
			4: 0.16,
			5: 0.16,
			6: 5.44,
			7: 2.72,
			8: 1.36,
			9: 0.68,
			10: 0.34,
			11: 0.17,
			12: 0.085,
			13: 0.0425,
			14: 0.02125,
			15: 0.010625,
			16: 0.0053125,
			17: 0.00265625,
			18: 0.001328125
		};
		const latAddition = zoomAdditions[zoomRate] === undefined ? 0.16 : zoomAdditions[zoomRate];

		const extraTopMargin = latAddition;
		const extraBottomMargin = latAddition / 8;

		const cardObject = { 
			lat: item.latitude < lat ? item.latitude + extraTopMargin : item.latitude - extraBottomMargin,
			lng: item.longitude 
		};
		return cardObject;
	}
	else {
		const cardObject = { 
			lat: item.latitude,
			lng: item.longitude 
		};
		return cardObject;
	}
};

export const checkPropertySearchCredits = async(item, setNoCredits, setCardClick) => {
	const user = await checkUserId();
	if ( user.status === 200 ) {
		const userId = user.userId;
		const colRef = "Property Searches";
		const encodedAddress = encodeURIComponent(item.description);
		const getPropertySearches = await getDocument(colRef, userId);
		
		let credits = 5;
		if ( getPropertySearches.status === 200 ) {
			const data = getPropertySearches.data.data;
			credits = data.credits;
		}

		if ( credits === 0 ) {
			if ( setNoCredits !== null ) {
				setNoCredits(true);	
			}
			return "no credits";
		}

		item.value = {
			terms: item.terms
		};
		item.label = item.description;
		const saveSearch = await writeSearch(colRef, userId, item, encodedAddress, credits);
		if ( saveSearch.status === 200 ) {
			await openPropertySearch(item, setCardClick);
		}
	}
};

export const writeSearch = async(colRef, docRef, place, encodedAddress, credits) => {
	const userData = {
		searches: [],
		credits: 5
	};
	const queryData = await getDocument(colRef, docRef);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;

		if ( colRef === "Property Searches" ) {
			const checkedSearches = data.searches === undefined ? [] : data.searches;
			for (let index = 0; index < checkedSearches.length; index++) {
				const element = checkedSearches[index];
				if ( element.zpid !== undefined ) {
					userData.searches.push(element);
				}
				else {
					credits = credits + 1;
				}
			}
		}
		else if ( colRef === "IP Addresses" ) {
			userData.id = data.id;
			userData.ipAddress = data.ipAddress;
			userData.userLocation = data.userLocation;
			const checkedSearches = data.searches;
			for (let index = 0; index < checkedSearches.length; index++) {
				const element = checkedSearches[index];
				if ( element.zpid !== undefined ) {
					userData.searches.push(element);
				}
				else {
					credits = credits + 1;
				}
			}
		}
		userData.credits = credits;
	};
	
	const addressIndex = userData.searches.findIndex(e => e.address === place.label);
	if ( addressIndex === -1 ) {
		const terms = place.value.terms;
		let streetAddress = "";
		let city = "";
		let state = "";
		for (let index = 0; index < terms.length; index++) {
			const element = terms[index];
			const value = element.value;
			if ( index < 2 ) {
				streetAddress += value + " ";
			}
			else if ( index === 2 ) {
				city = value;
			}
			else if ( index === 3 ) {
				state = value;
			}
		}

		const newSearch = {
			address: place.label,
			brokenDownAddress: {
				streetAddress: streetAddress,
				city: city,
				state: state
			},
			encodedAddress: encodedAddress,
			date: new Date()
		};
		userData.searches.push(newSearch);
		userData.credits = credits - 1;

		const writeData = await setData(colRef, docRef, userData);
		if ( writeData.status === 200 ) {
			const res = {
				status: 200,
				preSaved: false,
				creditRemoved: true
			};
			return res;
		}
	}
	else {
		const res = {
			status: 200,
			preSaved: true,
			creditRemoved: false
		};
		return res;
	}
};

export const changePartnerPropertyStatus = async(val, partnerData, navigate) => {
	const colRef = "Partners";
	const user = await checkUserId();
	if ( user.status === 200 ) {
		const docRef = user.userId;
		const queryData = await getDocument(colRef, docRef);
		if ( queryData.status === 200 ) {
			const data = queryData.data.data;
			const propertyListings = data.listings;
			const index = propertyListings.findIndex(e => e.zpid === val.zpid);
			if ( index !== -1 ) {
				data.listings[index].status = val.status;
			}
			await setData(colRef, docRef, data);

			if ( val.status === "sold" ) {
				navigate("/partner/sold", {
					state: {
						property: val,
						partnerData: partnerData,
						amendments: propertyListings.length === 0 ? null : propertyListings[index].amendments
					}
				});
			}
		}
	};
};

export const checkPayingCustomerLoggedIn = async(data) => {
	const userSubscriptions = data.subscriptions;
	if ( userSubscriptions !== undefined && userSubscriptions.length !== 0 ) {

		const subscriptionLength = userSubscriptions.length - 1;
		const finalSubscription = userSubscriptions[subscriptionLength];
		const endDate = finalSubscription.endDate.seconds;
		const today = new Date();
		const todaySeconds = today.getTime() / 1000;

		if ( endDate > todaySeconds ) {
			return true;
		}
		else {
			return false;
		}
	}
	else {
		return false;
	}
};

export const checkPayingCustomer = async() => {
	const colRef = "Subscriptions";
	const getUserId = await checkOnAuthStateChanged();
	if ( getUserId.status === 200 ) {
		const docRef = getUserId.userId;
		const query = await getDocument(colRef, docRef);
		if ( query.status === 200 ) {
			const userSubscriptions = query.data.data;
			if ( userSubscriptions !== undefined && userSubscriptions.length !== 0 ) {
				let paid = false;
				const today = new Date();
				const todaySeconds = today.getTime() / 1000;
				for (let index = 0; index < userSubscriptions.length; index++) {
					const element = userSubscriptions[index];
					const endDate = element.endDate.seconds;
					if ( endDate > todaySeconds ) {
						paid = true;
						break;
					};
				}

				return paid;
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	}
	else {
		return false;
	}
};

export const checkUserDetails = async() => {
	const colRef = "Users";
	return new Promise(async resolve => {
		const getUserId = await checkOnAuthStateChanged();
		if ( getUserId.status === 200 ) {
			const docRef = getUserId.userId;
			const query = await getDocument(colRef, docRef);
			if ( query.status === 200 ) {
				const res = {
					status: 200,
					data: query.data.data
				};
				resolve(res);
			}
			else {
				const res = {
					status: 400,
					data: []
				};
				resolve(res);
			}
		}
		else {
			const res = {
				status: 400,
				data: []
			};
			resolve(res);
		}
	});
};

export const changeBuyBoxFrequency = (frequency, id) => {
	return new Promise(async resolve => {
		const colRef = "Buy Boxes V4";
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			const queryDocument = await getDocument(colRef, userId);
			if ( queryDocument.status === 200 ) {
				const data = queryDocument.data.data;
				const buyBoxIndex = data.findIndex(e => e.id === id);
				if ( buyBoxIndex !== -1 ) {
					data[buyBoxIndex].frequency = frequency;
				}
				await setData(colRef, userId, data);
				const success = {
					status: 200,
					data: data
				};
				resolve(success);
			}
			else {
				const error = {
					status: 404
				};
				resolve(error);
			}
		}
		else {
			const error = {
				status: 400
			};
			resolve(error);
		}
	});
};

export const changeBuyBoxName = (id, name) => {
	return new Promise(async resolve => {
		const colRef = "Buy Boxes V4";
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			const queryDocument = await getDocument(colRef, userId);
			if ( queryDocument.status === 200 ) {
				const data = queryDocument.data.data;
				const buyBoxIndex = data.findIndex(e => e.id === id);
				if ( buyBoxIndex !== -1 ) {
					data[buyBoxIndex].title = name;
				}
				await setData(colRef, userId, data);
				const success = {
					status: 200,
					data: data
				};
				resolve(success);
			}
			else {
				const error = {
					status: 404
				};
				resolve(error);
			}
		}
		else {
			const error = {
				status: 400
			};
			resolve(error);
		}
	});
};

export const changeBuyBoxStrategy = (item, val) => {
	const id = item.id;
	const cityId = item.metroArea === true ? item.msaCode : item.cityId;
	return new Promise(async resolve => {
		const colRef = "Buy Boxes";
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			const queryDocument = await getDocument(colRef, userId);
			if ( queryDocument.status === 200 ) {
				let data = queryDocument.data.data;
				const cityIndex = data.findIndex(e => (e.metroArea === true && e.msaCode === cityId) || (e.metroArea !== true && e.cityId === cityId));
				if ( cityIndex !== -1 ) {
					const buyBox = data[cityIndex].buyBoxes;
					const buyBoxIndex = buyBox.findIndex(e => e.id === id);
					if ( buyBoxIndex !== -1 ) {
						data[cityIndex].buyBoxes[buyBoxIndex].strategy = val;
					}
				}
				await setData(colRef, userId, data);
				const success = {
					status: 200,
					data: data
				};
				resolve(success);
			}
			else {
				const error = {
					status: 404
				};
				resolve(error);
			}
		}
		else {
			const error = {
				status: 400
			};
			resolve(error);
		}
	});
};

export const deleteBuyBoxFrequency = (item) => {
	return new Promise(async resolve => {
		const colRef = "Buy Boxes";
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			const queryDocument = await getDocument(colRef, userId);
			if ( queryDocument.status === 200 ) {
				let data = queryDocument.data.data;
				let cityIndex = -1;
				if ( item.metroArea === true ) {
					cityIndex = data.findIndex(e => e.msaCode === item.msaCode);
				}
				else {
					cityIndex = data.findIndex(e => e.cityId === item.cityId);
				}
				if ( cityIndex !== -1 ) {
					const buyBox = data[cityIndex].buyBoxes;
					const buyBoxIndex = buyBox.findIndex(e => e.id === item.id);
					if ( buyBoxIndex !== -1 ) {
						data[cityIndex].buyBoxes.splice(buyBoxIndex, 1);
					}
				}
				await setData(colRef, userId, data);
				const success = {
					status: 200,
					data: data
				};
				resolve(success);
			}
			else {
				const error = {
					status: 404
				};
				resolve(error);
			}
		}
		else {
			const error = {
				status: 400
			};
			resolve(error);
		}
	});
};

export const deleteBuyBoxFunc = (id) => {
	return new Promise(async resolve => {
		const colRef = "Buy Boxes V4";
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			const queryDocument = await getDocument(colRef, userId);
			if ( queryDocument.status === 200 ) {
				const data = queryDocument.data.data;
				const buyBoxIndex = data.findIndex(e => e.id === id);
				if ( buyBoxIndex !== -1 ) {
					data.splice(buyBoxIndex, 1);
				}

				await setData(colRef, userId, data);
				await recordEvent("Buy Box Deleted", {
					id: id
				});
				const success = {
					status: 200,
					data: data
				};
				resolve(success);
			}
			else {
				const error = {
					status: 404
				};
				resolve(error);
			}
		}
		else {
			const error = {
				status: 400
			};
			resolve(error);
		}
	});
};

export const mixpanelDataSetup = async(vals) => {
	const name = vals.name;
	const email = vals.email;
	const userId = vals.userId;
	const subscriptions = vals.subscriptions;
	const user = vals.user;
	const userFreeTrial = vals.userFreeTrial;
	const todaySeconds = vals.todaySeconds;
	const emailVerified = vals.emailVerified;

	const activeSubscriptions = subscriptions.filter(e => e.endDate.seconds > todaySeconds);
	const payingCustomer = activeSubscriptions.length === 0 ? false : true;

	const creativeIndex = activeSubscriptions.length === 0 ? -1 : activeSubscriptions.findIndex(e => e.creative === true);
	const creativeSubscription = creativeIndex === -1 ? false : true;

	const earliestSubscription = activeSubscriptions.length === 0 ? null : activeSubscriptions.reduce((earliest, sub) => (earliest.startDate.seconds < sub.startDate.seconds ? earliest : sub), activeSubscriptions[0]);
	const furthestSubscription = activeSubscriptions.length === 0 ? null : activeSubscriptions.reduce((furthest, sub) => (furthest.endDate.seconds > sub.endDate.seconds ? furthest : sub), activeSubscriptions[0]);
	
	// Convert timestamps into ISO strings
	const startDate = activeSubscriptions.length === 0 ? null : new Date(earliestSubscription.startDate.seconds * 1000).toISOString();
	const endDate = activeSubscriptions.length === 0 ? null : new Date(furthestSubscription.endDate.seconds * 1000).toISOString();
	
	const freeTrialStartDate = userFreeTrial !== undefined ? new Date(userFreeTrial[0].startDate.seconds * 1000).toISOString() : null;
	const freeTrialEndDate = userFreeTrial !== undefined ? new Date(userFreeTrial[0].endDate.seconds * 1000).toISOString() : null;

	const userCreated = new Date(Number(user.user.metadata.createdAt)).toISOString();

	const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
	const isAdmin = adminIds.includes(userId);

	const mpVals = {
		name: name,
		email: email,
		userId: userId,
		payingCustomer: payingCustomer,
		freeTrialStartDate: freeTrialStartDate,
		freeTrialEndDate: freeTrialEndDate,
		userCreated: userCreated,
		admin: isAdmin,
		emailVerified: emailVerified
	};

	if ( payingCustomer === true ) {
		const subscriptionType = creativeSubscription === true ? "creative" : "traditional";
		mpVals.subscriptionType = subscriptionType;
		mpVals.subscriptionStartDate = startDate;
		mpVals.subscriptionEndDate = endDate;

		const autoBilling = furthestSubscription.autoBilling === undefined ? false : furthestSubscription.autoBilling;
		mpVals.autoBilling = autoBilling;

		const autoBillingCancelled = furthestSubscription.cancelled === undefined ? false : furthestSubscription.cancelled;
		
		if ( autoBilling === true || autoBillingCancelled === true ) {
			mpVals.nextRenewalDate = endDate;
		}
		else {
			mpVals.nextRenewalDate = null;
		}

		mpVals.annualPlan = furthestSubscription.price < 200 ? false : true;
		mpVals.monthlyPlan = furthestSubscription.price < 200 ? true : false;
	}

	await recordPerson(mpVals);
};

export const recordPerson = (vals) => {
	const name = vals.name;
	const email = vals.email;
	const userId = vals.userId;
	const payingCustomer = vals.payingCustomer;
	const subscriptionType = vals.subscriptionType;
	const subscriptionStartDate = vals.subscriptionStartDate;
	const subscriptionEndDate = vals.subscriptionEndDate;
	const admin = vals.admin;
	const annualPlan = vals.annualPlan;
	const monthlyPlan = vals.monthlyPlan;
	const autoBilling = vals.autoBilling;
	const nextRenewalDate = vals.nextRenewalDate;
	const emailVerified = vals.emailVerified;

	const freeTrialStart = vals.freeTrialStartDate;
	const freeTrialEnd = vals.freeTrialEndDate;
	const userCreated = vals.userCreated;

	const userObject = {
		'$name': name,
		'$email': email,
		'$distinct_id': userId,
		'Paying Customer': payingCustomer,
		'Admin': admin,
		'Free Trial Start': freeTrialStart,
		'Free Trial End': freeTrialEnd,
		'User Created': userCreated,
		'Email Verified': emailVerified
	}

	if ( payingCustomer === true ) {
		userObject['Subscription Type'] = subscriptionType;
		userObject['Subscription Start'] = subscriptionStartDate;
		userObject['Subscription End'] = subscriptionEndDate;
		userObject['Annual Plan'] = annualPlan;
		userObject['Monthly Plan'] = monthlyPlan;
		userObject['Recurring Subscription'] = autoBilling;

		if ( nextRenewalDate !== null ) {
			userObject['Next Renewal'] = nextRenewalDate;
		}
	}

	mixpanel.register({
		'Email': email,
		'Name': name
	});
	mixpanel.identify(userId);
	mixpanel.people.set(userObject);

	FullStory.identify(userId, {
		displayName: name,
		email: email,
		uid: userId
	});

	posthog?.identify(userId, {
		email: email,
		name: name
	});

	const tagManagerArgs = {
		dataLayer: {
			userId: userId,
			email: email,
			name: name
		}
	};
	TagManager.dataLayer(tagManagerArgs);
};

export const recordEvent = (name, fields) => {
	mixpanel.track(name, fields);
	FullStory.event(name, fields);
	posthog.capture(name, fields);
};

export const trackingProfileUpdate = async(vals) => {
	mixpanel.people.set(vals);
};

export const cityListingsQuery = async(
	setListingsLoading,
	propertyTypes,
	state,
	budgetRange,
	bedrooms,
	bathrooms,
	squareFootage,
	constructionYears,
	setTotalListings,
	setFilteredListings,
	setState
) => {
	setListingsLoading(true);
	let selectedPropertyTypes = 2;
	if ( propertyTypes[0].included === true && propertyTypes[1].included === true ) {
		selectedPropertyTypes = 2;
	}
	else if ( propertyTypes[0].included === true ) {
		selectedPropertyTypes = 1;
	}
	else {
		selectedPropertyTypes = 0;
	}

	const chosenCity = state.chosenCity;
	const cityId = chosenCity.cityId;
	const params = {
		city: chosenCity.city,
		state: chosenCity.state,
		returnCityId: cityId === undefined ? "True" : "False",
		homeType: selectedPropertyTypes,
		minPrice: budgetRange[0],
		maxPrice: budgetRange[1],
		minBeds: bedrooms,
		maxBeds: 10,
		minBaths: bathrooms,
		maxBaths: 10,
		minSqft: squareFootage[0],
		maxSqft: squareFootage[1],
		minYearBuilt: constructionYears[0] === "" ? "None" : constructionYears[0],
		maxYearBuilt: constructionYears[1] === "" ? "None" : constructionYears[1],
		daysOnMarket: "None",
		isForSaleForeclosure: "None",
		isNewConstruction: "None",
		isAuction: "None",
	};

	const getCityParams = await getCitySearch(params, true);
	const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
	const gatewayData = {
		type: "gateway",
		resourceId: "citySearchLive",
		queryString: getCityParams
	};
	const queryData = await cloudFunctionV2(gatewayURL, gatewayData);
	if ( queryData.status === 200 ) {
		const body = queryData.body;
		const filterCount = body.totalFilterResultCount;
		const totalCount = body.totalPropertyCount;
		setTotalListings(totalCount);
		setFilteredListings(filterCount);
		if ( body.cityId !== null && body.cityId !== undefined ) {
			state.chosenCity.cityId = body.cityId;
			setState(state);
		}
		else if ( cityId === undefined || cityId === null ) {
			const cityIdErrorPage = "Buy Box Setup - City Listings Query";
			const apiResponse = `City ID returned valued: ${body.cityId}`;
            await sendCityIDErrorEmail(chosenCity.city, chosenCity.state, cityIdErrorPage, apiResponse);
		}
	}
	setListingsLoading(false);
};

export const saveSearchParams = (label, val, searchParams, setSearchParams) => {
	let sortingParam;
	let offMarketParam;
	let searchQueryStateParam;
	let pageParam;
	let propertyTypeParam;
	let budgetParam;
	let propertySpecificsParam;
	let valueAddParam;
	let moreParam;
	
	if ( typeof(label) === "string" ) {
		sortingParam = label === "sort" ? val : searchParams.get('sort');
		offMarketParam = label === "offMarket" ? val : searchParams.get('offMarket');
		searchQueryStateParam = label === "searchQueryState" ? val : searchParams.get('searchQueryState');
		pageParam = label === "page" ? val : searchParams.get('page');
		propertyTypeParam = label === "propertyType" ? val : searchParams.get('propertyType');
		budgetParam = label === "budgetRange" ? val : searchParams.get('budgetRange');
		propertySpecificsParam = label === "propertySpecifics" ? val : searchParams.get('propertySpecifics');
		valueAddParam = label === "valueAdd" ? val : searchParams.get('valueAdd');
		moreParam = label === "more" ? val : searchParams.get('more');
	}
	else if ( Array.isArray(val) === true ) {
		for (let index = 0; index < label.length; index++) {
			const element = label[index];
			sortingParam = element === "sort" ? val[index] : sortingParam === undefined && label.indexOf("sort") === -1 ? searchParams.get('sort') : sortingParam;
			offMarketParam = element === "offMarket" ? val[index] : offMarketParam === undefined && label.indexOf("offMarket") === -1 ? searchParams.get('offMarket') : offMarketParam;
			searchQueryStateParam = element === "searchQueryState" ? val[index] : searchQueryStateParam === undefined && label.indexOf("searchQueryState") === -1 ? searchParams.get('searchQueryState') : searchQueryStateParam;
			pageParam = element === "page" ? val[index] : pageParam === undefined && label.indexOf("page") === -1 ? searchParams.get('page') : pageParam;
			propertyTypeParam = element === "propertyType" ? val[index] : propertyTypeParam === undefined && label.indexOf("propertyType") === -1 ? searchParams.get('propertyType') : propertyTypeParam;
			budgetParam = element === "budgetRange" ? val[index] : budgetParam === undefined && label.indexOf("budgetRange") === -1 ? searchParams.get('budgetRange') : budgetParam;
			propertySpecificsParam = element === "propertySpecifics" ? val[index] : propertySpecificsParam === undefined && label.indexOf("propertySpecifics") === -1 ? searchParams.get('propertySpecifics') : propertySpecificsParam;
			valueAddParam = element === "valueAdd" ? val[index] : valueAddParam === undefined && label.indexOf("valueAdd") === -1 ? searchParams.get('valueAdd') : valueAddParam;
			moreParam = element === "more" ? val[index] : moreParam === undefined && label.indexOf("more") === -1 ? searchParams.get('more') : moreParam;
		}
	}

	const newObject = {
		sort: sortingParam,
		page: pageParam,
		offMarket: offMarketParam,
		searchQueryState: searchQueryStateParam,
		propertyType: propertyTypeParam,
		budgetRange: budgetParam,
		propertySpecifics: propertySpecificsParam,
		valueAdd: valueAddParam,
		more: moreParam,
	}

	if ( sortingParam === null ) {
		delete newObject.sort;
	}
	if ( pageParam === null ) {
		delete newObject.page;
	}
	if ( offMarketParam === null ) {
		delete newObject.offMarket;
	}
	if ( searchQueryStateParam === null ) {
		delete newObject.searchQueryState;
	}
	if ( offMarketParam === null ) {
		delete newObject.offMarket;
	}
	if ( propertyTypeParam === null ) {
		delete newObject.propertyType;
	}
	if ( budgetParam === null ) {
		delete newObject.budgetRange;
	}
	if ( propertySpecificsParam === null ) {
		delete newObject.propertySpecifics;
	}
	if ( valueAddParam === null ) {
		delete newObject.valueAdd;
	}
	if ( moreParam === null ) {
		delete newObject.more;
	}

	setSearchParams(newObject);
	return true;
};

export const resetFilterType = (
	index,
	setPropertyTypes, 
	setUnits,
	setBudgetRange,
	setBedrooms,
	setBathrooms,
	setSquareFootage,
	setConstructionYears,
	setOnlyADU,
	setOnlyExtraBedroom,
	setHideFixerUppers,
	setOnlyFixerUppers,
	setPriceReduction,
	setPriceReductionIndex,
	setOnlySellerFinancing,
	setFixerUpperOption,
	setFsbo,
	setNeighbourhoodGradeMin,
	setNeighbourhoodGradeMax
) => {
	if ( index === 0 ) {
		setPropertyTypes([
			{property: "Multi-Family", code: "MultiFamily", abbreviation: "MFH", included: true},
			{property: "Single-Family", code: "SingleFamily", abbreviation: "SFH", included: true}
		]);
		setUnits(1);
	}
	else if ( index === 1 ) {
		setBudgetRange([
			0, 1000000000
		]);
	}
	else if ( index === 2 ) {
		setBedrooms(1);
		setBathrooms(1);
		setSquareFootage([0, 999999]);
		setConstructionYears(["", ""]);
	}
	else if ( index === 3 ) {
		setOnlyADU(false);
		setOnlyExtraBedroom(false);
		setHideFixerUppers(false);
		setOnlyFixerUppers(false);
		setFixerUpperOption("both");
	}
	else if ( index === 4 ) {
		setPriceReduction(false);
		setPriceReductionIndex(0);
		setOnlySellerFinancing(false);
		setFsbo(0);
		setNeighbourhoodGradeMin(1);
		setNeighbourhoodGradeMax(8);
	}
	else if ( index === "all" ) {
		setPropertyTypes([
			{property: "Multi-Family", code: "MultiFamily", abbreviation: "MFH", included: true},
			{property: "Single-Family", code: "SingleFamily", abbreviation: "SFH", included: true}
		]);
		setUnits(1);
		setBudgetRange([
			0, 1000000000
		]);
		setBedrooms(1);
		setBathrooms(1);
		setSquareFootage([0, 999999]);
		setConstructionYears(["", ""]);
		setOnlyADU(false);
		setOnlyExtraBedroom(false);
		setHideFixerUppers(false);
		setOnlyFixerUppers(false);
		setPriceReduction(false);
		setPriceReductionIndex(0);
		setOnlySellerFinancing(false);
		setFixerUpperOption("both");
		setFsbo(0);
		setNeighbourhoodGradeMin(1);
		setNeighbourhoodGradeMax(8);
	}
};

export const deleteSearchParam = (label, searchParams, setSearchParams) => {
	if ( searchParams.get(label) !== null ) {
		searchParams.delete(label);
		setSearchParams(searchParams);
	}

	return true;
}

export const saveNewFavourites = async(data, userId) => {
	const colRef = "Favourites";
	const favourites = [];
	for (let index = 0; index < data.length; index++) {
		const element = data[index];
		const params = {
			city: element.city,
			state: element.state,
			returnCityId: "True"
		}

		const getCityParams = await getCitySearch(params, false);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "citySearchLive",
			queryString: getCityParams
		};
		const getNewCity = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getNewCity.status === 200 ) {
			const cityId = getNewCity.body.cityId;
			const newFavouriteObject = {
				cityId: cityId,
				city: element.city,
				state: element.state,
				zpid: element.zpid,
			}
			favourites.push(newFavouriteObject);
		}
	};

	await setData(colRef, userId, favourites);
};

export const checkWhichAddCityPath = async(freeTrial, subscriptions) => {
	const allCitiesSubscriptionIndex = subscriptions.findIndex(e => e.allCities === true && e.endDate.seconds > new Date().getTime() / 1000);

	if ( freeTrial === true || allCitiesSubscriptionIndex !== -1 ) {
		const res = {
			path: "/home",
			state: null
		};
		return res;
	}
	else {
		const res = {
			path: "/upgrade-city",
			state: null
		};
		return res;
	}
};

export const addRecentMarket = async(item, userId, setRecentlyViewed) => {
	const colRef = "Recently Viewed";
	const queryData = await getDocument(colRef, userId);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		const index = data.findIndex(e => e.msaCode === item.msaCode);
		if ( index === -1 ) {
			const newObject = {
				msaCode: item.msaCode,
				msaTitle: item.msaTitle,
				icon: ""
			};
			data.unshift(newObject);
			await setData(colRef, userId, data);
		}
		else if (index > 0) {
			// Remove the object from its current position
			const [newItem] = data.splice(index, 1);
			
			// Add the object to the front of the array
			data.unshift(newItem);
			await setData(colRef, userId, data);
		}

		if ( setRecentlyViewed !== null ) {
			setRecentlyViewed(data);
		}
	}
	else {
		const newArray = [
			{
				msaCode: item.msaCode,
				msaTitle: item.msaTitle,
				icon: ""
			}
		];
		if ( setRecentlyViewed !== null ) {
			setRecentlyViewed(newArray);
		}
		await setData(colRef, userId, newArray);
	}
};

export const removeRecentMarket = async(item, userId, setRecentlyViewed) => {
	const colRef = "Recently Viewed";
	const queryData = await getDocument(colRef, userId);
	if ( queryData.status === 200 ) {
		const data = queryData.data.data;
		const index = data.findIndex(e => e.msaCode === item.msaCode);
		if ( index !== -1 ) {
			data.splice(index, 1);
			await setData(colRef, userId, data);
			if ( setRecentlyViewed !== null ) {
				setRecentlyViewed(data);
			}
		}
	}
};

export const checkGrandfatherSubscriptionStatus = async(userId) => {

	// User Types
	// 1. Free trial active, or done, or paid plan expired -> New free trial
	// 2. $100 paid plan currently active -> choose 5 cities
	// 3. $25 plan currently active -> Get your 1 city you previously paid for

	const collections = [
		"Users",
		"Subscriptions"
	];
	const queryData = await getUserDocuments(collections, userId);
	if ( queryData[0].status === 200 ) {
		const userData = queryData[0].data;
		const oldSubscriptionData = userData.subscriptions;
		const newSubscriptionData = queryData[1].status === 200 ? queryData[1].data : [];
		if ( oldSubscriptionData !== undefined && oldSubscriptionData.length !== 0 ) {
			const subscriptionLength = oldSubscriptionData.length - 1;
			const finalSubscription = oldSubscriptionData[subscriptionLength];
			const endDate = finalSubscription.endDate.seconds;
			const today = new Date();
			const todaySeconds = today.getTime() / 1000;
	
			if ( endDate > todaySeconds ) {
				const metadata = finalSubscription.metadata;
				if ( metadata.city === "" ) {
					// User had unlimited subscription
					const checkedCityCredits = userData.cityCredits === undefined ? 5 - newSubscriptionData.length : userData.cityCredits - newSubscriptionData.length;

					if ( checkedCityCredits > 0 ) {

						const res = {
							grandfather: true,
							subscriptions: newSubscriptionData,
							credits: checkedCityCredits
						}
						return res;
					}
					else {
						const res = {
							grandfather: false,
							subscriptions: newSubscriptionData,
							credits: 0
						}
						return res;
					}
				}
				else {
					const res = {
						grandfather: false,
						subscriptions: newSubscriptionData,
						credits: 0
					}
					return res;
				}
			}
			else {
				const res = {
					grandfather: false,
					subscriptions: newSubscriptionData,
					credits: 0
				}
				return res;
			}
		}
		else {
			const res = {
				grandfather: false,
				subscriptions: newSubscriptionData,
				credits: 0
			}
			return res;
		}
	}
	else {
		const res = {
			grandfather: false,
			subscriptions: [],
			credits: 0
		}
		return res;
	}
};

export const checkPriceFormatting = (price, startDate) => {
	if ( startDate < 1707816673 ) {
		return price / 100;
	}
	else {
		return price;
	}
};

export const saveMonthlyCosts = async(propertyObject, setRecurringCosts, exitStrategy, userSettings) => {
	let brrrrMortgage = "";
	let brrrrPropertyTax = "";
	let interestRate = "";

	if ( exitStrategy === "brrrr" ) {
		const loanType = Number(userSettings.loanType);
		const arv = propertyObject.arvComps === true ? propertyObject.arvCompsStats.arvPrice : propertyObject.price;
		const amountBorrowedInverse = 25;
		const checkInterestRate = userSettings.initialCosts[1];
		if ( checkInterestRate.value !== "" && checkInterestRate.default === false ) {
			interestRate = Number(checkInterestRate.value);
		}
		else {
			interestRate = propertyObject.mortgage30us;
		}

		const newMortgage = await calculateMortgagePayment(arv, amountBorrowedInverse, Number(interestRate), loanType);
		brrrrMortgage = Number(newMortgage.toFixed(2));
		const brrrrTaxRate = propertyObject.apiFinancialParams.propertyTaxRate;
		brrrrPropertyTax = (((brrrrTaxRate / 100) * arv) / 12).toFixed(2);
	}


	const newCosts = [
		{
			label: "Mortgage payment",
			value: exitStrategy === "brrrr" && brrrrMortgage !== "" ? brrrrMortgage : checkFinancialsInteger(propertyObject.financials.mortgagePayment),
			loss: true
		},
		{
			label: "HOA fees",
			value: propertyObject.financials.hoaFees === null ? 0 : checkFinancialsInteger(propertyObject.financials.hoaFees),
			loss: true
		},
		{
			label: "Insurance",
			value: checkFinancialsInteger(propertyObject.financials.insurance),
			loss: true
		},
		{
			label: "Property taxes",
			value: exitStrategy === "brrrr" && brrrrPropertyTax !== "" ? brrrrPropertyTax : checkFinancialsInteger(propertyObject.financials.propertyTaxes),
			loss: true
		},
		{
			label: "Vacancy",
			value: checkFinancialsInteger(propertyObject.financials.vacancyRateAllocation),
			loss: true
		},
		{
			label: "Management fee",
			value: checkFinancialsInteger(propertyObject.financials.managementFee),
			loss: true
		},
		{
			label: "Maintenance",
			value: checkFinancialsInteger(propertyObject.financials.maintenanceFee),
			loss: true
		},
		{
			label: "Other costs",
			value: propertyObject.financials.otherCosts === undefined ? 0 : checkFinancialsInteger(propertyObject.financials.otherCosts),
			loss: true
		}
	];

	setRecurringCosts(newCosts);
};

// Function for saving down payment and interest rate based on user settings
export const saveInitialCosts = (userSettings, propertyObject, setDownPayment, setInterestRate, setDefaultDownPayment, setDefaultInterestRate, exitStrategy) => {
	const mortgageCheck = userSettings.mortgage;
	const checkDownPayment = mortgageCheck === false ? {value: 100} : userSettings.initialCosts[0];
	if ( exitStrategy === "brrrr" ) {
		setDownPayment(100);

		if ( checkDownPayment.value !== "" ) {
			setDefaultDownPayment(Number(checkDownPayment.value));
		}
		else {
			setDefaultDownPayment(20);
		}
	}
	else if ( checkDownPayment.value !== "" ) {
		setDownPayment(Number(checkDownPayment.value));
		setDefaultDownPayment(Number(checkDownPayment.value));
	}
	else {
		setDownPayment(20);
		setDefaultDownPayment(20);
	}

	const checkInterestRate = userSettings.initialCosts[1];
	if ( checkInterestRate.value !== "" && checkInterestRate.default === false ) {
		setInterestRate(Number(checkInterestRate.value));
		setDefaultInterestRate(Number(checkInterestRate.value));
	}
	else {
		setInterestRate(propertyObject.mortgage30us);
		setDefaultInterestRate(propertyObject.mortgage30us);
	}
};

// Function for saving the default rehab cost per square foot
export const saveOtherCosts = async(userSettings, propertyObject, cityId, setDefaultCostPerSqFoot) => {
	const checkRehab = await checkUserSettingsOnRehab(userSettings, propertyObject, cityId);
	if ( checkRehab.change === true ) {
		setDefaultCostPerSqFoot(checkRehab.costPerSqFoot);
	}
};

export const saveSpreadsheetProperty = async(
	spreadsheetProperty, 
	setSpreadsheetProperty,
	newPrice,
	monthlyProfit,
	newCashOnCash,
	rent,
	monthlyCosts,
	totalRehabCost,
	uniqueUnitPropertyDetails,
	newInterestRate,
	newClosingPercentage
) => {
	const cloneProperty = structuredClone(spreadsheetProperty);
	cloneProperty.price = newPrice;
	cloneProperty.financials.monthlyProfit = monthlyProfit;
	cloneProperty.financials.cashOnCash = newCashOnCash;
	cloneProperty.financials.rent = Number(rent);
	cloneProperty.financials.otherIncome = 0;
	cloneProperty.financials.mortgagePayment = Number(monthlyCosts[0].inputs[0].value);
	cloneProperty.financials.hoaFees = Number(monthlyCosts[0].inputs[1].value);
	cloneProperty.financials.insurance = Number(monthlyCosts[0].inputs[2].value);
	cloneProperty.financials.propertyTaxes = Number(monthlyCosts[0].inputs[3].value);
	cloneProperty.financials.vacancyRateAllocation = Number(monthlyCosts[1].inputs[0].value);
	cloneProperty.financials.managementFee = Number(monthlyCosts[1].inputs[1].value);
	cloneProperty.financials.maintenanceFee = Number(monthlyCosts[2].inputs[0].value);
	cloneProperty.financials.otherCosts = Number(monthlyCosts[2].inputs[1].value);
	cloneProperty.financials.totalRehabCost = Number(totalRehabCost);
	if ( uniqueUnitPropertyDetails !== undefined ) {
		cloneProperty.uniqueUnitPropertyDetails = uniqueUnitPropertyDetails;
	}

	cloneProperty.mortgage30us = newInterestRate;
	cloneProperty.purchaseCosts.closingCostRate = newClosingPercentage / 100;

	setSpreadsheetProperty(cloneProperty);
};

export const savePropertyChanges = async(vals, zpid) => {
	const user = await checkUserId();
	if ( user.status === 200 ) {
		const colRef = "Users";
		const docRef = user.userId;
		const subColRef = "V4 Properties";
		const subDocRef = zpid;
		await setMergeSubData(colRef, docRef, subColRef, subDocRef, vals);
	}
};

export const checkUserSettingsOnCosts = (option, userSettings, cityId, index, subIndex, price, rent, arv) => {
	if ( index === 0 && subIndex < 2 ) {
		const res = {
			change: false,
			original: null,
			percentageVal: null
		};
		return res;
	}
	
	// I stupidly named Vacancy rate in three different way between normal settings, city-specific settings, and
	// the text inputs within the monthly costs object, so this accounts for that
	const optionLabel = option.label;
	const recurringCosts = userSettings.recurringCosts;
	const customCities = userSettings.customCities;
	const customCityIndex = customCities.findIndex(e => e.metroArea === true && e.msaCode === cityId);
	if ( customCityIndex !== -1 ) {
		const customCity = customCities[customCityIndex];
		const customCitySettings = customCity.settings;
		if ( customCitySettings.length > 0 ) {
			const citySettingIndex = customCitySettings.findIndex(e => e.label.includes(optionLabel));
			if ( citySettingIndex !== -1 ) {
				const citySetting = customCitySettings[citySettingIndex];
				if ( citySetting.value === "" ) {
					const res = {
						change: false,
						original: null,
						percentageVal: null
					};
					return res;
				}
				else if ( index === 0 ) {
					// calculate value relative to property price
					const citySettingValue = Number(citySetting.value);
					const original = (price * (citySettingValue / 100)) / 12;

					const res = {
						change: true,
						original: original,
						percentageVal: citySettingValue
					};

					if ( subIndex === 3 ) {
						// Calculate BRRRR original value
						const brrrrOriginal = (arv * (citySettingValue / 100)) / 12;
						res.brrrrOriginal = brrrrOriginal;
					}

					return res;
				}
				else {
					// calculate value relative to rent
					const citySettingValue = Number(citySetting.value);
					const original = rent * (citySettingValue / 100);

					const res = {
						change: true,
						original: original,
						percentageVal: citySettingValue
					}
					return res;
				}
			}
		}
	}
	
	const recurringCostIndex = recurringCosts.findIndex(e => e.label.includes(optionLabel));
	if ( recurringCostIndex !== -1 ) {
		const recurringCost = recurringCosts[recurringCostIndex];
		if ( recurringCost.default === true || recurringCost.value === "" ) {
			const res = {
				change: false,
				original: null,
				percentageVal: null
			};
			return res;
		}
		else if ( index === 0 ) {
			// calculate value relative to property price
			const settingValue = Number(recurringCost.value);
			const original = (price * (settingValue / 100)) / 12;
			const res = {
				change: true,
				original: original,
				percentageVal: settingValue
			};

			if ( subIndex === 3 ) {
				// Calculate BRRRR original value
				const brrrrOriginal = (arv * (settingValue / 100)) / 12;
				res.brrrrOriginal = brrrrOriginal;
			}

			return res;
		}
		else {
			// calculate value relative to rent
			const settingValue = Number(recurringCost.value);
			const original = rent * (settingValue / 100);
			const res = {
				change: true,
				original: original,
				percentageVal: settingValue
			}
			return res;
		}
	}
};

export const checkIndividualUserSettingsForCosts = (option, userSettings, cityId, priceBased, price, rent) => {
	const optionLabel = option.label;
	const recurringCosts = userSettings.recurringCosts;
	const customCities = userSettings.customCities;
	const customCityIndex = customCities.findIndex(e => e.metroArea === true && e.msaCode === cityId);
	if ( customCityIndex !== -1 ) {
		const customCity = customCities[customCityIndex];
		const customCitySettings = customCity.settings;
		if ( customCitySettings.length > 0 ) {
			const citySettingIndex = customCitySettings.findIndex(e => e.label.includes(optionLabel));
			if ( citySettingIndex !== -1 ) {
				const citySetting = customCitySettings[citySettingIndex];
				if ( citySetting.value === "" ) {
					const res = {
						change: false,
						original: null,
						percentageVal: null
					};
					return res;
				}
				else if ( priceBased === true ) {
					// calculate value relative to property price
					const citySettingValue = Number(citySetting.value);
					const original = (price * (citySettingValue / 100)) / 12;

					const res = {
						change: true,
						original: original,
						percentageVal: citySettingValue
					};
					return res;
				}
				else {
					// calculate value relative to rent
					const citySettingValue = Number(citySetting.value);
					const original = rent * (citySettingValue / 100);

					const res = {
						change: true,
						original: original,
						percentageVal: citySettingValue
					}
					return res;
				}
			}
		}
	}
	
	const recurringCostIndex = recurringCosts.findIndex(e => e.label.includes(optionLabel));
	if ( recurringCostIndex !== -1 ) {
		const recurringCost = recurringCosts[recurringCostIndex];
		if ( recurringCost.default === true || recurringCost.value === "" ) {
			const res = {
				change: false,
				original: null,
				percentageVal: null
			};
			return res;
		}
		else if ( priceBased === true ) {
			// calculate value relative to property price
			const settingValue = Number(recurringCost.value);
			const original = (price * (settingValue / 100)) / 12;
			const res = {
				change: true,
				original: original,
				percentageVal: settingValue
			};
			return res;
		}
		else {
			// calculate value relative to rent
			const settingValue = Number(recurringCost.value);
			const original = rent * (settingValue / 100);
			const res = {
				change: true,
				original: original,
				percentageVal: settingValue
			}
			return res;
		}
	}
};

export const checkUserSettingsOnRehab = (userSettings, propertyObject, cityId) => {
	if ( propertyObject.fixerUpper === false ) {
		const res = {
			change: false,
			costPerSqFoot: 0
		};
		return res;
	}
	
	const customCities = userSettings.customCities;
	const customCityIndex = customCities.findIndex(e => e.msaCode === cityId);
	if ( customCityIndex !== -1 ) {
		const customCity = customCities[customCityIndex];
		const customCitySettings = customCity.settings;
		if ( customCitySettings.length > 0 ) {
			const citySettingIndex = customCitySettings.findIndex(e => e.label === "Rehab");
			if ( citySettingIndex !== -1 ) {
				const citySetting = customCitySettings[citySettingIndex];
				if ( citySetting.value === "" || citySetting.default === true ) {
					const res = {
						change: false,
						costPerSqFoot: 0
					};
					return res;
				}
				else {
					const res = {
						change: true,
						costPerSqFoot: Number(citySetting.value)
					}
					return res;
				}
			}
		}
	}

	const rehabCosts = userSettings.rehabCosts;
	if ( rehabCosts.default === true || rehabCosts.value === "" ) {
		const res = {
			change: false,
			costPerSqFoot: 0
		};
		return res;
	}
	else {
		const res = {
			change: true,
			costPerSqFoot: Number(rehabCosts.value)
		}
		return res;
	}
};

export const convertDate = (value) => value instanceof Date ? value : new Date(value.seconds * 1000 + Math.floor(value.nanoseconds / 1e6));

export const checkBuyBoxUpdates = async(data, userId, subscriptions, freeTrial, setLoadingBuyBoxes) => {
	let changes = false;
	const today = new Date();
	const todaySeconds = today.getTime() / 1000;
	const todayFormatted = moment(today).format('YYYY-MM-DD');
	const startofToday = moment(todayFormatted);
	const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
	const paidPlan = freeTrial === true ? true : activeSubscription !== -1 ? true : false;

	if ( paidPlan === false ) {
		return;
	}

	for (let index = 0; index < data.length; index++) {
		const element = data[index];
		const firstStageLoadingBuyBoxes = [];
		data[index].created = await convertDate(element.created);
		
		if ( element.lastUpdate !== undefined ) {
			data[index].lastUpdate = await convertDate(element.lastUpdate);
		}
		if ( element.lastCheck !== undefined ) {
			data[index].lastCheck = await convertDate(element.lastCheck);
		}
		
		// If we're working with a city
		const cityId = element.msaCode;

		// Check the last time the cities were updated so 
		// as to recalculate total listings
		if ( element.lastCheck !== undefined ) {
			const lastCheck = element.lastCheck;
			const lastCheckFormatted = moment(lastCheck).format('YYYY-MM-DD');
			const startOfLastCheck = moment(lastCheckFormatted);
			const updateDateDifference = startofToday.diff(startOfLastCheck, 'days');
			if ( updateDateDifference === 0 ) {
				continue;
			}
			else {
				firstStageLoadingBuyBoxes.push(element.id);
			}
		}
		else {
			firstStageLoadingBuyBoxes.push(element.id);
		}

		setLoadingBuyBoxes(firstStageLoadingBuyBoxes);
		const creativeStrategy = element.acquisitionStrategy === "sf" || element.acquisitionStrategy === "sub" ? true : false;
		const strategyVals = [
			{
				buyBoxValue: "ltr",
				endpointValue: "ltr"
			},
			{
				buyBoxValue: "hh",
				endpointValue: "househack"
			},
			{
				buyBoxValue: "brrrr",
				endpointValue: "brrrr"
			},
			{
				buyBoxValue: "lo",
				endpointValue: "leaseoption"
			}
		];
		const strategyIndex = strategyVals.findIndex(e => e.buyBoxValue === element.exitStrategy);
		const filters = element.filters;
		const filterParams = await getBuyBoxFilterParams(filters, element, true);
		const newFilters = await filtersEncoder(filterParams);
		const getGatewayQueryString = await getAPIGatewayListingsV4(defaultUserData.settings, creativeStrategy, cityId, newFilters, 1, strategyVals[strategyIndex].endpointValue, "", null, false);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "listingsV4",
			queryString: getGatewayQueryString
		};
		// Get total listings for the city
		const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getGateway.status === 200 && getGateway.body.length !== 0 ) {
			const getGatewayBody = getGateway.body[0];
			const getGatewayData = getGatewayBody.data;
			const filteredPropertyCount = getGatewayData.filteredPropertyCount;
			const totalListings = getGatewayData.totalPropertyCount;
			const newListings = getGatewayData.filteredNewPropertyCount;
			
			// Get individual buy box listings to calculate how many
			// properties are available for each buy box

			if ( element.lastCheck === undefined ) {
				changes = true;
			}
			else {
				const lastCheck = element.lastCheck;
				const lastCheckSeconds = lastCheck.getTime() / 1000;
				const lastCheckFormatted = moment.unix(lastCheckSeconds).format('YYYY-MM-DD');
				const startOfLastCheck = moment(lastCheckFormatted);
				const updateDateDifference = startofToday.diff(startOfLastCheck, 'days');
				if ( updateDateDifference === 0 ) {
					data[index].lastCheck = new Date();
					data[index].lastUpdate = new Date();
					data[index].lastUpdateWords = "Today";
					continue;
				}
				else {
					changes = true;
				}
			}

			data[index].lastCheck = new Date();
			data[index].totalListings = totalListings;
			data[index].newListings = newListings;
			data[index].activeListings = filteredPropertyCount;
			data[index].lastUpdate = new Date();
			data[index].lastUpdateWords = "Today";
			setLoadingBuyBoxes([]);
		}
		else {
			if ( element.lastCheck === undefined || element.lastUpdateWords === undefined ) {
				data[index].lastCheck = new Date();
				data[index].lastUpdateWords = "Today";
				changes = true;
			}
			continue;
		}
	}

	if ( changes === true ) {
		await setData("Buy Boxes V4", userId, data);
	}

	const res = {
		data: data,
		changes: changes
	};
	return res;
};

export const sendCityIDErrorEmail = async(city, state, page, response) => {
	const dynamicTemplate = "d-91e29892705e40259004eb5dd63274a3";
	
	const getUser = await checkUserId();
	const contactEmail = "info@coffeeclozers.com";

	let userData = {
		firstName: "",
		lastName: "",
		email: "",
	};

	if ( getUser.status === 200 ) {
		const colRef = "Users";
		const docRef = getUser.userId;
		const queryData = await getDocument(colRef, docRef);
		if ( queryData.status === 200 ) {
			userData = queryData.data.data;
		}
	}

	const emailData = {
		to: ["ariel.herrera@coffeeclozers.com", "liam.maher@coffeeclozers.com"],
		from: contactEmail,
		templateId: dynamicTemplate,
		dynamic_template_data: {
			name: getUser.status === 200 ? `${userData.firstName} ${userData.lastName}` : "",
			email: getUser.status === 200 ? userData.email : "",
			userId: getUser.status === 200 ? getUser.userId : "",
			city: `${city}, ${state}`,
			page: page,
			response: response
		}
	};
	await sendEmail(emailData);
};

export const queryGooglePlaces = async(val, type) => {
	const autocompleteParams = {
		input: val,
		language: "en",
		types: type,
		components: "country:us"
	};

	return new Promise(async resolve => {
		const getAutocomplete = await placesAutocompleteSearch(autocompleteParams);
		if ( getAutocomplete.status === 200 ) {
			const responses = getAutocomplete.data;
			if ( responses.length !== 0 ) {
				
				const cityStateResponses = [];
				for (let index = 0; index < responses.length; index++) {
					const element = responses[index];
					const terms = element.terms;
					if ( terms.length > 1 ) {
						let cityState = terms[1].value;
						if ( cityState.length > 2 ) {
							const stateIndex = allStates.findIndex(e => e.name === cityState);
							if ( stateIndex !== -1 ) {
								cityState = allStates[stateIndex].abbreviation;
							}
						}

						const newObject = {
							city: terms[0].value,
							state: cityState
						}
						cityStateResponses.push(newObject);
					}
				}
				resolve(cityStateResponses);
			}
		}
		else {
			resolve([]);
		}
	});
};

export const renewFreeTrial = async(freeTrial, subscriptions, userId, userData, setUserData) => {
	const today = new Date();
	const todaySeconds = today.getTime() / 1000;
	
	// This represents Monday 25th November 2024
	const grandfatherDate = 1732493340;
	const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
	if ( activeSubscription !== -1 ) {
		return;
	}
	else if ( freeTrial === undefined || freeTrial.length === 0 ) {
		return;
	}
	else if ( freeTrial[0].startDate.seconds < grandfatherDate ) {
		const endDate = moment(today).add(7, "days");
		const newFreeTrial = [
			{
				startDate: today,
				endDate: endDate._d
			}
		];
		userData.freeTrial = newFreeTrial;
		setUserData(userData);
		const colRef = "Users";
		await setData(colRef, userId, userData);
		window.location.reload();
	}
	else {
		return;
	}
};

export const hasInvalidCharacters = (url) => {
    // Regular expression to match non-printable ASCII characters
    const pattern = /[^\x20-\x7E]/;
    return pattern.test(url);
}

export const checkNaN = (value) => {
	if ( isNaN(value) ) {
		return true;
	}
	else {
		return false;
	}
};

export const formatPrice = (value) => {
	const checkNaN = isNaN(value);
	const stringVal = `${value}`;
	if ( checkNaN === true ) {
		return value;
	}
	else if ( stringVal === "" ) {
		return "";
	}
	else if ( stringVal.includes(".") ) {
		const fullStopIndex = stringVal.indexOf('.');
		const firstString = stringVal.substring(0, fullStopIndex);
		const secondString = stringVal.substring(fullStopIndex, stringVal.length);
		const dollarNumber = formatterLong.format(Number(firstString)).replace("$", "").replace(".00", "");
		const removeExcessDPs = secondString.substring(0, 3);
		const finalNumber = `${dollarNumber}${removeExcessDPs}`;
		return finalNumber;
	}
	else {
		const dollarNumber = formatterLong.format(value).replace("$", "").replace(".00", "");
		return dollarNumber;
	}
};

export const saveFiltersToDB = async(filters, searchParams, setSearchParams) => {
	
	// Property type
	const propertyTypeObject = filters.propertyType;
	const propertyTypes = propertyTypeObject.propertyTypes;
	const valuesArray = propertyTypes.map(e => e.included ? e.abbreviation : null);
	const filteredValuesArray = valuesArray.filter(value => value !== null);
	const commaSeparatedString = filteredValuesArray.join(',');
	const newFilterArray = [];
	if ( commaSeparatedString.includes("SFH") && commaSeparatedString.includes("MFH") && propertyTypeObject.units === 0 ) {
	}
	else if ( commaSeparatedString === "" ) {
	}
	else {
		const newPropertyTypeObject = {
			propertyTypes: commaSeparatedString
		}

		if ( commaSeparatedString.includes("MFH") ) {
			newPropertyTypeObject.units = propertyTypeObject.units;
			newPropertyTypeObject.match = propertyTypeObject.match;
		}
		const newObject = {
			label: "propertyType",
			value: newPropertyTypeObject,
			type: "basics"
		}
		newFilterArray.push(newObject);
	}

	// Budget
	const budgetRange = filters.budgetRange;
	if ( budgetRange[0] === defaultVals.budgetRange[0] && budgetRange[1] === defaultVals.budgetRange[1] ) {
	}
	else if ( Number(budgetRange[0]) === defaultVals.budgetRange[0] && Number(budgetRange[1]) === 0 ) {
	}
	else {
		const newObject = {
			label: "price",
			value: budgetRange,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}

	// Bedrooms
	const bedroomsObject = filters.bedrooms;
	if ( bedroomsObject.value === defaultVals.bedrooms ) {
	}
	else {
		const newObject = {
			label: "beds",
			value: bedroomsObject.value,
			match: bedroomsObject.match,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}

	// Bathrooms
	const bathroomsObject = filters.bathrooms;
	if ( bathroomsObject.value === defaultVals.bathrooms ) {
	}
	else {
		const newObject = {
			label: "baths",
			value: bathroomsObject.value,
			match: bathroomsObject.match,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}

	// Square footage
	const squareFootage = filters.squareFootage;
	if ( squareFootage[0] === defaultVals.squareFootage[0] && squareFootage[1] === defaultVals.squareFootage[1] ) {
	}
	else {
		const newObject = {
			label: "sqft",
			value: squareFootage,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}

	// Construction years
	const constructionYears = filters.constructionYears;
	if ( constructionYears[0] === defaultVals.constructionYears[0] && constructionYears[1] === defaultVals.constructionYears[1] ) {
	}
	else {
		const newObject = {
			label: "construction",
			value: constructionYears,
			type: "basics"
		};
		newFilterArray.push(newObject);
	}

	// Condition
	const condition = filters.condition;
	if ( condition.length !== defaultVals.conditionValues.length ) {
		const newObject = {
			label: "condition",
			value: condition,
			type: "condition"
		};
		newFilterArray.push(newObject);
	}

	// Creative
	const creative = filters.creative;
	if ( creative !== defaultVals.creativeFinanceType ) {
		const newObject = {
			label: "creative",
			value: creative,
			type: "creative"
		};
		newFilterArray.push(newObject);
	}

	// Listing type
	const listingType = filters.listingType;
	if ( listingType !== defaultVals.listingType ) {
		const newObject = {
			label: "listingType",
			value: listingType,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}

	// Days on market
	const daysOnMarket = filters.daysOnMarket;
	if ( daysOnMarket !== defaultVals.daysOnMarket ) {
		const newObject = {
			label: "daysOnMarket",
			value: daysOnMarket,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}

	// Back On Market
	const backOnMarket = filters.backOnMarket;
	if ( backOnMarket === true ) {
		const newObject = {
			label: "backOnMarket",
			value: backOnMarket,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}

	// Price reduction
	const recentPriceReduction = filters.recentPriceReduction;
	if ( recentPriceReduction === true ) {
		const newObject = {
			label: "priceReduction",
			value: recentPriceReduction,
			type: "listing"
		};
		newFilterArray.push(newObject);
	}

	// Neighbourhood Grades
	const neighbourhoodGrades = filters.neighbourhoodGrades;
	if ( neighbourhoodGrades[0] === defaultVals.neighbourhoodGrades[0] && neighbourhoodGrades[1] === defaultVals.neighbourhoodGrades[1] ) {
	}
	else {
		const newObject = {
			label: "neighbourhoodGrades",
			value: neighbourhoodGrades,
			type: "location"
		};
		newFilterArray.push(newObject);
	}

	// Zip codes
	const zipcodes = filters.zipcodes;
	if ( zipcodes.length !== 0 ) {
		const newObject = {
			label: "zipcodes",
			value: zipcodes,
			type: "location"
		};
		newFilterArray.push(newObject);
	}

	// Floodzone
	const hideFloodzone = filters.hideFloodzone;
	if ( hideFloodzone === true ) {
		const newObject = {
			label: "floodzone",
			value: hideFloodzone,
			type: "location"
		};
		newFilterArray.push(newObject);
	}

	// HOA
	const hideHOA = filters.hideHOA;
	if ( hideHOA === true ) {
		const newObject = {
			label: "hoa",
			value: hideHOA,
			type: "location"
		};
		newFilterArray.push(newObject);
	}

	// ADU
	const onlyADU = filters.onlyADU;
	if ( onlyADU === true ) {
		const newObject = {
			label: "adu",
			value: onlyADU,
			type: "opportunities"
		};
		newFilterArray.push(newObject);
	}

	// Extra bedroom
	const onlyExtraBedroom = filters.onlyExtraBedroom;
	if ( onlyExtraBedroom === true ) {
		const newObject = {
			label: "extraBedroom",
			value: onlyExtraBedroom,
			type: "opportunities"
		};
		newFilterArray.push(newObject);
	}

	// Oversized Lot
	const oversizedLot = filters.oversizedLot;
	if ( oversizedLot === true ) {
		const newObject = {
			label: "oversizedLot",
			value: oversizedLot,
			type: "opportunities"
		};
		newFilterArray.push(newObject);
	}

	// Owner type
	const ownerType = filters.ownerType;
	if ( ownerType !== defaultVals.ownerType ) {
		const newObject = {
			label: "ownerType",
			value: ownerType,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}

	// Ownership length
	const ownershipLength = filters.ownershipLength;
	if ( ownershipLength !== defaultVals.ownershipLength ) {
		const newObject = {
			label: "ownershipLength",
			value: ownershipLength,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}

	// Distress flags
	const distressFlags = filters.distressFlags;
	if ( distressFlags !== defaultVals.distressFlags ) {
		const newObject = {
			label: "distress",
			value: distressFlags,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}

	// Estimated Interest
	const estimatedInterest = filters.estimatedInterest;
	if ( estimatedInterest !== defaultVals.estimatedInterest ) {
		const newObject = {
			label: "interest",
			value: estimatedInterest,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}

	// Equity Level
	const equityLevel = filters.equityLevel;
	if ( equityLevel !== defaultVals.equityLevel ) {
		const newObject = {
			label: "equity",
			value: equityLevel,
			type: "ownership"
		}
		newFilterArray.push(newObject);
	}

	// Mortgage Type
	const mortgageType = filters.mortgageType;
	if ( mortgageType !== defaultVals.mortgageType ) {
		const newObject = {
			label: "mortgageType",
			value: mortgageType,
			type: "ownership"
		};
		newFilterArray.push(newObject);
	}

	// ROI
	const minCoCR = filters.minCoCR;
	if ( minCoCR !== defaultVals.cocr ) {
		const newObject = {
			label: "cocr",
			value: minCoCR,
			type: "roi"
		};
		newFilterArray.push(newObject);
	}

	const params = Object.fromEntries(searchParams.entries());
	params.filters = JSON.stringify(newFilterArray);
	params.page = 1;
	setSearchParams(params);
};

export const formatBuyBoxFilters = (filters) => {
	const newFilters = {
	};

	// Budget
	const budgetRange = filters.budgetRange;
	if ( Number(budgetRange[0]) === defaultVals.budgetRange[0] && Number(budgetRange[1]) === defaultVals.budgetRange[1] ) {
	}
	else if ( Number(budgetRange[0]) === defaultVals.budgetRange[0] && Number(budgetRange[1]) === 0 ) {
	}
	else {
		const newBudgetRange = [Number(budgetRange[0]), budgetRange[1] === "" ? defaultVals.budgetRange[1] : Number(budgetRange[1])];
		newFilters.budgetRange = newBudgetRange;
	}

	// Neighbourhood Grades
	const neighbourhoodGrades = filters.neighbourhoodGrades;
	if ( neighbourhoodGrades[0] === defaultVals.neighbourhoodGrades[0] && neighbourhoodGrades[1] === defaultVals.neighbourhoodGrades[1] ) {
	}
	else {
		newFilters.neighbourhoodGrades = neighbourhoodGrades;
	}

	// Condition
	const condition = filters.condition;
	if ( condition.length !== defaultVals.conditionValues.length ) {
		newFilters.condition = condition;
	}

	// Property type
	const propertyTypes = filters.propertyTypes;
	const valuesArray = propertyTypes.map(e => e.included ? e.abbreviation : null);
	const filteredValuesArray = valuesArray.filter(value => value !== null);
	const commaSeparatedString = filteredValuesArray.join(',');
	if ( commaSeparatedString === "" ) {
	}
	else {
		newFilters.propertyTypes = commaSeparatedString;

		if ( commaSeparatedString.includes("MFH") && filters.units !== 0 ) {
			newFilters.units = filters.units;

			if ( filters.unitExactMatch === true ) {
				newFilters.unitExactMatch = filters.unitExactMatch;
			}
		}
	}

	// Bedrooms
	const bedrooms = filters.bedrooms;
	if ( bedrooms === defaultVals.bedrooms ) {
	}
	else {
		newFilters.bedrooms = bedrooms;
		
		if ( filters.bedroomExactMatch === true ) {
			newFilters.bedroomExactMatch = true;
		}
	}

	// Bathrooms
	const bathrooms = filters.bathrooms;
	if ( bathrooms === defaultVals.bathrooms ) {
	}
	else {
		newFilters.bathrooms = bathrooms;
		
		if ( filters.bathroomExactMatch === true ) {
			newFilters.bathroomExactMatch = true;
		}
	}

	// Square footage
	const squareFootage = filters.squareFootage;
	if ( squareFootage[0] === defaultVals.squareFootage[0] && squareFootage[1] === defaultVals.squareFootage[1] ) {
	}
	else {
		newFilters.squareFootage = squareFootage;
	}

	// Construction years
	const constructionYears = filters.constructionYears;
	if ( constructionYears[0] === defaultVals.constructionYears[0] && constructionYears[1] === defaultVals.constructionYears[1] ) {
	}
	else {
		const newConstructionYears = [constructionYears === "" ? "" : Number(constructionYears[0]), constructionYears[1] === "" ? "" : Number(constructionYears[1])];
		newFilters.constructionYears = newConstructionYears;
	}

	// ADU
	const onlyADU = filters.onlyADU;
	if ( onlyADU === true ) {
		newFilters.onlyADU = onlyADU;
	}

	// Extra bedroom
	const onlyExtraBedroom = filters.onlyExtraBedroom;
	if ( onlyExtraBedroom === true ) {
		newFilters.onlyExtraBedroom = onlyExtraBedroom;
	}

	// Oversized Lot
	const oversizedLot = filters.oversizedLot;
	if ( oversizedLot === true ) {
		newFilters.oversizedLot = oversizedLot;
	}

	// Floodzone
	const hideFloodzone = filters.hideFloodzone;
	if ( hideFloodzone === true ) {
		newFilters.hideFloodzone = hideFloodzone;
	}

	// HOA
	const hideHOA = filters.hideHOA;
	if ( hideHOA === true ) {
		newFilters.hideHOA = hideHOA;
	}

	// Owner type
	const ownerType = filters.ownerType;
	if ( ownerType !== defaultVals.ownerType ) {
		newFilters.ownerType = ownerType;
	}

	// Ownership length
	const ownershipLength = filters.ownershipLength;
	if ( ownershipLength !== defaultVals.ownershipLength ) {
		newFilters.ownershipLength = ownershipLength;
	}

	// Distress flags
	const distressFlags = filters.distressFlags;
	if ( distressFlags !== defaultVals.distressFlags ) {
		newFilters.distressFlags = distressFlags;
	}

	// Estimated Interest
	const estimatedInterest = filters.estimatedInterest;
	if ( estimatedInterest !== defaultVals.estimatedInterest ) {
		newFilters.estimatedInterest = estimatedInterest;
	}

	// Equity Level
	const equityLevel = filters.equityLevel;
	if ( equityLevel !== defaultVals.equityLevel ) {
		newFilters.equityLevel = equityLevel;
	}

	// Mortgage Type
	const mortgageType = filters.mortgageType;
	if ( mortgageType !== defaultVals.mortgageType ) {
		newFilters.mortgageType = mortgageType;
	}

	// Listing type
	const listingType = filters.listingType;
	if ( listingType !== defaultVals.listingType ) {
		newFilters.listingType = listingType;
	}

	// Days on market
	const daysOnMarket = filters.daysOnMarket;
	if ( daysOnMarket !== defaultVals.daysOnMarket ) {
		newFilters.daysOnMarket = daysOnMarket;
	}

	// Back On Market
	const backOnMarket = filters.backOnMarket;
	if ( backOnMarket === true ) {
		newFilters.backOnMarket = backOnMarket;
	}

	// Price reduction
	const recentPriceReduction = filters.recentPriceReduction;
	if ( recentPriceReduction === true ) {
		newFilters.recentPriceReduction = recentPriceReduction;
	}

	// ROI
	const minCoCR = filters.minCoCR;
	if ( minCoCR !== defaultVals.cocr ) {
		newFilters.minCoCR = Number(minCoCR);
	}

	return newFilters;
};

export const addToCircle = async(data) => {
	return new Promise(async resolve => {
		const params = {
			endpoint: "community_members",
			method: "POST",
			params: {
				email: data.email,
				name: data.name
			}
		};
		const queryData = await queryCircle(params);
		if ( queryData.status === 200 ) {
			const message = queryData.data.message;
			if ( message === "This user is already a member of this community." ) {
				console.log("User is already a member of this community.");
				resolve(false);
			}
			else if ( message === "This user has been invited to the community." ) {
				resolve(true);
			}
		}
		else {
			resolve(false);
		}
	});
};

export const queryCircleUser = async(data) => {
	return new Promise(async resolve => {
		const params = {
			endpoint: "community_members/search",
			method: "GET",
			params: {
				email: data.email
			}
		};
		const queryData = await queryCircle(params);
		resolve(queryData);
	});
};

export const deleteCircleUser = async(id) => {
	return new Promise(async resolve => {
		const params = {
			endpoint: `community_members/${id}`,
			method: "DELETE",
			params: {
				id: id
			}
		};
		const queryData = await queryCircle(params);
		resolve(queryData);
	});
};

export const queryCircle = (data) => {
	const url = "https://circlequery-oyonrz73aa-uc.a.run.app";
	
	return new Promise(async resolve => {
		await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				data: data
			})
		})
		.then(async(response) => {
			const content = await response.json();
			resolve(content);
		})
		.catch((error) => {
			const res = {
				status: 400,
				error: error
			};
			resolve(res);
		});
	});
};

export const calculateDealOptimisation = async(params) => {
	return new Promise(async resolve => {
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const offerParams = {
			type: "sellerFinance",
			financials: {
				listPrice: Number(params.price),
				rentalIncome: Number(params.rent),
				monthlyExpenses: Number(params.monthlyCosts),
				balloonYears: Number(params.duration) / 12,
				ballonAdjustableFlag: 1,
				minDownPaymentPrct: Number(params.minDownPayment),
				maxDownPaymentPrct: Number(params.maxDownPayment),
				minInterestRatePrct: Number(params.minInterestRate),
				maxInterestRatePrct: Number(params.maxInterestRate),
				requiredSellerEarningsPrct: Number(params.minSellerEarnings),
				targetCashOnCashReturn: Number(params.targetCoCR)
			}
		};
		const typeString = `type=${offerParams.type}`;
		const financialsString = `&${await formatFilters("financials", offerParams.financials)}`;
		const getGatewayQueryString = `runTermOptimization?${typeString}${financialsString}`;
		const resourceId = "termOptimisation";
		const getGatewayParams = {
			type: "gateway",
			resourceId: resourceId,
			queryString: getGatewayQueryString
		};
		const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
		resolve(getGateway);
	});
};

export const generateEmptyEmailVerification = async(userId) => {
	const colRef = "Email Verification";
	const data = [];

	return new Promise(async resolve => {
		const writeData = await setData(colRef, userId, data);
		resolve(writeData);
	});
};

export const stateLabelValues = [
	{ label:'Alabama', value: 'AL', propertyTax: 0.41},
	{ label:'Alaska', value: 'AK', propertyTax: 1.19},
	{ label:'Arizona', value: 'AZ', propertyTax: 0.66},
	{ label:'Arkansas', value: 'AR', propertyTax: 0.62},
	{ label:'California', value: 'CA', propertyTax: 0.76},
	{ label:'Colorado', value: 'CO', propertyTax: 0.51},
	{ label:'Connecticut', value: 'CT', propertyTax: 2.14},
	{ label:'Delaware', value: 'DE', propertyTax: 0.57},
	{ label:'District of Columbia', value: 'DC', propertyTax: 0.56},
	{ label:'Florida', value: 'FL', propertyTax: 0.89},
	{ label:'Georgia', value: 'GA', propertyTax: 0.92},
	{ label:'Hawaii', value: 'HI', propertyTax: 0.28},
	{ label:'Idaho', value: 'ID', propertyTax: 0.69},
	{ label:'Illinois', value: 'IL', propertyTax: 2.27},
	{ label:'Indiana', value: 'IN', propertyTax: 0.85},
	{ label:'Iowa', value: 'IA', propertyTax: 1.57},
	{ label:'Kansas', value: 'KS', propertyTax: 1.41},
	{ label:'Kentucky', value: 'KY', propertyTax: 0.86},
	{ label:'Louisiana', value: 'LA', propertyTax: 0.55},
	{ label:'Maine', value: 'ME', propertyTax: 1.36},
	{ label:'Maryland', value: 'MD', propertyTax: 1.09},
	{ label:'Massachusetts', value: 'MA', propertyTax: 1.23},
	{ label:'Michigan', value: 'MI', propertyTax: 1.54},
	{ label:'Minnesota', value: 'MN', propertyTax: 1.12},
	{ label:'Mississippi', value: 'MS', propertyTax: 0.81},
	{ label:'Missouri', value: 'MO', propertyTax: 0.97},
	{ label:'Montana', value: 'MT', propertyTax: 0.84},
	{ label:'Nebraska', value: 'NE', propertyTax: 1.73},
	{ label:'Nevada', value: 'NV', propertyTax: 0.60},
	{ label:'New Hampshire', value: 'NH', propertyTax: 2.18},
	{ label:'New Jersey', value: 'NJ', propertyTax: 2.49},
	{ label:'New Mexico', value: 'NM', propertyTax: 0.80},
	{ label:'New York', value: 'NY', propertyTax: 1.72},
	{ label:'North Carolina', value: 'NC', propertyTax: 0.84},
	{ label:'North Dakota', value: 'ND', propertyTax: 0.98},
	{ label:'Ohio', value: 'OH', propertyTax: 1.56},
	{ label:'Oklahoma', value: 'OK', propertyTax: 0.90},
	{ label:'Oregon', value: 'OR', propertyTax: 0.97},
	{ label:'Pennsylvania', value: 'PA', propertyTax: 1.58},
	{ label:'Rhode Island', value: 'RI', propertyTax: 1.63},
	{ label:'South Carolina', value: 'SC', propertyTax: 0.57},
	{ label:'South Dakota', value: 'SD', propertyTax: 1.31},
	{ label:'Tennessee', value: 'TN', propertyTax: 0.71},
	{ label:'Texas', value: 'TX', propertyTax: 1.80},
	{ label:'Utah', value: 'UT', propertyTax: 0.63},
	{ label:'Vermont', value: 'VT', propertyTax: 1.90},
	{ label:'Virginia', value: 'VA', propertyTax: 0.82},
	{ label:'Washington', value: 'WA', propertyTax: 0.98},
	{ label:'West Virginia', value: 'WV', propertyTax: 0.58},
	{ label:'Wisconsin', value: 'WI', propertyTax: 1.85},
	{ label:'Wyoming', value: 'WY', propertyTax: 0.61}
];

export const neighbourhoodTooltipIndexes = [
	{
		title: "Average price per square foot",
		description: "Average price per sq. foot of the entire ZIP code. This is a measure of how much a property costs per square foot of living space"
	},
	{
		title: "Average price to rent ratio",
		description: "Average price to rent ratio for the entire ZIP code. This looks at the relationship between the average house price and the average rent rate. Lower is better!"
	},
	{
		title: "Year over year appreciation",
		description: "The percentage change in the value of a property during the last 12 months."
	},
	{
		title: "Year over year days on market",
		description: "A measure of how long it takes a property to sell compared to the same time period the previous year. A negative number means properties are selling faster, whereas a positive number means they're sitting on the market longer."
	}
];

export const gentrificationTooltipIndexes = [
	{
		title: "Moved in the last 5 years",
		description: "This shows the percentage of people who have moved into the area within the last 5 years."
	},
	{
		title: "Bachelor's degree or higher",
		description: "This tells us what percentage of people in this neighborhood have a bachelor's degree or higher"
	},
	{
		title: "Employed",
		description: "This shows the percentage of people who are employed within this neighborhood."
	},
	{
		title: "Median household income",
		description: "This gives us a dollar amount for the median household income in this neighborhood."
	}
];

export const revenueTooltipIndexes = [
	{
		title: "How is this calculated?",
		description: "The rent is calculated by looking at properties currently being rented in the same zip code, with the same number of bedrooms and bathrooms. \n\n However, this estimate does NOT take the current condition of the property into account and assumes the property is rent-ready."
	},
	{
		title: "Other sources of income?",
		description: "Do you charge extra for any services or amenities? Having pets? Garage space? On street parking? Swimming pool stuff?"
	}
];

export const costsTooltipIndexes = [
	{
		title: "hopefully self-explanatory..",
		description: ""
	},
	{
		title: "How is this calculated?",
		description: "This is an estimate that comes from Zillow."
	},
	{
		title: "How is this calculated?",
		description: "This is an estimate that comes from Zillow."
	},
	{
		title: "How is this calculated?",
		description: "This is based on current property tax rates, but does NOT include any tax increases that may (or may not) occur after purchasing the property, so do please double-check that on the county website!"
	},
	{
		title: "How is this calculated?",
		description: "We calculate this based on the average vacancy rate for the city."
	}
	,{
		title: "How is this calculated?",
		description: "This is an estimate based on industry norms."
	}
	,{
		title: "How is this calculated?",
		description: "This is an estimate based on industry norms."
	},
	{
		title: "Miscellaneous costs",
		description: "Want to put money aside for CapEx, or something else? This is the place to do it."
	}
];

export const monthlyCostsTooltips = [
	{
		tooltips: [
			{
				title: "Mortgage payment",
				description: "This is calculated based on your down payment and interest rate, which can be adjusted in the 'Purchase' tab on the left. If you want to save your settings permanently, you can do that in your Financial Preferences."
			},
			{
				title: "HOA Fees",
				description: "This is an estimate that comes from Zillow."
			},
			{
				title: "Insurance",
				description: "This is an estimate that comes from Zillow."
			},
			{
				title: "Property taxes",
				description: `This is based on current property tax rates, but does NOT include any tax increases that may (or may not) occur after purchasing the property, so do please double-check that on the county website. ${"\n"} ${"\n"} If you have selected the BRRRR strategy, your taxes will be calculated off of the ARV instead of the property price.`
			}
		]
	},
	{
		tooltips: [
			{
				title: "Vacancy rate allocation",
				description: "We calculate this based on the average vacancy rate for the city."
			},
			{
				title: "Management fee",
				description: "This is an estimate based on industry norms."
			}
		]
	},
	{
		tooltips: [
			{
				title: "Maintenance",
				description: "This is an estimate based on industry norms."
			},
			{
				title: "Other costs",
				description: "Use this to put money aside for a rainy day, like fixing the roof when it leaks in 5 years from now!"
			}
		]
	}
];

export const formatCityNames = (item) => {
	if ( 
			( item.state === "VA" && item.county === "Norfolk City" ) ||
			( item.state === "VA" && item.county === "Chesapeake City" )
		) {
		return `${item.county.replace(" City", "")}, ${item.state}`;
	}
	else if ( 
		(item.county === "Baltimore City" && item.state === "MD") ||
		(item.county === "St. Louis City" && item.state === "MO") ||
		(item.county === "Richmond City" && item.state === "VA") ||
		(item.county === "Lynchburg City" && item.state === "VA") ||
		(item.county === "Newport News City" && item.state === "VA") ||
		(item.county === "Williamsburg City" && item.state === "VA") ||
		(item.county === "Virginia Beach City" && item.state === "VA") ||
		(item.county === "Fairfax City" && item.state === "VA") ||
		(item.county === "Hampton City" && item.state === "VA") ||
		(item.county === "Manassas City" && item.state === "VA")
	) {
		return `${item.county}, ${item.state}`;
	}
	else if ( item.state === "LA" || item.state === "D.C." ) {
		return `${item.county}, ${item.state}`;
	}
	else {
		return `${item.county} County, ${item.state}`;
	}
};

export const goToCreativeCheckout = async(navigate) => {
	const firstPricingOption = false;
	const origin = window.location.origin;
    const devMode = origin.includes("localhost") || window.location.hostname.includes("192.") || origin.includes("refi") ? true : false;
	const blackFriday = false;

	const bfItem = {
		counties: 1,
        creative: true,
		label: "Traditional + Creative",
		title: "Plus",
		oldPrice: `${formatterLong.format(combinedPricing[1].price / 100).replace(".00", "")}`,
		price: firstPricingOption === false ? `${formatterLong.format(Math.floor(blackFridayCombinedPricing[1].annualPrice / 1200)).replace(".00", "")}` : `${formatterLong.format(blackFridayCombinedPricing[1].price / 100).replace(".00", "")}`,
		amount: blackFridayCombinedPricing[1].price,
		subscriptionId: blackFridayCombinedPricing[1].subscriptionId,
		testSubscriptionId: blackFridayCombinedPricing[1].testSubscriptionId,
		total: firstPricingOption === true ? blackFridayCombinedPricing[1].price : blackFridayCombinedPricing[1].annualPrice,
        discountRate: blackFridayCombinedPricing[1].discountRate
	}

	const item = {
		counties: 1,
        creative: true,
		label: "Traditional + Creative",
		title: "Plus",
		oldPrice: firstPricingOption === false ? `${formatterLong.format(combinedPricing[1].price / 100).replace(".00", "")}` : "",
		price: firstPricingOption === false ? `${formatterLong.format(Math.floor(combinedPricing[1].annualPrice / 1200)).replace(".00", "")}` : `${formatterLong.format(combinedPricing[1].price / 100).replace(".00", "")}`,
		amount: combinedPricing[1].price,
		subscriptionId: combinedPricing[1].subscriptionId,
		testSubscriptionId: combinedPricing[1].testSubscriptionId,
		total: firstPricingOption === true ? combinedPricing[1].price : combinedPricing[1].annualPrice,
		discountRate: combinedPricing[1].discountRate
	};

	const finalItem = blackFriday === true ? bfItem : item;
	const newState = {
		route: "/pay",
		allCities: false,
		price: finalItem.amount / 100,
		subscriptionId: devMode === true ? finalItem.testSubscriptionId : finalItem.subscriptionId,
		autoBilling: firstPricingOption === true ? true : false,
		plan: {
			total: finalItem.total / 100,
			originalPrice: finalItem.amount / 100,
			months: firstPricingOption === true ? 1 : 12,
			price: firstPricingOption === true ? finalItem.total / 100 : finalItem.total / 1200,
			title: finalItem.title,
			label: finalItem.label,
			counties: finalItem.counties,
			creative: finalItem.creative,
			discount: firstPricingOption === true ? 0 : finalItem.discountRate
		}
	};
	const user = await checkOnAuthStateChanged();
	if ( user.status === 200 ) {
		recordEvent("Premium Modal - Checkout");
		navigate("/pay", {
			state: newState
		});
	}
	else {
		navigate("/sign-up", {
			state: newState
		});
	}
};

export const cashOnCashTooltipIndexes = [
	{
		title: "How is this calculated?",
		description: "The rent is calculated by looking at properties currently being rented in the same zip code, with the same number of bedrooms and bathrooms. \n\n However, this estimate does NOT take the current condition of the property into account and assumes the property is rent-ready."
	},
	{
		title: "Other sources of income?",
		description: "Do you charge extra for any services or amenities? Having pets? Garage space? On street parking? Swimming pool stuff?"
	},
	{
		title: "hopefully self-explanatory..",
		description: ""
	},
	{
		title: "How is this calculated?",
		description: "This is based on current property tax rates, but does NOT include any tax increases that may (or may not) occur after purchasing the property, so do please double-check that on the county website!"
	},
	{
		title: "How is this calculated?",
		description: "This is an estimate - slightly over 1% of the rental value."
	},
	{
		title: "How is this calculated?",
		description: "We get this information from public county records."
	},
	{
		title: "How is this calculated?",
		description: "This is an estimate, and is currently set to 1 month out of the year."
	}
	,{
		title: "How is this calculated?",
		description: "This is a rough estimate, a little under 10% of the rental income as we're taking vacancy rates into account."
	}
	,{
		title: "How is this calculated?",
		description: "This is an estimate and is set to 2% of your yearly rent. \n\n Please note this is SEPARATE from any renovation or capital expenditure costs - this is just for monthly ongoing maintenance."
	},
	{
		title: "Miscellaneous costs",
		description: "insert whatever you want"
	}
];

export const defaultRecurringCosts = [
	{
		label: "Insurance",
		value: "",
		default: true,
		placeholder: "city based",
		info: "The default is based on the average for each city according to Zillow.",
		rentPercentage: false
	},
	{
		label: "Property taxes",
		value: "",
		default: true,
		placeholder: "city based",
		info: "The default is based on Zillow's property tax estimates for each property, which usually does NOT take into account any tax increase after you purchase the property. Please check county websites for this data (we're working on aggregating them)!",
		rentPercentage: false
	},
	{
		label: "Vacancy Rate Allocation",
		value: "",
		default: true,
		placeholder: "city based",
		info: "The default is based on the average vacancy rate for the city according to FRED (Federal Reserve Economic Data)...not just some guy called Fred.",
		rentPercentage: true
	},
	{
		label: "Management fee",
		value: "",
		default: true,
		placeholder: "9",
		info: "The default is 9% of your rental income.",
		rentPercentage: true
	},
	{
		label: "Maintenance",
		value: "",
		default: true,
		placeholder: "2",
		info: "The default is 2% of your rental income.",
		rentPercentage: true
	},
	{
		label: "Other costs",
		value: "",
		default: true,
		placeholder: "0",
		info: "The default is 0%. Use this for things like CapEx, or for just being generally conservative on costs.",
		rentPercentage: true
	}
];

export const defaultInitialCosts = [
	{
		label: "Down payment",
		value: "",
		placeholder: "20"
	},
	{
		label: "Interest rate",
		value: "",
		default: true,
		placeholder: "7.73",
		info: "The default is automatically updated based on FRED's average for 30 year fixed-rate mortgages."
	}
];

export const cityPricing = [
	{
		label: "Auto-renew",
		description: "All features",
		guarantee: "",
		discount: 0,
		months: 1
	},
	{
		label: "3 months",
		description: "All features",
		guarantee: "Money-back guarantee",
		discount: 10,
		months: 3
	},
	{
		label: "6 months",
		description: "All features",
		guarantee: "Money-back guarantee",
		discount: 20,
		months: 6
	},
	{
		label: "12 months",
		description: "All features",
		guarantee: "Money-back guarantee",
		discount: 30,
		months: 12
	}
];

export const pricingOptions = [
	{
		price: 2500,
		label: "City",
		subscriptionId: "price_1OtgfZERRPhVxjedHf6L9I5P",
		testSubscriptionId: "price_1OtgfOERRPhVxjedCq8zMleh"
	},
	{
		price: 5000,
		label: "Metro",
		subscriptionId: "price_1Otgg4ERRPhVxjedzcQhBqCQ",
		testSubscriptionId: "price_1OtgfvERRPhVxjedbpvw2XIw"
	},
	{
		price: 10000,
		label: "Core Cities",
		subscriptionId: "price_1OtgefERRPhVxjedmCmjpv4C",
		testSubscriptionId: "price_1OtgeNERRPhVxjedYP7Wjbmi"
	}
];

export const countyPricingOptions = [
	{
		price: 5000,
		label: "1 County",
		subscriptionId: "price_1PSahXERRPhVxjedUt9tXAaY",
		testSubscriptionId: "price_1PSaewERRPhVxjedDCCykSh5",
		annualPrice: 25000
	},
	{
		price: 10000,
		label: "3 Counties",
		subscriptionId: "price_1PSahnERRPhVxjedt693Q8o2",
		testSubscriptionId: "price_1PSaf8ERRPhVxjed4EYcEsk2",
		annualPrice: 50000
	},
	{
		price: 15000,
		label: "5 Counties",
		subscriptionId: "price_1PSai0ERRPhVxjedURdXnuZp",
		testSubscriptionId: "price_1PSafKERRPhVxjedCBVUkALc",
		annualPrice: 75000
	}
];

export const cityTiers = [
	{
		label: "Small City",
		price: 40,
		subscriptionId: "price_1OhwwyERRPhVxjedhxIZNDi9",
		testSubscriptionId: "price_1OhwyCERRPhVxjedOokje6np",
		type: "small",
		tier: "Tier 1",
		population: "<50,000",
	},
	{
		label: "Medium City",
		price: 60,
		subscriptionId: "price_1OhwxOERRPhVxjed0wQu7zcF",
		testSubscriptionId: "price_1OhwyLERRPhVxjedE2A74i5G",
		type: "medium",
		tier: "Tier 2",
		population: "50,000-250,000",
	},
	{
		label: "Large City",
		price: 80,
		subscriptionId: "price_1OhwxXERRPhVxjedi5kMPb2i",
		testSubscriptionId: "price_1OhwyXERRPhVxjedezZeZILq",
		type: "large",
		tier: "Tier 3",
		population: "250,000+",
	}
];

export const countyPricing = [
	{
		price: 2500,
		label: "Under 250",
		subscriptionId: "price_1PDUhOERRPhVxjedWEaKY54b",
		testSubscriptionId: "price_1PDUh6ERRPhVxjedyaqILMS8"
	},
	{
		price: 3000,
		label: "250 - 500",
		subscriptionId: "price_1PDUiKERRPhVxjedm0IJ7Xxe",
		testSubscriptionId: "price_1PDUiFERRPhVxjedOr3yiDAA"
	},
	{
		price: 3500,
		label: "500 - 750",
		subscriptionId: "price_1PDUlNERRPhVxjed06DMDYmi",
		testSubscriptionId: "price_1PDUkwERRPhVxjedUWA68Xeb"
	},
	{
		price: 4000,
		label: "750 - 1000",
		subscriptionId: "price_1PDUmRERRPhVxjedyUeF7qyD",
		testSubscriptionId: "price_1PDUmJERRPhVxjedgdtTj8sh"
	},
	{
		price: 8000,
		label: "1000 - 3000",
		subscriptionId: "price_1PDUpNERRPhVxjedpOEdD9g4",
		testSubscriptionId: "price_1PDUp2ERRPhVxjed6u1fZ9nO"
	},
	{
		price: 12000,
		label: "3000 - 5000",
		subscriptionId: "price_1PDUraERRPhVxjedzvoCZYbr",
		testSubscriptionId: "price_1PDUrBERRPhVxjedk7SMuED1"
	},
	{
		price: 17000,
		label: "5000 - 7500",
		subscriptionId: "price_1PDW7gERRPhVxjedaTUBmHaa",
		testSubscriptionId: "price_1PDW7aERRPhVxjedEl1Q8tuY"
	},
	{
		price: 22000,
		label: "7500 - 10000",
		subscriptionId: "price_1PDW9VERRPhVxjedohXTi7EK",
		testSubscriptionId: "price_1PDW9MERRPhVxjed9srW7yIc"
	},
	{
		price: 25000,
		label: "10000+",
		subscriptionId: "price_1PDWA8ERRPhVxjedlSE7vSSH",
		testSubscriptionId: "price_1PDWA0ERRPhVxjedWtNNqd7D"
	}
];

export const combinedPricing = [
	{
		price: 9900,
		label: "Traditional",
		title: "Basic",
		subscriptionId: "price_1Px6jdERRPhVxjeduQBRY4JQ",
		testSubscriptionId: "price_1Px6jJERRPhVxjedRlZQhZVz",
		annualPrice: 99900,
		discountRate: 16
	},
	{
		price: 14900,
		label: "Traditional + Creative",
		title: "Plus",
		subscriptionId: "price_1PZBTRERRPhVxjedRd29izkc",
		testSubscriptionId: "price_1PZBRrERRPhVxjedJKe0IAH9",
		annualPrice: 143000,
		discountRate: 20
	},
];

export const blackFridayCombinedPricing = [
	{
		price: 7900,
		label: "Traditional",
		subscriptionId: "price_1QP3xSERRPhVxjedAzRQrYi0",
		testSubscriptionId: "price_1QP3zNERRPhVxjedt9MiPTcL",
		annualPrice: 74900,
		discountRate: 16
	},
	{
		price: 9900,
		label: "Traditional + Creative",
		subscriptionId: "price_1QP40tERRPhVxjedo4mPi2vL",
		testSubscriptionId: "price_1QP40FERRPhVxjedc4DS6SrT",
		annualPrice: 99900,
		discountRate: 20
	},
];

const origin = window.location.origin;
const devMode = origin.includes("localhost") || window.location.hostname.includes("192.") || origin.includes("refi") ? true : false;

export const defaultPricingStateMonthly = {
	route: "/pay",
	allCities: false,
	price: combinedPricing[1].price / 100,
	subscriptionId: devMode === true ? combinedPricing[1].testSubscriptionId : combinedPricing[1].subscriptionId,
	autoBilling: true,
	plan: {
		total: combinedPricing[1].price / 100,
		originalPrice: combinedPricing[1].price / 100,
		months: 1,
		price: combinedPricing[1].price / 100,
		title: combinedPricing[1].title,
		label: combinedPricing[1].label,
		counties: 1,
		creative: true,
		discount: 0
	}
};

export const defaultPricingStateYearly = {
	route: "/pay",
	allCities: false,
	price: combinedPricing[1].price / 100,
	subscriptionId: devMode === true ? combinedPricing[1].testSubscriptionId : combinedPricing[1].subscriptionId,
	autoBilling: false,
	plan: {
		total: combinedPricing[1].annualPrice / 100,
		originalPrice: combinedPricing[1].price / 100,
		months: 12,
		price: combinedPricing[1].price / 1200,
		title: combinedPricing[1].title,
		label: combinedPricing[1].label,
		counties: 1,
		creative: true,
		discount: combinedPricing[1].discountRate
	}
};