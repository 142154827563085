import React from 'react';
import '../styles/VerticalTab.css';
import { DefaultUserImage } from '../../components';
import { Badge } from './';
import { Dot, RedirectArrow } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function VerticalTab(props) {
    const selected = props.selected;
    const label = props.label;
    const disabled = props.disabled;
    const cta = props.cta;
    const icon = props.icon;
    const external = props.external;
    const account = props.account;
    const userData = props.userData;
    const standardIcon = props.standardIcon;
    const badge = props.badge;
    const badgeVal = props.badgeVal;
    const badgeBG = props.badgeBG;
    const reset = props.reset;

    return (
        <div className="vertical-tab-outer-container">
            <div 
                className={"vertical-tab-inner-container " + (selected === true ? "vertical-tab-selected-container " : "vertical-tab-unselected-container ") + (disabled === true ? "disabled" : "cursor-pointer")}
                onClick={() => cta()}
            >
                <div className="vertical-tab-left-container">
                    {
                        account === true ?
                            userData === null || userData.profileImage === undefined ?
                            <DefaultUserImage
                                size="small"
                            />
                            :
                            <img
                                src={userData.profileImage.url}
                                className="vertical-tab-account-pic"
                                alt="My account"
                            />
                        :
                        icon === null ? 
                        null 
                        :
                        standardIcon === true ?
                        <img
                            src={icon}
                            className="vertical-tab-icon"
                            alt={label}
                        />
                        :
                        icon
                    }
                    <div className="vertical-tab-badge-row">
                        <span className={selected === false ? "body-regular text-link" : "body-semibold colour-primary"}>
                            {label}
                        </span>
                        {
                            badge === true &&
                            <Badge
                                val={badgeVal}
                                bg={badgeBG}
                                round={true}
                                freeSize={false}
                            />
                        }
                        {
                            reset === true &&
                            <Dot
                                className=""
                                fill={colour.purplePurple02}
                                stroke={colour.indigoIndigo00}
                            />
                        }
                    </div>
                </div>
                {
                    external === true &&
                    <div className="vertical-tab-right-container">
                        <RedirectArrow
                            fill={colour.blueBlue03}
                            className=""
                            clickFunc={() => cta()}
                        />
                    </div>
                }
            </div>
        </div>
    )
};

export default VerticalTab;