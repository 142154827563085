import React, { useState } from 'react';
import '../styles/MFHHouseHackMultiUnitType.css';
import { colour } from '../styles/GlobalStyles';
import { RentAccordion } from '../components';
import { Dot, Units } from '../assets/icons';
import { FilterReset, FinancialsInput } from '../v4/components';
import { checkNaN, formatPrice } from '../functions';

function MFHHouseHackMultiUnitType(props) {
    const units = props.units;
    const unitLiving = props.unitLiving;
    const blurred = props.blurred;
    const rent = props.rent;
    const defaultRent = props.defaultRent;
    const onChangeRent = props.onChangeRent;
    const onChangeUnitLiving = props.onChangeUnitLiving;
    const resetRentEstimates = props.resetRentEstimates;
    const onChangeUnitRent = props.onChangeUnitRent;
    const property = props.property;
    const [rentAccordion, setRentAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };
    
    const clickUnit = (index, ii, e) => {
        const target = e.target;
        const targetClass = target.classList.value;
        const parentTargetClass = target.parentNode.classList.value;

        if ( targetClass.includes("financials-input-container") || parentTargetClass.includes("financials-input-container") ) {
            onChangeUnitLiving(index, ii);
        }
        else if ( targetClass.includes("financials-input-label") || parentTargetClass.includes("financials-input-label") ) {
            onChangeUnitLiving(index, ii);
        }
        else if ( targetClass.includes("financials-input") || parentTargetClass.includes("financials-input") ) {
            return;
        }
        else {
            onChangeUnitLiving(index, ii);
        }
    };
    
    return (
        <div className="mfh-house-hack-multi-unit-type-outer-container">
            <div className="mfh-house-hack-multi-unit-type-inner-container">
                <RentAccordion
                    rentAccordion={rentAccordion}
                    handleRentAccordion={handleRentAccordion}
                    property={property}
                />
                <div className="property-spacing-border">
				</div>
                <div className="mfh-house-hack-multi-unit-type-title-container relative-container">
                    <span className="body-semibold colour-primary">
                        Which unit do you plan to live in?
                    </span>
                    {
                        Number(rent) !== Number(defaultRent) &&
                        <Dot
                            className="mfh-house-hack-multi-unit-type-dot"
                            fill={colour.purplePurple02}
                            stroke={colour.indigoIndigo00}
                        />
                    }
                </div>
                <div className="mfh-house-hack-multi-unit-type-inputs-container">
                    {
                        units.map((item, index) =>
                            <div 
                                className="mfh-house-hack-multi-unit-type-element"
                                key={index}
                            >
                                <span className="body-regular colour-primary block-text">
                                    {item.label}
                                </span>
                                <div className="mfh-house-hack-multi-unit-type-grid">
                                    {
                                        item.units.map((unit, ii) =>
                                            <div
                                                key={ii}
                                                className={"mfh-house-hack-multi-unit-type-card-element " + (unitLiving[0] === index && unitLiving[1] === ii ? "mfh-house-hack-multi-unit-type-card-element-selected" : "")}
                                                onClick={(e) => clickUnit(index, ii, e)}
                                            >
                                                <div className="mfh-house-hack-multi-unit-type-inner-card-element">
                                                    <div className="mfh-house-hack-multi-unit-type-icon-container">
                                                        <Units
                                                            fill={colour.grayScaleBlack}
                                                            className="mfh-house-hack-multi-unit-type-icon"
                                                        />
                                                    </div>
                                                    <div className="mfh-house-hack-multi-unit-type-text-container">
                                                        <FinancialsInput
                                                            label={unit.label}
                                                            labelType={"default"}
                                                            info={false}
                                                            value={blurred === true ? "999" : formatPrice(unit.value)}
                                                            onChange={(text) => onChangeUnitRent(text.target.value.replaceAll(",", ""), index, ii)}
                                                            placeholder={formatPrice(defaultRent)}
                                                            type="text"
                                                            leftUnit={"$"}
                                                            rightUnit={null}
                                                            unitSwitch={false}
                                                            leftUnitSelected={true}
                                                            unitSwitchFunc={null}
                                                            disabled={blurred === true ? true : unitLiving[0] === index && unitLiving[1] === ii ? true : false}
                                                            multiline={false}
                                                            error={checkNaN(unit.value)}
                                                            onMouseEnter={null}
                                                            onMouseLeave={null}
                                                            reset={false}
                                                            resetFunc={null}
                                                            resetPosition={null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    Number(rent) !== Number(defaultRent) &&
                    <FilterReset
                        clickFunc={() => resetRentEstimates()}
                    />
                }
                <div className="property-spacing-border">
				</div>
                <div className="mfh-house-hack-multi-unit-type-total-container">
                    <FinancialsInput
                        label={"Total rent"}
                        labelType={"default"}
                        info={false}
                        value={blurred === true ? "999" : formatPrice(rent)}
                        onChange={(text) => onChangeRent(text.target.value.replaceAll(",", ""))}
                        placeholder={formatPrice(defaultRent)}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={blurred}
                        multiline={false}
                        error={checkNaN(rent)}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
            </div>
        </div>
    )
};

export default MFHHouseHackMultiUnitType;