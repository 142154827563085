import React from 'react';
import '../styles/HorizontalTab.css';

function HorizontalTab(props) {
    const label = props.label;
    const active = props.active;
    const icon = props.icon;
    const disabled = props.disabled;
    const func = props.func;
    const standardIcon = props.standardIcon;

    return (
        <div 
            className={"horizontal-tab-outer-container " + (active === true ? "horizontal-tab-selected-container " : "horizontal-tab-unselected-container ") + (disabled === true ? "disabled" : "cursor-pointer")}
            onClick={() => disabled === true ? null : func()}
        >
            <div className="horizontal-tab-inner-container">
                {
                    icon === null ?
                    null
                    :
                    standardIcon === false ?
                    icon
                    :
                    <img
                        src={icon}
                        className="horizontal-tab-icon"
                        alt={label}
                    />
                }
                <span className={active === true ? "body-semibold colour-primary" : "body-regular text-link"}>
                    {label}
                </span>
            </div>
        </div>
    )
};

export default HorizontalTab;