import React from 'react';
import '../styles/DeleteProperties.css';

function DeleteBuyBox(props) {
    const buyBoxes = props.buyBoxes;
    const buyBoxDeleteId = props.buyBoxDeleteId;

    const getBuyBoxName = () => {
        const buyBoxIndex = buyBoxes.findIndex(e => e.id === buyBoxDeleteId);
        if (buyBoxIndex !== -1) {
            return buyBoxes[buyBoxIndex].title;
        }
    };

    return (
        <div className="delete-properties-container">
            <span className="body-regular colour-primary">
                Remove <span className="body-semibold">{getBuyBoxName()}</span> from your saved Buy Boxes?
            </span>
        </div>
    )
};

export default DeleteBuyBox;