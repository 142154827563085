import React from 'react';

function Bell(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M8.8454 1.95865C8.89246 1.84848 8.9185 1.7272 8.9185 1.59983C8.9185 1.09489 8.50916 0.685547 8.00421 0.685547C7.49927 0.685547 7.08993 1.09489 7.08993 1.59983C7.08993 1.73596 7.11968 1.86513 7.17303 1.98121C4.32835 2.4675 2.31779 5.19886 2.75924 8.16622C2.81763 8.55868 2.8731 8.94272 2.9238 9.30886C2.96706 9.62122 2.91902 9.93822 2.78805 10.2251L1.99915 11.953C1.69787 12.6129 2.24893 13.3511 2.969 13.2632C4.39544 13.089 6.45634 12.876 8.00422 12.876C9.54889 12.876 11.6045 13.0882 13.0306 13.2621C13.7525 13.3502 14.3036 12.6081 13.9986 11.9479L13.225 10.2736C13.0784 9.95621 13.0325 9.60226 13.0917 9.25766C13.1609 8.85561 13.2318 8.43271 13.3028 8.00132C13.7889 5.04973 11.7127 2.36842 8.8454 1.95865ZM12.0405 9.07692C12.1091 8.67783 12.1796 8.25745 12.2503 7.82799C12.6695 5.28251 10.6875 2.97126 8.08139 2.97126C5.44329 2.97126 3.41994 5.35847 3.8143 8.00926C3.8731 8.40453 3.9291 8.79218 3.98039 9.16254C4.05196 9.67935 3.97186 10.2005 3.75837 10.6681L3.06962 12.1767C4.48934 12.0074 6.47069 11.8094 8.00422 11.8094C9.53418 11.8094 11.5098 12.0065 12.9287 12.1755L12.2567 10.721C12.0186 10.2058 11.9449 9.63264 12.0405 9.07692Z"
                fill={fill}
            />
            <path 
                d="M8.00421 15.3141C8.75795 15.3141 9.38387 14.7669 9.50632 14.048C9.53139 13.9009 9.42024 13.7728 9.27245 13.7518C8.95425 13.7067 8.40396 13.6379 8.00421 13.6379C7.60447 13.6379 7.05418 13.7067 6.73597 13.7518C6.58818 13.7728 6.47704 13.9009 6.50211 14.048C6.62455 14.7669 7.25047 15.3141 8.00421 15.3141Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Bell;