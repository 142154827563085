import React, { useState, useEffect } from 'react';
import '../styles/BestStrategy.css';
import { Wand } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function BestStrategy(props) {
    const property = props.property;
    const creativeUser = props.creativeUser;
    const [selectedStrategy, setSelectedStrategy] = useState(null);
    const scores = [
        {
            label: "Traditional Finance • Long term rental",
            score: Math.round(property.dripScoreOptions.ltrTraditional * 100),
            disabled: false
        },
        {
            label: "Traditional Finance • Househack",
            score: Math.round(property.dripScoreOptions.househackTraditional * 100),
            disabled: false
        },
        {
            label: "Traditional Finance • BRRRR",
            score: Math.round(property.dripScoreOptions.brrrrTraditional * 100),
            disabled: false
        },
        {
            label: "Creative Finance • Long term rental",
            score: Math.round(property.dripScoreOptions.ltrCreative * 100),
            disabled: !creativeUser
        },
        {
            label: "Creative Finance • Househack",
            score: Math.round(property.dripScoreOptions.househackCreative * 100),
            disabled: !creativeUser
        },
        {
            label: "Creative Finance • Lease option",
            score: Math.round(property.dripScoreOptions.leaseoptionCreative * 100),
            disabled: !creativeUser
        }
    ];
    const filteredScores = creativeUser === true ? scores : scores.filter(item => item.type === "Traditional");
    const rankedScores = filteredScores.sort((a, b) => b.score - a.score);

    useEffect(() => {
        if ( selectedStrategy === null ) {
            for (let index = 0; index < rankedScores.length; index++) {
                const element = rankedScores[index];
                if ( element.disabled === false ) {
                    setSelectedStrategy(element);
                    break;
                }
            };
        }
    }, [rankedScores, selectedStrategy]);

    return (
        <div className="best-strategy-container">
            <div className="best-strategy-icon-container">
                <Wand
                    fill={colour.grayScaleBlack}
                    className=""
                />
            </div>
            {
                selectedStrategy !== null &&
                <div className="best-strategy-text-container">
                    <span className="body-semibold colour-primary">
                        {selectedStrategy.label}
                    </span>
                    <span className="body-regular colour-secondary">
                        This acquisition & exit strategy combo 
                        looks best for this property.
                    </span>
                </div>
            }
        </div>
    )
};

export default BestStrategy;