import React from 'react';
import '../styles/MobileRadio.css';
import { colour } from '../../styles/GlobalStyles'
import { MobileRadioOutline } from '../../assets/icons';

function MobileRadio(props) {
    const label = props.label;
    const index = props.index;
    const clickFunc = props.clickFunc;
    const selected = props.selected;
    const disabled = props.disabled
    return (
        <div 
            className={"mobile-radio-container cursor-pointer " + (selected === true ? "mobile-radio-selected " : "mobile-radio-unselected ") + (disabled === true ? "disabled" : "")}
            onClick={() => disabled === true ? null : clickFunc(index)}
        >
            <MobileRadioOutline
                fill={colour.grayScaleWhite}
                className=""
                stroke={colour.blueBlue01}
                selected={selected}
                selectedFill={colour.blueBlue03}
            />
            <span className="body-regular colour-primary">
                {label}
            </span>
        </div>
    )
};

export default MobileRadio;