import React, { useEffect, useState } from 'react';
import LoginComponent from '../authentication/LoginComponent';
import '../styles/Login.css';
import { Header, Footer } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged } from '../functions';

function Login() {

	const location = useLocation();
	const state = location.state;
	const [initialCheck, setInitialCheck] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const checkLoggedIn = async () => {
			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				navigate("/home", {
					state: state
				});
			}
		};

		if ( initialCheck === false ) {
			checkLoggedIn();
			setInitialCheck(true);
		}
	}, [initialCheck, navigate, state]);

	return (
		<div className="login-outer-container">
			<Header
			 	subscriptions={null}
				users={null}
				queries={[true, true]}
				mobileNav={true}
				desktopControl={true}
			/>
			<div className="login-inner-container">
				<h1 className="heading-large-semibold colour-primary text-align-center">
					Let's get you logged in
				</h1>
				<LoginComponent 
					route={state !== null && state.route !== "" ? state.route : state !== null && state.cityId !== undefined ? `/properties-list/${state.cityId}` : ""}
					signUpRoute="/sign-up"
					state={state}
				/>
			</div>
			<Footer />
		</div>
	)
}

export default Login;