import React, { useState } from 'react';
import '../styles/ListCard.css';
import { numberFormatter } from '../../styles/GlobalStyles';
import { PropertySelect } from './';

function ListCard(props) {
    const list = props.list;
    const editMode = props.editMode;
    const selectedList = editMode === false ? false : props.selectedList.indexOf(list.id) !== -1 ? true : false;
    const setSelectedList = props.setSelectedList;
    const clickFunc = props.clickFunc;
    const [imageHover, setImageHover] = useState(false);

    const toggleImageHover = (value) => {
		setImageHover(value);
	};

    const toggleSelectedClick = () => {
        const listClone = [...props.selectedList];
        const listIndex = listClone.indexOf(list.id);
        if (listIndex === -1) {
            listClone.push(list.id);
        } else {
            listClone.splice(listIndex, 1);
        }
        setSelectedList(listClone);
    };

    return (
        <div 
            className="list-card-outer-container cursor-pointer"
            onClick={() => editMode === false ? clickFunc(list) : null}
        >
            <div className={"list-card-inner-container " + (selectedList === true ? "selected-list-card" : "")}>
                <div className="list-card-image-container">
                    <div 
                        className={"list-card-image-element " + (selectedList === true ? "list-card-selected" : "")}
                        style={{ backgroundImage: `url(${list.featuredImage})` }}
                        onMouseEnter={() => editMode === true ? toggleImageHover(true) : null}
                        onMouseLeave={() => editMode === true ? toggleImageHover(false) : null}
                    >
                        <div
							className={
								"list-card-image-hover-dark-bg " +
								(imageHover === true && editMode === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedList}
								toggleSelected={toggleSelectedClick}
							/>
						</div>
                        <div
							className={
								"list-card-image-hover-light-bg " +
								(imageHover === false && editMode === true && selectedList === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedList}
								toggleSelected={toggleSelectedClick}
							/>
						</div>
                    </div>
                </div>
                <div className="list-card-text-container">
                    <h3 className="body-semibold colour-primary">
                        {list.title}
                    </h3>
                    <span className="body-regular colour-secondary">
                        {numberFormatter.format(list.properties.length)} saved
                    </span>
                </div>
            </div>
        </div>
    )
};

export default ListCard;