import React from 'react';

function ADUBorder(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M13.1267 10.8746C13.1267 10.4604 13.4625 10.1246 13.8767 10.1246H18.5657C18.9799 10.1246 19.3157 10.4604 19.3157 10.8746V13.3131C19.3157 13.7273 18.9799 14.0631 18.5657 14.0631H13.8767C13.4625 14.0631 13.1267 13.7273 13.1267 13.3131V10.8746ZM14.2517 11.6246C14.2517 11.4175 14.4196 11.2496 14.6267 11.2496H17.8157C18.0228 11.2496 18.1907 11.4175 18.1907 11.6246V12.5631C18.1907 12.7702 18.0228 12.9381 17.8157 12.9381H14.6267C14.4196 12.9381 14.2517 12.7702 14.2517 12.5631V11.6246Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M2.43477 10.6868C2.43477 10.4797 2.26688 10.3118 2.05977 10.3118H0.434397C0.175322 10.3118 -0.00570685 10.0554 0.081046 9.81126L3.11362 1.27775C3.16676 1.12821 3.30827 1.02832 3.46697 1.02832H20.5328C20.6914 1.02832 20.833 1.12822 20.8861 1.27775L23.9187 9.81127C24.0054 10.0554 23.8244 10.3118 23.5653 10.3118H21.9394C21.7323 10.3118 21.5644 10.4797 21.5644 10.6868V18.0949C21.5644 18.302 21.3966 18.4699 21.1894 18.4699H2.80977C2.60267 18.4699 2.43477 18.302 2.43477 18.0949V10.6868ZM1.7625 9.18684C1.63296 9.18684 1.54244 9.05861 1.58582 8.93655L3.90775 2.40275C3.96089 2.25321 4.10241 2.15332 4.2611 2.15332H19.7386C19.8973 2.15332 20.0388 2.25321 20.092 2.40275L22.4139 8.93655C22.4573 9.05861 22.3668 9.18684 22.2372 9.18684H20.8144C20.6073 9.18684 20.4394 9.35473 20.4394 9.56184V16.9699C20.4394 17.177 20.2716 17.3449 20.0644 17.3449H11.2498V10.8746C11.2498 10.4604 10.9141 10.1246 10.4998 10.1246H6.74984C6.33562 10.1246 5.99984 10.4604 5.99984 10.8746V17.3449H3.93477C3.72767 17.3449 3.55977 17.177 3.55977 16.9699V9.56184C3.55977 9.35473 3.39188 9.18684 3.18477 9.18684H1.7625ZM7.49984 11.2496C7.29273 11.2496 7.12484 11.4175 7.12484 11.6246V16.8746C7.12484 17.0817 7.29273 17.2496 7.49984 17.2496H9.74984C9.95694 17.2496 10.1248 17.0817 10.1248 16.8746V11.6246C10.1248 11.4175 9.95694 11.2496 9.74984 11.2496H7.49984Z" 
                fill={fill}
            />
            <path 
                d="M4.68551 20.1576C4.68551 19.8468 4.93741 19.5949 5.24814 19.5949H18.4701C18.7809 19.5949 19.0328 19.8468 19.0328 20.1576C19.0328 20.4683 18.7809 20.7202 18.4701 20.7202H5.24814C4.93741 20.7202 4.68551 20.4683 4.68551 20.1576Z" 
                fill={fill}
            />
            <path 
                d="M2.43477 22.4083C2.43477 22.0976 2.68667 21.8457 2.99741 21.8457H21.0018C21.3125 21.8457 21.5644 22.0976 21.5644 22.4083C21.5644 22.719 21.3125 22.9709 21.0018 22.9709H2.99741C2.68668 22.9709 2.43477 22.719 2.43477 22.4083Z" 
                fill={fill}
            />
        </svg>
    )
}

export default ADUBorder;