import React from 'react';
import '../styles/PropertyCondition.css';
import { Condition, Indicator } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function PropertyCondition(props) {
    const condition = props.condition;
    const bgColour = condition === 3 ? "bg-colour-green-2" : condition === 2 ? "bg-colour-yellow-5" : condition === 1 ? "bg-colour-orange-3" : "bg-colour-red-2";
    const fill = condition === 3 ? colour.greenGreen02 : condition === 2 ? colour.yellowYellow05 : condition === 1 ? colour.orangeOrange03 : colour.redRed02;
    const label = condition === 3 ? "Remodeled" : condition === 2 ? "Maintained" : condition === 1 ? "Needs work" : "Gut rehab";
    const description = condition === 3 ? "Recently renovated or in great condition." : condition === 2 ? "Well-maintained, though not recently updated." : condition === 1 ? "May require moderate repairs or updates." : "May require serious rehab or full teardown.";
    const maxNumber = 4;

    return (
        <div className="property-condition-outer-container">
            <div className="property-condition-image-container">
                <div className={"property-condition-image-badge-container " + (bgColour)}>
                    <Condition
                        className=""
                        fill={colour.grayScaleWhite}
                    />
                </div>
            </div>
            <div className="property-condition-text-container">
                <h3 className="body-semibold colour-primary">
                    {label}
                </h3>
                <span className="body-regular colour-secondary">
                    {description}
                </span>
                <div className="property-condition-indicator-row">
                    {
                        Array.from({ length: maxNumber }).map((_, index) =>
                            <Indicator
                                index={index}
                                filledColour={fill}
                                unfilledColour={colour.greyGrey02}
                                maxNumber={maxNumber}
                                remainingNumber={condition + 1}
                                key={index}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default PropertyCondition;