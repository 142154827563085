import React from 'react';

function Wand(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none"
            className={className}
        >
            <path 
                d="M19.3398 0.149846C19.3807 -0.0499486 19.6662 -0.0499489 19.7071 0.149846L20.0306 1.72791C20.2299 2.69997 20.966 3.48607 21.9446 3.77184L23.1344 4.1193C23.3143 4.17184 23.3143 4.42672 23.1344 4.47926L21.9446 4.82673C20.966 5.1125 20.2299 5.89859 20.0306 6.87065L19.7071 8.44872C19.6662 8.64851 19.3807 8.64851 19.3398 8.44872L19.0163 6.87065C18.817 5.8986 18.0809 5.1125 17.1023 4.82673L15.9125 4.47926C15.7326 4.42672 15.7326 4.17184 15.9125 4.1193L17.1023 3.77184C18.0809 3.48607 18.817 2.69997 19.0163 1.72791L19.3398 0.149846Z" 
                fill={fill}
            />
            <path 
                d="M9.62338 3.10732L9.48017 2.37572C9.44064 2.17374 9.15169 2.17374 9.11216 2.37572L8.96896 3.10732C8.8404 3.76412 8.36549 4.29527 7.73415 4.48836L7.15515 4.66543C6.97827 4.71953 6.97827 4.96994 7.15515 5.02404L7.73414 5.20112C8.36549 5.39421 8.8404 5.92535 8.96896 6.58215L9.11216 7.31375C9.15169 7.51573 9.44064 7.51573 9.48017 7.31375L9.62338 6.58215C9.75194 5.92535 10.2268 5.39421 10.8582 5.20112L11.4372 5.02404C11.6141 4.96994 11.6141 4.71953 11.4372 4.66544L10.8582 4.48836C10.2268 4.29527 9.75194 3.76412 9.62338 3.10732Z" 
                fill={fill}
            />
            <path 
                d="M19.1688 12.9255L19.0256 12.1939C18.9861 11.9919 18.6971 11.9919 18.6576 12.1939L18.5144 12.9255C18.3859 13.5823 17.9109 14.1134 17.2796 14.3065L16.7006 14.4836C16.5237 14.5377 16.5237 14.7881 16.7006 14.8422L17.2796 15.0193C17.9109 15.2124 18.3859 15.7435 18.5144 16.4003L18.6576 17.1319C18.6971 17.3339 18.9861 17.3339 19.0256 17.1319L19.1688 16.4003C19.2974 15.7435 19.7723 15.2124 20.4036 15.0193L20.9826 14.8422C21.1595 14.7881 21.1595 14.5377 20.9826 14.4836L20.4036 14.3065C19.7723 14.1134 19.2974 13.5823 19.1688 12.9255Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M14.6701 7.81165L15.4415 8.58304C15.974 9.11557 15.974 9.97898 15.4415 10.5115L3.09926 22.8537C2.56673 23.3863 1.70332 23.3863 1.17079 22.8537L0.3994 22.0824C-0.133133 21.5498 -0.133133 20.6864 0.3994 20.1539L12.7416 7.81165C13.2742 7.27912 14.1376 7.27912 14.6701 7.81165ZM13.8987 8.58304C13.7922 8.47653 13.6195 8.47653 13.513 8.58304L11.5458 10.5503L12.7029 11.7073L14.6701 9.74012C14.7766 9.63362 14.7766 9.46093 14.6701 9.35443L13.8987 8.58304ZM1.17079 20.9253L10.7744 11.3216L11.9315 12.4787L2.32787 22.0824C2.22137 22.1889 2.04868 22.1889 1.94218 22.0824L1.17079 21.311C1.06428 21.2045 1.06428 21.0318 1.17079 20.9253Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Wand;