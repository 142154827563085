import React from 'react';
import { formatterLong } from '../styles/GlobalStyles';
import { FinancialsInput } from '../v4/components';
import { checkNaN, formatPrice } from '../functions';

function HoldingCosts(props) {
    const onChangeHoldingCosts = props.onChangeHoldingCosts;
    const onChangeHoldingPeriod = props.onChangeHoldingPeriod;
    const monthlyHoldingCosts = props.monthlyHoldingCosts;
    const holdingPeriod = props.holdingPeriod;
    const totalHoldingCosts = props.totalHoldingCosts;

    return (
        <div className="monthly-costs-fix-flip-container">
            <h3 className="body-regular colour-primary">
                Estimate holding cost
            </h3>
            <div className="monthly-costs-fix-flip-row">
                <div className="monthly-costs-holding-cost-input">
                    <FinancialsInput
                        label={"Monthly holding cost"}
                        labelType={"default"}
                        info={false}
                        value={formatPrice(monthlyHoldingCosts)}
                        onChange={(text) => onChangeHoldingCosts(text.target.value.replaceAll(",", ""))}
                        placeholder={"200"}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(monthlyHoldingCosts)}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
                <div className="monthly-costs-holding-period-input">
                    <FinancialsInput
                        label={"Holding period"}
                        labelType={"default"}
                        info={false}
                        value={formatPrice(holdingPeriod)}
                        onChange={(text) => onChangeHoldingPeriod(text.target.value.replaceAll(",", ""))}
                        placeholder={"4"}
                        type="text"
                        leftUnit={null}
                        rightUnit={"months"}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(holdingPeriod)}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
            </div>
            <div className="property-spacing-border">
            </div>
            <div className="monthly-costs-fix-flip-total-container">
                <span className="body-regular colour-primary">
                    Total holding cost
                </span>
                <span className="body-semibold colour-primary">
                    {formatterLong.format(totalHoldingCosts).replace(".00", '')}
                </span>
            </div>
        </div>
    );
};

export default HoldingCosts;