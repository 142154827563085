import React, { useState } from 'react';
import '../styles/PurchaseTab.css';
import { formatterCompact } from '../styles/GlobalStyles';
import { ValuesAccordion } from '../components';
import { CustomSlider, FinancialsInput } from '../v4/components';
import { checkNaN, formatPrice } from '../functions';

function PurchaseTab(props) {
    const onChangePrice = props.onChangePrice;
    const customPrice = props.customPrice;
    const downPayment = props.downPayment;
    const onChangeDownPayment = props.onChangeDownPayment;
    const interestRate = props.interestRate;
    const onChangeInterest = props.onChangeInterest;
    const closingCost = props.closingCost;
    const onChangeClosing = props.onChangeClosing;
    const closingPercentage = props.closingPercentage;
    const acquisitionStrategy = props.acquisitionStrategy;
    const exitStrategy = props.exitStrategy;
    const maxPercentage = props.maxPercentage;
    const changeSlider = props.changeSlider;
    const customDiscount = props.customDiscount;
    const property = props.property;
    const defaultDownPayment = props.defaultDownPayment;
    const defaultInterestRate = props.defaultInterestRate;
    const onChangeARVFinancing = props.onChangeARVFinancing;
    const arvFinancingPercentage = props.arvFinancingPercentage;
    const arvFinancing = props.arvFinancing;
    const [valuesAccordion, setValuesAccordion] = useState(true);

    const handleValuesAccordion = () => {
        setValuesAccordion(!valuesAccordion);
    };

    return (
        <div className="purchase-tab-outer-container">
            <div className="purchase-tab-inner-container">
                <ValuesAccordion
                    property={property}
                    valuesAccordion={valuesAccordion}
                    handleValuesAccordion={handleValuesAccordion}
                />
                <div className="property-spacing-border">
                </div>
                <div className="purchase-tab-price-container">
                    <FinancialsInput
                        label={"Offer price"}
                        labelType={"default"}
                        info={false}
                        value={formatPrice(customPrice)}
                        onChange={(text) => onChangePrice(text.target.value.replaceAll(",", ""))}
                        placeholder={formatPrice(property.price)}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(customPrice)}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={Number(customPrice) !== Number(property.price) ? true : false}
                        resetFunc={() => onChangePrice(property.price)}
                        resetPosition={"default"}
                    />
                </div>
                <div className="purchase-tab-slider-container relative-container">
                    <CustomSlider
                        disabled={false}
                        value={customDiscount}
                        label="Offer price"
                        onChange={changeSlider}
                        min={maxPercentage}
                        max={30}
                    />
                </div>
                <div className="property-spacing-border">
                </div>
                <div className="purchase-tab-initial-costs-row">
                    <div className="purchase-tab-financials-input relative-container">
                        <FinancialsInput
                            label={"Down payment"}
                            labelType={"default"}
                            info={false}
                            value={downPayment}
                            onChange={(text) => onChangeDownPayment(text.target.value.replaceAll(",", ""))}
                            placeholder={acquisitionStrategy === "c" ? 100 : 20}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={acquisitionStrategy === "c" ? true : false}
                            multiline={false}
                            error={acquisitionStrategy === "c" ? false : checkNaN(downPayment)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={acquisitionStrategy === "c" ? false : Number(downPayment) !== Number(defaultDownPayment) ? true : false}
                            resetFunc={() => onChangeDownPayment(defaultDownPayment)}
                            resetPosition={"bottom"}
                        />
                        <span className="body-semibold colour-error">
                            -{formatterCompact.format(downPayment * customPrice / 100).toLocaleLowerCase()}
                        </span>
                    </div>

                    {
                        Number(downPayment) !== 100 &&
                        <div className="purchase-tab-financials-other-input relative-container">
                            <FinancialsInput
                                label={"Interest rate"}
                                labelType={"default"}
                                info={false}
                                value={interestRate}
                                onChange={(text) => onChangeInterest(text.target.value.replaceAll(",", ""))}
                                placeholder={"6.5"}
                                type="text"
                                leftUnit={null}
                                rightUnit={"%"}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={checkNaN(interestRate)}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={Number(interestRate) !== Number(defaultInterestRate) ? true : false}
                                resetFunc={() => onChangeInterest(defaultInterestRate)}
                                resetPosition={"bottom"}
                            />
                        </div>
                    }
                    <div className="purchase-tab-financials-input relative-container">
                        <FinancialsInput
                            label={"Closing costs"}
                            labelType={"default"}
                            info={false}
                            value={closingPercentage}
                            onChange={(text) => onChangeClosing(text.target.value.replaceAll(",", ""))}
                            placeholder={"3"}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={false}
                            multiline={false}
                            error={checkNaN(closingPercentage)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                        <span className="body-semibold colour-error">
                            -{formatterCompact.format(closingCost).toLocaleLowerCase()}
                        </span>
                    </div>
                </div>

                {
                    exitStrategy === "brrrr" &&
                    <div className="purchase-tab-initial-costs-row">
                        <div className="purchase-tab-financials-input relative-container">
                            <FinancialsInput
                                label={"ARV Refinance"}
                                labelType={"default"}
                                info={false}
                                value={arvFinancingPercentage}
                                onChange={(text) => onChangeARVFinancing(text.target.value.replaceAll(",", ""))}
                                placeholder={"75"}
                                type="text"
                                leftUnit={null}
                                rightUnit={"%"}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={checkNaN(arvFinancingPercentage)}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={false}
                                resetFunc={null}
                                resetPosition={null}
                            />
                            <span className="body-semibold colour-green">
                                +{formatterCompact.format(arvFinancing).toLocaleLowerCase()}
                            </span>
                        </div>
                        <div className="purchase-tab-financials-other-input relative-container">
                            <FinancialsInput
                                label={"Interest rate"}
                                labelType={"default"}
                                info={false}
                                value={interestRate}
                                onChange={(text) => onChangeInterest(text.target.value.replaceAll(",", ""))}
                                placeholder={"6.5"}
                                type="text"
                                leftUnit={null}
                                rightUnit={"%"}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={checkNaN(interestRate)}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={Number(interestRate) !== Number(defaultInterestRate) ? true : false}
                                resetFunc={() => onChangeInterest(defaultInterestRate)}
                                resetPosition={"bottom"}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default PurchaseTab;