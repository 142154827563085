import React from 'react';
import '../styles/OptionChip.css';

function OptionChip(props) {
    const label = props.label;
    const leftIcon = props.leftIcon;
    const rightIcon = props.rightIcon;
    return (
        <div className="option-chip-container">
            {
                leftIcon !== null &&
                leftIcon
            }
            <span className="body-regular colour-primary">
                {label}
            </span>
            {
                rightIcon !== null &&
                rightIcon
            }
        </div>
    )
};

export default OptionChip;