import React from 'react';
import '../styles/NeighbourhoodHeatmapText.css';
import { Checkbox } from '../components';

function NeighbourhoodHeatmapText(props) {
    const dontShowNeighbourhoodHeatmapMessage = props.dontShowNeighbourhoodHeatmapMessage;
    const toggleSelected = props.toggleSelected;
    
    const learnMore = () => {
        window.open("https://coffeeclozers.notion.site/Coffee-Clozers-Maps-1541acce14b642ee8791b94c6c1cfccf", "_blank");
    };

    return (
        <div className="neighbourhood-heatmap-text-container">
            <span className="body-regular colour-primary">
                Our neighborhood heatmap is based on a proprietary 
                algorithm that combines <span className="body-semibold">population, amenity, demographic,</span>
                and <span className="body-semibold">real estate market</span> data to help you understand the city at a glance.
                <br/>
                <br/>
                Put simply, "A neighborhoods" are pricier with better amenities, while "D neighborhoods" 
                are less pricey with lower quality amenities. There is no “good” or “bad”
                - it just depends on YOUR unique investment strategy.{" "}
                <span 
                    className="body-regular-underline colour-link"
                    onClick={() => learnMore()}
                >
                    Learn more.
                </span>
            </span>
            <Checkbox
                label="Don't show this message again"
                selected={dontShowNeighbourhoodHeatmapMessage}
                type="default"
                disabled={false}
                cta={() => toggleSelected()}
            />
        </div>
    )
};

export default NeighbourhoodHeatmapText;