import React from 'react';

function Bathroom(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M3.94352 4.27956C3.94352 3.13205 4.54244 2.33805 5.26319 1.99601C5.74546 1.76715 6.29581 1.73073 6.80754 1.9427L6.29247 2.69405C5.98308 3.14536 6.31004 3.75809 6.85718 3.75234L9.15081 3.72827C9.69795 3.72253 10.012 3.10308 9.69319 2.65836L9.09104 1.81835C8.88011 1.52411 8.5811 1.34523 8.26293 1.281C7.19435 0.341581 5.81259 0.238639 4.68349 0.774451C3.47824 1.34641 2.59139 2.62412 2.59139 4.27956V8.56325H1.68997C1.44105 8.56325 1.23926 8.76504 1.23926 9.01396V11.9436C1.23926 13.2068 1.98171 14.2967 3.05405 14.8006C3.0463 14.8234 3.0421 14.8478 3.0421 14.8732V15.3239C3.0421 15.4484 3.14299 15.5493 3.26745 15.5493H3.94352C4.06798 15.5493 4.16888 15.4484 4.16888 15.3239V15.0906C4.24331 15.0959 4.31846 15.0986 4.39423 15.0986H11.6056C11.6814 15.0986 11.7565 15.0959 11.831 15.0906V15.3239C11.831 15.4484 11.9318 15.5493 12.0563 15.5493H12.7324C12.8568 15.5493 12.9577 15.4484 12.9577 15.3239V14.8732C12.9577 14.8478 12.9535 14.8234 12.9458 14.8006C14.0181 14.2967 14.7606 13.2068 14.7606 11.9436V9.01396C14.7606 8.76504 14.5588 8.56325 14.3099 8.56325H3.94352V4.27956Z" 
                fill={fill}
            />
            <path 
                d="M8.56542 4.56079C8.55023 4.5396 8.5186 4.53993 8.50385 4.56144L8.12091 5.12006C7.88641 5.46212 8.13422 5.92652 8.54892 5.92217C8.96362 5.91782 9.20163 5.44831 8.96001 5.11125L8.56542 4.56079Z" 
                fill={fill}
            />
            <path 
                d="M7.15172 6.13893C7.16647 6.11742 7.1981 6.11709 7.21329 6.13828L7.60788 6.68874C7.8495 7.0258 7.61149 7.4953 7.19679 7.49966C6.78209 7.50401 6.53428 7.0396 6.76878 6.69754L7.15172 6.13893Z" 
                fill={fill}
            />
            <path 
                d="M9.6922 6.81435C9.677 6.79315 9.64538 6.79348 9.63063 6.81499L9.24768 7.37361C9.01319 7.71567 9.261 8.18007 9.6757 8.17572C10.0904 8.17137 10.3284 7.70187 10.0868 7.3648L9.6922 6.81435Z" 
                fill={fill}
            />
        </svg>
    )
}

export default Bathroom;