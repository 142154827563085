import React from 'react';

function Expand(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M7.74036 0C7.05029 0 6.49087 0.559417 6.49087 1.24949C6.49087 1.93957 7.05029 2.49899 7.74036 2.49899H11.734L2.49899 11.734V7.74036C2.49899 7.05029 1.93957 6.49087 1.24949 6.49087C0.559417 6.49087 0 7.05029 0 7.74036V14.7505C0 15.4406 0.559417 16 1.24949 16H8.25963C8.94971 16 9.50913 15.4406 9.50913 14.7505C9.50913 14.0604 8.94971 13.501 8.25963 13.501H4.26603L13.501 4.26603V8.25963C13.501 8.94971 14.0604 9.50913 14.7505 9.50913C15.4406 9.50913 16 8.94971 16 8.25963V1.24949C16 0.559417 15.4406 0 14.7505 0H7.74036Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Expand;