import React, { useEffect, useRef, useState } from 'react';
import '../styles/BuyBoxMarket.css';
import { CountyMap, PredictiveSearch, SearchInput, SelectionChip, ZipcodeFilter } from './';
import { cloudFunctionV2, formatCityNames, getDocument } from '../../functions';
import { counties } from '../../counties';
import { ExitScreen, Loading } from '../../components';

function BuyBoxMarket(props) {
    const market = props.market;
    const onChangeMarketText = props.onChangeMarketText;
    const chosenMarket = props.chosenMarket;
    const setChosenMarket = props.setChosenMarket;
    const entireMarket = props.entireMarket;
    const setEntireMarket = props.setEntireMarket;
    const zipcodes = props.zipcodes;
    const setZipcodes = props.setZipcodes;
    const marketError = props.marketError;
    const zipcodeError = props.zipcodeError;
    const setMarketError = props.setMarketError;
    const setZipcodeError = props.setZipcodeError;
    const editMode = props.editMode;
    const buyBox = props.buyBox;
    const setBuyBoxName = props.setBuyBoxName;
    const allZipCodes = props.allZipCodes;
    const setAllZipCodes = props.setAllZipCodes;
    const mapRef = useRef(null);
    const [centre, setCentre] = useState({ lat: 37.0902, lng: -95.7129 });
    const [zoom, setZoom] = useState(4);
    const [boundaries, setBoundaries] = useState([]);
    const [activeSearching, setActiveSearching] = useState(false);
    const [cityCharacters, setCityCharacters] = useState(null);
    const [cityNames, setCityNames] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countyList, setCountyList] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        const queryZipCodes = async() => {
			if ( allZipCodes.length > 0 ) {
				return;
			}

			const colRef = buyBox.msaCode.includes("CTY") ? "County ZIP Codes" : "City ZIP Codes";
			const queryData = await getDocument(colRef, buyBox.msaCode);
			if ( queryData.status === 200 ) {
				const data = queryData.data.data;
				data.sort((a, b) => a.localeCompare(b));
				setAllZipCodes(data);
			}
		};

        if ( allZipCodes.length === 0 && editMode === true ) {
            queryZipCodes();
        }
    }, [allZipCodes.length, buyBox?.msaCode, editMode, setAllZipCodes]);

    const finalResults = [
        {
            label: "CITY",
            results: cityList
        },
        {
            label: "COUNTY",
            results: countyList
        }
    ];

    const marketOptions = [
        {
            label: (chosenMarket === null && editMode === false) ? "" : ((editMode === true && buyBox.msaCode.includes("CTY")) || (editMode === false && chosenMarket.type === "county")) ? "Entire county" : "Entire city",
            value: true,
            selected: entireMarket
        },
        {
            label: "Zip Codes",
            value: false,
            selected: !entireMarket
        }
    ];

    const changeMarketOption = (val) => {
        setEntireMarket(val);
    };

    const changeText = (val) => {
        onChangeMarketText(val);
        
        setChosenMarket(null);
        searchCounties(val);
        searchCities(val);
    };

    const searchCities = async(val) => {
		if ( val.length >= 3 ) {
			const newVal = val.slice(0, 3).toUpperCase();
			if ( newVal === cityCharacters ) {
				matchUpCities(val, cityNames);
			}
			else {
				setActiveSearching(true);
				setCityCharacters(newVal);
				
				// Query city names
				const colRef = "City Names";
				const queryData = await getDocument(colRef, newVal);
				if ( queryData.status === 200 ) {
					const data = queryData.data.data;
                    const filteredRegions = data.filter(e => e.regionId !== null);
					setCityNames(filteredRegions);
					matchUpCities(val, filteredRegions);
				}
				setActiveSearching(false);
			}
		}
		else {
			setCityCharacters(null);
			setCityList([]);
			setCityNames([]);
		}
	};

    const matchUpCities = (val, data) => {
        const citiesClone = [...data];
		const newArray = [];
		const lowerCaseVal = val.toLowerCase();

		for (let index = 0; index < citiesClone.length; index++) {
			const element = citiesClone[index];
			const cityName = element.regionName;

			const mainCityFullName = cityName.replace(".", "").replace(",", "").toLowerCase();
			const mainCityFullNameComma = cityName.replace(".", "").toLowerCase();
			const noFullStopVal = lowerCaseVal.replace(".", "");

			if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) ) {
				newArray.push(element);
			}
		};
		const sortedData = newArray.sort((a, b) => a.regionName.localeCompare(b.regionName));

        const finalArray = [];
        for (let index = 0; index < sortedData.length; index++) {
            if ( index < 2 ) {
                const element = sortedData[index];
                element.label = element.regionName;
                element.type = "city";
                finalArray.push(element);
            }
        }

		setCityList(finalArray);
    };

    const searchCounties = async(val) => {
		const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
                    newArray.push(counties[index]);
                }
            }
        };

        for (let index = 0; index < newArray.length; index++) {
            const element = newArray[index];
            element.label = await formatCityNames(element);
            element.type = "county";
        }

		setCountyList(newArray);
	}

    const pickGeography = async(item) => {
        setLoading(true);
        setZipcodes([]);
        setAllZipCodes([]);
        onChangeMarketText(item.label);
        const state = item.type === "city" ? item.regionName.slice(-2) : item.state;
        const checkedState = state === "D.C." ? "DC" : state;
        const docCityName = "District of Columbia";
        const boundariesURL = process.env.REACT_APP_BOUNDARIES_URL;
        const params = {
            city: state === "D.C." ? docCityName : item.type === "county" ? item.county : item.regionName.slice(0, -4),
            state: checkedState,
            county: item.type === "city" ? false : true
        };
        const query = await cloudFunctionV2(boundariesURL, params);
        if ( query.status === 200 ) {
            setBuyBoxName(`My ${item.label} buy box`);
            setMarketError(false);
            setChosenMarket(item);
            setError(false);
            setCentre(query.centre);
            setBoundaries(query.data);
            if ( query.data.length > 0 ) {
                setZoom(9);
            }

            const colRef = item.type === "city" ? "City ZIP Codes" : "County ZIP Codes";
            const placeId = item.type === "city" ? item.regionId : `CTY${item.code}`;
			const queryData = await getDocument(colRef, placeId);
			if ( queryData.status === 200 ) {
				const data = queryData.data.data;
				data.sort((a, b) => a.localeCompare(b));
				setAllZipCodes(data);
			}

            setCityList([]);
			setCityNames([]);
            setCountyList([]);
        }
        else {
            setChosenMarket(null);
            setError(true);
            setCityList([]);
			setCityNames([]);
            setCountyList([]);
        }
        setLoading(false);
    };

    return (
        <div className="buy-box-market-container relative-container">
            {
                loading === true &&
                <Loading />
            }
            {
                dropdownOpen === true &&
                <ExitScreen
                    clickScreen={() => setDropdownOpen(false)}
                />
            }
            <h2 className="body-semibold colour-primary">
                Choose your market
            </h2>
            <div className="buy-box-market-input-container relative-container">
                <SearchInput
                    value={market}
                    onChange={(text) => changeText(text.target.value)}
                    placeholder="Search a city, or county"
                    type="text"
                    clearInput={() => changeText("")}
                    magnifyingGlassFunc={null}
                />
                {
                    market.length > 0 && loading === false && chosenMarket === null && (finalResults[0].results.length !== 0 || finalResults[1].results.length !== 0) &&
                    <PredictiveSearch
                        loading={activeSearching}
                        results={finalResults}
                        selectItem={(item) => pickGeography(item)}
                        nested={true}
                    />
                }
            </div>
            {
                error === true &&
                <span className="body-regular colour-error">
                    There was an error retrieving the boundaries for this location.
                    Please contact us for more details.
                </span>
            }
            {
                marketError === true &&
                <span className="body-regular colour-error">
                    Please select your market here
                </span>
            }
            {
                (chosenMarket !== null || editMode === true) &&
                <div className="buy-box-market-chosen-container">
                    <span className="body-semibold colour-primary">
                        Choose how to segment your market
                    </span>
                    <div className="buy-box-market-chosen-options-row">
                        {
                            marketOptions.map((item, index) =>
                                <SelectionChip
                                    key={index}
                                    label={item.label}
                                    selected={item.selected}
                                    disabled={false}
                                    func={() => changeMarketOption(item.value)}
                                    index={index}
                                    leftIcon={null}
                                    rightIcon={null}
                                />
                            )
                        }
                    </div>
                </div>
            }
            {
                ((chosenMarket !== null && entireMarket === false && editMode === false) || (entireMarket === false && editMode === true)) &&
                <ZipcodeFilter
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                    zipcodes={zipcodes}
                    allZipCodes={allZipCodes}
                    entireMarket={entireMarket}
                    zipcodeError={zipcodeError}
                    switchLabel={marketOptions[0].label}
                    setZipcodes={setZipcodes}
                    setZipcodeError={setZipcodeError}
                    buyBox={true}
                    reset={false}
                    resetFilterType={null}
                />
            }
            <div className="buy-box-market-map-container">
                <CountyMap
                    centre={centre}
                    mapRef={mapRef}
                    zoom={zoom}
                    boundaries={boundaries}
                />
            </div>
        </div>
    )
};

export default BuyBoxMarket;