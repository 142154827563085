import React from 'react';
import '../styles/ListingTypeFilter.css';
import { FilterReset, SelectionChip, MobileRadio } from './';

function ListingTypeFilter(props) {
    const listingType = props.listingType;
    const setListingType = props.setListingType;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;

    const options = [
        {
            label: "Any",
            value: "",
            selected: listingType === "" ? true : false
        },
        {
            label: "Agent listed",
            value: "agent",
            selected: listingType === "agent" ? true : false
        },
        {
            label: "Owner listed (FSBO)",
            value: "fsbo",
            selected: listingType === "fsbo" ? true : false
        }
    ];

    const changeListingType = (index) => {
        setListingType(options[index].value);
    };

    return (
        <div className="listing-type-filter-container">
            <div className="listing-type-filter-title-container">
                <h3 className="body-semibold colour-primary">
                    Listing type
                </h3>
            </div>
            <div className="listing-type-filter-body-container mobile-none">
                {
                    options.map((item, index) =>
                        <SelectionChip
                            key={index}
                            label={item.label}
                            selected={item.selected}
                            disabled={false}
                            func={changeListingType}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                        />
                    )
                }
            </div>
            <div className="listing-type-filter-body-container-mobile desktop-none">
                {
                    options.map((item, index) =>
                        <MobileRadio
                            label={item.label}
                            selected={item.selected}
                            index={index}
                            clickFunc={() => changeListingType(index)}
                            key={index}
                            disabled={false}
                        />
                    )
                }
            </div>
            {
                reset &&
                <FilterReset
                    clickFunc={() => resetFilterType("listingType")}
                />
            }
        </div>
    )
};

export default ListingTypeFilter;