import React, { useEffect, useState } from 'react';
import '../styles/VerifyEmailComplete.css';
import { Header, Footer, LoadingStatic } from '../components';
import { checkOnAuthStateChanged, cloudFunctionV2, recordEvent } from '../functions';
import { useNavigate, useSearchParams } from 'react-router-dom';

function VerifyEmailComplete() {

    const [searchParams] = useSearchParams({});
    const mode = searchParams.get('mode');
	const oobCode = searchParams.get('oobCode');
	const apiKey = searchParams.get('apiKey');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Checking Email Verification | Coffee Clozers";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                if ( user.emailVerified === true ) {
                    document.title = "Email Verification Complete | Coffee Clozers";
                    setLoading(false);
                    recordEvent("Email Verified", {});
                    setTimeout(() => {
                        navigate("/welcome");
                    }, 4000)
                }
                else if ( mode === null || oobCode === null || apiKey === null ) {
                    navigate("/email-verification-error");
                }
                else {
                    const gatewayURL = process.env.REACT_APP_VERIFY_USER;
                    const params = {
                        userId: user.userId,
                        oobCode: oobCode,
                        mode: mode,
                        apiKey: apiKey
                    };
                    const queryData = await cloudFunctionV2(gatewayURL, params);
                    if ( queryData.status === 200 ) {
                        document.title = "Email Verification Complete | Coffee Clozers";
                        recordEvent("Email Verified", {});
                        setLoading(false);
                        setTimeout(() => {
                            navigate("/welcome");
                        }, 4000)
                    }
                    else {
                        setLoading(false);
                        navigate("/email-verification-error");
                    }
                }
            }
            else {
                navigate("/sign-up");
            }
        };

        fetchUserData();
    }, [navigate, apiKey, mode, oobCode]);

    const url = "https://firebasestorage.googleapis.com/v0/b/refi-787d3.appspot.com/o/email-verification-done.png?alt=media&token=3f22dee6-a951-4f67-acb7-5ee73ac8bc4e";

    return (
        <div className="verify-email-complete-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
                desktopControl={false}
            />
            {
                loading === true ?
                <div className="verify-email-complete-inner-container relative-container">
                    <LoadingStatic />
                </div>
                :
                <div className="verify-email-complete-inner-container">
                    <div className="verify-email-complete-image-container">
                        <img
                            src={url}
                            className="verify-email-complete-image"
                            alt="Pabs again"
                        />
                    </div>
                    <div className="verify-email-complete-text-container">
                        <h1 className="heading-large-semibold colour-primary text-align-center">
                            Thank you!
                        </h1>
                        <span className="body-regular colour-secondary block-text text-align-center">
                            Email verified. Redirecting you now...
                        </span>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default VerifyEmailComplete;