import React, { useState, useEffect } from 'react';
import '../styles/StrategyScores.css';
import { StrategyScoreCard }  from './';

function StrategyScores(props) {
    const property = props.property;
    const creativeUser = props.creativeUser;
    const [finalScores, setFinalScores] = useState([]);
    const scores = [
        {
            type: "Traditional",
            label: "LTR",
            score: Math.round(property.dripScoreOptions.ltrTraditional * 100),
            selected: false,
            disabled: false
        },
        {
            type: "Traditional",
            label: "Househack",
            score: Math.round(property.dripScoreOptions.househackTraditional * 100),
            selected: false,
            disabled: false
        },
        {
            type: "Traditional",
            label: "BRRRR",
            score: Math.round(property.dripScoreOptions.brrrrTraditional * 100),
            selected: false,
            disabled: false
        },
        {
            type: "Creative",
            label: "LTR",
            score: Math.round(property.dripScoreOptions.ltrCreative * 100),
            selected: false,
            disabled: !creativeUser
        },
        {
            type: "Creative",
            label: "Househack",
            score: Math.round(property.dripScoreOptions.househackCreative * 100),
            selected: false,
            disabled: !creativeUser
        },
        {
            type: "Creative",
            label: "Lease Option",
            score: Math.round(property.dripScoreOptions.leaseoptionCreative * 100),
            selected: false,
            disabled: !creativeUser
        }
    ];
    const filteredScores = creativeUser === true ? scores : scores.filter(item => item.type === "Traditional");
    const rankedScores = filteredScores.sort((a, b) => b.score - a.score);

    useEffect(() => {
        if ( finalScores.length === 0 ) {
            const newArray = [];
            for (let index = 0; index < rankedScores.length; index++) {
                const element = rankedScores[index];
                if ( element.disabled === false && newArray.length < 3 ) {
                    if ( newArray.length === 0 ) {
                        element.selected = true;
                    }
                    newArray.push(element);
                }
            };
            setFinalScores(newArray);
        }
    }, [finalScores.length, rankedScores]);

    return (
        <div className="strategy-scores-outer-container">
            <h2 className="heading-small-regular colour-primary">
                Score
            </h2>
            <div className="strategy-scores-row">
                {
                    finalScores.map((item, index) =>
                        <StrategyScoreCard
                            item={item}
                            selected={item.selected}
                            key={index}
                        />
                    )
                }
            </div>
        </div>
    )
};

export default StrategyScores;