import React from 'react';
import '../styles/Choicebox.css';

function Choicebox(props) {
    const label = props.label;
    const selected = props.selected;
    const cta = props.cta;
    const icon = props.icon;
    const disabled = props.disabled;
    const heading = props.heading;
    const body = props.body
    const standardIcon = props.standardIcon;

    return (
        <div 
            className={"choicebox-outer-container " + (selected === true ? "choicebox-selected-container " : "choicebox-unselected-container ") + (disabled === true ? "disabled" : "cursor-pointer")}
            onClick={() => cta()}
        >
            <div className="choicebox-inner-container">
                {
                    icon === null && heading !== null ? 
                    <h2 className="body-semibold colour-primary">
                        {heading}
                    </h2>
                    :
                    standardIcon === true ?
                    <img
                        src={icon}
                        className=""
                        alt={label}
                    />
                    :
                    icon
                }
                {
                    body === null ?
                    <span className="body-regular colour-primary block-text text-align-center">
                        {label}
                    </span>
                    :
                    <span className="label-regular colour-secondary">
                        {body}
                    </span>
                }
            </div>
        </div>
    )
};

export default Choicebox;