import React, { useState } from 'react';
import '../styles/NeighbourhoodGradeFilter.css';
import { Dropdown, FilterReset } from './';
import { ExitScreen } from '../../components';

function NeighbourhoodGradeFilter(props) {
    const neighbourhoodGrades = props.neighbourhoodGrades;
    const setNeighbourhoodGrades = props.setNeighbourhoodGrades;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const [minDropdownOpen, setMinDropdownOpen] = useState(false);
    const [maxDropdownOpen, setMaxDropdownOpen] = useState(false);

    const neighbourhoodGradeOptions = [
        {
            label: "A",
            value: 8,
            disabled: (neighbourhoodGrades[1] <= 8 && minDropdownOpen === true) ? true : false
        },
        {
            label: "B+",
            value: 7,
            disabled: (neighbourhoodGrades[1] <= 7 && minDropdownOpen === true) || (neighbourhoodGrades[0] >= 7 && maxDropdownOpen === true) ? true : false
        },
        {
            label: "B",
            value: 6,
            disabled: (neighbourhoodGrades[1] <= 6 && minDropdownOpen === true) || (neighbourhoodGrades[0] >= 6 && maxDropdownOpen === true) ? true : false
        },
        {
            label: "B-",
            value: 5,
            disabled: (neighbourhoodGrades[1] <= 5 && minDropdownOpen === true) || (neighbourhoodGrades[0] >= 5 && maxDropdownOpen === true) ? true : false
        },
        {
            label: "C+",
            value: 4,
            disabled: (neighbourhoodGrades[1] <= 4 && minDropdownOpen === true) || (neighbourhoodGrades[0] >= 4 && maxDropdownOpen === true) ? true : false
        },
        {
            label: "C",
            value: 3,
            disabled: (neighbourhoodGrades[1] <= 3 && minDropdownOpen === true) || (neighbourhoodGrades[0] >= 3 && maxDropdownOpen === true) ? true : false
        },
        {
            label: "C-",
            value: 2,
            disabled: (neighbourhoodGrades[1] <= 2 && minDropdownOpen === true) || (neighbourhoodGrades[0] >= 2 && maxDropdownOpen === true) ? true : false
        },
        {
            label: "D",
            value: 1,
            disabled: (neighbourhoodGrades[1] <= 1 && minDropdownOpen === true) || (neighbourhoodGrades[0] >= 1 && maxDropdownOpen === true) ? true : false
        }
    ];

    const toggleMinDropdown = () => {
        setMinDropdownOpen(!minDropdownOpen);
        setMaxDropdownOpen(false);
    };

    const toggleMaxDropdown = () => {
        setMaxDropdownOpen(!maxDropdownOpen);
        setMinDropdownOpen(false);
    };

    const selectMinOption = (item) => {
        const newGrades = [...neighbourhoodGrades];
        newGrades[0] = item.value;
        setNeighbourhoodGrades(newGrades);
        setMinDropdownOpen(false);
    };

    const selectMaxOption = (item) => {
        const newGrades = [...neighbourhoodGrades];
        newGrades[1] = item.value;
        setNeighbourhoodGrades(newGrades);
        setMaxDropdownOpen(false);
    };

    const checkSelected = (value) => {
        const valIndex = neighbourhoodGradeOptions.findIndex(e => e.value === value);
        return valIndex;
    };

    const findText = (value) => {
        const valIndex = neighbourhoodGradeOptions.findIndex(e => e.value === value);
        return neighbourhoodGradeOptions[valIndex].label;
    };

    const closeAllSelects = () => {
        setMinDropdownOpen(false);
        setMaxDropdownOpen(false);
    };
    
    return (
        <div className="neighbourhood-grades-filter-container relative-container">
            {
                (minDropdownOpen === true || maxDropdownOpen === true) &&
                <ExitScreen
                    clickScreen={() => closeAllSelects()}
                />
            }
            <h3 className="body-semibold colour-primary">
                Neighborhood grade
            </h3>
            <div className="neighbourhood-grades-filter-row">
                <div className="neighbourhood-grades-filter-element">
                    <Dropdown
                        open={maxDropdownOpen}
                        icon={null}
                        label={findText(neighbourhoodGrades[1])}
                        disabled={false}
                        options={neighbourhoodGradeOptions}
                        multiselect={false}
                        toggleDropdown={toggleMaxDropdown}
                        cta={selectMaxOption}
                        sidealign={"left"}
                        topalign={"bottom"}
                        indexSelect={true}
                        selectedIndex={checkSelected(neighbourhoodGrades[1])}
                    />
                </div>
                <span className="body-regular colour-secondary block-text text-align-center">
                    -
                </span>
                <div className="neighbourhood-grades-filter-element">
                    <Dropdown
                        open={minDropdownOpen}
                        icon={null}
                        label={findText(neighbourhoodGrades[0])}
                        disabled={false}
                        options={neighbourhoodGradeOptions}
                        multiselect={false}
                        toggleDropdown={toggleMinDropdown}
                        cta={selectMinOption}
                        sidealign={"left"}
                        topalign={"bottom"}
                        indexSelect={true}
                        selectedIndex={checkSelected(neighbourhoodGrades[0])}
                    />
                </div>
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("neighbourhoodGrades")}
                />
            }
        </div>
    )
};

export default NeighbourhoodGradeFilter;