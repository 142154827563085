import React from 'react';
import '../styles/CustomSettingElement.css';
import { Button, Dropdown, IconButton, SettingsElement } from './';
import { Exit, Plus } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function CustomSettingElement(props) {
    const item = props.item;
    const customIndex = props.customIndex;
    const dropdownOpen = props.dropdownOpen;
    const setDropdownOpen = props.setDropdownOpen;
    const removeCustomCity = props.removeCustomCity;
    const chooseSetting = props.chooseSetting;
    const innerWidth = props.innerWidth;
    const addSetting = props.addSetting;
    const data = props.data;
    const currentSettings = data.settings === undefined ? [] : data.settings;
    const customSettingChangeVal = props.customSettingChangeVal;
    const saveCustomSetting = props.saveCustomSetting;
    const monthlyCostFields = props.monthlyCostFields;
    const customCities = props.customCities;
    const defaultCustomCities = props.defaultCustomCities;

    const types = [
        {
            label: "Insurance",
            disabled: currentSettings.findIndex(e => e.title === "Insurance") === -1 ? false : true
        },
        {
            label: "Property taxes",
            disabled: currentSettings.findIndex(e => e.title === "Property taxes") === -1 ? false : true
        },
        {
            label: "Vacancy rate allocation",
            disabled: currentSettings.findIndex(e => e.title === "Vacancy rate allocation") === -1 ? false : true
        },
        {
            label: "Management fees",
            disabled: currentSettings.findIndex(e => e.title === "Management fees") === -1 ? false : true
        },
        {
            label: "Maintenance",
            disabled: currentSettings.findIndex(e => e.title === "Maintenance") === -1 ? false : true
        },
        {
            label: "Other costs",
            disabled: currentSettings.findIndex(e => e.title === "Other costs") === -1 ? false : true
        }
    ];

    const toggleDropdown = (index) => {
        if ( dropdownOpen === null ) {
            setDropdownOpen([customIndex, index])
        }
        else {
            setDropdownOpen(null);
        }
    };

    const customSettingItem = (setting) => {
        const fieldIndex = monthlyCostFields.findIndex(e => e.title === setting.title);
        if ( fieldIndex === -1 ) {
            return setting;
        }
        else {
            let defaultVal = "";
            const defaultCustomCitiesIndex = defaultCustomCities.findIndex(e => e.label === customCities[customIndex].label);
            if ( defaultCustomCitiesIndex !== -1 ) {
                const defaultCustomCitySettingIndex = defaultCustomCities[defaultCustomCitiesIndex].settings.findIndex(e => e.title === setting.title);
                if ( defaultCustomCitySettingIndex !== -1 ) {
                    defaultVal = defaultCustomCities[defaultCustomCitiesIndex].settings[defaultCustomCitySettingIndex].value;
                }
            }

            const field = monthlyCostFields[fieldIndex];
            field.value = setting.value;
            const settingDisabled = setting.value === defaultVal ? true : false;
            field.defaultSwitch = true;
            field.disabled = settingDisabled;
            field.onChange = (val) => customSettingChangeVal(val, customCities[customIndex].label, setting.title);
            field.cta = () => saveCustomSetting(customCities[customIndex].label, setting.title);
            return field;
        }
    };

    return (
        <div className="custom-setting-element-container">
            <div className="custom-setting-element-inner-container relative-container">
                <IconButton
                    size="large"
                    icon={<Exit className="custom-setting-exit-icon" fill={colour.grayScaleBlack} clickFunc={() => null} />}
                    disabled={false}
                    clickFunc={() => removeCustomCity(customIndex)}
                    standardIcon={false}
                    contextTooltip={false}
                    contextTooltipBG={null}
                    contextTooltipText={null}
                    customClass={"custom-setting-exit-icon-button mobile-none"}
                />
                <div className="custom-setting-title-container">
                    <h2 className="body-regular colour-primary">
                        {item.label}
                    </h2>
                </div>
                <div className="settings-spacing-container"></div>
                <div className="custom-setting-element-body-container">
                    {
                        item.settings.map((setting, index) =>
                            <React.Fragment 
                                key={index}
                            >
                                {
                                    setting.title === "" ?
                                    <div className="custom-setting-element-dropdown-container">
                                        <Dropdown
                                            open={dropdownOpen === null ? false : dropdownOpen[0] === customIndex && dropdownOpen[1] === index ? true : false}
                                            icon={null}
                                            label={"Choose setting"}
                                            disabled={false}
                                            options={types}
                                            multiselect={false}
                                            toggleDropdown={() => toggleDropdown(index)}
                                            cta={(val, fieldIndex) => chooseSetting(customIndex, index, fieldIndex)}
                                            sidealign={"left"}
                                            topalign={"bottom"}
                                            indexSelect={false}
                                            selectedIndex={null}
                                        />
                                    </div>
                                    :
                                    <SettingsElement
                                        item={customSettingItem(setting)}
                                        dropdownOpen={false}
                                        setDropdownOpen={null}
                                        innerWidth={innerWidth}
                                    />
                                }
                                <div className="settings-spacing-container"></div>
                            </React.Fragment>
                        )
                    }
                </div>
                {
                    item.settings.findIndex(e => e.title === "") === -1 &&
                    <div className="custom-setting-element-add-setting">
                        <Button
                            buttonType={innerWidth < 1000 ? "secondary-button" : "primary-button"}
                            size={innerWidth < 1000 ? "large" : "small"}
                            text={"Add setting"}
                            cta={() => addSetting(customIndex)}
                            disabled={false}
                            leftIcon={<Plus className="" fill={innerWidth < 1000 ? colour.grayScaleBlack : colour.grayScaleWhite} />}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                }
            </div>
        </div>
    )
};

export default CustomSettingElement;