import React from 'react';

function SingleFamily(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M7.26072 0.756846C7.46143 0.547437 7.7335 0.447192 8.00067 0.457228C8.26784 0.447638 8.53947 0.547883 8.73929 0.756958L15.2901 7.59834C15.6621 7.98683 15.6487 8.60324 15.2602 8.97522C14.8717 9.34721 14.2553 9.33383 13.8833 8.94534L8.00023 2.80088L2.11669 8.94523C1.7447 9.33372 1.12829 9.3471 0.739804 8.97522C0.351315 8.60324 0.337934 7.98672 0.70992 7.59834L7.26072 0.756846Z" 
                fill={fill} 
            />
            <path 
                d="M14.6268 9.47366C14.4703 9.47778 14.3133 9.44444 14.1701 9.37386V14.6296C14.1701 14.8818 13.9658 15.0863 13.7134 15.0863H13.2567V9.37721C13.2567 9.33015 13.2424 9.28644 13.2183 9.2502C13.1773 9.18909 13.1077 9.14884 13.0283 9.14884H2.9802C2.85397 9.14884 2.75183 9.25109 2.75183 9.37721V15.0863H2.2951C2.17423 15.0863 2.0645 15.0395 1.98288 14.9629C1.9249 14.9084 1.88074 14.8389 1.85755 14.7605C1.84506 14.719 1.83837 14.6751 1.83837 14.6296V9.38345C1.6943 9.45024 1.5373 9.48024 1.38164 9.47288V14.6296C1.38164 15.1341 1.79064 15.5431 2.2951 15.5431H13.7134C14.2178 15.5431 14.6268 15.1341 14.6268 14.6296V9.47366Z" 
                fill={fill}
            />
        </svg>
    )
}

export default SingleFamily;