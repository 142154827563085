import React from 'react';
import '../styles/Dropdown.css';

function Dropdown(props) {
    const open = props.open;
    const icon = props.icon;
    const label = props.label;
    const disabled = props.disabled;
    const options = props.options;
    const multiselect = props.multiselect;
    const cta = props.cta;
    const sidealign = props.sidealign;
    const topalign = props.topalign;
    const toggleDropdown = props.toggleDropdown;
    const indexSelect = props.indexSelect;
    const selectedIndex = props.selectedIndex;

    return (
        <div className={"dropdown-outer-container relative-container " + (open === true ? "dropdown-open-container " : "dropdown-closed-container ") + (disabled === true ? "disabled" : "cursor-pointer")}>
            <div 
                className="dropdown-inner-container"
                onClick={() => toggleDropdown()}
            >
                <div className="dropdown-left-container">
                    {
                        icon === null ?
                        null
                        :
                        icon
                    }
                    <span className="body-regular colour-primary block-text text-align-left">
                        {label}
                    </span>
                </div>
                <div className="dropdown-arrow-container">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="8" 
                        height="5" 
                        viewBox="0 0 8 5" 
                        fill="none"
                        className={"dropdown-arrow " + (open === true ? "reverse" : "")}
                    >
                        <path 
                            d="M4 5L7.4641 0.5H0.535898L4 5Z" 
                            fill="#292621"
                        />
                    </svg>
                </div>
            </div>
            {
                open === true &&
                <div className={"dropdown-options-container " + (sidealign === "left" ? "dropdown-options-align-left " : "dropdown-options-align-right ") + (topalign === "bottom" ? "dropdown-options-align-bottom" : "dropdown-options-align-top")}>
                    {
                        options.map((item, index) =>
                            item.labelOnly === true ?
                            <div 
                                className="dropdown-option-element-container dropdown-option-label"
                                key={index}
                            >
                                {
                                    typeof item === "string" ?
                                    <span className="label-semibold-caps colour-quaternary">
                                        {item}
                                    </span>
                                    :
                                    <span className="label-semibold-caps colour-quaternary">
                                        {item.label}
                                    </span>
                                }
                            </div>
                            :
                            <div 
                                className={"dropdown-option-element-container " + ((item.selected === true || (indexSelect === true && selectedIndex === index)) && multiselect === false ? "dropdown-option-selected " : (item.selected === false || (indexSelect === true && selectedIndex !== index)) && multiselect === false ? "dropdown-option-unselected " : (item.selected === true || (indexSelect === true && selectedIndex === index)) ? "dropdown-option-selected-multi-select " : "dropdown-option-unselected-multi-select " ) + (item.disabled === true ? "disabled" : "cursor-pointer")}
                                key={index}
                                onClick={() => item.disabled === true ? null : cta(item, index)}
                            >
                                {
                                    multiselect === true &&
                                    <div className="checkbox-container">
                                        {
                                            (item.selected === true) || (indexSelect === true && selectedIndex === index) ?
                                            <div className="checkbox-icon">
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                }
                                {
                                    typeof item === "string" ?
                                    <span className={((item.selected === true) || (indexSelect === true && selectedIndex === index) ) && multiselect === false ? "body-semibold colour-white" : "body-regular colour-primary"}>
                                        {item}
                                    </span>
                                    :
                                    <span className={((item.selected === true) || (indexSelect === true && selectedIndex === index) ) && multiselect === false ? "body-semibold colour-white" : "body-regular colour-primary"}>
                                        {item.label}
                                    </span>
                                }
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
};

export default Dropdown;