import React from 'react';
import '../styles/EmergingAreaTooltip.css';
import { Leaves } from '../assets/images';
import { recordEvent } from '../functions';
import { IconButton } from '../v4/components';
import { Exit } from '../assets/icons';
import { colour } from "../styles/GlobalStyles";

function EmergingAreaTooltip(props) {
    const setEmergingAreaModal = props.setEmergingAreaModal;
    const setEmergingAreaTract = props.setEmergingAreaTract;
    const title = props.title;
    const tract = props.tract;
    const emergingMarkets = props.emergingMarkets;
    const setEmergingMarkets = props.setEmergingMarkets;

    const exitButtonCTA = () => {
        setEmergingAreaModal(false);
        setEmergingAreaTract(null);

        const tractIndex = emergingMarkets.findIndex(e => e.region.tractId === tract.region.tractId);
        if (tractIndex !== -1) {
            const updatedEmergingMarkets = [...emergingMarkets];
            updatedEmergingMarkets.splice(tractIndex, 1);
            setEmergingMarkets(updatedEmergingMarkets);
        }
    };

    const cta = () => {
        setEmergingAreaTract(tract);
        setEmergingAreaModal(true);
        recordEvent("Emerging Area Tooltip CTA", {});
    };

    return (
        <div className="emerging-area-tooltip-container">
            <IconButton
                size="medium"
                icon={<Exit fill={colour.grayScaleBlack} className="" clickFunc={() => exitButtonCTA()} />}
                disabled={false}
                clickFunc={() => exitButtonCTA()}
                standardIcon={false}
                contextTooltip={false}
                contextTooltipBG={null}
                contextTooltipText={null}
                customClass={null}
            />
            <div className="emerging-area-tooltip-inner-container relative-container">
                <div className="emerging-area-tooltip-image-container">
                    <img
                        src={Leaves}
                        className="emerging-area-tooltip-image"
                        alt="Leaves"
                    />
                </div>
                <div className="emerging-area-tooltip-text-container">
                    <span className="body-regular colour-primary block-text margin-x-x-small">
                        {title}
                    </span>
                    <span 
                        className="body-regular-underline colour-link block-text"
                        onClick={() => cta()}
                    >
                        Click for more
                    </span>
                </div>
            </div>
        </div>
    )
};

export default EmergingAreaTooltip;