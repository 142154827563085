import React, { useEffect, useState, useRef } from 'react';
import '../styles/NewBuyBox.css';
import { Header, Footer, Loading, SideNavigation, ExitScreen } from '../components';
import { checkOnAuthStateChanged, formatBuyBoxFilters, getDocument, getUserDocuments, makeId, setData, submitError, recordEvent } from '../functions'
import { BathroomFilter, BedroomFilter, BudgetFilter, Button, BuyBoxFrequency, BuyBoxMarket, BuyBoxName, BuyBoxStrategies, ConditionFilter, DaysOnMarketFilter, DistressFlagsFilter, EstimatedInterestFilter, ListingTypeFilter, MoreLocationFilter, MortgageTypeFilter, NeighbourhoodGradeFilter, OpportunitiesFilter, OwnershipLengthFilter, OwnerTypeFilter, PropertyTypeFilter, ROIFilter, SquareFootageFilter, VerticalTab, YearBuiltFilter } from '../v4/components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Arrow, ChevronThick, Indicator, SectionIncomplete, TickCircle } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';
import { defaultVals } from '../defaults';

function NewBuyBox() {
    const location = useLocation();
    const state = location.state;
    const path = location.pathname;
    const editMode = path.includes("edit") ? true : false;
    const editedBuyBox = editMode === true && state !== null && state.buyBox !== undefined ? state.buyBox : null;
    const filters = editedBuyBox !== null ? editedBuyBox.filters : null;
    const [saveLoading, setSaveLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userData, setUserData] = useState(null);
    const [creativeUser, setCreativeUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [selectedOption, setSelectedOption] = useState(0);
    const [buyBoxName, setBuyBoxName] = useState(editedBuyBox === null ? "" : editedBuyBox.title);
    const [nameError, setNameError] = useState(false);
    const [buyBoxFrequency, setBuyBoxFrequency] = useState(editedBuyBox === null ? "daily" : editedBuyBox.frequency);
    const [market, setMarket] = useState(editedBuyBox === null ? "" : editedBuyBox.msaTitle);
    const [chosenMarket, setChosenMarket] = useState(null);
    const [entireMarket, setEntireMarket] = useState(editedBuyBox === null ? true : editedBuyBox.entireMarket);
    const [zipcodes, setZipcodes] = useState(editedBuyBox === null ? [] : editedBuyBox.entireMarket === false ? editedBuyBox.zipcodes : []);
    const [marketError, setMarketError] = useState(false);
    const [zipcodeError, setZipcodeError] = useState(false);
    const [acquisitionStrategy, setAcquisitionStrategy] = useState(editedBuyBox === null ? null : editedBuyBox.acquisitionStrategy);
    const [exitStrategy, setExitStrategy] = useState(editedBuyBox === null ? null : editedBuyBox.exitStrategy);
    const [strategyError, setStrategyError] = useState(false);
    const [budgetRange, setBudgetRange] = useState(editedBuyBox === null || filters.budgetRange === undefined ? defaultVals.budgetRange : filters.budgetRange);
    const [budgetError, setBudgetError] = useState(false);
    const amendedPropertyTypes = editedBuyBox === null || filters.propertyTypes === undefined ? null : defaultVals.propertyTypes.map(type => ({ 
        ...type, 
        included: filters.propertyTypes.split(',').includes(type.abbreviation) 
    }));
    const [propertyTypes, setPropertyTypes] = useState(editedBuyBox === null || filters.propertyTypes === undefined ? defaultVals.propertyTypes : amendedPropertyTypes);
    const [propertyTypeError, setPropertyTypeError] = useState(false);
    const [units, setUnits] = useState(editedBuyBox === null || filters.units === undefined ? defaultVals.units : filters.units);
    const [unitExactMatch, setUnitExactMatch] = useState(editedBuyBox === null || filters.unitExactMatch === undefined ? defaultVals.unitExactMatch : filters.unitExactMatch);
    const [bedrooms, setBedrooms] = useState(editedBuyBox === null || filters.bedrooms === undefined ? defaultVals.bedrooms : filters.bedrooms);
    const [bedroomExactMatch, setBedroomExactMatch] = useState(editedBuyBox === null || filters.bedroomExactMatch === undefined ? defaultVals.bedroomExactMatch : filters.bedroomExactMatch);
    const [bathrooms, setBathrooms] = useState(editedBuyBox === null || filters.bathrooms === undefined ? defaultVals.bathrooms : filters.bathrooms);
	const [bathroomExactMatch, setBathroomExactMatch] = useState(editedBuyBox === null || filters.bathroomExactMatch === undefined ? defaultVals.bathroomExactMatch : filters.bathroomExactMatch);
    const [squareFootage, setSquareFootage] = useState(editedBuyBox === null || filters.squareFootage === undefined ? defaultVals.squareFootage : filters.squareFootage);
    const [yearBuilt, setYearBuilt] = useState(editedBuyBox === null || filters.constructionYears === undefined ? defaultVals.constructionYears : filters.constructionYears);
    const [yearBuiltError, setYearBuiltError] = useState(false);
    const [onlyADU, setOnlyADU] = useState(editedBuyBox === null || filters.onlyADU === undefined ? defaultVals.onlyADU : filters.onlyADU);
    const [onlyExtraBedroom, setOnlyExtraBedroom] = useState(editedBuyBox === null || filters.onlyExtraBedroom === undefined ? defaultVals.onlyExtraBedroom : filters.onlyExtraBedroom);
    const [oversizedLot, setOversizedLot] = useState(editedBuyBox === null || filters.oversizedLot === undefined ? defaultVals.oversizedLot : filters.oversizedLot);
    const [hideFloodzone, setHideFloodzone] = useState(editedBuyBox === null || filters.hideFloodzone === undefined ? defaultVals.hideFloodzone : filters.hideFloodzone);
    const [hideHOA, setHideHOA] = useState(editedBuyBox === null || filters.hideHOA === undefined ? defaultVals.hideHOA : filters.hideHOA);
    const [conditionValues, setConditionValues] = useState(editedBuyBox === null || filters.conditionValues === undefined ? defaultVals.conditionValues : filters.conditionValues);
    const [neighbourhoodGrades, setNeighbourhoodGrades] = useState(editedBuyBox === null || filters.neighbourhoodGrades === undefined ? defaultVals.neighbourhoodGrades : filters.neighbourhoodGrades);
    const [ownerType, setOwnerType] = useState(editedBuyBox === null || filters.ownerType === undefined ? defaultVals.ownerType : filters.ownerType);
    const [ownershipLength, setOwnershipLength] = useState(editedBuyBox === null || filters.ownershipLength === undefined ? defaultVals.ownershipLength : filters.ownershipLength);
    const [distressFlags, setDistressFlags] = useState(editedBuyBox === null || filters.distressFlags === undefined ? defaultVals.distressFlags : filters.distressFlags);
    const [estimatedInterest, setEstimatedInterest] = useState(editedBuyBox === null || filters.estimatedInterest === undefined ? defaultVals.estimatedInterest : filters.estimatedInterest);
    const [equityLevel, setEquityLevel] = useState(editedBuyBox === null || filters.equityLevel === undefined ? defaultVals.equityLevel : filters.equityLevel);
    const [mortgageType, setMortgageType] = useState(editedBuyBox === null || filters.mortgageType === undefined ? defaultVals.mortgageType : filters.mortgageType);
    const [listingType, setListingType] = useState(editedBuyBox === null || filters.listingType === undefined ? defaultVals.listingType : filters.listingType);
    const [daysOnMarket, setDaysOnMarket] = useState(editedBuyBox === null || filters.daysOnMarket === undefined ? defaultVals.daysOnMarket : filters.daysOnMarket);
    const [backOnMarket, setBackOnMarket] = useState(editedBuyBox === null || filters.backOnMarket === undefined ? defaultVals.backOnMarket : filters.backOnMarket);
    const [recentPriceReduction, setRecentPriceReduction] = useState(editedBuyBox === null || filters.recentPriceReduction === undefined ? defaultVals.priceReduction : filters.recentPriceReduction);
    const [minCoCR, setMinCoCR] = useState(editedBuyBox === null || filters.minCoCR === undefined ? defaultVals.cocr : filters.minCoCR);
    const [cocrError, setCocrError] = useState(false);
    const [allZipCodes, setAllZipCodes] = useState([]);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [buyBoxFrequencyDropdown, setBuyBoxFrequencyDropdown] = useState(false);
    const navigate = useNavigate();
    const bodyRef = useRef(null);

    const allParams = {
        budgetRange: budgetRange,
        neighbourhoodGrades: neighbourhoodGrades,
        condition: conditionValues,
        propertyTypes: propertyTypes,
        units: units,
        unitExactMatch: unitExactMatch,
        bedrooms: bedrooms,
        bedroomExactMatch: bedroomExactMatch,
        bathrooms: bathrooms,
        bathroomExactMatch: bathroomExactMatch,
        squareFootage: squareFootage,
        constructionYears: yearBuilt,
        onlyADU: onlyADU,
        onlyExtraBedroom: onlyExtraBedroom,
        oversizedLot: oversizedLot,
        hideFloodzone: hideFloodzone,
        hideHOA: hideHOA,
        ownerType: ownerType,
        ownershipLength: ownershipLength,
        distressFlags: distressFlags,
        estimatedInterest: estimatedInterest,
        equityLevel: equityLevel,
        mortgageType: mortgageType,
        listingType: listingType,
        daysOnMarket: daysOnMarket,
        backOnMarket: backOnMarket,
        recentPriceReduction: recentPriceReduction,
        minCoCR: minCoCR
    };

    const checkSectionDone = (section) => {
        if ( section === 0 ) {
            if ( 
                ( editMode === true ) ||
                ( chosenMarket !== null && entireMarket === true ) ||
                ( chosenMarket !== null && entireMarket === false && zipcodes.length > 0 )
            ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( section === 1 ) {
            if ( acquisitionStrategy !== null && exitStrategy !== null ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( section === 2 ) {
            if ( 
                    Number(budgetRange[0]) <= Number(budgetRange[1]) &&
                    (propertyTypes[0].included === true || propertyTypes[1].included === true)
                ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( section === 3 ) {
            if ( 
                ownerType !== defaultVals.ownerType ||
                ownershipLength !== defaultVals.ownershipLength ||
                distressFlags !== defaultVals.distressFlags ||
                estimatedInterest !== defaultVals.estimatedInterest ||
                equityLevel !== defaultVals.equityLevel
            ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( section === 4 ) {
            if ( 
                listingType !== defaultVals.listingType ||
                daysOnMarket !== defaultVals.daysOnMarket ||
                backOnMarket !== defaultVals.backOnMarket ||
                recentPriceReduction !== defaultVals.priceReduction
            ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( section === 5 ) {
            if ( minCoCR !== defaultVals.cocr ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( section === 6 ) {
            if ( buyBoxName !== "" ) {
                return true;
            }
            else {
                return false;
            }
        }
    };

    const tabs = [
        {
            label: "Market",
            icon: checkSectionDone(0) === true ? <TickCircle fill={selectedOption === 0 ? colour.grayScaleBlack : colour.blueBlue03} className="" /> : <SectionIncomplete fill={selectedOption === 0 ? colour.grayScaleBlack : colour.blueBlue03} className="" />
        },
        {
            label: "Strategies",
            icon: checkSectionDone(1) === true ? <TickCircle fill={selectedOption === 1 ? colour.grayScaleBlack : colour.blueBlue03} className="" /> : <SectionIncomplete fill={selectedOption === 1 ? colour.grayScaleBlack : colour.blueBlue03} className="" />
        },
        {
            label: "Property",
            icon: checkSectionDone(2) === true ? <TickCircle fill={selectedOption === 2 ? colour.grayScaleBlack : colour.blueBlue03} className="" /> : <SectionIncomplete fill={selectedOption === 2 ? colour.grayScaleBlack : colour.blueBlue03} className="" />
        },
        {
            label: "Ownership",
            icon: checkSectionDone(3) === true ? <TickCircle fill={selectedOption === 3 ? colour.grayScaleBlack : colour.blueBlue03} className="" /> : <SectionIncomplete fill={selectedOption === 3 ? colour.grayScaleBlack : colour.blueBlue03} className="" />
        },
        {
            label: "Listing",
            icon: checkSectionDone(4) === true ? <TickCircle fill={selectedOption === 4 ? colour.grayScaleBlack : colour.blueBlue03} className="" /> : <SectionIncomplete fill={selectedOption === 4 ? colour.grayScaleBlack : colour.blueBlue03} className="" />
        },
        {
            label: "ROI",
            icon: checkSectionDone(5) === true ? <TickCircle fill={selectedOption === 5 ? colour.grayScaleBlack : colour.blueBlue03} className="" /> : <SectionIncomplete fill={selectedOption === 5 ? colour.grayScaleBlack : colour.blueBlue03} className="" />
        },
        {
            label: "Details",
            icon: checkSectionDone(6) === true ? <TickCircle fill={selectedOption === 6 ? colour.grayScaleBlack : colour.blueBlue03} className="" /> : <SectionIncomplete fill={selectedOption === 6 ? colour.grayScaleBlack : colour.blueBlue03} className="" />
        },
    ];

    useEffect(() => {
        document.title = `Coffee Clozers | ${editMode === true ? "Edit" : "New"} Buy Box`;

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
			    const isAdmin = adminIds.includes(user.userId);
                if ( isAdmin === true ) {
                    setCreativeUser(true);
                }

                const today = new Date();
                const todaySeconds = today.getTime() / 1000;

                const collections = [
                    "Users",
                    "Subscriptions"
                ];
                const queryData = await getUserDocuments(collections, user.userId);
                if ( queryData[0].status === 200 ) {
                    setUserData(queryData[0].data);

                    const freeTrial = queryData[0].data.freeTrial;
                    const endDate = freeTrial[0].endDate;
                    if ( endDate.seconds > todaySeconds ) {
                        setCreativeUser(true);
                    }
                }
                if ( queryData[1].status === 200 ) {
                    setSubscriptions(queryData[1].data);
                    const creativeSubscriptionIndex = queryData[1].data.findIndex(e => e.endDate.seconds > todaySeconds && e.creative === true);
                    if ( creativeSubscriptionIndex !== -1 ) {
                        setCreativeUser(true);
                    }
                }
            }
            else {
                navigate("/sign-up");
            }
            setLoading(false);
        };

        if ( (editMode === false) || (state !== null && state.buyBox !== undefined) ) {
            fetchUserData();
        }
        else {
            navigate("/buy-boxes");
        }

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);
    }, [navigate, editMode, state]);

    const backToBuyBoxes = () => {
        navigate("/buy-boxes");
    };

    const backStep = () => {
        setSelectedOption(selectedOption - 1);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 200);
    };

    const selectOption = (index) => {
        setSelectedOption(index);
        setTimeout(() => {
            if ( bodyRef.current ) {
                bodyRef.current.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }, 200);
    };

    const onChangeBuyBoxName = (val) => {
        if ( val.length <= 50 ) {
            setBuyBoxName(val);
        }
    };

    const onChangeBuyBoxFrequency = (val) => {
        if ( typeof val === "string" ) {
            setBuyBoxFrequency(val);
        }
        else {
            setBuyBoxFrequency(val.value);
        }
    };

    const onChangeMarketText = (val) => {
        setMarket(val);
    };

    const onChangeAcquisitionStrategy = (val) => {
        setAcquisitionStrategy(val);
    };

    const onChangeExitStrategy = (val) => {
        setExitStrategy(val);
    };

    const nextStep = () => {
        setSelectedOption(selectedOption + 1);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 200);
    };
    
    const checkDisabled = () => {
        if ( selectedOption === 0 && ( chosenMarket === null && editMode === false ) ) {
            return true;
        }
        else if ( selectedOption === 0 && ( chosenMarket !== null && entireMarket === false && zipcodes.length === 0 ) ) {
            return true;
        }
        else if ( selectedOption === 1 && ( acquisitionStrategy === null || exitStrategy === null ) ) {
            return true;
        }
        else if ( selectedOption === 2 && (budgetRange[0] !== "" && budgetRange[1] !== "" && Number(budgetRange[0]) > Number(budgetRange[1])) ) {
            return true;
        }
        else if ( selectedOption === 2 && (propertyTypes[0].included === false && propertyTypes[1].included === false) ) {
            return true;
        }
        else if ( selectedOption === 2 && (yearBuilt[0] !== "" && yearBuilt[1] !== "" && Number(yearBuilt[0]) > Number(yearBuilt[1])) ) {
            return true;
        }
        else if ( selectedOption === 5 && isNaN(minCoCR) === true ) {
            return true;
        }
        else if ( selectedOption === 6 && (buyBoxName === "" ) ) {
            return true;
        }
        else {
            return false;
        }
    };

    const saveBuyBox = async() => {
        if ( chosenMarket === null && editMode === false ) {
            setSelectedOption(0);
            setMarketError(true);
            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setMarketError(false);
        }

        if ( entireMarket === false && zipcodes.length === 0 ) {
            setSelectedOption(0);
            setZipcodeError(true);
            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setZipcodeError(false);
        }

        if ( acquisitionStrategy === null || exitStrategy === null ) {
            setStrategyError(true);
            setSelectedOption(1);
            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setStrategyError(false);
        }

        if ( (budgetRange[0] !== "" && budgetRange[1] !== "" && Number(budgetRange[0]) > Number(budgetRange[1])) ) {
            setBudgetError(true);
            setSelectedOption(2);

            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setBudgetError(false);
        }

        if ( propertyTypes[0].included === false && propertyTypes[1].included === false ) {
            setSelectedOption(2);
            setPropertyTypeError(true);

            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: 300,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setPropertyTypeError(false);
        }

        if ( (yearBuilt[0] !== "" && yearBuilt[1] !== "" && Number(yearBuilt[0]) > Number(yearBuilt[1])) ) {
            setYearBuiltError(true);
            setSelectedOption(2);

            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: bodyRef.current.scrollHeight,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setYearBuiltError(false);
        }

        if ( isNaN(minCoCR) === true ) {
            setCocrError(true);
            setSelectedOption(5);

            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setCocrError(false);
        }

        if ( buyBoxName === "" ) {
            setNameError(true);
            setSelectedOption(6);

            setTimeout(() => {
                if ( bodyRef.current ) {
                    bodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setNameError(false);
        }

        setSaveLoading(true);
        const id = editMode === true ? editedBuyBox.id : await makeId(10);
        const msaCode = editMode === true && chosenMarket === null ? editedBuyBox.msaCode : chosenMarket.type === "county" ? `CTY${chosenMarket.code}` : chosenMarket.regionId;
        const msaTitle = editMode === true && chosenMarket === null ? editedBuyBox.msaTitle : chosenMarket.label;
        const created = editMode === true ? editedBuyBox.created : new Date();
        const activeListings = editMode === true ? editedBuyBox.activeListings : 0;
        const lastUpdate = editMode === true ? editedBuyBox.lastUpdate : new Date();
        const newListings = editMode === true ? editedBuyBox.newListings : 0;
        const sent = editMode === true ? editedBuyBox.sent : [];
        const totalListings = editMode === true ? editedBuyBox.totalListings : 0;

        const newBuyBox = {
            acquisitionStrategy: acquisitionStrategy,
            activeListings: activeListings,
            created: created,
            entireMarket: entireMarket,
            exitStrategy: exitStrategy,
            filters: {},
            frequency: buyBoxFrequency,
            id: id,
            lastUpdate: lastUpdate,
            metroArea: true,
            msaCode: msaCode,
            msaTitle: msaTitle,
            newListings: newListings,
            sent: sent,
            title: buyBoxName,
            totalListings: totalListings,
            zipcodes: entireMarket === true ? [] : zipcodes
        };

        const newBuyBoxFilters = await formatBuyBoxFilters(allParams);
        newBuyBox.filters = newBuyBoxFilters;

        const colRef = "Buy Boxes V4";
        const queryData = await getDocument(colRef, userId);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            const checkBuyBoxIndex = data.findIndex(e => e.id === id);
            if ( checkBuyBoxIndex !== -1 ) {
                data.splice(checkBuyBoxIndex, 1);
            }
            data.unshift(newBuyBox);
            const saveData = await setData(colRef, userId, data);
            if ( saveData.status === 200 ) {
                navigate("/buy-boxes");
            }
            else {
                console.log(saveData.message);
                const newId = await makeId(10);
                const errorObject = {
                    error: saveData.message,
                    page: `${editMode === true ? "Edit" : "New"} Buy Box`,
                    date: new Date(),
                    userId: userId,
                }
                await submitError("Errors", newId, errorObject)
                alert("There was an error saving the buy box. Please contact us for more details.");
            }
        }
        else {
            const data = [
                newBuyBox
            ];
            const saveData = await setData(colRef, userId, data);
            if ( saveData.status === 200 ) {
                navigate("/buy-boxes");
            }
            else {
                console.log(saveData.message);
                const newId = await makeId(10);
                const errorObject = {
                    error: saveData.message,
                    page: `${editMode === true ? "Edit" : "New"} Buy Box`,
                    date: new Date(),
                    userId: userId,
                }
                await submitError("Errors", newId, errorObject)
                alert("There was an error saving the buy box. Please contact us for more details.");
            }
        }
        recordEvent("Buy Box Created", {
            id: id
        })
        setSaveLoading(false);
    };

    return (
        <div className="new-buy-box-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="new-buy-box-inner-container">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                    subscriptions={subscriptions}
                />
                <div className="new-buy-box-body-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        <div className="new-buy-box-inner-body-container">
                            {
                                buyBoxFrequencyDropdown === true &&
                                <ExitScreen
                                    clickScreen={() => setBuyBoxFrequencyDropdown(false)}
                                />
                            }
                            {
                                saveLoading === true &&
                                <Loading />
                            }
                            <div className="new-buy-box-top-container">
                                <div className="new-buy-box-title-container">
                                    <div 
                                        className="text-button button-row gap-x-small new-buy-box-chevron-container mobile-none"
                                        onClick={() => backToBuyBoxes()}    
                                    >
                                        <ChevronThick
                                            fill={colour.blueBlue03}
                                            className="new-buy-box-chevron"
                                        />
                                        <span className="body-regular text-link">
                                            Back to buy boxes
                                        </span>
                                    </div>
                                    <div 
                                        className="text-button button-row gap-x-small new-buy-box-chevron-container desktop-none"
                                        onClick={() => selectedOption === 0 ? backToBuyBoxes() : backStep()}    
                                    >
                                        <Arrow
                                            fill={colour.blueBlue03}
                                            className="new-buy-box-chevron"
                                        />
                                        <span className="body-regular text-link">
                                            Back
                                        </span>
                                    </div>
                                    <div className="new-buy-box-heading-row">
                                        <div className="new-buy-box-heading-left-container">
                                            <h1 className="heading-large-semibold colour-primary">
                                                {editMode === true ? "Edit" : "New"} buy box
                                            </h1>
                                            <div className="new-buy-box-details-row mobile-none">
                                                <BuyBoxName
                                                    title={buyBoxName}
                                                    onChangeBuyBoxName={onChangeBuyBoxName}
                                                    error={nameError}
                                                />
                                                <BuyBoxFrequency
                                                    selectedOption={buyBoxFrequency}
                                                    changeSelectedOption={onChangeBuyBoxFrequency}
                                                    buyBoxFrequencyDropdown={buyBoxFrequencyDropdown}
                                                    setBuyBoxFrequencyDropdown={setBuyBoxFrequencyDropdown}
                                                />
                                            </div>
                                        </div>
                                        <div className="new-buy-box-button-container mobile-none">
                                            <Button
                                                buttonType="primary-button"
                                                size="large"
                                                text={"Save"}
                                                cta={saveBuyBox}
                                                disabled={saveLoading}
                                                leftIcon={null}
                                                leftIconClass=""
                                                rightIcon={null}
                                                rightIconClass=""
                                                alt=""
                                                standardIcon={false}
                                                badge={false}
                                                badgeVal={null}
                                                badgeBG={null}
                                                buttonRef={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="buy-box-spacing-border mobile-none">
                                </div>
                            </div>
                            <div className="new-buy-box-indicator-row desktop-none">
                                {
                                    Array.from({ length: tabs.length }).map((_, index) =>
                                        <Indicator
                                            index={index}
                                            filledColour={colour.blueBlue02}
                                            unfilledColour={colour.grayScaleGray01}
                                            maxNumber={tabs.length}
                                            remainingNumber={selectedOption + 1}
                                            key={index}
                                        />
                                    )
                                }
                            </div>
                            <div className="new-buy-box-options-row">
                                <div className="new-buy-box-options-left-container mobile-none">
                                    {
                                        tabs.map((item, index) =>
                                            index === 6 ?
                                            null
                                            :
                                            <VerticalTab
                                                selected={index === selectedOption ? true : false}
                                                label={item.label}
                                                icon={item.icon}
                                                cta={() => selectOption(index)}
                                                external={false}
                                                account={false}
                                                userData={null}
                                                key={index}
                                                standardIcon={false}
                                                badge={false}
                                                reset={false}
                                            />
                                        )
                                    }
                                </div>
                                <div 
                                    className="new-buy-box-options-right-container"
                                    ref={bodyRef}
                                >
                                    {
                                        selectedOption === 0 &&
                                        <BuyBoxMarket
                                            market={market}
                                            onChangeMarketText={onChangeMarketText}
                                            chosenMarket={chosenMarket}
                                            setChosenMarket={setChosenMarket}
                                            entireMarket={entireMarket}
                                            setEntireMarket={setEntireMarket}
                                            zipcodes={zipcodes}
                                            setZipcodes={setZipcodes}
                                            marketError={marketError}
                                            zipcodeError={zipcodeError}
                                            setMarketError={setMarketError}
                                            setZipcodeError={setZipcodeError}
                                            editMode={editMode}
                                            setBuyBoxName={setBuyBoxName}
                                            buyBox={(state === null || state.buyBox === undefined) ? null : state.buyBox}
                                            allZipCodes={allZipCodes}
                                            setAllZipCodes={setAllZipCodes}
                                        />
                                    }
                                    {
                                        selectedOption === 1 &&
                                        <BuyBoxStrategies
                                            acquisitionStrategy={acquisitionStrategy}
                                            exitStrategy={exitStrategy}
                                            onChangeAcquisitionStrategy={onChangeAcquisitionStrategy}
                                            onChangeExitStrategy={onChangeExitStrategy}
                                            creativeUser={creativeUser}
                                            strategyError={strategyError}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <BudgetFilter
                                            label="Price"
                                            budgetRange={budgetRange}
                                            setBudgetRange={setBudgetRange}
                                            error={budgetError}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <NeighbourhoodGradeFilter
                                            neighbourhoodGrades={neighbourhoodGrades}
                                            setNeighbourhoodGrades={setNeighbourhoodGrades}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <ConditionFilter
                                            conditionValues={conditionValues}
                                            setConditionValues={setConditionValues}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <PropertyTypeFilter
                                            propertyTypes={propertyTypes}
                                            setPropertyTypes={setPropertyTypes}
                                            units={units}
                                            setUnits={setUnits}
                                            exactMatch={unitExactMatch}
                                            setExactMatch={setUnitExactMatch}
                                            error={propertyTypeError}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <BedroomFilter
                                            bedrooms={bedrooms}
                                            setBedrooms={setBedrooms}
                                            exactMatch={bedroomExactMatch}
                                            setExactMatch={setBedroomExactMatch}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <BathroomFilter
                                            bathrooms={bathrooms}
                                            setBathrooms={setBathrooms}
                                            exactMatch={bathroomExactMatch}
                                            setExactMatch={setBathroomExactMatch}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <SquareFootageFilter
                                            squareFootage={squareFootage}
                                            setSquareFootage={setSquareFootage}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <YearBuiltFilter
                                            yearBuilt={yearBuilt}
                                            setYearBuilt={setYearBuilt}
                                            reset={false}
                                            resetFilterType={null}
                                            error={yearBuiltError}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <OpportunitiesFilter
                                            onlyADU={onlyADU}
                                            setOnlyADU={setOnlyADU}
                                            onlyExtraBedroom={onlyExtraBedroom}
                                            setOnlyExtraBedroom={setOnlyExtraBedroom}
                                            oversizedLot={oversizedLot}
                                            setOversizedLot={setOversizedLot}
                                        />
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 2 &&
                                        <MoreLocationFilter
                                            hideFloodzone={hideFloodzone}
                                            setHideFloodzone={setHideFloodzone}
                                            hideHOA={hideHOA}
                                            setHideHOA={setHideHOA}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <OwnerTypeFilter
                                            ownerType={ownerType}
                                            setOwnerType={setOwnerType}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <OwnershipLengthFilter
                                            ownershipLength={ownershipLength}
                                            setOwnershipLength={setOwnershipLength}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <DistressFlagsFilter
                                            distressFlags={distressFlags}
                                            setDistressFlags={setDistressFlags}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <EstimatedInterestFilter
                                            estimatedInterest={estimatedInterest}
                                            setEstimatedInterest={setEstimatedInterest}
                                            resetInterest={false}
                                            resetFilterTypeInterest={null}
                                            equityLevel={equityLevel}
                                            setEquityLevel={setEquityLevel}
                                            resetEquity={false}
                                            resetFilterTypeEquity={null}
                                        />
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 3 &&
                                        <MortgageTypeFilter
                                            mortgageType={mortgageType}
                                            setMortgageType={setMortgageType}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 4 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 4 &&
                                        <ListingTypeFilter
                                            listingType={listingType}
                                            setListingType={setListingType}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 4 &&
                                        <div className="buy-box-spacing-border">
                                        </div>
                                    }
                                    {
                                        selectedOption === 4 &&
                                        <DaysOnMarketFilter
                                            daysOnMarket={daysOnMarket}
                                            setDaysOnMarket={setDaysOnMarket}
                                            backOnMarket={backOnMarket}
                                            setBackOnMarket={setBackOnMarket}
                                            recentPriceReduction={recentPriceReduction}
                                            setRecentPriceReduction={setRecentPriceReduction}
                                            reset={false}
                                            resetFilterType={null}
                                        />
                                    }
                                    {
                                        selectedOption === 5 &&
                                        <ROIFilter
                                            minCoCR={minCoCR}
                                            setMinCoCR={setMinCoCR}
                                            reset={false}
                                            resetFilterType={null}
                                            error={cocrError}
                                        />
                                    }
                                    {
                                        selectedOption === 6 && innerWidth < 1000 &&
                                        <BuyBoxName
                                            title={buyBoxName}
                                            onChangeBuyBoxName={onChangeBuyBoxName}
                                            error={nameError}
                                        />
                                    }
                                    {
                                        selectedOption === 6 && innerWidth < 1000 &&
                                        <BuyBoxFrequency
                                            selectedOption={buyBoxFrequency}
                                            changeSelectedOption={onChangeBuyBoxFrequency}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        loading === false &&
                        <div className="new-buy-box-mobile-button desktop-none">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={selectedOption === 6 ? "Save" : "Next"}
                                cta={selectedOption === 6 ? saveBuyBox : nextStep}
                                disabled={checkDisabled()}
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                rightIconClass=""
                                alt=""
                                standardIcon={false}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default NewBuyBox;