import React, { useState, useEffect } from 'react';
import '../styles/JoinAffiliate.css';
import { Header, Footer, Loading } from '../components';
import { useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, getDocument, makeId, recordEvent, sendEmail, setData } from '../functions';
import { Button, FinancialsInput } from '../v4/components';

function JoinAffiliate() {
    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [socialLinks, setSocialLinks] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Coffee Clozers | Affiliates";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);

                const colRef = "Users";
                const queryData = await getDocument(colRef, user.userId);
                if ( queryData.status === 200 ) {
                    setUserData(queryData.data.data);
                    setEmail(queryData.data.data.email);
                    setFirstName(queryData.data.data.firstName);
                    setLastName(queryData.data.data.lastName);
                }
            }
        };

        fetchUserData();
    }, []);

    const openEmail = () => {
        window.location.href = `mailto:info@coffeeclozers.com?`;
    };

    const submit = async() => {
        setLoading(true);
        const dynamicTemplate = "d-b8986fced76642708d8df10c3d141b65";
        const contactEmail = "liam.maher@coffeeclozers.com";

        const saveObject = {
            userId: userId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            socialLinks: socialLinks,
            date: new Date()
        };

        const colRef = "Affiliate Requests";
        const docRef = await makeId(15);
        const saveData = await setData(colRef, docRef, saveObject);
        if ( saveData.status === 200 ) {
            recordEvent("Affiliate Request Submitted", {
                userId: userId,
                socialLinks: socialLinks
            });

            const msg = {
				to: [contactEmail, "ariel.herrera@coffeeclozers.com", "maddy@coffeeclozers.com", "noah@coffeeclozers.com"],
				from: contactEmail,
				templateId: dynamicTemplate,
				dynamic_template_data: {
					userId: userId,
					email: email,
                    socialLinks: socialLinks,
                    firstName: firstName,
                    lastName: lastName,
				}
			};
			await sendEmail(msg);
        }

        setLoading(false);
        setSubmitted(true);
    };

    const complete = () => {
        navigate("/home");
    };

    return (
        <div className="bg-colour-white">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
				mobileNav={true}
                desktopControl={true}
            />
            <div className="join-affiliate-inner-container relative-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                <div className="join-affiliate-title-container margin-x-x-large">
                    <h1 className="display-xl-semibold colour-primary text-align-center margin-medium">
                        Become an Affliate
                    </h1>
                    <span className="body-regular colour-primary block-text text-align-center">
                        Want to work with us? Earn 20% of the revenue every month for each person you refer. 
                        Referrals commissions will last up to 12 months, or until the referral cancels their subscription. 
                        If you have questions, feel free to <span onClick={() => openEmail()} className="colour-link underline">email us here.</span>
                    </span>
                </div>
                {
                    submitted === true ?
                    <div className="join-affiliate-body-container">
                        <span className="body-regular colour-secondary block-text text-align-center margin-medium">
                            Thanks for your affiliate enquiry! We’ll get back to you via email as soon as possible. Keep an eye out on your inbox 🥳
                        </span>
                        <div className="custom-plan-finish-container margin-top-medium">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={"Back to dashboard"}
                                cta={complete}
                                disabled={false}
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                rightIconClass=""
                                alt=""
                                standardIcon={false}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                    </div>
                    :
                    <div className="join-affiliate-body-container">
                        <div className="join-affiliate-element-container margin-large">
                            <FinancialsInput
                                label={"Email"}
                                labelType={"inside"}
                                info={false}
                                value={email}
                                onChange={(text) => setEmail(text.target.value)}
                                placeholder={"youremail@email.com"}
                                type="email"
                                leftUnit={null}
                                rightUnit={null}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={false}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={false}
                                resetFunc={null}
                                resetPosition={null}
                            />
                        </div>
                        <div className="join-affiliate-element-container margin-large">
                            <FinancialsInput
                                label={"First name"}
                                labelType={"inside"}
                                info={false}
                                value={firstName}
                                onChange={(text) => setFirstName(text.target.value)}
                                placeholder={"Tim"}
                                type="text"
                                leftUnit={null}
                                rightUnit={null}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={false}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={false}
                                resetFunc={null}
                                resetPosition={null}
                            />
                        </div>
                        <div className="join-affiliate-element-container margin-large">
                            <FinancialsInput
                                label={"Last name"}
                                labelType={"inside"}
                                info={false}
                                value={lastName}
                                onChange={(text) => setLastName(text.target.value)}
                                placeholder={"Dillon"}
                                type="text"
                                leftUnit={null}
                                rightUnit={null}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={false}
                                error={false}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={false}
                                resetFunc={null}
                                resetPosition={null}
                            />
                        </div>
                        <div className="join-affiliate-element-container margin-x-large">
                            <FinancialsInput
                                label={null}
                                labelType={null}
                                info={false}
                                value={socialLinks}
                                onChange={(text) => setSocialLinks(text.target.value)}
                                placeholder={"Paste any relevant links to your website or social platforms where you'd be talking about Coffee Clozers with your audience."}
                                type="text"
                                leftUnit={null}
                                rightUnit={null}
                                unitSwitch={false}
                                leftUnitSelected={true}
                                unitSwitchFunc={null}
                                disabled={false}
                                multiline={true}
                                error={false}
                                onMouseEnter={null}
                                onMouseLeave={null}
                                reset={false}
                                resetFunc={null}
                                resetPosition={null}
                            />
                        </div>
                        <div className="join-affiliate-button-container">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={"Submit affiliate application"}
                                cta={submit}
                                disabled={firstName === "" || lastName === "" || email === "" || socialLinks === "" ? true : loading}
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                rightIconClass=""
                                alt=""
                                standardIcon={false}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </div>
    );
};

export default JoinAffiliate;