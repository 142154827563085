import React from 'react';
import '../styles/RentAccordion.css';
import { Accordion, AccordionDetails, AccordionSummary, formatterLong } from '../styles/GlobalStyles';
import { Block } from '../v4/components';

function ValuesAccordion(props) {
    const valuesAccordion = props.valuesAccordion;
    const handleValuesAccordion = props.handleValuesAccordion;
    const property = props.property;
    const traditionalRentEstimate = property.propertyEstimates === undefined ? null : property.propertyEstimates;
    const rentalEstimates = traditionalRentEstimate === null ? [] : [
		{
			label: "Zillow Zestimate",
			value: Math.round(traditionalRentEstimate.zillowEstimate)
		},
		{
			label: "Realtor.com",
			value: Math.round(traditionalRentEstimate.realtorEstimate)
		},
		{
			label: "REAPI",
			value: Math.round(traditionalRentEstimate.reapiEstimate)
		}
	];

    return (
        <Accordion 
            className="rent-accordion-outer-container"
            expanded={valuesAccordion}
            onChange={handleValuesAccordion}
            sx={{ 
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    gap: valuesAccordion === true ? '16px !important' : '8px !important',
                    boxShadow: 'none',
                    '&:before': {
                    display: 'none',
                }}
            }
        >
            <AccordionSummary
                aria-controls={`rent-accordion-bh-content`}
                id={`rent-accordion-bh-header`}
            >
                <div className="button-row">
                    <span className="body-regular-underline colour-link">
                        {valuesAccordion === true ? "Hide" : "Show"} value estimates
                    </span>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="rent-accordion-row">
                    {
                        rentalEstimates.map((item, index) =>
                            item.value === null || item.value === 0 ?
                            null
                            :
                            <Block
                                key={index}
                                label={item.label}
                                value={formatterLong.format(item.value).replace(".00", "")}
                                bg="bg-colour-grey"
                            />
                        )
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default ValuesAccordion;