import React from 'react';

function InvestmentProperty(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none"
            className={className}
        >
            <path 
                d="M11.289 0.474544C11.6804 0.0787977 12.3196 0.0787977 12.711 0.474544L23.7833 11.6698C24.0745 11.9643 24.0719 12.4392 23.7774 12.7305C23.4829 13.0217 23.008 13.0191 22.7167 12.7246L12 1.88878L1.28325 12.7246C0.991981 13.0191 0.517115 13.0217 0.222608 12.7305C-0.0718988 12.4392 -0.0745225 11.9643 0.216748 11.6698L11.289 0.474544Z" 
                fill={fill}
            />
            <path 
                d="M3.14482 12.4546C3.55493 12.3965 3.93453 12.6818 3.99267 13.0919L5.14893 21.2477C5.23631 21.8641 5.76398 22.3222 6.38655 22.3222H17.6136C18.2362 22.3222 18.7639 21.8641 18.8513 21.2477L20.0075 13.0919C20.0657 12.6818 20.4453 12.3965 20.8554 12.4546C21.2655 12.5128 21.5508 12.8924 21.4927 13.3025L20.3364 21.4582C20.1442 22.8143 18.9833 23.8222 17.6136 23.8222H6.38655C5.0169 23.8222 3.85603 22.8143 3.66378 21.4582L2.50752 13.3025C2.44938 12.8924 2.73471 12.5128 3.14482 12.4546Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M12.7728 19.2144V21.2932H11.5689V19.2195C11.064 19.1766 10.6139 19.0761 10.2185 18.918C9.68709 18.7011 9.25868 18.4191 8.9333 18.072C8.60792 17.7141 8.38015 17.3399 8.25 16.9494L9.4539 16.5264C9.61659 16.9386 9.91486 17.2965 10.3487 17.6002C10.6937 17.8417 11.1004 17.9906 11.5689 18.0469V14.4141L11.1621 14.3139C10.6958 14.1945 10.2674 14.0156 9.8769 13.777C9.49729 13.5384 9.1936 13.2401 8.96584 12.8822C8.74891 12.5134 8.64046 12.0742 8.64046 11.5644C8.64046 10.6425 8.94414 9.92123 9.55152 9.40062C10.0471 8.96701 10.7195 8.71029 11.5689 8.63046V6.92773H12.7728V8.63601C13.1875 8.68293 13.5617 8.78053 13.8953 8.92882C14.3834 9.14574 14.7793 9.42773 15.083 9.77481C15.3975 10.1219 15.6144 10.4852 15.7337 10.8648L14.5136 11.3041C14.3292 10.8486 14.0201 10.4798 13.5862 10.1978C13.345 10.037 13.0739 9.92207 12.7728 9.85295V13.3741C13.7199 13.6146 14.4466 14.0038 14.9528 14.5416C15.4843 15.0948 15.75 15.6859 15.75 16.3149C15.75 16.8572 15.6144 17.3507 15.3433 17.7954C15.0721 18.2401 14.6708 18.5926 14.1394 18.8529C13.7527 19.0423 13.2972 19.1628 12.7728 19.2144ZM12.7728 14.7381V18.0017C13.1601 17.9292 13.4855 17.8008 13.7489 17.6165C14.1936 17.3019 14.4159 16.9006 14.4159 16.4125C14.4159 15.9462 14.2153 15.5666 13.814 15.2737C13.53 15.0607 13.183 14.8822 12.7728 14.7381ZM11.5689 9.80672C11.1476 9.86725 10.8005 10.0139 10.5277 10.2466C10.1589 10.5503 9.97451 10.957 9.97451 11.4668C9.97451 11.944 10.1155 12.3073 10.3975 12.5568C10.6903 12.7954 11.0754 12.9744 11.5526 13.0937L11.5689 13.0974V9.80672Z" 
                fill={fill}
            />
        </svg>
    )
};

export default InvestmentProperty;