import React from 'react';
import '../styles/MapMarket.css';
import { Location } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';
import { Badge } from '../v4/components';

function MapMarket(props) {
    const market = props.market;
    const activeMarket = props.activeMarket;
    return (
        <div className="map-market-outer-container">
            <div className="map-market-inner-container">
                <div className="map-market-left-container">
                    <Location
                        fill={colour.blueBlue03}
                        className=""
                    />
                    <div className="map-market-left-text-container">
                        <span className="body-regular colour-primary">
                            {market}
                        </span>
                    </div>
                </div>
                {
                    activeMarket === false &&
                    <Badge
                        val={"FSBO ONLY"}
                        bg="bg-colour-orange-2"
                        freeSize={true}
                    />
                }
            </div>
        </div>
    )
};

export default MapMarket;