import React from 'react';
import '../styles/PropertyCardNote.css';

function PropertyCardNote(props) {
    const note = props.note;
    const cta = props.cta;
    const zpid = props.zpid;

    return (
        <div 
            className="property-card-note-container"
            onClick={() => cta(zpid)}
        >
            <span className={"body-regular colour-secondary block-text white-space-no-wrap " + (note === null ? "underline" : "")}>
                {
                    note === null ?
                    "Add note"
                    :
                    note
                }
            </span>
        </div>
    )
};

export default PropertyCardNote;