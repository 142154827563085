import React from 'react';
import '../styles/DistressFlagsFilter.css';
import { WorryIcon } from 'hugeicons-react';
import { colour } from '../../styles/GlobalStyles';
import { FilterReset, SelectionChip } from './';

function DistressFlagsFilter(props) {
    const distressFlags = props.distressFlags;
    const setDistressFlags = props.setDistressFlags;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;

    const distressOptions = [
        {
            label: "Any",
            value: "",
            selected: distressFlags === "" ? true : false
        },
        {
            label: "Absentee",
            value: "ab",
            selected: distressFlags === "ab" ? true : false
        },
        {
            label: "In-state absentee",
            value: "ia",
            selected: distressFlags === "ia" ? true : false
        },
        {
            label: "Death",
            value: "dt",
            selected: distressFlags === "dt" ? true : false
        },
        {
            label: "Inherited",
            value: "in",
            selected: distressFlags === "in" ? true : false
        },
        {
            label: "Pre-foreclosure",
            value: "pf",
            selected: distressFlags === "pf" ? true : false
        },
        {
            label: "Spousal death",
            value: "sd",
            selected: distressFlags === "sd" ? true : false
        },
        {
            label: "Vacant",
            value: "va",
            selected: distressFlags === "va" ? true : false
        }
    ];

    const changeDistressFlags = (value) => {
        setDistressFlags(value);
    };

    return (
        <div className="distress-flags-container">
            <div className="distress-flags-title-container">
                <div className="distress-flags-heading-container">
                    <WorryIcon
                        size={16}
                        color={colour.grayScaleBlack}
                    />
                    <h3 className="body-semibold colour-primary">
                        Distress flags
                    </h3>
                </div>
                <span className="body-regular colour-secondary">
                    Filter by these common owner distress flags. (Select one)
                </span>
            </div>
            <div className="distress-flags-body-container">
                {
                    distressOptions.map((item, index) =>
                        <SelectionChip
                            key={index}
                            label={item.label}
                            selected={item.selected}
                            disabled={false}
                            func={() => changeDistressFlags(item.value)}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                        />
                    )
                }
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("distress")}
                />
            }
        </div>
    )
};

export default DistressFlagsFilter;