import React from 'react';
import '../styles/DeleteProperties.css';

function DeleteProperties(props) {
    const selectedProperties = props.selectedProperties;

    return (
        <div className="delete-properties-container">
            <span className="body-regular colour-primary">
                Remove <span className="body-semibold">{selectedProperties.length} {selectedProperties.length === 1 ? "property" : "properties"}</span> from your saved properties and lists?
                It may be difficult to relocate them once removed.
            </span>
        </div>
    )
};

export default DeleteProperties;