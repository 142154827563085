import React from 'react';
import '../styles/LinkList.css';
import { ChevronLeftSmall } from '../../assets';

function LinkList(props) {
    const links = props.links;

    return (
        <div className="link-list-container">
            {
                links.map((item, index) =>
                    item.func !== null &&
                    <div 
                        className="link-list-element-container text-button button-row gap-x-small"
                        key={index}
                        onClick={() => item.func()}
                    >
                        {
                            item.icon !== null && item.standardIcon !== false ?
                            <img
                                src={item.icon}
                                className="link-list-icon"
                                alt={item.label}
                            />
                            :
                            item.icon !== null ?
                            item.icon
                            :
                            null
                        }
                        <span className={"body-regular " + (item.colour === undefined ? "text-link" : item.colour)}>
                            {item.label}
                        </span>
                        {
                            item.chevron === true &&
                            <img
                                src={ChevronLeftSmall}
                                className="link-list-icon reverse"
                                alt="Chevron Right"
                            />
                        }
                    </div>
                )
            }
        </div>
    )
};

export default LinkList;