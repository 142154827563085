import React from 'react';
import '../styles/StripeForm.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SetupForm } from './';
import { Exit } from "../assets";
import { colour, fontFamily } from '../styles/GlobalStyles';

const mode = window.location.hostname === "localhost" || window.location.hostname.includes("refi-787d3") || window.location.hostname.includes("192") ? "test" : "live";
const stripePromise = loadStripe(mode === "test" ? JSON.parse(process.env.REACT_APP_STRIPE).test.publishable : JSON.parse(process.env.REACT_APP_STRIPE).live.publishable);

function StripeForm(props) {
    const clientSecret = props.clientSecret;
    const closeModal = props.closeModal;
    const domain = props.domain;
    const productIndex = props.productIndex;
    const userData = props.userData;
    const setUserData = props.setUserData;
    const setPaymentMethodModal = props.setPaymentMethodModal;
    const product = props.product;
    const setLoading = props.setLoading;
    const options = {
        clientSecret: clientSecret,
        fonts: [
            {
                cssSrc: `https://fonts.googleapis.com/css?family=${fontFamily}`
            }
        ],
        appearance: {
            theme: "stripe",
            variables: {
                fontFamily: fontFamily,
                colorBackground: colour.grayScaleWhite,
                colorText: colour.textPrimary,
                colorDanger: colour.redRed01,
                borderRadius: '3px',
                fontSizeBase: '14px',
                colorPrimary: colour.brownBrown01
            }
        }
    };


    return (
        <div className="stripe-form-outer-container">
            <div className="stripe-form-inner-container">
                <div className="stripe-form-exit-container">
                    <img
                        src={Exit}
                        className="stripe-form-exit"
                        alt="Close Modal"
                        onClick={() => closeModal()}
                    />
                </div>
                <Elements 
                    stripe={stripePromise} 
                    options={options}
                >
                    <SetupForm
                        clientSecret={clientSecret}
                        domain={domain}
                        productIndex={productIndex}
                        userData={userData}
                        setUserData={setUserData}
                        setPaymentMethodModal={setPaymentMethodModal}
                        autoBilling={false}
                        product={product}
                        setLoading={setLoading}
                        payFunc={null}
                        creditCardButton={null}
                        setDisabled={() => null}
                        pageState={null}
                    />
                </Elements>
            </div>
        </div>
    )
}

export default StripeForm;