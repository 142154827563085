import React, { useEffect, useState } from 'react';
import '../styles/PropertyNotFound.css';
import { Header, Footer } from '../components';
import { checkOnAuthStateChanged, getUserDocuments } from '../functions';
import { Button, FinancialsInput } from '../v4/components';
import { useNavigate } from 'react-router-dom';
import { House } from "../assets/images";

function PropertyNotFound() {
    const [userData, setUserData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [link, setLink] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Property Not Found | Coffee Clozers";
        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const userId = user.userId;
				const collections = [
					"Users",
					"Subscriptions"
				];

				const queryUserData = await getUserDocuments(collections, userId);
				if ( queryUserData[0].status === 200 ) {
                    setUserData(queryUserData[0].data);
                }

                if ( queryUserData[1].status === 200 ) {
                    setSubscriptions(queryUserData[1].data);
                }
            }
        };

        fetchUserData();
    }, []);

    const checkZillowLink = () => {
        if ( link.includes("zillow.com/") ) {
            return false;
        }
        else {
            return true;
        }
    };

    const onChangeLink = (val) => {
        setLink(val);
    };

    const submit = () => {
        const encodedLink = encodeURIComponent(link);
        navigate(`/quick-report/${encodedLink}`);
    };

    return (
        <div className="property-not-found-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
				mobileNav={true}
                desktopControl={true}
            />
            <div className="property-not-found-inner-container">
                <div className="property-not-found-image-container">
                    <img
                        src={House}
                        className="property-not-found-image"
                        alt="House"
                    />
                </div>
                <div className="property-not-found-text-container">
                    <h1 className="heading-small-semibold colour-primary text-align-center">
                        We couldn't find this property
                    </h1>
                    <span className="body-regular colour-primary block-text text-align-center">
                        This could be due to a number of reasons. If the property is a townhouse, 
                        try pasting the Zillow link to the property down below:
                    </span>
                </div>
                <div className="property-not-found-input-container">
                    <FinancialsInput
                        label={null}
                        labelType={null}
                        info={false}
                        value={link}
                        onChange={(e) => onChangeLink(e.target.value)}
                        placeholder={"https://www.zillow.com/homedetails/..."}
                        type="text"
                        leftUnit={null}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={false}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
                <div className="property-not-found-button-container">
                    <Button
                        buttonType="primary-button"
                        text={"Submit"}
                        cta={() => submit()}
                        disabled={checkZillowLink()}
                        leftIcon={null}
                        rightIcon={null}
                        alt=""
                        size="large"
                        standardIcon={false}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default PropertyNotFound;