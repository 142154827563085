import React from 'react';
import '../styles/QuickReportPanel.css';
import { colour } from '../../styles/GlobalStyles';
import { Button } from './';
import { useNavigate } from 'react-router-dom';

function QuickReportPanel(props) {
    const maxFreeSearches = props.maxFreeSearches;
    const unlimited = props.unlimited;
    const remainingSearches = props.remainingSearches;
    const remainingSearchesTitle = unlimited === true ? "Unlimited " : `${remainingSearches} of ${maxFreeSearches} `;
    const remainingSearchesText = unlimited === true ? "searches " : "free searches remaining";
    const unfilledColour = colour.grayScaleGray01;
    const filledColour = colour.blueBlue02;
    const navigate = useNavigate();

    const upgrade = () => {
        navigate("/pricing");
    };

    const searchHistory = () => {
        navigate("/properties");
    };

    return (
        <div className="quick-report-panel-outer-container">
            <div className="quick-report-panel-inner-container">
                <div className="quick-report-panel-title-container">
                    <div className="quick-report-panel-icon-container">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="28" 
                            height="28" 
                            viewBox="0 0 28 28" 
                            fill="none"
                            className=""
                        >
                            <path 
                                d="M14.4842 0.477513C14.8098 0.144531 15.2513 -0.0148683 15.6848 0.00108929C16.1183 -0.0141591 16.559 0.14524 16.8832 0.47769L27.512 11.3562C28.1156 11.9739 28.0939 12.9541 27.4635 13.5456C26.8332 14.1371 25.833 14.1158 25.2295 13.498L15.6841 3.72772L10.8646 8.66028C9.91616 8.31408 8.89075 8.1326 7.82419 8.15033C7.52091 8.15538 7.22142 8.17648 6.92653 8.21282L14.4842 0.477513Z" 
                                fill="#748EA0"
                            />
                            <path 
                                d="M15.4487 20.6301L18.9116 23.9891H24.9538C25.7723 23.9891 26.4359 23.3387 26.4359 22.5366V14.3381C26.1819 14.3447 25.9271 14.2917 25.6948 14.1794V22.5366C25.6948 22.9377 25.3634 23.2629 24.9538 23.2629H24.2127V14.1847C24.2127 14.1099 24.1896 14.0404 24.1505 13.9828C24.0839 13.8856 23.971 13.8216 23.8422 13.8216H15.9792C16.2749 14.6583 16.4426 15.555 16.4581 16.4886C16.4831 17.9878 16.1136 19.3984 15.4487 20.6301Z" 
                                fill="#748EA0"
                            />
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" 
                                d="M12.1659 22.7818C11.0028 23.5777 9.59717 24.0556 8.08916 24.0807C3.98243 24.149 0.569225 20.8534 0.501035 16.754C0.432844 12.6547 3.74404 9.24719 7.84127 9.17904C11.948 9.11073 15.3612 12.4063 15.4294 16.5057C15.4559 18.0962 14.9776 19.572 14.1442 20.7981L18.3387 24.8667C18.6086 25.1273 18.7378 25.4756 18.7437 25.8353C18.7567 26.6117 18.1884 27.2367 17.4012 27.2498C17.0218 27.2561 16.6687 27.1294 16.3892 26.8689L12.1659 22.7818ZM2.49275 16.7209C2.54267 19.7221 5.04955 22.1426 8.05608 22.0925C11.0626 22.0425 13.4876 19.54 13.4377 16.5388C13.3878 13.5377 10.8809 11.1172 7.87435 11.1672C4.86781 11.2172 2.44282 13.7197 2.49275 16.7209Z" 
                                fill="#748EA0"
                            />
                        </svg>
                    </div>
                    <div className="quick-report-panel-title-right-container">
                        <span className="body-regular colour-primary block-text margin-x-x-small">
                            <span className="body-semibold">
                                {remainingSearchesTitle}
                            </span>
                            {remainingSearchesText}
                        </span>
                        <span className="body-regular colour-tertiary">
                            {
                                unlimited === true ?
                                "You get unlimited credits with your plan. Enjoy!"
                                :
                                "Credits reset monthly. Upgrade for unlimited."
                            }
                        </span>
                        {
                            unlimited === false ?
                            <div className="quick-report-panel-credits-container margin-top-x-large">
                                {
                                    Array.from({ length: maxFreeSearches }).map((_, index) =>
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="32" 
                                            height="8" 
                                            viewBox="0 0 32 8" 
                                            fill="none"
                                            key={index}
                                        >
                                            {
                                                index === 0 ?
                                                <path 
                                                    d="M0 4C0 1.79086 1.79086 0 4 0H32V8H4C1.79086 8 0 6.20914 0 4Z" 
                                                    fill={remainingSearches > index ? filledColour : unfilledColour}
                                                />
                                                :
                                                index === (maxFreeSearches - 1) ?
                                                <path 
                                                    d="M0 0H28C30.2091 0 32 1.79086 32 4C32 6.20914 30.2091 8 28 8H0V0Z" 
                                                    fill={remainingSearches > index ? filledColour : unfilledColour}
                                                />
                                                :
                                                <rect 
                                                    width="32" 
                                                    height="8" 
                                                    fill={remainingSearches > index ? filledColour : unfilledColour}
                                                />
                                            }
                                        </svg>
                                    )
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="quick-report-panel-button-container">
                    {
                        unlimited === true ?
                        null
                        :
                        <Button
                            buttonType="tertiary-button"
                            text="Get unlimited"
                            cta={upgrade}
                            disabled={false}
                            leftIcon={null}
                            rightIcon={null}
                            alt=""
                            size="medium"
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    }
                    <span 
                        className="body-regular-underline colour-link block-text text-align-center"
                        onClick={() => searchHistory()}
                    >
                        Search history
                    </span>
                </div>
            </div>
        </div>
    );
};

export default QuickReportPanel;