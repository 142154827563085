import React, { useState, useEffect } from 'react';
import '../styles/ImageGrid.css';
import ImageSwiper from './ImageSwiper';
import ImageGallery from 'react-image-gallery';
import '../styles/ImageSwiper.css';
import { useNavigate } from 'react-router-dom';
import { WhiteActionButton } from '../styles/GlobalStyles';
import { LockBlue } from '../assets';
import StreetView from "./StreetView";

function ImageGrid(props) {

	const property = props.property;
	const images = props.images;
	const title = props.title;
	const preview = props.preview;
	const setPreviewImageModal = props.setPreviewImageModal;
	const setPreviewModalStartIndex = props.setPreviewModalStartIndex;
	const blurred = props.blurred;

	const [imageModal, setImageModal] = useState(false);
	const [modalImages, setModalImages] = useState([]);
	const [modalStartIndex, setModalStartIndex] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		const newArray = [];
		if ( images !== undefined ) {
			for (let index = 0; index < images.length; index++) {
				if ( images[index].includes("maps.googleapis.com") ) {
				}
				else {
					const newObject = {
						original: images[index],
						thumbnail: images[index],
						originalClass: 'image-modal-element',
						thumbnailClass: 'image-modal-thumbnail-element'
					};
					newArray.push(newObject);
				}
			}
			setModalImages(newArray);
		}

		const keyDownHandler = event => {
			if (event.key === 'Escape') {
			  event.preventDefault();
	  
			  // 👇️ your logic here
			  setImageModal(false);
			  setModalStartIndex(0);
			  document.body.classList.remove('no-scroll');
			}
		};
	  
		document.addEventListener('keydown', keyDownHandler);
	
		// 👇️ clean up event listener
		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [images])

	// If there is 1 photo, do two equal squares, with
	// a blur on the second square.
	
	// If there are 2 photos, do two squares with no blur
	
	// If there are 3 photos, same as with 4
	
	// Four photos equals same grid but the first photo
	// takes the place of the fifth photo and is blurred.
	// There is a CTA on top of the fifth photo that navigates
	// you to the property description

	// Five photos equals no black box

	const openModal = (index) => {
		if ( preview === false ) {
			setModalStartIndex(index);
			setImageModal(true);
			window.scrollTo(0, 0);
			document.body.classList.add('no-scroll');
		}
		else {
			setPreviewModalStartIndex(index);
			setPreviewImageModal(true);
			window.scrollTo(0, 0);
			document.body.classList.add('no-scroll');
		}
	};

	const closeModal = () => {
		setModalStartIndex(0);
		setImageModal(false);
		document.body.classList.remove('no-scroll');
	}

	const elementClicked = (val) => {
		if ( preview === false ) {
			const target = val.target.classList;
			if ( target.contains("image-modal-element") ) {
				closeModal();
			}
		}
	}

	const openExtraImage = () => {
		if ( preview === false ) {
			setImageModal(true);
			setModalStartIndex(4);
		}
	}

	return (
		<div className={"image-grid-outer-container " + (imageModal === false ? "relative-container" : "")}>
			{
				imageModal === true && preview === false ?
					<ImageSwiper
						modalImages={modalImages}
						modalStartIndex={modalStartIndex}
						elementClicked={elementClicked}
						closeModal={closeModal}
					/>
				:
				null
			}
			{
				blurred === true ?
				<div className="mobile-image-grid-action-button">
					<WhiteActionButton
						onClick={() => navigate("/pricing")}
					>
						<img
							src={LockBlue}
							className="new-property-lock"
							alt="Get Access"
						/>
						Get access
					</WhiteActionButton>
				</div>
				:
				null
			}
			{
				imageModal === false ?
				<StreetView
					property={property}
				/>
				:
				null
			}
			<div className={"image-grid-inner-container " + (images === undefined ? "" : images.length > 3 ? "image-grid-layout" : images.length === 3 ? "image-grid-three-layout" : "image-double-layout")}>
				{
					images === undefined ? 
					null
					:
					images.map((item, index) =>
						index > 4 ?
						null
						:
						item.includes("maps.googleapis.com") ?
						null
						:
						<div 
							className={"image-grid-image-container " + (index === 0 ? "image-grid-container-first " : "image-grid-container-other ") + (index === 0 ? "" : `image-grid-container-other-image-${index + 1}`)}
							key={index}
							onClick={() => openModal(index)}
						>
							<img
								src={item}
								className={"image-grid-image " + (index === 0 ? "image-grid-first-image" : "image-grid-other-images")}
								alt={property.unlocked !== true ? "random image" : `${title}-${index+1}`}
							/>
							{
								images.length > 5 && index === 4 ?
								<div 
									className="image-grid-black-overlay"
									onClick={() => openExtraImage()}
								>
									<span>
										{images.length - 5}+
									</span>
								</div>
								:
								null
							}
						</div>
					)
				}
				{
					images === undefined ? 
					null
					:
					images.length === 1 && images[0].includes("maps.googleapis.com") === false ?
					<div
						className="image-grid-image-container blurry-image-double-container"
					>
						<img
							src={images[0]}
							className="image-grid-image image-grid-image-blurry"
							alt={property.unlocked !== true ? "random image" : `${title}-2`}
						/>
						{/* <div className="image-grid-cta-container">
							<ActionButton
								onClick={() => cta()}
							>
								View Description
							</ActionButton>
						</div> */}
					</div>
					:
					images.length === 4 ?
					<div
						className="image-grid-image-container blurry-image-grid-container"
					>
						<img
							src={images[0]}
							className="image-grid-image image-grid-image-blurry"
							alt={property.unlocked !== true ? "random image" : `${title}-4`}
						/>
						{/* <div className="image-grid-cta-container">
							<ActionButton
								onClick={() => cta()}
							>
								View Description
							</ActionButton>
						</div> */}
					</div>
					:
					null
				}
			</div>
			<div className="image-grid-inner-container-mobile">
				<ImageGallery 
					items={modalImages}
					showNav={true}
					showFullscreenButton={false}
					showPlayButton={false}
					startIndex={0}
					slideOnThumbnailOver={false}
					disableThumbnailScroll={false}
				/>
			</div>
		</div>
	)
}

export default ImageGrid;