import React from 'react';
import '../styles/EstimatedInterestFilter.css';
import { FilterReset, SelectionChip } from './';

function EstimatedInterestFilter(props) {
    const estimatedInterest = props.estimatedInterest;
    const setEstimatedInterest = props.setEstimatedInterest;
    const resetInterest = props.resetInterest;
    const resetFilterTypeInterest = props.resetFilterTypeInterest;
    const equityLevel = props.equityLevel;
    const setEquityLevel = props.setEquityLevel;
    const resetEquity = props.resetEquity;
    const resetFilterTypeEquity = props.resetFilterTypeEquity;

    const interestOptions = [
        {
            label: "Any",
            value: "",
            selected: estimatedInterest === "" ? true : false
        },
        {
            label: "<3%",
            value: 3,
            selected: estimatedInterest === 3 ? true : false
        },
        {
            label: "<5%",
            value: 5,
            selected: estimatedInterest === 5 ? true : false
        }
    ];

    const changeInterest = (value) => {
        setEstimatedInterest(value);
    };

    const equityOptions = [
        {
            label: "Any",
            value: "",
            selected: equityLevel === "" ? true : false
        },
        {
            label: "Low (20% or less)",
            value: 20,
            selected: equityLevel === 20 ? true : false
        },
        {
            label: "High (80% or more)",
            value: 80,
            selected: equityLevel === 80 ? true : false
        }
    ];

    const changeEquity = (value) => {
        setEquityLevel(value);
    };

    return (
        <div className="estimated-interest-filter-outer-container">
            <div className="estimated-interest-filter-container">
                <div className="estimated-interest-title-container">
                    <h3 className="body-semibold colour-primary">
                        Estimated interest rate on owner's mortgage
                    </h3>
                    <span className="body-regular colour-secondary">
                        Please note, this is estimated and may not 
                        always be completely accurate.
                    </span>
                </div>
                <div className="estimated-interest-body-container">
                    {
                        interestOptions.map((item, index) =>
                            <SelectionChip
                                key={index}
                                label={item.label}
                                selected={item.selected}
                                disabled={false}
                                func={() => changeInterest(item.value)}
                                index={index}
                                leftIcon={null}
                                rightIcon={null}
                            />
                        )
                    }
                </div>
                {
                    resetInterest === true &&
                    <FilterReset
                        clickFunc={() => resetFilterTypeInterest("interest")}
                    />
                }
            </div>
            <div className="estimated-interest-filter-container equity-level-filter-container">
                <div className="estimated-interest-title-container">
                    <h3 className="body-semibold colour-primary">
                        Equity level
                    </h3>
                    <span className="body-regular colour-secondary">
                        Please note, this is estimated and may 
                        not always be completely accurate.
                    </span>
                </div>
                <div className="estimated-interest-body-container">
                    {
                        equityOptions.map((item, index) =>
                            <SelectionChip
                                key={index}
                                label={item.label}
                                selected={item.selected}
                                disabled={false}
                                func={() => changeEquity(item.value)}
                                index={index}
                                leftIcon={null}
                                rightIcon={null}
                            />
                        )
                    }
                </div>
                {
                    resetEquity === true &&
                    <FilterReset
                        clickFunc={() => resetFilterTypeEquity("equity")}
                    />
                }
            </div>
        </div>
    )
};

export default EstimatedInterestFilter;