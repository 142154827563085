import React from 'react';
import '../styles/StrategyScoreCard.css';

function StrategyScoreCard(props) {
    const item = props.item;
    const selected = props.selected;

    return (
        <div className={"strategy-score-card-element " + (selected === true ? "strategy-score-card-element-selected" : "")}>
            <span className="label-regular colour-primary block-text text-align-center">
                {item.type}
            </span>
            <span className={"colour-primary block-text text-align-center " + (selected === true ? "body-semibold" : "body-regular")}>
                {item.label}
            </span>
            <span className={"colour-primary block-text text-align-center " + (selected === true ? "body-semibold" : "body-regular")}>
                {item.score}
            </span>
        </div>
    )
};

export default StrategyScoreCard;