import React, { useState } from 'react';
import '../styles/MonthlyCostsTab.css';
import { FinancialsInput, Tooltip } from '../v4/components';
import { checkNaN, checkUserId, formatPrice, monthlyCostsTooltips, recordEvent } from '../functions';
import { formatterLong } from '../styles/GlobalStyles';
import { useLocation, useNavigate } from 'react-router-dom';

function MonthlyCostsTab(props) {
    const blurred = props.blurred;
    const onChangeMonthlyCosts = props.onChangeMonthlyCosts;
    const onChangeMonthlyCostsPercentage = props.onChangeMonthlyCostsPercentage;
    const togglePercentage = props.togglePercentage;
    const exitStrategy = props.exitStrategy;
    const monthlyCosts = props.monthlyCosts;
    const resetMonthlyCost = props.resetMonthlyCost;
    const doneSettings = props.doneSettings;
    const [tooltip, setTooltip] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const toggleTooltip = (index, ii, val) => {
        if ( val === true ) {
            setTooltip([index, ii]);
        }
        else {
            setTooltip(null);
        }
    };

    // Function that gets the total monthly costs
    const getTotalMonthlyCosts = () => {
        const totalMonthlyCosts = monthlyCosts.reduce((accumulator, currentValue) => {
            currentValue.inputs.forEach(item => {
                if ( item.label === "Management fee" && exitStrategy === "hh" ) {

                }
                else if ( item.label === "Property taxes" && exitStrategy === "brrrr" ) {
                    accumulator += Number(item.brrrrValue);
                }
                else {
                    accumulator += Number(item.value);
                }
            });
            return accumulator; // Return the accumulator for the next iteration
        }, 0);
        return formatterLong.format(totalMonthlyCosts).replace(".00", "");
    };

    const goToSettings = async() => {
        recordEvent("Clicked on financial preferences banner", {});
        const user = await checkUserId();
        if ( user.status === 200 ) {
            navigate("/preferences", {
                state: state
            });
        }
        else {
            navigate("/sign-up", {
                state: state
            });
        }
    };

    return (
        <div className="monthly-costs-tab-outer-container">
            <div className="monthly-costs-tab-inner-container">
                {
                    monthlyCosts.map((item, index) =>
                        <div 
                            className="monthly-costs-tab-section-container"
                            key={index}
                        >
                            <div className="monthly-costs-grid-container">
                                {
                                    item.inputs.map((input, ii) =>
                                        <div 
                                            className="monthly-costs-grid-element"
                                            key={ii}
                                        >
                                            <div className="monthly-costs-input-container relative-container">
                                                <FinancialsInput
                                                    label={input.label}
                                                    labelType={"default"}
                                                    info={true}
                                                    value={
                                                        exitStrategy === "brrrr" && index === 0 && ii === 3 && input.percentage === false ?
                                                        formatPrice(input.brrrrValue)
                                                        :
                                                        exitStrategy === "brrrr" && index === 0 && ii === 3 && input.percentage === true ?
                                                        input.brrrrPercentageVal
                                                        :
                                                        input.label === "Management fee" && exitStrategy === "hh" ? 
                                                        0 
                                                        : 
                                                        input.percentage === true ? 
                                                        input.percentageVal
                                                        :
                                                        formatPrice(input.value)
                                                    }
                                                    onChange={(text) => input.percentage === true ? onChangeMonthlyCostsPercentage(text.target.value.replaceAll(",", ""), index, ii) : onChangeMonthlyCosts(text.target.value.replaceAll(",", ""), index, ii)}
                                                    placeholder={formatPrice(input.original)}
                                                    type="text"
                                                    leftUnit={"$"}
                                                    rightUnit={"%"}
                                                    unitSwitch={true}
                                                    leftUnitSelected={!input.percentage}
                                                    unitSwitchFunc={(val) => togglePercentage(val, index, ii)}
                                                    disabled={(input.label === "Mortgage payment") || (input.label === "Management fee" && exitStrategy === "hh") || (blurred === true) ? true : false}
                                                    multiline={false}
                                                    error={
                                                        exitStrategy === "brrrr" && index === 0 && ii === 3 && input.percentage === false ?
                                                        checkNaN(input.brrrrValue)
                                                        :
                                                        exitStrategy === "brrrr" && index === 0 && ii === 3 && input.percentage === true ?
                                                        checkNaN(input.brrrrPercentageVal)
                                                        :
                                                        input.label === "Management fee" && exitStrategy === "hh" ? 
                                                        0 
                                                        : 
                                                        input.percentage === true ? 
                                                        checkNaN(input.percentageVal)
                                                        :
                                                        checkNaN(input.value)
                                                    }
                                                    onMouseEnter={() => toggleTooltip(index, ii, true)}
                                                    onMouseLeave={() => toggleTooltip(index, ii, false)}
                                                    reset={
                                                        index === 0 && ii === 0 ?
                                                        false
                                                        :
                                                        (exitStrategy === "brrrr" && index === 0 && ii === 3 && (Number(input.brrrrValue) !== Number(input.brrrrOriginal))) || (Number(input.original) !== Number(input.value)) ?
                                                        true
                                                        :
                                                        false
                                                    }
                                                    resetFunc={() => resetMonthlyCost(index, ii)}
                                                    resetPosition={"bottom"}
                                                />
                                                {
                                                    tooltip !== null && index === tooltip[0] && ii === tooltip[1] ?
                                                    <Tooltip
                                                        type="info"
                                                        title={monthlyCostsTooltips[index].tooltips[ii].title}
                                                        body={monthlyCostsTooltips[index].tooltips[ii].description}
                                                        position="top"
                                                        contextTooltipBg={null}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="property-spacing-border">
                            </div>
                        </div>
                    )
                }
                <div className="monthly-costs-total-container">
                    <span className="body-regular colour-primary">
                        Total
                    </span>
                    <span className="body-semibold colour-primary">
                        {getTotalMonthlyCosts()}
                    </span>
                </div>
                <div className="property-spacing-border">
                </div>
                {
				    doneSettings === false &&
                    <div className="monthly-costs-financial-preferences-container">
                        <span className="body-regular colour-secondary">
                            Tired of changing values every time?
                        </span>
                        <span 
                            className="body-regular-underline colour-link"
                            onClick={() => goToSettings()}
                        >
                            Customize default values
                        </span>
                    </div>
                }
            </div>
        </div>
    )
};

export default MonthlyCostsTab;