import React from 'react';

function MultipleParcels(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="22" 
            height="24" 
            viewBox="0 0 22 24" 
            fill="none"
            className={className}
        >
            <path   
                d="M9.30825 1.0271C9.13349 0.847737 8.84807 0.847737 8.67331 1.0271L3.72942 6.10108C3.59936 6.23456 3.60054 6.44978 3.73204 6.58179C3.86354 6.71381 4.07557 6.71262 4.20563 6.57914L8.99078 1.66807L13.7759 6.57914C13.906 6.71262 14.118 6.71381 14.2495 6.58179C14.381 6.44978 14.3822 6.23456 14.2521 6.10108L9.30825 1.0271Z" 
                fill={fill}
            />
            <path 
                d="M5.41541 6.74562C5.38945 6.55975 5.21996 6.43043 5.03684 6.45678C4.85372 6.48314 4.72632 6.65518 4.75228 6.84105L4.9131 7.99251L5.50476 7.38529L5.41541 6.74562Z" 
                fill={fill}
            />
            <path 
                d="M13.0685 7.99259L13.2294 6.84105C13.2553 6.65518 13.1279 6.48314 12.9448 6.45678C12.7617 6.43043 12.5922 6.55975 12.5662 6.74562L12.4769 7.38537L13.0685 7.99259Z" 
                fill={fill}
            />
            <path 
                d="M15.2787 10.2609L16.5815 8.92388L21.3666 13.835C21.4967 13.9684 21.7087 13.9696 21.8402 13.8376C21.9717 13.7056 21.9729 13.4904 21.8428 13.3569L16.8989 8.28291C16.7242 8.10355 16.4388 8.10355 16.264 8.28291L14.8077 9.77753L15.2787 10.2609Z" 
                fill={fill}
            />
            <path 
                d="M16.3266 18.8647H19.088C19.6996 18.8647 20.2179 18.4079 20.3038 17.7933L20.82 14.0969C20.846 13.911 20.7186 13.7389 20.5355 13.7126C20.3524 13.6862 20.1829 13.8156 20.1569 14.0014L19.6406 17.6978C19.6016 17.9772 19.366 18.1848 19.088 18.1848H16.4216L16.3266 18.8647Z" 
                fill={fill}
            />
            <path 
                d="M8.46166 5.47027C8.75293 5.17133 9.22862 5.17133 9.5199 5.47027L17.7597 13.9269C17.9765 14.1494 17.9745 14.5081 17.7554 14.7281C17.5362 14.9481 17.1828 14.9461 16.966 14.7237L8.99078 6.53855L1.01553 14.7237C0.798766 14.9461 0.445377 14.9481 0.226209 14.7281C0.00704074 14.5081 0.00508829 14.1494 0.221848 13.9269L8.46166 5.47027Z" 
                fill={fill}
            />
            <path 
                d="M2.40088 14.5197C2.70608 14.4758 2.98857 14.6914 3.03184 15.0011L3.89231 21.1618C3.95734 21.6274 4.35002 21.9735 4.81333 21.9735H13.1684C13.6317 21.9735 14.0244 21.6274 14.0894 21.1618L14.9499 15.0011C14.9931 14.6914 15.2756 14.4758 15.5808 14.5197C15.886 14.5637 16.0984 14.8504 16.0551 15.1602L15.1946 21.3208C15.0515 22.3452 14.1876 23.1065 13.1684 23.1065H4.81333C3.79405 23.1065 2.93015 22.3452 2.78708 21.3208L1.92661 15.1602C1.88334 14.8504 2.09568 14.5637 2.40088 14.5197Z" 
                fill={fill}
            />
        </svg>
    )
}

export default MultipleParcels;