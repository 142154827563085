import React, { useEffect, useState } from 'react';
import '../styles/BuyBoxes.css';
import { Header, Footer, SideNavigation, Loading, ExitScreen } from '../components';
import { changeBuyBoxName, checkBuyBoxUpdates, checkOnAuthStateChanged, deleteBuyBoxFunc, getUserDocuments, goToCreativeCheckout, recordEvent } from '../functions';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, BuyBoxCard, Modal, Toast, ZeroState } from '../v4/components';
import { DeleteBuyBox, LinkList, SingleInput, Upgrade } from '../v4/modalbody';
import { PackageOpenIcon, Delete02Icon, Edit02Icon, TextFontIcon } from 'hugeicons-react';
import { colour } from '../styles/GlobalStyles';
import { Arrow, Download, TickCircle } from '../assets/icons';

function BuyBoxes() {

    const [subscriptions, setSubscriptions] = useState([]);
    const [userData, setUserData] = useState(null);
    const [buyBoxes, setBuyBoxes] = useState([]);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showExitScreen, setShowExitScreen] = useState(false);
    const [buyBoxFrequencyDropdown, setBuyBoxFrequencyDropdown] = useState(false);
    const [actionDropdown, setActionDropdown] = useState(false);
    const [dropdownId, setDropdownId] = useState(null);
    const [renameModal, setRenameModal] = useState(false);
    const [darkBg, setDarkBg] = useState(false);
    const [buyBoxName, setBuyBoxName] = useState("");
    const [renameLoading, setRenameLoading] = useState(false);
    const [buyBoxDeleteModal, setBuyBoxDeleteModal] = useState(false);
    const [buyBoxDeleteId, setBuyBoxDeleteId] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [noBuyBoxes, setNoBuyBoxes] = useState(false);
    const [premiumModal, setPremiumModal] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [mobileActionModal, setMobileActionMobile] = useState(false);
    const [loadingBuyBoxes, setLoadingBuyBoxes] = useState(false);
    const [freeTrial, setFreeTrial] = useState(false);
    const [csvDownloadLoading, setCSVDownloadLoading] = useState(false);
	const [csvDownloadComplete, setCSVDownloadComplete] = useState(false);
    const [csvDownloadError, setCSVDownloadError] = useState(false);
    const [csvDownloadLink, setCSVDownloadLink] = useState(null);
    const [payingCustomer, setPayingCustomer] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const actionOptions = [
        {
            icon: <Edit02Icon size={16} color={colour.grayScaleBlack} />,
            func: () => editBuyBox(),
            label: "Edit",
            colour: "colour-regular",
            standardIcon: false,
            chevron: true
        },
        {
            icon: <TextFontIcon size={16} color={colour.grayScaleBlack} />,
            func: () => viewRenameModal(),
            label: "Rename",
            colour: "colour-regular",
            standardIcon: false,
            chevron: true
        },
        {
            icon: <Delete02Icon size={16} color={colour.redRed02} />,
            func: () => viewDeleteModal(),
            label: "Delete",
            colour: "colour-error",
            standardIcon: false,
            chevron: true
        }
    ];

    // const sortOptions = [
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     },
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     },
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     }
    // ];

    useEffect(() => {
        document.title = "Coffee Clozers | Buy Boxes";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);

                let checkFreeTrial = false;
                const today = new Date();
                const todaySeconds = today.getTime() / 1000;

                const collections = [
                    "Users",
                    "Subscriptions",
                    "Buy Boxes V4"
                ];
                const queryData = await getUserDocuments(collections, user.userId);
                if ( queryData[0].status === 200 ) {
                    setUserData(queryData[0].data);
                    const freeTrialObject = queryData[0].data.freeTrial[0];
                    if ( freeTrialObject.endDate.seconds > todaySeconds ) {
                        checkFreeTrial = true;
                        setFreeTrial(true);
                    }
                }

                let checkSubscriptions = [];
                if ( queryData[1].status === 200 ) {
                    checkSubscriptions = queryData[1].data;
                    const subscriptionIndex = checkSubscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
                    if ( subscriptionIndex !== -1 ) {
                        setPayingCustomer(true);
                    }
                    setSubscriptions(queryData[1].data);
                }
                if ( queryData[2].status === 200 ) {
                    if ( queryData[2].data.length === 0 ) {
                        setNoBuyBoxes(true);
                    }
                    setBuyBoxes(queryData[2].data);

                    const buyBoxUpdates = await checkBuyBoxUpdates(queryData[2].data, user.userId, checkSubscriptions, checkFreeTrial, setLoadingBuyBoxes);
                    if ( buyBoxUpdates.changes === true ) {
                        setBuyBoxes(buyBoxUpdates.data);
                    }
                }
                else {
                    setNoBuyBoxes(true);
                }
            }
            else {
                navigate("/sign-up");
            }
            setLoading(false);
        };

        fetchUserData();

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);

            if ( window.innerWidth >= 1000 ) {
                setMobileActionMobile(false);
                setDarkBg(false);
            }
		};

		window.addEventListener('resize', handleWindowResize);
    }, [navigate]);

    // const search = () => {

    // };

    const newBuyBox = () => {
        recordEvent("New Buy Box", {
            userId: userId
        });
        navigate("/new-buy-box", {
            state: state
        });
    };

    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    //     setShowExitScreen(!dropdownOpen);
    // };

    const exitScreenClick = () => {
        // setDropdownOpen(false);
        setBuyBoxFrequencyDropdown(false);
        setActionDropdown(false);
        setShowExitScreen(false);
    };

    // const selectOption = (option) => {

    // };

    const closeRenameModal = () => {
        setRenameModal(false);
        setDarkBg(false);
    };

    const saveRename = async() => {
        setRenameLoading(true);
        const saveNewName = await changeBuyBoxName(dropdownId, buyBoxName);
        if ( saveNewName.status === 200 ) {
            setBuyBoxes(saveNewName.data);
        }
        setRenameLoading(false);
        closeRenameModal();
    };

    const closeDeleteModal = () => {
        setBuyBoxDeleteModal(false);
        setBuyBoxDeleteId(null);
        setDarkBg(false);
    };

    const deleteBuyBox = async() => {
        if ( buyBoxDeleteId === null ) {
            return;
        }
        
        setDeleteLoading(true);
        const newBuyBoxes = await deleteBuyBoxFunc(buyBoxDeleteId);
        if ( newBuyBoxes.status === 200 ) {
            setBuyBoxes(newBuyBoxes.data);
        }
        setDeleteLoading(false);
        setBuyBoxDeleteModal(false);
        setBuyBoxDeleteId(null);
        setDarkBg(false);
    };

    const closePremiumModal = () => {
        setPremiumModal(false);
    };

    const closeMobileActionModal = () => {
        setMobileActionMobile(false);
        setBuyBoxName("");
        setDropdownId(null);
        setDarkBg(false);
    };

    const backToActionsModal = () => {
        setRenameModal(false);
        setBuyBoxDeleteModal(false);
        setMobileActionMobile(true);
        setBuyBoxDeleteId(null);
    };

    const editBuyBox = () => {
        const buyBoxIndex = buyBoxes.findIndex(e => e.id === dropdownId);
        if ( buyBoxIndex !== -1 ) {
            const item = buyBoxes[buyBoxIndex];
            navigate("/edit-buy-box", {
                state: {
                    buyBox: item
                }
            })
        }
    };

    const viewRenameModal = () => {
        recordEvent("Rename Buy Box Modal", {
            id: dropdownId
        });
        setRenameModal(true);
        setMobileActionMobile(false);
    };

    const viewDeleteModal = () => {
        recordEvent("Delete Buy Box Modal", {
            id: dropdownId
        });
        setBuyBoxDeleteModal(true);
        setBuyBoxDeleteId(dropdownId);
        setMobileActionMobile(false);
    };

    const viewPricing = () => {
        navigate("/pricing");
    };

    const upgradeNow = () => {
        goToCreativeCheckout(navigate);
    };

    const closeCSVDownloadToast = () => {
        setCSVDownloadLoading(false);
        setCSVDownloadComplete(false);
        setCSVDownloadError(false);
        setCSVDownloadLink(null);
    };

    const viewSheet = () => {
        if ( csvDownloadLink !== null ) {
            window.open(csvDownloadLink, "_blank");
        }
    };

    return (
        <div className={"buy-boxes-outer-container " + ((mobileActionModal === true || renameModal === true || buyBoxDeleteModal === true) && innerWidth < 1000 ? "buy-boxes-mobile-modal-open" : "")}>
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="buy-boxes-inner-container relative-container">
                {
                    showExitScreen === true &&
                    <ExitScreen
                        clickScreen={() => exitScreenClick()}
                    />
                }
                {
                    ( darkBg === true || premiumModal === true ) &&
                    <div className="dark-bg">
                    </div>
                }
                {
					(csvDownloadLoading === true || csvDownloadComplete === true || csvDownloadError === true) &&
					<Toast
						type={csvDownloadComplete === true ? "link" : csvDownloadError === true ? "default" : "loading"}
						label={csvDownloadError === true ? "There was an error downloading your data" : csvDownloadComplete === true ? "Download successful" : "Downloading CSV file"}
						icon={csvDownloadError === true ? null : csvDownloadComplete === true ? <TickCircle fill={colour.grayScaleWhite} /> : <Download className="" fill={colour.grayScaleWhite} />}
						standardIcon={false}
						linkFunc={csvDownloadComplete === true ? viewSheet : null}
						linkText={csvDownloadComplete === true ? "Click here to view" : null}
						closeFunc={closeCSVDownloadToast}
						undoFunc={null}
					/>
				}
                {
                    renameModal === true &&
                    <Modal
                        heading="Rename"
                        subheading={null}
                        body={<SingleInput val={buyBoxName} setVal={setBuyBoxName} placeholder="My buy box" />}
                        type="basic"
                        cancelText={innerWidth < 1000 ? "Back" : "Cancel"}
                        cancelButtonCTA={innerWidth < 1000 ? backToActionsModal : closeRenameModal}
                        actionButtonText="Save changes"
                        actionButtonCTA={saveRename}
                        actionButtonDisabled={buyBoxName === "" ? true : false}
                        secondaryButtonText=""
                        secondaryButtonCTA={null}
                        exitButtonCTA={closeRenameModal}
                        loading={renameLoading}
                        leftIcon={null}
					    rightIcon={null}
                        cancelLeftIcon={innerWidth < 1000 ? <Arrow className="modal-cancel-arrow" fill={colour.blueBlue03} /> : null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                {
                    buyBoxDeleteModal === true &&
                    <Modal
                        heading="Delete Buy Box"
                        subheading={null}
                        body={<DeleteBuyBox buyBoxes={buyBoxes} buyBoxDeleteId={buyBoxDeleteId} />}
                        type="destructive"
                        cancelText={innerWidth < 1000 ? "Back" : "Cancel"}
                        cancelButtonCTA={innerWidth < 1000 ? backToActionsModal : closeDeleteModal}
                        actionButtonText="Delete Buy Box"
                        actionButtonCTA={deleteBuyBox}
                        actionButtonDisabled={deleteLoading}
                        secondaryButtonText=""
                        secondaryButtonCTA={null}
                        exitButtonCTA={closeDeleteModal}
                        loading={deleteLoading}
                        leftIcon={null}
					    rightIcon={null}
                        cancelLeftIcon={innerWidth < 1000 ? <Arrow className="modal-cancel-arrow" fill={colour.blueBlue03} /> : null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                {
                    premiumModal === true &&
                    <Modal
                        heading="Plus Feature 👀 "
                        subheading={"You've discovered a Plus Plan feature!"}
                        body={<Upgrade />}
                        type="secondary"
                        cancelText="Maybe later"
                        cancelButtonCTA={closePremiumModal}
                        actionButtonText="Upgrade now"
                        actionButtonCTA={upgradeNow}
                        actionButtonDisabled={false}
                        secondaryButtonText="See pricing plans"
                        secondaryButtonCTA={viewPricing}
                        exitButtonCTA={closePremiumModal}
                        loading={false}
                        leftIcon={null}
					    rightIcon={null}
                        cancelLeftIcon={null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                {
                    mobileActionModal === true && innerWidth < 1000 &&
                    <Modal
                        heading="Actions"
                        subheading={null}
                        body={<LinkList links={actionOptions} />}
                        type="single"
                        cancelText={null}
                        cancelButtonCTA={null}
                        actionButtonText="Close"
                        actionButtonCTA={closeMobileActionModal}
                        actionButtonDisabled={false}
                        secondaryButtonText=""
                        secondaryButtonCTA={null}
                        exitButtonCTA={closeMobileActionModal}
                        loading={renameLoading}
                        leftIcon={null}
                        rightIcon={null}
                        cancelLeftIcon={null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                    subscriptions={subscriptions}
                />
                <div className="buy-boxes-body-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        <div className="buy-boxes-inner-body-container">
                            <div className="buy-boxes-title-container">
                                <h1 className="heading-large-semibold colour-primary">
                                    Buy Boxes
                                </h1>
                                <div className="buy-boxes-title-right-container">
                                    {/* <div 
                                        className="text-button button-row gap-x-small"
                                        onClick={() => search()}
                                    >
                                        <img
                                            src={SearchBlue}
                                            className="buy-boxes-search-icon"
                                            alt="Search"
                                        />
                                        <span className="body-regular text-link">
                                            Search
                                        </span>
                                    </div>
                                    <div className="buy-boxes-sort-container">
                                        <Dropdown
                                            open={dropdownOpen}
                                            icon={Sort}
                                            label={"Sort"}
                                            disabled={false}
                                            options={sortOptions}
                                            multiselect={false}
                                            toggleDropdown={toggleDropdown}
                                            cta={selectOption}
                                            sidealign={"left"}
                                            topalign={"bottom"}
                                            indexSelect={false}
                                            selectedIndex={null}
                                        />
                                    </div> */}
                                    <div className="buy-boxes-new-buy-box-container mobile-none">
                                        <Button
                                            buttonType="primary-button"
                                            size="large"
                                            text={"Create Buy Box"}
                                            cta={newBuyBox}
                                            disabled={false}
                                            leftIcon={null}
                                            leftIconClass=""
                                            rightIcon={null}
                                            rightIconClass=""
                                            alt=""
                                            standardIcon={true}
                                            badge={false}
                                            badgeVal={null}
                                            badgeBG={null}
                                            buttonRef={null}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="buy-boxes-cards-container">
                                {
                                    noBuyBoxes === true ?
                                    <ZeroState
                                        icon={<PackageOpenIcon color={colour.grayScaleGray02} size={32} />}
                                        standardIcon={false}
                                        title="No buy boxes yet"
                                        subtitle="Create a buy box to get daily updates when new properties that meet your requirements hit the market."
                                        showButton={innerWidth >= 1000 ? true : false}
                                        buttonText="Create a new buy box"
                                        actionButtonCTA={newBuyBox}
                                    />
                                    :
                                    buyBoxes.map((item, index) =>
                                        <BuyBoxCard
                                            item={item}
                                            index={index}
                                            key={index}
                                            setShowExitScreen={setShowExitScreen}
                                            buyBoxFrequencyDropdown={dropdownId === item.id ? buyBoxFrequencyDropdown : false}
                                            setBuyBoxFrequencyDropdown={setBuyBoxFrequencyDropdown}
                                            actionDropdown={dropdownId === item.id ? actionDropdown : false}
                                            setActionDropdown={setActionDropdown}
                                            setDropdownId={setDropdownId}
                                            setBuyBoxes={setBuyBoxes}
                                            subscriptions={subscriptions}
                                            userId={userId}
                                            renameModal={renameModal}
                                            setRenameModal={setRenameModal}
                                            setDarkBg={setDarkBg}
                                            setBuyBoxName={setBuyBoxName}
                                            setBuyBoxDeleteModal={setBuyBoxDeleteModal}
                                            setBuyBoxDeleteId={setBuyBoxDeleteId}
                                            setMobileActionMobile={setMobileActionMobile}
                                            actionOptions={actionOptions}
                                            loading={loadingBuyBoxes}
                                            freeTrial={freeTrial}
                                            setCSVDownloadLoading={setCSVDownloadLoading}
                                            setCSVDownloadComplete={setCSVDownloadComplete}
                                            setCSVDownloadError={setCSVDownloadError}
                                            setCSVDownloadLink={setCSVDownloadLink}
                                            payingCustomer={payingCustomer}
                                            setPremiumModal={setPremiumModal}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        loading === false &&
                        <div className="buy-boxes-sticky-button desktop-none">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={"Create a new buy box"}
                                cta={newBuyBox}
                                disabled={false}
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                rightIconClass=""
                                alt=""
                                standardIcon={true}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BuyBoxes;