import React from 'react';
import '../styles/PricingCard.css';
import { Cappuccino } from '../assets/images';
import { formatterLong } from '../styles/GlobalStyles';
import moment from 'moment';

function CountyPricingCard(props) {
    const checkout = props.checkout;
    const total = props.total;
    const startDate = new Date();
	const endDate = moment(startDate).add(1, "M").format("D MMMM YYYY");
    const discountApplied = props.discountApplied;
    const plan = props.plan;

    const calculateDiscountedAmount = () => {
        const originalPrice = plan.originalPrice * plan.months;
        const discountAmount = originalPrice * (discountApplied.discount / 100);
        return discountAmount;
    };

    const calculateDiscountedTotal = () => {
        const originalPrice = plan.originalPrice * plan.months;
        const discount = discountApplied.discount + plan.discount;
        const discountAmount = originalPrice * (discount / 100);
        const finalPrice = originalPrice - discountAmount;
        return finalPrice;
    };

    return (
        <div className="pricing-card-outer-container">
            <div className="pricing-card-top-inner-container">
                <div className="pricing-card-image-container">
                    <img
                        src={Cappuccino}
                        className="pricing-card-image"
                        alt="Cappuccino"
                    />
                </div>
                <span className="body-regular colour-quaternary margin-x-small block-text">
                    Coffee Clozers Premium
                </span>
                <h2 className="heading-large-semibold text-link">
                    Upgrade summary
                </h2>
            </div>
            <div className="pricing-card-bottom-container bg-colour-white">
                <div className="pricing-card-cities-list-container margin-medium">
                    <div className="pricing-card-bottom-inner-container margin-x-large">
                        <div className="pricing-card-bottom-left-container">
                            <h3 className="body-semibold colour-primary margin-x-x-small">
                                {plan.title}
                            </h3>
                            <h4 className="body-semibold colour-quaternary">
                                {plan.label}
                            </h4>
                        </div>
                        <div className="pricing-card-bottom-right-container">
                            {
                                checkout === true && plan.months > 1 ?
                                <div className="button-row">
                                    <span className="body-regular colour-secondary block-text subtext-margin-right strike-through">
                                        {formatterLong.format(plan.originalPrice).replace(".00", "")}
                                    </span>
                                    <span className="body-semibold colour-primary">
                                        {formatterLong.format(plan.price).replace(".00", "")}
                                    </span>
                                </div>
                                :
                                <span className="body-semibold colour-primary">
                                    {formatterLong.format(plan.price).replace(".00", "")}
                                </span>
                            }
                            <span className="label-regular colour-secondary">
                                /month
                            </span>
                            {
                                checkout === true && plan.months > 1 ?
                                <span className="label-semibold colour-primary">
                                    x{plan.months} months
                                </span>
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        discountApplied !== null ?
                        <div className="pricing-card-bottom-inner-container margin-x-large">
                            <div className="pricing-card-bottom-left-container">
                                <h3 className="body-semibold colour-primary margin-x-small">
                                    Promo code
                                </h3>
                            </div>
                            <div className="pricing-card-bottom-right-container">
                                <span className="body-regular colour-secondary">
                                    -{formatterLong.format(calculateDiscountedAmount()).replace(".00", "")}
                                </span>
                                <span className="label-semibold colour-primary">
                                    Additional {discountApplied.discount}% off
                                </span>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="city-selection-divider-container margin-tops">
                </div>
                <div className="pricing-card-total-container">
                    <div className="pricing-card-total-label-container">
                        <span className="body-semibold colour-primary">
                            Total
                        </span>
                    </div>
                    <div className={"pricing-card-total-text-container " + (checkout === true ? "margin-x-small" : "")}>
                        {
                            checkout === false ?
                            <span className="body-regular colour-sceondary text-italic block-text text-align-right">
                                Total will be calculated based on <br/>
                                which plan you choose (next step)
                            </span>
                            :
                            checkout === true && total === 0 ?
                            <span className="body-regular colour-secondary text-italic">
                                Select a plan to calculate total
                            </span>
                            :
                            <div className="pricing-card-total-inner-container">
                                <div className="button-row">
                                    {
                                        plan.months > 1 ?
                                        <span className="body-regular colour-secondary block-text subtext-margin-right strike-through">
                                            {formatterLong.format(plan.originalPrice * plan.months).replace(".00", "")}
                                        </span>
                                        :
                                        null
                                    }
                                    {
                                        discountApplied !== null ?
                                        <span className="body-semibold colour-primary">
                                            {formatterLong.format(calculateDiscountedTotal()).replace(".00", "")}
                                        </span>
                                        :
                                        <span className="body-semibold colour-primary">
                                            {formatterLong.format(total).replace(".00", "")}
                                        </span>
                                    }
                                </div>
                                {
                                    plan.months > 1 ?
                                    <span className="label-semibold-caps colour-primary block-text margin-top-x-small">
                                        {plan.months} MONTHS ACCESS
                                    </span>
                                    :
                                    <span className="label-regular colour-secondary">
                                        Today
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    checkout === true && plan.months > 1 ?
                    <div className="pricing-card-supplementary-value margin-top-small">
                        <div className="button-row margin-x-small">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4995 1.90933C16.1415 2.51658 16.1696 3.52926 15.5623 4.1712L6.22901 14.0379C5.93211 14.3517 5.52089 14.5322 5.08888 14.5382C4.65687 14.5442 4.2408 14.3752 3.93529 14.0697L0.468629 10.6031C-0.15621 9.97821 -0.15621 8.96515 0.468629 8.34031C1.09347 7.71547 2.10653 7.71547 2.73137 8.34031L5.0348 10.6437L13.2376 1.97216C13.8449 1.33022 14.8576 1.30209 15.4995 1.90933Z" fill="#68AB8E"/>
                            </svg>
                            <span className="body-regular colour-primary block-text subtext-margin-left-large">
                                No automatic renewal
                            </span>
                        </div>
                        <div className="button-row">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4995 1.90933C16.1415 2.51658 16.1696 3.52926 15.5623 4.1712L6.22901 14.0379C5.93211 14.3517 5.52089 14.5322 5.08888 14.5382C4.65687 14.5442 4.2408 14.3752 3.93529 14.0697L0.468629 10.6031C-0.15621 9.97821 -0.15621 8.96515 0.468629 8.34031C1.09347 7.71547 2.10653 7.71547 2.73137 8.34031L5.0348 10.6437L13.2376 1.97216C13.8449 1.33022 14.8576 1.30209 15.4995 1.90933Z" fill="#68AB8E"/>
                            </svg>
                            <span className="body-regular colour-primary block-text subtext-margin-left-large">
                                Money-back guarantee within 14 days!
                            </span>
                        </div>
                    </div>
                    :
                    checkout === true && plan.months === 1 ?
                    <div className="pricing-card-renewal-container margin-x-small">
                        <div className="pricing-card-total-container margin-x-small">
                            <div className="pricing-card-total-label-container">
                                <span className="body-semibold colour-primary">
                                    Renews
                                </span>
                            </div>
                            <div className="pricing-card-total-text-container">
                                <span className="body-regular colour-primary">
                                    {endDate}
                                </span>
                            </div>
                        </div>
                        <span className="body-regular colour-primary">
                            Will renew monthly until you cancel.
                        </span>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default CountyPricingCard;