import React from 'react';

function Parking(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M2.08987 3.99341L2.37177 2.79531C2.65132 1.60724 3.71148 0.767578 4.93199 0.767578H11.2863C12.5068 0.767578 13.567 1.60724 13.8465 2.79531L14.1286 3.99421C14.2091 3.89758 14.3302 3.83607 14.4658 3.83607H15.5616C15.8037 3.83607 16 4.03233 16 4.27443V4.93196C16 5.17406 15.8037 5.37032 15.5616 5.37032H14.4658C14.4613 5.37032 14.4568 5.37025 14.4524 5.37012L14.5723 5.87984C15.4202 6.31644 16 7.20032 16 8.21963V14.1374C16 14.7427 15.5094 15.2333 14.9041 15.2333H14.0274C13.4222 15.2333 12.9315 14.7427 12.9315 14.1374V13.4799H3.06849V14.1374C3.06849 14.7427 2.57785 15.2333 1.9726 15.2333H1.09589C0.490647 15.2333 0 14.7427 0 14.1374V8.21963C0 7.10513 0.693203 6.15253 1.67197 5.76948L1.76593 5.37014C1.76178 5.37026 1.75761 5.37032 1.75342 5.37032H0.657534C0.415437 5.37032 0.219178 5.17406 0.219178 4.93196V4.27443C0.219178 4.03233 0.415437 3.83607 0.657534 3.83607H1.75342C1.88858 3.83607 2.00946 3.89724 2.08987 3.99341ZM4.93199 1.42511H11.2863C12.2017 1.42511 12.9968 2.05486 13.2065 2.94591L13.8383 5.63109C13.6863 5.60376 13.5297 5.5895 13.3699 5.5895H2.63014C2.54823 5.5895 2.4672 5.59324 2.3872 5.60057L3.01183 2.94591C3.22149 2.05486 4.0166 1.42511 4.93199 1.42511ZM0.876712 8.02481C0.876712 7.16402 1.57452 6.46621 2.43531 6.46621H2.63014C2.75119 6.46621 2.84932 6.56434 2.84932 6.68539V8.00045C2.84932 8.1215 2.75119 8.21963 2.63014 8.21963H1.07154C0.963939 8.21963 0.876712 8.13241 0.876712 8.02481ZM12.274 7.78128C12.274 8.50757 11.6852 9.09634 10.9589 9.09634L5.26027 9.09634C4.53398 9.09634 3.94521 8.50757 3.94521 7.78128V6.68539C3.94521 6.56434 4.04333 6.46621 4.16438 6.46621L12.0548 6.46621C12.1758 6.46621 12.274 6.56434 12.274 6.68539V7.78128ZM13.5647 6.46621C14.4255 6.46621 15.1233 7.16402 15.1233 8.02481C15.1233 8.13241 15.0361 8.21963 14.9285 8.21963H13.3699C13.2488 8.21963 13.1507 8.1215 13.1507 8.00045V6.68539C13.1507 6.56434 13.2488 6.46621 13.3699 6.46621H13.5647Z" 
                fill={fill}
            />
        </svg>
    )
}

export default Parking;