import React from 'react';
import '../styles/Flag.css';

function Flag(props) {
    const label = props.label;
    const bgColour = props.bgColour;
    const icon = props.icon;
    const standardIcon = props.standardIcon;

    return (
        <div className={"flag-container " + (bgColour)}>
            {
                icon !== null && standardIcon === true ?
                <img
                    src={icon}
                    className="flag-icon"
                    alt="Flag icon"
                />
                :
                standardIcon === false ?
                icon
                :
                null
            }
            <span className="body-semibold colour-primary block-text text-align-center">
                {label}
            </span>
        </div>
    )
};

export default Flag;