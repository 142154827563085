import React from 'react';

function ExpandThin(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M23.1429 0C23.6162 0 24 0.383756 24 0.857143V8.57143C24 9.04482 23.6162 9.42857 23.1429 9.42857C22.6695 9.42857 22.2857 9.04482 22.2857 8.57143V2.92647L2.92647 22.2857H8.57143C9.04482 22.2857 9.42857 22.6695 9.42857 23.1429C9.42857 23.6162 9.04482 24 8.57143 24H0.857143C0.383756 24 0 23.6162 0 23.1429V15.4286C0 14.9552 0.383756 14.5714 0.857143 14.5714C1.33053 14.5714 1.71429 14.9552 1.71429 15.4286V21.0735L21.0735 1.71429H15.4286C14.9552 1.71429 14.5714 1.33053 14.5714 0.857143C14.5714 0.383756 14.9552 0 15.4286 0H23.1429Z" 
                fill={fill}
            />
        </svg>
    )
};

export default ExpandThin;