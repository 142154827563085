import React, { useState } from 'react';
import '../styles/CompFilters.css';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CustomSelect, bathroomOptions, bedroomOptions, colour, squareFootageRange, FormInput, numberFormatter, CustomRadio, ActionButton, fontFamily } from '../styles/GlobalStyles';
import { Bathrooms, BedroomBlack } from '../assets';
import InputAdornment from '@mui/material/InputAdornment';
import { recordEvent } from '../functions';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function CompFilters(props) {
    const bedrooms = props.bedrooms;
    const setBedrooms = props.setBedrooms;
    const bathrooms = props.bathrooms;
    const setBathrooms = props.setBathrooms;
    const squareFootage = props.squareFootage;
    const setSquareFootage = props.setSquareFootage;
    const priceRange = props.priceRange;
    const setPriceRange = props.setPriceRange;
    const listingStatus = props.listingStatus;
    const setListingStatus = props.setListingStatus;
    const setOpenFilters = props.setOpenFilters;
    const saveFilters = props.saveFilters;
    const setNumberOfFilters = props.setNumberOfFilters;
    const setMaxDistance = props.setMaxDistance;
    const setMaxSoldDate = props.setMaxSoldDate;
    const resetAllFilters = props.resetAllFilters;
    const isNonDisclosure = props.isNonDisclosure;
    const rentMode = props.rentMode;

    const [modalBeds, setModalBeds] = useState(bedrooms);
    const [modalBaths, setModalBaths] = useState(bathrooms);
    const [modalSqFootage, setModalSqFootage] = useState(squareFootage);
    const [modalPriceRange, setModalPriceRange] = useState(priceRange);
    const [modalListingStatus, setModalListingStatus] = useState(listingStatus);
    const [priceRangeError, setPriceRangeError] = useState("")

    const statuses = [
        {
            label: "For sale",
            value: 0
        },
        {
            label: "Sold",
            value: 1
        },
        {
            label: "Both",
            value: 2
        }
    ];

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: fontFamily
		},
        fullWidth: {
            width: '100%'
        }
    };

    const changePriceRange = (val, index) => {
        const removedCommas = val.replace(",", "");
        const clone = [...modalPriceRange];
        clone[index] = removedCommas;
        setModalPriceRange(clone);
    };

    const formatInput = (val) => {
        if ( val === "" ) {
            return val;
        }
        else {
            const numberVal = Number(val);
            const formattedVal = numberFormatter.format(numberVal);
            return formattedVal;
        }
    };

    const changeListingStatus = (val) => {
        setModalListingStatus(Number(val));
    };

    const closeModal = () => {
        recordEvent("Close Comp Filters", {});
        setOpenFilters(false);
    };

    const resetFilters = () => {
        setBedrooms(1);
        setBathrooms(1);
        setSquareFootage([0, 999999]);
        setPriceRange(["", ""]);
        setListingStatus(2);
        setOpenFilters(false);
        setNumberOfFilters(0);
        setMaxDistance(2);
        setMaxSoldDate(6);
        resetAllFilters(2, 6, 1, 1, [0, 999999], ["", ""], 2);
    };

    const complete = () => {
        const minPrice = Number(modalPriceRange[0]);
        const maxPrice = Number(modalPriceRange[1]);
        if ( minPrice > maxPrice && maxPrice !== 0 && maxPrice !== "" ) {
            setPriceRangeError("Looks like the min is bigger than the max...this is awkward");
        }
        else {
            setBedrooms(Number(modalBeds));
            setBathrooms(Number(modalBaths));

            const sqFootageNumber = [Number(modalSqFootage[0]), Number(modalSqFootage[1])];
            setSquareFootage(sqFootageNumber);

            const priceRangeNumber = [modalPriceRange[0] === "" ? "" : Number(modalPriceRange[0]), modalPriceRange[1] === "" ? "" : Number(modalPriceRange[1])];
            setPriceRange(priceRangeNumber);
            setListingStatus(modalListingStatus);
            setPriceRangeError("");
            recordEvent("Save Comp Filters");
            setOpenFilters(false);

            let newTotalFilters = 0;
            if ( Number(modalBeds) > 1 ) {
                newTotalFilters++;
            }

            if ( Number(modalBaths) > 1 ) {
                newTotalFilters++;
            }

            if ( Number(modalSqFootage[0]) !== 0 || Number(modalSqFootage[1]) !== 999999 ) {
                newTotalFilters++;
            }

            if ( modalPriceRange[0] !== "" || modalPriceRange[1] !== "" ) {
                newTotalFilters++;
            }

            if ( modalListingStatus !== 2 ) {
                newTotalFilters++;
            }

            setNumberOfFilters(newTotalFilters);
            saveFilters(modalBeds, modalBaths, sqFootageNumber, priceRangeNumber, modalListingStatus, newTotalFilters);
        }
    };

    const viewArticle = () => {
        window.open('https://www.attomdata.com/news/most-recent/do-you-know-what-non-disclosure-states-are/', '_blank');
    };

    return (
        <div className={"comp-filters-outer-container bg-colour-light " + (rentMode === true ? "comp-filters-rent-mode" : "")}>
            <div className="comp-filters-inner-container relative-container">
                <div 
                    className="comp-filters-exit-container"
                    onClick={() => closeModal()}
                >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="22" 
                        height="24" 
                        viewBox="0 0 22 24" 
                        fill="none"
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.25829 0.419021C1.7903 -0.100741 0.989564 -0.142706 0.469802 0.32529C-0.0499597 0.793286 -0.0919245 1.59402 0.376071 2.11378L9.2968 12.0213L0.414356 21.8862C-0.0536387 22.406 -0.0116752 23.2067 0.508087 23.6747C1.02785 24.1427 1.82858 24.1007 2.29658 23.581L11.0009 13.9138L19.7052 23.5809C20.1732 24.1007 20.9739 24.1427 21.4937 23.6747C22.0134 23.2067 22.0554 22.4059 21.5874 21.8862L12.705 12.0213L21.6257 2.11382C22.0937 1.59406 22.0517 0.793322 21.532 0.325326C21.0122 -0.142669 20.2115 -0.100704 19.7435 0.419057L11.0009 10.1287L2.25829 0.419021Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
                <div className="comp-filters-title-container margin-x-large">
                    <h2 className="heading-small-semibold colour-quaternary">
                        Filter comps
                    </h2>
                </div>
                <div className="comp-filters-section-row margin-x-large">
                    <div className="comp-filters-element-container">
                            <div className="comp-filters-label-row margin-x-x-small">
                                <img
                                    src={BedroomBlack}
                                    className="comp-filters-icon"
                                    alt="Bedrooms"
                                />
                                <span className="body-semibold colour-primary">
                                    Beds
                                </span>
                            </div>
                            <Box sx={styles.fullWidth}>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="beds-select-label"
                                        id="bedroom-select"
                                        value={modalBeds}
                                        label=""
                                        onChange={(event) => setModalBeds(event.target.value)}
                                        input={<CustomSelect />}
                                    >
                                        {
                                            bedroomOptions.map((item, index) =>
                                                <MenuItem 
                                                    key={index}
                                                    value={item.value}
                                                    style={styles.menuItem}
                                                >
                                                    {item.label}
                                                </MenuItem>	
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div className="comp-filters-element-container">
                            <div className="comp-filters-label-row margin-x-x-small">
                                <img
                                    src={Bathrooms}
                                    className="comp-filters-icon"
                                    alt="Bathrooms"
                                />
                                <span className="body-semibold colour-primary">
                                    Baths
                                </span>
                            </div>
                            <Box sx={styles.fullWidth}>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="baths-select-label"
                                        id="baths-select"
                                        value={modalBaths}
                                        label=""
                                        onChange={(event) => setModalBaths(event.target.value)}
                                        input={<CustomSelect />}
                                    >
                                        {
                                            bathroomOptions.map((item, index) =>
                                                <MenuItem 
                                                    key={index}
                                                    value={item.value}
                                                    style={styles.menuItem}
                                                >
                                                    {item.label}
                                                </MenuItem>	
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                </div>
                <div className="comp-filters-label-row margin-x-x-small">
                    <span className="body-semibold colour-primary">
                        Square footage
                    </span>
                </div>
                <div className="comp-filters-section-row margin-x-large">
                    <div className="comp-filters-element-container">
                        <Box style={styles.fullWidth}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="min-sq-footage-label"
                                    id="min-sq-footage-select"
                                    value={modalSqFootage[0]}
                                    label=""
                                    onChange={(event) => setModalSqFootage([event.target.value, modalSqFootage[1]])}
                                    input={<CustomSelect />}
                                >
                                    {
                                        squareFootageRange.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                                disabled={modalSqFootage[1] <= item.value ? true : false}
                                            >
                                                {item.value === 0 || item.value === 999999 ? item.label : item.value}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="comp-filters-element-container">
                        <Box style={styles.fullWidth}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="max-sq-footage-label"
                                    id="max-sq-footage-select"
                                    value={modalSqFootage[1]}
                                    label=""
                                    onChange={(event) => setModalSqFootage([modalSqFootage[0], event.target.value])}
                                    input={<CustomSelect />}
                                >
                                    {
                                        squareFootageRange.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                                disabled={modalSqFootage[0] >= item.value ? true : false}
                                            >
                                                {item.value === 0 || item.value === 999999 ? item.label : item.value}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                <div className="comp-filters-label-row margin-x-x-small">
                    <span className="body-semibold colour-primary">
                        {rentMode === true ? "Rent" : "Price"} range
                    </span>
                </div>
                <div className={"comp-filters-section-row " + (priceRangeError === "" ? "margin-x-large" : "margin-x-small")}>
                    <div className="comp-filters-element-container">
                        <FormInput
                            value={formatInput(modalPriceRange[0])}
                            type="text"
                            id={`price-range-min-input`}
                            placeholder={rentMode === true ? "2,000" : "100,000"}
                            label="Min"
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            onChange={(text) => changePriceRange(text.target.value, 0)}
                        />
                    </div>
                    <div className="comp-filters-element-container">
                        <FormInput
							value={formatInput(modalPriceRange[1])}
							type="text"
							label="Max"
							id="price-range-max-input"
							placeholder={rentMode === true ? "2,500" : "800,000"}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
							onChange={(text) => changePriceRange(text.target.value, 1)}
						/>
                    </div>
                </div>
                {
                    priceRangeError !== "" ?
                    <div className="margin-x-large">
                        <span className="body-regular colour-error">
                            {priceRangeError}
                        </span>
                    </div>
                    :
                    null
                }
                {
                    rentMode === false ?
                    <div className="comp-filters-label-row margin-x-x-small">
                        <span className="body-semibold colour-primary">
                            Listing status
                        </span>
                    </div>
                    :
                    null
                }
                {
                    rentMode === false ?
                    <div className="margin-x-large">
                        <FormControl className="">
                            <RadioGroup
                                aria-labelledby="listing-status-buttons-group-label"
                                name="listing-status-radio-buttons-group"
                                value={modalListingStatus}
                                className=""
                                onChange={(val) => changeListingStatus(val.target.value)}
                            >
                                {
                                    statuses.map((item, index) =>
                                        <FormControlLabel
                                            value={item.value} 
                                            control={<CustomRadio />} 
                                            className=""
                                            label={<span className="body-regular colour-primary">{item.label}{isNonDisclosure === true && item.value === 1 ? <span className="colour-error" onClick={() => viewArticle()}> - no data for Non-Disclosure States</span> : null}</span>}
                                            key={index}
                                            disabled={isNonDisclosure === true && item.value === 1 ? true : false}
                                        />
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                    :
                    null
                }
                <div className="comp-filters-ctas-row-container">
                    <div 
                        className="text-button"
                        onClick={() => resetFilters()}
                    >
                        <span className="body-regular-underline colour-link">
                            Reset
                        </span>
                    </div>
                    <div className="comp-filters-cta-complete-button">
                        <ActionButton
                            onClick={() => complete()}
                        >
                            Save
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CompFilters;