import React, { useEffect, useState } from 'react';
import '../styles/SellerFinanceCalculator.css';
import { CustomSlider, FinancialsInput } from '../v4/components';
import { calculateInterestPaid, calculateMortgagePayment, formatPrice, checkNaN } from '../functions';

function SellerFinanceCalculator(props) {
    const property = props.property;
    const customPrice = props.customPrice;
    const setCustomPrice = props.setCustomPrice;
    const downPayment = props.downPayment;
    const setDownPayment = props.setDownPayment;
    const interestRate = props.interestRate;
    const setInterestRate = props.setInterestRate;
    const balloonPayment = props.balloonPayment;
    const setBalloonPayment = props.setBalloonPayment;
    const duration = props.duration;
    const setDuration = props.setDuration;
    const amortisation = props.amortisation;
    const setAmortisation = props.setAmortisation;
    const setMonthlyPayment = props.setMonthlyPayment;
    const setSellerCompensation = props.setSellerCompensation;
    const defaultDownPayment = props.defaultDownPayment;
    const onChangeSellerFinance = props.onChangeSellerFinance;
    const closingPercentage = props.closingPercentage;
    const setClosingPercentage = props.setClosingPercentage;
    const closingCost = props.closingCost;
    const defaultInterestRate = 3.5;

    const [firstQuery, setFirstQuery] = useState(false);
    const [dollarDownPayment, setDollarDownPayment] = useState((customPrice * (downPayment / 100)));
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(false);
    const [priceSlider, setPriceSlider] = useState(20);
    const [downPaymentSlider, setDownPaymentSlider] = useState(defaultDownPayment);

    useEffect(() => {

        const calculateFirstMonthlyPayment = async() => {
            const newMortgage = await calculateMortgagePayment(customPrice, downPayment, interestRate, (amortisation / 12));
            setMonthlyPayment(newMortgage);

            const totalInterest = await calculateInterestPaid(customPrice, downPayment, interestRate, (amortisation / 12), (duration / 12));
            const newTotalCompensation = Number(customPrice) + totalInterest;
            setSellerCompensation(newTotalCompensation);
            const downPaymentFee = customPrice * (downPayment / 100);
            const totalMonthlyPayments = newMortgage * duration;
            const remainingBalance = (newTotalCompensation - downPaymentFee) - totalMonthlyPayments;
            setBalloonPayment(remainingBalance);
        };

        if ( firstQuery === false ) {
            setFirstQuery(true);
            calculateFirstMonthlyPayment();
        }
    }, [
        amortisation,
        customPrice,
        downPayment,
        duration,
        firstQuery,
        interestRate,
        setBalloonPayment,
        setMonthlyPayment,
        setSellerCompensation
    ]);

    const roundToNearestTenth = (num) => {
        return Math.round(num * 10) / 10;
    };

    const onChangePrice = async(value) => {
        setCustomPrice(value);

        const oneSliderUnit = property.price / 20;
        const newSliderValue = roundToNearestTenth(Number(value) / oneSliderUnit);
        setPriceSlider(newSliderValue);

        const newClosingCost = (Number(value) * (closingPercentage / 100));
        recalculateMonthlyPayment(value, Number(downPayment), interestRate, duration, amortisation, newClosingCost);
    };

    const onChangePriceSlider = (event, value) => {
        setPriceSlider(value);

        const oneUnit = property.price / 20;
        const newPrice = (oneUnit * value);
        onChangePrice(newPrice);
    };

    const onChangeDownPayment = async(value) => {
        if ( checkNaN(value) === true && value !== "" ) {
            return;
        }
        else if ( Number(value) > 100 ) {
            return;
        }
        setDownPayment(value);
        const newDollar = (Number(value) / 100) * customPrice;
        setDollarDownPayment(newDollar);

        const newSliderValue = roundToNearestTenth(Number(value));
        setDownPaymentSlider(newSliderValue);

        recalculateMonthlyPayment(customPrice, Number(value), interestRate, duration, amortisation, closingCost);
    };

    const onChangeDownPaymentDollar = async(value) => {
        if ( checkNaN(value) === true && value !== "" ) {
            return;
        }
        const newPercentage = (Number(value) / customPrice) * 100;
        setDownPayment(newPercentage);
        setDollarDownPayment(value);
        setDownPaymentSlider(Math.round(newPercentage));

        recalculateMonthlyPayment(customPrice, Number(newPercentage), interestRate, duration, amortisation, closingCost);
    };

    const onChangeDownPaymentSlider = (event, value) => {
        setDownPaymentSlider(value);

        const newDownPayment = ((customPrice * value) / 100);
        onChangeDownPaymentDollar(newDownPayment);
    };

    const toggleDPPercentage = (val) => {
        setDownPaymentPercentage(val);
    };

    const onChangeInterestRate = async(value) => {
        setInterestRate(value);
        recalculateMonthlyPayment(customPrice, Number(downPayment), value, duration, amortisation, closingCost);
    };

    const onChangeClosing = async(value) => {
        if ( checkNaN(value) === true && value !== "" )  {
			return;
		}

		if ( Number(value) <= 100 ) {
			setClosingPercentage(value);
            const newClosingCost = (customPrice * (Number(value) / 100));
            recalculateMonthlyPayment(customPrice, Number(downPayment), interestRate, duration, amortisation, newClosingCost);
		}
    };

    const onChangeBalloon = (value) => {
        setBalloonPayment(value);
    };

    const onChangeDuration = async(value) => {
        if ( checkNaN(value) === true && value !== "" ) {
            return;
        }
        const newVal = value === "" ? "" : Number(value) * 12;
        setDuration(newVal);
        recalculateMonthlyPayment(customPrice, Number(downPayment), interestRate, newVal, amortisation, closingCost);
    };

    const onChangeAmortisation = async(value) => {
        if ( checkNaN(value) === true && value !== "" ) {
            return;
        }
        const newVal = value === "" ? "" : Number(value) * 12;
        setAmortisation(newVal);
        recalculateMonthlyPayment(customPrice, Number(downPayment), interestRate, duration, newVal, closingCost);
    };
    
    const recalculateMonthlyPayment = async(newPrice, newDown, newInt, newDuration, newAmortisation, newClosingCost) => {
        const newMortgage = await calculateMortgagePayment(newPrice, newDown, newInt, (newAmortisation / 12));
        setMonthlyPayment(newMortgage);

        const totalInterest = await calculateInterestPaid(newPrice, newDown, newInt, (newAmortisation / 12), (newDuration / 12));
        const newTotalCompensation = Number(newPrice) + totalInterest;
        setSellerCompensation(newTotalCompensation);
        const downPaymentFee = newPrice * (newDown / 100);
        const totalMonthlyPayments = newMortgage * newDuration;
        const remainingBalance = (newTotalCompensation - downPaymentFee) - totalMonthlyPayments;
        setBalloonPayment(remainingBalance);

        const res = {
            downPayment: downPaymentFee,
            totalMonthlyPayment: newMortgage,
            sellerFinance: true,
            price: newPrice,
            closingCost: newClosingCost
        };
        onChangeSellerFinance(res);
    };

    return (
        <div className="seller-finance-calculator-container">
            <div className="seller-finance-calculator-grid-container">
                <div className="seller-finance-grid-element">
                    <div className="seller-finance-input-container">
                        <FinancialsInput
                            label={"Offer price"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(customPrice)}
                            onChange={(text) => onChangePrice(text.target.value.replaceAll(",", ""))}
                            placeholder={formatPrice(property.price)}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={null}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={false}
                            multiline={false}
                            error={checkNaN(customPrice)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={Number(customPrice) !== Number(property.price) ? true : false}
                            resetFunc={() => onChangePrice(property.price)}
                            resetPosition={"default"}
                        />
                    </div>
                    <CustomSlider
                        disabled={false}
                        value={priceSlider}
                        label="Offer price"
                        onChange={onChangePriceSlider}
                        min={0}
                        max={25}
                    />
                </div>
                <div className="seller-finance-grid-element">
                    <div className="seller-finance-input-container">
                        <FinancialsInput
                            label={"Down payment"}
                            labelType={"default"}
                            info={false}
                            value={downPaymentPercentage === false ? formatPrice(dollarDownPayment) : formatPrice(downPayment)}
                            onChange={(text) => downPaymentPercentage === false ? onChangeDownPaymentDollar(text.target.value.replaceAll(",", "")) : onChangeDownPayment(text.target.value)}
                            placeholder={downPaymentPercentage === false ? 10 : formatPrice(property.price * 0.1)}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={"%"}
                            unitSwitch={true}
                            leftUnitSelected={!downPaymentPercentage}
                            unitSwitchFunc={(val) => toggleDPPercentage(val)}
                            disabled={false}
                            multiline={false}
                            error={checkNaN(customPrice)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={Number(downPayment) !== Number(defaultDownPayment) ? true : false}
                            resetFunc={() => onChangeDownPayment(defaultDownPayment)}
                            resetPosition={"default"}
                        />
                    </div>
                    <CustomSlider
                        disabled={false}
                        value={downPaymentSlider}
                        label="Down payment"
                        onChange={onChangeDownPaymentSlider}
                        min={0}
                        max={100}
                    />
                </div>
                <div className="seller-finance-grid-element-small seller-finance-grid-element-third">
                    <div className="seller-finance-input-container">
                        <FinancialsInput
                            label={"Interest rate"}
                            labelType={"default"}
                            info={false}
                            value={interestRate}
                            onChange={(text) => onChangeInterestRate(text.target.value.replaceAll(",", ""))}
                            placeholder={3.5}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={false}
                            multiline={false}
                            error={checkNaN(interestRate)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={Number(interestRate) !== Number(defaultInterestRate) ? true : false}
                            resetFunc={() => onChangeInterestRate(defaultInterestRate)}
                            resetPosition={"default"}
                        />
                    </div>
                </div>
                <div className="seller-finance-grid-element seller-finance-grid-element-third">
                    <div className="seller-finance-input-container">
                        <FinancialsInput
                            label={"Amortization"}
                            labelType={"default"}
                            info={false}
                            value={amortisation === "" ? "" : amortisation / 12}
                            onChange={(text) => onChangeAmortisation(text.target.value.replaceAll(",", ""))}
                            placeholder={30}
                            type="text"
                            leftUnit={null}
                            rightUnit={"years"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={false}
                            multiline={false}
                            error={checkNaN(amortisation)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
                <div className="seller-finance-grid-element seller-finance-grid-element-third">
                    <div className="seller-finance-input-container">
                        <FinancialsInput
                            label={"Closing costs"}
                            labelType={"default"}
                            info={false}
                            value={closingPercentage}
                            onChange={(text) => onChangeClosing(text.target.value.replaceAll(",", ""))}
                            placeholder={"3"}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={false}
                            multiline={false}
                            error={checkNaN(closingPercentage)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
                <div className="seller-finance-grid-element">
                    <div className="seller-finance-input-container">
                        <FinancialsInput
                            label={"Balloon payment"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(balloonPayment)}
                            onChange={(text) => onChangeBalloon(text.target.value.replaceAll(",", ""))}
                            placeholder={formatPrice(200000)}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={null}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={true}
                            multiline={false}
                            error={checkNaN(balloonPayment)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
                <div className="seller-finance-grid-element">
                    <div className="seller-finance-input-container">
                        <FinancialsInput
                            label={"Balloon due in"}
                            labelType={"default"}
                            info={false}
                            value={duration === "" ? "" : duration / 12}
                            onChange={(text) => onChangeDuration(text.target.value.replaceAll(",", ""))}
                            placeholder={5}
                            type="text"
                            leftUnit={null}
                            rightUnit={"years"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={false}
                            multiline={false}
                            error={checkNaN(duration)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SellerFinanceCalculator;