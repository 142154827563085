import React from 'react';

function Condition(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M10.4274 3.30047L9.89549 3.34862L6.6908 0.789226C6.02054 0.253929 5.08437 0.258055 4.36699 0.731638C4.2351 0.818554 4.31767 1.00573 4.47022 1.00731C5.03084 1.01332 5.57151 1.25721 5.93466 1.72571C6.82649 2.87625 6.07735 3.857 5.32457 4.79908L6.82047 5.99377L7.28338 5.41445C7.40194 5.26609 7.55717 5.17719 7.72405 5.1042C7.95996 5.00091 8.22832 5.04215 8.41996 5.1952L8.37877 5.47979C8.3661 5.56808 8.39628 5.65445 8.45972 5.7112L9.3874 6.54129C9.49891 6.64106 9.67606 6.62029 9.77551 6.49583L11.4442 4.40753C11.5401 4.28756 11.5264 4.11732 11.4138 4.02738L10.5481 3.33596C10.5304 3.32185 10.5101 3.31163 10.4883 3.30569C10.4688 3.30043 10.4482 3.29863 10.4274 3.30047Z" 
                fill={fill}
            />
            <path 
                d="M6.62684 6.25433L5.13094 5.05964L0.563427 10.5239C0.296404 10.8433 0.328019 11.3072 0.633467 11.5511L1.28739 12.0734C1.59284 12.3173 2.05234 12.2457 2.30502 11.9148L6.62684 6.25433Z" 
                fill={fill}
            />
            <path 
                d="M9.50475 13.517C9.50475 13.3061 9.67581 13.1351 9.88682 13.1351H13.7076C13.9186 13.1351 14.0896 13.3061 14.0896 13.517V15.2359C14.0896 15.4469 13.9186 15.6179 13.7076 15.6179H9.88682C9.67581 15.6179 9.50475 15.4469 9.50475 15.2359V13.517Z" 
                fill={fill}
            />
            <path 
                d="M11.4151 9.88831C11.2041 9.88831 11.033 10.0593 11.033 10.2703V11.9892C11.033 12.2001 11.2041 12.3711 11.4151 12.3711H15.2359C15.4469 12.3711 15.6179 12.2001 15.6179 11.9892V10.2703C15.6179 10.0593 15.4469 9.88831 15.2359 9.88831H11.4151Z" 
                fill={fill}
            />
            <path 
                d="M4.15571 13.517C4.15571 13.3061 4.32677 13.1351 4.53779 13.1351H8.35853C8.56954 13.1351 8.7406 13.3061 8.7406 13.517V15.2359C8.7406 15.4469 8.56954 15.6179 8.35853 15.6179H4.53779C4.32677 15.6179 4.15571 15.4469 4.15571 15.2359V13.517Z" 
                fill={fill}
            />
            <path 
                d="M6.06608 9.88831C5.85507 9.88831 5.68401 10.0593 5.68401 10.2703V11.9892C5.68401 12.2001 5.85507 12.3711 6.06608 12.3711H9.88682C10.0978 12.3711 10.2689 12.2001 10.2689 11.9892V10.2703C10.2689 10.0593 10.0978 9.88831 9.88682 9.88831H6.06608Z" 
                fill={fill}
            />
        </svg>
    )
}

export default Condition;