import React from 'react';
import '../styles/EmailVerificationError.css';
import { Header, Footer } from '../components';
import { useNavigate } from 'react-router-dom';

function EmailVerificationError() {
    const navigate = useNavigate();
    const url = "https://firebasestorage.googleapis.com/v0/b/refi-787d3.appspot.com/o/email-verification-error.png?alt=media&token=6a9080ab-a441-4299-a42c-4f29317111dd";

    const resend = () => {
        navigate("/verify-email");
    };

    return (
        <div className="email-verification-error-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
                desktopControl={false}
            />
            <div className="email-verification-error-inner-container">
                <div className="email-verification-error-image-container">
                    <img
                        src={url}
                        className="email-verification-error-image"
                        alt="Pabs mad"
                    />
                </div>
                <div className="email-verification-error-text-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Oops! Something went wrong
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        That link is no longer valid.{" "}
                        <span className="text-link underline cursor-pointer" onClick={() => resend()}>Please click here to resend</span>{" "}
                        the verification email.
                    </span>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EmailVerificationError;