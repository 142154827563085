import React from 'react';

function Units(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M1.21429 16C0.819797 16 0.5 15.6744 0.5 15.2727V0.727273C0.5 0.325611 0.819797 0 1.21429 0H14.7857C15.1802 0 15.5 0.325611 15.5 0.727273V15.2727C15.5 15.6744 15.1802 16 14.7857 16H1.21429ZM1.21429 0.727273H14.7857V15.2727H12.6429V6.90909C12.6429 4.29829 10.5642 2.18182 8 2.18182C5.43582 2.18182 3.35714 4.29829 3.35714 6.90909V15.2727H1.21429L1.21429 0.727273ZM5.69791 4.13465C6.2445 3.64607 6.95759 3.32923 7.74559 3.27273V5.08905C7.47215 5.13343 7.22335 5.24811 7.01974 5.41322L5.69791 4.13465ZM4.60714 6.54545C4.60714 5.76967 4.88544 5.05675 5.35073 4.49501L6.6885 5.789C6.55181 6.01024 6.47327 6.26898 6.47327 6.54545L4.60714 6.54545ZM11.3929 6.54545C11.3929 5.76969 11.1146 5.0568 10.6493 4.49507L9.31161 5.789C9.4483 6.01024 9.52684 6.26898 9.52684 6.54545L11.3929 6.54545ZM8.98037 5.41322L10.3022 4.1347C9.75558 3.64611 9.0425 3.32926 8.25452 3.27274V5.08905C8.52797 5.13343 8.77676 5.24811 8.98037 5.41322ZM5.55392 11.3317C5.47441 11.5607 5.25989 11.7248 5.00772 11.7248C4.68787 11.7248 4.42857 11.4608 4.42857 11.1351C4.42857 10.8095 4.68787 10.5455 5.00772 10.5455C5.25989 10.5455 5.47441 10.7095 5.55392 10.9386H6.35906C6.46568 10.9386 6.55211 11.0266 6.55211 11.1351C6.55211 11.2437 6.46568 11.3317 6.35906 11.3317H5.55392Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Units;