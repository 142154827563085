import React from 'react';

function Dot(props) {
    const stroke = props.stroke
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="10" 
            height="10" 
            viewBox="0 0 10 10" 
            fill="none"
            className={className}
        >
            <circle 
                cx="5" 
                cy="5" 
                r="4" 
                fill={fill}
            />
            <circle 
                cx="5" 
                cy="5" 
                r="4.5" 
                stroke={stroke}
                strokeOpacity="0.4"
            />
        </svg>
    )
};

export default Dot;