import React from 'react';
import '../styles/StreetView.css';
import { recordEvent } from '../functions';
import { Button } from '../v4/components';
import { Location, RedirectArrow } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';

function StreetView(props) {
    const property = props.property;
    const googleLink = `https://www.google.com/maps/place/${property.address.streetAddress.replace(" ", "+")},+${property.address.city},+${property.address.state}+${property.address.zipcode}`;
    
    const openGoogle = () => {
        recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
    };

    return (
        <Button
            buttonType="light-button"
            size="x-small"
            text={"Street view"}
            cta={openGoogle}
            disabled={false}
            leftIcon={<Location className="street-view-location-pin" fill={colour.blueBlue03} />}
            leftIconClass=""
            rightIcon={<RedirectArrow clickFunc={null} className="street-view-redirect-arrow" fill={colour.blueBlue03} />}
            rightIconClass=""
            alt=""
            standardIcon={false}
            badge={false}
            badgeVal={null}
            badgeBG={null}
            buttonRef={null}
        />
    )
}

export default StreetView;