import React from 'react';
import '../styles/DealOptimiserLoading.css';
import { Wand } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { useLottie } from 'lottie-react';
import { LoadingToastSpinner } from '../../assets/animations';

function DealOptimiserLoading() {
    const options = {
        animationData: LoadingToastSpinner,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div className="deal-optimiser-loading-container">
            <div className="deal-optimiser-loading-circle bg-colour-archive-status">
                <Wand
                    fill={colour.purplePurple02}
                    className="deal-optimiser-loading-icon"
                />
            </div>
            <div className="deal-optimiser-text-container">
                <div className="deal-optimiser-loading-animation">
                    {View}
                </div>
                <span className="body-regular colour-primary">
                    Finding optimal terms....
                </span>
            </div>
        </div>
    )
};

export default DealOptimiserLoading;