import React from 'react';
import '../styles/MobileLink.css';
import { Chevron } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function MobileLink(props) {
    const label = props.label;
    const cta = props.cta;
    return (
        <div 
            className="mobile-link-container cursor-pointer"
            onClick={() => cta()}
        >
            <span className="body-regular text-link">
                {label}
            </span>
            <Chevron
                fill={colour.blueBlue03}
                className="mobile-link-chevron"
            />
        </div>
    )
};

export default MobileLink;