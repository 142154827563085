import React from 'react';

function Share(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M15.3845 2.46154C15.3845 3.82101 14.2824 4.92308 12.9229 4.92308C12.2661 4.92308 11.6694 4.66584 11.228 4.24662L5.42462 7.87374C5.49848 8.10779 5.53831 8.35693 5.53831 8.61539C5.53831 8.79228 5.51965 8.96481 5.4842 9.13112L11.0663 11.9222C11.5176 11.4043 12.182 11.0769 12.9229 11.0769C14.2824 11.0769 15.3845 12.179 15.3845 13.5385C15.3845 14.8979 14.2824 16 12.9229 16C11.5635 16 10.4614 14.8979 10.4614 13.5385C10.4614 13.3616 10.48 13.1891 10.5155 13.0228L4.93332 10.2317C4.48206 10.7496 3.81764 11.0769 3.07677 11.0769C1.7173 11.0769 0.615234 9.97486 0.615234 8.61539C0.615234 7.25591 1.7173 6.15385 3.07677 6.15385C3.73362 6.15385 4.33037 6.41112 4.77177 6.8304L10.5751 3.2033C10.5012 2.96923 10.4614 2.72004 10.4614 2.46154C10.4614 1.10207 11.5635 0 12.9229 0C14.2824 0 15.3845 1.10207 15.3845 2.46154Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Share;