import React from 'react';

function LotSize(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M0.0677619 4.69519C-0.018791 4.71978 -0.0237458 4.84058 0.0605031 4.87218L7.86912 7.80039C7.95355 7.83205 8.04659 7.83205 8.13102 7.80039L15.9395 4.87217C16.0237 4.84058 16.0188 4.71977 15.9322 4.69518L8.15289 2.48516C8.05295 2.45677 7.94708 2.45677 7.84714 2.48516L0.0677619 4.69519ZM0.976271 10.2301L1.14457 10.6233C1.22249 10.8503 1.09959 11.1812 0.804519 11.1482C0.509449 11.1153 0.444217 11.0142 0.382589 10.7259L0.147153 9.59695C0.146423 9.47988 0.202825 9.3487 0.489359 9.38069L2.48705 9.55366C2.64082 9.56697 2.75261 9.68074 2.74094 9.76953C2.72927 9.85832 2.60019 9.93086 2.44643 9.91755L1.3691 9.79728L8.00049 12.9413L14.6075 9.80885L13.6336 9.91757C13.4798 9.93088 13.3507 9.85834 13.3391 9.76955C13.3274 9.68076 13.4392 9.56699 13.593 9.55368L15.5906 9.38071C15.8772 9.34873 15.9336 9.4799 15.9329 9.59697L15.6974 10.7259C15.6358 11.0142 15.5706 11.1153 15.2755 11.1483C14.9804 11.1812 14.8575 10.8503 14.9354 10.6233L15.1239 10.183L8.13051 13.5049C8.0491 13.5476 7.95188 13.5476 7.87047 13.5049L0.976271 10.2301Z" 
                fill={fill}
            />
        </svg>
    )
};

export default LotSize;