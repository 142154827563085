import React from 'react';

function User(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M10.7907 8.15764C11.8483 7.32035 12.5268 6.02513 12.5268 4.57143C12.5268 2.0467 10.4801 0 7.95539 0C5.43066 0 3.38396 2.0467 3.38396 4.57143C3.38396 6.05713 4.0927 7.3773 5.19053 8.21228C3.24249 9.3819 2.092 11.7061 2.48741 14.118C2.55317 14.5191 2.61247 14.9046 2.66193 15.2608C2.72706 15.7297 3.15996 16.057 3.62884 15.9918C4.09773 15.9267 4.42504 15.4938 4.35992 15.0249C4.30806 14.6516 4.24652 14.2518 4.17911 13.8407C3.77676 11.3865 5.61612 9.14286 8.03847 9.14286C10.4312 9.14286 12.2372 11.314 11.8015 13.6667L11.557 14.9868C11.4708 15.4523 11.7783 15.8995 12.2437 15.9857C12.7092 16.0719 13.1564 15.7644 13.2426 15.2989L13.4871 13.9788C13.9342 11.5646 12.7511 9.28238 10.7907 8.15764ZM10.8125 4.57143C10.8125 6.14938 9.53335 7.42857 7.95539 7.42857C6.37744 7.42857 5.09825 6.14938 5.09825 4.57143C5.09825 2.99347 6.37744 1.71429 7.95539 1.71429C9.53335 1.71429 10.8125 2.99347 10.8125 4.57143Z" 
                fill={fill}
            />
        </svg>
    )
};

export default User;