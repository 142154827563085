import React from 'react';

function ArrowThick(props) {
    const fill = props.fill;
    const className = props.className;
    
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M8 11L11.4641 6.5H4.5359L8 11Z" 
                fill={fill}
            />
        </svg>
    )
};

export default ArrowThick;