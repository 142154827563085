import React from 'react';

function AirConditioning(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="18" 
            height="16" 
            viewBox="0 0 18 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M14.8651 0.685791H3.13495L0.935547 6.8748H2.62338V12.3135H15.3765V6.8748H17.0645L14.8651 0.685791ZM8.25086 7.06227H4.87504V11.5634H8.25086V7.06227ZM13.8772 7.06227H9.75119V9.68791H13.8772V7.06227ZM4.12386 13.0635H13.6887V13.8137H4.12386V13.0635ZM2.62338 14.564H15.3765V15.3142H2.62338V14.564Z" 
                fill={fill}
            />
        </svg>
    )
}

export default AirConditioning;