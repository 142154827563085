import React, { useEffect, useState } from 'react';
import '../styles/PropertyNotFound.css';
import { Header, Footer } from '../components';
import { checkOnAuthStateChanged, getUserDocuments } from '../functions';
import { Button } from '../v4/components';
import { useNavigate } from 'react-router-dom';
import { House } from "../assets/images";
import { Arrow } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';

function CommercialProperty() {
    const [userData, setUserData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Commercial Property | Coffee Clozers";
        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const userId = user.userId;
				const collections = [
					"Users",
					"Subscriptions"
				];

				const queryUserData = await getUserDocuments(collections, userId);
				if ( queryUserData[0].status === 200 ) {
                    setUserData(queryUserData[0].data);
                }

                if ( queryUserData[1].status === 200 ) {
                    setSubscriptions(queryUserData[1].data);
                }
            }
        };

        fetchUserData();
    }, []);

    const submit = () => {
        navigate("/home");
    };

    return (
        <div className="property-not-found-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
				mobileNav={true}
                desktopControl={true}
            />
            <div className="property-not-found-inner-container">
                <div className="property-not-found-image-container">
                    <img
                        src={House}
                        className="property-not-found-image"
                        alt="House"
                    />
                </div>
                <div className="property-not-found-text-container">
                    <h1 className="heading-small-semibold colour-primary text-align-center">
                        Property not available
                    </h1>
                    <span className="body-regular colour-primary block-text text-align-center">
                        Look at you, you real estate tycoon!
                        <br/>
                        <br/>
                        It seems the property you searched is classified as commercial. 
                        Unfortunately, we don't have data on commercial properties like apartment buildings 
                        (more than 4 units) in our system.
                    </span>
                </div>
                <div className="commercial-property-button-container">
                    <Button
                        buttonType="primary-button"
                        text={"Go back"}
                        cta={() => submit()}
                        disabled={false}
                        leftIcon={<Arrow className="commercial-property-arrow" fill={colour.grayScaleWhite} />}
                        rightIcon={null}
                        alt=""
                        size="large"
                        standardIcon={false}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default CommercialProperty;