import React from 'react';
import '../styles/EconomicsBadge.css';
import { colour, percentage } from '../../styles/GlobalStyles';
import { Arrow } from '../../assets/icons';

function EconomicsBadge(props) {
    const item = props.item;

    const calcPercentage = (item) => {
        if ( item.percentage === false ) {
            const percentageChange = Math.round(((item.rawValue - item.stateValue) / item.stateValue) * 100) / 100;
            return percentage.format(Math.abs(percentageChange));
        }
        else if ( item.positive === true ) {
            const percentageChange = Math.abs((Math.round((item.rawValue - item.stateValue) * 100) / 100));
            return percentage.format(percentageChange);
        }
        else {
            const percentageChange = Math.abs((Math.round((item.stateValue - item.rawValue) * 100) / 100));
            return percentage.format(percentageChange);
        }
    };

    return (
        <div className={"economics-badge-comparison-container " + (item.percentage === true && item.difference < 0.01 ? "bg-colour-medium" : item.positive === true ? "bg-colour-green-6" : "bg-colour-red-3")}>
            <div className="economics-badge-inner-comparison-container">
                {
                    item.percentage === true && item.difference < 0.01 ?
                    null
                    :
                    <div className="economics-vals-comparison-icon-container">
                        <Arrow
                            className={(item.positive === true && item.reverse === false) ? "reverse" : ""}
                            fill={item.positive === false ? colour.redRed02 : colour.greenGreen06}
                        />
                    </div>
                }
                <span className={"label-semibold " + (item.percentage === true && item.difference < 0.01 ? "colour-primary" : item.positive === true ? "colour-green-7" : "colour-error")}>
                    {
                        item.percentage === true && item.difference < 0.01 ?
                        `About the same as state average`
                        :
                        `${calcPercentage(item)} ${item.positive === true && item.reverse === false ? "above" : item.positive === false && item.reverse === false ? "below" : item.positive === true && item.reverse === true ? "below" : "above"} state average`
                    }
                </span>
            </div>
        </div>
    )
};

export default EconomicsBadge;