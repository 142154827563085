import React from 'react';
import '../styles/SaleStatus.css';
import { colour, formatterCompact } from '../../styles/GlobalStyles';
import { ArrowThick } from '../../assets/icons';

function SaleStatus(props) {
    const label = props.label;
    const priceChange = props.priceChange;
    const priceCut = props.priceCut;
    const priceChangeTotalAmount = props.priceChangeTotalAmount;
    const priceChangeDate = props.priceChangeDate;
    const showPriceChange = props.showPriceChange;
    return (
        <div className="sale-status-container">
            <span className="body-semibold colour-white">
                {label}
            </span>
            {
                priceChange === true && showPriceChange === true &&
                <div className="sale-status-right-container">
                    <ArrowThick
                        className={"sale-status-arrow " + (priceCut === false ? "reverse" : "")}
                        fill={colour.grayScaleWhite}
                    />
                    <span className="body-semibold colour-white">
                        {formatterCompact.format(Math.abs(priceChangeTotalAmount)).replace(".00", "").toLocaleLowerCase()}
                    </span>
                    <span className="body-regular colour-beige-01">
                        {priceChangeDate}
                    </span>
                </div>
            }
        </div>
    )
};

export default SaleStatus;