import React from 'react';
import '../styles/DistressFlag.css';

function DistressFlag(props) {
    const label = props.label;
    const emoji = props.emoji;
    return (
        <div className="distress-flag-badge-container">
            <span className="distress-flag-emoji">
                {emoji}
            </span>
            <span className="body-semibold colour-primary">
                {label}
            </span>
        </div>
    )
};

export default DistressFlag;