import React from 'react';
import '../styles/MarketSearch.css';
import { SearchInput, PredictiveSearch } from '../components';

function MarketSearch(props) {
    const city = props.city;
    const changeCity = props.changeCity;
    const finalResults = props.finalResults;
    const activeSearching = props.activeSearching;
    const pickGeography = props.pickGeography;
    const extraPadding = props.extraPadding;
    
    return (
        <div className={"market-search-modal-body " + (extraPadding === true ? "market-search-modal-body-extra-padding" : "")}>
            <h3 className="body-semibold colour-primary desktop-none">
                Pick a market
            </h3>
            <div className="market-search-modal-search-input relative-container">
                <SearchInput
                    value={city}
                    onChange={(text) => changeCity(text.target.value)}
                    placeholder="Search for a city or county"
                    type="text"
                    clearInput={() => changeCity("")}
                    magnifyingGlassFunc={null}
                />
                {
                    city === "" || (finalResults.length === 0 && activeSearching === false) ?
                    null
                    :
                    <PredictiveSearch
                        loading={activeSearching}
                        results={finalResults}
                        selectItem={(item) => pickGeography(item)}
                        nested={true}
                    />
                }
            </div>
        </div>
    )
};

export default MarketSearch;