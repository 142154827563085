import React from 'react';
import '../styles/CreativeFinanceFilter.css';
import { FilterReset, SelectionChip } from './';

function CreativeFinanceFilter(props) {
    const creativeFinanceType = props.creativeFinanceType;
    const setCreativeFinanceType = props.setCreativeFinanceType;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const creativeStrategy = props.creativeStrategy;

    const options = [
        {
            label: "Any",
            value: ""
        },
        {
            label: "Seller-Finance",
            value: "sf"
        },
        {
            label: "Subject To",
            value: "sub"
        }
    ];

    const checkSelected = (index) => {
        if ( creativeFinanceType === options[index].value ) {
            return true;
        }
        else {
            return false;
        }
    };

    const changeCreative = (index) => {
        setCreativeFinanceType(options[index].value);
    };

    return (
        <div className="creative-finance-filter-container">
            <div className="creative-finance-filter-title-container">
                <h3 className="body-semibold colour-primary">
                    Creative finance preferences
                </h3>
            </div>
            <div className="creative-finance-body-container">
                {
                    options.map((item, index) =>
                        <SelectionChip
                            label={item.label}
                            selected={checkSelected(index)}
                            disabled={!creativeStrategy}
                            func={changeCreative}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                            key={index}
                        />
                    )
                }
            </div>
            {
                creativeStrategy === false ?
                <div className="creative-finance-filter-error-container">
                    <span className="body-regular colour-error">
                        Please select the "Creative" acquisition strategy to use this filter
                    </span>
                </div>
                :
                reset === true ?
                <FilterReset
                    clickFunc={() => resetFilterType("creative")}
                />
                :
                null
            }
        </div>
    )
};

export default CreativeFinanceFilter;