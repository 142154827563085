import React from 'react';
import '../styles/RoIBadge.css';

function RoIBadge(props) {
    const value = props.value;
    const label = props.label;
    const roiBadgeBG = props.roiBadgeBG;

    return (
        <div className={"roi-badge-container " + (roiBadgeBG)}>
            <span className="body-regular colour-primary">
                {label}
            </span>
            <span className="body-semibold colour-primary">
                {value}
            </span>
        </div>
    )
}

export default RoIBadge;