import React, { useState, useEffect } from 'react';
import '../styles/CountyMap.css';
import { GoogleMap, useLoadScript, Polygon } from '@react-google-maps/api';
import mapStyles from '../../styles/mapStyles.json';
import { mapColours } from '../../styles/GlobalStyles';

function CountyMap(props) {
    const [renderOverlay, setRenderOverlay] = useState(false);
    const api_key = JSON.parse(process.env.REACT_APP_GOOGLE).key
    const { isLoaded } = useLoadScript({
		googleMapsApiKey: api_key
	});
    const centre = props.centre;
    const mapRef = props.mapRef;
    const boundaries = props.boundaries;
    const zoom = props.zoom;

    const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		disableDoubleClickZoom: true,
		styles: mapStyles
	};

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}

	}, [renderOverlay]);

    const styles = {
		city: {
			fillColor: mapColours.mapColorsTransparentYellow,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		county: {
			fillColor: mapColours.mapColorsTransparentBlue,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		}
	};

    return (
        isLoaded === true ?
        <GoogleMap
            zoom={zoom}
            center={centre}
            mapContainerClassName="county-map-element"
            options={options}
            onLoad={(map) => {
                mapRef.current = map;
            }}
            ref={mapRef}
        >
            {
                renderOverlay === true ?
                    <Polygon
                        editable={false}
                        paths={boundaries}
                        options={styles.city}
                    />
                :
                null
            }
        </GoogleMap>
        :
        null
    )
};

export default CountyMap;