import React from 'react';
import '../styles/Block.css';

function Block(props) {
    const label = props.label;
    const value = props.value;
    const bg = props.bg;
    return (
        <div className={"block-container " + (bg)}>
            <span className="body-regular colour-secondary">
                {label}
            </span>
            <span className="body-semibold colour-primary">
                {value}
            </span>
        </div>
    )
};

export default Block;