import React from 'react';
import '../styles/DeleteProperties.css';

function DeleteProperty(props) {
    const property = props.property;
    const address = `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}`;

    return (
        <div className="delete-properties-container">
            <span className="body-regular colour-primary">
                <span className="body-semibold">{address}</span> will be removed from your saved properties. 
                It may be difficult to relocate this property once removed.
            </span>
        </div>
    )
};

export default DeleteProperty;