import React, { useEffect, useRef, useState } from 'react';
import '../styles/CompsAnalysis.css';
import mapStyles from '../styles/mapStyles.json';
import { GoogleMap, useLoadScript, Polygon, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { AddComp, ApplyEstimate, CompFilters, CompsFilter, CustomSwiper, MapLegend, Modal } from './';
import { formatterLong, numberFormatter, formatterCompact, WhiteActionButton, neighbourhoodColours, mapColours, colour, percentage, neighbourhoodDataOptions, nonDisclosureStates } from '../styles/GlobalStyles';
import { SQFootage, Bedrooms, Bathrooms, DollarIcon, HourGlass, PriceSqFoot, Construction, RedirectArrow, OffMarketHouse, LotSize } from '../assets';
import moment from 'moment';
import { recordEvent, getCompsImages, cloudFunctionV2, saveCompData, calculateMortgagePayment } from '../functions';
import { useNavigate } from 'react-router-dom';
import { Button, OptionSwitch, PropertyPosition } from '../v4/components';
import { Filters } from '../assets/icons';

function ARVCompsAnalysis(props) {
    const property = props.property;
    const neighbourhoodBounds = props.neighbourhoodBounds;
    const neighbourhoodData = props.neighbourhoodData;
    const hasUndefinedNeighborhoodGrade = neighbourhoodData.some(e => e.neighborhoodGrade === undefined);
    const dataLocation = neighbourhoodDataOptions[1].location;
    const dataSubLocation = neighbourhoodDataOptions[1].subLocation;
    const closeCompsAnalysis = props.closeCompsAnalysis;
    const saveCompsAnalysis = props.saveCompsAnalysis;
    const signUpModal = props.signUpModal;
    const setSignUpModal = props.setSignUpModal;
    const userId = props.userId;
    const savedData = props.savedData;
    const setSavedData = props.setSavedData;
    const editedProperty = props.editedProperty;
    const setEditedProperty = props.setEditedProperty;
    const loanYears = props.loanYears;
    const switchCompsTool = props.switchCompsTool;
    const propertyState = property.address.state;
    const isNonDisclosure = nonDisclosureStates.includes(propertyState);
    const [comps, setComps] = useState(props.comps);
    const [filteredComps, setFilteredComps] = useState(comps);
    const [centre] = useState({ lat: property.latitude, lng: property.longitude });
    const [renderOverlay, setRenderOverlay] = useState(false);
    const [selectedComp, setSelectedComp] = useState(null);
    const [maxDistance, setMaxDistance] = useState(savedData === null ? 2 : savedData.maxDistance);
    const [maxSoldDate, setMaxSoldDate] = useState(savedData === null ? 6 : savedData.maxSoldDate);
    const [addCompModal, setAddCompModal] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [bedrooms, setBedrooms] = useState(savedData === null ? 1 : savedData.bedrooms);
    const [bathrooms, setBathrooms] = useState(savedData === null ? 1 : savedData.bathrooms);
    const [squareFootage, setSquareFootage] = useState(savedData === null ? [0, 999999] : savedData.squareFootage);
    const [priceRange, setPriceRange] = useState(savedData === null ? ["", ""] : savedData.priceRange);
    const [listingStatus, setListingStatus] = useState(savedData === null ? 2 : savedData.listingStatus);
    const [numberOfFilters, setNumberOfFilters] = useState(savedData === null ? 0 : savedData.numberOfFilters);
    const [excludedComps, setExcludedComps] = useState(savedData === null ? [] : savedData.excludedComps);
    const [manualComps, setManualComps] = useState(savedData === null ? [] : savedData.manualComps);
    const [marketStats, setMarketStats] = useState([]);
    const [viewedProperties, setViewedProperties] = useState([]);
    const [hoverMapLegend, setHoverMapLegend] = useState(null);
    const [firstPricingOption, setFirstPricingOption] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const manualCompIndex = selectedComp === null ? -1 : manualComps.findIndex(e => e.zpid === selectedComp);
    const chosenCompIndex = selectedComp === null ? null : manualCompIndex !== -1 ? manualCompIndex : comps.findIndex(e => e.zpid === selectedComp);
    const chosenComp = chosenCompIndex === null || chosenCompIndex === -1 ? null : manualCompIndex !== -1 ? manualComps[manualCompIndex] : comps[chosenCompIndex];
    const selectedCompRef = useRef(null);
	const api_key = JSON.parse(process.env.REACT_APP_GOOGLE).key
    const { isLoaded } = useLoadScript({
		googleMapsApiKey: api_key
	});
    const compOptions = ["Rental comps", "Sales comps"];
    const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
    const rentalComps = multipleUniqueUnits === true && property.propertyTypeDimension === "Multi Family" ?
        property.unitPropertyComps
        :
        property.propertyTypeDimension === "Multi Family" && property.uniqueUnitPropertyDetails !== undefined ?
        property.unitPropertyComps
        :
        property.rentComps !== undefined && property.rentComps !== null ?
        property.rentComps
        :
        [];
    const switchDisabled = rentalComps === undefined || rentalComps === null || rentalComps.length === 0 ? true : false
    const valueTerm = property.fixerUpper === true ? "ARV" : "Value";
    const valueField = property.fixerUpper === true && property.arvComps === true ? property.arvCompsStats.arvPrice : property.propertyEstimateDefault;
    const subjectPropertyFullAddress = `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}`;
    const chosenCompFullAddress = chosenComp === null ? "" : `${chosenComp.address.streetAddress}, ${chosenComp.address.city}, ${chosenComp.address.state} ${chosenComp.address.zipcode}`;
    const longAddresses = subjectPropertyFullAddress.length > 37 || chosenCompFullAddress.length > 37 ? true : false;
    const navigate = useNavigate();
    const [amendedValue, setAmendedValue] = useState(valueField);
    const [applyEstimateModal, setApplyEstimateModal] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const showReset = numberOfFilters === 0 && maxDistance === 2 && maxSoldDate === 6 ? false : true;

    const propertyLabels = [
        {
            icon: Bedrooms,
            label: "Beds"
        },
        {
            icon: Bathrooms,
            label: "Baths"
        },
        {
            icon: Construction,
            label: "Built"
        },
        {
            icon: SQFootage,
            label: "Sqft"
        },
        {
            icon: PriceSqFoot,
            label: "Price/sqft"
        },
        {
            icon: DollarIcon,
            label: "Listing price"
        },
        {
            icon: LotSize,
            label: "Lot size"
        }
    ];

    const propertyValues = [
        {
            value: property.bedrooms !== null ? property.bedrooms : "N/A"
        },
        {
            value: property.bathrooms !== null ? property.bathrooms : "N/A"
        },
        {
            value: property.yearBuilt !== null ? property.yearBuilt : "N/A"
        },
        {
            value: property.livingArea !== null ? numberFormatter.format(property.livingArea) : "N/A"
        },
        {
            value: property.resoFacts.pricePerSqFoot !== null ? formatterLong.format(property.resoFacts.pricePerSqFoot).replace(".00", "") : "N/A"
        },
        {
            value: property.price !== null ? formatterCompact.format(property.price).replace(".00", "") : "N/A"
        },
        {
            value: property.resoFacts.lotSize !== null ? `${numberFormatter.format(property.resoFacts.lotSize)} sqft` : "N/A"
        }
    ];

    const selectedCompLabels = chosenComp === null ? [] : [
        {
            icon: Bedrooms,
            label: "Beds"
        },
        {
            icon: Bathrooms,
            label: "Baths"
        },
        {
            icon: Construction,
            label: "Built"
        },
        {
            icon: SQFootage,
            label: "Sqft"
        },
        {
            icon: PriceSqFoot,
            label: "Price/sqft"
        },
        {
            icon: HourGlass,
            label: chosenComp.listingStatus === "FOR_SALE" ? "Listing date" : "Sold date"
        },
        {
            icon: LotSize,
            label: "Lot size"
        }
    ];

    const selectedCompValues = chosenComp === null ? [] : [
        {
            value: chosenComp.bedrooms !== null ? chosenComp.bedrooms : "N/A",
            difference: chosenComp.bedrooms !== null && property.bedrooms !== null ? chosenComp.bedrooms - property.bedrooms : null
        },
        {
            value: chosenComp.bathrooms !== null ? chosenComp.bathrooms : "N/A",
            difference: chosenComp.bathrooms !== null && property.bathrooms !== null ? chosenComp.bathrooms - property.bathrooms : null
        },
        {
            value: chosenComp.yearBuilt !== null ? chosenComp.yearBuilt : "N/A",
            difference: null
        },
        {
            value: chosenComp.livingArea !== null ? numberFormatter.format(chosenComp.livingArea) : "N/A",
            difference: chosenComp.livingArea !== null && property.livingArea !== null ? chosenComp.livingArea - property.livingArea : null
        },
        {
            value: chosenComp.pricePerSquareFoot !== null ? formatterLong.format(chosenComp.pricePerSquareFoot).replace(".00", "") : "N/A",
            difference: null
        },
        {
            value: chosenComp.activityDate !== null ? moment(chosenComp.activityDate).format("DD MMM YYYY") : "N/A",
            difference: null
        },
        {
            value: chosenComp.lotAreaValue !== null ? `${numberFormatter.format(chosenComp.lotAreaValue)} sqft` : "N/A",
            difference: chosenComp.lotAreaValue !== null && property.resoFacts.lotSize !== null ? chosenComp.lotAreaValue - property.resoFacts.lotSize : null
        }
    ];

    useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

        const getImages = async() => {
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const getGatewayQueryString = await getCompsImages(comps, "sales");
			const resourceId = "compPhotos";
			const getGatewayParams = {
				type: "gateway",
				resourceId: resourceId,
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
            if ( getGateway.status === 200 ) {
                const body = getGateway.body;
                if ( body.message === "Endpoint request timed out" ) {
                    return;    
                }
                const salesCompImages = body.sales;
                const newArray = [];
                for (let index = 0; index < comps.length; index++) {
                    const element = comps[index];
                    const temporaryId = element.fullAddress.replaceAll(" ", "-");
                    const imageIndex = salesCompImages.findIndex(e => e.id === temporaryId);
                    if ( imageIndex !== -1 ) {
                        element.images = salesCompImages[imageIndex].photos;
                        element.imgSrc = salesCompImages[imageIndex].photoSourceUrl;
                        newArray.push(element);
                    }
                    else {
                        element.images = [];
                        element.imgSrc = "";
                        newArray.push(element);
                    }
                };
                setComps(newArray);
                runCompsEffect(newArray);
            }
            else {
                runCompsEffect(comps);
            }
        };

        const calculateAverageEffect = (nums, round) => {
            if (nums.length === 0) return 0;
        
            const sum = nums.reduce((acc, num) => acc + num, 0);
            const average = sum / nums.length;

            if ( round === true ) {
                const res = Math.round(average);
                return res;
            }
            else {
                return average;
            }
        };

        const runCompsEffect = (amendedComps) => {
            const newComps = [];
            for (let index = 0; index < amendedComps.length; index++) {
                const element = amendedComps[index];
                const today = new Date();
                const todaySeconds = moment.unix(today.getTime() / 1000);
                const activityDateTS = moment(element.activityDate).toDate();
                const activityDate = moment(activityDateTS)
                const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');
    
                if ( element.listingStatus === "FOR_SALE" && Number(listingStatus) === 1 ) {
                    continue;
                }
                else if ( (element.listingStatus === "SOLD" || element.listingStatus === "RECENTLY_SOLD") && Number(listingStatus) === 0 ) {
                    continue;
                }
    
                const checkedMinPrice = priceRange[0] === "" ? 0 : Number(priceRange[0]);
                const checkedMaxPrice = priceRange[1] === "" || priceRange[1] === 0 ? 999999999 : Number(priceRange[1]);
    
                if ( 
                        element.distance <= maxDistance && 
                        updateDateMonthDifference <= maxSoldDate &&
                        element.bedrooms >= bedrooms &&
                        element.bathrooms >= bathrooms &&
                        element.livingArea >= squareFootage[0] && element.livingArea <= squareFootage[1] &&
                        (element.price >= checkedMinPrice && element.price <= checkedMaxPrice)
                    ) {
                    newComps.push(element);
                }
            }
            setFilteredComps(newComps);
            saveMarketStats(newComps);
        };

        const saveMarketStats = async(newFilteredComps) => {
            const newTotal = newFilteredComps.length + manualComps.length - excludedComps.length;
            const pricePerSqFootArray = [];
            const bedsArray = [];
            const bathsArray = [];
            for (let index = 0; index < newFilteredComps.length; index++) {
                const element = newFilteredComps[index];
                const excludedIndex = excludedComps.indexOf(element.zpid);
                if ( excludedIndex === -1 ) {
                    pricePerSqFootArray.push(element.pricePerSquareFoot);
                    bedsArray.push(element.bedrooms);
                    bathsArray.push(element.bathrooms);
                }
            };

            for (let index = 0; index < manualComps.length; index++) {
                const element = manualComps[index];
                const excludedIndex = excludedComps.indexOf(element.zpid);
                if ( excludedIndex === -1 ) {
                    pricePerSqFootArray.push(element.pricePerSquareFoot);
                    bedsArray.push(element.bedrooms);
                    bathsArray.push(element.bathrooms);
                }
            };

            const averagePricePerSqFoot = await calculateAverageEffect(pricePerSqFootArray, false);
            const averageBeds = await calculateAverageEffect(bedsArray, true);
            const averageBaths = await calculateAverageEffect(bathsArray, true);
            const roundedPricePerSqFoot = Math.round(averagePricePerSqFoot);

            const newArray = [
                {
                    label: "TOTAL COMPS",
                    value: newTotal
                },
                {
                    label: "AVG PRICE/SQFT",
                    value: formatterLong.format(roundedPricePerSqFoot).replace(".00", "")
                },
                {
                    label: "AVG BEDS",
                    value: averageBeds
                },
                {
                    label: "AVG BATHS",
                    value: averageBaths
                }
            ];
            setMarketStats(newArray);

            if ( numberOfFilters === 0 && maxDistance === 2 && maxSoldDate === 6 && excludedComps.length === 0 ) {
                return;
            }
            else if ( savedData !== null ) {
                const weightedMedian = await recalculateAmendedValueEffect();
                setAmendedValue(weightedMedian);
            }
        };

        const recalculateAmendedValueEffect = () => {
            const finalComps = filteredComps.concat(manualComps);

            const values = [];
            const weights = [];
            for (let index = 0; index < finalComps.length; index++) {
                const element = finalComps[index];
                const excludedcompsIndex = excludedComps.indexOf(element.zpid);
                if ( excludedcompsIndex !== -1 ) {
                    continue;
                }

                const manualCompIndex = manualComps.findIndex(e => e.zpid === element.zpid);
                const price = element.price;
                const weight = manualCompIndex === -1 ? element.similarity_score : 1;
                values.push(price);
                weights.push(weight);
            }
            if ( values.length === 0 ) {
                return 0;
            }
        
            const weightedSum = values.reduce((acc, value, index) => acc + value * weights[index], 0);
            const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
            const weightedAverage = weightedSum / totalWeight;
        
            return weightedAverage;
        };

		if ( renderOverlay === false && firstRender === false ) {
            setFirstRender(true);
            getImages();
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000);
		}

	}, [
        renderOverlay, 
        comps, 
        manualComps, 
        excludedComps,
        bedrooms,
        bathrooms,
        squareFootage,
        priceRange,
        listingStatus,
        maxDistance,
        maxSoldDate,
        filteredComps,
        firstRender,
        savedData,
        numberOfFilters
    ]);

    const checkTimeGap = (item) => {
        const today = new Date();
        const todaySeconds = moment.unix(today.getTime() / 1000);

        const activityDateTS = moment(item.activityDate).toDate();
        const activityDate = moment(activityDateTS)
        const updateDateYearDifference = todaySeconds.diff(activityDate, 'years');
        const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');
        const updateDateDayDifference = todaySeconds.diff(activityDate, 'days');

        const checkedDifference = updateDateYearDifference > 0 ? `${updateDateYearDifference} yr${updateDateYearDifference === 1 ? "" : "s"} ago` : updateDateMonthDifference > 0 ? `${updateDateMonthDifference} mo ago` : `${updateDateDayDifference} day${updateDateDayDifference === 1 ? "" : "s"} ago`;
        return checkedDifference;
    };

    const checkTimeGapLong = (item) => {
        const today = new Date();
        const todaySeconds = moment.unix(today.getTime() / 1000);

        const activityDateTS = moment(item.activityDate).toDate();
        const activityDate = moment(activityDateTS)
        const updateDateYearDifference = todaySeconds.diff(activityDate, 'years');
        const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');
        const updateDateDayDifference = todaySeconds.diff(activityDate, 'days');

        const checkedDifference = updateDateYearDifference > 0 ? `${updateDateYearDifference} year${updateDateYearDifference === 1 ? "" : "s"} ago` : updateDateMonthDifference > 0 ? `${updateDateMonthDifference} month${updateDateMonthDifference === 1 ? "" : "s"} ago` : `${updateDateDayDifference} day${updateDateDayDifference === 1 ? "" : "s"} ago`;
        return checkedDifference;
    };

	const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: true,
		disableDoubleClickZoom: true,
		styles: mapStyles
	};

    const rgbaToHex = (r, g, b, a) => {
        // Convert the RGB to a hexadecimal value
        const rHex = r.toString(16).padStart(2, '0');
        const gHex = g.toString(16).padStart(2, '0');
        const bHex = b.toString(16).padStart(2, '0');
    
        // Convert the alpha value to a hexadecimal value
        const aHex = Math.round(a * 255).toString(16).padStart(2, '0');
    
        // Combine the RGB and alpha values to get the hexadecimal color code
        const hexCode = `#${rHex}${gHex}${bHex}${aHex}`;
    
        return hexCode.toUpperCase(); // Convert to uppercase for consistency
    };

	const generateColor = (darkYellow, lightYellow, percentage) => {
        percentage = Math.max(0, Math.min(1, percentage));
      
        const deltaRed = lightYellow.red - darkYellow.red;
        const deltaGreen = lightYellow.green - darkYellow.green;
        const deltaBlue = lightYellow.blue - darkYellow.blue;
      
        const red = Math.round(darkYellow.red + (deltaRed * percentage));
        const green = Math.round(darkYellow.green + (deltaGreen * percentage));
        const blue = Math.round(darkYellow.blue + (deltaBlue * percentage));
        const getHex = rgbaToHex(red, green, blue, 0.7);
      
        return getHex;
    };

    const checkNeighbourhoodStyles = (index) => {
        if ( hasUndefinedNeighborhoodGrade === true ) {
            const allValues = [];
            for (let i = 0; i < neighbourhoodData.length; i++) {
				if ( neighbourhoodData[i].stats !== undefined ) {
					const element = neighbourhoodData[i].stats[dataLocation];
					if ( element !== null ) {
						const subElement = element[dataSubLocation];
						if ( subElement !== null ) {
							allValues.push(subElement);
						}
					}
				}
            }

			if ( neighbourhoodData[index].stats !== undefined ) {
				const tractElement = neighbourhoodData[index].stats[dataLocation];
				if ( tractElement === null ) {
					return styles.inactive
				}
				else if ( tractElement[dataSubLocation] === null ) {
					return styles.inactive
				}
				else {
					const tractValue = tractElement[dataSubLocation];
					const minVal = Math.min(...allValues);
					const maxVal = Math.max(...allValues);
					const range = maxVal - minVal;
					const distanceFromMin = tractValue - minVal;
					if ( range === 0 ) {
						const newStyle = {
							fillColor: styles.inactive,
							fillOpacity: 1,
							strokeColor: '#FFFFFF',
							strokeWeight: 1,
							zIndex: 1
						}
						
						return newStyle;
					};
					const percentage = (distanceFromMin / range);
					const darkYellow = colour.heatmapHot;
					const lightYellow = colour.heatmapCold;
					const getHex = generateColor(lightYellow, darkYellow, percentage);
				
					const newStyle = {
						fillColor: getHex,
						fillOpacity: 1,
						strokeColor: '#FFFFFF',
						strokeWeight: 1,
						zIndex: 1
					}
					
					return newStyle;
				}
			}
        }
        else if ( hoverMapLegend !== null ) {
            const tractValue = neighbourhoodData[index].neighborhoodGrade;
            const gradeTranslation = [
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                1
            ];

            if ( tractValue === gradeTranslation[hoverMapLegend] ) {
                const newStyle = {
                    fillColor: neighbourhoodColours[hoverMapLegend],
                    fillOpacity: 1,
                    strokeColor: colour.blueBlue02,
                    strokeWeight: 3,
                    zIndex: 2
                }
                
                return newStyle;
            }
            else {
                const newStyle = {
                    fillColor: styles.inactive.fillColor,
                    fillOpacity: 1,
                    strokeColor: '#FFFFFF',
                    strokeWeight: 1,
                    zIndex: 1
                }
                
                return newStyle;
            }
        }
        else {
            const tractValue = neighbourhoodData[index].neighborhoodGrade;
            const getHex = tractValue === 8 ? styles.first.backgroundColor 
            :
            tractValue === 7 ? styles.second.backgroundColor
            :
            tractValue === 6 ? styles.third.backgroundColor
            :
            tractValue === 5 ? styles.fourth.backgroundColor
            :
            tractValue === 4 ? styles.fifth.backgroundColor
            :
            tractValue === 3 ? styles.sixth.backgroundColor
            :
            tractValue === 2 ? styles.seventh.backgroundColor
            :
            styles.eighth.backgroundColor;
            
            const newStyle = {
                fillColor: getHex,
                fillOpacity: 1,
                strokeColor: '#FFFFFF',
                strokeWeight: 1,
                zIndex: 1
            }
            
            return newStyle;
        }
	};
    
    const addComp = () => {
        recordEvent("Add Comp Clicked", {});
        setAddCompModal(true);
    };

    const clickComp = (index) => {
        recordEvent("Comp Clicked", {});
        setSelectedComp(filteredComps[index].zpid);

        const newViewedProperties = [...viewedProperties];
        const propertyIndex = viewedProperties.indexOf(filteredComps[index].zpid);
        if ( propertyIndex === -1 ) {
            newViewedProperties.push(filteredComps[index].zpid);
            setViewedProperties(newViewedProperties);
        }
        resetSwiper();
    };

    const clickManualComp = (index) => {
        recordEvent("Comp Clicked", {});
        setSelectedComp(manualComps[index].zpid);

        const newViewedProperties = [...viewedProperties];
        const propertyIndex = viewedProperties.indexOf(manualComps[index].zpid);
        if ( propertyIndex === -1 ) {
            newViewedProperties.push(manualComps[index].zpid);
            setViewedProperties(newViewedProperties);
        }
        resetSwiper();
    };

    const closeComp = () => {
        recordEvent("Comp Panel Closed", {});
        setSelectedComp(null);
        resetSwiper();
    };

    const resetSwiper = () => {
        if (selectedCompRef.current && selectedCompRef.current.swiper) {
            selectedCompRef.current.swiper.slideTo(0, 0);
        }
    };

    const styles = {
		inactive: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inactiveHover: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    const openFiltersModal = () => {
        recordEvent("Open Comp Filters", {});
        setOpenFilters(true);
    };

    const changeDistance = (event, value) => {
        setMaxDistance(value);
        runComps(value, maxSoldDate, bedrooms, bathrooms, squareFootage, priceRange, listingStatus, numberOfFilters, false);
    };

    const changeSoldDate = (event, value) => {
        setMaxSoldDate(value);
        runComps(maxDistance, value, bedrooms, bathrooms, squareFootage, priceRange, listingStatus, numberOfFilters, false);
    };

    const runComps = (newMaxDistance, newMaxSoldDate, newBeds, newBaths, newSqFootage, newPriceRange, newListingStatus, newTotalFilters, reset) => {
        const newComps = [];
        for (let index = 0; index < comps.length; index++) {
            const element = comps[index];
            const today = new Date();
            const todaySeconds = moment.unix(today.getTime() / 1000);
            const activityDateTS = moment(element.activityDate).toDate();
            const activityDate = moment(activityDateTS)
            const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');

            if ( element.listingStatus === "FOR_SALE" && Number(newListingStatus) === 1 ) {
                continue;
            }
            else if ( (element.listingStatus === "SOLD" || element.listingStatus === "RECENTLY_SOLD") && Number(newListingStatus) === 0 ) {
                continue;
            }

            const checkedMinPrice = newPriceRange[0] === "" ? 0 : Number(newPriceRange[0]);
            const checkedMaxPrice = newPriceRange[1] === "" || newPriceRange[1] === 0 ? 999999999 : Number(newPriceRange[1]);

            if ( 
                    element.distance <= newMaxDistance && 
                    updateDateMonthDifference <= newMaxSoldDate &&
                    element.bedrooms >= newBeds &&
                    element.bathrooms >= newBaths &&
                    element.livingArea >= newSqFootage[0] && element.livingArea <= newSqFootage[1] &&
                    (element.price >= checkedMinPrice && element.price <= checkedMaxPrice)
                ) {
                newComps.push(element);
            }
        }
        setFilteredComps(newComps);
        recalculateMarketStats(newComps, reset);
    };

    const calculateAverage = (nums, round) => {
        if (nums.length === 0) return 0;
        
        const sum = nums.reduce((acc, num) => acc + num, 0);
        const average = sum / nums.length;

        if ( round === true ) {
            const res = Math.round(average);
            return res;
        }
        else {
            return average;
        }
    };

    const recalculateMarketStats = async(newComps, reset) => {
        const relevantExcludedComps = [];
        for (let index = 0; index < newComps.length; index++) {
            const element = newComps[index];
            const excludedIndex = excludedComps.indexOf(element.zpid);
            if ( excludedIndex !== -1 ) {
                relevantExcludedComps.push(element.zpid);
            }
        }

        const newTotal = newComps.length + manualComps.length - relevantExcludedComps.length;
        const pricePerSqFootArray = [];
        const bedsArray = [];
        const bathsArray = [];
        for (let index = 0; index < newComps.length; index++) {
            const element = newComps[index];
            const excludedIndex = excludedComps.indexOf(element.zpid);
            if ( excludedIndex === -1 ) {
                pricePerSqFootArray.push(element.pricePerSquareFoot);
                bedsArray.push(element.bedrooms);
                bathsArray.push(element.bathrooms);
            }
        };

        for (let index = 0; index < manualComps.length; index++) {
            const element = manualComps[index];
            const excludedIndex = excludedComps.indexOf(element.zpid);
            if ( excludedIndex === -1 ) {
                pricePerSqFootArray.push(element.pricePerSquareFoot);
                bedsArray.push(element.bedrooms);
                bathsArray.push(element.bathrooms);
            }
        };

        const averagePricePerSqFoot = await calculateAverage(pricePerSqFootArray, false);
        const averageBeds = await calculateAverage(bedsArray, true);
        const averageBaths = await calculateAverage(bathsArray, true);
        const roundedPricePerSqFoot = Math.round(averagePricePerSqFoot);

        const newArray = [
            {
                label: "TOTAL COMPS",
                value: newTotal
            },
            {
                label: "AVG PRICE/SQFT",
                value: formatterLong.format(roundedPricePerSqFoot).replace(".00", "")
            },
            {
                label: "AVG BEDS",
                value: averageBeds
            },
            {
                label: "AVG BATHS",
                value: averageBaths
            }
        ];
        setMarketStats(newArray);

        if ( reset === false ) {
            const weightedMedian = await recalculateAmendedValue(newComps, excludedComps, manualComps);
            setAmendedValue(weightedMedian);
        }
    };

    const recalculateAmendedValue = (newComps, newExcluded, newManualComps) => {
        const finalComps = newComps.concat(newManualComps);

        const values = [];
        const weights = [];
        for (let index = 0; index < finalComps.length; index++) {
            const element = finalComps[index];
            const excludedcompsIndex = newExcluded.indexOf(element.zpid);
            if ( excludedcompsIndex !== -1 ) {
                continue;
            }

            const manualCompIndex = newManualComps.findIndex(e => e.zpid === element.zpid);
            const price = element.price;
            const weight = manualCompIndex === -1 ? element.similarity_score : 1;
            values.push(price);
            weights.push(weight);
        }
        if ( values.length === 0 ) {
            return 0;
        }
    
        const weightedSum = values.reduce((acc, value, index) => acc + value * weights[index], 0);
        const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
        const weightedAverage = weightedSum / totalWeight;
    
        return weightedAverage;
    };

    const changePricingOption = () => {
        if ( switchDisabled === false ) {
            setFirstPricingOption(!firstPricingOption);

            if ( firstPricingOption === true ) {
                switchCompsTool(1);
            }
            else {
                switchCompsTool(0);
            }
        }
    };

    const excludeComp = async() => {
        const newArray = [...excludedComps];
        newArray.push(chosenComp.zpid);
        setExcludedComps(newArray);
        setSelectedComp(null);

        if ( marketStats.length > 0 ) {
            const marketStatsClone = [...marketStats];
            marketStatsClone[0].value = marketStatsClone[0].value - 1;
            setMarketStats(marketStatsClone);
        }
        
        resetSwiper();
        const weightedMedian = await recalculateAmendedValue(filteredComps, newArray, manualComps);
        setAmendedValue(weightedMedian);
    };

    const includeComp = async() => {
        const newArray = [...excludedComps];
        const index = newArray.indexOf(chosenComp.zpid);
        if ( index !== -1 ) {
            newArray.splice(index, 1);
        }
        setExcludedComps(newArray);
        setSelectedComp(null);

        if ( marketStats.length > 0 ) {
            const marketStatsClone = [...marketStats];
            marketStatsClone[0].value = marketStatsClone[0].value + 1;
            setMarketStats(marketStatsClone);
        }
        
        resetSwiper();
        const weightedMedian = await recalculateAmendedValue(filteredComps, newArray, manualComps);
        setAmendedValue(weightedMedian);
    };
    
    const checkExcluded = (zpid) => {
        const exclusionIndex = excludedComps.indexOf(zpid);
        if ( exclusionIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const checkViewed = (zpid) => {
        const viewIndex = viewedProperties.indexOf(zpid);
        if ( viewIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const finishAddComp = async(newArray) => {
        const weightedMedian = await recalculateAmendedValue(filteredComps, excludedComps, newArray);
        setAmendedValue(weightedMedian);
    };

    const saveFilters = (modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus, newTotalFilters) => {
        runComps(maxDistance, maxSoldDate, modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus, newTotalFilters, false);
    };

    const resetAllFilters = (newMaxDistance, newMaxSoldDate, modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus) => {
        runComps(newMaxDistance, newMaxSoldDate, modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus, 0, true);
        setAmendedValue(valueField);
    };

    const checkGoogleMapLink = (item) => {
        const images = item.images;
        if ( images === undefined || images === null || images.length === 0 ) {
            return false;
        }
        else if ( images[0].includes("maps.googleapis.com") ) {
            return true;
        }
        else {
            return false;
        }
    };

    const saveWhichValue = () => {
        setApplyEstimateModal(true);
    };

    const saveCompChanges = async() => {
        // All filters
        // Manually added comps
        // Excluded comps
        setDisabled(true);

        const compData = {
            maxDistance: maxDistance,
            maxSoldDate: maxSoldDate,
            bedrooms: bedrooms,
            bathrooms: bathrooms,
            squareFootage: squareFootage,
            priceRange: priceRange,
            listingStatus: listingStatus,
            numberOfFilters: numberOfFilters,
            manualComps: manualComps,
            excludedComps: excludedComps

        };
        const zpid = property.zpid;

        if ( userId !== null && userId !== "" ) {
            const finalCompData = {
                salesCompData: compData,
            };
            await saveCompData(finalCompData, userId, zpid);
            setSavedData(compData);
        }
        saveCompsAnalysis();
        setDisabled(false);
    };

    const openGoogle = (item) => {
        recordEvent("Open Google Link", {});
        const googleLink = `https://www.google.com/maps/place/${item.address.streetAddress.replace(" ", "+")},+${item.address.city},+${item.address.state}+${item.address.zipcode}`;
        window.open(googleLink, "_blank");
    };

    const closeApplyEstimateModal = () => {
        setApplyEstimateModal(false);
    };

    const closeSignupModal = () => {
        recordEvent("Close Sign Up Modal", {});
        setSignUpModal(false);
        closeCompsAnalysis();
    };

    const goToSignup = () => {
        recordEvent("Sign Up Clicked", {});
        closeCompsAnalysis();
        navigate("/sign-up");
    };

    const resetFilters = () => {
        setBedrooms(1);
        setBathrooms(1);
        setSquareFootage([0, 999999]);
        setPriceRange(["", ""]);
        setListingStatus(2);
        setNumberOfFilters(0);
        setMaxDistance(2);
        setMaxSoldDate(6);
        resetAllFilters(2, 6, 1, 1, [0, 999999], ["", ""], 2);
    };

    const saveNewValue = async(val, type) => {
        setDisabled(true);
        const removedCommas = val.replace(",", "");
        const newVal = Number(removedCommas);

        if ( type === "offer-price" ) {
            const newMortgage = await calculateMortgagePayment(newVal, editedProperty.downPayment, editedProperty.interestRate, loanYears);
            const newMonthlyCosts = structuredClone(editedProperty.monthlyCosts);

            if ( newMonthlyCosts.length > 0 ) {
                newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
                setEditedProperty(prevState => ({
                    ...prevState,
                    price: newVal,
                    monthlyCosts: newMonthlyCosts
                }));
            }
        }
        else {
            setEditedProperty(prevState => ({
                ...prevState,
                arv: newVal
            }));
        }
        await saveCompChanges();
    };

    return (
        <div className="comps-analysis-outer-container">
            <div className="comps-analysis-inner-container relative-container">
                {
                    signUpModal === true ?
                    <Modal
                        title="Create a FREE account to save changes 😊"
                        description="You'll need to create a free account to save your changes here"
                        closeButtonText="Close"
                        saveButtonText="Sign up"
                        closeModal={() => closeSignupModal()}
                        saveModal={() => goToSignup()}
                    />
                    :
                    null
                }
                {
                    addCompModal === true ?
                    <AddComp
                        setAddCompModal={setAddCompModal}
                        property={property}
                        setSelectedComp={setSelectedComp}
                        manualComps={manualComps}
                        setManualComps={setManualComps}
                        marketStats={marketStats}
                        setMarketStats={setMarketStats}
                        resetSwiper={resetSwiper}
                        finishAddComp={finishAddComp}
                    />
                    :
                    null
                }
                {
                    openFilters === true ?
                    <CompFilters
                        bedrooms={bedrooms}
                        setBedrooms={setBedrooms}
                        bathrooms={bathrooms}
                        setBathrooms={setBathrooms}
                        squareFootage={squareFootage}
                        setSquareFootage={setSquareFootage}
                        priceRange={priceRange}
                        setPriceRange={setPriceRange}
                        listingStatus={listingStatus}
                        setListingStatus={setListingStatus}
                        setOpenFilters={setOpenFilters}
                        saveFilters={saveFilters}
                        setNumberOfFilters={setNumberOfFilters}
                        setMaxDistance={setMaxDistance}
                        setMaxSoldDate={setMaxSoldDate}
                        resetAllFilters={resetAllFilters}
                        isNonDisclosure={isNonDisclosure}
                        rentMode={false}
                    />
                    :
                    null
                }
                {
                    applyEstimateModal === true ?
                    <ApplyEstimate
                        amendedValue={amendedValue}
                        setAmendedValue={setAmendedValue}
                        valueField={valueField}
                        closeModal={closeApplyEstimateModal}
                        saveCompChanges={saveCompChanges}
                        saveNewValue={saveNewValue}
                        rentMode={false}
                    />
                    :
                    null
                }
                {
                    addCompModal === true || openFilters === true || signUpModal === true || applyEstimateModal === true ?
                    <div className="dark-bg">
                    </div>
                    :
                    null
                }
                <div className="comps-analysis-left-container">
                    <div className="comps-analysis-left-inner-container">
                        <span className="body-regular colour-primary block-text margin-x-small">
                            Subject property
                        </span>
                        <div className="comps-analysis-subject-property-images-container margin-medium">
                            {
                                property.images !== undefined && property.images !== null && property.images.length > 0 && checkGoogleMapLink(property) === false ?
                                <CustomSwiper
                                    images={property.images}
                                    title={property.address.streetAddress}
                                    defaultImage={property.imgSrc}
                                    Heart={null}
                                    HeartActive={null}
                                    favourites={[]}
                                    toggleFavourite={null}
                                    favouriteDisable={null}
                                    item={property}
                                    setFavourites={null}
                                    setFavouriteDisable={null}
                                    abstract={false}
                                    viewProperty={null}
                                    cityId={null}
                                    setCardClick={null}
                                    temporaryId={null}
                                    setSignUpModal={null}
                                    propertySearch={false}
                                    partner={false}
                                    arrowClicked={null}
                                    imageLimit={100}
                                    swiperRef={null}
                                />
                                :
                                <div className="off-market-card-image-container">
                                    <img
                                        src={OffMarketHouse}
                                        className="off-market-card-icon"
                                        alt="Off Market"
                                    />
                                    <div 
                                        className="off-market-card-text-row cursor-pointer"
                                        onClick={() => openGoogle(property)}
                                    >
                                        <span className="label-regular-caps colour-secondary">
                                            Go to street view
                                        </span>
                                        <img
                                            src={RedirectArrow}
                                            className="off-market-card-redirect-arrow"
                                            alt="Redirect"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={"comps-analysis-subject-property-address-container margin-x-small " + (longAddresses === true ? "tall-address-container" : "")}>
                            <span className="body-regular colour-primary">
                                {subjectPropertyFullAddress}
                            </span>
                        </div>
                        <div className="comps-analysis-arv-container margin-medium">
                            <div className="comps-analysis-arv-inner-container">
                                <span className="label-regular-caps colour-primary block-text">
                                    Estimated {valueTerm}
                                </span>
                                <span className="heading-large-semibold colour-primary block-text">
                                    {
                                        amendedValue === valueField || Math.round(amendedValue) === 0 ?
                                        formatterLong.format(valueField).replace(".00", "")
                                        :
                                        formatterLong.format(Math.round(amendedValue)).replace(".00", "")
                                    }
                                </span>
                                {
                                    amendedValue !== valueField && Math.round(amendedValue) !== 0 ?
                                    <span className="body-regular colour-quaternary text-italic">
                                        was {formatterLong.format(valueField).replace(".00", "")}
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="comps-analysis-details-grid">
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[0].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[0].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[0].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[0].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[1].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[1].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[1].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[1].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[2].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[2].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[2].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[2].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[3].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[3].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[3].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[3].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[4].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[4].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[4].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[4].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[5].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[5].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[5].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[5].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[6].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[6].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[6].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[6].value}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comps-analysis-map-container">
                    {
                        isLoaded === true ?
                        <GoogleMap
                            zoom={15}
                            center={centre}
                            mapContainerClassName="comps-analysis-map-element"
                            options={options}
                        >
                            {
                                renderOverlay === true && chosenComp !== null ?
                                <div className="comps-analysis-selected-comp-outer-container">
                                    <div className="comps-analysis-selected-comp-inner-container relative-container">
                                        <div 
                                            className="comps-analysis-selected-comp-exit-container"
                                            onClick={() => closeComp()}
                                        >
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                viewBox="0 0 16 16" 
                                                fill="none"
                                            >
                                                <path 
                                                    fillRule="evenodd" 
                                                    clipRule="evenodd" 
                                                    d="M15.0814 1.44782C15.402 1.09182 15.3732 0.543356 15.0172 0.222805C14.6612 -0.0977454 14.1127 -0.0690019 13.7922 0.287006L8.0017 6.718L2.21124 0.287037C1.89069 -0.0689705 1.34223 -0.097714 0.986225 0.222837C0.630217 0.543388 0.601473 1.09185 0.922024 1.44785L6.83449 8.01432L0.947808 14.5521C0.627257 14.9081 0.656001 15.4566 1.01201 15.7772C1.36802 16.0977 1.91648 16.069 2.23703 15.713L8.0017 9.31064L13.7664 15.713C14.087 16.069 14.6354 16.0977 14.9914 15.7772C15.3474 15.4566 15.3762 14.9082 15.0556 14.5522L9.16892 8.01432L15.0814 1.44782Z" 
                                                    fill="#292621"
                                                />
                                            </svg>
                                        </div>
                                        <div className="comps-analysis-selected-comp-title-container margin-x-small">
                                            <div className="comps-analysis-selected-comp-top-row-container subtext-margin-right-large">
                                                <span className="body-regular colour-primary block-text subtext-margin-right">
                                                    Similarity
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {
                                                        chosenComp.similarity_score === 1 ?
                                                        "N/A"
                                                        :
                                                        percentage.format(chosenComp.similarity_score)
                                                    }
                                                </span>
                                            </div>
                                            <div className="comps-analysis-selected-comp-top-row-container">
                                                <span className="body-regular colour-primary block-text subtext-margin-right">
                                                    Distance
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {Number(chosenComp.distance).toFixed(2)}mi
                                                </span>
                                            </div>
                                        </div>
                                        <div className="comps-analysis-selected-comp-image-container margin-medium">
                                            {
                                                chosenComp.images !== undefined && chosenComp.images !== null && chosenComp.images.length > 0 && checkGoogleMapLink(chosenComp) === false ?
                                                <CustomSwiper
                                                    images={chosenComp.images}
                                                    title={chosenComp.address.streetAddress}
                                                    defaultImage={chosenComp.imgSrc}
                                                    Heart={null}
                                                    HeartActive={null}
                                                    favourites={[]}
                                                    toggleFavourite={null}
                                                    favouriteDisable={null}
                                                    item={chosenComp}
                                                    setFavourites={null}
                                                    setFavouriteDisable={null}
                                                    abstract={false}
                                                    viewProperty={null}
                                                    cityId={null}
                                                    setCardClick={null}
                                                    temporaryId={null}
                                                    setSignUpModal={null}
                                                    propertySearch={false}
                                                    partner={false}
                                                    arrowClicked={null}
                                                    imageLimit={100}
                                                    swiperRef={selectedCompRef}
                                                />
                                                :
                                                <div className="off-market-card-image-container">
                                                    <img
                                                        src={OffMarketHouse}
                                                        className="off-market-card-icon"
                                                        alt="Off Market"
                                                    />
                                                    <div 
                                                        className="off-market-card-text-row cursor-pointer"
                                                        onClick={() => openGoogle(chosenComp)}
                                                    >
                                                        <span className="label-regular-caps colour-secondary">
                                                            Go to street view
                                                        </span>
                                                        <img
                                                            src={RedirectArrow}
                                                            className="off-market-card-redirect-arrow"
                                                            alt="Redirect"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={"comps-analysis-selected-comp-address-container margin-x-small " + (longAddresses === true ? "tall-address-container" : "")}>
                                            <span className="body-regular colour-primary">
                                                {chosenCompFullAddress}
                                            </span>
                                        </div>
                                        <div className="comp-analysis-price-outer-container bg-colour-white margin-medium">
                                            <div className="comp-analysis-price-inner-container">
                                                <span className="label-regular-caps colour-primary block-text">
                                                    {chosenComp.listingStatus === "FOR_SALE" ? "LISTING PRICE" : "SOLD PRICE"}
                                                </span>
                                                <span className="heading-large-semibold colour-primary block-text">
                                                    {formatterLong.format(chosenComp.price).replace(".00", "")}
                                                </span>
                                                <span className="body-regular text-link">
                                                    {checkTimeGapLong(chosenComp)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="comps-analysis-details-grid margin-large">
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[0].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[0].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[0].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element relative-container"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[0].value}
                                                </span>
                                                {
                                                    selectedCompValues[0].difference !== null && selectedCompValues[0].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[0].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[0].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[0].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[1].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[1].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[1].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element relative-container"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[1].value}
                                                </span>
                                                {
                                                    selectedCompValues[1].difference !== null && selectedCompValues[1].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[1].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[1].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[1].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[2].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[2].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[2].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element relative-container"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[2].value}
                                                </span>
                                                {
                                                    selectedCompValues[2].difference !== null && selectedCompValues[2].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[2].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[2].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[2].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[3].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[3].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[3].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[3].value}
                                                </span>
                                                {
                                                    selectedCompValues[3].difference !== null && selectedCompValues[3].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[3].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[3].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[3].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[4].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[4].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[4].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[4].value}
                                                </span>
                                            </div>

                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[5].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[5].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[5].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[5].value}
                                                </span>
                                            </div>

                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[6].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[6].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[6].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[6].value}
                                                </span>
                                                {
                                                    selectedCompValues[6].difference !== null && selectedCompValues[6].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[6].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[6].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[6].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="comps-analysis-selected-comp-button-container">
                                            {
                                                checkExcluded(chosenComp.zpid) === true ?
                                                <Button
                                                    buttonType="accent-button"
                                                    size="large"
                                                    text={"Include"}
                                                    cta={() => includeComp()}
                                                    disabled={false}
                                                    leftIcon={null}
                                                    leftIconClass=""
                                                    rightIcon={null}
                                                    rightIconClass=""
                                                    alt=""
                                                    standardIcon={false}
                                                    badge={false}
                                                    badgeVal={null}
                                                    badgeBG={null}
                                                    buttonRef={null}
                                                />
                                                :
                                                <Button
                                                    buttonType="destructive-button"
                                                    size="large"
                                                    text={"Exclude"}
                                                    cta={() => excludeComp()}
                                                    disabled={false}
                                                    leftIcon={null}
                                                    leftIconClass=""
                                                    rightIcon={null}
                                                    rightIconClass=""
                                                    alt=""
                                                    standardIcon={false}
                                                    badge={false}
                                                    badgeVal={null}
                                                    badgeBG={null}
                                                    buttonRef={null}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {
							   renderOverlay === true ?
                               neighbourhoodBounds.map((item, index) =>
                                   <Polygon
                                       editable={false}
                                       paths={item}
                                       options={checkNeighbourhoodStyles(index)}
                                       key={index}
                                   />
                               )
                               :
                               null
                            }
                            {
                                renderOverlay === true ?
                                <PropertyPosition
                                    latitude={property.latitude}
                                    longitude={property.longitude}
                                />
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                filteredComps.map((item, index) =>
                                    <OverlayViewF
                                        position={{ lat: item.latitude, lng: item.longitude }}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        key={index}
                                    >
                                        {
                                            checkExcluded(item.zpid) === true ?
                                            <div 
                                                className={"comps-analysis-comp-map-excluded-element " + (selectedComp === item.zpid ? "bg-colour-brown" : "bg-colour-white")}
                                                onClick={() => clickComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.zpid ? "colour-white" : "colour-brown")}>
                                                    {formatterCompact.format(item.price)}, {checkTimeGap(item)}
                                                </span>
                                            </div>
                                            :
                                            <div 
                                                className={"comps-analysis-comp-map-element " + (selectedComp === item.zpid ? "bg-colour-brown" : checkViewed(item.zpid) === true ? "bg-colour-archive-status" : "bg-colour-white")}
                                                onClick={() => clickComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.zpid ? "colour-white" : "colour-brown")}>
                                                    {formatterCompact.format(item.price)}, {checkTimeGap(item)}
                                                </span>
                                            </div>
                                        }
                                    </OverlayViewF>
                                )
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                manualComps.map((item, index) =>
                                    <OverlayViewF
                                        position={{ lat: item.latitude, lng: item.longitude }}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        key={index}
                                    >
                                        {
                                            checkExcluded(item.zpid) === true ?
                                            <div 
                                                className={"comps-analysis-comp-map-excluded-element " + (selectedComp === item.zpid ? "bg-colour-brown" : "bg-colour-white")}
                                                onClick={() => clickManualComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.zpid ? "colour-white" : "colour-brown")}>
                                                    {formatterCompact.format(item.price)}, {checkTimeGap(item)}
                                                </span>
                                            </div>
                                            :
                                            <div 
                                                className={"comps-analysis-comp-map-element " + (selectedComp === item.zpid ? "bg-colour-brown" : checkViewed(item.zpid) === true ? "bg-colour-archive-status" : "bg-colour-white")}
                                                onClick={() => clickManualComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.zpid ? "colour-white" : "colour-brown")}>
                                                    {formatterCompact.format(item.price)}, {checkTimeGap(item)}
                                                </span>
                                            </div>
                                        }
                                    </OverlayViewF>
                                )
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <div className="comps-analysis-first-custom-filter">
                                    <CompsFilter
                                        title="Max active date"
                                        value={`${maxSoldDate} month${maxSoldDate === 1 ? "" : "s"}`}
                                        changeValue={changeSoldDate}
                                        sliderVal={maxSoldDate}
                                        step={1}
                                        min={1}
                                        max={18}
                                    />
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <div className="comps-analysis-second-custom-filter">
                                    <CompsFilter
                                        title="Max distance"
                                        value={`${maxDistance} mile${maxDistance === 1 ? "" : "s"}`}
                                        changeValue={changeDistance}
                                        sliderVal={maxDistance}
                                        step={0.25}
                                        min={0.25}
                                        max={5}
                                    />
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true && showReset === true ?
                                <div 
                                    className="reset-button-outer-container"
                                    onClick={() => resetFilters()}
                                >
                                    <span className="label-semibold-caps text-link">
                                        Reset
                                    </span>
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <div className={"comps-analysis-all-filters-button " + (numberOfFilters > 0 ? "comps-analysis-all-filters-button-long" : "comps-analysis-all-filters-button-short")}>
                                    <Button
                                        buttonType="light-button"
                                        text={`All filters ${numberOfFilters > 0 ? `(${numberOfFilters})` : ""}`}
                                        cta={() => openFiltersModal()}
                                        disabled={false}
                                        leftIcon={<Filters fill={colour.grayScaleBlack} className="comps-filters-icon" />}
                                        rightIcon={null}
                                        alt=""
                                        size="large"
                                        standardIcon={false}
                                        badge={false}
                                        badgeVal={null}
                                        badgeBG={null}
                                        buttonRef={null}
                                    />
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <div className="comps-analysis-add-comp-button">
                                    <WhiteActionButton
                                        onClick={() => addComp()}
                                    >
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="17" 
                                            height="16" 
                                            viewBox="0 0 17 16" 
                                            fill="none"
                                            className="comps-analysis-add-comp-icon"
                                        >
                                            <path 
                                                d="M7.51547 15.881C7.3172 15.881 7.14868 15.8216 7.00989 15.7026C6.89093 15.5638 6.83145 15.3953 6.83145 15.197V9.01115H0.734796C0.536531 9.01115 0.368006 8.95167 0.22922 8.83271C0.110261 8.69393 0.0507812 8.5254 0.0507812 8.32714V7.37546C0.0507812 7.1772 0.110261 7.01859 0.22922 6.89963C0.368006 6.76084 0.536531 6.69145 0.734796 6.69145H6.83145V0.684015C6.83145 0.48575 6.89093 0.327138 7.00989 0.208179C7.14868 0.0693929 7.3172 0 7.51547 0H8.55636C8.75462 0 8.91324 0.0693929 9.03219 0.208179C9.17098 0.327138 9.24037 0.48575 9.24037 0.684015V6.69145H15.3668C15.565 6.69145 15.7236 6.76084 15.8426 6.89963C15.9814 7.01859 16.0508 7.1772 16.0508 7.37546V8.32714C16.0508 8.5254 15.9814 8.69393 15.8426 8.83271C15.7236 8.95167 15.565 9.01115 15.3668 9.01115H9.24037V15.197C9.24037 15.3953 9.17098 15.5638 9.03219 15.7026C8.91324 15.8216 8.75462 15.881 8.55636 15.881H7.51547Z" 
                                                fill="#2F4858"
                                            />
                                        </svg>
                                        Add comp
                                    </WhiteActionButton>
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <MapLegend
                                    hoverMapLegend={hoverMapLegend}
                                    setHoverMapLegend={setHoverMapLegend}
                                    neighbourhoodOption={hasUndefinedNeighborhoodGrade === true ? 1 : null}
                                />
                                :
                                null
                            }
                        </GoogleMap>
                        :
                        null
                    }
                </div>
                <div className="comps-analysis-bottom-bar">
                    <div className="comps-analysis-bottom-inner-bar">
                        <div className="comps-analysis-bottom-left-container">
                            <div 
                                className="text-button-inverse"
                                onClick={() => closeCompsAnalysis()}
                            >
                                <span className="body-regular colour-white">
                                    Cancel
                                </span>
                            </div>
                            <div className="comps-analysis-button-container">
                                <Button
                                    buttonType="light-button"
                                    text={"Save & exit"}
                                    cta={() => amendedValue === valueField || Math.round(amendedValue) === 0 || userId === null || userId === "" ? saveCompChanges() : saveWhichValue()}
                                    disabled={disabled}
                                    leftIcon={null}
                                    rightIcon={null}
                                    alt=""
                                    size="large"
                                    standardIcon={true}
                                    badge={false}
                                    badgeVal={null}
                                    badgeBG={null}
                                    buttonRef={null}
                                />
                            </div>
                        </div>
                        <div className="comps-analysis-bottom-right-container">
                            <div className="comps-analysis-switch-container relative-container">
                                <OptionSwitch
                                    labels={compOptions}
                                    firstOption={firstPricingOption}
                                    disabled={switchDisabled}
                                    optionSwitch={changePricingOption}
                                    switchWidth={190}
                                    leftWidth={0.5}
                                    rightWidth={0.5}
                                />
                            </div>
                            {
                                marketStats.map((item, index) =>
                                    <div 
                                        className="comps-analysis-market-stats-element"
                                        key={index}
                                    >
                                        <span className="label-regular-caps colour-white block-text">
                                            {item.label}
                                        </span>
                                        <span className="heading-large-semibold colour-white block-white">
                                            {item.value}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ARVCompsAnalysis;