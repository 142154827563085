import React, { useEffect, useState } from 'react';
import '../styles/VerifyEmail.css';
import { Header, Footer } from '../components';
import { checkOnAuthStateChanged, cloudFunctionV2, getDocument, recordEvent } from '../functions';
import { useNavigate } from 'react-router-dom';
import { Button } from '../v4/components';

function VerifyEmail() {
    const origin = window.location.origin;
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState("");
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    const subject = "Let's verify your email";

    useEffect(() => {

        const fetchUserData = async() => {
            document.title = "Verify Email | Coffee Clozers";

            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setEmail(user.email);
                if ( user.emailVerified === true ) {
                    navigate("/email-verification-complete");
                }
                else {
                    const colRef = "Users";
                    setUserId(user.userId);
                    const queryData = await getDocument(colRef, user.userId);
                    if ( queryData.status === 200 ) {
                        const firstName = queryData.data.data.firstName;
                        const newEmail = queryData.data.data.email;
                        setName(firstName);
                        recordEvent("Verification Email Sent", {});
                        await sendVerificationEmail(firstName, newEmail, user.userId);
                    }
                }
            }
            else {
                navigate("/login");
            }
        };

        const sendVerificationEmail = async(newName, newEmail, newUserId) => {
            return new Promise(async (resolve, reject) => {
                const gatewayURL = process.env.REACT_APP_EMAIL_VERIFICATION_URL;
                const params = {
                    email: newEmail,
                    name: newName,
                    returnURL: `${origin}/email-verification-complete`,
                    subject: subject,
                    userId: newUserId
                };
                const queryData = await cloudFunctionV2(gatewayURL, params);
                if ( queryData.status === 200 ) {
                    setEmailError("");
                }
                else if ( queryData.status === 400 && queryData.error === "Email has been sent less than 1 minute ago" ) {
                    console.log("Email sent less than 1 minute ago");
                }
                else {
                    console.log("queryData = ", queryData);
                    setEmailError("There was an error sending your verification email");
                }
                resolve(queryData);
            });
        };

        fetchUserData();
    }, [navigate, email, origin]);

    const resendEmail = async() => {
        recordEvent("Verification Email Resent", {});
        const gatewayURL = process.env.REACT_APP_EMAIL_VERIFICATION_URL;
        const params = {
            email: email,
            name: name,
            returnURL: `${origin}/email-verification-complete`,
            subject: subject,
            userId: userId
        };
        const queryData = await cloudFunctionV2(gatewayURL, params);
        if ( queryData.status === 200 ) {
            setEmailError("");
        }
        else {
            setEmailError("There was an error sending your verification email");
        }
    };

    const complete = async() => {
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            await user.user.reload();
            await user.user.reload();
            const newRefreshedUser = user;
            if ( newRefreshedUser.emailVerified === true ) {
                recordEvent("Email Verified", {});
                navigate("/email-verification-complete");
            }
            else {
                setEmailError("Your email has not been verified - you may need to check the spam folder");
            }
        }
        else {
            navigate("/sign-up");
        }
    };

    return (
        <div className="verify-email-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={false}
                desktopControl={false}
            />
            <div className="verify-email-inner-container">
                <div className="verify-email-image-container">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/refi-787d3.appspot.com/o/email-verification.png?alt=media&token=0ab48cc7-599e-41b6-96bd-c0d337da2d1c"
                        className="verify-email-image"
                        alt="Pabs"
                    />
                </div>
                <div className="verify-email-top-container">
                    <span className="heading-small-regular colour-primary block-text text-align-center">
                        Verify your email
                    </span>
                    <h2 className="heading-large-semibold colour-primary text-align-center">
                        We'll wait here....
                    </h2>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        Click the link we just sent to {" "}
                        <span className="body-semibold">
                            {email}
                        </span>
                        {" "}to verify your email address
                    </span>
                </div>
                <div className="verify-email-button-container">
                    <Button
                        buttonType="accent-button"
                        text={"Done"}
                        cta={() => complete()}
                        disabled={false}
                        leftIcon={null}
                        rightIcon={null}
                        alt=""
                        size="large"
                        standardIcon={true}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
                {
                    emailError !== "" &&
                    <span className="body-regular colour-error block-text text-align-center">
                        {emailError}
                    </span>
                }
                <div className="verify-email-resend-container">
                    <span className="body-regular colour-primary block-text text-align-center">
                        Didn't get it?
                    </span>
                    <span 
                        className="body-regular colour-link underline block-text text-align-center"
                        onClick={() => resendEmail()}
                    >
                        Click here to resend
                    </span>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default VerifyEmail;