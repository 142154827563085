import React from 'react';
import '../styles/Banner.css';

function Banner(props) {
    const label = props.label;
    const value = props.value;
    return (
        <div className="banner-outer-container bg-colour-grey">
            <div className="banner-inner-container">
                <span className="body-regular colour-secondary">
                    {label}
                </span>
                <span className="body-semibold colour-primary">
                    {value}
                </span>
            </div>
        </div>
    )
};

export default Banner;