import React from 'react';
import '../styles/NeighbourhoodPanel.css';
import { neighbourhoodColours, neighbourhoodValues, numberFormatter, formatterLong, percentage, colour } from '../../styles/GlobalStyles';
import { EconomicsBadge, SchoolElement } from './';
import { SchoolIcon } from 'hugeicons-react';

function NeighbourhoodPanel(props) {
    const property = props.property;
    const neighbourhoodData = props.neighbourhoodData;
    const neighbourhoodIndex = neighbourhoodData.findIndex(e => e.region.tractId === property.censusTract);
    const chosenTract = neighbourhoodIndex !== -1 ? neighbourhoodData[neighbourhoodIndex] : null;
    // const hasUndefinedNeighborhoodGrade = neighbourhoodData.some(e => e.neighborhoodGrade === undefined);

    const basicInfo = chosenTract === null ? [] : [
        {
            label: "ZIP code",
            value: chosenTract.region.zipCode
        },
        {
            label: "Population",
            value: numberFormatter.format(chosenTract.stats.economics.totalPopulation)
        }
    ];

    const checkNeighbourhoodGrade = (grade) => {
        const text = grade === 8 ? "A Neighborhood" :
        grade === 7 ? "B+ Neighborhood" :
        grade === 6 ? "B Neighborhood" :
        grade === 5 ? "B- Neighborhood" :
        grade === 4 ? "C+ Neighborhood" :
        grade === 3 ? "C Neighborhood" :
        grade === 2 ? "C- Neighborhood" :
         "D Neighborhood";
        return text;
    };

    const openDocumentation = () => {
        window.open("https://coffeeclozers.notion.site/Coffee-Clozers-Maps-1541acce14b642ee8791b94c6c1cfccf", "_blank");
    };

    const economicsVals = chosenTract === null ? [] : [
        {
            label: "Median household income",
            value: formatterLong.format(chosenTract.stats.economics.medianHouseholdIncome).replace(".00", ""),
            rawValue: chosenTract.stats.economics.medianHouseholdIncome,
            stateValue: chosenTract.stats.economics.medianHouseholdIncomeState,
            positive: chosenTract.stats.economics.medianHouseholdIncome >= chosenTract.stats.economics.medianHouseholdIncomeState ? true : false,
            percentage: false,
            reverse: false,
            difference: chosenTract.stats.economics.medianHouseholdIncome >= chosenTract.stats.economics.medianHouseholdIncomeState ? chosenTract.stats.economics.medianHouseholdIncome - chosenTract.stats.economics.medianHouseholdIncomeState : chosenTract.stats.economics.medianHouseholdIncomeState - chosenTract.stats.economics.medianHouseholdIncome,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Bachelors or higher",
            value: percentage.format(chosenTract.stats.economics.ratioEducationGraduateDegree).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioEducationGraduateDegree,
            stateValue: chosenTract.stats.economics.ratioEducationGraduateDegreeState,
            positive: chosenTract.stats.economics.ratioEducationGraduateDegree >= chosenTract.stats.economics.ratioEducationGraduateDegreeState ? true : false,
            percentage: true,
            reverse: false,
            difference: chosenTract.stats.economics.ratioEducationGraduateDegree >= chosenTract.stats.economics.ratioEducationGraduateDegreeState ? chosenTract.stats.economics.ratioEducationGraduateDegree - chosenTract.stats.economics.ratioEducationGraduateDegreeState : chosenTract.stats.economics.ratioEducationGraduateDegreeState - chosenTract.stats.economics.ratioEducationGraduateDegree,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Employed",
            value: percentage.format(chosenTract.stats.economics.ratioEmployed).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioEmployed,
            stateValue: chosenTract.stats.economics.ratioEmployedState,
            positive: chosenTract.stats.economics.ratioEmployed >= chosenTract.stats.economics.ratioEmployedState ? true : false,
            percentage: true,
            reverse: false,
            difference: chosenTract.stats.economics.ratioEmployed >= chosenTract.stats.economics.ratioEmployedState ? chosenTract.stats.economics.ratioEmployed - chosenTract.stats.economics.ratioEmployedState : chosenTract.stats.economics.ratioEmployedState - chosenTract.stats.economics.ratioEmployed,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Vacancy ratio",
            value: percentage.format(chosenTract.stats.economics.ratioVacant).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioVacant,
            stateValue: chosenTract.stats.economics.ratioVacantState,
            positive: chosenTract.stats.economics.ratioVacant <= chosenTract.stats.economics.ratioVacantState ? true : false,
            percentage: true,
            reverse: true,
            difference: chosenTract.stats.economics.ratioVacant <= chosenTract.stats.economics.ratioVacantState ? chosenTract.stats.economics.ratioVacantState - chosenTract.stats.economics.ratioVacant : chosenTract.stats.economics.ratioVacant - chosenTract.stats.economics.ratioVacantState,
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Owner occupied",
            value: percentage.format(chosenTract.stats.economics.ratioOwnerOccupied).replace(".00", ""),
            rawValue: chosenTract.stats.economics.ratioOwnerOccupied,
            stateValue: chosenTract.stats.economics.ratioOwnerOccupiedState,
            positive: chosenTract.stats.economics.ratioOwnerOccupied >= chosenTract.stats.economics.ratioOwnerOccupiedState ? true : false,
            percentage: true,
            reverse: false,
            difference: chosenTract.stats.economics.ratioOwnerOccupied >= chosenTract.stats.economics.ratioOwnerOccupiedState ? chosenTract.stats.economics.ratioOwnerOccupied - chosenTract.stats.economics.ratioOwnerOccupiedState : chosenTract.stats.economics.ratioOwnerOccupiedState - chosenTract.stats.economics.ratioOwnerOccupied,
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const marketVals = chosenTract === null ? [] : chosenTract.stats.market === undefined ? [] : [
        {
            label: "Number of active listings",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.numberOfListings !== null ? numberFormatter.format(chosenTract.stats.market.numberOfListings) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median list price",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.medianListPrice !== null ? formatterLong.format(chosenTract.stats.market.medianListPrice).replace(".00", "") : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median rent",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.medianRent !== null ? formatterLong.format(chosenTract.stats.market.medianRent).replace(".00", "") : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median days on market",
            value: chosenTract.stats.market !== null && chosenTract.stats.market.medianDaysOnMarket !== null ? numberFormatter.format(chosenTract.stats.market.medianDaysOnMarket) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const metricsVals = chosenTract === null ? [] : chosenTract.stats.metrics === undefined ? [] : [
        {
            label: "Median CoCR",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.medianCashOnCash !== null ? percentage.format(chosenTract.stats.metrics.medianCashOnCash) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median price per square foot",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.medianPricePerSqft !== null ? formatterLong.format(chosenTract.stats.metrics.medianPricePerSqft).replace(".00", "") : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Price to rent ratio",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.priceToRentRatio !== null ? numberFormatter.format(chosenTract.stats.metrics.priceToRentRatio) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median ARV spread",
            value: chosenTract.stats.metrics !== null && chosenTract.stats.metrics.medianArvSpread !== null ? percentage.format(chosenTract.stats.metrics.medianArvSpread) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const propertyAttributes = chosenTract === null ? [] : chosenTract.stats.propAttr === undefined ? [] : [
        {
            label: "Median bedrooms",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianBedrooms !== null ? numberFormatter.format(chosenTract.stats.propAttr.medianBedrooms) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median bathrooms",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianBathrooms !== null ? numberFormatter.format(chosenTract.stats.propAttr.medianBathrooms) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median square-footage",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianSqft !== null ? numberFormatter.format(chosenTract.stats.propAttr.medianSqft) : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        },
        {
            label: "Median construction year",
            value: chosenTract.stats.propAttr !== null && chosenTract.stats.propAttr.medianYear !== null ? chosenTract.stats.propAttr.medianYear : "N/A",
            title: "",
            description: "",
            link: "",
            tooltip: false
        }
    ];

    const openSource = (val) => {
        const links = [
            "https://www.census.gov/library/visualizations/2022/comm/median-household-income.html",
            "https://www.census.gov/topics/education/educational-attainment.html",
            "https://www.census.gov/topics/employment/labor-force.html",
            "https://www.census.gov/housing/hvs/index.html",
            "https://www.census.gov/housing/hvs/index.html"
        ];
        window.open(links[val], "_blank");
    };

    const styles = {
        first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    return (
        chosenTract === null ?
        null
        :
        <div className="neighbourhood-panel-outer-container">
            <div className="neighbourhood-panel-inner-container">
                <div className="neighbourhood-panel-title-container">
                    <h2 className="heading-small-regular colour-primary">
                        Neighborhood
                    </h2>
                </div>
                {
                    chosenTract !== null &&
                    <div className="neighbourhood-panel-row">
                        {
                            basicInfo.map((item, index) =>
                                <div 
                                    className="neighbourhood-panel-row-element"
                                    key={index}
                                >
                                    <span className="body-semibold colour-primary">
                                        {item.label}
                                    </span>
                                    <span className="body-regular colour-primary">
                                        {item.value}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                }
                {
                    chosenTract !== null &&
                    <div className="property-spacing-border">
                    </div>
                }
                <div className="neighbourhood-panel-data-container">
                    <h3 className="body-semibold colour-primary">
                        Neighborhood score
                    </h3>
                    {
                        chosenTract.neighborhoodGrade !== undefined ?
                            <div 
                                className="neighbourhood-data-badge"
                                style={
                                    chosenTract.neighborhoodGrade === 8 ? styles.first :
                                    chosenTract.neighborhoodGrade === 7 ? styles.second :
                                    chosenTract.neighborhoodGrade === 6 ? styles.third :
                                    chosenTract.neighborhoodGrade === 5 ? styles.fourth :
                                    chosenTract.neighborhoodGrade === 4 ? styles.fifth :
                                    chosenTract.neighborhoodGrade === 3 ? styles.sixth :
                                    chosenTract.neighborhoodGrade === 2 ? styles.seventh :
                                    styles.eighth
                                }
                            >
                                <span className={"body-regular " + (chosenTract.neighborhoodGrade === 8 ? "colour-primary" : "colour-white")}>
                                    {neighbourhoodValues[chosenTract.neighborhoodGrade - 1].label}
                                </span>
                            </div>
                            :
                            property.neighborhoodGradeCensus !== undefined && property.neighborhoodGradeCensus !== null ?
                            <div 
                                className="neighbourhood-data-badge"
                                style={
                                    property.neighborhoodGradeCensus === 8 ? styles.first :
                                    property.neighborhoodGradeCensus === 7 ? styles.second :
                                    property.neighborhoodGradeCensus === 6 ? styles.third :
                                    property.neighborhoodGradeCensus === 5 ? styles.fourth :
                                    property.neighborhoodGradeCensus === 4 ? styles.fifth :
                                    property.neighborhoodGradeCensus === 3 ? styles.sixth :
                                    property.neighborhoodGradeCensus === 2 ? styles.seventh :
                                    styles.eighth
                                }
                            >
                                <span className={"body-regular " + (chosenTract.neighborhoodGrade === 8 ? "colour-primary" : "colour-white")}>
                                    {checkNeighbourhoodGrade(property.neighborhoodGradeCensus)}
                                </span>
                            </div>
                            :
                            <div 
                                className="neighbourhood-data-badge blurred"
                                style={styles.fourth}
                            >
                                <span className="body-regular colour-white">
                                    B- Neighborhood
                                </span>
                            </div>
                    }
                    <span className="body-regular colour-secondary">
                        The neighborhood score is based on a combination of micro and macro economic factors, 
                        amenities, and real estate market data.{" "}
                        <span 
                            className="underline colour-link"
                            onClick={() => openDocumentation()}
                        >
                            Learn more
                        </span>
                    </span>
                </div>
                <div className="property-spacing-border">
                </div>
                <div className="neighbourhood-panel-demographics-container">
                    <h2 className="heading-small-regular colour-primary">
                        Demographics
                    </h2>
                    {
                        economicsVals.map((item, index) =>
                            <div 
                                className="neighbourhood-panel-demographics-element"
                                key={index}
                            >
                                <h3 className="body-semibold colour-primary">
                                    {item.label}
                                </h3>
                                <span className="body-regular colour-primary">
                                    {item.value}
                                </span>
                                <EconomicsBadge
                                    item={item}
                                    index={index}
                                />
                            </div>
                        )
                    }
                </div>
                <div className="property-spacing-border">
                </div>
                <div className="neighbourhood-panel-demographics-container">
                    <div className="neighbourhood-panel-title-container">
                        <h2 className="heading-small-regular colour-primary">
                            Real estate market
                        </h2>
                        <span className="body-regular colour-secondary">
                            Specific stats on this neighborhood.
                        </span>
                    </div>
                    {
                        marketVals.map((item, index) =>
                            <div 
                                className="neighbourhood-panel-demographics-element"
                                key={index}
                            >
                                <h3 className="body-semibold colour-primary">
                                    {item.label}
                                </h3>
                                <span className="body-regular colour-primary">
                                    {item.value}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="property-spacing-border">
                </div>
                <div className="neighbourhood-panel-demographics-container">
                    <div className="neighbourhood-panel-title-container">
                        <h2 className="heading-small-regular colour-primary">
                            Property performance
                        </h2>
                        <span className="body-regular colour-secondary">
                            Property performance metrics in this nieghborhood.
                        </span>
                    </div>
                    {
                        metricsVals.map((item, index) =>
                            <div 
                                className="neighbourhood-panel-demographics-element"
                                key={index}
                            >
                                <h3 className="body-semibold colour-primary">
                                    {item.label}
                                </h3>
                                <span className="body-regular colour-primary">
                                    {item.value}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="property-spacing-border">
                </div>
                <div className="neighbourhood-panel-demographics-container">
                    <div className="neighbourhood-panel-title-container">
                        <h2 className="heading-small-regular colour-primary">
                            Property attributes
                        </h2>
                        <span className="body-regular colour-secondary">
                            What the average property looks like in this neighborhood.
                        </span>
                    </div>
                    {
                        propertyAttributes.map((item, index) =>
                            <div 
                                className="neighbourhood-panel-demographics-element"
                                key={index}
                            >
                                <h3 className="body-semibold colour-primary">
                                    {item.label}
                                </h3>
                                <span className="body-regular colour-primary">
                                    {item.value}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="property-spacing-border">
                </div>
                <div className="neighbourhood-panel-schools-container">
                    <h2 className="heading-small-regular colour-primary">
                        Schools
                    </h2>
                    <div className="neighbourhood-panel-schools-district-row">
                        <SchoolIcon
                            color={colour.grayScaleGray03}
                            size={16}
                        />
                        <span className="body-regular colour-primary">
                            {property.address.city} School District
                        </span>
                    </div>
                </div>
                <div className="property-spacing-border">
                </div>
                {
                    property.schools === undefined || property.schools === null || Object.keys(property.schools).length === 0 ?
                    null
                    :
                    property.schools.map((item, index) =>
                        <SchoolElement
                            item={item}
                            key={index}
                        />
                    )
                }
                {
                    property.schools === undefined || property.schools === null || Object.keys(property.schools).length === 0 ?
                    null
                    :
                    <div className="property-spacing-border">
                    </div>
                }
                <div className="neighbourhood-panel-sources-container">
                    <h3 className="body-semibold colour-primary">
                        Economic data sources
                    </h3>
                    <span className="body-regular colour-secondary">
                        U.S. Census Bureau. <span className="text-italic">American Community Survey (ACS).</span> 5 Year Estimates 2022.
                        Links: <span onClick={() => openSource(0)} className="body-regular-underline colour-link">Median household income,</span> <span onClick={() => openSource(1)} className="body-regular-underline colour-link">Education,</span> <span onClick={() => openSource(2)} className="body-regular-underline colour-link">Employment,</span> <span onClick={() => openSource(3)} className="body-regular-underline colour-link">Vacancy ratio,</span> <span onClick={() => openSource(4)} className="body-regular-underline colour-link">Owner occupied</span>
                    </span>
                </div>
                <div className="neighbourhood-panel-sources-container">
                    <h3 className="body-semibold colour-primary">
                        Neighborhood statistics sources
                    </h3>
                    <span className="body-regular colour-secondary">
                        Coffee Clozers Research Team (proprietary).
                    </span>
                </div>
                <span className="body-regular colour-primary">
                    Neighborhood data updates weekly. 
                    Property performance and attribute metrics update daily.
                </span>
            </div>
        </div>
    )
};

export default NeighbourhoodPanel;