import React, { useEffect } from 'react';
import '../styles/DemoConfirmation.css';
import { Header, Footer } from '../components';
import { Button } from '../v4/components';
import { useNavigate, useLocation } from 'react-router-dom';

function DemoConfirmation() {

    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Demo Confirmation | Coffee Clozers";
    }, []);

    const goToSample = () => {
        navigate("/describe-you", {
            state: state
        });
    };

    return (
        <div className="demo-confirmation-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="demo-confirmation-inner-container">
                <div className="demo-confirmation-title-container margin-x-x-large">
                    <h1 className="heading-large-semibold colour-primary margin-x-small text-align-center">
                        Thanks for booking a demo!
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        We look forward to speaking with you! In the meantime, 
                        start exploring creative finance listings now.
                        <br/>
                        Click below to continue to the product.
                    </span>
                </div>
                <div className="demo-confirmation-button-container">
                    <Button
                        buttonType="primary-button"
                        size="large"
                        text={"Start exploring"}
                        cta={goToSample}
                        disabled={false}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={false}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default DemoConfirmation;