import React from 'react';
import '../styles/FilterChip.css';

function FilterChip(props) {
    const label = props.label;
    const selected = props.selected;
    const index = props.index;
    const disabled = props.disabled;
    const textColour = index === selected ? "colour-white" : "colour-primary";
    const leftIcon = props.leftIcon;
    const rightIcon = props.rightIcon;
    const func = props.func;

    return (
        <div 
            className={"filter-chip-outer-container " + (selected === index ? "filter-chip-selected " : "filter-chip-unselected ") + (disabled === true ? "disabled" : "cursor-pointer")}
            onClick={() => disabled === false ? func(index) : null}
        >
            <div className="filter-chip-inner-container">
                {
                    leftIcon !== null &&
                    <img
                        src={leftIcon}
                        alt="filter-chip-left-icon"
                        className="filter-chip-left-icon"
                    />
                }
                <span className={"body-regular block-text text-align-center " + (textColour)}>
                    {label}
                </span>
                {
                    rightIcon !== null &&
                    <img
                        src={rightIcon}
                        alt="filter-chip-right-icon"
                        className="filter-chip-right-icon"
                    />
                }
            </div>
        </div>
    )
};

export default FilterChip;