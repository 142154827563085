import React, { useState } from 'react';
import '../styles/ZipcodeFilter.css';
import { Dropdown, FilterReset } from './';
import { OptionChip } from './';
import { Exit } from '../../assets/icons'
import { colour } from '../../styles/GlobalStyles';

function ZipcodeFilter(props) {
    const dropdownOpen = props.dropdownOpen;
    const setDropdownOpen = props.setDropdownOpen;
    const zipcodes = props.zipcodes;
    const allZipCodes = props.allZipCodes;
    const entireMarket = props.entireMarket;
    const zipcodeError = props.zipcodeError;
    const switchLabel = props.switchLabel;
    const setZipcodes = props.setZipcodes;
    const setZipcodeError = props.setZipcodeError;
    const buyBox = props.buyBox;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const [tooManyZIPCodes, setTooManyZIPCodes] = useState(false);

    const transformedArray = allZipCodes.map(item => ({
        label: item,
        selected: zipcodes.indexOf(item) === -1 ? false : true
    }));

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const selectZipCode = (item) => {
        const label = item.label;
        const clone = [...zipcodes];
        const index = clone.indexOf(label);
        if ( index === -1 ) {
            if ( zipcodes.length < 4 ) {
                // Allow addition
                clone.push(label);
                setZipcodes(clone);
                setTooManyZIPCodes(false);
            }
            else {
                setDropdownOpen(false);
                setTooManyZIPCodes(true);
            }
        }
        else {
            // Remove item
            removeZIPCodeObject(item);
        }

        if ( buyBox === true ) {
            setZipcodeError(false);
        }
    };

    const removeZIPCodeObject = (item) => {
        const label = item.label;
        const clone = [...zipcodes];
        const index = clone.indexOf(label);
        if ( index > -1 ) {
            clone.splice(index, 1);
        }
        setZipcodes(clone);
        setTooManyZIPCodes(false);
    };

    const removeZIPCode = (label) => {
        const clone = [...zipcodes];
        const index = clone.indexOf(label);
        if ( index > -1 ) {
            clone.splice(index, 1);
        }
        setZipcodes(clone);
        setTooManyZIPCodes(false);
    };

    return (
        <div className="zip-code-filter-container relative-container">
            <div className="zip-code-filter-title-container">
                {
                    buyBox === false &&
                    <span className="body-semibold colour-primary">
                        ZIP codes
                    </span>
                }
                <Dropdown
                    open={dropdownOpen}
                    icon={null}
                    label={zipcodes.length === 1 ? zipcodes[0] : zipcodes.length > 1 ? `Selected (${zipcodes.length})` : "Select ZIP Codes"}
                    disabled={false}
                    options={transformedArray}
                    multiselect={true}
                    toggleDropdown={toggleDropdown}
                    cta={selectZipCode}
                    sidealign={"left"}
                    topalign={"bottom"}
                    indexSelect={false}
                    selectedIndex={null}
                />
            </div>
            {
                zipcodes.length > 0 && entireMarket === false &&
                <div className="zip-code-filter-codes-row">
                    {
                        zipcodes.map((item, index) =>
                            <OptionChip
                                label={item}
                                leftIcon={null}
                                rightIcon={<Exit className="zip-code-filter-icon cursor-pointer" fill={colour.grayScaleBlack} clickFunc={() => removeZIPCode(item)} />}
                                key={index}
                            />
                        )
                    }
                </div>
            }
            {
                zipcodeError === true &&
                <span className="body-regular colour-error">
                    Please select your zip codes, or switch to the {switchLabel}
                </span>
            }
            {
                tooManyZIPCodes === true &&
                <span className="body-regular colour-error">
                    You can only select up to 4 ZIP codes
                </span>
            }
            {
                reset === true && buyBox === false &&
                <FilterReset
                    clickFunc={() => resetFilterType("zipcodes")}
                />
            }
        </div>
    )
};

export default ZipcodeFilter;