import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../logo.svg';
import { useDispatch } from 'react-redux';
import { logoClickAction } from '../actions';
import { addRecentMarket, checkPropertySearchCredits, containsNumbers, formatCityNames, getDocument, goToCreativeCheckout, placesAutocompleteSearch, recordEvent } from '../functions/index';
import { FBPixel } from '../integrations';
import { DefaultUserImage, Loading, MobileBottomNav, TrialExpiration } from './';
import { IconButton, Modal, PredictiveSearch, QuickReportPanel, SearchInput } from '../v4/components';
import { isMobile } from 'react-device-detect';
import { counties } from '../counties';
import { HeaderTotalEffect, HeaderPartialEffect, HeaderNoEffect } from '../queries';
import { QuickReportPanelIcon } from '../assets';
import { Upgrade } from '../v4/modalbody';

function Header(props) {
	const location = useLocation();
	const state = props.state === undefined || props.state === null ? location.state : props.state;
	const colQueries = props.queries !== undefined ? props.queries : [true, true];
	const mobileNav = props.mobileNav;
	const desktopControl = props.desktopControl === undefined ? true : props.desktopControl;
	const path = window.location.pathname;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [userData, setUserData] = useState("");
	const [city, setCity] = useState("");
	const [premiumModal, setPremiumModal] = useState(false);
	const [activeSearching, setActiveSearching] = useState(false);
	const [freeTrial, setFreeTrial] = useState(null);
	const [subscriptions, setSubscriptions] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const [userId, setUserId] = useState(null);
	const [quickReportPanelView, setQuickReportPanelView] = useState(false);
	const [remainingSearches, setRemainingSearches] = useState(3);
	const [cityCharacters, setCityCharacters] = useState(null);
	const [cityNames, setCityNames] = useState([]);
	const [finalResults, setFinalResults] = useState([]);
	const hasResultsArray = finalResults.some(
		item => item.results && Array.isArray(item.results)
	);
	const temporaryId = props.temporaryId;
	const devMode = window.location.hostname === "localhost" || window.location.hostname.includes("refi-787d3") ? true : false;
	const todaySeconds = new Date().getTime() / 1000;

	useEffect(() => {

		const checkSearches = async() => {
			const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
			const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
			const isAdmin = adminIds.includes(userId);
			if ( activeSubscription === -1 && isAdmin === false ) {
				const colRef = "Property Searches";
				const queryData = await getDocument(colRef, userId);
				if ( queryData.status === 200 ) {
					const data = queryData.data.data;
					const searches = data.length >= 5 ? 0 : 5 - data.length;
					setRemainingSearches(searches);
				}
				else {
					setRemainingSearches(5);
				}
			}
		};

		if ( userId !== null ) {
			checkSearches();
		}
	}, [userId, subscriptions, todaySeconds]);

	const goHome = () => {
		dispatch(logoClickAction(true));
		recordEvent("Navigation", {
			oldRoute: path,
			newRoute: "/"
		});

		navigate("/home", {
			state: state
		});
	};

	const changeCity = async(val) => {
		setCity(val);
		const checkNumber = await containsNumbers(val);
		if ( val === "" ) {
			setActiveSearching(false);
			setFinalResults([]);
		}
		else if ( val.includes("zillow.com") ) {
			const zillowArray = [{
				label: val,
				type: "zillow"
			}];
			setFinalResults(zillowArray);
		}
		else if ( checkNumber === true ) {
			if ( val.length === 4 || val.length === 8 || val.length === 12 || val.length > 12 ) {
				setActiveSearching(true);
			}
			await queryGooglePlaces(val, "address");
			setCityCharacters(null);
			setCityNames([]);
		}
		else {
			setActiveSearching(false);
			searchCities(val);
		}
	};

	const searchCities = async(val) => {
		if ( val.length >= 3 ) {
			const newVal = val.slice(0, 3).toUpperCase();
			if ( newVal === cityCharacters ) {
				matchUpCities(val, cityNames);
			}
			else {
				setActiveSearching(true);
				setCityCharacters(newVal);
				
				// Query city names
				const colRef = "City Names";
				const queryData = await getDocument(colRef, newVal);
				if ( queryData.status === 200 ) {
					const data = queryData.data.data;
					const filteredRegions = data.filter(e => e.regionId !== null);
					setCityNames(filteredRegions);
					matchUpCities(val, filteredRegions);
				}
				setActiveSearching(false);
			}
		}
		else {
			setCityCharacters(null);
			setCityNames([]);
			searchCounties(val, []);
		}
	};

	const matchUpCities = (val, data) => {
		const citiesClone = [...data];
		const newArray = [];
		const lowerCaseVal = val.toLowerCase();

		for (let index = 0; index < citiesClone.length; index++) {
			const element = citiesClone[index];
			const cityName = element.regionName;

			const mainCityFullName = cityName.replace(".", "").replace(",", "").toLowerCase();
			const mainCityFullNameComma = cityName.replace(".", "").toLowerCase();
			const noFullStopVal = lowerCaseVal.replace(".", "");

			if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) ) {
				element.label = element.regionName;
				element.type = "city";
				newArray.push(element);
			}
		};
		const sortedData = newArray.sort((a, b) => a.regionName.localeCompare(b.regionName));
		searchCounties(val, sortedData);
	};

	const searchCounties = (val, cityData) => {
		const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
				counties[index].label = formatCityNames(element);
				counties[index].type = "county";
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
					counties[index].label = formatCityNames(element);
					counties[index].type = "county";
                    newArray.push(counties[index]);
                }
            }
        };

		const conjoinedArray = [
			{
				label: "CITY",
				results: cityData.slice(0, 2)
			},
			{
				label: "COUNTY",
				results: newArray
			}
		];

		if ( conjoinedArray[0].results.length === 0 && conjoinedArray[1].results.length === 0 ) {
			setFinalResults([]);
		}
		else {
			setFinalResults(conjoinedArray);
		}
	}

	const queryGooglePlaces = async(val, type) => {
		if ( (val.length > 4 && type === "address") || type === "locality" ) {
			const autocompleteParams = {
				input: val,
				language: "en",
				types: type,
				components: "country:us"
			};
			const getAutocomplete = await placesAutocompleteSearch(autocompleteParams);
			if ( getAutocomplete.status === 200 ) {
				const responses = getAutocomplete.data;
				if ( responses.length !== 0 ) {
					if ( type === "address" ) {
						const updatedArray = responses.map(item => ({ ...item, label: item.description, type: "address" }));
						setFinalResults(updatedArray);
						setActiveSearching(false);
					}
				}
				else {
					setFinalResults([]);
				}
			}
			else {
				setFinalResults([]);
				setActiveSearching(false);
			}
		}
	};

	const selectCity = async(item) => {
		const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
		const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
        const isAdmin = adminIds.includes(userId);
		const activeFreeTrial = freeTrial !== null ? freeTrial[0].endDate.seconds > todaySeconds : false;

		if ( activeSubscription === -1 && isAdmin === false && activeFreeTrial === false ) {
			setPremiumModal(true);
		}
		else {
			const type = item.type;
			const msaCode = type === "city" ? item.regionId : `CTY${item.code}`;
			window.open(`/properties-list/${msaCode}`, "_blank");
			recordEvent("Region Search", {
				region: item.label,
				code: msaCode
			});
			if ( userId !== null ) {
				const newItem = {
					msaTitle: item.label,
					msaCode: msaCode
				};
				await addRecentMarket(newItem, userId, null);
			}
		}
		setCity("");
		setCityCharacters(null);
		setCityNames([]);
		setFinalResults([]);
	};

	const pickGeography = async(item) => {
		const type = item.type;
		if ( type === "address" || type === "zillow" ) {
			selectGoogleMapProperty(item);
		}
		else {
			selectCity(item);
		}
	};

	const resetCity = () => {
		setCity("");
		setFinalResults([]);
	};

	const selectGoogleMapProperty = async(item) => {
		setLoading(true);
		const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
		const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
        const isAdmin = adminIds.includes(userId);

		if ( activeSubscription === -1 && isAdmin === false && item.type === "address" ) {

			const queryPropertySearch = await checkPropertySearchCredits(item, null, null);
			if ( queryPropertySearch === "no credits" ) {
				setPremiumModal(true);
			}
		}
		else if ( item.type === "zillow" ) {
			if ( activeSubscription === -1 && isAdmin === false ) {
				setPremiumModal(true);
			}
			else {
				const encodedAddress = encodeURIComponent(item.label);
				window.open(`/quick-report/${encodedAddress}`, "_self");
			}
		}
		else if ( item.zpid !== undefined ) {
			window.open(`/properties/${item.cityId}/${item.zpid}`, "_blank");
		}
		else {
			recordEvent("Property Searched", {});
			const encodedAddress = encodeURIComponent(item.description);
			window.open(`/quick-report/${encodedAddress}`, "_self");
		}
		setLoading(false);
		setCity("");
	};

	const changePath = (newPath) => {
		navigate(newPath, {
			state: state
		});
		recordEvent("Navigation", {
			oldRoute: path,
			newRoute: newPath
		});
	};

	const upgradeSubscription = async() => {
		setDisabled(true);
		recordEvent("Upgrade Now Button", {});
		navigate("/pricing", {
			state: state
		});
		setDisabled(false);
	};

	const toggleQuickReportPanel = () => {
		setQuickReportPanelView(!quickReportPanelView);
	};


	const checkSubscription = () => {
		const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
		const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
        const isAdmin = adminIds.includes(userId);
		if ( activeSubscription === -1 && isAdmin === false ) {
			return false;
		}
		else {
			return true
		}
	};

	const closePremiumModal = () => {
		setPremiumModal(false);
	};

	const viewPricing = () => {
		navigate("/pricing");
	};

	const upgradeNow = () => {
		goToCreativeCheckout(navigate);
	};

	return (
		<header className="header-outer-container">
			{
				loading === true ?
				<Loading />
				:
				null
			}
			{
				premiumModal === true &&
				<Modal
					heading="Plus Feature 👀 "
					subheading={"You've discovered a Plus Plan feature!"}
					body={<Upgrade />}
					type="secondary"
					cancelText="Maybe later"
					cancelButtonCTA={closePremiumModal}
					actionButtonText="Upgrade now"
					actionButtonCTA={upgradeNow}
					actionButtonDisabled={false}
					secondaryButtonText="See pricing plans"
					secondaryButtonCTA={viewPricing}
					exitButtonCTA={closePremiumModal}
					loading={false}
					leftIcon={null}
					rightIcon={null}
					cancelLeftIcon={null}
					cancelRightIcon={null}
					customClass={null}
				/>
			}
			{
				colQueries[0] === true && colQueries[1] === true ?
				<HeaderTotalEffect
					setDisabled={setDisabled}
					setUserData={setUserData}
					setSubscriptions={setSubscriptions}
					setFreeTrial={setFreeTrial}
					state={state}
					path={path}
					setUserId={setUserId}
				/>
				:
				colQueries[0] === false && colQueries[1] === false && props !== null && props.users !== null && props.users !== "" ?
				<HeaderNoEffect
					setDisabled={setDisabled}
					setUserData={setUserData}
					setSubscriptions={setSubscriptions}
					setFreeTrial={setFreeTrial}
					state={state}
					userData={props.users}
					path={path}
					subscriptions={props.subscriptions}
					setUserId={setUserId}
				/>
				:
				colQueries[0] === true && colQueries[1] === false && props !== null ?
				<HeaderPartialEffect
					setDisabled={setDisabled}
					setUserData={setUserData}
					setSubscriptions={setSubscriptions}
					setFreeTrial={setFreeTrial}
					state={state}
					userData={userData === null ? null : userData !== "" ? userData : null}
					path={path}
					subscriptions={props.subscriptions}
					collection={"Users"}
					setUserId={setUserId}	
				/>
				:
				(colQueries[0] === false && colQueries[1] === true) ?
				<HeaderPartialEffect
					setDisabled={setDisabled}
					setUserData={setUserData}
					setSubscriptions={setSubscriptions}
					setFreeTrial={setFreeTrial}
					state={state}
					userData={props.users}
					path={path}
					subscriptions={subscriptions === null ? null : subscriptions.length !== 0 ? subscriptions : null}
					collection={"Subscriptions"}
					setUserId={setUserId}
				/>
				:
				null
			}
			<div className="header-inner-container header-desktop">
				{
					devMode === false ?
					<FBPixel
						email={userData === "" ? "" : userData.email}
						firstName={userData === "" ? "" : userData.firstName}
						lastName={userData === "" ? "" : userData.lastName}
					/>
					:
					null
				}
				<div className="header-logo-row">
					<img
						src={Logo}
						onClick={() => desktopControl === false ? null : goHome()}
						className="header-logo"
						alt="Logo"
					/>
				</div>
				{
					desktopControl === false ?
					null
					:
					<div className="header-central-container">
						<div className="relative-container">
							<IconButton
								size="large"
								icon={QuickReportPanelIcon}
								disabled={false}
								clickFunc={() => toggleQuickReportPanel()}
								standardIcon={true}
								contextTooltip={false}
								contextTooltipBG={null}
								contextTooltipText={null}
								customClass={null}
							/>
							{
								quickReportPanelView === true ?
								<QuickReportPanel
									maxFreeSearches={userId === null ? 3 : 5}
									unlimited={checkSubscription()}
									remainingSearches={remainingSearches}
								/>
								:
								null
							}
						</div>
						<div className="header-central-inner-container">
							<SearchInput
								value={city}
								onChange={(text) => changeCity(text.target.value, false)}
								placeholder="Search an address, city, or county"
								type="text"
								clearInput={() => resetCity()}
								magnifyingGlassFunc={null}
							/>
							{
								city === "" || (finalResults.length === 0 && activeSearching === false) ?
								null
								:
								<PredictiveSearch
									loading={activeSearching}
									results={finalResults}
									selectItem={(item) => pickGeography(item)}
									nested={hasResultsArray}
								/>
							}
						</div>
					</div>
				}
				{
					desktopControl === false ?
					null
					:
					<div className="header-right-side-container">
						<TrialExpiration
							freeTrial={freeTrial}
							upgradeSubscription={upgradeSubscription}
							disabled={disabled}
							temporaryId={temporaryId}
						/>
						<div 
							className="cursor-pointer"
							onClick={() => changePath(userData === "" ? "/login" : "/profile")}
						>
							{
								userData === "" ?
								<span className="body-regular colour-link">
									Login
								</span>
								:
								userData.profileImage === undefined ?
								<DefaultUserImage
									size="small"
								/>
								:
								<img
									src={userData.profileImage.url}
									className="side-navigation-account-pic"
									alt="My account"
								/>
							}
						</div>
					</div>
				}
			</div>
			{
				desktopControl === false ?
				null
				:
				<div className={"header-inner-container header-mobile " + (isMobile === true ? "header-mobile-phone-container" : "")}>
					<div className="header-central-inner-container">
						<SearchInput
							value={city}
							onChange={(text) => changeCity(text.target.value, false)}
							placeholder="Search an address, city, or county"
							type="text"
							clearInput={() => resetCity()}
							magnifyingGlassFunc={null}
						/>
						{
							city === "" || (finalResults.length === 0 && activeSearching === false) ?
							null
							:
							<PredictiveSearch
								loading={activeSearching}
								results={finalResults}
								selectItem={(item) => pickGeography(item)}
								nested={hasResultsArray}
							/>
						}
					</div>
					<div className="relative-container">
						<IconButton
							size="large"
							icon={QuickReportPanelIcon}
							disabled={false}
							clickFunc={() => toggleQuickReportPanel()}
							standardIcon={true}
							contextTooltip={false}
							contextTooltipBG={null}
							contextTooltipText={null}
							customClass={null}
						/>
						{
							quickReportPanelView === true ?
							<QuickReportPanel
								maxFreeSearches={userId === null ? 3 : 5}
								unlimited={checkSubscription()}
								remainingSearches={remainingSearches}
							/>
							:
							null
						}
					</div>
					{
						mobileNav === true ?
						<MobileBottomNav />
						:
						null
					}
				</div>
			}
		</header>
	)
}

export default Header;