import React from 'react';
import '../styles/SingleInput.css';
import { FinancialsInput } from '../components';

function SingleInput(props) {
    const val = props.val;
    const setVal = props.setVal;
    const placeholder = props.placeholder;

    const onChange = (e) => {
        if ( e.target.value.length > 50 ) return
        setVal(e.target.value);
    };

    return (
        <div className="single-input-container">
            <FinancialsInput
                label={null}
                labelType={null}
                info={false}
                value={val}
                onChange={onChange}
                placeholder={placeholder}
                type="text"
                leftUnit={null}
                rightUnit={null}
                unitSwitch={false}
                leftUnitSelected={true}
                unitSwitchFunc={null}
                disabled={false}
                multiline={false}
                error={false}
                onMouseEnter={null}
                onMouseLeave={null}
                reset={false}
                resetFunc={null}
                resetPosition={null}
            />
            <span className="label-regular colour-secondary">
                {val.length}/50 characters
            </span>
        </div>
    )
};

export default SingleInput;