import React, { useState } from 'react';
import '../styles/FinancialsCalculatorFooter.css';
import { formatterLong, percentage, formatterCompact } from '../../styles/GlobalStyles';
import { DefaultTooltip } from '../../components';
import { Button } from './';

function FinancialsCalculatorFooter(props) {
    const selectedTab = props.selectedTab;
    const highLevelProfits = props.highLevelProfits;
    const blurred = props.blurred;
    const cashInDeal = props.cashInDeal;
    const generateOffer = props.generateOffer;
    const creativeAcquisition = props.creativeAcquisition;
    const shareWithAgent = props.shareWithAgent;
    const exitStrategy = props.exitStrategy;
    const offerLoading = props.offerLoading;
    const [showTooltip, setShowTooltip] = useState(false);

    const initialCosts = exitStrategy === "lo" ? highLevelProfits.leaseOptionTotalCosts : highLevelProfits.totalCosts;
    const cashFlow = exitStrategy === "lo" ? highLevelProfits.leaseOptionProfit : exitStrategy === "str" ? highLevelProfits.strProfit : highLevelProfits.monthlyProfit;
    const cashOnCash = exitStrategy === "lo" ? highLevelProfits.leaseOptionCashOnCash : exitStrategy === "str" ? highLevelProfits.strCashOnCash : highLevelProfits.cashOnCash;
    const surplus = exitStrategy === "lo" && initialCosts < 0 ? true : false;
    const roiColour = exitStrategy === "lo" && highLevelProfits.leaseOptionProfit > 0 ?  "colour-green" : exitStrategy === "lo" && highLevelProfits.leaseOptionProfit <= 0 ? "colour-error" : exitStrategy === "brrrr" && highLevelProfits.monthlyProfit > 0 ? "colour-green" : exitStrategy === "brrrr" && highLevelProfits.monthlyProfit <= 0 ? "colour-error" : ((highLevelProfits.cashOnCash >= 0.07 && exitStrategy === "ltr") || (highLevelProfits.strCashOnCash >= 0.07 && exitStrategy === "str")) ? "colour-green" : ((highLevelProfits.cashOnCash >= 0 && exitStrategy === "ltr") || (highLevelProfits.strCashOnCash >= 0 && exitStrategy === "str")) ? "colour-medium" : (highLevelProfits.expensesCovered >= 1 && exitStrategy === "hh") ? "colour-green" : (highLevelProfits.expensesCovered >= 0.7 && exitStrategy === "hh") ? "colour-medium" : "colour-error";

    return (
        <div className="financials-calculator-footer-container">
            {
                selectedTab !== 0 &&
                <div className="financials-calculator-footer-body-container">
                    <div className="financials-calculator-footer-body-left-container">
                        <div className="financials-calculator-coc-element">
                            <h3 className="label-semibold-caps colour-primary">
                                {
                                    surplus ?
                                    "SURPLUS"
                                    :
                                    "UP-FRONT COSTS"
                                }
                            </h3>
                            {
                                exitStrategy === "lo" ?
                                <h2 className={"heading-large-semibold " + (surplus === true ? "colour-green" : "colour-error")}>
                                    {formatterLong.format(Math.abs(initialCosts)).replace(".00", "")}
                                </h2>
                                :
                                <h2 className="heading-large-semibold colour-error">
                                    {
                                        exitStrategy === "brrrr" ?
                                        formatterCompact.format(initialCosts)
                                        :
                                        formatterLong.format(initialCosts).replace(".00", "")
                                    }
                                </h2>
                            }
                        </div>
                        <div className="financials-calculator-coc-element">
                            <h3 className="label-semibold-caps colour-primary">
                                MONTHLY COSTS
                            </h3>
                            <h2 className="heading-large-semibold colour-error">
                                {
                                    exitStrategy === "brrrr" ?
                                    formatterCompact.format(Math.round(highLevelProfits.monthlyCosts)).replace(".00", "")
                                    :
                                    formatterLong.format(Math.round(highLevelProfits.monthlyCosts)).replace(".00", "")
                                }
                            </h2>
                        </div>
                    </div>
                    <div className="financials-calculator-footer-body-right-container">
                        <div className="financials-calculator-coc-element">
                            <h3 className="label-semibold-caps colour-primary">
                                CASH-FLOW
                            </h3>
                            <h2 className={"heading-large-semibold " + (roiColour)}>
                                {
                                    blurred === true ?
                                    "$44"
                                    :
                                    exitStrategy === "lo" ?
                                    formatterLong.format(Math.round(cashFlow)).replace(".00", "")
                                    :
                                    formatterLong.format(Math.round(cashFlow)).replace(".00", "")
                                }
                            </h2>
                        </div>
                        {
                            exitStrategy !== "hh" ?
                            <div className="financials-calculator-coc-element">
                                <h3 className="label-semibold-caps colour-primary">
                                    {
                                        exitStrategy === "brrrr" ?
                                        "COCR AFTER RE-FI"
                                        :
                                        "CASH ON CASH"
                                    }
                                </h3>
                                {
                                    exitStrategy === "brrrr" && cashInDeal <= 0 ?
                                    <div className="infinite-cocr-container relative-container">
                                        <h2 className={"heading-large-semibold " + (roiColour)}>
                                            ∞
                                        </h2>
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            viewBox="0 0 16 16" 
                                            fill="none"
                                            className="infinity-cocr-icon"
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                        >
                                            <path 
                                                d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                fill="#716E69"
                                            />
                                            <path 
                                                d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                fill="#716E69"
                                            />
                                            <path 
                                                fillRule="evenodd" 
                                                clipRule="evenodd" 
                                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                fill="#716E69"
                                            />
                                        </svg>
                                        {
                                            showTooltip === true ?
                                            <DefaultTooltip
                                                title="Infinite CoCR"
                                                description={`The cash on cash return is a measure of how much money you have put INTO the deal vs how much profit you're taking OUT of the deal. Since your ARV refinance is causing you to have no more money in the deal, your cash on cash return is technically infinite because we're dividing by zero. ${`\n\n`}This is like the bit in in Oppenheimer when they thought they might set fire to the universe. Spooky stuff.`}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    surplus === true ?
                                    <h2 className="heading-large-semibold colour-green">
                                        ∞
                                    </h2>
                                    :
                                    <h2 className={"heading-large-semibold " + (roiColour)}>
                                        {
                                            blurred === true ?
                                            "4.85%"
                                            :
                                            percentage.format(cashOnCash)
                                        }
                                    </h2>
                                }
                            </div>
                            :
                            exitStrategy === "hh" ?
                            <div className="financials-calculator-coc-element">
                                <h3 className="label-semibold-caps colour-primary">
                                    EXPENSES COVERED
                                </h3>
                                <h2 className={"heading-large-semibold " + (roiColour)}>
                                    {
                                        blurred === true ?
                                        "4.85%"
                                        :
                                        `${percentage.format(highLevelProfits.expensesCovered)}`
                                    }
                                </h2>
                            </div>
                            :
                            null
                        }
                        {
                            exitStrategy === "brrrr" ?
                            <div className="financials-calculator-coc-element">
                                <h3 className="label-semibold-caps colour-primary">
                                    ARV SPREAD
                                </h3>
                                <h2 className={"heading-large-semibold " + (highLevelProfits.arvSpread > 0 || blurred === true ? "colour-green" : "colour-error")}>
                                    {
                                        blurred === true ?
                                        "4.85%"
                                        :
                                        `${percentage.format(highLevelProfits.arvSpread)}`
                                    }
                                </h2>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            }
            <div className="financials-calculator-footer-button-container">
                <Button
                    buttonType="primary-button"
                    size="large"
                    text={creativeAcquisition === true ? "Generate offer" : "Share with my agent"}
                    cta={creativeAcquisition === true ? generateOffer : shareWithAgent}
                    disabled={offerLoading}
                    leftIcon={null}
                    leftIconClass=""
                    rightIcon={null}
                    rightIconClass=""
                    alt=""
                    standardIcon={true}
                    badge={false}
                    badgeVal={null}
                    badgeBG={null}
                    buttonRef={null}
                />
            </div>
        </div>
    )
};

export default FinancialsCalculatorFooter;