import React from 'react';
import '../styles/DeleteList.css';
import { Checkbox } from '../components';

function DeleteList(props) {
    const title = props.title;
    const selected = props.selected;
    const setSelected = props.setSelected;

    const toggleSelected = () => {
        setSelected(!selected);
    };

    return (
        <div className="delete-list-container">
            <span className="body-regular colour-primary">
                Permanently delete <span className="body-semibold">{title}</span>?
                The properties in this list will not be deleted.
            </span>
            <Checkbox
                label="Delete list and properties"
                selected={selected}
                type="default"
                disabled={false}
                cta={() => toggleSelected()}
            />
        </div>
    );
};

export default DeleteList;