import React, { useEffect, useState } from 'react';
import '../styles/LiveCounties.css';
import { addRecentMarket, getDocument, recordEvent } from '../functions';
import { allStates, colour } from '../styles/GlobalStyles';
import { RedirectArrow } from '../assets/icons';
import { Badge } from '../v4/components';

function LiveCounties(props) {
    const userId = props.userId;
    const userData = props.userData;
    const subscriptions = props.subscriptions;
    const featured = props.featured;
    const todaySeconds = new Date().getTime() / 1000;
    const payingCustomer = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds) === -1 ? false : true;
    const activeFreeTrial = userData === null ? false : userData.freeTrial[0].endDate.seconds > todaySeconds ? true : false;
    const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
    const isAdmin = adminIds.includes(userId);
    const setPremiumModal = props.setPremiumModal;
    const title = props.title;

    const [countyList, setCountyList] = useState([]);

    const featuredMarkets = [
        {
            msaTitle: "Cleveland, OH",
            msaCode: "3924115",
            icon: "🏙️",
            badge: "TOP FOR CASH-FLOW"
        },
        {
            msaTitle: "Greenville, SC",
            msaCode: "4524960",
            icon: "🧵",
            badge: "TOP FOR CREATIVE FINANCE"
        },
        {
            msaTitle: "Tampa, FL",
            msaCode: "1241176",
            icon: "🏴‍☠️",
            badge: "TOP FOR APPRECIATION"
        }
    ];

    useEffect(() => {

        const queryCounties = async() => {
            const colRef = "Active Cities";
            const docRef = "List";

            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const data = queryData.data.data;

                const newArray = [];
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    if ( element.city === "" ) {
                        continue;
                    }
                    else {
                        const state = element.city.includes("D.C.") ? element.city.slice(-4) : element.city.slice(-2);
                        const stateIndex = allStates.findIndex(e => e.abbreviation === state);
                        if ( stateIndex !== -1 ) {
                            const fullState = allStates[stateIndex].name;

                            const savedStateIndex = newArray.findIndex(e => e.abbreviation === state);
                            if ( savedStateIndex === -1 ) {
                                const newObject = {
                                    state: fullState,
                                    abbreviation: state,
                                    counties: [{ msaTitle: element.city, msaCode: element.cityId }]
                                };
                                newArray.push(newObject);
                            }
                            else {
                                const currentCountes = newArray[savedStateIndex].counties;
                                const savedCountyIndex = currentCountes.indexOf(element.city);
                                if ( savedCountyIndex === -1 ) {
                                    const newCounty = { msaTitle: element.city, msaCode: element.cityId };
                                    newArray[savedStateIndex].counties.push(newCounty);
                                }
                            }
                        }
                    }
                };
                newArray.sort((a, b) => a.state.localeCompare(b.state));
                for (let index = 0; index < newArray.length; index++) {
                    newArray[index].counties.sort((a, b) => a.msaTitle.localeCompare(b.msaTitle));
                };
                setCountyList(newArray);
            }
        };

        queryCounties();

    }, []);
    
    const viewMarketSelection = () => {
        window.open("https://www.coffeeclozers.com/intelligent-market-selection", "_blank");
    };

    const viewCounty = async(item) => {
        if ( payingCustomer === false && activeFreeTrial === false && isAdmin === false ) {
            setPremiumModal(true);
        }
        else {
            window.open(`/properties-list/${item.msaCode}`, "_blank");
			recordEvent("Region Search", {
				region: item.msaTitle,
				code: item.msaCode
			});
			if ( userId !== null ) {
				const newItem = {
					msaTitle: item.msaTitle,
					msaCode: item.msaCode
				};
				await addRecentMarket(newItem, userId, null);
			}
        }
    };

    const viewFeaturedMarket = async(item) => {
        if ( payingCustomer === false && activeFreeTrial === false && isAdmin === false ) {
            setPremiumModal(true);
        }
        else {
            window.open(`/properties-list/${item.msaCode}`, "_blank");
			recordEvent("Featured Market Click", {
				region: item.msaTitle,
				code: item.msaCode
			});
			if ( userId !== null ) {
				const newItem = {
					msaTitle: item.msaTitle,
					msaCode: item.msaCode
				};
				await addRecentMarket(newItem, userId, null);
			}
        }
    };

    return (
        <div className="live-counties-container">
            {
                title === true &&
                <div className="live-counties-title-container">
                    <h2 className="heading-small-semibold text-link">
                        Available Counties on Coffee Clozers
                    </h2>
                    <span className="body-regular colour-secondary">
                        We have MLS data on the following counties.
                        Customers on the <span className="body-semibold underline">Plus</span> plan also have nationwide data on For-Sale-By-Owner (FSBO) properties.
                    </span>
                </div>
            }
            {
                featured === true &&
                <div className="live-counties-featured-markets">
                    <h2 className="heading-small-semibold colour-primary">
                        Featured 🔥
                    </h2>
                    <span className="body-regular colour-secondary">
                        Our customers love these markets
                    </span>
                </div>
            }
            {
                featured === true &&
                <div className="live-counties-featured-market-grid">
                    {
                        featuredMarkets.map((item, index) =>
                            <div 
                                className="live-counties-featured-element-container cursor-pointer"
                                onClick={() => viewFeaturedMarket(item)}
                                key={index}
                            >
                                <span className="view-best-market-icon">
                                    {item.icon}
                                </span>
                                <span className="body-regular colour-primary block-text text-align-center">
                                    {item.msaTitle}
                                </span>
                                <Badge
                                    val={item.badge}
                                    bg="bg-colour-sold-status"
                                    freeSize={true}
                                />
                            </div>
                        )
                    }
                </div>
            }
            {
                featured === true &&
                <div className="live-counties-spacing">
                </div>
            }
            <div className="live-counties-body-container">
                {
                    countyList.map((item, index) =>
                        <div
                            className="live-counties-element-container"
                            key={index}
                        >
                            <span className="body-semibold colour-primary block-text margin-x-small">
                                {item.state}
                            </span>
                            <div className="live-counties-msa-body">
                                {
                                    item.counties.map((county) =>
                                        <div 
                                            className="text-button"
                                            key={county.msaCode}
                                            onClick={() => viewCounty(county)}
                                        >
                                            <span className="body-regular text-link">
                                                {county.msaTitle}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="live-counties-spacing">
            </div>
            <div className="live-counties-market-selection-container">
                <h2 className="heading-small-semibold colour-primary">
                    Need help choosing?
                </h2>
                <div className="live-counties-market-selection-row">
                    <span 
                        className="body-regular colour-secondary"
                        onClick={() => viewMarketSelection()}
                    >
                        Check out our <span className="colour-link underline">Intelligent Market Selection tool</span>
                    </span>
                    <RedirectArrow
                        fill={colour.blueBlue03}
                        className="live-counties-redirect-arrow cursor-pointer"
                        clickFunc={() => viewMarketSelection()}
                    />
                </div>
            </div>
        </div>
    )
};

export default LiveCounties;