import React from 'react';
import '../styles/SummarySection.css';

function SummarySection(props) {
    const label = props.label;
    const data = props.data;
    const roiVals = props.roiVals;
    return (
        <div className={"summary-section-container " + (roiVals === true ? "summary-section-roi-container" : "")}>
            <h3 className="label-regular-caps colour-quaternary">
                {label}
            </h3>
            <div className={roiVals === true ? "summary-section-roi-grid" : "summary-section-body-container"}>
                {
                    roiVals === false ?
                    data.map((item, index) =>
                        item.value !== null &&
                        <div
                            className={"summary-section-element-container " + (item.animate === true ? "summary-section-animate-bg" : "summary-section-animate-bg-out")}
                            key={index}
                        >
                            <div className="summary-section-element-left-container">
                                <span className={"colour-primary " + (item.bold === true ? "body-semibold" : "body-regular")}>
                                    {item.label}
                                </span>
                                {
                                    item.subLabel !== null &&
                                    <span className="body-regular colour-secondary">
                                        {item.subLabel}
                                    </span>
                                }
                            </div>
                            <div className="summary-section-element-right-container">
                                <span className={(item.green === true ? "colour-green " : "colour-primary ") + (item.bold === true || item.green === true ? "body-semibold" : "body-regular")}>
                                    {item.value}
                                </span>
                            </div>
                        </div>
                    )
                    :
                    data.map((item, index) =>
                        <div
                            className={"summary-section-roi-element-container " + (item.animate === true ? "summary-section-animate-bg" : "summary-section-animate-bg-out")}
                            key={index}
                        >
                            <div className="summary-section-roi-label-container">
                                <span className="body-semibold colour-primary">
                                    {item.label}
                                </span>
                                {
                                    item.icon !== null &&
                                    item.icon
                                }
                            </div>
                            <span className={"heading-small-semibold " + (item.colour)}>
                                {item.value}
                            </span>
                            <span className="body-regular colour-secondary">
                                {item.subLabel}
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default SummarySection;