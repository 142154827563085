import React, { useEffect, useState } from 'react';
import '../styles/SideNavigation.css';
import { CoffeeIcon } from '../assets';
import { Home, Heart, Community, Sparkle } from '../assets/icons';
import { checkOnAuthStateChanged, goToCreativeCheckout, recordEvent } from '../functions';
import { Modal, VerticalTab } from '../v4/components';
import { PackageOpenIcon, Settings01Icon } from 'hugeicons-react';
import { colour } from '../styles/GlobalStyles';
import { Upgrade } from "../v4/modalbody";

function SideNavigation(props) {
    const pathname = window.location.pathname;
    const navigate = props.navigate;
    const state = props.state;
    const userData = props.userData;
    const subscriptions = props.subscriptions;
    const today = new Date();
    const todaySeconds = today.getTime() / 1000;
    const checkCreativeSub = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds && e.creative === true);
    const creativeCustomer = checkCreativeSub !== -1 ? true : false;
    const [premiumModal, setPremiumModal] = useState(false);

    const [options, setOptions] = useState([
        {
            title: "Home",
            icon: <Home fill={pathname === "/home" || pathname === "/" ? colour.grayScaleBlack : colour.blueBlue03} />,
            link: "/home",
            active: pathname === "/home" || pathname === "/" ? true : false,
            standardIcon: false
        },
        {
            title: "Buy Boxes",
            icon: <PackageOpenIcon color={pathname.includes("buy-box") ? colour.grayScaleBlack : colour.blueBlue03} size={16} />,
            link: "/buy-boxes",
            active: pathname.includes("buy-box") ? true : false,
            standardIcon: false
        },
        {
            title: "Properties",
            icon: <Heart className="" fill={pathname === "/properties" || pathname.includes("list") ? colour.grayScaleBlack : colour.blueBlue03} />,
            link: "/properties",
            active: pathname === "/properties" || pathname.includes("list") ? true : false,
            standardIcon: false
        },
        {
            title: "Settings",
            icon: <Settings01Icon color={pathname === "/settings" ? colour.grayScaleBlack : colour.blueBlue03} size={16} />,
            link: "/settings",
            active: pathname === "/settings" ? true : false,
            standardIcon: false
        },
    ]);

    useEffect(() => {

        const checkUser = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const admins = JSON.parse(process.env.REACT_APP_ADMINIDS);
                if ( admins.indexOf(userId) !== -1 ) {
                    const optionsClone = [...options];
                    const godModeIndex = optionsClone.findIndex(option => option.link === "/god-mode");
                    if ( godModeIndex === -1 ) {
                        optionsClone.push(
                            {
                                title: "God mode",
                                icon: CoffeeIcon,
                                link: "/god-mode",
                                active: pathname === "/god-mode" ? true : false,
                                standardIcon: true
                            }
                        );
                        setOptions(optionsClone);
                    }
                }
            }
        };

        checkUser();
    }, [options, pathname]);

    const selectOption = (item) => {
        navigate(item.link, {
            state: state
        });
    };

    const openExternalLink = (index) => {
        const links = [
            "https://chatgpt.com/g/g-oJ88StbKN-creative-clozers",
            "https://creative-clozers.circle.so/c/welcome-start-here/"
        ];
        window.open(links[index], "_blank");

        const eventName = index === 0 ? "Open Coffee Clozers GPT" : "Open Community";
        recordEvent(eventName, {});
    };

    const closePremiumModal = () => {
        setPremiumModal(false);
    };

    const openPremiumModal = () => {
        setPremiumModal(true);
    };

    const goToPricing = () => {
        recordEvent("Premium Modal - Pricing");
        navigate("/pricing", {
            state: state
        });
    };

    const goToCheckout = () => {
        goToCreativeCheckout(navigate);
    };

    return (
        <div className="side-navigation-outer-container bg-colour-white">
            {
                premiumModal === true &&
                <Modal
                    heading="Plus Feature 👀 "
                    subheading={"You've discovered a Plus Plan feature!"}
                    body={<Upgrade />}
                    type="secondary"
                    cancelText="Maybe later"
                    cancelButtonCTA={closePremiumModal}
                    actionButtonText="Upgrade now"
                    actionButtonCTA={goToCheckout}
                    actionButtonDisabled={false}
                    secondaryButtonText="See pricing plans"
                    secondaryButtonCTA={goToPricing}
                    exitButtonCTA={closePremiumModal}
                    loading={false}
                    leftIcon={null}
                    rightIcon={null}
                    cancelLeftIcon={null}
                    cancelRightIcon={null}
                    customClass={null}
                />
            }
            <div className="side-navigation-inner-container relative-container">
                <ul className="side-navigation-options-container">
                    {
                        options.map((item, index) =>
                            <VerticalTab
                                selected={item.active}
                                label={item.title}
                                icon={item.icon}
                                cta={() => selectOption(item)}
                                external={false}
                                account={false}
                                userData={null}
                                key={index}
                                standardIcon={item.standardIcon}
                                badge={false}
                                reset={false}
                            />
                        )
                    }
                </ul>
                <div className="side-navigation-account-container">
                    <VerticalTab
                        selected={false}
                        label={"Coffee Clozers GPT"}
                        icon={<Sparkle fill={colour.blueBlue03} className="" />}
                        cta={() => creativeCustomer === true ? openExternalLink(0) : openPremiumModal()}
                        external={true}
                        account={false}
                        userData={null}
                        standardIcon={false}
                        badge={false}
                        reset={false}
                    />
                    <VerticalTab
                        selected={false}
                        label={"Community"}
                        icon={<Community fill={colour.blueBlue03} className="" />}
                        cta={() => creativeCustomer === true ? openExternalLink(1) : openPremiumModal()}
                        external={true}
                        account={false}
                        userData={null}
                        standardIcon={false}
                        badge={false}
                        reset={false}
                    />
                    <div className="side-navigation-divider-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="1" viewBox="0 0 200 1" fill="none">
                            <rect width="200" height="1" rx="0.5" fill="#F5F5F5"/>
                        </svg>
                    </div>
                    <VerticalTab
                        selected={pathname === "/profile" ? true : false}
                        label={"Account"}
                        icon={null}
                        cta={() => selectOption({link: "/profile"})}
                        external={false}
                        account={true}
                        userData={userData}
                        standardIcon={true}
                        badge={false}
                        reset={false}
                    />
                </div>
            </div>
        </div>
    )
};

export default SideNavigation;