import React, { useState, useEffect, useRef } from 'react';
import '../styles/OptionSwitch.css';

function OptionSwitch(props) {
    const labels = props.labels;
    const firstOption = props.firstOption;
    const disabled = props.disabled;
    const optionSwitch = props.optionSwitch;
    const leftWidth = props.leftWidth;
    const rightWidth = props.rightWidth;
    const switchWidth = props.switchWidth;
    const containerRef = useRef(null);
    const [leftPosition, setLeftPosition] = useState({ start: 0, end: 0 });

    useEffect(() => {
        const container = containerRef.current;
        const startPosition = 2;
    
        if (container) {
            const newEndPosition = (switchWidth - (switchWidth * rightWidth)) - startPosition;

            setLeftPosition({
                start: startPosition,
                end: newEndPosition
            });
        }
    }, [leftWidth, rightWidth, switchWidth]);
    

    return (
        <div 
            className="option-switch-outer-container"
            ref={containerRef}
            style={{
                '--start': `${leftPosition.start}px`,
                '--end': `${leftPosition.end}px`,
                '--left-width': `${leftWidth * 100}%`,
                '--right-width': `${rightWidth * 100}%`,
                '--switch-width': `${switchWidth}px`
            }}
        >
            <div className="option-switch-inner-container">
                <div 
                    className={"option-switch-thumb-element bg-colour-white cursor-pointer " + (firstOption === true ? "option-switch-move-left" : "option-switch-move-right")}
                    onClick={() => disabled === true ? null : optionSwitch()}
                >
                </div>
                {
                    labels.map((item, index) =>
                        <div
                            className={"option-switch-element position-relative " + (index === 0 ? "option-switch-left-width " : "option-switch-right-width ") + (disabled === true && ((index === 0 && firstOption === false) || (index === 1 && firstOption === true) ) ? "disabled " : "cursor-pointer ")}
                            key={index}
                            onClick={() => disabled === true ? null : optionSwitch()}
                        >
                            <span className={"colour-primary " + ((firstOption === true && index === 0) || (firstOption === false && index === 1) ? "body-semibold" : "body-regular")}>
                                {item}
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default OptionSwitch;