import React from 'react';

function Bedroom(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M1.33333 1.99984C1.33333 1.26346 1.93029 0.666504 2.66667 0.666504H13.3333C14.0697 0.666504 14.6667 1.26346 14.6667 1.99984V6.4242C14.4219 6.28263 14.1526 6.17883 13.8667 6.12079V4.39984C13.8667 3.95801 13.5085 3.59984 13.0667 3.59984H9.06667C8.62484 3.59984 8.26667 3.95801 8.26667 4.39984V6.06745H7.73333V4.39984C7.73333 3.95801 7.37516 3.59984 6.93333 3.59984H2.93333C2.49151 3.59984 2.13333 3.95801 2.13333 4.39984V6.12079C1.84739 6.17883 1.57807 6.28263 1.33333 6.4242V1.99984Z" 
                fill={fill}
            />
            <path 
                d="M2.66667 7.0665C1.19391 7.0665 0 8.26041 0 9.73317V10.7998C0 10.8265 0.00039266 10.8531 0.00117188 10.8797C0.000393168 10.8974 0 10.9152 0 10.9332V14.1332C0 14.7959 0.537258 15.3332 1.2 15.3332C1.86274 15.3332 2.4 14.7959 2.4 14.1332V13.4533C2.48771 13.462 2.57667 13.4665 2.66667 13.4665H13.3333C13.4233 13.4665 13.5123 13.462 13.6 13.4533V14.1332C13.6 14.7959 14.1373 15.3332 14.8 15.3332C15.4627 15.3332 16 14.7959 16 14.1332V10.9332C16 10.9152 15.9996 10.8974 15.9988 10.8797C15.9996 10.8531 16 10.8265 16 10.7998V9.73317C16 8.26041 14.8061 7.0665 13.3333 7.0665H2.66667Z" 
                fill={fill}
            />
        </svg>
    )
}

export default Bedroom;