import React from 'react';
import '../styles/MarketUnavailable.css';
import { Badge } from '../components';

function MarketUnavailable(props) {
    const market = props.market;
    const viewFreeTrialMarkets = props.viewFreeTrialMarkets;

    return (
        <div className="market-unavailable-container">
            <div className="market-unavailable-title-container">
                <span className="label-regular-caps colour-accent block-text text-align-center">
                    How your free trial works
                </span>
            </div>
            <div className="market-unavailable-body-container">
                <div className="market-unavailable-element-container market-unavailable-free-trial-container">
                    <div className="market-unavailable-element-title-container">
                        <h3 className="heading-small-semibold colour-primary">
                            During your free trial...
                        </h3>
                    </div>
                    <div className="market-unavailable-element-body-container">
                        <span className="body-regular colour-primary">
                            Browse any of our <span onClick={() => viewFreeTrialMarkets()} className="colour-link underline">free trial markets.</span>
                        </span>
                        <span className="body-regular colour-primary">
                            Generate 3 offer letters for free.
                        </span>
                        <span className="body-regular colour-primary">
                            Get 5 free searches.
                        </span>
                    </div>
                </div>
                <div className="market-unavailable-element-container market-unavailable-upgrade-container">
                    <div className="market-unavailable-element-title-container">
                        <h3 className="heading-small-semibold colour-primary">
                            After you upgrade...
                        </h3>
                    </div>
                    <div className="market-unavailable-element-body-container">
                        <span className="body-regular colour-primary">
                            Unlock <span className="body-semibold colour-accent">{market}</span>
                        </span>
                        <div className="market-unavailable-element-row">
                            <span className="body-regular colour-primary">
                                Generate unlimited offer letters
                            </span>
                            <Badge
                                val={"PLUS"}
                                bg="bg-colour-sold-status"
                                freeSize={true}
                            />
                        </div>
                        <span className="body-regular colour-primary">
                            Get unlimited searches.
                        </span>
                        <span className="body-regular colour-primary">
                            & more!
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MarketUnavailable;