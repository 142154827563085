import React from 'react';
import '../styles/OwnershipLengthFilter.css';
import { Clock05Icon } from 'hugeicons-react';
import { colour } from '../../styles/GlobalStyles';
import { FilterReset, SelectionChip } from './';

function OwnershipLengthFilter(props) {
    const ownershipLength = props.ownershipLength;
    const setOwnershipLength = props.setOwnershipLength;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;

    const ownershipLengthOptions = [
        {
            label: "Any",
            value: 0,
            selected: ownershipLength === 0 ? true : false
        },
        {
            label: "<5",
            value: 5,
            selected: ownershipLength === 5 ? true : false
        },
        {
            label: "10+",
            value: 10,
            selected: ownershipLength === 10 ? true : false
        },
        {
            label: "15+",
            value: 15,
            selected: ownershipLength === 15 ? true : false
        },
        {
            label: "20+",
            value: 20,
            selected: ownershipLength === 20 ? true : false
        },
        {
            label: "30+",
            value: 30,
            selected: ownershipLength === 30 ? true : false
        }
    ];
    
    const changeOwnershipLength = (item) => {
        setOwnershipLength(item.value);
    };

    return (
        <div className="ownership-length-container">
            <div className="ownership-length-title-container">
                <Clock05Icon
                    size={16}
                    color={colour.grayScaleBlack}
                />
                <h3 className="body-semibold colour-primary">
                    Ownership length (in years)
                </h3>
            </div>
            <div className="ownership-length-body-container">
                {
                    ownershipLengthOptions.map((item, index) =>
                        <SelectionChip
                            key={index}
                            label={item.label}
                            selected={item.selected}
                            disabled={false}
                            func={() => changeOwnershipLength(item)}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                        />
                    )
                }
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("ownershipLength")}
                />
            }
        </div>
    )
};

export default OwnershipLengthFilter;