import React from 'react';

function SectionIncomplete(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M15.801 9.78098L14.3384 9.44844C14.4439 8.98407 14.5 8.4995 14.5 8C14.5 7.5005 14.4439 7.01593 14.3384 6.55156L15.801 6.21902C15.9312 6.79176 16 7.38785 16 8C16 8.61215 15.9312 9.20824 15.801 9.78098ZM14.7747 3.74324L13.5054 4.54248C12.9852 3.71627 12.2837 3.01485 11.4575 2.49462L12.2568 1.22528C13.2729 1.86507 14.1349 2.72714 14.7747 3.74324ZM9.78098 0.198963L9.44844 1.66164C8.98407 1.55607 8.4995 1.5 8 1.5C7.5005 1.5 7.01593 1.55607 6.55156 1.66164L6.21902 0.198964C6.79176 0.0687549 7.38785 0 8 0C8.61215 0 9.20824 0.0687548 9.78098 0.198963ZM3.74324 1.22528L4.54248 2.49462C3.71627 3.01485 3.01485 3.71627 2.49462 4.54249L1.22528 3.74325C1.86507 2.72714 2.72714 1.86507 3.74324 1.22528ZM0.198963 6.21902C0.0687548 6.79176 0 7.38785 0 8C0 8.61215 0.0687549 9.20824 0.198964 9.78098L1.66164 9.44844C1.55607 8.98407 1.5 8.4995 1.5 8C1.5 7.5005 1.55607 7.01593 1.66164 6.55156L0.198963 6.21902ZM1.22528 12.2568L2.49462 11.4575C3.01485 12.2837 3.71627 12.9852 4.54249 13.5054L3.74325 14.7747C2.72714 14.1349 1.86507 13.2729 1.22528 12.2568ZM6.21902 15.801L6.55156 14.3384C7.01593 14.4439 7.5005 14.5 8 14.5C8.4995 14.5 8.98407 14.4439 9.44844 14.3384L9.78098 15.801C9.20824 15.9312 8.61215 16 8 16C7.38785 16 6.79176 15.9312 6.21902 15.801ZM12.2568 14.7747L11.4575 13.5054C12.2837 12.9852 12.9852 12.2837 13.5054 11.4575L14.7747 12.2568C14.1349 13.2729 13.2729 14.1349 12.2568 14.7747Z" 
                fill={fill}
            />
        </svg>
    )
};

export default SectionIncomplete;