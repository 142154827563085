import React from 'react';
import '../styles/PropertySection.css';
import { IconButton, ListCard } from './';
import { formatterLong } from '../../styles/GlobalStyles';
import { NewPropertyCard } from '../../components';
import { ArrowLeftBlue, ArrowRightBlue } from '../../assets';
import { useNavigate } from 'react-router-dom';

function PropertySection(props) {
    const title = props.title;
    const type = props.type;
    const data = props.data;
    const favourites = props.favourites;
    const setFavourites = props.setFavourites;
    const userData = props.userData;
    const selectedProperties = props.selectedProperties;
    const setSelectedProperties = props.setSelectedProperties;
    const subscriptions = props.subscriptions;
    const openNoteModal = props.openNoteModal;
    const lists = props.lists;
    const openUnfavouritePropertiesModal = props.openUnfavouritePropertiesModal;
    const navigate = useNavigate();
    const sectionClass = `property-section-type-${type}`;

    const checkSelected = (id) => {
        const selectedIndex = selectedProperties.indexOf(id);
        if ( selectedIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const toggleSelected = (id) => {
        const selectedIndex = selectedProperties.indexOf(id);
        if ( selectedIndex !== -1 ) {
            setSelectedProperties(selectedProperties.filter(e => e !== id));
        }
        else {
            setSelectedProperties([...selectedProperties, id]);
        }
    };

    const checkMatchingFavourites = (properties) => {
        const matchingProperties = [];
        for (let index = 0; index < properties.length; index++) {
            const element = properties[index];
            const zpid = element.zpid;
            const favouriteIndex = favourites.findIndex(e => e.zpid === zpid);
            if ( favouriteIndex !== -1 ) {
                matchingProperties.push(favourites[favouriteIndex]);
            }
        }
        return matchingProperties;
    };

    const viewList = (list) => {
        navigate(`/list/${list.id}`, {
            state: {
                listVals: true,
                list: list,
                favourites: favourites,
                userData: userData,
                subscriptions: subscriptions,
                lists: lists,
                properties: checkMatchingFavourites(list.properties)
            }
        });
    };

    return (
        <div className={"property-section-container " + (sectionClass)}>
            <div className="property-section-title-container">
                <h2 className="heading-small-regular colour-primary">
                    {title}
                </h2>
                {
                    type === "lists" && data.length > 4 &&
                    <div className="property-section-arrow-container">
                        <IconButton
                            size="medium"
                            icon={ArrowLeftBlue}
                            disabled={false}
                            clickFunc={() => {}}
                            standardIcon={true}
                            contextTooltip={true}
                            contextTooltipBG={"dark"}
                            contextTooltipText={"Previous page"}
                            customClass={null}
                        />
                        <IconButton
                            size="medium"
                            icon={ArrowRightBlue}
                            disabled={false}
                            clickFunc={() => {}}
                            standardIcon={true}
                            contextTooltip={true}
                            contextTooltipBG={"dark"}
                            contextTooltipText={"Next page"}
                            customClass={null}
                        />
                    </div>
                }
            </div>
            <div className="property-section-row">
                {
                    data.map((item, index) =>
                        type === "lists" ?
                        <ListCard
                            list={item}
                            key={index}
                            editMode={false}
                            clickFunc={viewList}
                        />
                        :
                        <NewPropertyCard
                            item={item}
                            index={index}
                            key={index}
                            userDetails={userData}
                            formatter={formatterLong}
                            abstract={true}
                            viewedProperties={null}
                            setViewedProperties={null}
                            cityId={item.cityId}
                            setHoverCard={null}
                            propertyRefs={[]}
                            onboarding={true}
                            onboardingStep={0}
                            setOnboardingStep={false}
                            setCardClick={null}
                            temporaryId={false}
                            overlay={true}
                            setOverlay={null}
                            propertySearch={false}
                            partnerView={false}
                            partnerEditProperty={null}
                            partnerSoldProperty={null}
                            partnerData={null}
                            changeStatus={null}
                            setShowExitScreen={null}
                            selectOption={null}
                            openStatus={null}
                            chooseStatus={null}
                            state={null}
                            favourites={favourites}
                            setFavourites={setFavourites}
                            strategy={null}
                            arrowClicked={null}
                            imageLimit={10}
                            bulkActions={true}
                            selectedProperty={checkSelected(item.zpid)}
                            toggleSelected={toggleSelected}
                            note={item.note}
                            openNoteModal={openNoteModal}
                            openUnfavouritePropertiesModal={openUnfavouritePropertiesModal}
                            creativeStrategy={false}
                            activeMarket={true}
                        />
                    )    
                }
            </div>
        </div>
    )
};

export default PropertySection;