import React from 'react';

function MultiFamily(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="32" 
            height="32" 
            viewBox="0 0 32 32" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M3 3.34375C3 2.04933 4.03934 1 5.32143 1H26.6786C27.9607 1 29 2.04933 29 3.34375V28.6562C29 29.9507 27.9607 31 26.6786 31H18.7857V23.9688C18.7857 23.1921 18.1621 22.5625 17.3929 22.5625H14.6071C13.8379 22.5625 13.2143 23.1921 13.2143 23.9688V31H5.32143C4.03934 31 3 29.9507 3 28.6562V3.34375ZM7.64286 4.75C7.13002 4.75 6.71429 5.16973 6.71429 5.6875V9.4375C6.71429 9.95527 7.13002 10.375 7.64286 10.375H13.2143C13.7271 10.375 14.1429 9.95527 14.1429 9.4375V5.6875C14.1429 5.16973 13.7271 4.75 13.2143 4.75H7.64286ZM18.7857 4.75C18.2729 4.75 17.8571 5.16973 17.8571 5.6875V9.4375C17.8571 9.95527 18.2729 10.375 18.7857 10.375H24.3571C24.87 10.375 25.2857 9.95527 25.2857 9.4375V5.6875C25.2857 5.16973 24.87 4.75 24.3571 4.75H18.7857ZM6.71429 15.0625C6.71429 14.5447 7.13002 14.125 7.64286 14.125H13.2143C13.7271 14.125 14.1429 14.5447 14.1429 15.0625V18.8125C14.1429 19.3303 13.7271 19.75 13.2143 19.75H7.64286C7.13002 19.75 6.71429 19.3303 6.71429 18.8125V15.0625ZM18.7857 14.125C18.2729 14.125 17.8571 14.5447 17.8571 15.0625V18.8125C17.8571 19.3303 18.2729 19.75 18.7857 19.75H24.3571C24.87 19.75 25.2857 19.3303 25.2857 18.8125V15.0625C25.2857 14.5447 24.87 14.125 24.3571 14.125H18.7857Z" 
                fill={fill}
            />
        </svg>
    )
}

export default MultiFamily;