import React from 'react';
import '../styles/ResetButton.css';

function ResetButton(props) {
    const resetMap = props.resetMap;
    return (
        <div 
            className="reset-button-outer-container"
            onClick={() => resetMap()}
        >
            <span className="label-semibold-caps text-link">
                Reset
            </span>
        </div>
    )
}

export default ResetButton;