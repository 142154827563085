import React from 'react';
import '../styles/PropertyPosition.css';
import { SingleFamily } from '../../assets/icons'; 
import { InfoWindow } from '@react-google-maps/api';
import { colour } from '../../styles/GlobalStyles';

function PropertyPosition(props) {
    const latitude = props.latitude;
    const longitude = props.longitude;

    return (
        <InfoWindow
            options={{ pixelOffset: new window.google.maps.Size(0, 0) }}
            position={{ lat: latitude, lng: longitude }}
        >
            <div className="property-position-container">
                <SingleFamily
                    fill={colour.brownBrown01}
                    className="property-position-icon"
                />
            </div>
        </InfoWindow>
    )
};

export default PropertyPosition;