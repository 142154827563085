import React from 'react';
import '../styles/SelectionChip.css';

function SelectionChip(props) {
    const label = props.label;
    const selected = props.selected;
    const disabled = props.disabled;
    const func = props.func;
    const index = props.index;
    const leftIcon = props.leftIcon;
    const rightIcon = props.rightIcon;

    return (
        <div 
            className={"selection-chip-outer-container " + (selected === true ? "selection-chip-selected " : "selection-chip-unselected ") + (disabled === true ? "disabled" : "cursor-pointer")}
            onClick={() => disabled === false ? func(index) : null}
        >
            <div className="selection-chip-inner-container">
                {
                    leftIcon !== null &&
                    <img
                        src={leftIcon}
                        alt="selection-chip-left-icon"
                        className="selection-chip-left-icon"
                    />
                }
                <span className="body-regular colour-primary block-text text-align-center">
                    {label}
                </span>
                {
                    rightIcon !== null &&
                    <img
                        src={rightIcon}
                        alt="selection-chip-right-icon"
                        className="selection-chip-right-icon"
                    />
                }
            </div>
        </div>
    )
};

export default SelectionChip;