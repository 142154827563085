import React from 'react';
import '../styles/ConditionBadge.css';
import { colour } from '../../styles/GlobalStyles';

function ConditionBadge(props) {
    const condition = props.condition;
    const bgColour = condition === 3 ? colour.greenGreen02 : condition === 2 ? colour.yellowYellow05 : condition === 1 ? colour.orangeOrange03 : colour.redRed02;
    const label = condition === 3 ? "Remodeled" : condition === 2 ? "Maintained" : condition === 1 ? "Needs work" : "Gut rehab";

    return (
        <div className="condition-badge-container">
            <svg 
                width="16" 
                height="16" 
                viewBox="0 0 16 16" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect 
                    width="16" 
                    height="16" 
                    rx="8" 
                    fill={bgColour}
                />
                <path 
                    d="M9.5932 4.91551L9.2441 4.94712L7.14071 3.26727C6.70078 2.91593 6.08633 2.91863 5.61548 3.22947C5.52892 3.28652 5.58311 3.40937 5.68324 3.41041C6.0512 3.41435 6.40606 3.57443 6.64442 3.88192C7.22977 4.63708 6.73807 5.28079 6.24398 5.89912L7.22582 6.68325L7.52965 6.30302C7.60746 6.20564 7.70934 6.14729 7.81888 6.09939C7.97372 6.03159 8.14985 6.05866 8.27563 6.15911L8.2486 6.3459C8.24028 6.40385 8.26009 6.46054 8.30173 6.49779L8.91061 7.04261C8.9838 7.1081 9.10007 7.09447 9.16535 7.01277L10.2606 5.64213C10.3235 5.56339 10.3145 5.45165 10.2406 5.39262L9.6724 4.9388C9.66081 4.92955 9.64746 4.92284 9.63315 4.91894C9.62035 4.91549 9.60688 4.9143 9.5932 4.91551Z" 
                    fill="#FFF9F2"
                />
                <path 
                    d="M7.09873 6.85427L6.1169 6.07014L3.11903 9.65657C2.94377 9.86625 2.96452 10.1707 3.165 10.3308L3.5942 10.6736C3.79468 10.8337 4.09627 10.7867 4.26211 10.5695L7.09873 6.85427Z" 
                    fill="#FFF9F2"
                />
                <path 
                    d="M8.98763 11.6211C8.98763 11.4827 9.09991 11.3704 9.23841 11.3704H11.7461C11.8846 11.3704 11.9969 11.4827 11.9969 11.6211V12.7493C11.9969 12.8878 11.8846 13 11.7461 13H9.23841C9.09991 13 8.98763 12.8878 8.98763 12.7493V11.6211Z" 
                    fill="#FFF9F2"
                />
                <path 
                    d="M10.2415 9.23942C10.103 9.23942 9.99072 9.35166 9.99072 9.49012V10.6183C9.99072 10.7568 10.103 10.869 10.2415 10.869H12.7492C12.8877 10.869 13 10.7568 13 10.6183V9.49012C13 9.35166 12.8877 9.23942 12.7492 9.23942H10.2415Z" 
                    fill="#FFF9F2"
                />
                <path 
                    d="M5.47681 11.6211C5.47681 11.4827 5.58909 11.3704 5.72758 11.3704H8.23531C8.37381 11.3704 8.48609 11.4827 8.48609 11.6211V12.7493C8.48609 12.8878 8.37381 13 8.23531 13H5.72758C5.58909 13 5.47681 12.8878 5.47681 12.7493V11.6211Z" 
                    fill="#FFF9F2"
                />
                <path 
                    d="M6.73068 9.23942C6.59218 9.23942 6.4799 9.35166 6.4799 9.49012V10.6183C6.4799 10.7568 6.59218 10.869 6.73068 10.869H9.23841C9.3769 10.869 9.48918 10.7568 9.48918 10.6183V9.49012C9.48918 9.35166 9.3769 9.23942 9.23841 9.23942H6.73068Z" 
                    fill="#FFF9F2"
                />
            </svg>
            <span className="body-semibold colour-primary">
                {label}
            </span>
        </div>
    )
};

export default ConditionBadge;