import React, { forwardRef, useState } from 'react';
import '../styles/NewPropertyCard.css';
import CustomSwiper from './CustomSwiper';
import { BlackTick, FaveSelected, FaveUnselected, OffMarketHouse, RedirectArrow, TickBlue } from '../assets';
import { toggleFavourite, markPropertyViewed, recordEvent, markOfferSent, checkOnAuthStateChanged, checkCondition } from '../functions/index';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoIBadge, NewPropertyCharacteristics, PriceCutBadge, PropertyCardBadge } from './';
import { PropertySelect, Button, PropertyCardNote, ConditionBadge, Flag } from '../v4/components';
import { colour, percentage } from '../styles/GlobalStyles';
import { ArrowTurnBackwardIcon } from 'hugeicons-react';

const NewPropertyCard = forwardRef((props, ref) => {
	const item = props.item;
	const index = props.index;
	const formatter = props.formatter;
	const abstract = props.abstract;
	const imageLimit = props.imageLimit;
	const viewedProperties = props.viewedProperties;
	const setViewedProperties = props.setViewedProperties;
	const cityId = props.cityId;
	const setHoverCard = props.setHoverCard;
	const propertyRefs = props.propertyRefs;
	const onboarding = props.onboarding;
	const setCardClick = props.setCardClick;
	const temporaryId = props.temporaryId;
	const setSignUpModal = props.setSignUpModal;
	const overlay = props.overlay;
	const setOverlay = props.setOverlay;
	const propertySearch = props.propertySearch;
	const partnerView = props.partnerView;
	const partnerData = props.partnerData;
	const arrowClicked = props.arrowClicked;
	const propertyViewed = viewedProperties === null ? false : viewedProperties.findIndex(e => e.zpid === item.zpid) === -1 ? false : true;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites;
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const [imageHover, setImageHover] = useState(false);
	const bulkActions = props.bulkActions;
	const selectedProperty = props.selectedProperty;
	const toggleSelected = props.toggleSelected;
	const note = props.note;
	const openNoteModal = props.openNoteModal;
	const openUnfavouritePropertiesModal = props.openUnfavouritePropertiesModal;
	const zpidIndex = favourites.findIndex(e => e.zpid === item.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
	const ipUser = props.ipUser;
	const noUserId = props.noUserId;
	const activeMarket = props.activeMarket;
	const creativeStrategy = props.creativeStrategy !== undefined && props.creativeStrategy !== null ? props.creativeStrategy : false;
	const creativeType = item.freeClearFlag === true ? "sf" : "sub";
	const strategy = props.strategy !== undefined && props.strategy !== null ? props.strategy : 0;
	const buyingCash = item.financials.mortgagePayment === 0 ? true : false;
	const acquisitionParam = creativeStrategy === true ? `?acquisition=${creativeType}` : `?acquisition=${buyingCash === true ? "c" : "m"}`;
	const exitStrategyText = strategy === 0 ? "ltr" : strategy === 1 ? "hh" : strategy === 2 ? "brrrr" : "lo";
	const exitParam = `&exit=${exitStrategyText}`;
	const strategyParam = `${acquisitionParam}${exitParam}`;
	const googleLink = `https://www.google.com/maps/place/${item.address.streetAddress.replace(" ", "+")},+${item.address.city},+${item.address.state}+${item.address.zipcode}`;
	const cityObject = {
		cityId: cityId,
		city: item.address.city,
		state: item.address.state
	};
	const propertiesPath = `/properties`;
	const maxNoteLength = 42;
	const [isValidImage, setIsValidImage] = useState(true);

	const roiLabel = strategy === 1 ? "Exp. covered" : "CoCR";

	const rawRoI = strategy === 1 ? item.houseHackingFeatures.ratioOfMortgageCovered : item.financials.cashOnCash;
	const roiCalc = strategy === 1 ? rawRoI : Math.round(rawRoI) / 100;
	const roiBadgeBG = strategy === 1 && rawRoI >= 1 ? "bg-colour-high-score" : strategy === 1 ? "bg-colour-low-score" : rawRoI >= 7 ? "bg-colour-high-score" : rawRoI >= 0 ? "bg-colour-medium-score" : "bg-colour-low-score";

	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;

	const viewProperty = async(zpid, e, bgImage) => {
		if ( partnerView === true ) {
			const target = e.target;
			const targetClass = target.parentNode.classList.value;
			if ( targetClass.includes("off-market-card-text-row") || targetClass.includes("off-market-extra-badges-container") ) {
				return;
			}
			else if ( zpid === undefined ) {
				alert("There was a problem getting the unique ID for this property...contact us for help!");
			}
			else {
				navigate(`/partner/${partnerData.id}/${zpid}`, {
					state: state
				});
				recordEvent("Partner Card Clicked", {
					zpid: zpid
				});
			}
		}
		else if ( propertySearch === true ) {
			const target = e.target;
			const targetClass = target.parentNode.classList.value;
			if ( targetClass.includes("off-market-card-text-row") || targetClass.includes("off-market-extra-badges-container") ) {
				return;
			}
			else {
				const encodedAddress = item.encodedAddress === undefined ? `${encodeURIComponent(`${item.address.streetAddress}, ${item.address.city}, ${item.address.state}, USA`)}` : item.encodedAddress;
				navigate(`/quick-report/${encodedAddress}`, {
					state: {
						redirect: true,
						preSaved: true,
						ipUser: ipUser,
						noUserId: noUserId
					}
				});
				recordEvent("Property Search Card Clicked", {
					zpid: zpid
				});
			}
		}
		else if ( overlay === false ) {
			setOverlay(true);
		}
		else if ( bgImage === true ) {
			const target = e.target;
			const targetClass = target.classList.value;
			if ( targetClass !== "favourite-icon " ) {
				if ( onboarding === true ) {
					if ( setCardClick !== null ) {
						setCardClick(true);
					}
					window.open(`${propertiesPath}/${cityId}/${zpid}${strategyParam}`, '_blank');
		
					if ( temporaryId === false ) {
						const path = window.location.pathname;
						const newObject = {
							zpid: zpid,
							date: new Date()
						}
	
						if ( viewedProperties !== null ) {
							const newArray = viewedProperties.slice(0);
							newArray.push(newObject);
							setViewedProperties(newArray);
							await markPropertyViewed(zpid);
						}

						recordEvent("Navigation", {
							oldRoute: path,
							newRoute: `${propertiesPath}/${cityId}/${zpid}${strategyParam}`
						});

						recordEvent("Property Click", {
							zpid: zpid,
							cityId: cityId
						});
					}

					setTimeout(() => {
						if ( setCardClick !== null ) {
							setCardClick(false);
						}
					}, 500)
				}
			}
		}
		else {
			if ( onboarding === true ) {
				if ( setCardClick !== null ) {
					setCardClick(true);
				}
				window.open(`${propertiesPath}/${cityId}/${zpid}${strategyParam}`, '_blank');
	
				if ( temporaryId === false ) {
					const newObject = {
						zpid: zpid,
						date: new Date()
					}
	
					if ( viewedProperties !== null ) {
						const newArray = viewedProperties.slice(0);
						newArray.push(newObject);
						setViewedProperties(newArray);
						await markPropertyViewed(zpid);
					}
		
					const path = window.location.pathname;

					recordEvent("Navigation", {
						oldRoute: path,
						newRoute: `${propertiesPath}/${cityId}/${zpid}${strategyParam}`
					});

					recordEvent("Property Click", {
						zpid: zpid,
						cityId: cityId
					});
				}
	
				setTimeout(() => {
					if ( setCardClick !== null ) {
						setCardClick(false);
					}
				}, 500)
			}
		}
	};

	const addHover = () => {
		setHoverCard(item.zpid);
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 3;
		}
	}

	const removeHover = () => {
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 1;
		}
		setHoverCard("");
	}

	const openGoogle = () => {
		recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
    };

	const toggleImageHover = (value) => {
		setImageHover(value);
	};

	const toggleSelectedClick = () => {
		toggleSelected(item.zpid);
	};

	const openFavouriteModal = () => {
		openUnfavouritePropertiesModal(item);
	};

	const offerSent = async() => {
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			await markOfferSent(userId, item.zpid, true);
			
			const favouritesClone = [...favourites];
			const zpidIndex = favouritesClone.findIndex(e => e.zpid === item.zpid);
			if ( zpidIndex !== -1 ) {
				favouritesClone[zpidIndex].offerSent = true;
				setFavourites(favouritesClone);
			}

			recordEvent("Offer Sent", {
				zpid: item.zpid
			});
		}
	};

	const undoOfferSent = async() => {
		const user = await checkOnAuthStateChanged();
		if ( user.status === 200 ) {
			const userId = user.userId;
			await markOfferSent(userId, item.zpid, false);
			
			const favouritesClone = [...favourites];
			const zpidIndex = favouritesClone.findIndex(e => e.zpid === item.zpid);
			if ( zpidIndex !== -1 ) {
				favouritesClone[zpidIndex].offerSent = false;
				setFavourites(favouritesClone);
			}

			recordEvent("Offer Unsent", {
				zpid: item.zpid
			});
		}
	};

	const handleImageError = () => {
		setIsValidImage(false);
	};

	return (
		<div 
			className={"new-property-card-outer-container " + (abstract === false && propertyViewed === true && onboarding === true ? "new-property-card-viewed-property" : "")}
			onMouseOver={() => abstract === false ? addHover() : null}
			onMouseOut={() => abstract === false ? removeHover() : null}
		>
			<div className={"new-property-card-inner-container " + (item.offMarket === false ? "new-property-card-on-market" : "")}>
				{
					item.images !== undefined && item.images !== null && item.images.length > 1 ?
					<div 
						className={"new-property-card-images-swiper-container " + (selectedProperty === true ? "new-property-card-selected" : "")}
						onMouseEnter={() => toggleImageHover(true)}
                        onMouseLeave={() => toggleImageHover(false)}
					>
						{
							bulkActions === true && item.offerSent === true && imageHover === false &&
							<Flag
								label="Offer sent"
								bgColour={"bg-colour-yellow-2"}
								icon={BlackTick}
								standardIcon={true}
							/>
						}
						<div
							className={
								"new-property-card-image-hover-dark-bg " +
								(imageHover === true && bulkActions === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedProperty}
								toggleSelected={toggleSelectedClick}
							/>
							<Button
								buttonType={item.offerSent === true ? "tertiary-button" : "light-button"}
								size="medium"
								text={item.offerSent === true ? "Undo offer sent" : "Mark offer sent"}
								cta={item.offerSent === true ? undoOfferSent : offerSent}
								disabled={false}
								leftIcon={item.offerSent === true ? <ArrowTurnBackwardIcon size={16} color={colour.blueBlue03} /> : TickBlue}
								leftIconClass=""
								rightIcon={null}
								rightIconClass=""
								alt=""
								standardIcon={item.offerSent === true ? false : true}
								badge={false}
								badgeVal={null}
								badgeBG={null}
								buttonRef={null}
							/>
						</div>
						<div
							className={
								"new-property-card-image-hover-light-bg " +
								(imageHover === false && bulkActions === true && selectedProperty === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedProperty}
								toggleSelected={toggleSelectedClick}
							/>
						</div>
						<CustomSwiper
							images={item.images}
							title={item.address.streetAddress}
							defaultImage={item.imgSrc}
							Heart={FaveUnselected}
							HeartActive={FaveSelected}
							favourites={favourites}
							toggleFavourite={bulkActions === true ? openFavouriteModal : toggleFavourite}
							favouriteDisable={favouriteDisable}
							item={item}
							setFavourites={setFavourites}
							setFavouriteDisable={setFavouriteDisable}
							abstract={abstract}
							viewProperty={viewProperty}
							cityId={cityId}
							setCardClick={setCardClick}
							temporaryId={temporaryId}
							setSignUpModal={setSignUpModal}
							propertySearch={propertySearch}
							partner={partnerData === null ? false : true}
							arrowClicked={arrowClicked}
							imageLimit={imageLimit}
							swiperRef={null}
						/>
						{
							item.propertyCondition !== "Unclear" && item.propertyCondition !== undefined && item.propertyCondition !== null &&
							<ConditionBadge
								condition={checkCondition(item.propertyCondition)}
							/>
						}
						{
							bulkActions === true && item.offerSent === true ?
							null
							:
							item.auction === true ?
							<PropertyCardBadge
								text="AUCTION"
							/>
							:
							item.backOnMarket === true ?
							<PropertyCardBadge
								text="BACK ON MARKET"
							/>
							:
							partnerView === true && item.status === "sold" ?
							<PropertyCardBadge
								text="SOLD"
							/>
							:
							item.priceChange === true ?
							<PriceCutBadge
								priceDrop={item.priceChangeTotalPrctDiff > 0 ? false : true}
								priceChangeHistory={item.priceChangeHistory}
								priceChangeTotalAmount={item.priceChangeTotalAmount}
								priceChangeLatestDate={item.priceChangeLatestDate}
							/>
							:
							null
						}
					</div>
					:
					isValidImage === false || item.images === null || (item.images.length > 0 && item.images[0].includes("maps.googleapis.com")) || (item.imgSrc !== undefined && item.imgSrc.includes("maps.googleapis.com")) || (item.images.length === 0) ?
					<div 
						className={"off-market-card-image-container " + (selectedProperty === true ? "new-property-card-selected" : "")}
						onClick={(e) => bulkActions === false ? viewProperty(item.zpid, e, false) : null}
						onMouseEnter={() => toggleImageHover(true)}
                        onMouseLeave={() => toggleImageHover(false)}
					>
						{
							bulkActions === true && item.offerSent === true && imageHover === false &&
							<Flag
								label="Offer sent"
								bgColour={"bg-colour-yellow-2"}
								icon={BlackTick}
								standardIcon={true}
							/>
						}
						{
							item.propertyCondition !== "Unclear" &&
							<ConditionBadge
								condition={checkCondition(item.propertyCondition)}
							/>
						}
						<div
							className={
								"new-property-card-image-hover-dark-bg " +
								(imageHover === true && bulkActions === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedProperty}
								toggleSelected={toggleSelectedClick}
							/>
							<Button
								buttonType={item.offerSent === true ? "tertiary-button" : "light-button"}
								size="medium"
								text={item.offerSent === true ? "Undo offer sent" : "Mark offer sent"}
								cta={item.offerSent === true ? undoOfferSent : offerSent}
								disabled={false}
								leftIcon={item.offerSent === true ? <ArrowTurnBackwardIcon size={16} color={colour.blueBlue03} /> : TickBlue}
								leftIconClass=""
								rightIcon={null}
								rightIconClass=""
								alt=""
								standardIcon={item.offerSent === true ? false : true}
								badge={false}
								badgeVal={null}
								badgeBG={null}
								buttonRef={null}
							/>
						</div>
						<div
							className={
								"new-property-card-image-hover-light-bg " +
								(imageHover === false && bulkActions === true && selectedProperty === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedProperty}
								toggleSelected={toggleSelectedClick}
							/>
						</div>
						<img
							src={OffMarketHouse}
							className="off-market-card-icon"
							alt="Off Market"
						/>
						<div 
							className="off-market-card-text-row"
							onClick={() => openGoogle()}
						>
							<span className="label-regular-caps colour-secondary">
								Go to street view
							</span>
							<img
								src={RedirectArrow}
								className="off-market-card-redirect-arrow"
								alt="Redirect"
							/>
						</div>
					</div>
					:
					<div 
						style={{ backgroundImage: `url(${item.imgSrc})` }} 
						className={"new-property-card-image-container " + (selectedProperty === true ? "new-property-card-selected" : "")}
						onMouseEnter={() => toggleImageHover(true)}
                        onMouseLeave={() => toggleImageHover(false)}
						onClick={(e) => bulkActions === false ? viewProperty(item.zpid, e, true) : null}
					>
						{
							bulkActions === true && item.offerSent === true && imageHover === false &&
							<Flag
								label="Offer sent"
								bgColour={"bg-colour-yellow-2"}
								icon={BlackTick}
								standardIcon={true}
							/>
						}
						{
							item.propertyCondition !== "Unclear" &&
							<ConditionBadge
								condition={checkCondition(item.propertyCondition)}
							/>
						}
						<div
							className={
								"new-property-card-image-hover-dark-bg " +
								(imageHover === true && bulkActions === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedProperty}
								toggleSelected={toggleSelectedClick}
							/>
							<Button
								buttonType={item.offerSent === true ? "tertiary-button" : "light-button"}
								size="medium"
								text={item.offerSent === true ? "Undo offer sent" : "Mark offer sent"}
								cta={item.offerSent === true ? undoOfferSent : offerSent}
								disabled={false}
								leftIcon={item.offerSent === true ? <ArrowTurnBackwardIcon size={16} color={colour.blueBlue03} /> : TickBlue}
								leftIconClass=""
								rightIcon={null}
								rightIconClass=""
								alt=""
								standardIcon={item.offerSent === true ? false : true}
								badge={false}
								badgeVal={null}
								badgeBG={null}
								buttonRef={null}
							/>
						</div>
						<div
							className={
								"new-property-card-image-hover-light-bg " +
								(imageHover === false && bulkActions === true && selectedProperty === true ? "" : "view-none")
							}
						>
							<PropertySelect
								selected={selectedProperty}
								toggleSelected={toggleSelectedClick}
							/>
						</div>
						{
							bulkActions === true && item.offerSent === true ?
							null
							:
							item.auction === true ?
							<PropertyCardBadge
								text="AUCTION"
							/>
							:
							item.backOnMarket === true ?
							<PropertyCardBadge
								text="BACK ON MARKET"
							/>
							:
							partnerView === true && item.status === "sold" ?
							<PropertyCardBadge
								text="SOLD"
							/>
							:
							item.priceChange === true ?
							<PriceCutBadge
								priceDrop={item.priceChangeTotalPrctDiff > 0 ? false : true}
								priceChangeHistory={item.priceChangeHistory}
								priceChangeTotalAmount={item.priceChangeTotalAmount}
								priceChangeLatestDate={item.priceChangeLatestDate}
							/>
							:
							null
						}
						{
							propertySearch === true || partnerView === true ?
							null
							:
							<div 
								className="favourite-icon-container"
								onClick={() => temporaryId === true ? setSignUpModal(true) : setFavourites === null ? null : bulkActions === true ? openFavouriteModal() : toggleFavourite(favourites, item.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, setCardClick)}
							>
								<img
									src={
											inFavourites === false ?
											FaveUnselected
											:
											FaveSelected
										}
									className={`favourite-icon ${favouriteDisable === true ? "favourite-icon-disable" : ""}`}
									alt="Add To Favourites"
									onError={handleImageError}
								/>
							</div>
						}
					</div>
				}
				<div 
					onClick={(e) => viewProperty(item.zpid, e, false)}
					className="new-property-card-info-container"
				>
					<div className="new-property-card-price-container">
						<h3 className="heading-small-semibold colour-primary">
							{formatter.format(item.price).replace(".00", "")}
						</h3>
					</div>
					<NewPropertyCharacteristics
						item={item}
					/>
				</div>
				<div className="new-property-card-bottom-container">
					<div className="new-property-card-roi-row">
						<RoIBadge
							label={roiLabel}
							value={percentage.format(roiCalc)}
							roiBadgeBG={roiBadgeBG}
						/>
						{
							activeMarket === false || item.dripScore === 0 ?
							null
							:
							<RoIBadge
								label="Score"
								value={Math.round(item.dripScore * 100)}
								roiBadgeBG={item.dripScore >= 0.8 ? "bg-colour-high-score" : item.dripScore >= 0.7 ? "bg-colour-medium-score" : "bg-colour-low-score"}
							/>
						}
					</div>
					{
						bulkActions === true &&
						<PropertyCardNote
							note={note === null ? null : note.length >= maxNoteLength ? `${note.slice(0, maxNoteLength)}..` : note}
							cta={openNoteModal}
							zpid={item.zpid}
						/>
					}
				</div>
			</div>
		</div>
	)
});

export default NewPropertyCard;