import React from 'react';

function Filters(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="33" 
            height="32" 
            viewBox="0 0 33 32" 
            fill="none"
            className={className}
        >
            <rect 
                width="32" 
                height="32" 
                transform="translate(0.5)" 
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M8.5 1.45459C10.5333 1.45459 12.2418 2.84525 12.7262 4.72732H31.4091C32.0116 4.72732 32.5 5.21573 32.5 5.81823C32.5 6.42072 32.0116 6.90914 31.4091 6.90914H12.7262C12.2418 8.7912 10.5333 10.1819 8.5 10.1819C6.46672 10.1819 4.75825 8.7912 4.27384 6.90914H1.59091C0.988417 6.90914 0.5 6.42072 0.5 5.81823C0.5 5.21573 0.988417 4.72732 1.59091 4.72732H4.27384C4.75825 2.84525 6.46672 1.45459 8.5 1.45459ZM11.7727 5.81823C11.7727 7.6257 10.3075 9.09095 8.5 9.09095C6.69252 9.09095 5.22727 7.6257 5.22727 5.81823C5.22727 4.01075 6.69252 2.5455 8.5 2.5455C10.3075 2.5455 11.7727 4.01075 11.7727 5.81823Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M12.8636 21.8182C14.8969 21.8182 16.6054 23.2089 17.0898 25.091H31.4091C32.0116 25.091 32.5 25.5794 32.5 26.1819C32.5 26.7844 32.0116 27.2728 31.4091 27.2728H17.0898C16.6054 29.1548 14.8969 30.5455 12.8636 30.5455C10.8304 30.5455 9.12188 29.1548 8.63747 27.2728H1.59091C0.988417 27.2728 0.5 26.7844 0.5 26.1819C0.5 25.5794 0.988417 25.091 1.59091 25.091H8.63747C9.12188 23.2089 10.8304 21.8182 12.8636 21.8182ZM16.1364 26.1819C16.1364 27.9893 14.6711 29.4546 12.8636 29.4546C11.0562 29.4546 9.59091 27.9893 9.59091 26.1819C9.59091 24.3744 11.0562 22.9091 12.8636 22.9091C14.6711 22.9091 16.1364 24.3744 16.1364 26.1819Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M1.59091 14.9091C0.988417 14.9091 0.5 15.3976 0.5 16C0.5 16.6025 0.988417 17.091 1.59091 17.091H20.2738C20.7582 18.973 22.4667 20.3637 24.5 20.3637C26.5333 20.3637 28.2418 18.973 28.7262 17.091H31.4091C32.0116 17.091 32.5 16.6025 32.5 16C32.5 15.3976 32.0116 14.9091 31.4091 14.9091H28.7262C28.2418 13.0271 26.5333 11.6364 24.5 11.6364C22.4667 11.6364 20.7582 13.0271 20.2738 14.9091H1.59091ZM27.7727 16C27.7727 17.8075 26.3075 19.2728 24.5 19.2728C22.6925 19.2728 21.2273 17.8075 21.2273 16C21.2273 14.1926 22.6925 12.7273 24.5 12.7273C26.3075 12.7273 27.7727 14.1926 27.7727 16Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Filters;