import React from 'react';
import '../styles/ClearFilters.css';
import { Filters } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function ClearFilters(props) {
    const func = props.func;

    return (
        <div className="clear-filters-outer-container">
            <div className="clear-filters-inner-container">
                <Filters
                    fill={colour.grayScaleGray02}
                />
                <h2 className="heading-small-semibold colour-primary text-align-center">
                    Oops! No properties match your search. 
                </h2>
                <span className="body-regular colour-secondary block-text text-align-center">
                    Your filters may be too restrictive. 
                    Try changing your filters or resetting the map.
                </span>
                <div 
                    className="text-button"
                    onClick={() => func()}
                >
                    <span className="body-regular text-link block-text text-align-center">
                        Clear all filters
                    </span>
                </div>
            </div>
        </div>
    )
};

export default ClearFilters;