import React from 'react';
import '../styles/YearBuiltFilter.css';
import { Condition } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { FilterReset, FinancialsInput } from './';

function YearBuiltFilter(props) {
    const yearBuilt = props.yearBuilt;
    const setYearBuilt = props.setYearBuilt;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const error = props.error;

    const onChangeLower = (e) => {
        if ( e.target.value.length <= 4 ) {
            setYearBuilt([e.target.value, yearBuilt[1]]);
        }
    };

    const onChangeUpper = (e) => {
        if ( e.target.value.length <= 4 ) {
            setYearBuilt([yearBuilt[0], e.target.value]);
        }
    };

    const checkNaN = (val) => {
        if ( error === true ) {
            return true;
        }
        else if ( isNaN(val) ) {
            return true;
        }
        else if ( (yearBuilt[0] !== "" && yearBuilt[1] !== "" && Number(yearBuilt[0]) > Number(yearBuilt[1])) ) {
            return true;
        }
        return false;
    };

    return (
        <div className="year-built-outer-container">
            <div className="year-built-title-container">
                <Condition
                    fill={colour.grayScaleBlack}
                    className=""
                />
                <span className="body-regular colour-primary">
                    Year built
                </span>
            </div>
            <div className="year-built-body-container">
                <div className="year-built-input-container">
                    <FinancialsInput
                        label={null}
                        labelType={null}
                        info={false}
                        value={yearBuilt[0]}
                        onChange={onChangeLower}
                        placeholder={"Min"}
                        type="text"
                        leftUnit={null}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(yearBuilt[0])}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
                <span className="body-regular colour-secondary">
                    -
                </span>
                <div className="year-built-input-container">
                    <FinancialsInput
                        label={null}
                        labelType={null}
                        info={false}
                        value={yearBuilt[1]}
                        onChange={onChangeUpper}
                        placeholder={"Max"}
                        type="text"
                        leftUnit={null}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(yearBuilt[1])}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
            </div>
            {
                error === true ?
                <span className="body-regular colour-error">
                    Your max year can't be earlier than your min year
                </span>
                :
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("construction")}
                />
            }
        </div>
    )
};

export default YearBuiltFilter;