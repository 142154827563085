import React, { useState } from 'react';
import '../styles/StickyROI.css';
import { Download, Heart, HeartActive } from '../assets/icons';
import { colour, formatterLong } from '../styles/GlobalStyles';
import { ExitScreen } from './';
import { IconButton, SaleStatus } from '../v4/components';
import moment from 'moment';
import { sendPropertiesForExcel } from '../functions';

function StickyROI(props) {

	const toggleFavourite = props.toggleFavourite;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites
	const setFavouriteDisable = props.setFavouriteDisable;
	const navigate = props.navigate;
	const favouriteDisable = props.favouriteDisable;
	const freeTrial = props.freeTrial;
	const property = props.property;
	const cityId = props.cityId;
	const downPayment = props.downPayment;
	const blurred = props.blurred;
	const spreadsheetProperty = props.spreadsheetProperty;
	const setPremiumModal = props.setPremiumModal;
	const csvDownloadLoading = props.csvDownloadLoading;
	const setCSVDownloadLoading = props.setCSVDownloadLoading;
	const setCSVDownloadComplete = props.setCSVDownloadComplete;
	const setCSVDownloadError = props.setCSVDownloadError;
	const setCSVDownloadLink = props.setCSVDownloadLink;
	const cityObject = {
		cityId: cityId,
		city: property.address.city,
		state: property.address.state
	};

	const zpidIndex = favourites.findIndex(e => e.zpid === property.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
	const [downloadSelect, setDownloadSelect] = useState(false);

	const showSaleStatus = ((property.priceChange === true && property.onMarket === true) || (property.homeStatus === "PENDING") || (property.homeStatus === "SOLD") || (property.backOnMarket === true) || (property.auction === true)) ? true : false;
	const priceChange = property.priceChange === true && property.onMarket === true ? true : false;
	const priceChangeLabel = priceChange === false ? null : property.priceChangeTotalPrctDiff > 0 ? "Price gain" : "Price cut";
	const priceCut = priceChange === false ? null : property.priceChangeTotalPrctDiff > 0 ? false : true;
	const priceChangeLatestDate = priceChange === false ? null : property.priceChangeLatestDate;
	const priceChangeTotalAmount = priceChange === false ? null : property.priceChangeTotalAmount;
	const todayDate = moment().format("YYYY-MM-DD");
	const priceChangeDate = priceChange === false ? null : priceChangeLatestDate === todayDate ? "Today" : moment(priceChangeLatestDate).format("MMM DD");
	const saleStatusLabel = property.homeStatus === "PENDING" ? "Pending" : property.homeStatus === "SOLD" ? "Sold" : property.backOnMarket === true ? "Back on market" : priceChange === true ? priceChangeLabel : property.auction === true ? "Auction" : "";
	const showPriceChange = property.homeStatus === "PENDING" ? false : property.homeStatus === "SOLD" ? false : property.backOnMarket === true ? false : priceChange === true ? true : false;

	const closeDownloadSelector = () => {
		setDownloadSelect(false);
	};

	const clickDownloadCSV = async() => {
		if ( freeTrial === true ) {
			setPremiumModal(true);
			return;
		}
		setCSVDownloadError(false);
		setCSVDownloadLoading(true);
		const downloadData = await sendPropertiesForExcel([spreadsheetProperty], `${property.address.streetAddress}`, cityId, downPayment, 0);
		if ( downloadData.status === 200 ) {
			window.open(downloadData.data, "_blank");
			setCSVDownloadLink(downloadData.data);
			setCSVDownloadComplete(true);
			setCSVDownloadLoading(false);
		}
		else {
			setCSVDownloadError(true);
			setCSVDownloadLoading(false);
		}

		setDownloadSelect(false);
	};

	return (
		<div className="sticky-roi-outer-container">
			{
				downloadSelect === true ?
				<ExitScreen
					clickScreen={() => closeDownloadSelector()}
				/>
				:
				null
			}
			<div className="sticky-roi-inner-container">
				<div className="sticky-roi-price-container">
					<h1 className="heading-large-semibold colour-primary">
						{formatterLong.format(property.price).replace(".00", "")}
					</h1>
					{
						showSaleStatus === true &&
						<SaleStatus
							label={saleStatusLabel}
							priceChange={priceChange}
							priceCut={priceCut}
							priceChangeTotalAmount={priceChangeTotalAmount}
							priceChangeDate={priceChangeDate}
							showPriceChange={showPriceChange}
						/>
					}
				</div>
				<div className="new-property-interactive-buttons-container">
					{
						blurred === true ?
						null
						:
						<IconButton
							size="large"
							icon={<Download className="sticky-roi-download-icon" fill={colour.blueBlue03} />}
							disabled={csvDownloadLoading}
							clickFunc={clickDownloadCSV}
							standardIcon={false}
							contextTooltip={true}
							contextTooltipBG={"dark"}
							contextTooltipText={"Download CSV"}
							customClass={"mobile-none"}
						/>
					}
					{
						(property.zpid === undefined || property.zpid === null) ?
						null
						:
						<IconButton
							size="large"
							icon={inFavourites === true ? <HeartActive className="sticky-roi-heart-icon" fill={colour.blueBlue03} /> : <Heart className="sticky-roi-heart-icon" fill={colour.blueBlue03} />}
							disabled={false}
							clickFunc={() => favouriteDisable === true ? null : toggleFavourite(favourites, property.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null)}
							standardIcon={false}
							contextTooltip={true}
							contextTooltipBG={"dark"}
							contextTooltipText={"Add to favorites"}
							customClass={null}
						/>
					}
				</div>
			</div>
		</div>
	)
}

export default StickyROI;