import React from 'react';
import '../styles/Footer.css';
import { FooterLogo } from '../assets';
import { Instagram, TikTok, LinkedIn, YouTube } from '../assets/social';
import { useLocation, useNavigate } from 'react-router-dom';
import { recordEvent } from '../functions';

function Footer(props) {

    const location = useLocation();
    const state = props.state === undefined || props.state === null ? location.state : props.state;
    const navigate = useNavigate();
    const email = "info@coffeeclozers.com";
    const socials = [
        {
            text: "Instagram",
            image: Instagram,
            link: "http://instagram.com/coffeeclozers/"
        },
        {
            text: "YouTube",
            image: YouTube,
            link: "https://www.youtube.com/@coffeeclozers"
        },
        {
            text: "TikTok",
            image: TikTok,
            link: "https://www.tiktok.com/@coffeeclozers"
        },
        {
            text: "LinkedIn",
            image: LinkedIn,
            link: "https://www.linkedin.com/company/90956321"
        }
    ];

    const rightLinks = [
        {
            label: "COMPANY",
            links: [
                {
                    text: "Home",
                    link: "https://www.coffeeclozers.com/",
                    redirect: true
                },
                {
                    text: "About",
                    link: "https://www.coffeeclozers.com/#about",
                    redirect: true
                }
            ]
        },
        {
            label: "RESOURCES",
            links: [
                {
                    text: "Blog",
                    link: "https://www.coffeeclozers.com/blog",
                    redirect: true
                },
                {
                    text: "FAQ",
                    link: "https://www.coffeeclozers.com/faq",
                    redirect: true
                },
                {
                    text: "Releases",
                    link: "https://www.coffeeclozers.com/releases",
                    redirect: true
                },
                {
                    text: "Learn",
                    link: "https://www.coffeeclozers.com/learning-center",
                    redirect: true
                },
                {
                    text: "Become an Affiliate",
                    link: "/affiliate",
                    redirect: false
                }
            ]
        },
        {
            label: "PRODUCT",
            links: [
                {
                    text: "Pricing",
                    link: "/pricing",
                    redirect: false
                },
                {
                    text: "Does our product suck?",
                    link: "/penny-for-thoughts",
                    redirect: false
                }
            ]
        }
    ];

    const legalLinks = [
        {
            text: "Terms & Conditions",
            link: "/terms-conditions",
            redirect: false
        },
        {
            text: "Privacy Policy",
            link: "/privacy-policy",
            redirect: false
        }
    ];

    const navLink = (link) => {
        if ( link.redirect === true ) {
            window.open(link.link, "_blank");
        }
        else {
            navigate(link.link, {
                state: state
            });
        }

        recordEvent("Navigation", {
            oldRoute: window.location.pathname,
            newRoute: link.link
        });
    };

    return (
        <div className="footer-outer-container">
            <div className="footer-inner-container">
                <div className="footer-left-container">
                    <div className="footer-logo-container margin-medium">
                        <img
                            src={FooterLogo}
                            className="footer-logo"
                            alt="Coffee Clozers Logo"
                        />
                    </div>
                    <div className="footer-left-text-container margin-medium">
                        <span className="body-regular colour-beige">
                            We’re on a mission to help anyone 
                            become a real estate investor,
                        </span>
                    </div>
                    <div className="footer-left-links-container">
                        <a 
                            className="body-regular-underline colour-white margin-medium block-text"
                            href={`mailto:${email}`}
                        >
                            {email}
                        </a>
                        <div className="footer-social-row">
                            {
                                socials.map((item, index) =>
                                    <a
                                        href={item.link}
                                        key={index}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={item.image}
                                            className={"footer-social-icon " + (index === 3 ? "" : "subtext-margin-right-x-large")}
                                            alt={item.text}
                                        />
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="footer-right-container">
                    {
                        rightLinks.map((item, index) =>
                            <div 
                                className="footer-right-element-container"
                                key={index}
                            >
                                <h2 className="label-regular-caps colour-beige margin-medium">
                                    {item.label}
                                </h2>
                                <ul className="footer-right-element-links-container">
                                    {
                                        item.links.map((subItem, i) =>
                                            <li 
                                                className="footer-right-link-element body-semibold colour-white margin-medium"
                                                key={i}
                                                onClick={() => navLink(subItem)}
                                            >
                                                {subItem.text}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="footer-bottom-container">
                <div className="footer-bottom-left-container">
                    <span className="body-regular colour-beige">
                        © Coffee Clozers 2024
                    </span>
                </div>
                <div className="footer-bottom-legal-container">
                    {
                        legalLinks.map((item, index) =>
                            <div 
                                className={"footer-bottom-legal-element cursor-pointer " + (index === 0 ? "subtext-margin-right-x-x-large" : "")}
                                key={index}
                                onClick={() => navLink(item)}
                            >
                                <span className="body-regular-underline colour-beige">
                                    {item.text}
                                </span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Footer;