import React from 'react';

function SquareFootage(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M11.6668 1.81818C11.8399 0.786286 12.7373 0 13.8184 0C15.0234 0 16.0002 0.976833 16.0002 2.18182C16.0002 3.26291 15.214 4.16035 14.1821 4.33347V11.6665C15.214 11.8397 16.0002 12.7371 16.0002 13.8182C16.0002 15.0232 15.0234 16 13.8184 16C12.7373 16 11.8399 15.2137 11.6668 14.1818H4.33371C4.16059 15.2137 3.26315 16 2.18206 16C0.977077 16 0.000244141 15.0232 0.000244141 13.8182C0.000244141 12.7371 0.78653 11.8397 1.81843 11.6665L1.81843 4.33347C0.78653 4.16035 0.000244141 3.26291 0.000244141 2.18182C0.000244141 0.976833 0.977077 0 2.18206 0C3.26315 0 4.16059 0.786286 4.33371 1.81818H11.6668ZM3.81843 2.18182C3.81843 3.08556 3.0858 3.81818 2.18206 3.81818C1.27832 3.81818 0.545699 3.08556 0.545699 2.18182C0.545699 1.27808 1.27832 0.545455 2.18206 0.545455C3.0858 0.545455 3.81843 1.27808 3.81843 2.18182ZM4.33371 2.54545C4.18043 3.45909 3.45934 4.18019 2.5457 4.33347L2.5457 11.6665C3.45934 11.8198 4.18043 12.5409 4.33371 13.4545H11.6668C11.8201 12.5409 12.5412 11.8198 13.4548 11.6665V4.33347C12.5412 4.18019 11.8201 3.45909 11.6668 2.54545H4.33371ZM15.4548 2.18182C15.4548 3.08556 14.7222 3.81818 13.8184 3.81818C12.9147 3.81818 12.1821 3.08556 12.1821 2.18182C12.1821 1.27808 12.9147 0.545455 13.8184 0.545455C14.7222 0.545455 15.4548 1.27808 15.4548 2.18182ZM13.8184 15.4545C14.7222 15.4545 15.4548 14.7219 15.4548 13.8182C15.4548 12.9144 14.7222 12.1818 13.8184 12.1818C12.9147 12.1818 12.1821 12.9144 12.1821 13.8182C12.1821 14.7219 12.9147 15.4545 13.8184 15.4545ZM3.81843 13.8182C3.81843 14.7219 3.0858 15.4545 2.18206 15.4545C1.27832 15.4545 0.545699 14.7219 0.545699 13.8182C0.545699 12.9144 1.27832 12.1818 2.18206 12.1818C3.0858 12.1818 3.81843 12.9144 3.81843 13.8182Z" 
                fill={fill}
            />
        </svg>
    )
}

export default SquareFootage;