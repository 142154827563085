import React from 'react';
import '../styles/Badge.css';

function Badge(props) {
    const val = props.val;
    const bg = props.bg;
    const freeSize = props.freeSize;

    return (
        <div className={"badge-element-container " + (freeSize === false ? "badge-element-normal-size " : "badge-element-free-size ") + (bg)}>
            <span className="label-semibold-caps colour-white block-text text-align-center">
                {val}
            </span>
        </div>
    )
};

export default Badge;