import React from 'react';
import '../styles/Tooltip.css';

function Tooltip(props) {
    const type = props.type;
    const title = props.title;
    const body = props.body;
    const position = props.position;
    const className = `tooltip-beak-${position}`;
    const contextTooltipBg = props.contextTooltipBg;
    const visible = props.visible;

    return (
        type === "info" ?
        <div className="tooltip-outer-container">
            <div className="tooltip-inner-container relative-container">
                <div className="tooltip-beak-container">
                    <svg 
                        className={"tooltip-beak " + (className)}
                        xmlns="http://www.w3.org/2000/svg" 
                        width="18" 
                        height="15" 
                        viewBox="0 0 18 15" 
                        fill="none"
                    >
                        <path d="M9 15L17.6603 0H0.339746L9 15Z" fill="#FFFEFC"/>
                    </svg>
                </div>
                <h2 className="body-semibold colour-primary margin-x-small">
                    {title}
                </h2>
                <span className="body-regular colour-primary">
                    {body}
                </span>
            </div>
        </div>
        :
        <div className={"tooltip-context-outer-container " + (visible === true ? "visible " : "") + (contextTooltipBg === "dark" ? "bg-colour-dark" : "bg-colour-white")}>
            <div className="tooltip-context-inner-container">
                <span className={"body-regular block-text text-align-center " + (contextTooltipBg === "dark" ? "colour-white" : "colour-primary")}>
                    {body}
                </span>
            </div>
        </div>
    )
};

export default Tooltip;