import React from 'react';

function ChevronThick(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M10.7568 4.24395C10.4436 3.91868 9.92874 3.91868 9.61564 4.24395L6 7.99999L9.61564 11.756C9.92874 12.0813 10.4436 12.0813 10.7568 11.756C11.0607 11.4403 11.0607 10.9351 10.7568 10.6194L8.23525 7.99999L10.7568 5.38057C11.0607 5.06483 11.0607 4.55968 10.7568 4.24395Z" 
                fill={fill}
            />
        </svg>
    )
};

export default ChevronThick;