import React from 'react';
import '../styles/CreativeAnalysis.css';
import { formatterLong } from '../../styles/GlobalStyles';

function CreativeAnalysis(props) {
    const customPrice = props.customPrice;
    const sellerCompensation = props.sellerCompensation;
    const monthlyPayment = props.monthlyPayment;
    const property = props.property;
    const interestCompensation = Number(sellerCompensation) - Number(customPrice);
    const oldMortgage = property.financials.mortgagePayment;

    const sellerInfo = [
        {
            label: "Sale price",
            value: formatterLong.format(customPrice).replace(".00", "")
        },
        {
            label: "Income from interest",
            value: formatterLong.format(interestCompensation).replace(".00", "")
        },
        {
            label: "Sale price + interest",
            value: formatterLong.format(sellerCompensation).replace(".00", "")
        }
    ];

    return (
        <div className="creative-analysis-container">
            <div className="creative-analysis-element creative-analysis-element-small bg-colour-grey">
                <div className="creative-analyis-element-body-container">
                    <span className="body-regular colour-primary">
                        Mortgage to seller
                    </span>
                    <div className="creative-analysis-element-body-row">
                        <span className="body-semibold colour-primary">
                            {formatterLong.format(monthlyPayment).replace(".00", "")}
                        </span>
                        <span className="body-regular colour-secondary">
                            /month
                        </span>
                    </div>
                </div>
                <div className="creative-analysis-element-footer-container">
                    <span className="body-regular colour-primary">
                        {
                            oldMortgage > monthlyPayment ?
                            "Saving "
                            :
                            "Losing "
                        }
                        {
                            oldMortgage > monthlyPayment ?
                            <span className="body-semibold colour-green">
                                {formatterLong.format(oldMortgage - monthlyPayment).replace(".00", "")}
                            </span>
                            :
                            <span className="body-semibold colour-error">
                                {formatterLong.format(monthlyPayment - oldMortgage).replace(".00", "")}
                            </span>
                        }
                    </span>
                </div>
            </div>
            <div className="creative-analysis-element creative-analysis-element-large bg-colour-grey">
                <div className="creative-analyis-element-list-row">
                    {
                        sellerInfo.map((item, index) =>
                            <div 
                                className="creative-analyis-element-body-container"
                                key={index}
                            >
                                <span className="body-regular colour-primary">
                                    {item.label}
                                </span>
                                <span className="body-semibold colour-primary">
                                    {item.value}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="creative-analysis-element-footer-container">
                    <span className="body-regular colour-primary">
                        Seller is making{" "}<span className="body-semibold colour-green">{formatterLong.format(interestCompensation).replace(".00", "")}</span>{" "}
                        <span className="body-semibold">on top</span> of the asking price.
                    </span>
                </div>
            </div>
        </div>
    )
};

export default CreativeAnalysis;