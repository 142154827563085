import React from 'react';
import '../styles/ROIFilter.css';
import { FinancialsInput, FilterReset } from './';

function ROIFilter(props) {
    const minCoCR = props.minCoCR;
    const setMinCoCR = props.setMinCoCR;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const error = props.error;

    const onChangeRoI = (e) => {
        setMinCoCR(e.target.value);
    };

    const checkNaN = (val) => {
        if ( error === true ) {
            return true;
        }
        else if ( isNaN(val) ) {
            return true;
        }
        return false;
    };

    return (
        <div className="roi-filter-container">
            <div className="roi-filter-title-container">
                <div className="roi-filter-heading-container">
                    <h3 className="body-semibold colour-primary">
                        Minimum cash on cash return
                    </h3>
                </div>
                <div className="roi-filter-input-container">
                    <FinancialsInput
                        label={null}
                        labelType={null}
                        info={false}
                        value={minCoCR}
                        onChange={onChangeRoI}
                        placeholder={"None"}
                        type="text"
                        leftUnit={null}
                        rightUnit={"%"}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(minCoCR)}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
            </div>
            <span className="body-regular colour-secondary">
                Use with caution. If you make this filter too restrictive, 
                you could miss properties with potential to increase ROI.
            </span>
            {
                error === true ?
                <span className="body-regular colour-error">
                    You entered an invalid value. Please enter a number.
                </span>
                :
                reset === true ?
                <FilterReset
                    clickFunc={() => resetFilterType("cocr")}
                />
                :
                null
            }
        </div>
    )
};

export default ROIFilter;