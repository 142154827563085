import React from 'react';
import '../styles/ContactAgent.css';

function ContactAgent(props) {
	const property = props.property;

	const formatPhoneNumber = (value) => {
		const newVal = value.replace("-", "");
		const currentValue = newVal.replace(/[^\d]/g, '');
		return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }

	return (
		<div className="contact-agent-outer-container">
			<div className="contact-agent-inner-container">
				<h2 className="body-semibold colour-primary">
					{
						property.fsbo === true ?
						"Property owner"
						:
						"Agent"
					}
				</h2>
				{
					property.listedBy.displayName === null && 
					property.attributionInfo.agentName === null &&
					property.listedBy.businessName === null &&
					property.attributionInfo.brokerName === null &&
					property.attributionInfo.agentEmail === null &&
					property.attributionInfo.agentPhoneNumber === null
					?
					<div className="contact-agent-body-container">
						<span className="body-regular colour-secondary">
							Oh no! It appears this <span className="text-italic">very busy </span>
							real estate agent forgot to include their contact
							info in the listing. Not much we can do here...
						</span>
					</div>
					:
					<div className="contact-agent-body-container">
						{
							property.fsbo === true ?
							null
							:
							<h3 className="body-regular colour-primary">
								{
									property.listedBy.displayName !== null ?
										property.listedBy.displayName
										:
										property.attributionInfo.agentName
								}
							</h3>
						}
						{
							property.fsbo === true ?
							null
							:
							<h4 className="body-regular colour-secondary">
								{
									property.listedBy.businessName !== null ?
										property.listedBy.businessName
										:
										property.attributionInfo.brokerName
								}
							</h4>
						}
						<div className="contact-agent-contact-details-container">
							{
								property.attributionInfo.agentPhoneNumber !== undefined &&
								property.attributionInfo.agentPhoneNumber !== null ?
								<a 
									href={`tel:${property.attributionInfo.agentPhoneNumber}`}
									className="body-semibold colour-primary no-underline"
								>
									{
										property.attributionInfo.agentPhoneNumber !== null ?
											property.attributionInfo.agentPhoneNumber.includes("(") ?
												property.attributionInfo.agentPhoneNumber
											:
												formatPhoneNumber(property.attributionInfo.agentPhoneNumber)
											:
											property.attributionInfo.brokerPhoneNumber.includes("(") ?
												property.attributionInfo.brokerPhoneNumber
											:
												formatPhoneNumber(property.attributionInfo.brokerPhoneNumber)
									}
								</a>
								:
								property.fsbo === true && property.fsboOwnerPhone !== undefined
								&& property.fsboOwnerPhone !== null ?
									<a 
										href={`tel:${property.fsboOwnerPhone}`}
										className="body-semibold colour-primary no-underline"
									>
										{
											property.fsboOwnerPhone.includes("(") ?
											property.fsboOwnerPhone
											:
											formatPhoneNumber(property.fsboOwnerPhone)
										}
									</a>
									:
									null
							}
							{
								property.attributionInfo.agentEmail !== undefined &&
								property.attributionInfo.agentEmail !== null
								?
								<span className="body-semibold colour-primary">
									•
								</span>
								:
								null
							}
							<a 
								href={`mailto:${property.attributionInfo.agentEmail}`}
								className="body-regular-underline colour-link"
							>
								{property.attributionInfo.agentEmail}
							</a>
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default ContactAgent;