import React from 'react';
import '../styles/MapSettings.css';
import { neighbourhoodDataOptions } from '../../styles/GlobalStyles';
import { Dropdown, Switch } from '../components';
import { ExitScreen } from '../../components';

function MapSettings(props) {
    const hideHeatmap = props.hideHeatmap;
    const toggleHideHeatmap = props.toggleHideHeatmap;
    const neighbourhoodOption = props.neighbourhoodOption;
    const mapTypeDropdownOpen = props.mapTypeDropdownOpen;
    const toggleDropdown = props.toggleDropdown;
    const selectOption = props.selectOption;

    return (
        <div className="map-settings-container relative-container">
            {
                mapTypeDropdownOpen === true &&
                <ExitScreen
                    clickScreen={() => toggleDropdown()}
                />
            }
            <div className="map-settings-switch-element">
                <Switch
                    val={!hideHeatmap}
                    setVal={() => toggleHideHeatmap()}
                    label="Show heatmap"
                />
            </div>
            <div className="map-settings-select-element">
                <Dropdown
                    open={mapTypeDropdownOpen}
                    icon={null}
                    label={hideHeatmap === true ? "Map view" : neighbourhoodDataOptions[neighbourhoodOption].label}
                    disabled={false}
                    options={neighbourhoodDataOptions}
                    multiselect={false}
                    toggleDropdown={toggleDropdown}
                    cta={selectOption}
                    sidealign={"right"}
                    topalign={"top"}
                    indexSelect={hideHeatmap === true ? false : true}
                    selectedIndex={neighbourhoodOption}
                />
            </div>
        </div>
    )
};

export default MapSettings;