import React from 'react';
import '../styles/BuyBoxName.css';
import { FinancialsInput } from './';

function BuyBoxName(props) {
    const title = props.title;
    const onChangeBuyBoxName = props.onChangeBuyBoxName;
    const error = props.error;
    return (
        <div className="buy-box-name-container">
            <div className="buy-box-name-element">
                <FinancialsInput
                    label={null}
                    labelType={null}
                    info={false}
                    value={title}
                    onChange={(event) => onChangeBuyBoxName(event.target.value)}
                    placeholder={"Name this buy box"}
                    type="text"
                    leftUnit={null}
                    rightUnit={null}
                    unitSwitch={false}
                    leftUnitSelected={true}
                    unitSwitchFunc={null}
                    disabled={false}
                    multiline={false}
                    error={false}
                    onMouseEnter={null}
                    onMouseLeave={null}
                    reset={false}
                    resetFunc={null}
                    resetPosition={null}
                />
                <span className="label-regular colour-secondary">
                    {title.length}/50 characters
                </span>
                {
                    error === true &&
                    <span className="body-regular colour-error">
                        Please choose a name for your buy box
                    </span>
                }
            </div>
        </div>
    )
};

export default BuyBoxName;