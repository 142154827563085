import React from 'react';

function Exit(props) {
    const className = props.className;
    const fill = props.fill;
    const clickFunc = props.clickFunc;
    return (
        <svg 
            width="15" 
            height="16" 
            viewBox="0 0 15 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={() => clickFunc === null ? null : clickFunc()}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M15.0795 1.44782C15.4 1.09182 15.3713 0.543356 15.0153 0.222805C14.6592 -0.0977454 14.1108 -0.0690019 13.7902 0.287006L7.99975 6.718L2.20929 0.287037C1.88874 -0.0689705 1.34028 -0.097714 0.984271 0.222837C0.628264 0.543388 0.59952 1.09185 0.920071 1.44785L6.83254 8.01432L0.945855 14.5521C0.625304 14.9081 0.654048 15.4566 1.01006 15.7772C1.36606 16.0977 1.91452 16.069 2.23507 15.713L7.99975 9.31064L13.7645 15.713C14.085 16.069 14.6335 16.0977 14.9895 15.7772C15.3455 15.4566 15.3742 14.9082 15.0537 14.5522L9.16696 8.01432L15.0795 1.44782Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Exit;