import { SingleFamily, MultiFamily } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';

export const defaultVals = {
    propertyTypes: [
        {
            label: "Single-Family",
            icon: <SingleFamily className="" fill={colour.blueBlue03} />,
            body: null,
			included: false,
            standardIcon: false,
			code: "SingleFamily",
			abbreviation: "SFH"
        },
        {
            label: "Multi-Family",
            icon: <MultiFamily className="" fill={colour.blueBlue03} />,
            body: null,
			included: false,
            standardIcon: false,
			code: "MultiFamily",
			abbreviation: "MFH"
        }
    ],
    units: 0,
    unitExactMatch: false,
    budgetRange: [0, 1000000000],
    bedrooms: 0,
    bedroomExactMatch: false,
    bathrooms: 0,
    bathroomExactMatch: false,
    squareFootage: [0, 999999],
    constructionYears: ["", ""],
    conditionValues: [],
    creativeFinanceType: "",
    listingType: "",
    daysOnMarket: "",
    backOnMarket: false,
    priceReduction: false,
    neighbourhoodGrades: [1, 8],
    zipcodes: [],
    hideFloodzone: false,
    hideHOA: false,
    onlyADU: false,
    onlyExtraBedroom: false,
    oversizedLot: false,
    ownerType: "",
    ownershipLength: 0,
    distressFlags: "",
    estimatedInterest: "",
    equityLevel: "",
    mortgageType: "",
    cocr: ""
}