import React from 'react';
import '../styles/MobileBottomNav.css';
import { colour } from '../styles/GlobalStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Home, Heart, UserCircle } from '../assets/icons';
import { PackageOpenIcon } from 'hugeicons-react';
import { MobileTab } from '../v4/components';

function MobileBottomNav() {
    const pathname = window.location.pathname;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const links = [
        {
            label: "Home",
            link: "/",
            icon: <Home fill={pathname === "/home" || pathname === "/" ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: pathname === "/" || pathname === "/home" ? true : false
        },
        {
            label: "Buy Boxes",
            link: "/buy-boxes",
            icon: <PackageOpenIcon color={pathname.includes("/buy-box") ? colour.grayScaleBlack : colour.blueBlue03} size={16} />,
            active: pathname.includes("/buy-box") ? true : false
        },
        {
            label: "Properties",
            link: "/properties",
            icon: <Heart className="" fill={pathname === "/properties" || pathname.includes("/list") ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: pathname === "/properties" || pathname.includes("/list") ? true : false
        },
        {
            label: "Me",
            link: "/profile",
            icon: <UserCircle className="" fill={pathname === "/profile" ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: pathname === "/profile" ? true : false
        }
    ];

    const goToLink = (item) => {
        navigate(item.link, { 
            state: state 
        });
    };

    return (
        <div className="mobile-bottom-nav-outer-container bg-colour-white">
            {
                links.map((item, index) =>
                    <MobileTab
                        item={item}
                        index={index}
                        title={item.label}
                        active={item.active}
                        cta={() => goToLink(item)}
                        icon={item.icon}
                        disabled={false}
                        line={false}
                        key={index}
                    />
                )
            }
        </div>
    )
};

export default MobileBottomNav;