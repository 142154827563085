import React from 'react';
import '../styles/SettingsSection.css';
import { MobileLink, SettingsElement, CustomSettingElement, Button } from './';
import { MarketSearch } from '../modalbody';
import { defaultUserData } from '../../styles/GlobalStyles';

function SettingsSection(props) {
    const title = props.title;
    const subtitle = props.subtitle;
    const data = props.data;
    const ref = props.sectionRef;
    const dropdownOpen = props.dropdownOpen;
    const setDropdownOpen = props.setDropdownOpen;
    const selectedPanel = props.selectedPanel;
    const mobileOption = props.mobileOption;
    const selectMobileOption = props.selectMobileOption;
    const innerWidth = window.innerWidth;
    const customCities = props.customCities;
    const setCustomCities = props.setCustomCities;
    const customCityDropdownOpen = props.customCityDropdownOpen;
    const setCustomCityDropdownOpen = props.setCustomCityDropdownOpen;
    const customSettingChangeVal = props.customSettingChangeVal;
    const saveCustomSetting = props.saveCustomSetting;
    const defaultCustomCities = props.defaultCustomCities;
    const deleteCustomCity = props.deleteCustomCity;
    const openMarketModal = props.openMarketModal;
    const city = props.city;
    const finalResults = props.finalResults;
    const activeSearching = props.activeSearching;
    const changeCity = props.changeCity;
    const pickGeography = props.pickGeography;
    const customSetting = title === "Custom" ? true : false;

    const monthlyCostFields = [
        {
            title: "Insurance",
            description: "The default is based on the average for each city according to Zillow.",
            placeholder: "city based",
            defaultSwitch: true,
            dropdown: false,
            input: true,
            disabled: false,
            size: "medium",
            leftUnit: null,
            rightUnit: "%",
            defaultVal: defaultUserData.settings.recurringCosts[0].value
        },
        {
            title: "Property taxes",
            description: "Based on Zillow's property tax estimates. This usually does NOT take into account any tax increase after you purchase the property. Please check county websites for this data (we're working on aggregating them!)",
            placeholder: "city based",
            defaultSwitch: true,
            dropdown: false,
            input: true,
            disabled: false,
            size: "medium",
            leftUnit: null,
            rightUnit: "%",
            defaultVal: defaultUserData.settings.recurringCosts[1].value
        },
        {
            title: "Vacancy rate allocation",
            description: "Based on the FRED (Federal Reserve Economic Data) average vacancy rate for the city.",
            placeholder: "city based",
            defaultSwitch: true,
            dropdown: false,
            input: true,
            disabled: false,
            size: "medium",
            leftUnit: null,
            rightUnit: "%",
            defaultVal: defaultUserData.settings.recurringCosts[2].value
        },
        {
            title: "Management fees",
            description: "The default is 9% of your rental income.",
            placeholder: "9",
            defaultSwitch: true,
            dropdown: false,
            input: true,
            disabled: false,
            size: "medium",
            leftUnit: null,
            rightUnit: "%",
            defaultVal: defaultUserData.settings.recurringCosts[3].value
        },
        {
            title: "Maintenance",
            description: "The default is 2% of your rental income.",
            placeholder: "2",
            defaultSwitch: true,
            dropdown: false,
            input: true,
            disabled: false,
            size: "medium",
            leftUnit: null,
            rightUnit: "%",
            defaultVal: defaultUserData.settings.recurringCosts[4].value
        },
        {
            title: "Other costs",
            description: "Account for any additional monthly recurring costs.",
            placeholder: "0",
            defaultSwitch: false,
            dropdown: false,
            input: true,
            disabled: false,
            size: "medium",
            leftUnit: null,
            rightUnit: "%",
            defaultVal: defaultUserData.settings.recurringCosts[5].value
        }
    ]

    const addSetting = (index) => {
        const newCustomCities = [...customCities];
        const newObject = {
            title: "",
            value: ""
        };
        newCustomCities[index].settings.push(newObject);
        setCustomCities(newCustomCities);
    };

    const chooseSetting = (cityIndex, settingIndex, fieldIndex) => {
        const newCustomCities = [...customCities];
        const fieldTitle = monthlyCostFields[fieldIndex].title;
        newCustomCities[cityIndex].settings[settingIndex].title = fieldTitle;
        setCustomCities(newCustomCities);
    };

    return (
        <div 
            className="settings-section-container"
            ref={ref}
        >
            {
                innerWidth < 1000 && mobileOption !== null ?
                null
                :
                <div className="settings-section-title-container">
                    <h3 className="heading-small-regular colour-secondary">
                        {title}
                    </h3>
                    {
                        subtitle !== null &&
                        <h4 className="body-regular colour-primary">
                            {subtitle}
                        </h4>
                    }
                </div>
            }
            <div className="settings-spacing-container mobile-none">
            </div>
            {
                customSetting === true && innerWidth > 1000 &&
                <MarketSearch
                    city={city} 
                    changeCity={changeCity} 
                    finalResults={finalResults} 
                    activeSearching={activeSearching} 
                    pickGeography={pickGeography}
                    extraPadding={data.length < 1 ? true : false}
                />
            }
            {
                customSetting === true &&
                <div className="settings-spacing-container mobile-none">
                </div>
            }
            <div className="settings-section-body-container mobile-none">
                {
                    data.map((item, index) =>
                        <React.Fragment 
                            key={index}
                        >
                            {
                                customSetting === false ?
                                <SettingsElement
                                    item={item}
                                    dropdownOpen={dropdownOpen}
                                    setDropdownOpen={setDropdownOpen}
                                    innerWidth={innerWidth}
                                />
                                :
                                <CustomSettingElement
                                    item={item}
                                    customIndex={index}
                                    innerWidth={innerWidth}
                                    dropdownOpen={customCityDropdownOpen}
                                    setDropdownOpen={setCustomCityDropdownOpen}
                                    removeCustomCity={deleteCustomCity}
                                    chooseSetting={chooseSetting}
                                    addSetting={addSetting}
                                    data={data[index]}
                                    customSettingChangeVal={customSettingChangeVal}
                                    saveCustomSetting={saveCustomSetting}
                                    monthlyCostFields={monthlyCostFields}
                                    customCities={customCities}
                                    defaultCustomCities={defaultCustomCities}
                                    customCityDropdownOpen={customCityDropdownOpen}
                                />
                            }
                            <div className="settings-spacing-container"></div>
                        </React.Fragment>
                    )
                }
            </div>
            {
                mobileOption === null &&
                <div className="settings-section-body-container desktop-none">
                    {
                        data.map((item, index) =>
                            <React.Fragment 
                                key={index}
                            >
                                <MobileLink
                                    label={customSetting === true ? item.label : item.title}
                                    index={index}
                                    cta={() => selectMobileOption(selectedPanel, index, customSetting)}
                                />
                            </React.Fragment>
                        )
                    }
                </div>
            }
            {
                mobileOption !== null && selectedPanel === mobileOption[0] && mobileOption[2] === false &&
                <SettingsElement
                    item={data[mobileOption[1]]}
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                    innerWidth={innerWidth}
                />
            }
            {
                mobileOption !== null && selectedPanel === mobileOption[0] && mobileOption[2] === true && customSetting === true &&
                <CustomSettingElement
                    item={data[mobileOption[1]]}
                    customIndex={mobileOption[1]}
                    innerWidth={innerWidth}
                    dropdownOpen={customCityDropdownOpen}
                    setDropdownOpen={setCustomCityDropdownOpen}
                    removeCustomCity={deleteCustomCity}
                    chooseSetting={chooseSetting}
                    addSetting={addSetting}
                    data={data[mobileOption[1]]}
                    customSettingChangeVal={customSettingChangeVal}
                    saveCustomSetting={saveCustomSetting}
                    monthlyCostFields={monthlyCostFields}
                    customCities={customCities}
                    defaultCustomCities={defaultCustomCities}
                    customCityDropdownOpen={customCityDropdownOpen}
                />
            }
            {
                customSetting === true && mobileOption === null &&
                <div className="settings-market-button-container relative-container desktop-none">
                    <Button
                        buttonType="primary-button"
                        size="large"
                        text={"New custom setting"}
                        cta={() => openMarketModal()}
                        disabled={false}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={false}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            }
        </div>
    )
};

export default SettingsSection;