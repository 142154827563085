import React, { useEffect, useState } from 'react';
import '../styles/GodMode.css';
import { checkOnAuthStateChanged, cloudFunctionV2, getDocument, makeId, setData, getCountySearch, queryAllSubscriptions } from '../functions';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Footer, Header, Loading, LoadingStatic, SideNavigation } from '../components';
import { FilterCityInput } from '../styles/GlobalStyles';
import moment from 'moment';
import { Exit, Search } from '../assets';
import { counties } from '../counties';
import { Button, FilterChip } from '../v4/components';

function GodMode() {

    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedOption, setSelectedOption] = useState(0);
    const [waitingRoom, setWaitingRoom] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [county, setCounty] = useState("");
    const [countyOptions, setCountyOptions] = useState([]);
    const [subsLoading, setSubsLoading] = useState(false);
    const [lastSubs, setLastSubs] = useState([]);
    const getTab = searchParams.get('tab');
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const options = [
        "Add a county",
        "Waiting room"
    ];

    useEffect(() => {
        document.title = "God Mode";
        const fetchUser = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                if ( JSON.parse(process.env.REACT_APP_ADMINIDS).indexOf(userId) !== -1 ) {
                    if ( getTab !== null ) {
                        setSelectedOption(Number(getTab));
                    }

                    const colRef = "Users";
                    const queryData = await getDocument(colRef, userId);
                    if ( queryData.status === 200 ) {
                        setUserDetails(queryData.data.data);
                        setLoading(false);

                        const waitingRoomColRef = "Waiting Room";
                        const waitingRoomDocRef = "Users";
                        const queryWaitingRoomData = await getDocument(waitingRoomColRef, waitingRoomDocRef);
                        if ( queryWaitingRoomData.status === 200 ) {
                            setWaitingRoom(queryWaitingRoomData.data.data);
                        }
                    }
                }
                else {
                    navigate("/");
                }
            }
            else {
                navigate("/");
            }
        };

        fetchUser();
    }, [navigate, getTab]);

    const getLast5Subs = async() => {
        setSubsLoading(true);
        const queryData = await queryAllSubscriptions();
        if ( queryData.status === 200 ) {
            const subData = queryData.data;
            const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
            const filteredData = [];
            for (let index = 0; index < subData.length; index++) {
                const element = subData[index];
                if ( adminIds.indexOf(element.userId) === -1 ) {
                    filteredData.push(element);
                }
            }
            
            filteredData.sort((a,b) => (a.startDate._seconds < b.startDate._seconds) ? 1 : ((b.startDate._seconds < a.startDate._seconds) ? -1 : 0));
            const lastData = filteredData.slice(0, 5);
            setLastSubs(lastData);
            setSubsLoading(false);
        }
        else {
            alert("ERROR: Reload page and try again");
        }
    };

    const changeCounty = (val) => {
        setCounty(val);

        const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
        };
        setCountyOptions(newArray);
    };

    const selectCounty = async(item) => {
        setCounty("");
        setDisabled(true);
        const countyParams = {
            countyId: `CTY${item.code}`
        }
		const getCountyParams = await getCountySearch(countyParams);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "county",
			queryString: getCountyParams
		};
		const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getCityDetails.status === 200 ) {
            const body = getCityDetails.body;
            body.price = item.price;
            const msaCityList = [];
            const msaCityIDs = [];
            for (let index = 0; index < body.msaCityList.length; index++) {
                const element = body.msaCityList[index];
                const newObject = {
                    city: element.city,
                    state: element.state,
                    cityId: element.cityId
                };
                msaCityList.push(newObject);
                msaCityIDs.push(element.cityId);
            }

            const today = new Date();
            const endDate = moment(today).add(1, "years");
            const id = await makeId(10);

            const newObject = {
                allCities: false,
                autoBilling: false,
                endDate: endDate._d,
                metroArea: true,
                msaTitle: body.countyName,
                msaCode: body.countyId,
                metroCities: msaCityList,
                msaCityIds: msaCityIDs,
                price: 0,
                id: id,
                startDate: today
            };
            setCities([...cities, newObject]);
        }
        setDisabled(false);
    };

    const deleteListing = (index) => {
        const citiesClone = [...cities];
        citiesClone.splice(index, 1);
        setCities(citiesClone);
    };

    const complete = async() => {
        setDisabled(true);
        const colRef = "Buy Boxes";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            let buyBoxes = [];
            const getBuyBoxes = await getDocument(colRef, docRef);
            if ( getBuyBoxes.status === 200 ) {
                buyBoxes = getBuyBoxes.data.data;
            }

            for (let index = 0; index < cities.length; index++) {
                const element = cities[index];
                const cityIdIndex = buyBoxes.findIndex(e => element.metroArea === true ? e.msaCode === element.msaCode : e.cityId === element.cityId);
                if ( cityIdIndex === -1 ) {
                    // SETTING LOADED TO TRUE ONLY FOR GOD MODE
                    const newObject = {
                        buyBoxes: [],
                        metroArea: element.metroArea,
                        loaded: true
                    }

                    if ( element.metroArea === true ) {
                        newObject.msaTitle = element.msaTitle;
                        newObject.msaCode = element.msaCode;
                        newObject.metroCities = element.metroCities;
                        newObject.msaCityIds = element.msaCityIds;
                    }
                    else {
                        newObject.city = element.city;
                        newObject.state = element.state;
                        newObject.cityId = element.cityId;
                    }
                    buyBoxes.push(newObject);
                }
            }

            await setData(colRef, docRef, buyBoxes);

            const newSubscriptions = [];
            let subscriptionArray = [];
            const subscriptionColRef = "Subscriptions";
            const querySubscriptionData = await getDocument(subscriptionColRef, docRef);
            if ( querySubscriptionData.status === 200 ) {
                subscriptionArray = querySubscriptionData.data.data;
            }

            for (let index = 0; index < cities.length; index++) {
                const element = cities[index];
                const newSubscription = {
                    allCities: false,
                    autoBilling: false,
                    endDate: element.endDate,
                    id: element.id,
                    metroArea: element.metroArea,
                    price: element.price,
                    startDate: element.startDate
                };

                newSubscription.msaTitle = element.msaTitle;
                newSubscription.msaCode = element.msaCode;
                newSubscription.metroCities = element.metroCities;
                newSubscription.msaCityIds = element.msaCityIds;

                subscriptionArray.push(newSubscription);
                newSubscriptions.push(newSubscription);
            }
            await setData(subscriptionColRef, docRef, subscriptionArray);
            navigate("/home");
        }
        setDisabled(false);
    };

    const changeSelectedPanel = (index) => {
        setSelectedOption(index);
        setSearchParams({tab: index});
    };

    return (
        <div className="god-mode-outer-container bg-colour-white">
            <Header
                subscriptions={[]}
                users={userDetails}
                queries={[false, true]}
                mobileNav={true}
                desktopControl={true}
            />
            {
                loading === true ?
                <div className="god-mode-inner-container">
                    <Loading />
                </div>
                :
                <div className="god-mode-outer-row-container">
                    <SideNavigation
                        navigate={navigate}
                        state={state}
                        userData={userDetails}
                        subscriptions={[]}
                    />
                    <div className="god-mode-inner-container">
                        <div className="god-mode-title-container margin-large">
                            <h1 className="heading-large-semibold colour-primary margin-x-small text-align-center">
                                Welcome to God mode
                            </h1>
                            <span className="body-regular colour-secondary block-text text-align-center">
                                (blasphemy intended)
                            </span>
                        </div>
                        {
                            disabled === true ?
                            <Loading />
                            :
                            null
                        }
                        <div className="god-mode-options-row margin-large">
                            {
                                options.map((item, index) =>
                                    <FilterChip
										label={item}
										selected={selectedOption}
										index={index}
										disabled={false}
										leftIcon={null}
										rightIcon={null}
										func={changeSelectedPanel}
										key={index}
									/>
                                )
                            }
                        </div>
                        {
                            selectedOption === 0 ?
                            <div className="god-mode-body-container margin-large">
                                <span className="body-regular colour-primary block-text margin-x-small">
                                    Pick the counties you'd like to add to your account
                                </span>
                                <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                                    <FilterCityInput
                                        value={county}
                                        type="text"
                                        id="upgrade-city-input"
                                        placeholder="Search for any county in the U.S..."
                                        onChange={(text) => changeCounty(text.target.value)}
                                        disabled={false}
                                    />
                                    <img
                                        src={county === "" ? Search : Exit}
                                        className={county === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                                        alt="Search"
                                    />
                                    {
                                        countyOptions.length > 0 && county !== "" ?
                                            <div className="city-filtering-outer-container">
                                                {
                                                    countyOptions.map((item, index) =>
                                                        <div 
                                                            className="city-filtering-element"
                                                            key={index}
                                                            onClick={() => selectCounty(item)}
                                                        >
                                                            <span className="body-regular colour-primary">
                                                                {item.county}{item.state === "LA" ? "" : " County"}, {item.state} (${item.price})
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            :
                            <div className="god-mode-waiting-room-body-container margin-large">
                                {
                                    waitingRoom.map((item, index) =>
                                        <div 
                                            className="god-mode-waiting-room-element margin-medium"
                                            key={index}
                                        >
                                            <span className="body-regular colour-primary block-text margin-x-small">
                                                {index + 1}{")"} {item.city}, {item.state}
                                            </span>
                                            <span className="body-regular colour-primary block-text margin-x-small">
                                                City ID: {item.cityId}
                                            </span>
                                            {
                                                item.users.map((user, userIndex) =>
                                                    <div 
                                                        className="god-mode-waiting-room-user margin-x-small"
                                                        key={userIndex}
                                                    >
                                                        <span className="body-regular colour-primary block-text margin-x-small">
                                                            {user.email}
                                                        </span>
                                                        <span className="body-regular colour-primary block-text margin-x-small">
                                                            {user.userId}
                                                        </span>
                                                        <span className="body-regular colour-primary block-text margin-x-small">
                                                            {moment.unix(user.date.seconds).format("HH:mm on DD MMMM YYYY")}
                                                        </span>
                                                    </div>)
                                            }
                                        </div>
                                    )
                                }
                                <div className="god-mode-last-five-subs-container">
                                    {
                                        subsLoading === true ?
                                        <LoadingStatic />
                                        :
                                        lastSubs.length === 0 ?
                                        <div className="">
                                            <Button
                                                buttonType="primary-button"
                                                size="large"
                                                text={"Get last 5 subscriptions"}
                                                cta={() => getLast5Subs()}
                                                disabled={false}
                                                leftIcon={null}
                                                leftIconClass=""
                                                rightIcon={null}
                                                rightIconClass=""
                                                alt=""
                                                standardIcon={false}
                                                badge={false}
                                                badgeVal={null}
                                                badgeBG={null}
                                                buttonRef={null}
                                            />
                                        </div>
                                        :
                                        <div className="god-mode-last-five-list">
                                            <h3 className="body-semibold colour-primary margin-medium">
                                                Last 5 subscriptions 
                                            </h3>
                                            {
                                                lastSubs.map((item, index) =>
                                                    <div 
                                                        className="margin-large"
                                                        key={index}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            {index + 1}) {item.metroArea === true ? `${item.msaTitle} (${item.msaCode})` : `${item.city}, ${item.state} (${item.cityId})`}
                                                            {"  "}-{"  "}
                                                            {moment.unix(item.startDate._seconds).format("HH:mm on DD MMMM YYYY")}
                                                        </span>
                                                        <br/>
                                                        <span className="body-regular colour-primary">
                                                            USER ID - {item.userId}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            selectedOption < 3 ?
                            <div className="god-mode-results-container">
                                <div className="god-mode-city-list-container margin-large margin-top-medium">
                                    {
                                        cities.map((item, index) =>
                                            <div 
                                                className="god-mode-city-list-item margin-medium"
                                                key={index}
                                            >
                                                {
                                                    item.metroArea === true ?
                                                    <span className="body-regular colour-primary block-text margin-x-small">
                                                        {item.msaTitle} - {item.msaCode}
                                                    </span>
                                                    :
                                                    <span className="body-regular colour-primary block-text margin-x-small">
                                                        {item.city}, {item.state} - {item.cityId}
                                                    </span>
                                                }
                                                <span 
                                                    className="body-regular colour-error cursor-pointer"
                                                    onClick={() => deleteListing(index)}
                                                >
                                                    Delete
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    selectedOption === 0 &&
                                    <div className="god-mode-button-container">
                                        <Button
                                            buttonType="primary-button"
                                            size="large"
                                            text={"Complete"}
                                            cta={() => complete()}
                                            disabled={disabled === true ? true : loading === true ? true : cities.length === 0 ? true : false}
                                            leftIcon={null}
                                            leftIconClass=""
                                            rightIcon={null}
                                            rightIconClass=""
                                            alt=""
                                            standardIcon={false}
                                            badge={false}
                                            badgeVal={null}
                                            badgeBG={null}
                                            buttonRef={null}
                                        />
                                    </div>
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default GodMode;