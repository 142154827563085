import React from 'react';

function UserCircle(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M1.83398 8.00004C1.83398 4.59428 4.5949 1.83337 8.00065 1.83337C11.4064 1.83337 14.1673 4.59428 14.1673 8.00004C14.1673 11.4058 11.4064 14.1667 8.00065 14.1667C4.5949 14.1667 1.83398 11.4058 1.83398 8.00004ZM8.00065 0.833374C4.04261 0.833374 0.833984 4.042 0.833984 8.00004C0.833984 11.9581 4.04261 15.1667 8.00065 15.1667C11.9587 15.1667 15.1673 11.9581 15.1673 8.00004C15.1673 4.042 11.9587 0.833374 8.00065 0.833374ZM6.8258 6.33354C6.8258 5.68988 7.34856 5.16687 7.99482 5.16687C8.64116 5.16687 9.16389 5.68988 9.16389 6.33354C9.16389 6.97718 8.64116 7.5002 7.99482 7.5002C7.34856 7.5002 6.8258 6.97719 6.8258 6.33354ZM7.99482 4.16687C6.79762 4.16687 5.8258 5.13625 5.8258 6.33354C5.8258 7.53082 6.79762 8.5002 7.99482 8.5002C9.19209 8.5002 10.1639 7.53082 10.1639 6.33354C10.1639 5.13624 9.19209 4.16687 7.99482 4.16687ZM5.36213 11.6788C6.72907 10.2471 9.26871 10.1923 10.6328 11.6724C10.82 11.8754 11.1363 11.8883 11.3393 11.7012C11.5424 11.5141 11.5553 11.1977 11.3682 10.9947C9.58987 9.06519 6.38085 9.16371 4.63885 10.9883C4.44816 11.188 4.45548 11.5045 4.65521 11.6952C4.85494 11.8859 5.17144 11.8785 5.36213 11.6788Z" 
                fill={fill}
            />
        </svg>
    )
};

export default UserCircle;