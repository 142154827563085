import React, { useState, useEffect } from 'react';
import '../styles/ForgotPassword.css';
import { Header, Footer, Loading } from '../components';
import { cloudFunctionV2, recordEvent } from '../functions/index';
import { Button, FinancialsInput } from '../v4/components';
import { useLocation } from 'react-router-dom';

function ForgotPassword() {
    const location = useLocation();
    const state = location.state;
    const [email, setEmail] = useState(state === null ? "" : state.email === undefined ? "" : state.email);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        document.title = "Coffee Clozers | Forgot Password";
    }, []);

    const passwordRecovery = async() => {
        setDisabled(true);

        const origin = window.location.origin;

        const gatewayURL = process.env.REACT_APP_PASSWORD_RESET;
        const params = {
            email: email,
            returnURL: `${origin}/reset-password`,
            subject: "Password reset"
        };
        const queryData = await cloudFunctionV2(gatewayURL, params);
        if ( queryData.status === 200 ) {
            setErrorMessage("");

            recordEvent("Password Forgotten", {
                email: email
            });

            setSuccess(true);
            setDisabled(false);
        }
        else {
            if ( queryData.error.code === "auth/email-not-found" ) {
                setErrorMessage("Email not found 🤔");
			}
            else if ( queryData.error.code === "auth/invalid-email" ) {
                setErrorMessage("Please enter a valid email 👍");
            }
			else {
                console.log("queryData = ", queryData);
			}
            setDisabled(false);
        }
	};

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div className="forgot-password-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="forgot-password-inner-container relative-container">
                {
                    disabled === true &&
                    <Loading />
                }
                <div className="forgot-password-title-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
					    Forgot your password?
				    </h1>
                </div>
                <div className="forgot-password-input-container">
                    <FinancialsInput
						label={"Email"}
						labelType={"inside"}
						info={false}
						value={email}
						onChange={onChangeEmail}
						placeholder={"youremail@email.com"}
						type="email"
						leftUnit={null}
						rightUnit={null}
						unitSwitch={false}
						leftUnitSelected={true}
						unitSwitchFunc={null}
						disabled={false}
						multiline={false}
						error={false}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
					/>
                    {
                        errorMessage !== "" ?
                        <div className="forgot-password-success-message-container">
                            <span className="body-regular colour-error">
                                {errorMessage}
                            </span>
                        </div>
                        :
                        success === true ?
                        <div className="forgot-password-success-message-container">
                            <span className="body-regular colour-primary">
                                We just sent you an email 😊
                            </span>
                        </div>
                        :
                        null
                    }
				</div>
                <div className="forgot-password-button-container margin-x-x-large">
                    <Button
                        buttonType="primary-button"
                        size="large"
                        text={"Submit"}
                        cta={passwordRecovery}
                        disabled={email === "" || disabled === true ? true : false}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={true}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword;