import React from 'react';
import '../styles/OpportunitiesFilter.css';
import { ADU, Bedroom, LotSize } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { Switch } from './';

function OpportunitiesFilter(props) {
    const onlyADU = props.onlyADU;
    const setOnlyADU = props.setOnlyADU;
    const onlyExtraBedroom = props.onlyExtraBedroom;
    const setOnlyExtraBedroom = props.setOnlyExtraBedroom;
    const oversizedLot = props.oversizedLot;
    const setOversizedLot = props.setOversizedLot;

    const toggleADU = () => {
        setOnlyADU(!onlyADU);
    };

    const toggleExtraBedroom = () => {
        setOnlyExtraBedroom(!onlyExtraBedroom);
    };

    const toggleOversizedLot = () => {
        setOversizedLot(!oversizedLot);
    };

    return (
        <div className="opportunities-filter-container">
            <div className="opportunities-filter-element">
                <div className="opportunities-filter-title-container">
                    <ADU
                        fill={colour.grayScaleBlack}
                    />
                    <h3 className="body-semibold colour-primary">
                        Accessory Dwelling Unit (ADU)
                    </h3>
                </div>
                <Switch
                    val={onlyADU}
                    setVal={() => toggleADU()}
                    label="Show ONLY properties with ADUs or ADU zoning"
                />
            </div>
            <div className="opportunities-filter-element">
                <div className="opportunities-filter-title-container">
                    <Bedroom
                        className=""
                        fill={colour.grayScaleBlack}
                    />
                    <h3 className="body-semibold colour-primary">
                        Add a bedroom
                    </h3>
                </div>
                <Switch
                    val={onlyExtraBedroom}
                    setVal={() => toggleExtraBedroom()}
                    label="Show ONLY properties with add a bedroom"
                />
            </div>
            <div className="opportunities-filter-element">
                <div className="opportunities-filter-title-container">
                    <LotSize
                        className=""
                        fill={colour.grayScaleBlack}
                    />
                    <h3 className="body-semibold colour-primary">
                        Oversized lot
                    </h3>
                </div>
                <span className="body-regular colour-secondary">
                    Find properties with larger-than-average lot sizes 
                    and conditions that make them more likely to be subdivided.
                </span>
                <Switch
                    val={oversizedLot}
                    setVal={() => toggleOversizedLot()}
                    label="Show ONLY properties with oversized lots"
                />
            </div>
        </div>
    )
};

export default OpportunitiesFilter;