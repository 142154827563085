import React from 'react';
import '../styles/ZeroState.css';
import { Button } from './';

function ZeroState(props) {
    const icon = props.icon;
    const standardIcon = props.standardIcon;
    const title = props.title;
    const subtitle = props.subtitle;
    const showButton = props.showButton;
    const buttonText = props.buttonText;
    const actionButtonCTA = props.actionButtonCTA;

    return (
        <div className="zero-state-outer-container">
            <div className="zero-state-inner-container">
                {
                    icon !== null && standardIcon === true ?
                    <img
                        src={icon}
                        className="zero-state-icon"
                        alt={title}
                    />
                    :
                    icon !== null && standardIcon === false ?
                    icon
                    :
                    null
                }
                <h2 className="heading-small-semibold colour-primary text-align">
                    {title}
                </h2>
                <span className="body-regular colour-secondary block-text text-align-center">
                    {subtitle}
                </span>
                {
                    showButton &&
                    <Button
                        buttonType="primary-button"
                        size="large"
                        text={buttonText}
                        cta={actionButtonCTA}
                        disabled={false}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={true}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                }
            </div>
        </div>
    );
};

export default ZeroState;