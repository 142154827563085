import React from 'react';

function TickCircle(props) {
    const fill = props.fill;
    
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.7265 6.15277C13.106 5.75974 13.0884 5.13974 12.6872 4.76795C12.286 4.39617 11.6531 4.41339 11.2735 4.80642L6.14675 10.1155L4.70711 8.70528C4.31658 8.32273 3.68342 8.32273 3.29289 8.70528C2.90237 9.08784 2.90237 9.70808 3.29289 10.0906L5.45956 12.2131C5.6505 12.4001 5.91055 12.5036 6.18055 12.4999C6.45056 12.4962 6.70757 12.3857 6.89313 12.1936L12.7265 6.15277Z" 
                fill={fill}
            />
        </svg>
    )
};

export default TickCircle;