import React from 'react';

function Home(props) {
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="17" 
            viewBox="0 0 16 17" 
            fill="none"
        >
            <path 
                d="M8.474 0.447873C8.21307 0.184042 7.78693 0.184042 7.526 0.447873L0.144499 7.91139C-0.0496817 8.10773 -0.0479326 8.4243 0.148405 8.61849C0.344743 8.81267 0.661321 8.81092 0.855501 8.61458L8 1.3907L15.1445 8.61458C15.3387 8.81092 15.6553 8.81267 15.8516 8.61849C16.0479 8.4243 16.0497 8.10773 15.8555 7.91139L8.474 0.447873Z" 
                fill={fill}
            />
            <path 
                d="M2.66178 8.85947C2.62302 8.58606 2.36995 8.39584 2.09655 8.4346C1.82314 8.47336 1.63292 8.72643 1.67168 8.99984L2.44252 14.437C2.57069 15.3411 3.3446 16.013 4.2577 16.013H11.7424C12.6555 16.013 13.4294 15.3411 13.5576 14.437L14.3284 8.99984C14.3672 8.72643 14.177 8.47336 13.9036 8.4346C13.6302 8.39584 13.3771 8.58606 13.3383 8.85947L12.5675 14.2966C12.5092 14.7076 12.1575 15.013 11.7424 15.013H4.2577C3.84265 15.013 3.49088 14.7076 3.43262 14.2966L2.66178 8.85947Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Home;