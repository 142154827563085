import React from 'react';
import '../styles/FilterSpacing.css';

function FilterSpacing() {
    return (
        <div className="filter-spacing-container">
            <div className="filter-spacing-border">
            </div>
        </div>
    );
};

export default FilterSpacing;