import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pennyThoughtsAction } from '../actions';
import '../styles/PennyThoughts.css';
import { Header, Footer } from '../components';
import { setData, makeId, checkUserId, getIp, sendEmail, recordEvent } from '../functions/index';
import { useNavigate } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';
import { Button, FinancialsInput } from '../v4/components';

function PennyThoughts() {
	const pennyThoughts = useSelector(state => state.pennyThoughts);
	const [disabled, setDisabled] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const contactEmail = "liam.maher@coffeeclozers.com";
	const dynamicTemplate = "d-38f45cfd953d4dc0af3ca5204bd72b64";

	useEffect(() => {
		document.title = "Coffee Clozers | Penny 4 Thoughts";
	});

	const complete = async() => {
		setDisabled(true);
		const colRef = "Penny For Thoughts";
		const docRef = await makeId(15);
		const user = await checkUserId();
		const ipAddress = await getIp();
		const data = {
			thought: pennyThoughts,
			user: user.userId,
			ip: ipAddress.error === true ? "" : ipAddress,
			date: new Date()
		};

		const writeData = await setData(colRef, docRef, data);
		recordEvent("User Feedback Submitted", {
			thought: pennyThoughts,
			user: user.userId,
			ip: ipAddress.error === true ? "" : ipAddress,
			date: new Date()
		
		});
		if ( writeData.status === 200 ) {
			const msg = {
				to: [contactEmail, "ariel.herrera@coffeeclozers.com", "maddy@coffeeclozers.com"],
				from: contactEmail,
				templateId: dynamicTemplate,
				dynamic_template_data: {
					thought: pennyThoughts,
					userId: user.userId,
					email: user.email,
					isMobile: isMobile === true ? "true" : "false",
					isBrowser: isBrowser === true ? "true" : "false",
					creative: false
				}
			};
			const emailAPI = await sendEmail(msg);
			console.log("email = ", emailAPI);
			alert("Thank you for sharing your thoughts!");
			dispatch(pennyThoughtsAction(""));
			navigate('/');
		}
		else {
			console.log(`There was an error: ${writeData.message}`);
			console.log(`data: ${data}`);
		}
		setDisabled(false);
	};

	return (
		<div className="penny-thoughts-outer-container bg-colour-white">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
				desktopControl={true}
			/>
			<div className="penny-thoughts-inner-container">
				<div className="penny-thoughts-title-container">
					<h1 className="heading-large-semibold colour-primary text-align-center">
						Penny for your thoughts
					</h1>
					<h2 className="body-regular colour-primary text-align-center">
						Does our product suck? let us know here.
					</h2>
				</div>
				<div className="penny-thoughts-body-container">
					<FinancialsInput
						label={null}
						labelType={null}
						info={false}
						value={pennyThoughts}
						onChange={(text) => dispatch(pennyThoughtsAction(text.target.value))}
						placeholder={"so whaddaya think..."}
						type="text"
						leftUnit={null}
						rightUnit={null}
						unitSwitch={false}
						leftUnitSelected={true}
						unitSwitchFunc={null}
						disabled={false}
						multiline={true}
						error={false}
						onMouseEnter={null}
						onMouseLeave={null}
						reset={false}
						resetFunc={null}
						resetPosition={null}
					/>
					<div className="penny-thoughts-button-container">
						<Button
                            buttonType="primary-button"
                            size="large"
                            text={"Send off"}
                            cta={complete}
                            disabled={disabled === true ? true : pennyThoughts === "" ? true : false}
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
							buttonRef={null}
                        />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default PennyThoughts;