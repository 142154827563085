import React, { useState, useEffect } from 'react';
import '../styles/DealOptimiser.css';
import { FilterSpacing, FinancialsInput } from '../components';
import { formatPrice, checkNaN } from '../../functions';

function DealOptimiser(props) {
    const property = props.property;
    const blurred = props.blurred;
    const editedProperty = props.editedProperty;
    const optimisedParams = props.optimisedParams;
    const setOptimisedParams = props.setOptimisedParams;
    const [price, setPrice] = useState(editedProperty.price);
    const [duration, setDuration] = useState(60);
    const [monthlyCosts, setMonthlyCosts] = useState(
        editedProperty.monthlyCosts.reduce((accumulator, currentValue) => {
            currentValue.inputs.forEach(item => {
                if ( item.label.includes("Mortgage") ) {
                }
                else if ( item.label === "Property taxes" && editedProperty.strategy === 2 ) {
                    accumulator += Number(item.brrrrValue);
                }
                else {
                    accumulator += Number(item.value);
                }
            });
            return accumulator;
    }, 0));
    const [rent, setRent] = useState(editedProperty.rent);
    const [minDownPayment, setMinDownPayment] = useState(5);
    const [maxDownPayment, setMaxDownPayment] = useState(20);
    const [minInterestRate, setMinInterestRate] = useState(1);
    const [maxInterestRate, setMaxInterestRate] = useState(7);
    const [minSellerEarnings, setMinSellerEarnings] = useState(5);
    const [targetCoCR, setTargetCoCR] = useState(10);

    useEffect(() => {
        if ( optimisedParams === null ) {
            const newObject = {
                price: price,
                duration: duration,
                monthlyCosts: monthlyCosts,
                rent: rent,
                minDownPayment: minDownPayment,
                maxDownPayment: maxDownPayment,
                minInterestRate: minInterestRate,
                maxInterestRate: maxInterestRate,
                minSellerEarnings: minSellerEarnings,
                targetCoCR: targetCoCR
            };
            setOptimisedParams(newObject);
        }
    }, [
        price,
        duration,
        monthlyCosts,
        rent,
        minDownPayment,
        maxDownPayment,
        minInterestRate,
        maxInterestRate,
        minSellerEarnings,
        targetCoCR,
        optimisedParams,
        setOptimisedParams
    ]);

    const onChangePrice = (text) => {
        setPrice(text);
        setOptimisedParams({
            ...optimisedParams,
            price: text
        });
    };

    const onChangeDuration = (text) => {
        if ( text === "" ) {
            setDuration("");
            setOptimisedParams({
                ...optimisedParams,
                duration: ""
            });
        }
        else if ( checkNaN(Number(text)) ) {
            setDuration(text);
            setOptimisedParams({
                ...optimisedParams,
                duration: text
            });
        }
        else {
            const newNumber = Number(text);
            setDuration(newNumber * 12);
            setOptimisedParams({
                ...optimisedParams,
                duration: newNumber
            });
        }
    };

    const onChangeMonthlyCosts = (text) => {
        setMonthlyCosts(text);
        setOptimisedParams({
            ...optimisedParams,
            monthlyCosts: text
        });
    };

    const onChangeRent = (text) => {
        setRent(text);
        setOptimisedParams({
            ...optimisedParams,
            rent: text
        });
    };

    const onChangeMinDownPayment = (text) => {
        setMinDownPayment(text);
        setOptimisedParams({
            ...optimisedParams,
            minDownPayment: text
        });
    };

    const onChangeMaxDownPayment = (text) => {
        setMaxDownPayment(text);
        setOptimisedParams({
            ...optimisedParams,
            maxDownPayment: text
        });
    };

    const onChangeMinInterestRate = (text) => {
        setMinInterestRate(text);
        setOptimisedParams({
            ...optimisedParams,
            minInterestRate: text
        });
    };

    const onChangeMaxInterestRate = (text) => {
        setMaxInterestRate(text);
        setOptimisedParams({
            ...optimisedParams,
            maxInterestRate: text
        });
    };

    const onChangeMinSellerEarnings = (text) => {
        setMinSellerEarnings(text);
        setOptimisedParams({
            ...optimisedParams,
            minSellerEarnings: text
        });
    };

    const onChangeTargetCoCR = (text) => {
        setTargetCoCR(text);
        setOptimisedParams({
            ...optimisedParams,
            targetCoCR: text
        });
    };

    return (
        <div className="deal-optimiser-container">
            <div className="deal-optimiser-section-container">
                <div className="deal-optimiser-title-container">
                    <span className="body-regular colour-secondary">
                        Terms
                    </span>
                </div>
                <div className="deal-optimiser-section-row">
                    <div className="deal-optimiser-short-input-container">
                        <FinancialsInput
                            label={"Offer price"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(price)}
                            onChange={(text) => onChangePrice(text.target.value.replaceAll(",", ""))}
                            placeholder={formatPrice(property.price)}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={null}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={price === "" ? true : checkNaN(price)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    <div className="deal-optimiser-short-input-container">
                        <FinancialsInput
                            label={"Balloon due in"}
                            labelType={"default"}
                            info={false}
                            value={duration === "" ? "" : checkNaN(duration) === true ? duration : duration / 12}
                            onChange={(text) => onChangeDuration(text.target.value.replaceAll(",", ""))}
                            placeholder={"5"}
                            type="text"
                            leftUnit={null}
                            rightUnit={"years"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={duration === "" ? true : checkNaN(duration)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    <div className="deal-optimiser-long-input-container">
                        <FinancialsInput
                            label={"Monthly costs"}
                            secondaryLabel={"(minus mortgage)"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(monthlyCosts)}
                            onChange={(text) => onChangeMonthlyCosts(text.target.value.replaceAll(",", ""))}
                            placeholder={"0"}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={null}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={monthlyCosts === "" ? true : checkNaN(monthlyCosts)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
                <div className="deal-optimiser-section-row">
                    <div className="deal-optimiser-short-input-container">
                        <FinancialsInput
                            label={"Rent"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(rent)}
                            onChange={(text) => onChangeRent(text.target.value.replaceAll(",", ""))}
                            placeholder={formatPrice(editedProperty.rent)}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={null}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={rent === "" ? true : checkNaN(rent)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    <div className="deal-optimiser-short-input-container">
                        <FinancialsInput
                            label={"Min. down payment"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(minDownPayment)}
                            onChange={(text) => onChangeMinDownPayment(text.target.value.replaceAll(",", ""))}
                            placeholder={5}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={minDownPayment === "" ? true : checkNaN(minDownPayment)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    <div className="deal-optimiser-short-input-container">
                        <FinancialsInput
                            label={"Max. down payment"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(maxDownPayment)}
                            onChange={(text) => onChangeMaxDownPayment(text.target.value.replaceAll(",", ""))}
                            placeholder={5}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={maxDownPayment === "" ? true : checkNaN(maxDownPayment)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
            </div>
            <FilterSpacing />
            <div className="deal-optimiser-section-container">
                <div className="deal-optimiser-title-container">
                    <span className="body-regular colour-secondary">
                        What is your target interest rate range?
                    </span>
                </div>
                <div className="deal-optimiser-section-row-small">
                    <div className="deal-optimiser-short-input-container">
                        <FinancialsInput
                            label={"Min"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(minInterestRate)}
                            onChange={(text) => onChangeMinInterestRate(text.target.value.replaceAll(",", ""))}
                            placeholder={1}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={minInterestRate === "" ? true : checkNaN(minInterestRate)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    <div className="deal-optimiser-separator-container">
                        <span className="body-regular colour-primary">
                            -
                        </span>
                    </div>
                    <div className="deal-optimiser-short-input-container">
                        <FinancialsInput
                            label={"Max"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(maxInterestRate)}
                            onChange={(text) => onChangeMaxInterestRate(text.target.value.replaceAll(",", ""))}
                            placeholder={7}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={maxInterestRate === "" ? true : checkNaN(maxInterestRate)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
            </div>
            <FilterSpacing />
            <div className="deal-optimiser-section-container">
                <div className="deal-optimiser-title-container">
                    <span className="body-regular colour-secondary">
                        Preferred returns for both you and the seller?
                    </span>
                </div>
                <div className="deal-optimiser-section-row">
                    <div className="deal-optimiser-medium-input-container">
                        <FinancialsInput
                            label={"Minimum seller earnings"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(minSellerEarnings)}
                            onChange={(text) => onChangeMinSellerEarnings(text.target.value.replaceAll(",", ""))}
                            placeholder={5}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={minSellerEarnings === "" ? true : checkNaN(minSellerEarnings)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    <div className="deal-optimiser-medium-input-container">
                        <FinancialsInput
                            label={"Your target cash on cash return"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(targetCoCR)}
                            onChange={(text) => onChangeTargetCoCR(text.target.value.replaceAll(",", ""))}
                            placeholder={10}
                            type="text"
                            leftUnit={null}
                            rightUnit={"%"}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={blurred}
                            multiline={false}
                            error={targetCoCR === "" ? true : checkNaN(targetCoCR)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DealOptimiser;