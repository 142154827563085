import React, { useState } from 'react';
import '../styles/MFHRentOneUnitType.css';
import { colour } from '../styles/GlobalStyles';
import { FilterReset, FinancialsInput }  from '../v4/components';
import { Dot, Units } from '../assets/icons';
import { RentAccordion } from '../components';
import { checkNaN, formatPrice } from '../functions';

function MFHRentOneUnitType(props) {
    const rent = props.rent;
    const defaultRent = props.defaultRent;
    const blurred = props.blurred;
    const onChangeRent = props.onChangeRent;
    const units = props.units;
    const onChangeUnitRent = props.onChangeUnitRent;
    const resetRentEstimates = props.resetRentEstimates;
    const property = props.property;
    const [rentAccordion, setRentAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };

    return (
        <div className="mfh-rent-one-unit-outer-container">
            <div className="mfh-rent-one-unit-inner-container">
                <RentAccordion
                    rentAccordion={rentAccordion}
                    handleRentAccordion={handleRentAccordion}
                    property={property}
                />
                <div className="property-spacing-border">
				</div>
                <div className="mfh-rent-one-unit-title-container relative-container">
                    <h3 className="body-semibold colour-primary">
                        Rent by unit
                    </h3>
                    {
                        Number(rent) !== Number(defaultRent) &&
                        <Dot
                            className="mfh-rent-multi-unit-type-dot"
                            fill={colour.purplePurple02}
                            stroke={colour.indigoIndigo00}
                        />
                    }
                </div>
                <div className="mfh-rent-one-unit-body-container">
                    <div className="mfh-rent-one-unit-icon-container">
                        <Units
                            fill={colour.grayScaleBlack}
                            className="mfh-rent-multi-unit-type-icon"
                        />
                    </div>
                    <div className="mfh-rent-one-unit-grid">
                        {
                            units.map((item, index) =>
                                <div 
                                    className="mfh-rent-one-unit-financials-element"
                                    key={index}
                                >
                                    <FinancialsInput
                                        label={item.label}
                                        labelType={"inside"}
                                        info={false}
                                        value={blurred === true ? "999" : formatPrice(item.value)}
                                        onChange={(text) => onChangeUnitRent(text.target.value.replaceAll(",", ""), index)}
                                        placeholder={formatPrice(defaultRent)}
                                        type="text"
                                        leftUnit={"$"}
                                        rightUnit={null}
                                        unitSwitch={false}
                                        leftUnitSelected={true}
                                        unitSwitchFunc={null}
                                        disabled={blurred}
                                        multiline={false}
                                        error={checkNaN(item.value)}
                                        onMouseEnter={null}
                                        onMouseLeave={null}
                                        reset={false}
                                        resetFunc={null}
                                        resetPosition={null}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    Number(rent) !== Number(defaultRent) &&
                    <div className="mfh-rent-reset-container">
                        <FilterReset
                            clickFunc={() => resetRentEstimates()}
                        />
                    </div>
                }
                <div className="property-spacing-border">
				</div>
                <div className="mfh-rent-one-unit-total-container">
                    <FinancialsInput
                        label={"Total rent"}
                        labelType={"default"}
                        info={false}
                        value={blurred === true ? "999" : formatPrice(rent)}
                        onChange={(text) => onChangeRent(text.target.value.replaceAll(",", ""))}
                        placeholder={formatPrice(defaultRent)}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={blurred}
                        multiline={false}
                        error={checkNaN(rent)}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
            </div>
        </div>
    )
};

export default MFHRentOneUnitType;