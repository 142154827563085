import React from 'react';

function Agent(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="18" 
            height="22" 
            viewBox="0 0 18 22" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M4.76025 5.5C4.76025 7.84 6.67025 9.75 9.01025 9.75C11.3503 9.75 13.2603 7.84 13.2603 5.5V4.5C13.2603 2.16 11.3503 0.25 9.01025 0.25C6.67025 0.25 4.76025 2.16 4.76025 4.5V5.5ZM6.26025 4.5C6.26025 2.98 7.49025 1.75 9.01025 1.75C10.5303 1.75 11.7603 2.98 11.7603 4.5V5.5C11.7603 7.02 10.5303 8.25 9.01025 8.25C7.49025 8.25 6.26025 7.02 6.26025 5.5V4.5ZM17.01 21.75L17.005 21.75C17.4128 21.7472 17.75 21.4083 17.75 21V16C17.75 13.98 17.74 12.87 16.94 12.06C16.14 11.25 15.02 11.25 13 11.25C12.7 11.25 12.43 11.43 12.31 11.7L10.0978 16.6858L9.78043 14.14L10.6804 12.34C10.8004 12.11 10.7904 11.83 10.6504 11.61C10.5104 11.38 10.2704 11.25 10.0104 11.25H8.01043C7.75043 11.25 7.51043 11.39 7.37043 11.61C7.24043 11.83 7.22043 12.11 7.34043 12.34L8.24043 14.14L7.91847 16.7225L5.69 11.7C5.56 11.42 5.3 11.25 5 11.25C2.98 11.25 1.86 11.25 1.06 12.06C0.25 12.86 0.25 13.98 0.25 16V21C0.25 21.41 0.59 21.75 1 21.75C1.41 21.75 1.75 21.41 1.75 21V16C1.75 14.26 1.77 13.47 2.12 13.12C2.44 12.8 3.11 12.75 4.52 12.75L8.32 21.3C8.56 21.84 9.45 21.84 9.69 21.3L13.49 12.75C14.9 12.76 15.57 12.8 15.89 13.12C16.25 13.47 16.26 14.25 16.26 16V21C16.26 21.4083 16.5972 21.7472 17.005 21.75L17 21.75H17.01Z" 
                fill={fill}
            />
        </svg>
    )
}

export default Agent;