import React from 'react';

function FilterReset(props) {
    const clickFunc = props.clickFunc;
    return (
        <span 
            className="body-regular-underline colour-link"
            onClick={() => clickFunc()}
        >
            Reset
        </span>
    );
};

export default FilterReset;