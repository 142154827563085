import React from 'react';

function CoCRSort() {
    return (
        <span className="body-regular colour-primary">
            Sorting by CoCR isn't ideal because it overlooks the importance of location. 
            This might rank properties in less stable or low-growth areas higher. 
            Instead, use the <span className="body-semibold">"Strategies"</span> option to find the best all-around 
            properties for each strategy.
        </span>
    )
}

export default CoCRSort;