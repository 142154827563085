import React, { useState, useEffect } from 'react';
import '../styles/AvailableMarkets.css';
import { Footer, Header, LiveCounties, Loading } from '../components';
import { checkOnAuthStateChanged, getUserDocuments, goToCreativeCheckout } from '../functions';
import { Modal } from '../v4/components';
import { Upgrade } from '../v4/modalbody';
import { useNavigate } from 'react-router-dom';

function AvailableMarkets() {
    const [loading, setLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [premiumModal, setPremiumModal] = useState(false);
    const [paidCustomer, setPaidCustomer] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Unlocked Markets | Coffee Clozers";

        const fetchUserData = async() => {
            const today = new Date();
            const todaySeconds = today.getTime() / 1000;

            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const collections = [
                    "Users",
                    "Subscriptions"
                ];
                const queryData = await getUserDocuments(collections, user.userId);
                if ( queryData[0].status === 200 ) {
                    setUserData(queryData[0].data);
                }
                if ( queryData[1].status === 200 ) {
                    setSubscriptions(queryData[1].data);

                    const data = queryData[1].data;
                    const activeSubscription = data.findIndex(e => e.endDate.seconds > todaySeconds);

                    if ( activeSubscription !== -1 ) {
                        setPaidCustomer(true);
                    }
                }

                const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
			    const isAdmin = adminIds.includes(user.userId);
                if ( isAdmin === true ) {
                    setPaidCustomer(true);
                }
                setLoading(false);
            }
            else {
                navigate("/login");
            }
        };

        fetchUserData();
    }, [navigate]);

    const upgradeNow = () => {
        goToCreativeCheckout(navigate);
    };

    const viewPricing = () => {
        navigate("/pricing");
    };

    const closePremiumModal = () => {
        setPremiumModal(false);
    };

    return (
        <div className="available-markets-outer-container bg-colour-white">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={premiumModal === true ? false : true}
                desktopControl={true}
            />
            <div className="available-markets-inner-container relative-container">
                {
                    loading === true &&
                    <Loading />
                }
                {
                    premiumModal === true &&
                    <div className="dark-bg">
                    </div>
                }
                {
                    premiumModal === true &&
                    <Modal
                        heading="Plus Feature 👀 "
                        subheading={"You've discovered a Plus Plan feature!"}
                        body={<Upgrade />}
                        type="secondary"
                        cancelText="Maybe later"
                        cancelButtonCTA={closePremiumModal}
                        actionButtonText="Upgrade now"
                        actionButtonCTA={upgradeNow}
                        actionButtonDisabled={false}
                        secondaryButtonText="See pricing plans"
                        secondaryButtonCTA={viewPricing}
                        exitButtonCTA={closePremiumModal}
                        loading={false}
                        leftIcon={null}
					    rightIcon={null}
                        cancelLeftIcon={null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                <div className="available-markets-title-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Unlocked Markets
                    </h1>
                    <h2 className="body-regular colour-secondary text-align-center">
                        Browse any of these unlocked markets
                        {
                            paidCustomer === false &&
                            <span className="body-regular colour-secondary">
                                {" "}during your <span className="body-semibold">free trial.</span>
                            </span>
                        }
                    </h2>
                </div>
                <div className="available-markets-border">
                </div>
                <div className="available-markets-body-container">
                    {
                        loading === false &&
                        <LiveCounties
                            userId={userId}   
                            userData={userData}
                            subscriptions={subscriptions} 
                            setPremiumModal={setPremiumModal}
                            title={false}
                            featured={true}
                        />
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default AvailableMarkets;