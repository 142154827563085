import React from 'react';
import '../styles/SearchInput.css';
import { Exit, MagnifyingGlass } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function SearchInput(props) {
    const value = props.value;
    const onChange = props.onChange;
    const placeholder = props.placeholder;
    const type = props.type;
    const clearInput = props.clearInput;
    const magnifyingGlassFunc = props.magnifyingGlassFunc;

    return (
        <div className="search-input-container">
            <div className="search-input-wrapper relative-container">
                <input
                    type={type}
                    value={value}
                    onChange={onChange}
                    className="search-input body-regular colour-primary"
                    placeholder={placeholder}
                />
                {
                    value === "" ?
                    <MagnifyingGlass
                        fill={colour.grayScaleGray02}
                        className="search-input-icon search-magnifying-glass"
                        clickFunc={magnifyingGlassFunc === null ? null : () => magnifyingGlassFunc()}
                    />
                    :
                    <Exit
                        className="search-input-icon search-exit-icon cursor-pointer"
                        fill={colour.grayScaleBlack}
                        clickFunc={() => clearInput()}
                    />
                }
            </div>
        </div>
    )
};

export default SearchInput;