import React from 'react';
import '../styles/Upgrade.css';
import { TickThick } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function Upgrade() {

    return (
        <div className="upgrade-container">
            <span className="body-regular colour-primary">
                You've discovered a Plus Plan feature! You can unlock this feature 
                (and much more!!) by upgrading to a Plus plan. Some premium features include:
            </span>
            <div className="upgrade-body-container">
                <div className="upgrade-element">
                    <TickThick
                        fill={colour.blueBlue02}
                        className=""
                    />
                    <span className="body-regular colour-secondary">
                        Access to Traditional <span className="body-semibold">+ Creative</span> Coffee Clozers
                    </span>
                </div>
                <div className="upgrade-element">
                    <TickThick
                        fill={colour.blueBlue02}
                        className=""
                    />
                    <span className="body-regular colour-secondary">
                        Access <span className="body-semibold">all</span> counties* on Coffee Clozers, plus 
                        unlock any <span className="colour-primary">1 county</span> of your choice
                    </span>
                </div>
                <div className="upgrade-element">
                    <TickThick
                        fill={colour.blueBlue02}
                        className=""
                    />
                    <span className="body-regular colour-secondary">
                        Unlimited property search credits
                    </span>
                </div>
                <div className="upgrade-element">
                    <TickThick
                        fill={colour.blueBlue02}
                        className=""
                    />
                    <span className="body-regular colour-secondary">
                        <span className="body-semibold">Nationwide</span> data for all FSBO properties
                    </span>
                </div>
                <div className="upgrade-element">
                    <TickThick
                        fill={colour.blueBlue02}
                        className=""
                    />
                    <span className="body-regular colour-secondary">
                        Generate unlimited offer letters
                    </span>
                </div>
                <div className="upgrade-element">
                    <TickThick
                        fill={colour.blueBlue02}
                        className=""
                    />
                    <span className="body-regular colour-secondary">
                        <span className="body-semibold">CoffeeClozersGPT</span> to practice pitching sellers
                    </span>
                </div>
                <div className="upgrade-element">
                    <TickThick
                        fill={colour.blueBlue02}
                        className=""
                    />
                    <span className="body-regular colour-secondary">
                        Membership to our exclusive creative finance <span className="body-semibold">community</span>
                    </span>
                </div>
            </div>
        </div>
    )
};

export default Upgrade;