import React, { useState, useRef, useEffect } from 'react';
import '../styles/MobilePropertyCardOverlay.css';
import { toggleFavourite, markPropertyViewed, recordEvent, checkCondition } from '../functions/index';
import { useNavigate } from 'react-router-dom';
import { HeartActive, Heart } from '../assets/icons';
import { colour, numberFormatter, percentage } from '../styles/GlobalStyles';
import { ConditionBadge, IconButton } from '../v4/components'
import { RoIBadge } from './';

function MobilePropertyCardOverlay(props) {
	const item = props.item;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites;
	const formatter = props.formatter;
	const viewedProperties = props.viewedProperties;
	const setViewedProperties = props.setViewedProperties;
	const strategy = props.strategy;
	const cityId = props.cityId;
	const creativeStrategy = props.creativeStrategy !== undefined && props.creativeStrategy !== null ? props.creativeStrategy : false;
	const creativeType = item.freeClearFlag === true ? "sf" : "sub";
	const buyingCash = item.financials.mortgagePayment === 0 ? true : false;
	const acquisitionParam = creativeStrategy === true ? `?acquisition=${creativeType}` : `?acquisition=${buyingCash === true ? "c" : "m"}`;
	const exitStrategyText = strategy === 0 ? "ltr" : strategy === 1 ? "hh" : strategy === 2 ? "brrrr" : "lo";
	const exitParam = `&exit=${exitStrategyText}`;
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const navigate = useNavigate();
	const cityObject = {
		cityId: cityId,
		city: item.address.city,
		state: item.address.state
	}
	const strategyParam = `${acquisitionParam}${exitParam}`;
	const inFavourites = favourites.findIndex(e => e.zpid === item.zpid) === -1 ? false : true;

	const bedrooms = item.bedrooms === null ? "N/A" : `${item.bedrooms} beds`;
	const bathrooms = item.bathrooms === null ? "N/A" : `${item.bathrooms} baths`;
	const livingArea = item.livingArea === null ? "N/A" : `${numberFormatter.format(item.livingArea)} sqft`;
	const propertyAbbreviation = item.propertyTypeDimension === "Single Family" ? "SFH" : item.propertyTypeDimension === "Multi Family" ? "MFH" : "N/A";
	const divider = "|";

	const details = [
		bedrooms,
		divider,
		bathrooms,
		divider,
		livingArea,
		divider,
		propertyAbbreviation
	];

	const roiLabel = strategy === 1 ? "Exp. covered" : "CoCR";

	const rawRoI = strategy === 1 ? item.houseHackingFeatures.ratioOfMortgageCovered : item.financials.cashOnCash;
	const roiCalc = strategy === 1 ? rawRoI : Math.round(rawRoI) / 100;
	const roiBadgeBG = strategy === 1 && rawRoI >= 1 ? "bg-colour-high-score" : strategy === 1 ? "bg-colour-low-score" : rawRoI >= 7 ? "bg-colour-high-score" : rawRoI >= 0 ? "bg-colour-medium-score" : "bg-colour-low-score";
	const textRef = useRef(null);
	const [textHeight, setTextHeight] = useState(0);

	useEffect(() => {
        if (textRef.current) {
            setTextHeight(textRef.current.offsetHeight);
        }
    }, [textRef]);

	const viewProperty = async(e) => {
		const target = e.target;
        const targetClass = target.classList.value;
        const parentTargetClass = target.parentNode.classList.value;

		if ( targetClass.includes("heart") || parentTargetClass.includes("heart") || targetClass.includes("icon-button") || parentTargetClass.includes("icon-button") ) {
			return;
		}
		else {
			window.open(`/properties/${cityId}/${item.zpid}${strategyParam}`, '_blank');
			
			const newObject = {
				zpid: item.zpid,
				date: new Date()
			}
			const newArray = viewedProperties.slice(0);
			newArray.push(newObject);
			setViewedProperties(newArray);
			await markPropertyViewed(item.zpid);
			recordEvent("Property Click", {
				zpid: item.zpid,
				cityId: cityId
			});
		}
	};

	return (
		<div 
			className="mobile-property-card-overlay-outer-container cursor-pointer desktop-none"
			onClick={(e) => viewProperty(e)}
		>
			<div 
				className="mobile-property-card-overlay-inner-container"
				style={{
					'--text-height': `${textHeight}px`,
				}}
			>
				<div 
					style={{ backgroundImage: item.imgSrc.includes("maps.googleapis.com") === true ? "" : `url(${item.imgSrc})` }} 
					className="mobile-property-card-overlay-image-container relative-container"
				>
					{
						item.propertyCondition !== "Unclear" &&
						<ConditionBadge
							condition={checkCondition(item.propertyCondition)}
						/>
					}
				</div>
				<div 
					className="mobile-property-card-overlay-right-container"
					ref={textRef}
				>
					<div className="mobile-property-card-overlay-info-container">
						<div className="mobile-property-card-overlay-price-container relative-container">
							<h3 className="body-semibold colour-primary">
								{formatter.format(item.price).replace(".00", "")}
							</h3>
							<IconButton
								size="medium"
								icon={inFavourites === true ? <HeartActive className="sticky-roi-heart-icon" fill={colour.blueBlue03} /> : <Heart className="sticky-roi-heart-icon" fill={colour.blueBlue03} />}
								disabled={false}
								clickFunc={() => favouriteDisable === true ? null : toggleFavourite(favourites, item.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null)}
								standardIcon={false}
								contextTooltip={false}
								contextTooltipBG={null}
								contextTooltipText={null}
								customClass={null}
							/>
						</div>
						<div className="mobile-property-card-overlay-address-container">
							<span className="xs-label colour-tertiary">
								{item.address.streetAddress}, {item.address.city}, {item.address.state} {item.address.zipcode}
							</span>
						</div>
						<div className="mobile-property-card-details-row">
							{
								details.map((item, index) =>
									<span 
										className="xs-label colour-primary"
										key={index}
									>
										{item}
									</span>
								)
							}
						</div>
					</div>
					<div className="mobile-property-card-overlay-roi-container">
						<RoIBadge
							label={roiLabel}
							value={percentage.format(roiCalc)}
							roiBadgeBG={roiBadgeBG}
						/>
						<RoIBadge
							label="Score"
							value={Math.round(item.dripScore * 100)}
							roiBadgeBG={item.dripScore >= 0.8 ? "bg-colour-high-score" : item.dripScore >= 0.7 ? "bg-colour-medium-score" : "bg-colour-low-score"}
						/>
					</div>
				</div>
			</div>
		</div>
	)
};

export default MobilePropertyCardOverlay;