import React from 'react';
import '../styles/BathroomFilter.css';
import { Bathroom } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { Checkbox, FilterReset, SelectionChip } from './';

function BathroomFilter(props) {
    const bathrooms = props.bathrooms;
    const setBathrooms = props.setBathrooms;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const exactMatch = props.exactMatch;
    const setExactMatch = props.setExactMatch;
    const options = [
        {
            label: "Any",
            value: 0,
            selected: bathrooms === 0 ? true : false
        },
        {
            label: "1+",
            value: 1,
            selected: bathrooms === 1 ? true : false
        },
        {
            label: "1.5+",
            value: 1.5,
            selected: bathrooms === 1.5 ? true : false
        },
        {
            label: "2+",
            value: 2,
            selected: bathrooms === 2 ? true : false
        },
        {
            label: "2.5+",
            value: 2.5,
            selected: bathrooms === 2.5 ? true : false
        },
        {
            label: "3+",
            value: 3,
            selected: bathrooms === 3 ? true : false
        },
        {
            label: "4+",
            value: 4,
            selected: bathrooms === 4 ? true : false
        }
    ];
    
    const changeBaths = (bathIndex) => {
        setBathrooms(options[bathIndex].value);
    };

    const changeExactMatch = () => {
        setExactMatch(!exactMatch);
    };

    return (
        <div className="bathroom-filter-container">
            <div className="bathroom-filter-title-container">
                <Bathroom
                    className=""
                    fill={colour.grayScaleBlack}
                />
                <h3 className="body-semibold colour-primary">
                    Baths
                </h3>
            </div>
            <div className="bathroom-filter-options-row">
                {
                    options.map((item, index) =>
                        <SelectionChip
                            label={exactMatch === true ? item.label.replace("+", "") : item.label}
                            selected={item.selected}
                            disabled={false}
                            func={changeBaths}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                            key={index}
                        />
                    )
                }
            </div>
            <div className="bathroom-filter-exact-match-container">
                <Checkbox
                    label="Use exact match"
                    selected={exactMatch}
                    type="default"
                    disabled={false}
                    cta={changeExactMatch}
                />
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("baths")}
                />
            }
        </div>
    )
};

export default BathroomFilter;