import React from 'react';
import '../styles/BedroomFilter.css';
import { Bedroom } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { Checkbox, FilterReset, SelectionChip } from './';

function BedroomFilter(props) {
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const bedrooms = props.bedrooms;
    const setBedrooms = props.setBedrooms;
    const exactMatch = props.exactMatch;
    const setExactMatch = props.setExactMatch;
    const options = [
        {
            label: "Any",
            value: 0,
            selected: bedrooms === 0 ? true : false
        },
        {
            label: "1+",
            value: 1,
            selected: bedrooms === 1 ? true : false
        },
        {
            label: "2+",
            value: 2,
            selected: bedrooms === 2 ? true : false
        },
        {
            label: "3+",
            value: 3,
            selected: bedrooms === 3 ? true : false
        },
        {
            label: "4+",
            value: 4,
            selected: bedrooms === 4 ? true : false
        },
        {
            label: "5+",
            value: 5,
            selected: bedrooms === 5 ? true : false
        }
    ];
    
    const changeBeds = (bedIndex) => {
        setBedrooms(options[bedIndex].value);
    };

    const changeExactMatch = () => {
        setExactMatch(!exactMatch);
    };

    return (
        <div className="bedroom-filter-container">
            <div className="bedroom-filter-title-container">
                <Bedroom
                    className=""
                    fill={colour.grayScaleBlack}
                />
                <h3 className="body-semibold colour-primary">
                    Beds
                </h3>
            </div>
            <div className="bedroom-filter-options-row">
                {
                    options.map((item, index) =>
                        <SelectionChip
                            label={exactMatch === true ? item.label.replace("+", "") : item.label}
                            selected={item.selected}
                            disabled={false}
                            func={changeBeds}
                            index={index}
                            leftIcon={null}
                            rightIcon={null}
                            key={index}
                        />
                    )
                }
            </div>
            <div className="bedroom-filter-exact-match-container">
                <Checkbox
                    label="Use exact match"
                    selected={exactMatch}
                    type="default"
                    disabled={false}
                    cta={changeExactMatch}
                />
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("beds")}
                />
            }
        </div>
    )
};

export default BedroomFilter;