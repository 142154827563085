import React, { useEffect } from 'react';
import { Header, Footer } from '../components';
import '../styles/ErrorPage.css';
import { EmptyCup } from '../assets';
import { ActionButton } from "../styles/GlobalStyles";
import { useNavigate } from 'react-router-dom';
import { recordEvent } from '../functions';

function PreviewExpired() {

    const navigate = useNavigate();
    useEffect(() => {
		document.title = "Link Expired";
	}, []);

    const cta = () => {
        navigate("/home");
		recordEvent("Preview Expired CTA", {});
    };

    return (
        <div className="error-page-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
				desktopControl={true}
			/>
			<div className="error-page-inner-container">
				<div className="error-page-image-container">
					<img
						src={EmptyCup}
						className="error-page-image"
						alt="Error 404"
					/>
				</div>
				<div className="error-page-body-container">
					<h1 className="heading-large-semibold colour-primary text-align-center margin-medium">
						🫣 Preview link expired
					</h1>
					<span className="body-regular colour-primary block-text text-align-center margin-medium">
						I'm afraid you clicked on a temporary preview link which
                        has now expired
					</span>
				</div>
				<div className="error-page-footer-container">
					<ActionButton
                        onClick={() => cta()}
                    >
                        Back to home
                    </ActionButton>
				</div>
			</div>
			<Footer />
		</div>
    )
}

export default PreviewExpired;