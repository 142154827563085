import React from 'react';
import '../styles/AddToList.css';
import { ListCard } from '../components';

function AddToList(props) {
    const lists = props.lists;
    const selectedList = props.selectedList;
    const setSelectedList = props.setSelectedList;

    return (
        <div className="add-to-list-outer-container">
            {
                lists.map((item, index) =>
                    <ListCard
                        list={item}
                        key={index}
                        editMode={true}
                        selectedList={selectedList}
                        setSelectedList={setSelectedList}
                    />
                )
            }
        </div>
    )
};

export default AddToList;