import React from 'react';
import '../styles/AISummary.css';
import { Sparkle } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { Typewriter } from './';

function AISummary(props) {
    const property = props.property;
    const creativeUser = props.creativeUser;
    return (
        <div className="ai-summary-outer-container">
            <div className="ai-summary-inner-container">
                <div className="ai-sparkle-icon-container">
                    <Sparkle
                        fill={colour.grayScaleBlack}
                        className="ai-summary-icon"
                    />
                </div>
                <div className="ai-summary-text-container">
                    <Typewriter
                        text={creativeUser === false ? "The AI summary is only available to users on the Creative plan." : property.dripScoreSummaryDescription.overall}
                        delay={15}
                        className="body-regular colour-primary"
                    />
                </div>
            </div>
        </div>
    )
};

export default AISummary;