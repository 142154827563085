import React from 'react';
import '../styles/PredictiveSearch.css';

function PredictiveSearch(props) {
    const loading = props.loading;
    const results = props.results;
    const selectItem = props.selectItem;
    const nested = props.nested;

    return (
        <div className="predictive-search-container">
            {
                loading === true ?
                <div className="predictive-search-loading-container">
                    <span className="body-regular colour-primary">
                        Loading...
                    </span>
                </div>
                :
                null
            }
            {
                nested === false ?
                results.map((item, index) =>
                    <div 
                        className="predictive-search-element-container cursor-pointer"
                        onClick={() => selectItem(item)}
                        key={index}
                    >
                        <span className="body-regular colour-primary">
                            {item.label}
                        </span>
                    </div>
                )
                :
                results.map((type, i) =>
                    <div
                        className="predictive-search-label-container"
                        key={i}
                    >
                        <span className="label-semibold-caps colour-quaternary">
                            {type.label}
                        </span>
                        {
                            type.results.map((item, index) =>
                                <div 
                                    className="predictive-search-element-container cursor-pointer"
                                    onClick={() => selectItem(item)}
                                    key={index}
                                >
                                    <span className="body-regular colour-primary">
                                        {item.label}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

export default PredictiveSearch;