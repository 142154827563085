import React, { useState } from 'react';
import '../styles/SquareFootageFilter.css';
import { SquareFootage } from '../../assets/icons';
import { colour, numberFormatter } from '../../styles/GlobalStyles';
import { Dropdown, FilterReset } from './';
import { ExitScreen } from '../../components';

function SquareFootageFilter(props) {
    const squareFootage = props.squareFootage;
    const setSquareFootage = props.setSquareFootage;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const [minDropdownOpen, setMinDropdownOpen] = useState(false);
    const [maxDropdownOpen, setMaxDropdownOpen] = useState(false);
    const dropdownOptions = [
        {
            label: "No min",
            value: 0,
            icon: null,
            disabled: maxDropdownOpen
        },
        {
            label: "500",
            value: 500,
            icon: null,
            disabled: ((squareFootage[1] < 500 && minDropdownOpen === true) || (squareFootage[0] >= 500 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "750",
            value: 750,
            icon: null,
            disabled: ((squareFootage[1] < 750 && minDropdownOpen === true) || (squareFootage[0] >= 750 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "1,000",
            value: 1000,
            icon: null,
            disabled: ((squareFootage[1] < 1000 && minDropdownOpen === true) || (squareFootage[0] >= 1000 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "1,250",
            value: 1250,
            icon: null,
            disabled: ((squareFootage[1] < 1250 && minDropdownOpen === true) || (squareFootage[0] >= 1250 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "1,500",
            value: 1500,
            icon: null,
            disabled: ((squareFootage[1] < 1500 && minDropdownOpen === true) || (squareFootage[0] >= 1500 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "1,750",
            value: 1750,
            icon: null,
            disabled: ((squareFootage[1] < 1750 && minDropdownOpen === true) || (squareFootage[0] >= 1750 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "2,000",
            value: 2000,
            icon: null,
            disabled: ((squareFootage[1] < 2000 && minDropdownOpen === true) || (squareFootage[0] >= 2000 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "3,000",
            value: 3000,
            icon: null,
            disabled: ((squareFootage[1] < 3000 && minDropdownOpen === true) || (squareFootage[0] >= 3000 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "3,500",
            value: 3500,
            icon: null,
            disabled: ((squareFootage[1] < 3500 && minDropdownOpen === true) || (squareFootage[0] >= 3500 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "4,000",
            value: 4000,
            icon: null,
            disabled: ((squareFootage[1] < 4000 && minDropdownOpen === true) || (squareFootage[0] >= 4000 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "5,000",
            value: 5000,
            icon: null,
            disabled: ((squareFootage[1] < 5000 && minDropdownOpen === true) || (squareFootage[0] >= 5000 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "7,500",
            value: 7500,
            icon: null,
            disabled: ((squareFootage[1] < 7500 && minDropdownOpen === true) || (squareFootage[0] >= 7500 && maxDropdownOpen === true)) === true ? true : false
        },
        {
            label: "No max",
            value: 999999,
            icon: null,
            disabled: ((squareFootage[1] < 999999 && minDropdownOpen === true) || (squareFootage[0] >= 999999 && maxDropdownOpen === true)) === true ? true : false
        }
    ];

    const toggleMinDropdown = () => {
        setMinDropdownOpen(!minDropdownOpen);
    };

    const selectMinOption = (item, index) => {
        setSquareFootage([dropdownOptions[index].value, squareFootage[1]]);
        setMinDropdownOpen(false);
    };

    const toggleMaxDropdown = () => {
        setMaxDropdownOpen(!maxDropdownOpen);
    };

    const selectMaxOption = (item, index) => {
        setSquareFootage([squareFootage[0], dropdownOptions[index].value]);
        setMaxDropdownOpen(false);
    };

    const checkSelected = (value) => {
        const valIndex = dropdownOptions.findIndex(e => e.value === value);
        return valIndex;
    };

    const closeAllSelects = () => {
        setMinDropdownOpen(false);
        setMaxDropdownOpen(false);
    };

    return (
        <div className="square-footage-filter-container relative-container">
            {
                (minDropdownOpen === true || maxDropdownOpen === true) &&
                <ExitScreen
                    clickScreen={() => closeAllSelects()}
                />
            }
            <div className="square-footage-filter-title-container">
                <SquareFootage
                    fill={colour.grayScaleBlack}
                    className=""
                />
                <h3 className="body-semibold colour-primary">
                    Square Footage
                </h3>
            </div>
            <div className="square-footage-filter-body-container">
                <div className="square-footage-filter-element">
                    <Dropdown
                        open={minDropdownOpen}
                        icon={null}
                        label={squareFootage[0] === 0 ? "No min" : numberFormatter.format(squareFootage[0])}
                        disabled={false}
                        options={dropdownOptions}
                        multiselect={false}
                        toggleDropdown={toggleMinDropdown}
                        cta={selectMinOption}
                        sidealign={"left"}
                        topalign={"bottom"}
                        indexSelect={true}
                        selectedIndex={checkSelected(squareFootage[0])}
                    />
                </div>
                <span className="body-regular colour-secondary block-text text-align-center">
                    -
                </span>
                <div className="square-footage-filter-element">
                    <Dropdown
                        open={maxDropdownOpen}
                        icon={null}
                        label={squareFootage[1] === 999999 ? "No max" : numberFormatter.format(squareFootage[1])}
                        disabled={false}
                        options={dropdownOptions}
                        multiselect={false}
                        toggleDropdown={toggleMaxDropdown}
                        cta={selectMaxOption}
                        sidealign={"left"}
                        topalign={"bottom"}
                        indexSelect={true}
                        selectedIndex={checkSelected(squareFootage[1])}
                    />
                </div>
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("sqft")}
                />
            }
        </div>
    );
};

export default SquareFootageFilter;