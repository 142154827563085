import React from 'react';

function Sort(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M8.35401 5.87499L4.67098 2.19197C4.44497 1.96596 4.07854 1.96596 3.85253 2.19197L0.169507 5.87499C-0.0565021 6.101 -0.0565023 6.46743 0.169507 6.69344C0.395515 6.91945 0.761948 6.91945 0.987957 6.69344L3.68303 3.99837L3.68302 12.3094C3.68302 12.629 3.94213 12.8881 4.26176 12.8881C4.58138 12.8881 4.84049 12.629 4.84049 12.3094L4.84049 3.99837L7.53556 6.69344C7.76156 6.91945 8.128 6.91945 8.35401 6.69344C8.58002 6.46743 8.58002 6.101 8.35401 5.87499ZM15.8305 10.1243L12.1475 13.8074C11.9215 14.0334 11.555 14.0334 11.329 13.8074L7.64599 10.1243C7.41999 9.89834 7.41999 9.53191 7.64599 9.3059C7.872 9.07989 8.23844 9.07989 8.46444 9.3059L11.1595 12.001L11.1595 3.68993C11.1595 3.3703 11.4186 3.1112 11.7382 3.1112C12.0579 3.1112 12.317 3.3703 12.317 3.68993L12.317 12.001L15.012 9.3059C15.2381 9.07989 15.6045 9.07989 15.8305 9.3059C16.0565 9.53191 16.0565 9.89834 15.8305 10.1243Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Sort;