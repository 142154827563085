import React from 'react';
import '../styles/BudgetFilter.css';
import { FilterReset, FinancialsInput } from './';
import { formatPrice } from '../../functions';

function BudgetFilter(props) {
    const label = props.label;
    const budgetRange = props.budgetRange;
    const setBudgetRange = props.setBudgetRange;
    const error = props.error;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;

    const onChangeLower = (e) => {
        const val = e.target.value.replaceAll(",", "");
        setBudgetRange([val, budgetRange[1]]);
    };

    const onChangeHigher = (e) => {
        const val = e.target.value.replaceAll(",", "");
        setBudgetRange([budgetRange[0], val]);
    };

    const checkNaN = (val) => {
        if ( error === true ) {
            return true;
        }
        else if ( isNaN(val) ) {
            return true;
        }
        else if ( (budgetRange[0] !== "" && budgetRange[1] !== "" && Number(budgetRange[0]) > Number(budgetRange[1])) ) {
            return true;
        }
        return false;
    };

    return (
        <div className="budget-filter-container">
            <h3 className="body-semibold colour-primary">
                {label}
            </h3>
            <div className="budget-filter-inner-container">
                <div className="budget-filter-element">
                    <FinancialsInput
                        label={null}
                        labelType={null}
                        info={false}
                        value={budgetRange[0] === 0 ? "" : formatPrice(budgetRange[0])}
                        onChange={onChangeLower}
                        placeholder={"0"}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(budgetRange[0])}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
                <span className="body-regular colour-secondary">
                    -
                </span>
                <div className="budget-filter-element">
                    <FinancialsInput
                        label={null}
                        labelType={null}
                        info={false}
                        value={budgetRange[1] === 1000000000 ? "" : formatPrice(budgetRange[1])}
                        onChange={onChangeHigher}
                        placeholder={"1,000,000"}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={null}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={false}
                        multiline={false}
                        error={checkNaN(budgetRange[1])}
                        onMouseEnter={null}
                        onMouseLeave={null}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
            </div>
            <div className="budget-filter-reset-container">
                {
                    error === true ?
                    <span className="body-regular colour-error">
                        Your maximum budget can't be lower than your minimum budget
                    </span>
                    :
                    reset === true &&
                    <FilterReset
                        clickFunc={() => resetFilterType("price")}
                    />
                }
            </div>
        </div>
    );
};

export default BudgetFilter;