import React from 'react';
import '../styles/DealOptimiserResults.css';
import { FilterSpacing } from '../components';
import { formatterLong, numberFormatter, percentage } from '../../styles/GlobalStyles';

function DealOptimiserResults(props) {
    const results = props.results;
    const dealOptimiseError = props.dealOptimiseError;
    return (
        dealOptimiseError !== "" ?
        <div className="deal-optimiser-results-container">
            <div className="deal-optimiser-results-section-container">
                <div className="deal-optimiser-results-title-container">
                    <span className="body-regular colour-error">
                        {dealOptimiseError}
                    </span>
                </div>
            </div>
        </div>
        :
        <div className="deal-optimiser-results-container">
            <div className="deal-optimiser-results-section-container">
                <div className="deal-optimiser-results-title-container">
                    <span className="body-regular colour-secondary">
                        Target terms
                    </span>
                </div>
                <div className="deal-optimiser-results-row">
                    <div className="deal-optimiser-results-element">
                        <span className="body-semibold colour-primary">
                            Down payment
                        </span>
                        <span className="body-regular colour-primary">
                            {percentage.format(results.downPaymentPrct / 100)}
                        </span>
                    </div>
                    <div className="deal-optimiser-results-element">
                        <span className="body-semibold colour-primary">
                            Interest rate
                        </span>
                        <span className="body-regular colour-primary">
                            {percentage.format(results.interestRate / 100)}
                        </span>
                    </div>
                </div>
                <div className="deal-optimiser-results-row">
                    <div className="deal-optimiser-results-element">
                        <span className="body-semibold colour-primary">
                            Offer price
                        </span>
                        <span className="body-regular colour-primary">
                            {formatterLong.format(results.offerPrice).replace(".00", "")}
                        </span>
                    </div>
                    <div className="deal-optimiser-results-element">
                        <span className="body-semibold colour-primary">
                            Balloon due in
                        </span>
                        <span className="body-regular colour-primary">
                            {numberFormatter.format(results.balloonYears)} years
                        </span>
                    </div>
                </div>
            </div>
            <FilterSpacing />
            <div className="deal-optimiser-results-section-container">
                <span className="body-regular colour-primary">
                    If you can successfully negotiate the terms, 
                    here are your expected returns:
                </span>
                <div className="deal-optimiser-results-roi-container">
                    <div className="deal-optimiser-results-roi-element">
                        <span className="label-semibold-caps colour-primary">
                            CASH-FLOW
                        </span>
                        <span className="heading-large-semibold colour-green">
                            {formatterLong.format(results.monthlyCashFlow).replace(".00", "")}
                        </span>
                    </div>
                    <div className="deal-optimiser-results-roi-element">
                        <span className="label-semibold-caps colour-primary">
                            CASH ON CASH
                        </span>
                        <span className="heading-large-semibold colour-green">
                            {percentage.format(results.cashOnCashReturn)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DealOptimiserResults;