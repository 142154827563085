import React from 'react';

function HourGlass(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M7.84556 9.33276C7.94532 9.29308 8.05812 9.29 8.16013 9.32349C11.4437 10.4015 12.2362 13.0157 12.426 14.1107C12.4727 14.3803 12.2587 14.6062 11.9852 14.6062H4.10541C3.84394 14.6062 3.63487 14.3988 3.66894 14.1395C3.75626 13.4751 4.07307 12.2065 5.21193 11.0325C5.82281 10.4027 6.6702 9.80022 7.84556 9.33276Z" 
                fill={fill}
            />
            <path 
                d="M7.771 7.40626C7.87047 7.43854 7.97997 7.43551 8.07736 7.3974C8.99669 7.03775 9.66134 6.57663 10.1418 6.0949C10.9883 5.24623 11.2632 4.33359 11.3519 3.79903C11.3947 3.54126 11.1863 3.3334 10.925 3.3334L4.86368 3.3334C4.58997 3.3334 4.3767 3.56019 4.43184 3.82828C4.61641 4.72568 5.29451 6.60284 7.771 7.40626Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M13.818 1.15158C14.0858 1.15158 14.3028 0.934508 14.3028 0.666733C14.3028 0.398959 14.0858 0.181885 13.818 0.181885H2.18163C1.91385 0.181885 1.69678 0.398959 1.69678 0.666733C1.69678 0.934508 1.91385 1.15158 2.18163 1.15158C2.18163 3.89277 3.35541 6.28555 5.09908 7.56248C5.32447 7.72754 5.55939 7.87395 5.80252 8.00007C5.55939 8.12618 5.32447 8.27259 5.09908 8.43765C3.35541 9.71458 2.18163 12.1074 2.18163 14.8486C1.91385 14.8486 1.69678 15.0656 1.69678 15.3334C1.69678 15.6012 1.91385 15.8182 2.18163 15.8182H13.818C14.0858 15.8182 14.3028 15.6012 14.3028 15.3334C14.3028 15.0656 14.0858 14.8486 13.818 14.8486C13.818 12.1074 12.6442 9.71458 10.9005 8.43765C10.6751 8.27259 10.4402 8.12618 10.1971 8.00007C10.4402 7.87395 10.6751 7.72754 10.9005 7.56248C12.6442 6.28555 13.818 3.89277 13.818 1.15158ZM13.1321 0.909158H2.86752C2.89432 0.984983 2.9089 1.06658 2.9089 1.15158C2.9089 4.03703 4.30455 6.40378 6.13739 7.35448C6.37847 7.47952 6.52979 7.72848 6.52979 8.00007C6.52979 8.27165 6.37847 8.52061 6.13739 8.64566C4.30455 9.59635 2.9089 11.9631 2.9089 14.8486C2.9089 14.9336 2.89432 15.0152 2.86752 15.091H13.1321C13.1053 15.0152 13.0907 14.9336 13.0907 14.8486C13.0907 11.9631 11.6951 9.59635 9.86223 8.64566C9.62115 8.52061 9.46982 8.27165 9.46982 8.00007C9.46982 7.72848 9.62115 7.47952 9.86223 7.35448C11.6951 6.40378 13.0907 4.03703 13.0907 1.15158C13.0907 1.06658 13.1053 0.984983 13.1321 0.909158Z" 
                fill={fill}
            />
        </svg>
    )
}

export default HourGlass;