import React from 'react';
import '../styles/FullScreenButton.css';
import { ExpandThin } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';

function FullScreenButton() {

    const clickFullScreen = () => {
        const fullScreenButton = document.querySelector('.gm-fullscreen-control');
        if ( fullScreenButton !== null ) {
            fullScreenButton.click();
        }
    };

    return (
        <div 
            className="full-screen-button-outer-container"
            onClick={() => clickFullScreen()}
        >
            <div className="full-screen-button-inner-container">
                <ExpandThin
                    fill={colour.blueBlue03}
                    className="expand-icon"
                />
            </div>
        </div>
    )
}

export default FullScreenButton;