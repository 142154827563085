import { useEffect, useState } from 'react';
import { checkOnAuthStateChanged, recordEvent, getUserDocuments, checkUserDocumentExists, renewFreeTrial, mixpanelDataSetup } from '../functions/index';
import { useNavigate } from 'react-router-dom';

function HeaderTotalEffect(props) {

    const setDisabled = props.setDisabled;
    const setUserData = props.setUserData;
    const setSubscriptions = props.setSubscriptions;
    const setFreeTrial = props.setFreeTrial;
    const state = props.state;
    const path = props.path;
	const setUserId = props.setUserId;
    const navigate = useNavigate();
    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
		async function fetchUserData() {
			const collections = ["Users", "Subscriptions"];
			const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
				const userId = getUserId.userId;
				setUserId(userId);

				const today = new Date();
				const todaySeconds = today.getTime() / 1000;

				const emailVerified = getUserId.emailVerified;
				if ( emailVerified === false && path !== "/profile" && path !== "/verify-email" && path !== "/email-verification" && path !== "/email-verification-complete" && path !== "/describe-you" && path !== "/welcome" && path !== "/demo-confirmation" && path !== "/pay" && path !== "/pricing" ) {
					await getUserId.user.reload();
                    const refreshedUser = getUserId;
                    if ( refreshedUser.false === true ) {
                        navigate("/verify-email");
                    }
				}

				const docRef = userId;
				const query = await getUserDocuments(collections, docRef);
				if ( query[0].status === 200 ) {
					setDisabled(false);
					const data = query[0].data;
					setUserData(data);

					// 3 different user types

					// 1. Paid users who need grandfathering
					// 2. Free Trial users who need grandfathering
					// 3. New users who DON'T need grandfathering (further split into paid vs free)

					const userFreeTrial = data.freeTrial;
					const checkSubscriptions = query[1].status === 200 ? query[1].data : [];
					renewFreeTrial(userFreeTrial, checkSubscriptions, userId, data, setUserData);

					if ( data.repeatUser === true && path !== "/profile" ) {
						// User who has created 2 accounts and gotten found out

						if ( path !== "/repeat-user" ) {
							navigate("/repeat-user", {
								state: state
							});
							recordEvent("Sent to Repeat User", {});
						}
					}
					else if ( query[1].status === 200 ) {
						// User who has subscriptions on new system
						setSubscriptions(query[1].data);
						
						const subscriptionData = query[1].data;
						const activeSubscription = subscriptionData.findIndex(e => e.endDate.seconds > todaySeconds);
						if ( activeSubscription === -1 ) {
							setFreeTrial(userFreeTrial);
						}
					}
					else {
						setFreeTrial(userFreeTrial);
					}

					// Mixpanel data setting
					if ( data.email !== undefined ) {
						const name = `${data.firstName} ${data.lastName}`;
						const email = data.email.toLowerCase();
	
						const vals = {
							name: name,
							email: email,
							userId: userId,
							subscriptions: query[1].status === 200 ? query[1].data : [],
							user: getUserId,
							userFreeTrial: userFreeTrial,
							todaySeconds: todaySeconds,
							emailVerified: emailVerified
						};
						await mixpanelDataSetup(vals);
					}
				}
				else {
					await checkUserDocumentExists(getUserId, navigate)
					setDisabled(false);
				}
			}
			else {
				setDisabled(false);
			}
		}

		if ( firstRender === false ) {
			setFirstRender(true);
			fetchUserData();
		}
		else {
			setDisabled(false);
		}
	  
	}, [
		firstRender, 
		navigate, 
		path, 
		setDisabled, 
		setFreeTrial, 
		setSubscriptions, 
		setUserData, 
		setUserId,
		state
	]);
};

export default HeaderTotalEffect;