import React from 'react';

function FreeClear(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="22" 
            height="22" 
            viewBox="0 0 22 22" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M11.6441 0.516077C11.2895 0.156099 10.7105 0.156099 10.3559 0.516077L0.325743 10.6995C0.0618859 10.9674 0.0642626 11.3993 0.331051 11.6643C0.59784 11.9292 1.02801 11.9268 1.29187 11.6589L10.5977 2.21096C10.8189 1.98638 11.1811 1.98637 11.4023 2.21096L20.7081 11.6589C20.972 11.9268 21.4021 11.9292 21.6689 11.6643C21.9357 11.3993 21.9381 10.9674 21.6742 10.6995L11.6441 0.516077ZM3.74628 11.9931C3.69361 11.62 3.34974 11.3605 2.97823 11.4134C2.60672 11.4663 2.34824 11.8115 2.40091 12.1846L3.44834 19.6032C3.62251 20.8367 4.67411 21.7535 5.91486 21.7535H11.1951C11.5719 21.7535 11.8773 21.4481 11.8773 21.0713C11.8773 20.6945 11.5719 20.3891 11.1951 20.3891H5.91486C5.35088 20.3891 4.87288 19.9723 4.79371 19.4117L3.74628 11.9931ZM15.6353 18.5975H15.601C15.3539 18.5843 15.1342 18.4452 15.0243 18.2332C14.633 17.4714 14.2416 17.0806 14.2347 17.0806C13.9738 16.8223 13.9738 16.3983 14.2347 16.1466C14.5025 15.8883 14.935 15.8883 15.2028 16.1466C15.2371 16.1797 15.4294 16.3718 15.6834 16.7229C16.2807 15.981 17.2145 14.9874 18.1482 14.6628C18.5052 14.537 18.8966 14.7224 19.027 15.0669C19.1506 15.4113 18.9652 15.7889 18.6082 15.9148C17.8324 16.1797 16.727 17.5178 16.2121 18.2928C16.0885 18.4849 15.8688 18.5975 15.6353 18.5975ZM11.3506 16.6104C11.3506 19.4468 13.6573 21.7535 16.4937 21.7535C19.3301 21.7535 21.6368 19.4468 21.6368 16.6104C21.6368 13.774 19.3301 11.4673 16.4937 11.4673C13.6573 11.4673 11.3506 13.774 11.3506 16.6104ZM12.6917 16.6104C12.6917 14.5116 14.3949 12.8084 16.4937 12.8084C18.5925 12.8084 20.2957 14.5116 20.2957 16.6104C20.2957 18.7092 18.5925 20.4124 16.4937 20.4124C14.3949 20.4124 12.6917 18.7092 12.6917 16.6104Z" 
                fill={fill}
            />
        </svg>
    )
}

export default FreeClear;