import React from 'react';

function Circle(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="12" 
            height="12" 
            viewBox="0 0 12 12" 
            fill="none"
            className={className}
        >
            <circle 
                cx="5.56315" 
                cy="6.00003" 
                r="5" 
                transform="rotate(0.812867 5.56315 6.00003)" 
                fill={fill}
                stroke="#FFFEFC"
            />
        </svg>
    )
};

export default Circle;