import React from 'react';

function Indicator(props) {
    const index = props.index;
    const filledColour = props.filledColour;
    const unfilledColour = props.unfilledColour;
    const maxNumber = props.maxNumber;
    const remainingNumber = props.remainingNumber;

    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="32" 
            height="8" 
            viewBox="0 0 32 8" 
            fill="none"
        >
            {
                index === 0 ?
                <path 
                    d="M0 4C0 1.79086 1.79086 0 4 0H32V8H4C1.79086 8 0 6.20914 0 4Z" 
                    fill={remainingNumber > index ? filledColour : unfilledColour}
                />
                :
                index === (maxNumber - 1) ?
                <path 
                    d="M0 0H28C30.2091 0 32 1.79086 32 4C32 6.20914 30.2091 8 28 8H0V0Z" 
                    fill={remainingNumber > index ? filledColour : unfilledColour}
                />
                :
                <rect 
                    width="32" 
                    height="8" 
                    fill={remainingNumber > index ? filledColour : unfilledColour}
                />
            }
        </svg>
    )
};

export default Indicator;