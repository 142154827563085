import React from 'react';

function Chevron(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M15.7576 4.23289C15.4344 3.92178 14.9104 3.92178 14.5872 4.23289L8 10.5739L1.41278 4.23289C1.08959 3.92178 0.565588 3.92178 0.242395 4.23289C-0.0807968 4.54401 -0.0807969 5.04842 0.242395 5.35953L8 12.8271L15.7576 5.35953C16.0808 5.04842 16.0808 4.54401 15.7576 4.23289Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Chevron;