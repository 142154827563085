import React from 'react';

function Floodzone(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="20" 
            viewBox="0 0 24 20" 
            fill={fill}
            className={className}
        >
            <path 
                d="M6.81069 5.51399C9.03206 5.51399 10.7721 4.5696 12.3333 3.72227L12.3878 3.69264C14.0002 2.8177 15.4332 2.05453 17.2431 2.05453C18.8418 2.05453 20.0089 2.45315 20.7653 2.83735C21.1449 3.03016 21.4231 3.22048 21.6014 3.35741C21.6905 3.42585 21.7545 3.48078 21.7934 3.51578C21.8128 3.53327 21.826 3.54576 21.8329 3.55241L21.8361 3.55555L21.838 3.55737C22.1291 3.85429 22.6057 3.86077 22.9048 3.57104C23.205 3.28023 23.2126 2.80114 22.9218 2.50096L22.3873 3.01874C22.9218 2.50095 22.9215 2.50067 22.9212 2.50039L22.9207 2.49978L22.9193 2.49845L22.9163 2.4953L22.9116 2.49059L22.9082 2.48716C22.9019 2.48093 22.894 2.47308 22.8843 2.46374C22.865 2.44506 22.8388 2.42038 22.8056 2.39057C22.7394 2.33098 22.6454 2.25078 22.5233 2.15705C22.2793 1.96966 21.9224 1.72754 21.4507 1.48793C20.5044 1.00726 19.1039 0.541016 17.2431 0.541016C15.0218 0.541016 13.2818 1.4854 11.7206 2.33274L11.666 2.36236C10.0536 3.2373 8.62065 4.00047 6.81069 4.00047C5.2117 4.00047 4.02955 3.60166 3.25723 3.2155C2.86983 3.0218 2.58396 2.83043 2.39988 2.69237C2.30789 2.62337 2.24155 2.56787 2.20091 2.53231C2.1806 2.51454 2.16675 2.50178 2.15935 2.49484C2.15622 2.49191 2.15425 2.49002 2.15344 2.48924C1.85766 2.19687 1.38087 2.19792 1.0864 2.49239C0.790863 2.78793 0.790863 3.26708 1.08639 3.56261L1.61253 3.03647C1.0864 3.56261 1.08666 3.56288 1.08694 3.56315L1.08752 3.56373L1.08881 3.56502L1.09189 3.56806C1.09416 3.57029 1.09687 3.57294 1.10002 3.576C1.10632 3.5821 1.11438 3.58981 1.1242 3.59901C1.14383 3.61741 1.17051 3.64182 1.20426 3.67134C1.27172 3.73038 1.36755 3.81001 1.49177 3.90318C1.74012 4.08944 2.1029 4.3305 2.58037 4.56923C3.53777 5.04793 4.95022 5.51399 6.81069 5.51399Z" 
                fill={fill}
            />
            <path 
                d="M6.81069 10.1626C9.03206 10.1626 10.7721 9.21825 12.3333 8.37092L12.3878 8.34129C14.0002 7.46635 15.4332 6.70318 17.2431 6.70318C18.8418 6.70318 20.0089 7.1018 20.7653 7.48599C21.1449 7.67881 21.4231 7.86913 21.6014 8.00606C21.6905 8.07449 21.7545 8.12943 21.7934 8.16443C21.8128 8.18192 21.826 8.1944 21.8329 8.20106L21.8361 8.20419L21.838 8.20602C22.1291 8.50294 22.6057 8.50942 22.9048 8.21969C23.205 7.92888 23.2126 7.44979 22.9218 7.1496L22.3873 7.66739C22.9218 7.1496 22.9215 7.14932 22.9212 7.14904L22.9207 7.14843L22.9193 7.14709L22.9163 7.14395L22.9116 7.13924L22.9082 7.13581C22.9019 7.12957 22.894 7.12173 22.8843 7.11239C22.865 7.09371 22.8388 7.06903 22.8056 7.03922C22.7394 6.97963 22.6454 6.89943 22.5233 6.8057C22.2793 6.61831 21.9224 6.37619 21.4507 6.13658C20.5044 5.65591 19.1039 5.18966 17.2431 5.18966C15.0218 5.18967 13.2818 6.13405 11.7206 6.98139L11.666 7.01101C10.0536 7.88595 8.62065 8.64912 6.81069 8.64912C5.2117 8.64912 4.02955 8.25031 3.25723 7.86415C2.86983 7.67045 2.58396 7.47908 2.39988 7.34102C2.30789 7.27202 2.24155 7.21652 2.20091 7.18096C2.1806 7.16319 2.16675 7.15043 2.15935 7.14349C2.15622 7.14056 2.15425 7.13867 2.15344 7.13789C1.85766 6.84551 1.38087 6.84656 1.0864 7.14104C0.790863 7.43657 0.790863 7.91573 1.08639 8.21126L1.61253 7.68512C1.0864 8.21126 1.08666 8.21153 1.08694 8.2118L1.08752 8.21238L1.08881 8.21367L1.09189 8.21671C1.09416 8.21894 1.09687 8.22159 1.10002 8.22464C1.10632 8.23075 1.11438 8.23845 1.1242 8.24766C1.14383 8.26606 1.17051 8.29047 1.20426 8.31999C1.27172 8.37903 1.36755 8.45866 1.49177 8.55183C1.74012 8.73809 2.1029 8.97915 2.58037 9.21788C3.53777 9.69658 4.95022 10.1626 6.81069 10.1626Z" 
                fill={fill}
            />
            <path 
                d="M12.3333 13.0196C10.7721 13.8669 9.03206 14.8113 6.81069 14.8113C4.95022 14.8113 3.53777 14.3452 2.58037 13.8665C2.1029 13.6278 1.74012 13.3867 1.49177 13.2005C1.36755 13.1073 1.27172 13.0277 1.20426 12.9686C1.17051 12.9391 1.14383 12.9147 1.1242 12.8963C1.11438 12.8871 1.10632 12.8794 1.10002 12.8733C1.09687 12.8702 1.09416 12.8676 1.09189 12.8654L1.08881 12.8623L1.08752 12.861L1.08694 12.8604C1.08666 12.8602 1.0864 12.8599 1.61253 12.3338L1.08639 12.8599C0.790863 12.5644 0.790863 12.0852 1.0864 11.7897C1.38087 11.4952 1.85766 11.4942 2.15344 11.7865C2.15425 11.7873 2.15622 11.7892 2.15935 11.7921C2.16675 11.7991 2.1806 11.8118 2.20091 11.8296C2.24155 11.8652 2.30789 11.9207 2.39988 11.9897C2.58396 12.1277 2.86983 12.3191 3.25723 12.5128C4.02955 12.899 5.2117 13.2978 6.81069 13.2978C8.62065 13.2978 10.0536 12.5346 11.666 11.6597L11.7206 11.63C13.2818 10.7827 15.0218 9.83831 17.2431 9.83831C19.1039 9.83831 20.5044 10.3046 21.4507 10.7852C21.9224 11.0248 22.2793 11.267 22.5233 11.4544C22.6454 11.5481 22.7394 11.6283 22.8056 11.6879C22.8388 11.7177 22.865 11.7424 22.8843 11.761C22.894 11.7704 22.9019 11.7782 22.9082 11.7845L22.9116 11.7879L22.9163 11.7926L22.9193 11.7957L22.9207 11.7971L22.9212 11.7977C22.9215 11.798 22.9218 11.7983 22.3873 12.316L22.9218 11.7983C23.2126 12.0984 23.205 12.5775 22.9048 12.8683C22.6057 13.1581 22.1291 13.1516 21.838 12.8547L21.8361 12.8528L21.8329 12.8497C21.826 12.8431 21.8128 12.8306 21.7934 12.8131C21.7545 12.7781 21.6905 12.7231 21.6014 12.6547C21.4231 12.5178 21.1449 12.3275 20.7653 12.1346C20.0089 11.7504 18.8418 11.3518 17.2431 11.3518C15.4332 11.3518 14.0002 12.115 12.3878 12.9899L12.3333 13.0196Z" 
                fill={fill}
            />
            <path 
                d="M6.81069 19.4599C9.03206 19.4599 10.7721 18.5156 12.3333 17.6682L12.3878 17.6386C14.0002 16.7636 15.4332 16.0005 17.2431 16.0005C18.8418 16.0005 20.0089 16.3991 20.7653 16.7833C21.1449 16.9761 21.4231 17.1664 21.6014 17.3034C21.6905 17.3718 21.7545 17.4267 21.7934 17.4617C21.8128 17.4792 21.826 17.4917 21.8329 17.4984L21.8361 17.5015L21.838 17.5033C22.1291 17.8002 22.6057 17.8067 22.9048 17.517C23.205 17.2262 23.2126 16.7471 22.9218 16.4469L22.3873 16.9647C22.9218 16.4469 22.9215 16.4466 22.9212 16.4463L22.9207 16.4457L22.9193 16.4444L22.9163 16.4413L22.9116 16.4365L22.9082 16.4331C22.9019 16.4269 22.894 16.419 22.8843 16.4097C22.865 16.391 22.8388 16.3663 22.8056 16.3365C22.7394 16.2769 22.6454 16.1967 22.5233 16.103C22.2793 15.9156 21.9224 15.6735 21.4507 15.4339C20.5044 14.9532 19.1039 14.487 17.2431 14.487C15.0218 14.487 13.2818 15.4313 11.7206 16.2787L11.666 16.3083C10.0536 17.1833 8.62065 17.9464 6.81069 17.9464C5.2117 17.9464 4.02955 17.5476 3.25723 17.1614C2.86983 16.9677 2.58396 16.7764 2.39988 16.6383C2.30789 16.5693 2.24155 16.5138 2.20091 16.4783C2.1806 16.4605 2.16675 16.4477 2.15935 16.4408C2.15622 16.4379 2.15425 16.436 2.15344 16.4352C1.85766 16.1428 1.38087 16.1439 1.0864 16.4383C0.790863 16.7339 0.790863 17.213 1.08639 17.5086L1.61253 16.9824C1.0864 17.5086 1.08666 17.5088 1.08694 17.5091L1.08752 17.5097L1.08881 17.511L1.09189 17.514C1.09416 17.5162 1.09687 17.5189 1.10002 17.5219C1.10632 17.528 1.11438 17.5358 1.1242 17.545C1.14383 17.5634 1.17051 17.5878 1.20426 17.6173C1.27172 17.6763 1.36755 17.756 1.49177 17.8491C1.74012 18.0354 2.1029 18.2764 2.58037 18.5152C3.53777 18.9939 4.95022 19.4599 6.81069 19.4599Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Floodzone;