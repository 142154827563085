import React from 'react';
import '../styles/SettingsElement.css';
import { Switch, FinancialsInput, Button, SelectionChip, Dropdown, MobileRadio } from './';

function SettingsElement(props) {
    const item = props.item;
    const dropdownOpen = props.dropdownOpen;
    const setDropdownOpen = props.setDropdownOpen;
    const innerWidth = props.innerWidth;

    const toggleDropdown = () => {
        if ( setDropdownOpen !== null ) {
            setDropdownOpen(!dropdownOpen);
        }
    };

    return (
        <div className="settings-element-container">
            <div className="settings-element-mobile-title-container desktop-none">
                <h3 className="heading-small-semibold colour-primary">
                    {item.title}
                </h3>
            </div>
            <div className="settings-spacing-container desktop-none"></div>
            <div className="settings-element-left-container">
                <div className="settings-element-title-container">
                    <h3 className="body-semibold colour-primary mobile-none">
                        {item.title}
                    </h3>
                    {
                        item.defaultSwitch === true &&
                        <h3 className="body-semibold colour-primary desktop-none">
                            Use default
                        </h3>
                    }
                    {
                        item.description !== null &&
                        <span className="body-regular colour-secondary">
                            {item.description}
                        </span>
                    }
                </div>
                {
                    item.defaultSwitch === true &&
                    <Switch
                        val={(item.value === item.defaultVal) || (Number(item.value) === Number(item.defaultVal)) ? true : false}
                        setVal={() => item.onChange(item.defaultVal)}
                        label={innerWidth < 1000 ? null : "Use default"}
                    />
                }
            </div>
            <div className="settings-element-right-container">
                {
                    item.input === true ?
                    <div className="settings-element-input-container">
                        <FinancialsInput
                            label={null}
                            labelType={null}
                            info={false}
                            value={item.value}
                            onChange={(text) => item.onChange(text.target.value)}
                            placeholder={item.placeholder}
                            type="text"
                            leftUnit={item.leftUnit}
                            rightUnit={item.rightUnit}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={false}
                            multiline={false}
                            error={false}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    :
                    item.chip === true && innerWidth >= 1000 ?
                    <div className="settings-element-chip-row">
                        {
                            item.options.map((option, index) =>
                                <SelectionChip
                                    label={option.label}
                                    selected={option.included}
                                    disabled={false}
                                    func={() => item.onChange(option.value)}
                                    index={index}
                                    leftIcon={null}
                                    rightIcon={null}
                                    key={index}
                                />
                            )
                        }
                    </div>
                    :
                    item.chip === true && innerWidth < 1000 ?
                    <div className="settings-element-chip-row">
                        {
                            item.options.map((option, index) =>
                                <MobileRadio
                                    label={option.label}
                                    selected={option.included}
                                    index={index}
                                    clickFunc={() => item.onChange(option.value)}
                                    key={index}
                                    disabled={false}
                                />
                            )
                        }
                    </div>
                    :
                    item.dropdown === true ?
                    <Dropdown
                        open={dropdownOpen}
                        icon={null}
                        label={item.options.filter(option => option.value === item.value)[0].label}
                        disabled={false}
                        options={item.options}
                        multiselect={false}
                        toggleDropdown={toggleDropdown}
                        cta={(val) => item.onChange(val.value)}
                        sidealign={"left"}
                        topalign={"bottom"}
                        indexSelect={false}
                        selectedIndex={null}
                    />
                    :
                    null
                }
                <div className="settings-element-button-container mobile-none">
                    <Button
                        buttonType="primary-button"
                        text={"Save"}
                        cta={() => item.cta()}
                        disabled={item.disabled}
                        leftIcon={null}
                        rightIcon={null}
                        alt=""
                        size={item.size}
                        standardIcon={true}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
        </div>
    )
};

export default SettingsElement;