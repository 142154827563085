import React from 'react';
import '../styles/ShareWithAgent.css';
import { Button, FinancialsInput } from '../components';
import { CopyKeychain } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function ShareWithAgent(props) {
    const property = props.property;
    const acquisitionStrategy = props.acquisitionStrategy;
    const exitStrategy = props.exitStrategy;
    const setTextCopiedToast = props.setTextCopiedToast;
    const origin = window.location.origin;
    const cityId = property.countyId === null ? property.cityId : `CTY${property.countyId}`;
    const link = `${origin}/properties/${cityId}/${property.zpid}?acquisition=${acquisitionStrategy}&exit=${exitStrategy}`;

    const copyLink = () => {
        navigator.clipboard.writeText(link)
        .then(() => {
            setTextCopiedToast(true);
        })
        .catch((err) => {
            console.error("Failed to copy text: ", err);
        });
    };

    return (
        <div className="share-with-agent-row-container">
            <div className="share-with-agent-input-container">
                <FinancialsInput
                    label={null}
                    labelType={null}
                    info={false}
                    value={link}
                    onChange={null}
                    placeholder={null}
                    type="text"
                    leftUnit={null}
                    rightUnit={null}
                    unitSwitch={false}
                    leftUnitSelected={true}
                    unitSwitchFunc={null}
                    disabled={true}
                    multiline={false}
                    error={false}
                    onMouseEnter={null}
                    onMouseLeave={null}
                    reset={false}
                    resetFunc={null}
                    resetPosition={null}
                />
            </div>
            <div className="share-with-agent-button-container">
                <Button
                    buttonType="primary-button"
                    size="medium"
                    text={"Copy link"}
                    cta={copyLink}
                    disabled={false}
                    leftIcon={<CopyKeychain clickFunc={null} fill={colour.grayScaleWhite} className="" />}
                    leftIconClass=""
                    rightIcon={null}
                    rightIconClass=""
                    alt=""
                    standardIcon={false}
                    badge={false}
                    badgeVal={null}
                    badgeBG={null}
                    buttonRef={null}
                />
            </div>
        </div>
    )
};

export default ShareWithAgent;