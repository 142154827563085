import React, { useEffect, useState } from 'react';
import '../styles/GenerateURL.css';
import { checkOnAuthStateChanged, getDocument, makeId, setData } from '../functions';
import { useNavigate } from 'react-router-dom';
import { Footer, Header } from '../components';
import { ActionButton, citiesForPurchase, colour, CustomSelect, fontFamily, FormInput } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function GenerateURL() {
    const [city, setCity] = useState("");
    const [url, setURL] = useState("");
    const [existingURLs, setExistingURLs] = useState([]);
    const [urlType, setUrlType] = useState("");
    const [disabled, setDisabled] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Generate URL | Coffee Clozers";
        const checkUser = async() => {
			const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
                const userId = getUserId.userId;
                if ( userId !== "mcLtoqeEVFZdvDopvGwYuGK9TB12" && userId !== "kE4LMakdUAOEJIDVTeKpEqbC4gF2" && userId !== "HHTwL0UD3fQmNcIyL3gpvnyZdCM2" && userId !== "3Y07zDu9wibxwnt3Oe6Nsv6qRtp1" ) {
                    navigate('/404');
                }
                else {
                    const colRef = "Unique URLs";
                    const docRef = "URLs";
                    const queryData = await getDocument(colRef, docRef);
                    if ( queryData.status === 200 ) {
                        setExistingURLs(queryData.data.data);
                        setDisabled(false);
                    }
                    else {
                        setDisabled(false);
                    }
                }
            }
            else {
                navigate('/404');
            }
        };

        checkUser();
    }, [navigate]);

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: fontFamily
		}
    };

    const createURL = async() => {
        setDisabled(true);
        const generateId = await makeId(15);
        const idIndex = existingURLs.findIndex(item => item.id === generateId);
        if ( idIndex === -1 ) {
            const today = new Date();
            const expiryDate = today.setDate(today.getDate() + 2);
            const cityId = citiesForPurchase[city].city_id;
            
            const newObject = {
                id: generateId,
                city: cityId,
                startDate: today,
                endDate: expiryDate,
                type: urlType,
                url: `https://app.coffeeclozers.com/properties-list/${cityId}?id=${generateId}`
            };
            await writeNewObject(newObject);
        }
        else {
            createURL();
        }
    };

    const writeNewObject = async(newObject) => {
        const colRef = "Unique URLs";
        const docRef = "URLs";
        existingURLs.push(newObject);
        const write = await setData(colRef, docRef, existingURLs);
        if ( write.status === 200 ) {
            setURL(newObject.url);
            setDisabled(false);
        }
        else {
            alert("ERROR...check console");
            setDisabled(false);
        }
    }

    const copyVal = () => {
        navigator.clipboard.writeText(url);
    }

    return (
        <div className="generate-url-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="generate-url-inner-container">
                <div className="generate-url-title-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Generate URL
                    </h1>
                </div>
                <div className="generate-url-pick-city">
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                labelId="city-select-label"
                                id="city-select"
                                value={city}
                                label=""
                                onChange={(event) => setCity(event.target.value)}
                                input={<CustomSelect />}
                            >   
                                <MenuItem 
                                    value={"placeholder"}
                                    style={styles.menuItem}
                                    disabled
                                >
                                    Choose your city
                                </MenuItem>	
                                {
                                    citiesForPurchase.map((item, index) =>
                                        <MenuItem 
                                            key={index}
                                            value={index}
                                            style={styles.menuItem}
                                        >
                                            {item.cityUnchanged}, {item.state}
                                        </MenuItem>	
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <div className="generate-url-type-container">
                        <FormInput
                            value={urlType}
                            type="text"
                            label="Type"
                            id="type-input"
                            onChange={(text) => setUrlType(text.target.value)}
                        />
                    </div>
                </div>
                {
                    url === "" ?
                    null
                    :
                    <div className="generate-url-url-container">
                        <span className="body-regular colour-primary text-align-center block-text">
                            {url}
                        </span>
                    </div>
                }
                <div className="generate-url-button-container">
                    <ActionButton
                        onClick={() => url === "" ? createURL() : copyVal()}
                        disabled={city === "" || urlType === "" ? true : disabled}
                    >
                        {
                            url === "" ?
                            "Generate URL"
                            :
                            "Copy To Clipboard"
                        }
                    </ActionButton>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GenerateURL;