import React from 'react';
import '../styles/MortgageTypeFilter.css';
import { FilterReset, SelectionChip } from './';

function MortgageTypeFilter(props) {
    const mortgageType = props.mortgageType;
    const setMortgageType = props.setMortgageType;
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;

    const mortgageTypeOptions = [
        {
            label: "Any",
            value: "",
            selected: mortgageType === "" ? true : false
        },
        {
            label: "Conventional",
            value: "COV",
            selected: mortgageType === "COV" ? true : false
        },
        {
            label: "FHA",
            value: "FHA",
            selected: mortgageType === "FHA" ? true : false
        },
        {
            label: "VA",
            value: "VA",
            selected: mortgageType === "VA" ? true : false
        }
    ];

    const changeMortgageType = (value) => {
        setMortgageType(value);
    };

    return (
        <div className="mortgage-type-filter-outer-container">
            <div className="mortgage-type-filter-container">
                <div className="mortgage-type-title-container">
                    <h3 className="body-semibold colour-primary">
                        Mortgage type
                    </h3>
                    <span className="body-regular colour-secondary">
                        Filter by the type of mortgage on the property
                    </span>
                </div>
                <div className="mortgage-type-body-container">
                    {
                        mortgageTypeOptions.map((item, index) =>
                            <SelectionChip
                                key={index}
                                label={item.label}
                                selected={item.selected}
                                disabled={false}
                                func={() => changeMortgageType(item.value)}
                                index={index}
                                leftIcon={null}
                                rightIcon={null}
                            />
                        )
                    }
                </div>
                {
                    reset === true &&
                    <FilterReset
                        clickFunc={() => resetFilterType("mortgageType")}
                    />
                }
            </div>
        </div>
    )
};

export default MortgageTypeFilter;