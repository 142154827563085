import React, { useState } from 'react';
import '../styles/Location.css';
import { ExitScreen, LocationMap } from './';
import { recordEvent } from '../functions';
import { Switch, Dropdown } from '../v4/components';

function Location(props) {

	const property = props.property;
	const cityState = `${property.city} ${property.state}`;
	const neighbourhoodData = props.neighbourhoodData;
	const neighbourhoodBounds = props.neighbourhoodBounds;
	const hasUndefinedNeighborhoodGrade = neighbourhoodData.some(e => e.neighborhoodGrade === undefined);
	const setPremiumModal = props.setPremiumModal;
	const [neighbourhoodOption, setNeighbourhoodOption] = useState(hasUndefinedNeighborhoodGrade === true ? 1 : 0);
	const [hideHeatmap, setHideHeatmap] = useState(true);
	const [mapTypeDropdownOpen, setMapTypeDropdownOpen] = useState(false);

	const lowerCaseCity = cityState.toLowerCase();
	const formattedCity = lowerCaseCity.replace(" ", "-");
	const checkFullStop = formattedCity.replace(".", "");
	const crimeGradeURL = `https://crimegrade.org/safest-places-in-${checkFullStop}/`;

	const neighbourhoodDataOptions = [
		{
			label: "Neighborhood grade",
			location: ""
		},
		{
			label: "Median household income",
			location: "economics",
			subLocation: "medianHouseholdIncome"
		},
		{
			label: "Education (graduate degree)",
			location: "economics",
			subLocation: "ratioEducationGraduateDegree",
		},
		{
			label: "Employed",
			location: "economics",
			subLocation: "ratioEmployed"
		},
		{
			label: "Owner occupied",
			location: "economics",
			subLocation: "ratioOwnerOccupied"
		},
		{
			label: "Vacant",
			location: "economics",
			subLocation: "ratioVacant"
		}
	];

	const openCrimeMap = () => {
		window.open(crimeGradeURL, "_blank");
		recordEvent("Open Crime Map", {
			city: cityState
		});
	};

	const openUpgradeModal = () => {
		if ( setPremiumModal !== null ) {
			setPremiumModal(true);
		}
	};

	const toggleDropdown = () => {
		setMapTypeDropdownOpen(!mapTypeDropdownOpen);
	};

	const onChangeNeighbourhoodOption = (val) => {
		setHideHeatmap(false);
		setNeighbourhoodOption(val);
		recordEvent("Change Neighbourhood Option", {
			"Neighbourhood Option": neighbourhoodDataOptions[val].label
		});
    };

	const selectOption = (val) => {
		const neighbourhoodIndex = neighbourhoodDataOptions.findIndex(e => e.label === val.label);
		onChangeNeighbourhoodOption(neighbourhoodIndex);
        setMapTypeDropdownOpen(false);
	};

	const toggleHideHeatmap = () => {
		setHideHeatmap(!hideHeatmap);
	};

	return (
		<div className="location-outer-container relative-container">
			{
				mapTypeDropdownOpen === true &&
				<ExitScreen
					clickScreen={() => toggleDropdown()}
				/>
			}
			<div className="location-inner-container">
				<div className="location-top-bar-container">
					<div className="location-title-container">
						<h2 className="heading-small-regular colour-primary">
							Location
						</h2>
					</div>
					<div className="location-top-selector-container">
						{
							hasUndefinedNeighborhoodGrade === true ?
							<div className="location-upgrade-prompt-container">
								<div 
									className="text-button button-row"
									onClick={() => openUpgradeModal()}
								>
									<span className="body-regular colour-link block-text subtext-margin-right">
										🚀 Browse on-market listings and unlock heatmaps
									</span>
									<svg 
										xmlns="http://www.w3.org/2000/svg" 
										width="16" 
										height="16" 
										viewBox="0 0 16 16" 
										fill="none"
									>
										<path 
											d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
											fill="#2F4858"
										/>
									</svg>
								</div>
							</div>
							:
							null
						}
						{
							hasUndefinedNeighborhoodGrade === false &&
							<Switch
								val={!hideHeatmap}
								setVal={() => toggleHideHeatmap()}
								label="Show heatmap"
							/>
						}
						{
							hasUndefinedNeighborhoodGrade === false &&
							<div className="property-list-top-bar-dropdown-container">
								<Dropdown
									open={mapTypeDropdownOpen}
									icon={null}
									label={hideHeatmap === true ? "Map view" : neighbourhoodDataOptions[neighbourhoodOption].label}
									disabled={false}
									options={neighbourhoodDataOptions}
									multiselect={false}
									toggleDropdown={toggleDropdown}
									cta={selectOption}
									sidealign={"right"}
									topalign={"bottom"}
									indexSelect={hideHeatmap === true ? false : true}
									selectedIndex={neighbourhoodOption}
								/>
							</div>	
						}
					</div>
				</div>
				<LocationMap
					property={property}
					controls={false}
					text={true}
					neighbourhoodBounds={neighbourhoodBounds}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodOption={neighbourhoodOption}
					neighbourhoodDataOptions={neighbourhoodDataOptions}
					hideHeatmap={hideHeatmap}
				/>
				<div className="location-crime-map-container">
					<span 
						className="body-regular-underline colour-link"
						onClick={() => openCrimeMap()}
					>
						View crime map
					</span>
				</div>
			</div>
		</div>
	)
}

export default Location;