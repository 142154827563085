import React from 'react';
import '../styles/CreditCard.css';
import { Amex, Visa, MasterCard, JCB, Discover } from '../assets/credit-cards';

function CreditCard(props) {
    const card = props.creditCard;
    const cardType = card.brand === "visa" ? Visa : card.brand === "mastercard" ? MasterCard: card.brand === "amex" ? Amex : card.brand === "jcb" ? JCB : card.brand === "discover" ? Discover : null;
    const deleteCard = props.deleteCard;
    const showDelete = props.showDelete;

    return (
        <div className="credit-card-outer-container">
            <div className="credit-card-inner-container">
                {
                    cardType === null ?
                    <span className="body-semibold colour-primary">
                        {card.brand}
                    </span>
                    :
                    <img
                        src={cardType}
                        className="credit-card-icon"
                        alt={card.brand}
                    />
                }
                <div className="credit-card-text-container">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        <span className="text-capitalise">
                            {card.brand}
                        </span>
                        {' '}ending in {card.last4}
                    </h3>
                    <span className="body-regular colour-primary block-text margin-x-small">
                        Expiration {card.exp_month < 10 ? "0" : null}{card.exp_month}/{card.exp_year}
                    </span>
                    {
                        showDelete === true &&
                        <span 
                            className="body-regular-underline colour-link block-text"
                            onClick={() => deleteCard()}
                        >
                            Delete to change
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default CreditCard;