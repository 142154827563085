import React from 'react';
import '../styles/BuyBoxStrategies.css';
import { MobileRadio, SelectionChip } from './';

function BuyBoxStrategies(props) {
    const acquisitionStrategy = props.acquisitionStrategy;
    const exitStrategy = props.exitStrategy;
    const onChangeAcquisitionStrategy = props.onChangeAcquisitionStrategy;
    const onChangeExitStrategy = props.onChangeExitStrategy;
    const creativeUser = props.creativeUser;
    const strategyError = props.strategyError;

    const acquisitionStrategyOptions = [
        {
            selected: acquisitionStrategy === "m" ? true : false,
            label: "Mortgage",
            disabled: exitStrategy === "lo" || exitStrategy === "brrrr" ? true : false,
            labelOnly: false,
            value: "m"
        },
        {
            selected: acquisitionStrategy === "c" ? true : false,
            label: "Cash",
            disabled: exitStrategy === "lo" ? true : false,
            labelOnly: false,
            value: "c"
        },
        {
            selected: acquisitionStrategy === "sf" ? true : false,
            label: "Seller-finance",
            disabled: exitStrategy === "brrrr" ? true : !creativeUser,
            labelOnly: false,
            value: "sf"
        },
        {
            selected: acquisitionStrategy === "sub" ? true : false,
            label: "Subto",
            disabled: exitStrategy === "brrrr" ? true : !creativeUser,
            labelOnly: false,
            value: "sub"
        }
    ];

    const exitStrategyOptions = [
        {
            selected: exitStrategy === "ltr" ? true : false,
            label: "Long term rental (LTR)",
            disabled: false,
            labelOnly: false,
            value: "ltr"
        },
        {
            selected: exitStrategy === "brrrr" ? true : false,
            label: "BRRRR",
            disabled: acquisitionStrategy !== "c" ? true : false,
            labelOnly: false,
            value: "brrrr"
        },
        {
            selected: exitStrategy === "hh" ? true : false,
            label: "Househack",
            disabled: false,
            labelOnly: false,
            value: "hh"
        },
        {
            selected: exitStrategy === "lo" ? true : false,
            label: "Lease option",
            disabled: acquisitionStrategy === "c" || acquisitionStrategy === "m" ? true : !creativeUser,
            labelOnly: false,
            value: "lo"
        }
    ];
    
    return (
        <div className="buy-box-strategies-container">
            <div className="buy-box-strategies-element">
                <h2 className="body-semibold colour-primary">
                    Acquisition strategy
                </h2>
                <div className="buy-box-strategies-row mobile-none">
                    {
                        acquisitionStrategyOptions.map((item, index) =>
                            <SelectionChip
                                key={index}
                                label={item.label}
                                selected={item.selected}
                                disabled={item.disabled}
                                func={() => onChangeAcquisitionStrategy(item.value)}
                                index={index}
                                leftIcon={null}
                                rightIcon={null}
                            />
                        )
                    }
                </div>
                <div className="buy-box-mobile-strategies-column desktop-none">
                    {
                        acquisitionStrategyOptions.map((item, index) =>
                            <MobileRadio
                                label={item.label}
                                selected={item.selected}
                                index={index}
                                clickFunc={() => onChangeAcquisitionStrategy(item.value)}
                                key={index}
                                disabled={item.disabled}
                            />
                        )
                    }
                </div>
            </div>
            <div className="buy-box-strategies-element">
                <h2 className="body-semibold colour-primary">
                    Exit strategy
                </h2>
                <div className="buy-box-strategies-row mobile-none">
                    {
                        exitStrategyOptions.map((item, index) =>
                            <SelectionChip
                                key={index}
                                label={item.label}
                                selected={item.selected}
                                disabled={item.disabled}
                                func={() => onChangeExitStrategy(item.value)}
                                index={index}
                                leftIcon={null}
                                rightIcon={null}
                            />
                        )
                    }
                </div>
                <div className="buy-box-mobile-strategies-column desktop-none">
                    {
                        exitStrategyOptions.map((item, index) =>
                            <MobileRadio
                                label={item.label}
                                selected={item.selected}
                                index={index}
                                clickFunc={() => onChangeExitStrategy(item.value)}
                                key={index}
                                disabled={item.disabled}
                            />
                        )
                    }
                </div>
            </div>
            {
                strategyError === true && (acquisitionStrategy === null || exitStrategy === null) ?
                <span className="body-regular colour-error">
                    Please select both acquisition and exit strategy
                </span>
                :
                null
            }
        </div>
    )
};

export default BuyBoxStrategies;