import React from 'react';
import '../styles/ConditionFilter.css';
import { Checkbox, FilterReset } from './';

function ConditionFilter(props) {
    const conditionValues = props.conditionValues;
    const setConditionValues = props.setConditionValues;
    const resetFilterType = props.resetFilterType;
    const reset = props.reset;
    const condition = [
        {
            label: "Recently remodeled",
            value: "rr"
        },
        {
            label: "Well-maintained",
            value: "wm"
        },
        {
            label: "Moderate rehab",
            value: "mr"
        },
        {
            label: "Gut rehab",
            value: "gr"
        }
    ];

    const changeValues = (value) => {
        const newArray = [...conditionValues];
        const valIndex = newArray.indexOf(value);
        if (valIndex > -1) {
            newArray.splice(valIndex, 1);
        }
        else {
            newArray.push(value);
        }
        setConditionValues(newArray);
    };

    const checkSelected = (value) => {
        const valIndex = conditionValues.indexOf(value);
        if (valIndex > -1) {
            return true;
        }
        else {
            return false;
        }
    };

    return (
        <div className="condition-filter-container">
            <div className="condition-filter-title-container">
                <h3 className="body-semibold colour-primary">
                    Property condition
                </h3>
            </div>
            <div className="condition-filter-body-container">
                {
                    condition.map((item, index) =>
                        <Checkbox
                            label={item.label}
                            selected={checkSelected(item.value)}
                            type="default"
                            disabled={false}
                            cta={() => changeValues(item.value)}
                            key={index}
                        />
                    )
                }
            </div>
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("condition")}
                />
            }
        </div>
    )
};

export default ConditionFilter;