import React from 'react';
import LoginComponent from '../authentication/LoginComponent';
import '../styles/Login.css';
import { Header, Footer } from '../components';
import { useLocation } from 'react-router-dom';

function BuyLogin() {

	const location = useLocation();
	const state = location.state;
	return (
		<div className="login-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
				desktopControl={true}
			/>
			<div className="login-inner-container">
				<h1 className="heading-large-semibold colour-primary text-align-center">
					Let's get you logged in
				</h1>
				<LoginComponent
					route={state.route}
					signUpRoute="/buy-sign-up"
					state={state}
				/>
			</div>
			<Footer />
		</div>
	)
}

export default BuyLogin;