import React from 'react';
import '../styles/PropertyTypeFilter.css';
import { Checkbox, Choicebox, FilterReset, SelectionChip } from './';
import { Units } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function PropertyTypeFilter(props) {
    const reset = props.reset;
    const resetFilterType = props.resetFilterType;
    const propertyTypes = props.propertyTypes;
    const setPropertyTypes = props.setPropertyTypes;
    const units = props.units;
    const setUnits = props.setUnits;
    const exactMatch = props.exactMatch;
    const setExactMatch = props.setExactMatch;
    const error = props.error;
    const unitOptions = [
        {
            label: "Any",
            value: 0,
            included: units === 0 ? true : false
        },
        {
            label: "1+",
            value: 1,
            included: units === 1 ? true : false
        },
        {
            label: "2+",
            value: 2,
            included: units === 2 ? true : false
        },
        {
            label: "3+",
            value: 3,
            included: units === 3 ? true : false
        },
        {
            label: "4",
            value: 4,
            included: units === 4 ? true : false
        }
    ];

    const changeProperty = (index) => {
        const newPropertyTypes = [...propertyTypes];
        newPropertyTypes[index].included = !newPropertyTypes[index].included;
        setPropertyTypes(newPropertyTypes);
    };

    const changeUnits = (unitIndex) => {
        setUnits(unitOptions[unitIndex].value);
    };

    const changeExactMatch = () => {
        setExactMatch(!exactMatch);
    };

    return (
        <div className="property-type-filter-container">
            <h3 className="body-semibold colour-primary">
                Property type
            </h3>
            <div className="property-type-selectors-container">
                {
                    propertyTypes.map((item, index) =>
                        <Choicebox
                            label={item.label}
                            icon={item.icon}
                            selected={item.included}
                            body={item.body}
                            standardIcon={item.standardIcon}
                            key={index}
                            cta={() => changeProperty(index)}
                        />
                    )
                }
            </div>
            {
                error === true &&
                <span className="body-regular colour-error">
                    Please select at least one property type
                </span>
            }
            {
                propertyTypes[1].included &&
                <div className="property-type-units-container">
                    <div className="property-type-heading-row">
                        <Units
                            className=""
                            fill={colour.grayScaleBlack}
                        />
                        <h3 className="body-semibold colour-primary">
                            Multi-family units
                        </h3>
                    </div>
                    <div className="property-type-units-selector">
                        {
                            unitOptions.map((item, index) =>
                                <SelectionChip
                                    label={exactMatch === true ? item.label.replace("+", "") : item.label}
                                    selected={item.included}
                                    disabled={false}
                                    func={changeUnits}
                                    index={index}
                                    leftIcon={null}
                                    rightIcon={null}
                                    key={index}
                                />
                            )
                        }
                    </div>
                    <div className="property-type-checkbox-container">
                        <Checkbox
                            label="Use exact match"
                            selected={exactMatch}
                            type="default"
                            disabled={false}
                            cta={changeExactMatch}
                        />
                    </div>
                </div>
            }
            {
                reset === true &&
                <FilterReset
                    clickFunc={() => resetFilterType("propertyType")}
                />
            }
        </div>
    )
};

export default PropertyTypeFilter;