import React from 'react';

function HeartActive(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none"
            className={className}
        >
            <path 
                d="M4.48134 12.3942C4.075 12.0058 3.51991 11.4531 2.95215 10.8161C2.39965 10.1962 1.75086 9.40289 1.21651 8.53685C0.765774 7.80633 0 6.41034 0 4.72423C0 2.39129 1.89621 0.500061 4.23529 0.500061C5.87432 0.500061 7.29589 1.42865 8 2.78712C8.70411 1.42865 10.1257 0.500061 11.7647 0.500061C14.1038 0.500061 16 2.39129 16 4.72423C16 6.41034 15.2342 7.80633 14.7835 8.53685C14.2491 9.40289 13.6003 10.1962 13.0479 10.8161C12.4801 11.4531 11.925 12.0058 11.5187 12.3942C10.28 13.5782 10.4909 13.3569 9.32085 14.3574C8.56082 15.0072 7.43918 15.0072 6.67915 14.3574C5.50913 13.3569 5.72012 13.5783 4.48134 12.3942Z" 
                fill={fill}
            />
        </svg>
    )
};

export default HeartActive;