import React, { useState } from 'react';
import { RentAccordion } from '../components';
import { FinancialsInput } from '../v4/components';
import { checkNaN, formatPrice } from '../functions';

function SFHRent(props) {
    const rent = props.rent;
    const blurred = props.blurred;
    const onChangeRent = props.onChangeRent;
    const defaultRent = props.defaultRent;
    const resetRentEstimates = props.resetRentEstimates;
    const property = props.property;
    const [rentAccordion, setRentAccordion] = useState(true);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };

    return (
        <div className="rent-tab-single-family-container">
            <RentAccordion
                rentAccordion={rentAccordion}
                handleRentAccordion={handleRentAccordion}
                property={property}
            />
            <div className="property-spacing-border">
            </div>
            <div className="sfh-rent-input-container strategies-panel-financials-input">
                <FinancialsInput
                    label={"Rent"}
                    labelType={"default"}
                    info={false}
                    value={blurred === true ? "999" : formatPrice(rent)}
                    onChange={(text) => onChangeRent(text.target.value.replaceAll(",", ""))}
                    placeholder={formatPrice(defaultRent)}
                    type="text"
                    leftUnit={"$"}
                    rightUnit={null}
                    unitSwitch={false}
                    leftUnitSelected={true}
                    unitSwitchFunc={null}
                    disabled={blurred}
                    multiline={false}
                    error={checkNaN(rent)}
                    onMouseEnter={null}
                    onMouseLeave={null}
                    reset={Number(rent) !== Number(defaultRent) ? true : false}
                    resetFunc={() => resetRentEstimates()}
                    resetPosition={"bottom"}
                />
            </div>
        </div>
    )
};

export default SFHRent;