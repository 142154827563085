import React from 'react';

function Meatball(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="30" 
            height="24" 
            viewBox="0 0 30 24" 
            fill="none"
            className={className}
        >
            <path 
                d="M6.3335 12C6.3335 13.6569 4.99035 15 3.3335 15C1.67664 15 0.333496 13.6569 0.333496 12C0.333496 10.3431 1.67664 9 3.3335 9C4.99035 9 6.3335 10.3431 6.3335 12Z" 
                fill={fill}
            />
            <path 
                d="M18.0002 12C18.0002 13.6569 16.657 15 15.0002 15C13.3433 15 12.0002 13.6569 12.0002 12C12.0002 10.3431 13.3433 9 15.0002 9C16.657 9 18.0002 10.3431 18.0002 12Z" 
                fill={fill}
            />
            <path 
                d="M26.6668 15C28.3237 15 29.6668 13.6569 29.6668 12C29.6668 10.3431 28.3237 9 26.6668 9C25.01 9 23.6668 10.3431 23.6668 12C23.6668 13.6569 25.01 15 26.6668 15Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Meatball;