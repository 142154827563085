import React, { useEffect } from 'react';
import '../styles/Legal.css';
import { Header, Footer } from '../components';

function PrivacyPolicy() {

	useEffect(() => {
		document.title = "Coffee Clozers | Privacy Policy";
	});

	const email = "info@coffeeclozers.com";

	return (
		<div className="legal-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
				desktopControl={true}
			/>
			<div className="legal-inner-container">
				<h1 className="display-xl-semibold colour-primary">
					Privacy Policy
				</h1>
				<div className="legal-text-container">
					<div 
						className="legal-text-element"
					>
						<p className="body-regular colour-primary margin-medium">
							This privacy policy applies to the website and mobile application operated by Coffee Clozers Inc. 
							("Coffee Clozers", "we", "us", or "our").
						</p>
						<h2 className="heading-large-semibold colour-primary margin-medium">
							1. Information We Collect
						</h2>
						<p className="body-regular colour-primary">
							We collect the following information about you when you use our website or mobile application:
						</p>
						<ul>
							<li className="body-regular colour-primary">
								Contact information, such as your name, email address, and phone number;
							</li>
							<li className="body-regular colour-primary">
								Demographic information, such as your age, gender, and location;
							</li>
							<li className="body-regular colour-primary">
								Information about your use of our website or mobile application, such as the pages you visit and the products you view;
							</li>
							<li className="body-regular colour-primary">
								Information about your device, such as your IP address, browser type, and operating system;
							</li>
							<li className="body-regular colour-primary">
								Information about your interactions with our marketing emails, such as whether you open them or click on the links.
							</li>
						</ul>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							2. How We Use Your Information
						</h2>
						<p className="body-regular colour-primary">
							We use the information we collect about you for the following purposes:
						</p>
						<ul>
							<li className="body-regular colour-primary">
								To provide you with the products and services you request;
							</li>
							<li className="body-regular colour-primary">
								To improve our website and mobile application;
							</li>
							<li className="body-regular colour-primary">
								To send you marketing communications about our products and services;
							</li>
							<li className="body-regular colour-primary">
								To track your interactions with our marketing communications;
							</li>
							<li className="body-regular colour-primary">
								To comply with applicable laws and regulations.
							</li>
						</ul>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							3. Sharing Your Information
						</h2>
						<p className="body-regular colour-primary">
							We may share your information with the following third parties:
						</p>
						<ul>
							<li className="body-regular colour-primary">
								Our service providers, who help us operate our website and mobile application;
							</li>
							<li className="body-regular colour-primary">
								Our marketing partners, who help us send you marketing communications;
							</li>
							<li className="body-regular colour-primary">
								Government agencies, if required by law.
							</li>
						</ul>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							4. Your Rights
						</h2>
						<p className="body-regular colour-primary">
							You have the following rights with respect to your personal information:
						</p>
						<ul>
							<li className="body-regular colour-primary">
								The right to access your personal information;
							</li>
							<li className="body-regular colour-primary">
								The right to correct your personal information;
							</li>
							<li className="body-regular colour-primary">
								The right to delete your personal information;
							</li>
							<li className="body-regular colour-primary">
								The right to object to the processing of your personal information;
							</li>
							<li className="body-regular colour-primary">
								The right to withdraw your consent to the processing of your personal information;
							</li>
							<li className="body-regular colour-primary">
								The right to data portability.
							</li>
							<li className="body-regular colour-primary">
								You can exercise your rights by contacting us at <a href={`mailto:${email}`}>{email}</a>
							</li>
						</ul>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							5. How We Protect Your Information
						</h2>
						<p className="body-regular colour-primary">
							We take steps to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. 
							These steps include:
						</p>
						<ul>
							<li className="body-regular colour-primary">
								Using secure servers to store your personal information;
							</li>
							<li className="body-regular colour-primary">
								Using encryption to protect your personal information when it is transmitted over the internet;
							</li>
							<li className="body-regular colour-primary">
								Limiting access to your personal information to authorized employees and contractors;
							</li>
							<li className="body-regular colour-primary">
								Implementing physical security measures to protect our premises.
							</li>
						</ul>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							6. How Long We Keep Your Information
						</h2>
						<p className="body-regular colour-primary">
							We will keep your personal information for as long as necessary to fulfill the purposes 
							for which it was collected, or as required by law.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							7. Changes to This Privacy Policy
						</h2>
						<p className="body-regular colour-primary">
							We may update this privacy policy from time to time. If we make any significant changes, we will notify you 
							by email or through a prominent notice on our website.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							8. Contact Us
						</h2>
						<p className="body-regular colour-primary">
							If you have any questions about this privacy policy, please contact us at <a href={`mailto:${email}`}>{email}</a>
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default PrivacyPolicy;