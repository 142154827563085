import React from 'react';
import '../styles/PriceHistory.css';
import { formatterLong } from '../../styles/GlobalStyles';
import moment from 'moment';

function PriceHistory(props) {
    const property = props.property;
    const labels = [
        "Date",
        "Activity",
        "Price",
        "Price per sqft"
    ];

    return (
        <div className="price-history-outer-container">
            <div className="price-history-inner-container">
                <div className="price-history-title-container">
                    <h2 className="heading-small-regular colour-primary">
                        Price history
                    </h2>
                </div>
                <div className="price-history-body-container">
                    <table className="price-history-table-container">
                        <thead>
                            <tr className="price-history-table-heading-container">
                                {
                                    labels.map((label, index) =>
                                        <th
                                            className="price-history-table-heading body-semibold colour-primary"
                                            key={index}
                                        >
                                            {label}
                                        </th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody className="price-history-table-body-container">
                            {
                                property.priceHistory.map((item, index) =>
                                    <tr 
                                        className="price-history-table-body-element"
                                        key={index}
                                    >
                                        <td className="price-history-table-body body-regular colour-primary">
                                            {moment(item.date).format("MMM D, YYYY")}
                                        </td>
                                        <td className="price-history-table-body body-regular colour-primary">
                                            {item.event}
                                        </td>
                                        <td className="price-history-table-body body-regular colour-primary">
                                            {formatterLong.format(item.price).replace(".00", "")}
                                        </td>
                                        <td className="price-history-table-body body-regular colour-primary">
                                            {formatterLong.format(item.pricePerSquareFoot).replace(".00", "")}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default PriceHistory;