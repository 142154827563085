import React, { useEffect } from 'react';
import '../styles/ViewBestMarket.css';
import { Header, Footer } from '../components';
import { Badge, Button } from '../v4/components';
import { Arrow } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, recordEvent } from "../functions";

function ViewBestMarket() {
    const navigate = useNavigate();
    
    useEffect(() => {
        document.title = "Welcome | Coffee Clozers";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status !== 200 ) {
                navigate("/sign-up");
            }
        };

        fetchUserData();
    }, [navigate])

    const market = {
        msaTitle: "Cleveland, OH",
        msaCode: "3924115"
    }

    const complete = () => {
        recordEvent("View Best Market", {});
        navigate(`/properties-list/${market.msaCode}`);    
    };

    return (
        <div className="view-best-market-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={false}
                desktopControl={false}
            />
            <div className="view-best-market-inner-container">
                <div className="view-best-market-title-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Welcome to Coffee Clozers!
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        We help you find the best properties to meet your investment goals! 
                        Let's get started by exploring our most popular market:
                    </span>
                </div>
                <div className="view-best-market-body-container">
                    <div 
                        className="view-best-market-card-container cursor-pointer"
                        onClick={() => complete()}
                    >
                        <span className="view-best-market-icon">
                            🏙️
                        </span>
                        <span className="body-regular colour-primary block-text text-align-center">
                            {market.msaTitle}
                        </span>
                        <Badge
                            val={"TOP FOR CASH-FLOW"}
                            bg="bg-colour-sold-status"
                            freeSize={true}
                        />
                    </div>
                </div>
                <div className="view-best-market-bottom-container">
                    <span className="body-regular colour-secondary block-text text-align-center">
                        (Don't worry, you can change this later)
                    </span>
                    <div className="view-best-market-button-container">
                        <Button
                            buttonType="primary-button"
                            size="large"
                            text={"Sounds good"}
                            cta={() => complete()}
                            disabled={false}
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={<Arrow className="view-best-market-arrow" fill={colour.grayScaleWhite} />}
                            rightIconClass=""
                            alt=""
                            standardIcon={false}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default ViewBestMarket;