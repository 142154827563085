import React from 'react';
import '../styles/Toast.css';
import { ToastExit } from '../../assets';
import { LoadingToastSpinner } from '../../assets/animations';
import IconButton from "./IconButton";
import Button from './Button';
import { useLottie } from 'lottie-react';

function Toast(props) {
    const type = props.type;
    const label = props.label;
    const icon = props.icon;
    const standardIcon = props.standardIcon;
    const linkFunc = props.linkFunc
    const linkText = props.linkText
    const closeFunc = props.closeFunc;
    const undoFunc = props.undoFunc;
    const options = {
        animationData: LoadingToastSpinner,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div className="toast-outer-container">
            <div className="toast-inner-container">
                <div className="toast-left-container">
                    {
                        icon === null ?
                        null
                        :
                        standardIcon === true ?
                        <img
                            src={icon}
                            className="toast-tick-icon"
                            alt="Tick"
                        />
                        :
                        icon
                    }
                    <span className="body-regular colour-white">
                        {label}
                    </span>
                    {
                        type === "link" &&
                        <span 
                            className="body-regular-underline colour-quaternary cursor-pointer block-text"
                            onClick={() => linkFunc()}
                        >
                            {linkText}
                        </span>
                    }
                </div>
                <div className="toast-right-container">
                    {
                        type === "undo" ?
                        <Button
                            buttonType="light-button"
                            size="small"
                            text={"Undo"}
                            cta={undoFunc}
                            disabled={false}
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                        :
                        null
                    }
                    {
                        type === "loading" ?
                        <div className="toast-loading-container">
                            {View}
                        </div>
                        :
                        <IconButton
                            size="small"
                            icon={ToastExit}
                            disabled={false}
                            clickFunc={closeFunc}
                            standardIcon={true}
                            contextTooltip={false}
							contextTooltipBG={null}
							contextTooltipText={null}
                            customClass={null}
                        />
                    }
                </div>
            </div>
        </div>
    )
};

export default Toast;