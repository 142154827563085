import React from 'react';
import '../styles/Highlights.css';
import { PropertyCondition } from './';
import { checkCondition } from '../../functions';

function Highlights(props) {
    const property = props.property;
    const label = props.label;
    const elements = props.elements;
    const conditionView = props.conditionView;

    return (
        <div className="highlights-outer-container">
            {
                label !== null &&
                <h2 className="heading-small-regular colour-primary">
                    {label}
                </h2>
            }
            {
                conditionView === true &&
                <PropertyCondition
                    condition={checkCondition(property.propertyCondition)}
                />
            }
            {
                elements.map((item, index) =>
                    item.active === true &&
                    <div 
                        className="highlights-element-container"
                        key={index}
                    >
                        <div className="highlights-icon-container">
                            {item.icon}
                        </div>
                        <div className="highlights-text-container">
                            <h3 className="body-semibold colour-primary">
                                {item.title}
                            </h3>
                            <span className="body-regular colour-secondary">
                                {item.description}
                            </span>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default Highlights;